import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";

import { Label, InputWrapper, ErrorMessage } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { label, value, error = undefined, ...rest }: InputProps,
  ref,
) => {
  return (
    <div>
      {!!label && <Label>{label}</Label>}
      <InputWrapper isErrored={!!error} isValid={!!value && !error}>
        <input ref={ref} value={value} {...rest} />

        {!!error && <FiXCircle size={20} color="#dc2626" />}
        {!error && !!value && <FiCheckCircle size={20} color="#00A650" />}
      </InputWrapper>

      {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

export const Input = forwardRef(InputBase);
