import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonLoading } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockItemAreaServicesList } from "@/pages/old/Services/components/BlockItemAreaServiceList";
import { getAppSlug } from "@/services/old/starter";
import { getAreaCategoria } from "@/services/old/servicos";
import { getContent } from "@/content/index";
import { useSendClickService } from "../../hooks/useSendClickService";
import { useAppId } from "@/store/useAppStore";

interface paramsPage {
  SLUG: string;
  id: string;
}

export function CategoryList() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const appId = useAppId();

  const { isLoading, data } = useQuery(
    ["getAreaCategoria"],
    () => getAreaCategoria(params.SLUG, params.id),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const { mutateAsync } = useSendClickService();

  function sendClickService(id: number) {
    mutateAsync({
      appId: Number(appId),
      serviceId: id,
    });
  }

  if (isLoading || !data) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  }

  if (!data || data?.data.length === 0) {
    return (
      <>
        <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
      </>
    );
  }

  return (
    <PageTemplate name={data?.data.titulo}>
      <div style={{ width: "100%", paddingBottom: "16px" }}>
        <div style={{ padding: "8px 8px", maxWidth: "100vw" }}>
          {data?.data.categorias.map((categoria: any, index: number) => {
            return (
              <div key={index} style={{ width: "100%", marginBottom: "16px" }}>
                {categoria.integracao && categoria.abrir_externo && (
                  <BlockItemAreaServicesList
                    openNewWindow={true}
                    href={categoria.integracao}
                    customImage={categoria.imagem}
                    title={categoria.titulo}
                    description={categoria.descricao}
                    onClick={() => sendClickService(categoria.id)}
                  />
                )}
                {categoria.integracao && !categoria.abrir_externo && (
                  <BlockItemAreaServicesList
                    routerLink={"/" + getAppSlug() + `/services-abrir-externo/${categoria.id}`}
                    customImage={categoria.imagem}
                    title={categoria.titulo}
                    description={categoria.descricao}
                    onClick={() => sendClickService(categoria.id)}
                  />
                )}
                {!categoria.integracao && !categoria.abrir_externo && (
                  <BlockItemAreaServicesList
                    routerLink={"/" + getAppSlug() + `/open-protocol/${categoria.id}`}
                    customImage={categoria.imagem}
                    title={categoria.titulo}
                    description={categoria.descricao}
                    onClick={() => sendClickService(categoria.id)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </PageTemplate>
  );
}
