import { changePassword } from "@/services/auth/forgotMyPassword/changePassword";
import { useMutation } from "@tanstack/react-query";

interface useChangePasswordParams {
  appId: number;
  username: string;
  password: string;
  token: string;
}

export function useChangePassword() {
  return useMutation({
    mutationFn: ({ appId, username, password, token }: useChangePasswordParams) => {
      return changePassword({ appId, username, password, token });
    },
  });
}
