import { useState } from "react";
import { atom } from "jotai";

import { Modal } from "@/components/Modal";
import { MoodAvaliationModal } from "./steps/MoodAvaliationModal";
import { RatingAvaliationModal } from "./steps/RatingAvaliationModal";
import { TextAvaliationModal } from "./steps/TextAvaliationModal";
import { SucessModal } from "./steps/SucessModal";

interface AvaliationResearchProps {
  isOpened: boolean;
  onCloseModal: () => void;
  enableService: boolean;
}

export type RatingType = {
  mood: number;
  stars: number | null;
  avaliationText: string | null;
};

const initialValues = {
  mood: 0,
  stars: null,
  avaliationText: "",
};

export const ratingAtom = atom<RatingType>(initialValues);

export function AvaliationResearchModal({
  isOpened,
  onCloseModal,
  enableService,
}: AvaliationResearchProps) {
  const [currentStep, setCurrentStep] = useState(1);

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  const closeModal = () => {
    setCurrentStep(1);
    onCloseModal();
  };

  const renderStepModal = () => {
    switch (currentStep) {
      case 1:
        return (
          <MoodAvaliationModal
            handleChangeStep={handleChangeStep}
            enableAvaliationService={enableService}
          />
        );
      case 2:
        return <RatingAvaliationModal handleChangeStep={handleChangeStep} />;
      case 3:
        return (
          <TextAvaliationModal
            handleChangeStep={handleChangeStep}
            enableAvaliationService={enableService}
          />
        );
      case 4:
        return <SucessModal onClose={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <Modal onClose={closeModal} showModal={isOpened}>
      {renderStepModal()}
    </Modal>
  );
}
