import { Block } from "@/components/old/Block";
import styled, { css } from "styled-components";

interface BlockImageProps {
  title?: string;
  src: string;
  maxHeigh?: string;
}

interface ImageProps {
  maxHeigh?: string;
}

const Image = styled.img<ImageProps>`
  margin: 0 auto;
  display: block;

  ${(props) =>
    !props.maxHeigh &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.maxHeigh &&
    css`
      hight: 100%;
    `}
`;
const ImagePreview = styled.div<ImageProps>`
  ${(props) =>
    props.maxHeigh &&
    css`
      max-height: ${props.maxHeigh};
    `}
  background: #000000;
  overflow: hidden;
`;

export function BlockImage(props: BlockImageProps) {
  return (
    <Block noPadding title={props.title}>
      {props.maxHeigh && (
        <ImagePreview maxHeigh={props.maxHeigh}>
          <Image src={props.src} maxHeigh={props.maxHeigh} alt="" />
        </ImagePreview>
      )}

      {!props.maxHeigh && <Image src={props.src} maxHeigh={props.maxHeigh} alt="" />}
    </Block>
  );
}
