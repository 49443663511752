import { useQuery } from "@tanstack/react-query";
import { getEntity } from "@/services/schedulings";

type useEntitySchedulingParams = {
  appId: number;
  id: string;
};

export function useEntityScheduling({ appId, id }: useEntitySchedulingParams) {
  return useQuery({
    queryKey: ["entity-scheduling", id, appId],
    queryFn: () => getEntity({ appId, id }),
    staleTime: 1 * (60 * 1000), // 5 minutos
  });
}
