import { SetStateAction, useState } from "react";
import { RadioGroup, RadioGroupItem, Text } from "@astrolabe-ui/react";

import { Category } from "@/services/categoryServices";
import { sortAreaCategories } from "@/pages/CategoriesServices/utils/sortAreaCategories";
import { Modal } from "@/components";

export const OrderModal = ({
  setShowOrderModal,
  showOrderModal,
  setServices,
}: {
  setShowOrderModal: (value: SetStateAction<boolean>) => void;
  showOrderModal: boolean;
  setServices: (value: SetStateAction<Category[]>) => void;
}) => {
  const [orderServices, setOrderServices] = useState<"asc" | "desc">("asc");

  const handleSorteAlphabetic: (order?: "asc" | "desc") => void = (order = "asc") => {
    setOrderServices(order);

    setServices((prevState) => sortAreaCategories(prevState, order) as Array<Category>);
    setShowOrderModal(false);
  };

  return (
    <Modal onClose={() => setShowOrderModal(false)} showModal={showOrderModal}>
      <div className="px-2">
        <RadioGroup>
          <RadioGroupItem
            {...(orderServices === "asc" && {
              checked: true,
            })}
            value="1"
            id="1"
            onClick={() => {
              handleSorteAlphabetic();
            }}
          >
            <Text color={orderServices === "asc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: A-Z
            </Text>
          </RadioGroupItem>
          <RadioGroupItem
            {...(orderServices === "desc" && {
              checked: true,
            })}
            value="2"
            id="2"
            onClick={() => {
              handleSorteAlphabetic("desc");
            }}
          >
            <Text color={orderServices === "desc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: Z-A
            </Text>
          </RadioGroupItem>
        </RadioGroup>
      </div>
    </Modal>
  );
};
