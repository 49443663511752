import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Content = styled.main`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: ${COLORS.PRIMARY};
      margin: 0;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      background-color: #e2e8f0;
      border-radius: 6px;

      svg {
        color: #1e293b;
      }
    }
  }

  form {
    display: flex;
    flex: 1;
    margin-top: 24px;
  }
`;

export const NotSubCategory = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 18px;
  font-size: 14px;
  color: #64748b;
`;
