import React, { ComponentProps } from "react";
import { IonRippleEffect } from "@ionic/react";
import { cn } from "@/lib/utils";

interface HeaderButtonProps extends ComponentProps<"button"> {
  variant?: "primary" | "secondary";
  icon: JSX.Element;
}

export function HeaderButton({
  variant = "primary",
  icon,
  className,
  ...props
}: HeaderButtonProps) {
  return (
    <button
      data-variant={variant}
      className={cn(
        "ion-activatable ripple-parent flex h-8 w-8 items-center justify-center rounded-full border-thin border-transparent bg-transparent disabled:cursor-not-allowed disabled:bg-slate-50 data-[variant='secondary']:border-slate-200",
        className,
      )}
      {...props}
    >
      {React.cloneElement(icon, {
        size: 20,
        className: cn("text-textHeader", {
          "text-primary-500": variant === "secondary",
          "text-slate-300": props.disabled,
        }),
      })}

      {!props.disabled ? (
        <IonRippleEffect
          data-variant={variant}
          className="text-primary-900 data-[variant='secondary']:text-primary-200"
        />
      ) : null}
    </button>
  );
}
