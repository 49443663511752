import { useQuery } from "@tanstack/react-query";
import { getServices } from "@/services/categoryServices";

export function useService(id: number, appId: number) {
  return useQuery({
    queryKey: ["services", id, appId],
    queryFn: () => getServices(id, appId),
    enabled: !!appId && !!id,
  });
}
