import { Text } from "@astrolabe-ui/react";

import { HourButton } from "./HourButton";
import { getBookingTimes } from "./utils/getBookingTimes";
import { getDateSelected } from "./utils/getDateSelected";
import { useInstitutionContext } from "../../contexts/InstitutionContext";
import { useStepsContext } from "../../contexts/StepsContext";

export const Time = () => {
  const { institution } = useInstitutionContext();

  const {
    dataPresentialSchedulingInstitution,
    setDataPresentialSchedulingInstitution,
    handleNextStep,
  } = useStepsContext();

  const times = getBookingTimes(institution, dataPresentialSchedulingInstitution);

  const handleSelectTime = (id: number) => {
    setDataPresentialSchedulingInstitution &&
      setDataPresentialSchedulingInstitution((prevState) => ({
        ...prevState,
        id_hora: id,
      }));
    handleNextStep && handleNextStep();
  };

  if (
    !dataPresentialSchedulingInstitution?.servico_agendamento_id ||
    !dataPresentialSchedulingInstitution?.sector_id ||
    !dataPresentialSchedulingInstitution?.id_agendamento ||
    !institution
  ) {
    throw new Error("Previous steps not defined");
  }

  const dateSelected = getDateSelected({
    institution,
    serviceId: dataPresentialSchedulingInstitution?.servico_agendamento_id,
    sectorId: dataPresentialSchedulingInstitution?.sector_id,
    bookingId: dataPresentialSchedulingInstitution?.id_agendamento,
  });

  return (
    <div className="flex flex-col gap-4">
      {dateSelected ? (
        <Text size="sm" color="slate-700" weight="medium" className="first-letter:uppercase">
          {dateSelected}
        </Text>
      ) : null}

      <div className="flex grow flex-wrap">
        <div className="grid grow grid-cols-3 gap-2 self-start xs:grid-cols-4">
          {times.map((bookingTime) => (
            <div key={bookingTime.id_agenda}>
              <HourButton
                className="px-5"
                textClassName="text-sm text-center"
                time={bookingTime.datetime}
                onClick={() => handleSelectTime(bookingTime.id_agenda)}
                selected={bookingTime.id_agenda === dataPresentialSchedulingInstitution?.id_hora}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
