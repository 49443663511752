import { http } from "@/lib/axios";
import { Holder, Review, User } from "@/store/useUserStore";
import { TermsOfUse } from "@/store/useAppStore";
import { ResponseLogin } from "./types";

interface LoginParams {
  appId: number;
  username: string;
  password: string;
  captchaResponse: string;
  cancel: boolean;
}

export async function login({ appId, username, password, captchaResponse, cancel }: LoginParams) {
  try {
    const response = await http.post<ResponseLogin>(`/v3/${appId}/usuarios/login`, {
      username,
      password,
      captchaResponse,
      cancelar: cancel ? 1 : undefined,
    });

    const data = response.data;

    const holderResponse = data.user_profile.titulares[0];

    let holder: Holder | null = null;

    if (holderResponse) {
      holder = {
        id: holderResponse.id,
        name: holderResponse.nome,
        email: holderResponse.email,
        cpf: holderResponse.cpf,
        birthdayDate: holderResponse.data_nascimento,
        imageUrl: holderResponse.foto,
        phone: holderResponse.telefone,
      };
    }

    let termsOfUse: TermsOfUse | null = null;

    if (data.user_profile?.link_termo_uso && data.user_profile?.versao_termo_uso) {
      termsOfUse = {
        url: data.user_profile.link_termo_uso,
        version: data.user_profile.versao_termo_uso,
      };
    }

    let review: Review | null = null;

    if (data.user_profile?.avaliacao) {
      review = {
        rating: data.user_profile.avaliacao.rating,
        comment: data.user_profile.avaliacao.comment,
      };
    }

    const user: User = {
      id: data.user_profile.id,
      name: data.user_profile.name,
      email: data.user_profile.email,
      cpf: data.user_profile.cpf,
      imageUrl: data.user_profile.imagem,
      phone: data.user_profile?.fone ? String(data.user_profile.fone) : null,
      location: data.user_profile.bairro,
      verifiedEmail: !!data.user_profile.email_verified_at,
      addresses: data.user_profile.adresses,
      review,
      termsOfUse,
      holder,
    };

    return {
      user,
      token: data.access_token,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
