import { http } from "@/lib/axios";
import dayjs from "dayjs";

type GetChatsParams = {
  appId: number;
  id: number;
};

type GetChatsResponse = Array<{
  id: number;
  mensagem: string;
  created_at: string;
  user: {
    id: number;
    name: string;
    imagem: string;
  };
}>;

export async function getChats({ appId, id }: GetChatsParams) {
  try {
    const response = await http.get<GetChatsResponse>(
      `/v3/${appId}/comerciante/${id}/listar_chats`,
    );

    return response.data.map((item) => ({
      id: item.id,
      time: dayjs(item.created_at).format("DD/MM/YYYY"),
      message: item.mensagem,
      user: {
        id: item.user.id,
        name: item.user.name,
        image: item.user.imagem,
      },
    }));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
