import { useQuery } from "@tanstack/react-query";
import { getMessagesMarket } from "@/services/market/get-messages-market";

type UseMessagesMarketParams = {
  appId: number;
  id: number;
  userId: number;
};

export function useMessagesMarketMerchant({ appId, id, userId }: UseMessagesMarketParams) {
  return useQuery({
    queryKey: ["messages-market-merchant", id, appId, userId],
    queryFn: () => getMessagesMarket({ appId, id, userId }),
    enabled: !!appId && !!id,
  });
}
