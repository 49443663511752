import { useMutation } from "@tanstack/react-query";
import { subscribeToEvent, unsubscribeFromEvent } from "@/services/events/subscribe-event";

export function useSubscribeToEvent() {
  return useMutation(subscribeToEvent);
}

export function useUnsubscribeFromEvent() {
  return useMutation(unsubscribeFromEvent);
}
