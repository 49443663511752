import { IonPage } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useAtom } from "jotai/react";
import { CaretLeft, X } from "@phosphor-icons/react";

import { List, Badge, Button, Container, Form, ListTerritories } from "./styles";

import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { MainContent } from "@/templates/old/ppa/MainContent";
import { ComboboxText } from "./components/combobox";
import { Territory, territoriesAtom } from "./atoms/territories-atom";

import logoPPA from "@/assets/images/logo-ppa.png";

export function Territories() {
  const { SLUG, id } = useParams<{
    SLUG: string;
    id: string;
  }>();
  const history = useHistory();
  const location = useLocation();

  const initalOrdination = new URLSearchParams(location.search).get("order");

  const [territories, setTerritories] = useAtom(territoriesAtom);

  function handleRemoveTerritory(territory: Territory) {
    setTerritories(territories.filter((item) => item.name !== territory.name));
  }

  function handleOpenProposals() {
    history.push(
      `/${SLUG}/consultas/${id}/propostas${initalOrdination ? `/?order=${initalOrdination}` : ""}`,
    );
  }

  function handleGoBack() {
    history.goBack();
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoBack} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="PPA RN" />
        </NavLink>
      </Header>

      <MainContent>
        <Container>
          <header>
            <strong>Territórios</strong>
            <p>
              O OP/RN é aplicado de maneira territorial, uma cidade pertence a um território, e sua
              priorização será para o território completo.
            </p>
          </header>

          <Form>
            <ComboboxText />
          </Form>

          <ListTerritories>
            <div>
              <strong>Territórios selecionados</strong>
              <p>
                Lista de territórios disponível para escolha quando realizar a priorização de uma
                diretriz.
              </p>
            </div>

            <List>
              {territories.length ? (
                <>
                  {territories.map((item) => (
                    <Badge key={item.name}>
                      <div>
                        {item.name}
                        <small>{`(${item.cities.join(", ")})`}</small>
                      </div>
                      <X
                        weight="bold"
                        onClick={() => handleRemoveTerritory(item)}
                        style={{ cursor: "pointer" }}
                      />
                    </Badge>
                  ))}
                </>
              ) : (
                <small>
                  Não há territórios selecionado, informe a cidade acima que irá adicionar o
                  território referente.
                </small>
              )}
            </List>
          </ListTerritories>

          <Button onClick={handleOpenProposals} disabled={!territories.length}>
            Iniciar
          </Button>
        </Container>
      </MainContent>
    </IonPage>
  );
}
