import { Box, Text } from "@astrolabe-ui/react";
import { CalendarCheck, NavigationArrow } from "@phosphor-icons/react";

interface CardEventsProps {
  title: string;
  banner: string;
  localization: string | null;
  time: string;
  handleOnClick: () => void;
}

export function CardEvents({ title, banner, localization, time, handleOnClick }: CardEventsProps) {
  return (
    <Box
      px={3}
      py={3}
      className="flex items-center gap-3 border-thin hover:cursor-pointer"
      onClick={handleOnClick}
    >
      <div className="h-20 w-32 shrink-0 overflow-hidden rounded-xs">
        <img src={banner} alt="" className="h-full w-full object-cover" />
      </div>

      <div className="flex flex-col gap-1 overflow-hidden">
        <Text size="xs" weight="medium" color="slate-700" className="line-clamp-2">
          {title}
        </Text>

        <div className="flex items-center gap-1">
          <CalendarCheck size={18} className="flex-shrink-0 text-primary-500" />
          <Text size="xs" weight="normal" color="slate-700" className="line-clamp-1">
            {time}
          </Text>
        </div>

        {localization ? (
          <div className="flex items-center gap-1">
            <NavigationArrow size={18} className="flex-shrink-0 text-primary-500" />
            <Text size="xs" weight="normal" color="slate-700" className="line-clamp-1">
              {localization}
            </Text>
          </div>
        ) : null}
      </div>
    </Box>
  );
}
