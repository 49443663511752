import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IonLoading, useIonAlert } from "@ionic/react";
import { FiX } from "react-icons/fi";

import { SelectSubcategories } from "../steps/SelectSubCategories";
import { FormProtocol } from "../steps/FormProtocol";
import { ShowFormProtocol } from "../steps/ShowFormControl";
import { ItemRadioButton } from "@/components/old/Forms/RadioButton";
import { Step, Steps } from "@/components/old/Steps";
import { Modal } from "@/components/old/Modal";
import { getServicoCategoryById, registProtocol } from "@/services/old/servicos";
import { isLogged } from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";

import { Content, NotSubCategory } from "./styles";

interface ServiceParams {
  id: string;
  SLUG: string;
}

export type ProtocolData = {
  subcategory: ItemRadioButton;
  nome: string;
  endereco: string;
  latitude: number;
  longitude: number;
  imagemValue: string;
  imagem: string;
  mensagem: string;
  identificador: string;
  placaVeiculo: string;
  campos: Record<number, any>;
  hideUser: boolean;
};

const CONTENT = getContent();

const stepsInitial = [
  { id: 0, title: "Selecione a subcategoria", status: "current" },
  { id: 1, title: "Preencha os campos do formulário", status: "upcoming" },
  { id: 2, title: "Revise seus dados", status: "upcoming" },
];

export function FormOpenProtocol() {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<Step[]>(stepsInitial);

  const [isLoading, setIsLoading] = useState(false);

  const [token] = useState(!!localStorage.getItem(STORAGE.AUTH_ACCESS_TOKEN.KEY));
  const [userId] = useState(localStorage.getItem(STORAGE.AUTH_USER_ID.KEY));

  const methods = useForm<ProtocolData>({
    mode: "onChange",
  });

  const params: ServiceParams = useParams();

  const { data: category } = useQuery({
    queryKey: ["getServicoCategoryById", params.id],
    queryFn: () => getCategory(params.id, params.SLUG),
    enabled: true,
  });

  const valuesSubcategory = methods.watch("subcategory");
  const [fields, setFields] = useState<any[]>([]);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [subcategorySelected, setSubcategorySelected] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState("");

  const history = useHistory();

  const [presentAlert] = useIonAlert();

  async function getCategory(id: string, slug: string) {
    const response = await getServicoCategoryById(id, slug);

    if (!response.data?.abrir_anonimo && !isLogged()) {
      presentAlert({
        message: CONTENT.GLOBAL.NEEDTOBELOGED,
        backdropDismiss: false,
        buttons: [
          {
            text: CONTENT.GLOBAL.CLOSE,
            handler: () => history.goBack(),
          },
          {
            text: CONTENT.USER.LOGIN,
            handler: () => history.replace("/" + getAppSlug() + "/auth"),
          },
        ],
      });
    }

    const subcategories = response.data?.sub_categoria_servicos.map((item: any) => ({
      id: item.id,
      title: item.titulo,
      message: item.mensagem,
      fields: item.campo.map((item: any) => ({
        id: item.id,
        title: item.nome,
        type: item.tipo,
        required: item.required,
      })),
    }));

    const fields = response.data?.campo.map((item: any) => ({
      id: item.id,
      title: item.nome,
      type: item.tipo,
      required: item.required,
    }));

    setCategoryTitle(response.data.titulo);

    return {
      ...response.data,
      subcategories,
      fields,
    };
  }

  const handleSubmitForm: SubmitHandler<ProtocolData> = async () => {
    if (category.usarEndereco && !methods.getValues("endereco")) {
      const element = document.getElementById("geo-location");
      element?.scrollIntoView();

      return methods.setError("endereco", {
        type: "required",
        message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
      });
    }

    handleNextStep();
  };

  async function submitForm() {
    setIsLoading(true);

    const values = methods.getValues();

    const form = new FormData();

    form.append("categoriaServico", params.id);
    form.append("subCategoriaServico", values.subcategory.value);

    if (category.usarEndereco && values.latitude && values.longitude) {
      form.append("latitude", values.latitude.toString());
      form.append("longitude", values.longitude.toString());
    }

    if (category.usarEndereco && values.endereco) {
      form.append("endereco", values.endereco);
    }

    if (values.imagem) {
      form.append("imagem", values.imagem);
    }

    form.append("mensagem", values.mensagem);

    if (category.usarIdentificador && values.identificador) {
      form.append("identificador", values.identificador);
    }

    if (category.usarPlaca && values.placaVeiculo) {
      form.append("placaVeiculo", values.placaVeiculo);
    }

    if (values.nome && !token) {
      form.append("nome", values.nome);
    } else if (values.hideUser && token && userId) {
      form.append("autor", userId);
    }

    if (values.campos) {
      let i = 0;
      Object.entries(values.campos).forEach(([index, value]) => {
        form.append(`campos_extras[${i}]`, index);
        form.append(`campo_value[${i}]`, value.file ? value.file : value || "");
        i++;
      });
    }

    const anonymous = values.hideUser ? values.hideUser : !isLogged();

    registProtocol(form, params.SLUG, anonymous)
      .then((res) => {
        if (res) {
          methods.reset();
          setCurrentStep(0);
          setSteps(stepsInitial);
          history.push(`/${getAppSlug()}/confirmation-open-protocol/${res.data.id}`);
        } else {
          return setOpenModal("error");
        }
      })
      .catch((err) => {
        console.error(err);

        if (err?.status === 405) {
          setOpenModal("limit");
          return;
        }

        setOpenModal("error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleNextStep() {
    if (currentStep < steps.length - 1) {
      const indexCurrent = steps.findIndex((item) => item.id === currentStep);
      const newStep = steps[indexCurrent + 1].id;

      setCurrentStep(newStep);
      handleChangeStep(newStep, "next");
    }
  }

  function handleBackStep() {
    const indexCurrent = steps.findIndex((item) => item.id === currentStep);
    const newStep = steps[indexCurrent - 1].id;

    setCurrentStep(newStep);
    handleChangeStep(newStep);
  }

  function handleChangeStep(currentStep: number, direction?: string) {
    const element = document.getElementById("container-step");
    element?.scrollIntoView();

    if (direction === "next") {
      const newsSteps = steps.map((step) => ({
        ...step,
        status:
          step.id < currentStep ? "complete" : step.id === currentStep ? "current" : "upcoming",
      }));

      setSteps(newsSteps);
    } else {
      const newsSteps = steps.map((step) => ({
        ...step,
        status:
          step.id < currentStep ? "complete" : step.id === currentStep ? "current" : "upcoming",
      }));

      setSteps(newsSteps);
    }
  }

  function handleClose() {
    methods.reset();
    setCurrentStep(0);
    setSteps(stepsInitial);
    history.goBack();
  }

  useEffect(() => {
    if (valuesSubcategory && category && category.subcategories) {
      const fieldsSubcategories = category?.subcategories.find(
        (item: any) => item.id === valuesSubcategory?.value,
      )?.fields;

      setSubcategorySelected(
        category?.sub_categoria_servicos.find((item: any) => item.id === valuesSubcategory?.value),
      );

      setFields([...category.fields, ...fieldsSubcategories]);
    }
  }, [category, valuesSubcategory]);

  if (!category) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  }

  const optionSubcategories =
    category && category.subcategories
      ? category?.subcategories?.map((item: any) => ({
          value: item.id,
          label: item.title,
          message: item.message,
        }))
      : [];

  return (
    <Content>
      <header>
        <h1>{categoryTitle}</h1>
        <button onClick={() => setOpenModal("cancel")} disabled={isLoading}>
          <FiX size={24} />
        </button>
      </header>

      <Steps steps={steps} currentStepId={currentStep} sizeList="33.33%" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
          {currentStep === 0 && (
            <>
              {optionSubcategories.length > 0 && (
                <SelectSubcategories
                  handleNext={handleNextStep}
                  name="subcategory"
                  options={optionSubcategories}
                  disabledNext={!valuesSubcategory}
                />
              )}
              {optionSubcategories.length === 0 && (
                <NotSubCategory>Sem resultados para subcategorias.</NotSubCategory>
              )}
            </>
          )}

          {currentStep === 1 && (
            <FormProtocol
              handleBack={handleBackStep}
              name="form"
              category={category}
              subcategorySelected={subcategorySelected}
              fields={fields}
              disabledNext={false}
            />
          )}

          {currentStep === 2 && (
            <ShowFormProtocol
              submitForm={submitForm}
              handleBack={handleBackStep}
              fields={fields}
              isLoading={isLoading}
            />
          )}
        </form>
      </FormProvider>

      <Modal
        isOpen={openModal === "cancel"}
        onClose={() => setOpenModal("")}
        title="Cancelar protocolo"
        description="Deseja cancelar abertura do protocolo?"
        status="warn"
        textButton="Confirmar"
        textLink="Não, obrigado"
        onActionPrimary={() => handleClose()}
        onActionSecondary={() => setOpenModal("")}
      />

      <Modal
        isOpen={openModal === "error"}
        onClose={() => setOpenModal("")}
        title="Falha ao finalizar o protocolo!"
        description="Revise seus dados ou tente novamente mais tarde."
        status="error"
        textButton="Tentar novamente"
        textLink="Retornar a página inicial"
        onActionPrimary={() => setOpenModal("")}
        onActionSecondary={() => history.push(`/${getAppSlug()}`)}
      />

      <Modal
        isOpen={openModal === "limit"}
        onClose={() => setOpenModal("")}
        title="Limite atingido!"
        description="Você atingiu o limite de protocolos nessa categoria por tempo."
        status="error"
        textButton="Tentar novamente"
        textLink="Retornar a página inicial"
        onActionPrimary={() => setOpenModal("")}
        onActionSecondary={() => history.push(`/${getAppSlug()}`)}
      />
    </Content>
  );
}
