import { PageTemplate } from "@/templates/old/PageTemplate";
import { TabProtocolsList } from "@/pages/old/Services/components/TabProtocolsList";

export function MyServices() {
  return (
    <PageTemplate name="Meus protocolos">
      <TabProtocolsList />
    </PageTemplate>
  );
}
