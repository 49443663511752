import React from "react";
import * as AccordionLib from "@radix-ui/react-accordion";
import { CaretDown } from "@phosphor-icons/react";

import { Root, Item, Header, Trigger, Content, ContentText } from "./styles";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionLib.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionLib.Trigger>
>(({ children, ...props }, forwardedRef) => (
  <Header>
    <Trigger {...props} ref={forwardedRef}>
      {children}
      <CaretDown size={16} aria-hidden />
    </Trigger>
  </Header>
));

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionLib.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionLib.Content>
>(({ children, ...props }, forwardedRef) => (
  <Content {...props} ref={forwardedRef}>
    <ContentText>{children}</ContentText>
  </Content>
));

AccordionContent.displayName = "AccordionContent";

interface AccordionProps {
  title: string;
  content: string;
}

export function Accordion({ title, content }: AccordionProps) {
  return (
    <Root type="single" collapsible>
      <Item value={title}>
        <AccordionTrigger>{title}</AccordionTrigger>
        <AccordionContent>
          <span
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></span>
        </AccordionContent>
      </Item>
    </Root>
  );
}
