import { useParams } from "react-router-dom";
import * as Accordion from "@radix-ui/react-accordion";
import { Text } from "@astrolabe-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { useAppId } from "@/store/useAppStore";
import { EmptyFilteredQuestions } from "./components/EmptyFilteredQuestions";
import { CardFilteredQuestion } from "./components/CardFilteredQuestion";
import { LoadingFaqs } from "../LoadingFaqs";
import { useFaqsGeneral } from "../../hooks/useFaqsGeneral";

export function FilteredQuestions({ query }: { query: string }) {
  const { id } = useParams<{ id: string }>();

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useFaqsGeneral({ appId, id, query });

  if (isLoading) {
    return <LoadingFaqs />;
  }

  if (!data?.length && !isLoading) {
    return <EmptyFilteredQuestions />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <MagnifyingGlass className="text-primary-500" size={20} weight="bold" />

        <Text size="md" color="primary-500" weight="medium">
          {data.length} resultado(s)
        </Text>
      </div>

      <Accordion.Root type="multiple" className="flex flex-col gap-5">
        {data.map((item) => (
          <CardFilteredQuestion
            key={item.id}
            id={item.id}
            title={item.title}
            content={item.content}
            category={{ title: item.category.title, icon: item.category.icon }}
          />
        ))}
      </Accordion.Root>
    </div>
  );
}
