import { useState } from "react";
import { IonToast } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { chevronForwardCircleOutline, copyOutline } from "ionicons/icons";

import {
  Ancho,
  Content,
  ContentIconRow,
  ContentId,
  ContentStatus,
  ContentTitle,
  IconCopy,
  IconRow,
  Id,
  RowDate,
  RowStatusId,
  RowTitle,
  Status,
  Title,
} from "./styles";

interface BlockItemProtocolsListProps {
  id: number;
  category?: string;
  status: string;
  createdAt: string;
  routerLink: string;
}

export function BlockItemProtocolsList(props: BlockItemProtocolsListProps) {
  const history = useHistory();
  const [toast, setToast] = useState(false);
  const status: any = statusColor(props.status);

  function statusColor(status: string) {
    let statusValues = {};

    switch (status) {
      case "Em Aberto":
        statusValues = {
          decription: "Aberto",
          styleStatus: {
            backgroundColor: "#2563eb1a",
            color: "#2563EB",
          },
          styleContent: {
            borderLeft: "2px solid #2563EB",
          },
        };
        break;

      case "Em Atendimento":
        statusValues = {
          decription: "Atendimento",
          styleStatus: {
            backgroundColor: "#f779061a",
            color: "#F67906",
          },
          styleContent: {
            borderLeft: "2px solid #F67906",
          },
        };
        break;

      case "Encerrado":
        statusValues = {
          decription: "Concluído",
          styleStatus: {
            backgroundColor: "#16a34a1a",
            color: "#16A34A",
          },
          styleContent: {
            borderLeft: "2px solid #16A34A",
          },
        };
        break;

      default:
        statusValues = {
          decription: status,
          styleStatus: {
            backgroundColor: "#f3f4f6",
            color: "#4b5563",
          },
          styleContent: {
            borderLeft: "2px solid #4b5563",
          },
        };
        break;
    }

    return statusValues;
  }

  function handleAncho(e: any) {
    e.preventDefault();
    history.push(props.routerLink);
  }

  function copyId(e: any) {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(props.id.toString());
    setToast(true);
  }

  function formatDescriptiveDate(date: string) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date));
  }

  return (
    <Ancho onClick={handleAncho}>
      <Content style={status.styleContent}>
        <RowTitle>
          <ContentTitle>
            <Title>{props.category}</Title>
          </ContentTitle>
          <ContentIconRow>
            <IconRow icon={chevronForwardCircleOutline} />
          </ContentIconRow>
        </RowTitle>
        <RowDate>{formatDescriptiveDate(props.createdAt)}</RowDate>
        <RowStatusId>
          <ContentStatus style={status.styleStatus}>
            <Status>{status.decription}</Status>
          </ContentStatus>
          <ContentId onClick={(e) => copyId(e)}>
            <Id>{props.id}</Id>
            <IconCopy icon={copyOutline} />
          </ContentId>
        </RowStatusId>
      </Content>
      <IonToast
        isOpen={toast}
        position="bottom"
        color="dark"
        onDidDismiss={() => setToast(false)}
        message="Copiado com sucesso."
        duration={3000}
      />
    </Ancho>
  );
}
