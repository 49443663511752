import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { COLORS } from "@/data/colors";

import { Select } from "../inputs/select";

interface SelectFieldProps {
  options: Array<string>;
  label: string;
  name: string;
}

export function SelectField({ options, name, label }: SelectFieldProps) {
  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const valueField = useWatch({
    name,
    control,
  });

  const error = errors[name]?.message ? String(errors[name]?.message) : undefined;

  const errorOrther = errors[`orther${name}`]?.message
    ? String(errors[`orther${name}`]?.message)
    : undefined;

  return (
    <Container>
      <Label htmlFor={name}>
        {label} <span>*</span>
      </Label>

      <Select
        id={name}
        {...register(name, {
          required: {
            value: true,
            message: "Esse campo é obrigatório",
          },
          onChange: () => {
            if (valueField === "Outro") {
              setValue(`orther${name}`, "");
            }
          },
        })}
        hasValue={!!getValues(name)}
      >
        <option value="" disabled>
          Selecione
        </option>
        {options.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Select>

      {error ? <FieldError>{error}</FieldError> : null}

      {valueField === "Outro" ? (
        <OrtherInput>
          <label htmlFor={`orther${name}`}>Informe um novo valor</label>

          <TextInput
            id={`orther${name}`}
            type="text"
            placeholder="Digite"
            maxLength={30}
            {...register(`orther${name}`, {
              required: {
                value: true,
                message: "Informe o valor para prosseguir",
              },
            })}
          />

          {errorOrther ? <FieldError>{errorOrther}</FieldError> : null}
        </OrtherInput>
      ) : null}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: 0.875rem;
  line-height: 150%;
  color: #334155;

  span {
    color: #e43c3c;
  }
`;

export const FieldError = styled.span`
  font-size: 0.75rem;
  line-height: 150%;
  color: #e43c3c;
`;

export const OrtherInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.75rem;
    line-height: 150%;
    color: #334155;
  }
`;

export const TextInput = styled.input`
  appearance: none;

  height: 3.225rem;
  width: 100%;
  padding: 1rem 0.75rem;

  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  line-height: 150%;
  color: #334155;

  outline: none;

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    border-color: ${COLORS.PRIMARY};
  }
`;
