import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";
import { CaretRight, Copy } from "@phosphor-icons/react";

import { Tag } from "./Tag";
import { cn } from "@/lib/utils";
import { decodeFormatDate } from "@/utils/decodeFormatDate";
import { MouseEvent } from "react";

export const Card = ({
  code,
  subtitle,
  tag,
  title,
  onClickCaretRightButton = () => {
    console.log("add onClickCaretRightButton prop");
  },
  ...rest
}: {
  title: string;
  subtitle: string;
  tag: { text: string; variant: "primary" | "warning" | "success" };
  code: string;
  onClickCaretRightButton?: () => void;
  className?: string;
}) => {
  const { t } = useTranslation();

  const handleCopy = async (event: MouseEvent) => {
    event.stopPropagation();
    await window.navigator.clipboard.writeText(code);
    toast.success(t("general.Copiado com sucesso"));
  };

  return (
    <div
      onClick={onClickCaretRightButton}
      className={cn(
        `flex flex-col gap-3 rounded-md border-b-[1px] border-r-[1px] border-t-[1px] border-b-slate-200 border-r-slate-200 border-t-slate-200 p-3 ${
          rest.className || " "
        }`,
        {
          "border-l-[2px] border-l-primary-500": tag.variant === "primary",
          "border-l-[2px] border-l-orange-600": tag.variant === "warning",
          "border-l-[2px] border-l-green-600": tag.variant === "success",
        },
      )}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between gap-4">
          <Text color="slate-700" weight="medium">
            {title}
          </Text>

          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-50">
            <CaretRight size={12} weight="bold" className="text-primary-500" />
          </div>
        </div>

        <Text color="slate-400" size="xs">
          {decodeFormatDate(subtitle, "cut")}
        </Text>
      </div>
      <div className="flex justify-between text-center">
        <div className="flex items-center">
          <Tag text={tag.text} variant={tag.variant} />
        </div>

        <Button
          variant="tertiary"
          type="button"
          className="tracking-[.5rem] "
          onClick={(e) => handleCopy(e)}
        >
          {code.split("").map((c, index) => (
            <Text color="slate-700" weight="medium" key={index}>
              {c}
            </Text>
          ))}

          <Copy className="text-slate-300" />
        </Button>
      </div>
    </div>
  );
};
