import styled from "styled-components";
import { IonText } from "@ionic/react";

export const PageTitle = styled.h1`
  text-align: center;
  width: 100%;
  font-size: 1.4em;
  padding: 18px 10px 12px 10px;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.2em;
  }
`;

export const PostTitle = styled.h1`
  font-size: 1.6em;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 1.3em;
  }
`;

export const PostText = styled(IonText)`
  font-size: 1em;
  line-height: 1.7em;

  @media (max-width: 700px) {
    font-size: 1em;
  }
`;
