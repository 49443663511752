import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";
import toast from "react-hot-toast";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { IonRippleEffect, IonSkeletonText } from "@ionic/react";
import { PaperPlaneRight, CaretLeft, Trash } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton } from "@/components";
import { ModalConfirmationDeleteComment } from "./components/modal-confirmation-delete-comment";
import { useAppId } from "@/store/useAppStore";
import { useUserStore } from "@/store/useUserStore";
import { useUserToken } from "@/store/useAuthStore";
import { useComments } from "./hooks/useComments";
import { useAddComment } from "./hooks/useAddComment";

const FormCommentSchema = z.object({
  comment: z.string().min(1),
});

type FormCommentData = z.infer<typeof FormCommentSchema>;

export function PostComments() {
  const { id, moduleId } = useParams<{ id: string; moduleId: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();

  const appId = useAppId();
  const userId = useUserStore((state) => state.user?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const isAuthenticated = !!useUserToken();

  const [modalDeleteCommentOpen, setModalDeleteCommentOpen] = useState({
    isOpen: false,
    comment: {
      id: 0,
      content: "",
    },
  });

  const { register, reset, handleSubmit } = useForm<FormCommentData>({
    resolver: zodResolver(FormCommentSchema),
    defaultValues: {
      comment: "",
    },
  });

  const { data: comments, isLoading } = useComments({
    appId,
    id: Number(id),
  });

  const { mutateAsync } = useAddComment();

  function handleAddComment(data: FormCommentData) {
    toast.promise(
      mutateAsync(
        {
          appId: Number(appId),
          id: Number(id),
          comment: data.comment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["comments", Number(id), appId] });

            queryClient.invalidateQueries({
              queryKey: ["getGalleryModulosPhotosModulo", moduleId],
            });

            reset({ comment: "" });
          },
        },
      ),
      {
        loading: "Enviando...",
        success: "Comentário adicionado",
        error: "Erro ao adicionar",
      },
    );
  }

  function handleOpenModalDeleteComment({ id, content }: { id: number; content: string }) {
    setModalDeleteCommentOpen({ isOpen: true, comment: { id, content } });
  }

  function handleCloseModalDeleteComment() {
    setModalDeleteCommentOpen({
      isOpen: false,
      comment: {
        id: 0,
        content: "",
      },
    });
  }

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text
            size="md"
            weight="medium"
            leading="relaxed"
            className="line-clamp-1 text-textHeader"
          >
            Comentários
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col px-4 py-6 ios:pb-2">
          <div className="flex flex-col gap-6 pb-20">
            {isLoading && !comments
              ? Array.from(Array(5).keys()).map((index) => (
                  <IonSkeletonText key={index} animated className="h-12 w-full" />
                ))
              : null}

            {!isLoading && comments?.length
              ? comments.map((item) => (
                  <div key={item.id} className="flex items-center gap-2">
                    <div className="flex w-full flex-col justify-between">
                      <div className="flex items-center gap-1.5">
                        <Text size="xs">{item.user.name.split(" ")[0]}</Text>
                        <Text size="xs">•</Text>
                        <Text size="xs">{item.time}</Text>
                      </div>
                      <Text color="slate-700">{item.comment}</Text>
                    </div>

                    {item.user.id === userId ? (
                      <button
                        className="ion-activatable ripple-parent flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full"
                        onClick={() =>
                          handleOpenModalDeleteComment({ id: item.id, content: item.comment })
                        }
                      >
                        <Trash className="text-red-500" />
                        <IonRippleEffect className="text-slate-200" />
                      </button>
                    ) : null}
                  </div>
                ))
              : null}

            {!isLoading && !comments?.length ? (
              <div className="flex flex-col items-center gap-0.5 px-4 py-8 text-center">
                <Text weight="medium" color="slate-700">
                  Não há comentários!
                </Text>
                <Text color="slate-400">
                  Utilize o campo de texto abaixo para adicionar um comentário.
                </Text>
              </div>
            ) : null}
          </div>

          {isAuthenticated ? (
            <form onSubmit={handleSubmit(handleAddComment)}>
              <div className="fixed inset-x-0 bottom-0 flex w-full items-center gap-2 bg-slate-100 p-4">
                <input
                  type="text"
                  placeholder="Digite aqui..."
                  className="flex-1 font-sans text-sm text-slate-700 placeholder:text-slate-400"
                  {...register("comment")}
                />

                <button
                  type="submit"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-primary-500"
                >
                  <PaperPlaneRight size={20} className="text-white" weight="fill" />
                </button>
              </div>
            </form>
          ) : null}
        </div>

        <ModalConfirmationDeleteComment
          isOpen={modalDeleteCommentOpen.isOpen}
          onClose={handleCloseModalDeleteComment}
          comment={modalDeleteCommentOpen.comment}
        />
      </Content>
    </Layout>
  );
}
