import { MouseEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { Image, X } from "@phosphor-icons/react";

import { Button, Loading, Modal, ReactPortal } from "@/components/index";
import { usePhotoGallery } from "@/hooks/usePhotoGallery";
import { useAppId } from "@/store/useAppStore";
import { useSendTemporaryFile } from "../../hooks/use-send-tempory-file";
import { cn } from "@/lib/utils";
import { FieldProps } from "../question";

export function ImageField({ value, onChange, field }: FieldProps) {
  const { t } = useTranslation();
  const appId = useAppId();
  const [showModal, setShowModal] = useState(false);

  const {
    formState: { errors },
  } = useFormContext();

  const { mutateAsync, isLoading } = useSendTemporaryFile();

  const { takePhoto } = usePhotoGallery();

  function handleSendImage(file: Blob) {
    mutateAsync(
      { appId: Number(appId), file },
      {
        onSuccess: (url: string) => onChange(url),
        onError: (err) => console.error(err),
      },
    );
  }

  async function handleSelectImage(mode: "camera" | "photos") {
    try {
      const photo = await takePhoto(mode);

      if (photo) {
        handleSendImage(photo.file);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowModal(false);
    }
  }

  function removeFile(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    onChange("");
  }

  const error = errors[field.id]?.message ? String(errors[field.id]?.message) : undefined;

  return (
    <div className="flex flex-col gap-1">
      <div
        className={cn(
          "flex flex-col items-center justify-center gap-3 rounded-md border-thin border-slate-200 p-4",
          {
            "border-red-500": !!error,
          },
        )}
        onClick={() => setShowModal(true)}
      >
        <div className="flex items-center gap-2">
          <Image className="text-md text-primary-500" alt="Icon file" />
          <Text size="xs" color="primary-500">
            {field.name}
          </Text>
        </div>

        {value ? (
          <div className="relative w-full">
            <img src={String(value)} alt="" className="h-32 w-full rounded-sm object-cover" />

            <button
              onClick={removeFile}
              className="absolute right-2 top-2 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-slate-100"
            >
              <X weight="bold" size={12} className="text-slate-700" />
            </button>
          </div>
        ) : null}

        {isLoading ? <Loading variant="secondary" /> : null}
      </div>

      {error ? (
        <Text size="xs" className="text-red-500">
          {error}
        </Text>
      ) : null}

      {!error && !!field.hint ? <Text size="xs">{field.hint}</Text> : null}

      <ReactPortal>
        <Modal showModal={showModal} onClose={() => setShowModal(false)}>
          <div className="flex flex-col items-center gap-3">
            <Button
              variant="secondary"
              type="button"
              size="lg"
              full
              className="border-slate-200"
              onClick={() => handleSelectImage("camera")}
            >
              {t("profile.Usar câmera")}
            </Button>

            <Button
              variant="primary"
              type="button"
              size="lg"
              full
              onClick={() => handleSelectImage("photos")}
            >
              {t("profile.Escolher na galeria")}
            </Button>
          </div>
        </Modal>
      </ReactPortal>
    </div>
  );
}
