import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";

export const getDefaultAmountTime = (dataAssetsScheduling?: IDataAssetsScheduling) =>
  dataAssetsScheduling?.hora_fim && dataAssetsScheduling?.hora_inicio
    ? dayjs(dayjs(dataAssetsScheduling?.hora_fim).format("HH:mm"), "HH:mm").diff(
        dayjs(dayjs(dataAssetsScheduling?.hora_inicio).format("HH:mm"), "HH:mm"),
        "minutes",
      )
    : 0;
