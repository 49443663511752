import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import avaliation from "@/assets/illustrations/avaliation-success.svg";

type SucessModalProps = {
  onClose: () => void;
};

export function SucessModal({ onClose }: SucessModalProps) {
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col items-center gap-6">
      <img src={avaliation} width={128} height={128} alt="avaliation" />

      <div className="flex flex-col items-center gap-1">
        <Text size="md" className="text-center" color="slate-700">
          {t("avaliationResearch.Muito obrigado!")}
        </Text>
        <Text size="sm" color="slate-400" className="text-center">
          {t("avaliationResearch.Sua avaliação foi recebida com sucesso.")}
        </Text>
      </div>

      <Button full onClick={onClose} size="lg" className="max-w-md">
        {t("general.Concluir")}
      </Button>
    </div>
  );
}
