import { Text, cn } from "@astrolabe-ui/react";
import { CaretRight } from "@phosphor-icons/react";

export const Breadcrumble = ({
  stack,
  show = 1,
  className,
}: {
  stack: Array<{ text: string; action?: () => void; className?: string }>;
  show: number;
  className?: string;
}) => {
  return (
    <div className={cn(`flex flex-wrap items-center gap-1 ${className}`)}>
      {stack
        .filter((_, index) => index < show)
        .map((el, index) => (
          <div
            key={index}
            className="flex items-center justify-center gap-1 truncate"
            {...(el.action && { onClick: el.action })}
          >
            {index !== 0 && <CaretRight size={12} weight="bold" className="text-slate-500" />}
            <Text color="slate-500">{el.text}</Text>
          </div>
        ))}
    </div>
  );
};
