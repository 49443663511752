import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";

import { BlockItem } from "@/components/old/BlockItem";
import { PageTitle } from "@/components/old/SideMenu";
import { getServicesV3 } from "@/services/old/servicos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { useSendClickService } from "../../hooks/useSendClickService";
import { useAppId } from "@/store/useAppStore";

interface paramsPage {
  SLUG: string;
}

export function TabServiceListExpand() {
  const location = useLocation();
  const params: paramsPage = useParams();
  const CONTENT = getContent();

  const queryParams = new URLSearchParams(location.search);

  const appId = useAppId();

  const { isLoading, data } = useQuery(["getservicos"], () =>
    getServicesV3({
      slug: params.SLUG,
      aggregatorOnly: queryParams.get("aggregator_only"),
      aggregatorExcept: queryParams.get("aggregator_except"),
    }),
  );

  const { mutateAsync } = useSendClickService();

  function sendClickService(id: number) {
    mutateAsync({
      appId: Number(appId),
      serviceId: id,
    });
  }

  if (isLoading || !data) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING}</PageTitle>;
  }

  if (!data || data?.data.length === 0) {
    return (
      <>
        <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
      </>
    );
  }

  return (
    <>
      {/* <PageTitle> {CONTENT.SERVICES.PAGE_TITLE} </PageTitle> */}
      <IonGrid style={{ width: "100%", paddingBottom: "16px" }}>
        <IonAccordionGroup expand="inset">
          {data?.data.map((area: any, index: number) => {
            return (
              <div key={index}>
                <IonAccordion
                  mode="md"
                  value={area.titulo}
                  style={{
                    marginBottom: "20px",
                    borderRadius: "6px",
                    // backgroundColor: "white"
                  }}
                >
                  <IonItem
                    slot="header"
                    color="transparent"
                    lines="none"
                    style={{
                      backgroundColor: "white",
                      borderBottom: "none",
                    }}
                  >
                    {area.imagem ? (
                      <IonThumbnail slot="start">
                        <img alt="Silhouette of mountains" src={area.imagem} />{" "}
                      </IonThumbnail>
                    ) : null}
                    <IonLabel>{area.titulo}</IonLabel>
                  </IonItem>
                  <div
                    className="ion-padding"
                    slot="content"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#ffffff70",
                      borderRadius: "0px 0px 6px 6px",
                    }}
                  >
                    <IonRow style={{ padding: "0 8px" }}>
                      {area.categorias?.map((categoriaItem: any, categoriaIndex: number) => {
                        return (
                          <IonCol
                            size={area.categorias.length === 1 ? "12" : "6"}
                            key={categoriaIndex}
                          >
                            {categoriaItem.integracao && categoriaItem.abrir_externo && (
                              <BlockItem
                                openNewWindow={true}
                                href={categoriaItem.integracao}
                                customImage={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                                type={categoriaItem.abrir_externo ? "link_externo" : "link_interno"}
                                onClick={() => sendClickService(categoriaItem.id)}
                              />
                            )}
                            {categoriaItem.integracao && !categoriaItem.abrir_externo && (
                              <BlockItem
                                routerLink={
                                  "/" + getAppSlug() + `/services-abrir-externo/${categoriaItem.id}`
                                }
                                customImage={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                                onClick={() => sendClickService(categoriaItem.id)}
                              />
                            )}
                            {!categoriaItem.integracao && !categoriaItem.abrir_externo && (
                              <BlockItem
                                routerLink={
                                  "/" + getAppSlug() + `/services-abrir/${categoriaItem.id}`
                                }
                                customImage={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                                onClick={() => sendClickService(categoriaItem.id)}
                              />
                            )}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                  </div>
                </IonAccordion>
              </div>
            );
          })}
        </IonAccordionGroup>
      </IonGrid>
    </>
  );
}
