import { useState } from "react";
import { IonModal, IonRippleEffect } from "@ionic/react";
import { CaretRight } from "@phosphor-icons/react";

import { Container, ContainerModal, Content, ThemeBadge, Themes } from "./styles";
import { Theme } from "../../../proposals/hooks/use-proposals";

interface SelectThemeProps {
  value?: Theme;
  onChange: (theme: Theme) => void;
  themes: Array<Theme>;
}

export function SelectTheme({ value, onChange, themes }: SelectThemeProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm text-slate-700">Informe o tema</label>

        <Container hasData={!!value} onClick={() => setIsOpenModal(true)}>
          <span>{value?.title ?? "Selecione o tema"}</span>
          <CaretRight size={16} weight="bold" color="#94A3B8" />

          <IonRippleEffect style={{ color: "#94A3B8" }} />
        </Container>
      </div>

      <IonModal
        isOpen={isOpenModal}
        onDidDismiss={() => handleCloseModal()}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        <ContainerModal>
          <Content>
            <strong>Selecione o tema</strong>

            <Themes>
              {themes.map((item) => (
                <ThemeBadge
                  key={item.id}
                  selected={value?.id === item.id}
                  onClick={() => {
                    onChange(item);
                    handleCloseModal();
                  }}
                >
                  {item.title}
                </ThemeBadge>
              ))}
            </Themes>
          </Content>
        </ContainerModal>
      </IonModal>
    </>
  );
}
