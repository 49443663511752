import { STORAGE } from "./storage";

export const COLORS = {
  PRIMARY: localStorage.getItem(STORAGE.PRIMARY_COLOR.KEY) || "#0066ff",
  SECONDARY: localStorage.getItem(STORAGE.SECONDARY_COLOR.KEY) || "#0066ff",
  INPUTCOLOR: localStorage.getItem(STORAGE.BUTTONS_COLOR.KEY) || "#0066ff",
  BACKGROUND_COLOR:
    localStorage.getItem(STORAGE.BACKGROUND_COLOR.KEY) ||
    localStorage.getItem(STORAGE.PRIMARY_COLOR.KEY),
  LIGHT: "#ffffff",
  BORDER: "#dcdcdc",
};

export function getBackgroundColor(): string {
  const bgcolor = localStorage.getItem(STORAGE.BACKGROUND_COLOR.KEY);
  return bgcolor || "#FFFFFF";
}
