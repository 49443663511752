import dayjs from "dayjs";
import { IDataAssetsScheduling } from "../..";

export const selectNext = (
  time: string,
  timeSelected: string[],
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  dayjs(time, "HH:mm").isAfter(dayjs(timeSelected[0], "HH:mm")) &&
  dayjs(time, "HH:mm").diff(dayjs(timeSelected[0], "HH:mm"), "minute") <=
    (dataAssetsScheduling?.extra_fields?.max as number);
