import { IonFab, IonFabButton, IonIcon, IonPage, IonContent } from "@ionic/react";
import { add } from "ionicons/icons";

import { Header } from "@/components/old/Header";
import { PageTitle } from "@/components/old/SideMenu";
import { PageContent } from "@/components/old/Base";
import { getBackgroundColor } from "@/data/colors";
import { getContent } from "@/content/index";

interface EmptyContentPageTemplateProps {
  name: string;
  hideHeaderUserButton?: boolean;
  backPress?: any;
  onSearchTextChange?: (search: string) => void;
  showAddButton?: boolean;
  onAddButtonClick?: () => void;
}

export function EmptyContentPageTemplate(props: EmptyContentPageTemplateProps) {
  const CONTENT = getContent();

  return (
    <IonPage>
      <Header
        name={props.name}
        backPress={props.backPress}
        onSearchTextChange={props.onSearchTextChange}
        hideUserButton={props.hideHeaderUserButton}
      />

      <IonContent fullscreen style={{ "--background": getBackgroundColor() }}>
        <PageContent>
          <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>

          {props.showAddButton && (
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton onClick={props.onAddButtonClick}>
                <IonIcon icon={add} />
              </IonFabButton>
            </IonFab>
          )}
        </PageContent>
      </IonContent>
    </IonPage>
  );
}
