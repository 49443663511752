import * as RadioGroup from "@radix-ui/react-radio-group";
import { useFormContext } from "react-hook-form";
import { CheckFat } from "@phosphor-icons/react";

type DeficiencyFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

export function DeficiencyField({ value, onChange }: DeficiencyFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors.enabledDeficiency?.message
    ? String(errors.enabledDeficiency?.message)
    : undefined;

  const errorOrther = errors.deficiency?.message ? String(errors.deficiency?.message) : undefined;

  return (
    <div className="flex flex-col gap-4 ">
      <label className="text-sm leading-normal text-slate-700">
        Você possui alguma deficiência?
      </label>

      <RadioGroup.Root value={value} onValueChange={onChange} className="grid grid-cols-2 gap-3">
        {["Sim", "Não"].map((item) => (
          <div className="flex items-center gap-2" key={item}>
            <RadioGroup.Item
              className="flex h-5 w-5 cursor-pointer items-center justify-center overflow-hidden rounded-xs border border-slate-200 bg-white data-[state='checked']:border-primary-500 data-[state='checked']:bg-primary-500"
              value={item}
              id={item}
            >
              <RadioGroup.Indicator asChild className="flex items-center justify-center">
                <CheckFat weight="fill" size={12} className="text-white" />
              </RadioGroup.Indicator>
            </RadioGroup.Item>

            <label className="text-sm leading-normal text-slate-400" htmlFor={item}>
              {item}
            </label>
          </div>
        ))}
      </RadioGroup.Root>

      {error ? <label className="text-sm leading-normal text-red-500">{error}</label> : null}

      {value === "Sim" ? (
        <div className="flex flex-col gap-1.5">
          <label className="text-sm leading-normal text-slate-700" htmlFor="ortherDeficiency">
            Informe qual
          </label>

          <input
            id="deficiency"
            type="text"
            placeholder="Digite aqui"
            className="h-12 w-full rounded-md border border-slate-200 bg-white px-3 py-4 text-sm leading-normal text-slate-700 outline-none placeholder:text-slate-400 focus:border-primary-800"
            maxLength={30}
            {...register("deficiency", {
              required: {
                value: true,
                message: "Informe para prosseguir",
              },
            })}
          />

          {errorOrther ? (
            <label className="text-sm leading-normal text-red-500">{errorOrther}</label>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
