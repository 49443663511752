import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IonButton } from "@ionic/react";

import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockFormItem } from "@/pages/old/Forms/components/BlockFormItem";
import { getForms } from "@/services/old/forms";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}
export function Forms() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getForms"],
    ({ pageParam = 1 }) => getForms(pageParam, params.SLUG),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.data.next_page_url + "").split("page=")[1];
      },
    },
  );

  function loadMore() {
    fetchNextPage();
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FORM.HEADER}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data || data?.pages[0].data.data.length === 0) {
    return <EmptyContentPageTemplate name={CONTENT.FORM.HEADER} />;
  }

  return (
    <PageTemplate name={CONTENT.FORM.HEADER}>
      {data?.pages.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            {item.data.data.map((item: any, index: number) => {
              return (
                <BlockFormItem
                  key={index}
                  title={item.titulo}
                  answered={item.ja_respondeu}
                  description={item.descricao}
                  dateStart={item.created_at}
                  dateEnd={item.dataencerramento}
                  openURL={"/" + getAppSlug() + "/form-open/" + item.id}
                />
              );
            })}
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
