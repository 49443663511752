export function setColors(primary: string | null, secondary: string | null) {
  if (!primary) {
    primary = "#890ed3";
  }

  if (!secondary) {
    secondary = "#c09ee3";
  }

  const root = document.documentElement;

  const chromeColor = document.querySelector("meta[name=theme-color]");
  chromeColor?.setAttribute("content", primary);

  root.style.setProperty("--ion-color-primary", primary);
  root.style.setProperty("--ion-color-secondary", secondary);
}

export function setBackgroundColor() {
  const root = document.documentElement;
  root.style.setProperty("--ion-background-color", "transparent");
}

export function setInputBackgroundColor(color: string | null) {
  const root = document.documentElement;

  if (!color) {
    color = "#1d2d91";
  }

  root.style.setProperty("--ion-item-background", color);
}

export function setHeaderColor(color: string | null, colorText: string | null) {
  const root = document.documentElement;

  if (!color) {
    color = "#af40ff";
  }

  if (!colorText) {
    colorText = "#000000";
  }

  root.style.setProperty("--ion-color-tertiary", color);
  root.style.setProperty("--ion-color-textheader", colorText);
}
