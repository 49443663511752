interface HTMLRenderProps {
  HTML_CONTENT: string;
}

export function HTMLRender(props: HTMLRenderProps) {
  function removeIframe(content: string) {
    if (content) {
      content = content.replace("&lt;iframe", "<iframe");
      return content.replace("&gt;&lt;/iframe&gt;", "></iframe>");
    }
    return "";
  }

  return (
    <div
      className="[&_a]:text-blue-600 [&_a]:underline"
      dangerouslySetInnerHTML={{ __html: removeIframe(props.HTML_CONTENT) }}
    />
  );
}
