import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.25rem 1rem max(env(safe-area-inset-bottom), 1rem);
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;

    strong {
      font-size: 1rem;
      font-weight: 500;
      color: #334155;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      line-height: 160%;
      color: #94a3b8;
    }
  }
`;

export const ListTerritories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    strong {
      font-size: 0.875rem;
      font-weight: 500;
      color: #334155;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      line-height: 160%;
      color: #94a3b8;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 40px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  & > small {
    font-size: 0.75rem;
    font-weight: 500;
    color: #94a3b8;
  }
`;

export const Badge = styled.span`
  padding: 0.325rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #0cccd5;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    flex-shrink: 0;
  }
`;

export const Button = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: auto;
  width: 100%;

  padding: 0.875rem 1rem;
  background-color: ${COLORS.PRIMARY};
  border-radius: 0.5rem;

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #cbd5e1;
  }
`;
