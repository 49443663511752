import { http } from "@/lib/axios";

interface sendClickServiceParams {
  appId: number;
  serviceId: number;
}

export async function sendClickService({ appId, serviceId }: sendClickServiceParams) {
  return http.post(`v3/${appId}/registrar-click`, {
    id: serviceId,
    type: "categoria_servico",
  });
}
