import React from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useQueryClient } from "@tanstack/react-query";
import { IonImg, IonNote, IonRouterLink, IonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { Heart } from "@phosphor-icons/react";

import { Block } from "@/components/old/Block";
import { IconButton } from "@/components/IconButton";
import { dateToHourFullDate } from "@/services/old/functions";
import { COLORS } from "@/data/colors";
import { useAppId } from "@/store/useAppStore";
import { useLike } from "../hooks/useLike";

const Post = styled.div`
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

interface PostImageProps {
  image?: string;
}

const PostImage = styled(IonRouterLink)<PostImageProps>`
  max-width: 300px;
  width: 100%;

  background-color: ${COLORS.SECONDARY};

  ${(props) =>
    props.image &&
    css`
      background-image: url("${props.image}");
      background-size: cover;
      background-position: center center;
    `}

  @media(max-width: 550px) {
    max-width: 100%;
    height: 180px;
  }

  display: flex;
`;

const PostInfo = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
`;

const PostTitle = styled.h1`
  margin: 0 0 0 0;
  font-size: 1.4em;

  @media (max-width: 700px) {
    font-size: 1.1em;
  }
`;

const PostResume = styled.p`
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const UserImage = styled.img`
  width: 31px;
  height: 31px;
  border-radius: 17px;
  margin-right: 8px;
`;

interface BlockPostProps {
  image?: string;
  title?: string;
  date?: string;
  detail?: string;
  dateEnd?: string;
  provider?: string;
  resume?: string;
  id?: string;
  openURL?: string;
  unlimitedHeigh?: boolean;
  userImg?: string;
  userName?: string;
  comments: number;
  likes: number;
  userLike: boolean;
  // eslint-disable-next-line no-undef
  onClick?: React.MouseEventHandler<HTMLIonRouterLinkElement>;
}

export function BlockPostItem(props: BlockPostProps) {
  const params = useParams<{ id: string }>();
  const appId = useAppId();
  const queryClient = useQueryClient();

  const { mutateAsync } = useLike();

  function handleLike() {
    mutateAsync(
      {
        appId: Number(appId),
        id: Number(props.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["getGalleryModulosPhotosModulo", params.id] });
        },
      },
    );
  }

  return (
    <Block noPadding>
      <Post data-testid="blockpost-container">
        {props.image && !props.unlimitedHeigh && (
          <PostImage data-testid="blockpost-image" routerLink={props.openURL} image={props.image} />
        )}

        {props.image && props.unlimitedHeigh && (
          <IonImg data-testid="blockpost-image" src={props.image} />
        )}

        <PostInfo>
          <div className="mb-2 flex items-center gap-1">
            <IconButton
              rounded="full"
              icon={<Heart weight={props.userLike ? "fill" : "regular"} className="text-red-500" />}
              size="md"
              onClick={handleLike}
            />
            <Text color="slate-700">
              {props.likes} {props.likes > 1 ? "curtidas" : "curtida"}
            </Text>
          </div>

          <IonRouterLink routerLink={props.openURL} onClick={props.onClick}>
            <div style={{ display: "flex" }}>
              {props.userImg && <UserImage src={props.userImg} />}
              <div>
                {props.userImg && !props.userName && <div style={{ height: "6px" }}></div>}
                <PostTitle data-testid="blockpost-title"> {props.title} </PostTitle>
                <IonNote> {props.userName} </IonNote>
              </div>
            </div>
          </IonRouterLink>

          {props.date && (
            <IonNote data-testid="blockpost-date">
              {props.date ? dateToHourFullDate(props.date) : ""}
              {props.dateEnd ? " à " + dateToHourFullDate(props.dateEnd) : ""}
              {props.provider && (
                <IonText data-testid="blockpost-provider"> - {props.provider} </IonText>
              )}
            </IonNote>
          )}
          {props.detail && <IonNote data-testid="blockpost-date">{props.detail}</IonNote>}

          <PostResume data-testid="blockpost-resume">{props.resume}</PostResume>

          <Text className="mb-3 ml-2">
            {props.comments} {props.comments > 1 ? "comentários... " : "comentário... "}
            <Link to={`${params.id}/posts/${props.id}`} className="text-primary-500 underline">
              ver mais
            </Link>
          </Text>
        </PostInfo>
      </Post>
    </Block>
  );
}
