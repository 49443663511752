export function checkURLIsFile(url: string) {
  try {
    const urlFormatted = new URL(url);

    const index = urlFormatted.pathname?.split("/")?.pop()?.indexOf(".");

    return index && index > 0;
  } catch (error) {
    console.error(error);
  }
}
