import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import bannerImg from "@/assets/rn-participativo/banner.png";
import { useAppSlug } from "@/store/useAppStore";

export function Banners() {
  const slug = useAppSlug();

  return (
    <div className="h-36 w-full lg:h-72">
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="h-full w-full rounded-md"
      >
        <SwiperSlide>
          <Link to={`/${slug}/consultas/6/resultados`}>
            <img src={bannerImg} alt="" className="h-full w-full object-contain" />
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
