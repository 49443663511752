import { http } from "@/lib/axios";
import { ResponseBooking } from "./types";

export async function getBookings(
  appId: number,
  cpf?: string | null,
): Promise<Array<ResponseBooking>> {
  try {
    const response = await http.get<Array<ResponseBooking>>(
      `v3/${appId}/agendamentos/consultaexterna?cpf=${cpf}`,
    );

    return response.data.map((booking) => ({
      ...booking,
      agendamento: {
        ...booking.agendamento,
        vagas_hora: booking.agendamento.vagas_hora.filter((hora) =>
          hora.id_agendados.includes(booking.id),
        ),
      },
    }));
  } catch (error) {
    console.error("error = ", error);
    throw new Error();
  }
}
