import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { BlockItem } from "@/components/old/BlockItem";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockInfoMap } from "./components/BlockMapWithMarkersInfoItem";
import { getInfoCategory } from "@/services/old/infos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function InfoCatergory() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["geInfoCategory"],
    ({ pageParam = 1 }) => getInfoCategory(pageParam, params.SLUG),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.data.next_page_url + "").split("page=")[1];
      },
    },
  );

  function loadMore() {
    fetchNextPage();
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.INFORMATION.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data || data?.pages[0].data.data.length === 0) {
    return <EmptyContentPageTemplate name={CONTENT.INFORMATION.HEADER_TITLE} />;
  }

  return (
    <PageTemplate name={CONTENT.INFORMATION.HEADER_TITLE}>
      {data?.pages.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <IonGrid style={{ width: "100%" }}>
              <IonRow>
                {item.data.data.map((item: any, index: number) => {
                  if (item.destaque && item.exibirmapa) {
                    return (
                      <IonCol key={index} size={item.destaque ? "12" : "6"}>
                        <BlockInfoMap
                          SLUG={params.SLUG}
                          key={index}
                          id={item.id}
                          title={item.titulo}
                        />
                      </IonCol>
                    );
                  } else {
                    return (
                      <IonCol key={index} size={`${item.destaque ? "12" : "6"}`}>
                        <BlockItem
                          title={item.titulo}
                          customImage={item.imagem}
                          routerLink={
                            item.informacoes?.length === 1 && !item.informacoes[0].link_servico
                              ? `/${getAppSlug()}/info-abrir/${item.informacoes[0].id}`
                              : `/${getAppSlug()}/info-category-abrir/${item.id}`
                          }
                        />
                      </IonCol>
                    );
                  }
                })}
              </IonRow>
            </IonGrid>
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
