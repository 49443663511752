import { useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { IonModal } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, X } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton, Breadcrumble, StepsBars } from "@/components";
import { useAppStore } from "@/store/useAppStore";

import { Units } from "./Units";
import { Places } from "./Places";
import { Identification } from "./Identification";
import { Justification } from "./Justification";
import { Datepage } from "./Date";
import { Time } from "./Time";
import { Review } from "./Review";

import { StepsContext } from "../contexts/StepsContext";
import { useInstitution } from "../hooks/useInstitution";

export interface IDataAssetsScheduling {
  unidade_id?: number;
  espaco_id?: number;
  hora_inicio?: string;
  hora_fim?: string;
  documento?: string;
  nome_completo?: string;
  telefone?: string;
  email?: string;
  pauta?: string;
  extra_fields?: {
    position_date?: number;
    week_number?: number;
    max?: number;
    min?: number;
    step?: number;
    date?: string;
  };
}

interface FormModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const stepsTitle = ["Local", "Espaço", "Pessoal", "Justificativa", "Data", "Hora", "Revisão"];

export const Steps = ({ isOpen, handleClose }: FormModalProps) => {
  const total = 7;
  const params = useParams<{ id: string }>();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [currentStep, setCurrentStep] = useState(0);
  const [dataAssetsScheduling, setDataAssetsScheduling] = useState<
    IDataAssetsScheduling | undefined
  >();

  const { refetch } = useInstitution({ id: Number(params.id), appId });

  const { t } = useTranslation();

  const handleNextStep = () => {
    if (currentStep < total) {
      setCurrentStep((prevState) => prevState + 1);
    } else {
      setCurrentStep(total - 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    } else {
      handleClose();
    }
  };

  const handleGoTo = (page: number) => page <= total && page >= 0 && setCurrentStep(page);

  const steps = [
    <Units key={0} />,
    <Places key={1} />,
    <Datepage key={2} />,
    <Time key={3} />,
    <Identification key={4} />,
    <Justification key={5} />,
    <Review key={6} />,
  ];

  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      <Layout>
        <StepsContext.Provider
          value={{
            currentStep,
            setCurrentStep,
            total,
            handleNextStep,
            handlePrevStep,
            handleGoTo,
            dataAssetsScheduling,
            setDataAssetsScheduling,
          }}
        >
          <Header variant="secondary">
            <div className="flex w-full items-center gap-4">
              <div className="flex-1">
                <HeaderButton
                  variant="secondary"
                  icon={<CaretLeft weight="bold" />}
                  onClick={handlePrevStep}
                />
              </div>

              <StepsBars total={total} currentStep={currentStep} />

              <div className="flex justify-end">
                <HeaderButton
                  variant="secondary"
                  icon={<X weight="bold" />}
                  onClick={() => {
                    setCurrentStep(0);
                    refetch();
                    setDataAssetsScheduling({});
                    handleClose();
                  }}
                />
              </div>
            </div>
          </Header>

          <Content>
            <div className="flex min-h-full w-full flex-col justify-between gap-6 p-4">
              <div className="flex flex-col gap-1">
                <Text weight="medium" className="text-md" color="primary-500">
                  {t("booking.Agendamento")}
                </Text>

                <div className="flex grow justify-between">
                  <Breadcrumble
                    stack={stepsTitle.map((step, index) => ({
                      text: t(`booking.${step}`),
                      action: () => handleGoTo(index),
                    }))}
                    show={currentStep + 1}
                  />
                </div>
              </div>

              <div className="flex grow flex-col justify-between">
                {steps.filter((_, index) => index === currentStep)}
              </div>
            </div>
          </Content>
        </StepsContext.Provider>
      </Layout>
    </IonModal>
  );
};
