import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IonButton, IonLoading, useIonViewDidEnter } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { ShareButton } from "@/components/old/ShareButton";
import { BlockMap } from "@/components/old/BlockMap";
import { BlockSeller } from "@/pages/old/Feirinha/components/BlockSeller";
import { BlockProduct } from "@/pages/old/Feirinha/components/BlockProduct";
import { getUserID } from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { getProductPost, productUpdateStatus } from "@/services/old/feirinha";
import { getContent } from "@/content/index";
import { Button } from "@astrolabe-ui/react";
import { useUserStore } from "@/store/useUserStore";
import { useUserToken } from "@/store/useAuthStore";

interface ProductPostParams {
  id: string;
  SLUG: string;
}

export function OpenProduct() {
  const CONTENT = getContent();

  const queryClient = useQueryClient();
  const history = useHistory();
  const userId = useUserStore((state) => state.user?.id);

  const isAuthenticated = !!useUserToken();

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const params: ProductPostParams = useParams();

  const { isLoading, data, refetch } = useQuery(
    ["getProductPost", params.id],
    () => getProductPost(params.id, params.SLUG),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  useIonViewDidEnter(() => refetch());

  function setUpCategories(newStatus: number) {
    productUpdateStatus(params.id, newStatus, params.SLUG)
      .then(() => {
        queryClient.refetchQueries(["getProductPost" + params.id]).then(
          () => {
            setShowLoadingDialog(false);
          },
          () => {
            setShowLoadingDialog(false);
          },
        );
      })
      .catch((err) => {
        console.error(err);
        setShowLoadingDialog(false);
      });
  }

  function handleOpenChat() {
    if (!isAuthenticated) {
      history.push(`/${params.SLUG}/auth/login`);
      return;
    }

    history.push(`/${params.SLUG}/comerciantes/${data?.data.comerciante.id}/conversa`);
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={data?.data.titulo}>
      <BlockProduct
        title={data?.data.titulo}
        productPrice={data?.data.valor}
        productAmount={data?.data.unidade}
        productStatus={data?.data.situacao}
        img_src={data?.data.imagem}
        sellerId={data?.data.comerciante?.id}
        description={data?.data.descricao}
      />

      {+getUserID() === data?.data.comerciante.usuario_id && (
        <Link to={`/${getAppSlug()}/novo-produto/${data?.data.id}`}>
          <IonButton
            color="primary"
            className="ion-no-padding"
            expand="block"
            disabled={data?.data.situacao === 0}
          >
            {CONTENT.FEIRINHA.EDIT_PRODUCT}
          </IonButton>
        </Link>
      )}

      {+getUserID() === data?.data.comerciante.usuario_id &&
        data?.data.situacao === 2 && ( // Produto oculto pelo comerciante pode ser enviado para visivel
          <IonButton
            color={"success"}
            className="ion-no-padding"
            expand="block"
            onClick={() => setUpCategories(1)}
          >
            {CONTENT.FEIRINHA.SEND_TO_VISIBLE}
          </IonButton>
        )}
      {+getUserID() === data?.data.comerciante.usuario_id &&
        data?.data.situacao === 3 && ( // Produto recusado pode ser enviado a revisão
          <IonButton
            color={"primary"}
            className="ion-no-padding"
            expand="block"
            onClick={() => setUpCategories(0)}
          >
            {CONTENT.FEIRINHA.SEND_TO_REVISION}
          </IonButton>
        )}
      {+getUserID() === data?.data.comerciante.usuario_id &&
        data?.data.situacao === 1 && ( // Produto disponivel e pode ser ocultado
          <IonButton
            color={"danger"}
            className="ion-no-padding"
            expand="block"
            onClick={() => setUpCategories(2)}
          >
            {CONTENT.FEIRINHA.SEND_TO_HIDE}
          </IonButton>
        )}

      <div className="mb-2 flex items-center gap-2">
        <ShareButton
          url={"https://mobby.app.br/" + params.SLUG + "/abrir-produto/" + params.id}
          disabled={data?.data.situacao === 0}
          title={data?.data.titulo + " - " + data?.data.valor + data?.data.unidade}
        />

        {data?.data.modulo.habilitar_chat && data.data.comerciante.usuario_id !== userId ? (
          <Button variant="secondary" onClick={handleOpenChat}>
            Enviar mensagem
          </Button>
        ) : null}
      </div>

      {data?.data.comerciante && (
        <BlockSeller
          sellerImage={data?.data.comerciante.imagem}
          sellerName={data?.data.comerciante.nome}
          sellerCategory={data?.data.categoria.titulo}
          facebook={data?.data.comerciante.social?.facebook}
          instagram={data?.data.comerciante.social?.instagram}
          sellerPhone={data?.data.comerciante.telefone}
          twitter={data?.data.comerciante.social?.twitter}
          youtube={data?.data.comerciante.social?.youtube}
          address={`${data?.data.comerciante.logradouro ?? ""}${
            data?.data.comerciante.numero ? "," : ""
          } ${data?.data.comerciante.numero ?? ""}${data?.data.comerciante.bairro ? "," : ""}  ${
            data?.data.comerciante.bairro?.titulo ?? ""
          }`}
          openURL={"/" + getAppSlug() + "/abrir-vendedor/" + data?.data.comerciante.id}
        />
      )}

      {data?.data.comerciante &&
        data?.data.comerciante.latitude &&
        data?.data.comerciante.longitude && (
          <BlockMap lat={data?.data.comerciante.latitude} lng={data?.data.comerciante.longitude} />
        )}
      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
