import { http } from "@/lib/axios";

interface alterEmailparams {
  email: string;
  novoEmail: string;
  password?: string;
  appId?: number;
}

export function alterEmail({ email, novoEmail, password, appId }: alterEmailparams) {
  return http.post(`v3/${appId}/usuarios/trocar_email`, { email, novoEmail, password });
}
