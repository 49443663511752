import { FileOpener } from "@capacitor-community/file-opener";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";

import { convertBlobToBase64 } from "./convertBlobToBase64";

export async function openPdfDocument(file: Blob, filename: string) {
  try {
    if (!Capacitor.isNativePlatform()) {
      const fileUrl = URL.createObjectURL(file);

      window.open(fileUrl, "_blank");

      return;
    }

    const fileInBase64 = await convertBlobToBase64(file);

    const path = Capacitor.getPlatform() === "ios" ? filename : `Download/${filename}`;

    await Filesystem.writeFile({
      path,
      data: fileInBase64,
      directory: Directory.ExternalStorage,
    });

    const { uri } = await Filesystem.getUri({
      path,
      directory: Directory.ExternalStorage,
    });

    if (uri) {
      FileOpener.open({
        filePath: uri,
        contentType: "application/pdf",
        openWithDefault: true,
      });
    }
  } catch (err) {
    console.error(err);
  }
}
