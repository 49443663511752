import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { useIonAlert } from "@ionic/react";

import { getContent } from "@/content/index";
import { Button } from "@astrolabe-ui/react";

interface ShareButtonProps {
  title?: string;
  message?: string;
  disabled?: boolean;
  url: string;
  className?: string;
}

export function ShareButton(props: ShareButtonProps) {
  const CONTENT = getContent();
  const [showAlert] = useIonAlert();

  function share() {
    try {
      if (Capacitor.isNativePlatform()) {
        Share.share({
          text: props.message,
          url: props.url,
        });
      } else {
        const newVariable = window.navigator;

        if (newVariable && newVariable.share) {
          newVariable.share({
            title: props.title,
            text: props.message,
            url: props.url,
          });
        } else {
          copyToClipboard(props.url);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function copyToClipboard(value: string) {
    window.navigator.clipboard.writeText(value);

    showAlert({
      header: CONTENT.GLOBAL.SHARE,
      message: CONTENT.GLOBAL.SHARE_MSG,
      buttons: [CONTENT.GLOBAL.DONE],
    });
  }

  return (
    <Button className={props.className} onClick={share} disabled={props.disabled}>
      Compartilhar
    </Button>
  );
}
