import dayjs from "dayjs";
import { Text } from "@astrolabe-ui/react";
import { CalendarCheck, Clock } from "@phosphor-icons/react";

type CardSchedulingProps = {
  local?: string;
  name: string;
  date?: string;
  hour?: string;
};

export function CardSchedulingInfos({ local, name, date, hour }: CardSchedulingProps) {
  return (
    <div className="flex-clol flex items-center justify-between gap-2 rounded-md border-thin border-l-medium border-slate-200 border-l-green-500 p-3">
      <div className="flex flex-col">
        <Text color="slate-700">{local}</Text>

        <Text size="xs">{name}</Text>

        <div className="mt-1 flex gap-3">
          <div className="flex items-center gap-1">
            <CalendarCheck className="flex-shrink-0 text-primary-500" />
            <Text size="xs" color="slate-700">
              {dayjs(date).format("DD MMM YY")}
            </Text>
          </div>

          <div className="flex items-center gap-1">
            <Clock className="flex-shrink-0 text-primary-500" />
            <Text size="xs" color="slate-700">
              {dayjs(hour).format("HH:mm")}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
