import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import styled from "styled-components";
import { useAtom } from "jotai/react";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { territoriesAtom } from "../atoms/territories-atom";
import { City, cities } from "../../../data/cities";

export function ComboboxText() {
  const [territories, setTerritories] = useAtom(territoriesAtom);

  const [selected, setSelected] = useState<City | null>(null);
  const [query, setQuery] = useState("");

  function handleOnChange(city: City) {
    setSelected(city);

    const territorySelected = city.territory;

    const territoryIsExits = territories?.find((item) => item.name === territorySelected);

    if (territoryIsExits && !territoryIsExits.cities.includes(city.name)) {
      const newsTerritories = territories?.map((item) => {
        if (item.name === territoryIsExits.name) {
          return {
            ...territoryIsExits,
            cities: [...territoryIsExits.cities, city.name],
          };
        } else {
          return item;
        }
      });

      setTerritories(newsTerritories);
    } else if (!territoryIsExits) {
      setTerritories([
        ...territories,
        {
          id: city.territory_code,
          name: territorySelected,
          cities: [city.name],
        },
      ]);
    }
  }

  const filteredCities =
    query === ""
      ? cities
      : cities.filter((city) =>
          city.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  useEffect(() => {
    document
      .getElementsByClassName("input-identifier")[0]
      ?.setAttribute("placeholder", "Informe a cidade");
  }, []);

  return (
    <Container>
      <Combobox value={selected} onChange={handleOnChange}>
        <Box>
          <BoxInput>
            <Input
              className="input-identifier"
              displayValue={(city: City) => city?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Button>
              <MagnifyingGlass size={20} color="#334155" />
            </Button>
          </BoxInput>

          <Options>
            {filteredCities.length === 0 && query !== "" ? (
              <NotFound>Cidade não encontrada</NotFound>
            ) : (
              filteredCities.map((city) => (
                <Option key={`${city.name}-${city.territory}`} value={city}>
                  {({ selected }) => <Text selected={selected}>{city.name}</Text>}
                </Option>
              ))
            )}
          </Options>
        </Box>
      </Combobox>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Box = styled.div`
  position: relative;
  margin-top: 0.25rem;
`;

const BoxInput = styled.div`
  position: relative;
  width: 100%;
  cursor: default;
  overflow: hidden;
  border-radius: 9999px;
  border: 2px solid #f1f5f9;
  background-color: #ffffffff;
  text-align: left;

  &:focus {
    outline: none;
  }
`;

const Input = styled(Combobox.Input)`
  width: 100%;
  border: none;
  background: transparent;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: #334155;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #94a3b8;
  }
`;

const Button = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;

  display: flex;
  align-items: center;
`;

const Options = styled(Combobox.Options)`
  position: absolute;
  margin-top: 0.25rem;
  max-height: 15rem;
  width: 100%;
  overflow: auto;
  border-radius: 0.75rem;
  background-color: #ffffff;
  padding: 0.25rem 0;

  font-size: 0.875rem;
  line-height: 150%;
`;

const Option = styled(Combobox.Option)`
  position: relative;
  cursor: default;
  user-select: none;
  padding: 0.5rem 1rem;
  cursor: pointer;

  background-color: #f8fafc;
  color: #334155;

  & + & {
    border-top: 1px solid #e2e8f0;
  }
`;

interface TextProps {
  selected?: boolean;
}

const Text = styled.span<TextProps>`
  display: block;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
`;

const NotFound = styled.div`
  position: relative;
  cursor: default;
  user-select: none;
  padding: 0.5rem 1rem;
  color: #64748b;
  background-color: #f8fafc;
`;
