import dayjs from "dayjs";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, WarningCircle } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Button, Header, HeaderButton } from "@/components";
import { CancelSchedulingModal } from "./components/cancel-scheduling-modal";
import { InstructionsModal } from "./components/instructions-modal";
import { CardInfo } from "../../components/card-info";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useScheduling } from "./hooks/use-scheduling";
import { useUserStore } from "@/store/useUserStore";
import { useUserToken } from "@/store/useAuthStore";
import { formatCpf, formatPhone } from "@/utils/formats";

const mapStatus: Record<string, JSX.Element> = {
  agendado: (
    <span className="w-fit rounded-full bg-green-100 px-5 py-1 font-sans text-xs font-medium text-green-500">
      Agendado
    </span>
  ),
  atendido: (
    <span className="w-fit rounded-full bg-green-100 px-5 py-1 font-sans text-xs font-medium text-green-500">
      Atendido
    </span>
  ),
  indeferido: (
    <span className="w-fit rounded-full bg-red-100 px-5 py-1 font-sans text-xs font-medium text-red-500">
      Indeferido
    </span>
  ),
  nao_compareceu: (
    <span className="w-fit rounded-full bg-red-100 px-5 py-1 font-sans text-xs font-medium text-red-500">
      Não compareceu
    </span>
  ),
};

export function Scheduling() {
  const navigation = useHistory();
  const { id } = useParams<{ id: string }>();

  const slug = useAppSlug();
  const isAuthenticated = !!useUserToken();
  const userId = useUserStore((state) => state?.user?.id);

  const [isOpenModal, setIsOpenModal] = useState("");

  function handleCloseModal() {
    setIsOpenModal("");
  }

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useScheduling({ appId, id });

  function handleGoBack() {
    if (!isAuthenticated) {
      navigation.replace(`/${slug}/buscar-agendamentos?cpf=${data?.cpf}`);
      return;
    }

    navigation.replace(`/${slug}/meus-agendamentos`);
  }

  const isPossibleCancelScheduling =
    userId === data?.userId && data?.status === "agendado" && dayjs().isBefore(data.hour.date);

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleGoBack}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            Agendamento #{id}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="relative mx-auto flex min-h-full w-full max-w-3xl flex-col gap-6 px-4 py-6 ios:pb-2">
          {isLoading && !data ? <ContentLoading /> : null}

          {!isLoading && data ? (
            <>
              {data.status === "indeferido" && data.justificationForRejection ? (
                <BoxJustificationForRejection justification={data.justificationForRejection} />
              ) : null}

              <CardInfo.Root>
                <CardInfo.Title>Dados de agendamento</CardInfo.Title>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Status</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{mapStatus[data.status]}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Data</CardInfo.InfoTitle>
                  <CardInfo.InfoText>
                    {dayjs(data.date.date).format("DD/MM/YYYY")}
                  </CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Hora</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{dayjs(data.hour.date).format("HH:mm")}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Serviço</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data.resource.name}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Local de atendimento</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data.local.name}</CardInfo.InfoText>
                </CardInfo.Info>

                {data.local.address ? (
                  <CardInfo.Info>
                    <CardInfo.InfoTitle>Endereço</CardInfo.InfoTitle>
                    <CardInfo.InfoText>{data.local.address.address}</CardInfo.InfoText>
                  </CardInfo.Info>
                ) : null}
              </CardInfo.Root>

              <CardInfo.Root>
                <CardInfo.Title>Dados pessoais</CardInfo.Title>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Nome</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data?.name}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>CPF</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{formatCpf(data.cpf)}</CardInfo.InfoText>
                </CardInfo.Info>

                {data.email ? (
                  <CardInfo.Info>
                    <CardInfo.InfoTitle>E-mail</CardInfo.InfoTitle>
                    <CardInfo.InfoText>{data.email}</CardInfo.InfoText>
                  </CardInfo.Info>
                ) : null}

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Celular</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{formatPhone(data.phone)}</CardInfo.InfoText>
                </CardInfo.Info>

                {data.extraFields.map((item) => (
                  <CardInfo.Info key={item.id}>
                    <CardInfo.InfoTitle>{item.field}</CardInfo.InfoTitle>

                    {item.type === "arquivo" ? (
                      <CardInfo.InfoFile>{item.answer}</CardInfo.InfoFile>
                    ) : null}

                    {item.type === "imagem" ? (
                      <CardInfo.InfoImage src={item.answer.toString()} />
                    ) : null}

                    {item.type !== "imagem" && item.type !== "arquivo" ? (
                      <CardInfo.InfoText>{item.answer}</CardInfo.InfoText>
                    ) : null}
                  </CardInfo.Info>
                ))}
              </CardInfo.Root>

              {data.questions.length ? (
                <CardInfo.Root>
                  <CardInfo.Title>Perguntas</CardInfo.Title>

                  {data.questions.map((item) => (
                    <CardInfo.Info key={item.id}>
                      <CardInfo.InfoTitle>{item.question}</CardInfo.InfoTitle>
                      <CardInfo.InfoText>{item.answer}</CardInfo.InfoText>
                    </CardInfo.Info>
                  ))}
                </CardInfo.Root>
              ) : null}

              <footer className="flex flex-col gap-2">
                {data.resource.textInstructions ? (
                  <Button
                    variant="tertiary"
                    size="lg"
                    full
                    onClick={() => setIsOpenModal("instructions")}
                  >
                    Instruções de agendamento
                  </Button>
                ) : null}

                {isPossibleCancelScheduling ? (
                  <Button
                    variant="secondary"
                    size="lg"
                    className="underline"
                    onClick={() => setIsOpenModal("cancel")}
                  >
                    Cancelar agendamento
                  </Button>
                ) : null}

                <Button size="lg" full onClick={() => window.print()}>
                  Imprimir comprovante
                </Button>
              </footer>

              <CancelSchedulingModal
                scheduling={data}
                isOpen={isOpenModal === "cancel"}
                onClose={handleCloseModal}
              />

              {data.resource.textInstructions ? (
                <InstructionsModal
                  isOpen={isOpenModal === "instructions"}
                  onClose={handleCloseModal}
                  textInstructions={data.resource.textInstructions}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}

function ContentLoading() {
  return (
    <div className="flex flex-col gap-6">
      {Array.from(Array(3).keys()).map((index) => (
        <IonSkeletonText key={index} animated className="h-[400px] w-full bg-slate-50" />
      ))}
    </div>
  );
}

function BoxJustificationForRejection({ justification }: { justification: string }) {
  return (
    <div className="flex flex-col gap-2 rounded-xs bg-red-500/10 px-5 py-4">
      <div className="flex items-center gap-1">
        <WarningCircle weight="fill" className="text-red-500" />
        <Text weight="medium" color="red-500">
          Justificativa para indeferimento
        </Text>
      </div>

      <Text color="slate-700">{justification}</Text>
    </div>
  );
}
