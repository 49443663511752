import dayjs from "dayjs";

import { ResponseInstitution } from "@/services/presentialScheduling";
import { getData, getSector } from "../../Review/utils/getOutputViewValues";

interface getDateSelectedProps {
  institution: ResponseInstitution;
  sectorId: number;
  serviceId: number;
  bookingId: number;
}

export function getDateSelected({
  institution,
  sectorId,
  serviceId,
  bookingId,
}: getDateSelectedProps) {
  const sector = getSector({
    id: sectorId,
    institution,
  });

  if (!sector) {
    return;
  }

  const booking = getData({
    id: bookingId,
    serviceId,
    sector,
  });

  if (!booking?.data_aplicacao) {
    return;
  }

  return dayjs(booking.data_aplicacao).format("dddd[,] DD [de] MMMM [de] YYYY");
}
