import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { IonPage } from "@ionic/react";
import { ArrowRight, CaretLeft, ChartBar, File, UsersThree } from "@phosphor-icons/react";

import { Container, BlockText, Faq } from "./styles";

import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { MainContent } from "@/templates/old/ppa/MainContent";
import { Accordion } from "./components/accordion/index";

import logoPPA from "@/assets/images/logo-ppa.png";
import imgPPA from "@/assets/images/ppa.png";
import { openFileInBrowser } from "@/utils/openFileInBrowser";

export function PPA() {
  const { SLUG } = useParams<{ SLUG: string }>();

  const history = useHistory();

  function handleGoback() {
    history.replace(`/${SLUG}`);
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoback} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="PPA RN" />
        </NavLink>
      </Header>

      <MainContent>
        <Container>
          <img src={imgPPA} alt="PPA RN" />

          <BlockText>
            <strong>O que é o Plano Plurianual - PPA?</strong>
            <p>
              O Plano Plurianual, mais conhecido como PPA, é um instrumento de Planejamento do Poder
              Executivo. Possui vigência de quatro anos e tem como função estabelecer as diretrizes,
              objetivos e metas de médio prazo da Administração Pública. Nele constam
              detalhadamente, os atributos das políticas públicas a serem executadas, públicos-alvo
              e entregas previstas. Em 2023, o PPA será elaborado com apoio aberto da população por
              meio da plataforma RN Participativo e Brasil Participativo.
            </p>
          </BlockText>

          <BlockText>
            <strong>Por que sua contribuição é importante?</strong>
            <p>
              A contribuição na elaboração do Plano Plurianual em 2023 é importante para ajudar na
              construção de um futuro com garantia dos direitos públicos. Por meio da sua
              participação, os Governos Federal e Estadual poderão avançar na formação de políticas
              públicas eficientes e eficazes visando o desenvolvimento da sua região.
            </p>
          </BlockText>

          <BlockText>
            <strong>Como posso participar?</strong>
            <p>
              O processo é simples, basta apenas: escolher os eixos do PPA que você possui mais
              interesse em realizar a consulta, inserir seus dados pessoais e algumas informações
              adicionais e depois aprovar ou não, as propostas de um eixo.
            </p>
          </BlockText>

          <Faq>
            <header>
              <strong>Conheça os eixos</strong>
              <span>Estes serão os eixos disponíveis para consulta.</span>
            </header>

            <div>
              <Accordion
                title="Eixo I - Desenvolvimento Governamental e Gestão Pública Transparente e Eficiente"
                content="
                <p>
                No Eixo “Desenvolvimento Governamental e Gestão Pública Transparente e Eficiente”, apresentamos as diretrizes para a atuação do Governo do Estado no próximo quadriênio. 
                <br><br>Para que isso se consolide, precisamos aperfeiçoar uma governança participativa, transparente e com controle social; consolidar os mecanismos de diálogo permanente com os órgãos de controle, a sociedade e a iniciativa privada; e prosseguir com a modernização da gestão administrativa, com forte incremento tecnológico, para mais eficiência, eficácia e efetividade na implementação e na gestão das políticas públicas. 
                <br><br>Nessa perspectiva, faz-se importante a ampliação das políticas de gestão de pessoas que valorizem os servidores e servidoras, com uma política salarial justa, com formação, capacitação e qualificação contínua, em consonância com a política de capacitação da Escola de Governo, e da Escola de Tecnologia da Informação a Gestão Pública. 
                <br><br>Agora, mais do que nunca, uma janela de oportunidades se abre para o Rio Grande do Norte. A candidatura de Lula Presidente e a nossa reeleição trazem novos horizontes para o povo potiguar.                
                </p>
                "
              />

              <Accordion
                title="Eixo II - Desenvolvimento Sustentável e Integração Regional e Metropolitana"
                content="
                <p>
                O Eixo “Desenvolvimento Sustentável e Integração Regional e Metropolitana” apresenta as diretrizes orientadoras para o processo de consolidação das ações do Governo do Estado para a integração do território potiguar, iniciado em 2019. 
                <br><br>As diretrizes buscam fortalecer a infraestrutura terrestre e hídrica do RN, promover a integração física das nossas regiões e coordenar o planejamento das cidades, tendo como premissa o respeito ao meio ambiente para um desenvolvimento urbano e regional sólido e sustentável.                
                </p>"
              />

              <Accordion
                title="Eixo III - Desenvolvimento Humano e Social e Segurança Pública"
                content="
                <p>
                O Eixo “Desenvolvimento Humano e Social e Segurança Pública”, apresenta as diretrizes do governo para a implementação de políticas públicas com foco nas pessoas, tendo como premissas as garantias dos direitos fundamentais das cidadãs e dos cidadãos do Rio Grande do Norte, e um dos nossos principais desafios: a diminuição da desigualdade social.
                <br><br>Impulsionaremos ainda mais as políticas públicas para as mulheres, negros e negras, pessoas com deficiência, jovens e comunidade LGBTI+. As populações indígenas, quilombolas, povos e comunidades tradicionais, população em situação de rua e itinerantes terão ainda mais espaço no Governo do Estado do Rio Grande do Norte, a partir da garantia de recursos para políticas, planos, programas e projetos de inclusão.
                <br><br>Nosso compromisso com a educação, a segurança, a saúde e a assistência social, na nova gestão, ganham outras perspectivas, pois estamos arrumando a casa, atraímos mais investimentos, propiciando um ambiente de confiança aos investidores.
                <br><br>As diretrizes buscam renovar, também, o compromisso com a justiça social e inclusão com direitos, desenvolvimento, trabalho, renda, moradia adequada, segurança alimentar, combate à fome, ao desemprego e à miséria. A garantia dos direitos humanos, da cultura e o reconhecimento da diversidade, a partir da implementação de políticas públicas, é mais um compromisso renovado com a população potiguar.
                </p>"
              />

              <Accordion
                title="Eixo IV - Desenvolvimento Econômico Sustentável"
                content="
                <p>
                O Eixo “Desenvolvimento Econômico Sustentável” apresenta as diretrizes para que o Governo do Estado do Rio Grande do Norte possa implementar ações que assegurem bem-estar, desenvolvimento, igualdade e justiça social à população, a partir do fomento à economia por meio do incentivo à indústria, às energias renováveis, ao comércio e serviços, ao turismo, à ciência, à tecnologia, à inovação e ao desenvolvimento agrário e rural.
                <br><br>Nossas diretrizes objetivam a valorização das potencialidades econômicas locais e a melhoria da vida das pessoas que vivem em terra potiguar. Acreditamos que esses novos investimentos proporcionarão desenvolvimento econômico sustentável para, consequentemente, combater a pobreza e a fome, por meio da geração de emprego e renda.
                </p>"
              />
            </div>
          </Faq>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <strong className="text-sm font-medium leading-normal text-slate-700 lg:text-md">
                Como foi o PPA?
              </strong>
              <span className="text-sm leading-normal text-slate-400 lg:text-md">
                Acompanhe as propostas solicitadas e votadas, como também os PPA dos anos
                anteriores.
              </span>
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <header className="flex items-center gap-2">
                  <ChartBar size={24} color="#1e408a" />
                  <strong className="text-sm font-medium text-primary-500 lg:text-md">
                    Ranking
                  </strong>
                </header>

                <div className="flex flex-col items-start gap-2">
                  <span className="text-sm font-medium leading-normal text-slate-700 lg:text-md">
                    Veja a listagem das propostas mais votadas pelo aplicativo.
                  </span>

                  <NavLink
                    to={`/${SLUG}/ppa/propostas?order=topRated`}
                    className="flex cursor-pointer items-center justify-center gap-2 text-sm font-semibold text-primary-500"
                  >
                    Acessar
                    <ArrowRight size={14} weight="bold" />
                  </NavLink>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <header className="flex items-center gap-2">
                  <UsersThree size={24} color="#1e408a" />
                  <strong className="text-sm font-medium text-primary-500 lg:text-md">
                    Propostas presenciais
                  </strong>
                </header>

                <div className="flex flex-col items-start gap-2">
                  <span className="text-sm font-medium leading-normal text-slate-700 lg:text-md">
                    Veja a listagem das propostas coletadas nas plenárias presenciais.
                  </span>

                  <div
                    onClick={() =>
                      openFileInBrowser(
                        "https://grt8-devs-assets.s3.sa-east-1.amazonaws.com/3+3_Demandas_Plenarias_SETORIAIS+(1).pdf",
                      )
                    }
                    className="flex cursor-pointer items-center justify-center gap-2 text-sm font-semibold text-primary-500"
                  >
                    Acessar
                    <ArrowRight size={14} weight="bold" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <header className="flex items-center gap-2">
                  <File size={24} color="#1e408a" />
                  <strong className="text-sm font-medium text-primary-500 lg:text-md">
                    Informações
                  </strong>
                </header>

                <div className="flex flex-col items-start gap-2">
                  <span className="text-sm font-medium leading-normal text-slate-700 lg:text-md">
                    Visualize todos os documentos e informações importantes sobre o projeto.
                  </span>

                  <NavLink
                    to={`/${SLUG}/info-category-abrir/328`}
                    className="flex cursor-pointer items-center justify-center gap-2 text-sm font-semibold text-primary-500"
                  >
                    Acessar
                    <ArrowRight size={14} weight="bold" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </IonPage>
  );
}
