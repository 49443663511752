import { cn } from "@/lib/utils";

interface StepsBarsProps {
  total: number;
  currentStep: number;
  getIndexStep?: (index: number) => void;
}

export function StepsBars({ total, currentStep, getIndexStep }: StepsBarsProps) {
  const steps = new Array(total).fill(null);
  return (
    <div className="flex w-full justify-between gap-1" key={currentStep}>
      {steps.map((_, index) => (
        <div
          key={index}
          onClick={() => getIndexStep && getIndexStep(index)}
          className={cn("flex h-[5px] grow rounded-md bg-slate-200", {
            "bg-primary-500": currentStep >= index,
          })}
        />
      ))}
    </div>
  );
}
