import { useState } from "react";
import styled from "styled-components";
import { darken, transparentize } from "polished";
import { IonContent, IonPage, IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";

import { Header } from "@/components/old/Header";
import { PageContent } from "@/components/old/Base";
import { COLORS, getBackgroundColor } from "@/data/colors";

const SegmentWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 4px ${transparentize(0.85, darken(0.4, COLORS.PRIMARY))};
`;

const Content = styled(IonContent)`
  background-color: ${COLORS.BACKGROUND_COLOR};
`;

const Topdiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 4px ${transparentize(0.85, darken(0.4, COLORS.PRIMARY))};
`;

interface PageTemplateProps {
  name: string;
  backgroud?: string;
  first?: {
    value: string;
    content: any;
  };
  second?: {
    value: string;
    content: any;
  };
  third?: {
    value: string;
    content: any;
  };
  selected?: any;
}

export function TabTemplate(props: PageTemplateProps) {
  const [selected, setSelected] = useState(props.selected ? props.selected : props.first?.value);

  return (
    <IonPage style={{ "--background": getBackgroundColor() }}>
      <Topdiv>
        <Header name={props.name} />

        <SegmentWrapper>
          <IonSegment mode="md" value={selected} onIonChange={(e) => setSelected(e.detail.value)}>
            <IonSegmentButton value={props.first?.value}>
              <IonLabel> {props.first?.value} </IonLabel>
            </IonSegmentButton>

            {props.second && (
              <IonSegmentButton value={props.second.value}>
                <IonLabel> {props.second.value} </IonLabel>
              </IonSegmentButton>
            )}

            {props.third && (
              <IonSegmentButton value={props.third.value}>
                <IonLabel> {props.third.value} </IonLabel>
              </IonSegmentButton>
            )}
          </IonSegment>
        </SegmentWrapper>
      </Topdiv>

      <Content id="main-content" style={{ "--background": getBackgroundColor() }}>
        <PageContent style={{ padding: "0", height: "100%" }}>
          {selected === props.first?.value && props.first?.content}
          {selected === props.second?.value && props.second?.content}
          {selected === props.third?.value && props.second?.content}
        </PageContent>
      </Content>
    </IonPage>
  );
}
