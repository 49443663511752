import { useState } from "react";
import { IonToast } from "@ionic/react";
import { copyOutline, documentOutline } from "ionicons/icons";

import {
  Container,
  Content,
  ContentCopy,
  ContentId,
  ContentStatus,
  ContentTitle,
  DescriptionFiles,
  FilesPreview,
  IconCopy,
  IconFilesPreview,
  Id,
  Image,
  ImagePreview,
  LinkFiles,
  RowDescription,
  RowStatusId,
  RowTitle,
  Status,
  TextCopy,
  TextIconCopy,
  Title,
} from "./styles";
import { BlockMap } from "@/components/old/BlockMap";

interface BlockItemProtocolsDetailsListProps {
  type?: string;
  title: string;
  description: string;
  descriptionFile?: string;
  lat?: number;
  long?: number;
}

export function BlockItemProtocolsDetailsList(props: BlockItemProtocolsDetailsListProps) {
  const [toast, setToast] = useState(false);
  const status: any = props.type === "status" && statusColor(props.description);

  function statusColor(status: string) {
    let statusValues = {};

    switch (status) {
      case "Em Aberto":
        statusValues = {
          decription: "Aberto",
          styleStatus: {
            backgroundColor: "#2563eb1a",
            color: "#2563EB",
          },
          styleContent: {
            borderLeft: "2px solid #2563EB",
          },
        };
        break;
      case "Em Atendimento":
        statusValues = {
          decription: "Atendimento",
          styleStatus: {
            backgroundColor: "#f779061a",
            color: "#F67906",
          },
          styleContent: {
            borderLeft: "2px solid #F67906",
          },
        };
        break;
      case "Encerrado":
        statusValues = {
          decription: "Concluído",
          styleStatus: {
            backgroundColor: "#16a34a1a",
            color: "#16A34A",
          },
          styleContent: {
            borderLeft: "2px solid #16A34A",
          },
        };
        break;
      default:
        statusValues = {
          decription: status,
          styleStatus: {
            backgroundColor: "#f3f4f6",
            color: "#4b5563",
          },
          styleContent: {
            borderLeft: "2px solid #4b5563",
          },
        };
        break;
    }

    return statusValues;
  }

  function copyId(e: any) {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(props.description.toString());
    setToast(true);
  }

  function formatDescriptiveDate(date: string) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date));
  }

  return (
    <Container>
      <Content>
        <RowTitle>
          <ContentTitle>
            <Title>{props.title}</Title>
          </ContentTitle>
        </RowTitle>
        <RowDescription>
          {(props.type === "default" || !props.type) && props.description}
          {props.type === "code" && (
            <ContentId>
              <Id>{props.description}</Id>
              <ContentCopy>
                <TextIconCopy onClick={(e) => copyId(e)}>
                  <TextCopy>Copiar ID</TextCopy>
                  <IconCopy icon={copyOutline} />
                </TextIconCopy>
              </ContentCopy>
            </ContentId>
          )}
          {props.type === "date" && (
            <>{props.description ? formatDescriptiveDate(props.description) : "Sem valor"}</>
          )}
          {props.type === "status" && (
            <RowStatusId>
              <ContentStatus style={status.styleStatus}>
                <Status>{status.decription}</Status>
              </ContentStatus>
            </RowStatusId>
          )}
          {props.type === "image" && (
            <LinkFiles href={props.description} target="_blank">
              <ImagePreview>
                <Image src={props.description} alt="" />
              </ImagePreview>
            </LinkFiles>
          )}
          {props.type === "file" && (
            <LinkFiles href={props.description} target="_blank">
              <FilesPreview>
                <IconFilesPreview icon={documentOutline} />
                <DescriptionFiles>{props.descriptionFile}</DescriptionFiles>
              </FilesPreview>
            </LinkFiles>
          )}
          {props.type === "map" && props.lat && props.long && (
            <BlockMap lat={props.lat} lng={props.long} hiddenTitle={true} />
          )}
        </RowDescription>
      </Content>
      <IonToast
        isOpen={toast}
        position="bottom"
        color="dark"
        onDidDismiss={() => setToast(false)}
        message="Copiado com sucesso."
        duration={3000}
      />
    </Container>
  );
}
