import { useFormContext } from "react-hook-form";
import { TextInput } from "@astrolabe-ui/react";

import { FieldProps } from "../question";

export function NumberField({ value, onChange, field }: FieldProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message ? String(errors[field.id]?.message) : undefined;

  return (
    <TextInput
      value={String(value)}
      onChange={(event) => onChange(Number(event.target.value))}
      type="number"
      size="lg"
      placeholder={field.name ?? "Resposta"}
      hint={field.hint}
      error={error}
    />
  );
}
