import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { IonPage } from "@ionic/react";

import { Header } from "@/components/old/Header";
import { getBackgroundColor } from "@/data/colors";

const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

type IframeTemplateProps = {
  src: string;
  name: string;
};

interface DownloadFileProps {
  file: Blob;
  name: string;
}

export function IframeTemplate(props: IframeTemplateProps) {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const downloadFile = useCallback(async ({ file, name }: DownloadFileProps) => {
    if (Capacitor.isNativePlatform()) {
      const fileBase64 = await convertBlobToBase64(file);

      const currentDate = new Date().getTime();

      const { uri } = await Filesystem.writeFile({
        path: `${name}-${currentDate}.pdf`,
        data: fileBase64,
        directory: Directory.Documents,
      });

      await FileOpener.open({
        filePath: uri,
        contentType: "application/pdf",
      });
    } else {
      const data = window.URL.createObjectURL(file);

      window.open(data, "_blank");

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  }, []);

  const handleLoadIframe = useCallback(() => {
    const channel = new MessageChannel();

    console.log("Canal criado", channel);

    channel.port2.onmessage = async (evt) => {
      await downloadFile(evt.data);
    };

    iframeRef.current?.contentWindow?.postMessage("", "*", [channel.port1]);
  }, [downloadFile]);

  useEffect(() => {
    if (iframeRef?.current) {
      const iframe = iframeRef.current;

      iframe.addEventListener("load", handleLoadIframe);

      return () => {
        iframe.removeEventListener("load", handleLoadIframe);
      };
    }
  }, [iframeRef, handleLoadIframe]);

  function convertBlobToBase64(data: Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result as string);

      reader.readAsDataURL(data);
    });
  }

  return (
    <IonPage style={{ backgroundColor: getBackgroundColor() }}>
      <Header name={props.name} />

      <IFrame
        ref={iframeRef}
        src={props.src}
        allow="clipboard-write"
        sandbox="allow-downloads allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation allow-forms allow-modals"
      />
    </IonPage>
  );
}
