import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Input = styled.input`
  position: relative;
  display: inline-flex;

  width: 100%;
  padding: 0.5rem 1rem;
  background: #ffffff;

  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  line-height: 150%;
  color: #334155;

  &:focus {
    border-color: ${COLORS.PRIMARY};
    outline: none;
  }

  &::placeholder {
    font-size: 0.75rem;
    color: #94a3b8;
  }
`;
