import dayjs from "dayjs";

type MyMessageProps = {
  message: string;
  time: string;
};

export function MyMessage({ message, time }: MyMessageProps) {
  return (
    <div className="flex flex-row-reverse flex-wrap gap-1 text-right font-sans text-xs text-white [&_strong]:font-semibold">
      <p
        className="rounded-b-md rounded-l-md bg-primary-500 p-2 [overflow-wrap:anywhere] [&_a]:underline"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <time dateTime={time} className="w-full text-slate-400">
        {dayjs(time).format("HH[h]mm")}
      </time>
    </div>
  );
}
