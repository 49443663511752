import styled, { css } from "styled-components";
import { COLORS } from "@/data/colors";

export const PageContent = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: 700px;
  padding: 16px;
  grid-template-columns: 1fr;
  gap: 8px;
`;

export const PageContentTransparent = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

type HorizontalAlignProps = {
  columns?: boolean;
};

export const HorizontalAlign = styled.div<HorizontalAlignProps>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.columns &&
    css`
      flex-direction: column;
    `}
`;

export const Footer = styled.div`
  padding: 10px;
  font-size: 0.8em;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;

interface SpacerProps {
  space: number;
}

export const Spacer = styled.div<SpacerProps>`
  ${(props) =>
    props.space &&
    css`
      display: block;
      width: 100%;
      height: ${props.space}px;
    `}
`;

export const Line = styled.span`
  border-bottom: solid ${COLORS.PRIMARY} 1px;
  display: block;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1em;
  margin: 20px 0 20px 0%;
`;

export const ErrorMessage = styled.span`
  color: #de2828;
  position: relative;
  font-size: 0.9em;
  top: -6px;
`;
