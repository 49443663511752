import { useQuery } from "@tanstack/react-query";

import { getCategoriesService } from "@/services/categoryServices";

type useCategoriesServiceParams = {
  appId: number;
  staleTime?: number;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export function useCategoriesService({
  appId,
  aggregatorExcept,
  aggregatorOnly,
  staleTime,
}: useCategoriesServiceParams) {
  return useQuery({
    queryKey: ["categories-service", appId, aggregatorOnly, aggregatorExcept],
    queryFn: () => getCategoriesService({ appId, aggregatorOnly, aggregatorExcept }),
    enabled: !!appId,
    ...(staleTime && { staleTime }),
  });
}
