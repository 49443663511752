import { useQuery } from "@tanstack/react-query";

import { getSubcategories } from "@/services/categoryServices";

export function useSubcategory(serviceId: number, appId: number) {
  return useQuery({
    queryKey: ["subServices", serviceId, appId],
    queryFn: () => getSubcategories(serviceId, appId),
    enabled: !!appId,
  });
}
