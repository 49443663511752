import { Map as MapLeaflet } from "leaflet";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";

import { useMapContext } from "./context";
import { MapMarker } from "./MapMarker";

export const Map = () => {
  const { center } = useMapContext();

  function handleMapCreated(map: MapLeaflet | null) {
    if (map) {
      map.invalidateSize();
    }
  }

  return (
    <MapContainer
      ref={handleMapCreated}
      center={{ lat: Number(center.lat), lng: Number(center.lng) }}
      zoom={13}
      className="z-10 grow"
      zoomControl={false}
    >
      <MapMarker />
      <ZoomControl position="topright" />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>
  );
};
