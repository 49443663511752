import { useState } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { useHistory } from "react-router-dom";
import { atom, useSetAtom } from "jotai";
import { useIonViewWillLeave } from "@ionic/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton } from "@/components";
import { UsernameForm } from "./steps/UsernameForm";
import { CodeForm } from "./steps/CodeForm";
import { PasswordForm } from "./steps/PasswordForm";
import { useAppSlug } from "@/store/useAppStore";

type FormDataType = {
  username: string;
  code: string;
  password: string;
  passwordConfirmation: string;
};

const initialValues = {
  username: "",
  code: "",
  password: "",
  passwordConfirmation: "",
};

export const forgotMyPasswordFormAtom = atom<FormDataType>(initialValues);

export function ForgotMyPassword() {
  const history = useHistory();
  const { t } = useTranslation();

  const slug = useAppSlug();

  const [step, setStep] = useState("username");
  const setForgotMyPasswordForm = useSetAtom(forgotMyPasswordFormAtom);

  function handleNextStep() {
    if (step === "username") {
      setStep("code");
    } else if (step === "code") {
      setStep("password");
    }
  }

  function handleNavigate() {
    if (step === "password") {
      setStep("code");
    } else if (step === "code") {
      setStep("username");
    } else {
      history.replace(`/${slug}/auth/login`);
    }
  }

  function handleResetProcess() {
    setStep("username");
    setForgotMyPasswordForm(initialValues);
  }

  useIonViewWillLeave(() => {
    setForgotMyPasswordForm(initialValues);
  });

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {t("auth.Redefinição de senha")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center gap-4 px-4 py-6 ios:pb-2">
          <TabsPrimitive.Root className="h-full w-full" value={step} onValueChange={setStep}>
            <TabsPrimitive.Content value="username" className="h-full w-full">
              <UsernameForm handleNextStep={handleNextStep} />
            </TabsPrimitive.Content>

            <TabsPrimitive.Content value="code" className="h-full w-full">
              <CodeForm handleNextStep={handleNextStep} />
            </TabsPrimitive.Content>

            <TabsPrimitive.Content value="password" className="h-full w-full">
              <PasswordForm handleReset={handleResetProcess} />
            </TabsPrimitive.Content>
          </TabsPrimitive.Root>
        </div>
      </Content>
    </Layout>
  );
}
