import { Text } from "@astrolabe-ui/react";

import BRImg from "@/assets/rn-participativo/parceiros/GOVBR.svg";
import BRParticipativoImg from "@/assets/rn-participativo/parceiros/BR-PARTICIPATIVO.svg";
import RNImg from "@/assets/rn-participativo/parceiros/RN.jpg";
import NotaPotiguarImg from "@/assets/rn-participativo/parceiros/NOTA-POTIGUAR.jpg";
import AnoregImg from "@/assets/rn-participativo/parceiros/Anoreg.jpg";
import SebreaImg from "@/assets/rn-participativo/parceiros/sebrae.jpg";
import SescImg from "@/assets/rn-participativo/parceiros/Sesc.jpg";
import SenacImg from "@/assets/rn-participativo/parceiros/senac.jpg";
import FecomercioImg from "@/assets/rn-participativo/parceiros/Fecomercio.jpg";
import FiernImg from "@/assets/rn-participativo/parceiros/fiern.jpg";
import FetarnImg from "@/assets/rn-participativo/parceiros/Fetarn.jpg";
import FetrafImg from "@/assets/rn-participativo/parceiros/fetraf.jpg";
import FemurnImg from "@/assets/rn-participativo/parceiros/Femurn.jpg";

export function Footer() {
  const images = [
    { img: BRImg, url: "https://www.gov.br/pt-br" },
    { img: BRParticipativoImg, url: "https://brasilparticipativo.presidencia.gov.br" },
    { img: RNImg, url: "https://www.instagram.com/governodorn/" },
    { img: NotaPotiguarImg, url: "https://notapotiguar.set.rn.gov.br/hotsite/#/home" },
    { img: AnoregImg, url: "https://anoregrn.org.br" },
    { img: SebreaImg, url: "https://sebrae.com.br/sites/PortalSebrae/" },
    { img: SescImg, url: "https://sescrn.com.br" },
    { img: SenacImg, url: "https://www.rn.senac.br" },
    { img: FecomercioImg, url: "https://fecomerciorn.com.br" },
    { img: FiernImg, url: "https://www.fiern.org.br" },
    { img: FetarnImg, url: "https://fetarn.org.br" },
    { img: FetrafImg, url: "http://www.fetrafrn.org.br" },
    { img: FemurnImg, url: "https://www.femurn.org.br" },
  ];

  return (
    <footer className="w-full bg-[#00629D] px-4 py-6">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-6">
        <Text asChild color="white" weight="medium" className="text-md lg:text-lg">
          <strong>Parceiros</strong>
        </Text>

        <div className="grid grid-cols-2 items-center gap-8 lg:grid-cols-5">
          {images.map((item) => (
            <a
              key={item.url}
              href={item.url}
              target="_blank"
              className="h-24 w-28 overflow-hidden rounded-sm bg-white p-1"
              rel="noreferrer"
            >
              <img
                src={item.img}
                alt=""
                width={112}
                height={96}
                className="h-full w-full object-contain"
              />
            </a>
          ))}
        </div>

        <Text size="sm" weight="medium" className="text-[#86D2FF]">
          Desenvolvido por Mobby Cidade&reg;
        </Text>
      </div>
    </footer>
  );
}
