import { http } from "@/lib/axios";
import { Local } from "./types";

type GetLocalsParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  schedulingId?: number;
};

type GetLocalsResponse = Array<{
  id: number;
  titulo: string;
  endereco: {
    endereco_formatado: string;
    lat: string;
    long: string;
  };
}>;

export async function getLocals({
  appId,
  entityId,
  resourceId,
  schedulingId,
}: GetLocalsParams): Promise<Array<Local>> {
  try {
    const response = await http.get<GetLocalsResponse>(
      `v3/${appId}/mobile/entidade_agendamento/${entityId}/recursos/${resourceId}/locais`,
      {
        params: {
          ...(schedulingId ? { cadastro_id: schedulingId } : {}),
        },
      },
    );

    const data = response.data;

    const locals = data.map((item) => ({
      id: item.id,
      name: item.titulo,
      address: {
        address: item.endereco.endereco_formatado,
        lat: item.endereco.lat,
        lng: item.endereco.long,
      },
    }));

    return locals;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
