import { getAppIdentifier } from "../startApp/getAppIdentifier";
import { getDataApp } from "../startApp/getDataStartApp";

import { useUserStore } from "@/store/useUserStore";

export async function updateUser() {
  try {
    const { updateUser } = useUserStore.getState();

    const slug = await getAppIdentifier();

    // TO-DO: criação de rota v3 na API para retornar dados do usuário no mesmo formato
    const { user } = await getDataApp(slug);

    if (user) {
      updateUser(user);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
