import { useQuery } from "@tanstack/react-query";
import { getInfoMedia } from "@/services/infos/get-info-media";

type UseInfoMediaParams = {
  appId: number;
  id: number;
};

export function useInfoMedia({ appId, id }: UseInfoMediaParams) {
  return useQuery({
    queryKey: ["info-media", id, appId],
    queryFn: () => getInfoMedia({ appId, id }),
    enabled: !!appId && !!id,
  });
}
