import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  InfiniteScrollCustomEvent,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import {
  Button,
  IconButton,
  RadioGroup,
  RadioGroupItem,
  Text,
  TextInput,
} from "@astrolabe-ui/react";
import { Barcode, CaretLeft, FadersHorizontal, MagnifyingGlass, X } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { FilterIonModal, Header, HeaderButton, Loading, Accordion } from "@/components";
import { useCategoriesService } from "@/pages/CategoriesServices/hooks/useCategoryServices";
import { GetProtocolsParamsFilter } from "@/services/protocols";
import { useAppStore } from "@/store/useAppStore";
import { http } from "@/lib/axios";

import { Card } from "./components/Card";
import { useProtocols } from "./hooks/useProtocols";

import emptyIllustration from "@/assets/illustrations/empty.svg";

export const Protocols = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [appId, slug] = useAppStore((state) => [state.app?.id, state.app?.slug]);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [query, setQuery] = useState<string>("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [orderParam, setOrderParam] = useState<{
    field: "id" | "titulo";
    direction: "asc" | "desc";
  }>({ field: "id", direction: "desc" });

  const [filterParams, setFilterParams] = useState<GetProtocolsParamsFilter>({
    status: [],
    areaServices: [],
  });

  const { data, isLoading, hasNextPage, fetchNextPage, refetch, isRefetching } = useProtocols({
    appId,
    filters: {
      search: query,
      ...filterParams,
    },
    orderby: `${orderParam.field}:${orderParam.direction}`,
  });

  const { data: services } = useCategoriesService({ appId, staleTime: 999999 });

  const { data: status } = useQuery({
    staleTime: 999999,
    queryFn: async () =>
      (await http.get(`v2/${appId}/status`)).data as Array<{ id: number; titulo: string }>,
    enabled: !!appId,
    queryKey: ["status", appId],
  });

  function handleNavigate() {
    history.replace(`/${slug}`);
  }

  const clearFilter = () => {
    setFilterParams((prevState) => ({
      search: prevState.search,
      areaServices: [],
      status: [],
    }));
    setOrderParam({ field: "id", direction: "desc" });
  };

  const handleFilter = async () => await refetch();

  const navigateToMessagePage = (id: string) => history.push(`/${slug}/protocols/${id}`);

  const filters = [
    {
      title: t("filters.Ordenar por") as string,
      content: (
        <RadioGroup>
          <RadioGroupItem
            id="1"
            value="1"
            checked={orderParam.field === "id" && orderParam.direction === "desc"}
            onClick={() => setOrderParam({ field: "id", direction: "desc" })}
          >
            {t("filters.Mais recentes primeiro")}
          </RadioGroupItem>
          <RadioGroupItem
            id="2"
            value="2"
            checked={orderParam.field === "id" && orderParam.direction === "asc"}
            onClick={() => setOrderParam({ field: "id", direction: "asc" })}
          >
            {t("filters.Mais antigos primeiro")}
          </RadioGroupItem>
          <RadioGroupItem
            id="3"
            value="3"
            checked={orderParam.field === "titulo" && orderParam.direction === "desc"}
            onClick={() => setOrderParam({ field: "titulo", direction: "desc" })}
          >
            {t("filters.Ordem alfabética crescente")}
          </RadioGroupItem>
          <RadioGroupItem
            id="4"
            value="4"
            checked={orderParam.field === "titulo" && orderParam.direction === "asc"}
            onClick={() => setOrderParam({ field: "titulo", direction: "asc" })}
          >
            {t("filters.Ordem alfabética decrescente")}
          </RadioGroupItem>
        </RadioGroup>
      ),
      value: "1",
    },
    {
      title: t("filters.Status") as string,
      content: (
        <RadioGroup>
          <RadioGroupItem
            id="0"
            value="0"
            checked={filterParams.status.length === 0}
            onClick={() => setFilterParams((prevState) => ({ ...prevState, status: [] }))}
          >
            {t("filters.Todos")}
          </RadioGroupItem>
          {status?.map((s) => (
            <RadioGroupItem
              key={s.id}
              id={String(s.id)}
              value={String(s.id)}
              onClick={() => {
                setFilterParams((prevState) => ({
                  ...prevState,
                  status: prevState?.status.includes(s.id)
                    ? prevState?.status.filter((sta) => sta !== s.id)
                    : [...prevState?.status, s.id],
                }));
              }}
              checked={filterParams?.status?.includes(s.id)}
            >
              {s.titulo}
            </RadioGroupItem>
          ))}
        </RadioGroup>
      ),
      value: "2",
    },
    {
      title: t("filters.Área de serviço") as string,
      content: (
        <RadioGroup>
          <RadioGroupItem
            id="0"
            value="0"
            checked={filterParams.areaServices.length === 0}
            onClick={() => setFilterParams((prevState) => ({ ...prevState, areaServices: [] }))}
          >
            {t("filters.Todos")}
          </RadioGroupItem>
          {services?.map((service, index) => (
            <RadioGroupItem
              key={index}
              id={String(index + 1)}
              value={String(index + 1)}
              checked={filterParams?.areaServices.includes(service.id)}
              onClick={() =>
                setFilterParams((prevState) => ({
                  ...prevState,
                  areaServices: prevState?.areaServices.includes(service.id)
                    ? prevState?.areaServices.filter((sta) => sta !== service.id)
                    : [...prevState?.areaServices, service.id],
                }))
              }
            >
              {service.title}
            </RadioGroupItem>
          ))}
        </RadioGroup>
      ),
      value: "3",
    },
  ];

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleNavigate} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("modules.Protocolos")}
          </Text>
        </div>
      </Header>

      <IonContent>
        <Content>
          <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
            <header className="flex items-center gap-1.5">
              <TextInput
                icon={<MagnifyingGlass className="text-slate-700" />}
                placeholder="Filtre por ID ou título do protocolo"
                value={query}
                onChange={async (e) => {
                  setQuery(e.target.value);
                }}
              />
              <IconButton
                icon={<FadersHorizontal className="text-slate-700" />}
                size="lg"
                onClick={() => setShowFilterModal(true)}
                variant="tertiary"
                rounded="full"
              />
            </header>

            <div className="flex items-center gap-1 text-slate-400">
              <Barcode size={24} />
              {isLoading || isRefetching ? (
                <Loading variant="secondary" />
              ) : (
                <Text color="slate-700" size="md">
                  {data && data?.pages[0].total > 0
                    ? `${data?.pages[0].total} ${t("general.Protocolos")}`
                    : t("modules.Nenhum protocolo")}
                </Text>
              )}
            </div>

            <div className="flex h-full flex-col gap-5">
              {isLoading || isRefetching ? (
                <div className="flex h-full flex-col items-center justify-center">
                  <Loading variant="secondary" />
                </div>
              ) : data && data.pages[0].total > 0 ? (
                <>
                  {data.pages.map((page, index) => (
                    <React.Fragment key={index}>
                      {page.protocols.map((protocol) => (
                        <Card
                          key={protocol.id}
                          // TO-DO: Remove this when the protocol has a title
                          title={protocol.title || protocol.subcategoryService.title}
                          onClickCaretRightButton={() => navigateToMessagePage(protocol.id)}
                          subtitle={protocol.created_at}
                          tag={{
                            text:
                              protocol.tagCategoryService?.title &&
                              protocol.status.title === "Em Atendimento"
                                ? protocol.tagCategoryService.title
                                : protocol.status.title,
                            variant:
                              protocol.status.title === "Em Aberto"
                                ? "primary"
                                : protocol.status.title === "Encerrado"
                                ? "success"
                                : "warning",
                          }}
                          code={protocol.id.toString()}
                          className="w-full"
                        />
                      ))}
                    </React.Fragment>
                  ))}

                  <IonInfiniteScroll
                    threshold="200px"
                    disabled={!hasNextPage}
                    onIonInfinite={async (e: InfiniteScrollCustomEvent) => {
                      await fetchNextPage();
                      e.target.complete();
                    }}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner="dots"
                      loadingText={t("general.Carregando")}
                      className="font-sans text-sm font-medium text-slate-400"
                    />
                  </IonInfiniteScroll>
                </>
              ) : (
                <div className="w-ful mt-12 flex flex-col items-center justify-center gap-6 px-4 text-center">
                  <img src={emptyIllustration} alt="empty illustration" />
                  <div className="flex flex-col gap-2">
                    <Text weight="medium" color="slate-700">
                      {t("protocols.Você não possui protocolos")}
                    </Text>

                    <Text>
                      Não foi possível encontrar protocolos, busque por ID ou título no campo acima.
                    </Text>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Content>
      </IonContent>

      <FilterIonModal isOpen={showFilterModal} onDidDismiss={() => setShowFilterModal(false)}>
        <div className="relative flex flex-col items-center justify-center px-4 pt-6 ">
          <IconButton
            icon={<X className="text-primary-500" />}
            onClick={() => setShowFilterModal(false)}
            variant="secondary"
            rounded="full"
            className="absolute left-4"
          />
          <Text color="slate-700" weight="medium" size="md">
            Filtros
          </Text>
        </div>

        <div className="mt-8 flex overflow-auto px-6">
          <Accordion
            type="multiple"
            items={filters}
            className="flex flex-wrap gap-9"
            itemClassName="w-full"
          />
        </div>

        <div className="flex flex-grow items-end">
          <footer className="sticky bottom-4 flex w-full items-center justify-between border-t-thin border-slate-200 bg-white p-4 pb-[calc(var(--safe-area-inset-bottom)+1rem)]">
            <Button
              variant="secondary"
              size="lg"
              rounded="full"
              className="px-9"
              onClick={() => clearFilter()}
            >
              {t("general.Limpar")}
            </Button>
            <Button
              size="lg"
              rounded="full"
              className="px-9"
              form="selectCategories"
              onClick={() => handleFilter()}
            >
              {t("general.Aplicar filtros")}
            </Button>
          </footer>
        </div>
      </FilterIonModal>
    </Layout>
  );
};
