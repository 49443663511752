import { useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { IonModal } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, X } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Breadcrumble, Header, HeaderButton } from "@/components";

import { Sectors } from "./Sectors";
import { Services } from "./Services";
import { Identification } from "./Identification";
import { Datepage } from "./Date";
import { Time } from "./Time";
import { Review } from "./Review";

import { StepsContext } from "../contexts/StepsContext";
import { useInstitution } from "../hooks/useInstitution";
import { useAppStore } from "@/store/useAppStore";
import { StepsBars } from "@/components/StepsBars";

export interface IDataPresentialScheduling {
  cpf?: string;
  email?: string;
  id_agendamento?: number;
  id_hora?: number;
  instituicao_id?: number;
  nome?: string;
  sector_id?: number;
  servico_agendamento_id?: number;
  telefone?: string;
}

interface FormModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const stepsTitle = ["Setor", "Serviço", "Pessoal", "Data", "Hora", "Revisão"];

export const Steps = ({ isOpen, handleClose }: FormModalProps) => {
  const params = useParams<{ id: string }>();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [currentStep, setCurrentStep] = useState(0);
  const [dataPresentialSchedulingInstitution, setDataPresentialSchedulingInstitution] = useState<
    IDataPresentialScheduling | undefined
  >();

  const { refetch } = useInstitution({ id: Number(params.id), appId });

  const { t } = useTranslation();

  const handleNextStep = () => {
    if (currentStep < stepsTitle.length) {
      setCurrentStep((prevState) => prevState + 1);
    } else {
      setCurrentStep(stepsTitle.length - 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    } else {
      handleClose();
    }
  };

  function handleGoTo(page: number) {
    console.log(page);
    return page <= stepsTitle.length && page >= 0 && setCurrentStep(page);
  }

  const steps = [
    <Sectors key={0} />,
    <Services key={1} />,
    <Identification key={2} />,
    <Datepage key={3} />,
    <Time key={4} />,
    <Review key={5} />,
  ];

  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      <Layout>
        <StepsContext.Provider
          value={{
            currentStep,
            setCurrentStep,
            total: stepsTitle.length,
            handleNextStep,
            handlePrevStep,
            handleGoTo,
            dataPresentialSchedulingInstitution,
            setDataPresentialSchedulingInstitution,
          }}
        >
          <Header variant="secondary">
            <div className="flex w-full items-center gap-4">
              <div className="flex-1">
                <HeaderButton
                  variant="secondary"
                  icon={<CaretLeft weight="bold" />}
                  onClick={handlePrevStep}
                />
              </div>

              <StepsBars total={stepsTitle.length} currentStep={currentStep} />

              <div className="flex justify-end">
                <HeaderButton
                  variant="secondary"
                  icon={<X weight="bold" />}
                  onClick={() => {
                    setCurrentStep(0);
                    refetch();
                    setDataPresentialSchedulingInstitution(undefined);
                    handleClose();
                  }}
                />
              </div>
            </div>
          </Header>

          <Content>
            <div className="flex min-h-full w-full flex-col justify-between gap-6 p-4">
              <div className="flex flex-col gap-1">
                <Text weight="medium" className="text-md" color="primary-500">
                  {t("booking.Agendamento")}
                </Text>

                <div className="flex grow justify-between">
                  <Breadcrumble
                    stack={stepsTitle.map((step, index) => ({
                      text: t(`booking.${step}`),
                      action: () => handleGoTo(index),
                    }))}
                    show={currentStep + 1}
                  />
                </div>
              </div>

              <div className="flex grow flex-col justify-between">
                {steps.filter((_, index) => index === currentStep)}
              </div>
            </div>
          </Content>
        </StepsContext.Provider>
      </Layout>
    </IonModal>
  );
};
