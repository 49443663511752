import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import i18n from "@/lib/i18next";
import { cn } from "@/lib/utils";

import { useStepsContext } from "../contexts/StepsContext";

interface IJustificationForm {
  pauta: string;
}

const justificationSchema = z.object({
  pauta: z.string().min(10, i18n.t("error.Informe um motivo com no mínimo 10 caracteres")),
});

export const Justification = () => {
  const { t } = useTranslation();
  const { setDataAssetsScheduling, dataAssetsScheduling, handleNextStep } = useStepsContext();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IJustificationForm>({
    resolver: zodResolver(justificationSchema),
    defaultValues: {
      pauta:
        process.env.NODE_ENV === "development"
          ? "Teste de justificativa em desenvolvimento"
          : dataAssetsScheduling?.pauta,
    },
  });

  const onSubmit = (data: IJustificationForm) => {
    setDataAssetsScheduling && setDataAssetsScheduling((prevState) => ({ ...prevState, ...data }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 grow flex-col gap-6">
      <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-wrap items-start gap-3">
        <div className="flex w-full flex-col">
          <textarea
            className={cn(
              "relative inline-flex resize-none rounded-md border-thin border-slate-200 bg-white p-4 font-sans text-sm leading-normal text-slate-700 transition-colors focus-within:border-primary-500 [&:focus-within_svg]:text-primary-500 [&_svg]:h-5 [&_svg]:w-5 [&_svg]:text-slate-400 [&_svg]:transition-colors",
              { "border-red-500": errors && errors.pauta },
            )}
            {...register("pauta")}
            rows={4}
          />

          <span className="m-0 mt-1.5 font-sans text-xs font-normal leading-normal text-red-500">
            {errors.pauta?.message}
          </span>
        </div>

        <Button size="lg" full className="self-end">
          {t("general.Continuar")}
        </Button>
      </form>
    </div>
  );
};
