import * as L from "leaflet";
import { MapContainer, TileLayer, ZoomControl, Marker } from "react-leaflet";

interface DynamicMapProps {
  latitude: number;
  longitude: number;
  trackerCoordinates?: {
    latitude: number;
    longitude: number;
  };
}

export function DynamicMap({ latitude, longitude, trackerCoordinates }: DynamicMapProps) {
  function handleMapCreated(map: L.Map | null) {
    if (map) {
      map.invalidateSize();

      if (trackerCoordinates) {
        const pulsatingIcon = L.divIcon({
          html: `<span class="pointer-pulse"/>`,
          className: "",
        });

        L.marker([trackerCoordinates.latitude, trackerCoordinates.longitude], {
          icon: pulsatingIcon,
        }).addTo(map);
      }
    }
  }

  return (
    <MapContainer
      ref={handleMapCreated}
      center={[latitude, longitude]}
      zoom={17}
      zoomControl={false}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="topright" />
      <Marker position={[latitude, longitude]}></Marker>
    </MapContainer>
  );
}
