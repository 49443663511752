import { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Text } from "@astrolabe-ui/react";

type CalendarProps = {
  selectedDate: Date | null;
  onSelectDate: (date: Date) => void;
  availableDays: Array<string>;
};

export function Calendar({ availableDays, selectedDate, onSelectDate }: CalendarProps) {
  const calendarMonths = useMemo(() => {
    const months: Array<{
      firstDateMonth: Dayjs;
      dates: Array<{ date: Dayjs; disabled: boolean }>;
    }> = [];

    const firstDate = availableDays[0];
    const lastDate = availableDays[availableDays.length - 1];

    const monthFirstDate = dayjs(firstDate).month();
    const monthLastDate = dayjs(lastDate).month();

    const firstDateAllMonths = Array.from({
      length: monthLastDate - monthFirstDate + 1,
    }).map((_, i) => dayjs(firstDate).set("date", 1).add(i, "month"));

    firstDateAllMonths.forEach((currentDate) => {
      const daysInMonthArray = Array.from({ length: currentDate.daysInMonth() }).map((_, i) =>
        currentDate.set("date", i + 1),
      );

      const firstWeekDay = currentDate.get("day");

      const previousMonthFillArray = Array.from({ length: firstWeekDay })
        .map((_, i) => currentDate.subtract(i + 1, "day"))
        .reverse();

      const lastDayInCurrentMonth = currentDate.set("date", currentDate.daysInMonth());
      const lastWeekDay = lastDayInCurrentMonth.get("day");

      const nextMonthFillArray = Array.from({ length: 7 - (lastWeekDay + 1) }).map((_, i) =>
        lastDayInCurrentMonth.add(i + 1, "day"),
      );

      months.push({
        firstDateMonth: currentDate,
        dates: [
          ...previousMonthFillArray.map((date) => ({
            date,
            disabled: true,
          })),
          ...daysInMonthArray.map((date) => ({
            date,
            disabled: !availableDays.includes(date.toISOString()),
          })),
          ...nextMonthFillArray.map((date) => ({
            date,
            disabled: true,
          })),
        ],
      });
    });

    return months;
  }, [availableDays]);

  return (
    <div className="flex flex-col gap-8">
      {calendarMonths.map((currentMonth) => (
        <div key={currentMonth.firstDateMonth.toISOString()} className="flex flex-col gap-4">
          <Text size="md" color="primary-500" weight="medium" className="capitalize">
            {currentMonth.firstDateMonth.format("MMMM YYYY")}
          </Text>

          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-7 gap-2">
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                DOM
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                SEG
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                TER
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                QUA
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                QUI
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                SEX
              </Text>
              <Text size="xs" weight="medium" color="slate-700" className="w-full text-center">
                SAB
              </Text>
            </div>

            <div className="grid grid-cols-7 gap-2">
              {currentMonth.dates.map((item) => (
                <button
                  key={item.date.toISOString()}
                  type="button"
                  className="flex aspect-square w-full items-center justify-center rounded-md border-thin border-slate-200 font-sans text-xs font-medium text-slate-900 transition-colors disabled:border-slate-50 disabled:bg-slate-50 disabled:text-slate-300 data-[selected=true]:border-primary-500 data-[selected=true]:bg-primary-500 data-[selected=true]:text-white"
                  onClick={() => onSelectDate(item.date.toDate())}
                  disabled={item.disabled}
                  data-selected={
                    selectedDate &&
                    dayjs(selectedDate).isSame(item.date, "day") &&
                    item.date.month() === currentMonth.firstDateMonth.month()
                  }
                >
                  {item.date.format("DD")}
                </button>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
