import {
  IconButton as IconButtonLib,
  IconButtonProps as IconButtonLibProps,
} from "@astrolabe-ui/react";
import { IonRippleEffect } from "@ionic/react";

interface IconButtonProps extends IconButtonLibProps {
  rounded: "md" | "full";
}

export function IconButton(props: IconButtonProps) {
  return (
    <div
      data-rounded={props.rounded}
      className="ion-activatable ripple-parent flex shrink-0 items-center justify-center data-[rounded='full']:rounded-full data-[rounded='md']:rounded-md"
    >
      <IconButtonLib {...props} />
      <IonRippleEffect className="text-slate-200" />
    </div>
  );
}
