import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { IframeTemplate } from "@/templates/old/IframeTemplate";

export function Iframe() {
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const url = useMemo(() => queryParams.get("url"), [queryParams]);

  const title = useMemo(() => queryParams.get("title"), [queryParams]);

  if (url && title) {
    return <IframeTemplate src={decodeURIComponent(url)} name={decodeURIComponent(title)} />;
  }

  return null;
}
