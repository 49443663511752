import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useIonAlert } from "@ionic/react";
import { Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import { Text } from "@astrolabe-ui/react";

import { Loading } from "@/components";
import { locationAPI } from "@/lib/axios";
import { formatMapDisplayName } from "@/utils/mapDisplayNameFormatter";

import { useMapContext } from "./context";

export const MapMarker = () => {
  const {
    popMessage,
    center,
    setTextLocation,
    setDataLocation,
    dataLocation,
    setCenter,
    sendByUser,
    loading,
    setLoading,
  } = useMapContext();

  const handleLocation = async (latlng: { lat: number; lng: number }) => {
    setLoading(true);
    try {
      const res = await locationAPI.get(
        `reverse.php?lat=${latlng.lat}&lon=${latlng.lng}&zoom=18&format=jsonv2`,
      );
      const address = await formatMapDisplayName({
        ...res.data.address,
        lng: res.data.lon,
        lat: res.data.lat,
      });
      if (res.data.type === "administrative" || res.data.type === "unclassified")
        presentAlert({
          header: t("location.Não encontramos o endereço"),
          message: t("location.Aponte localização no mapa"),
          buttons: ["OK"],
        });
      setDataLocation((prevState) =>
        !sendByUser ? address.data : { ...prevState, lat: latlng.lat, lng: latlng.lng },
      );
      !sendByUser && setTextLocation(address.displayName);
    } catch (error: any) {
      setDataLocation((prevState) => ({ ...prevState, lat: latlng.lat, lng: latlng.lng }));
      if (!sendByUser) {
        setTextLocation({
          title: "Endereço apontado no mapa",
          subtitle: "Complete na próxima etapa",
        });
      }
      console.log("ERROR GEOCODE = ", error.message);
    }
    setCenter({ lat: latlng.lat, lng: latlng.lng });
    setLoading(false);
  };

  const map = useMap();

  useMapEvents({
    click: async (e) => {
      await handleLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
    },
  });

  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (center.lat !== 0 && center.lng !== 0)
      map.setView({
        lat: dataLocation.lat || Number(center.lat),
        lng: dataLocation.lng || Number(center.lng),
      });
  }, [center, dataLocation.lat, dataLocation.lng, map]);

  const fetchLocationNomatim = async () => {
    setLoading(true);
    try {
      const res = await locationAPI.get(
        `search.php?q=${`${encodeURI(
          (dataLocation?.uf || "") +
            " " +
            (dataLocation?.city?.split(" - ")[0] || "") +
            " " +
            dataLocation.neighborhood +
            " " +
            dataLocation.street,
        )}`}&polygon_geojson=1&format=jsonv2`,
      );
      map.flyTo({ lat: res.data[0].lat, lng: res.data[0].lon });
      setCenter({ lat: res.data[0].lat, lng: res.data[0].lon });
      setDataLocation((prevState) => ({
        ...prevState,
        lat: res.data[0].lat,
        lng: res.data[0].lon,
      }));
      return res;
    } catch (error: any) {
      console.log("ERROR GEOCODE = ", error.message);
      presentAlert({
        header: t("location.Não encontramos o endereço"),
        message: t("location.Aponte localização no mapa"),
        buttons: ["OK"],
      });
      return error;
    } finally {
      setLoading(false);
    }
  };

  useQuery({
    queryKey: ["get-nomation-search"],
    queryFn: () => fetchLocationNomatim(),
    enabled: sendByUser,
  });

  return (
    <Marker
      position={{
        lat: dataLocation.lat || Number(center.lat),
        lng: dataLocation.lng || Number(center.lng),
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Popup maxWidth={192}>
          <Text className="text-center font-medium text-slate-700" style={{ margin: 0 }}>
            {popMessage?.title || t("location.Você está aqui?")}
          </Text>

          <Text className="text-center" style={{ margin: 0 }}>
            {popMessage?.subtitle || t("location.Se não for sua localização, ajuste no mapa")}
          </Text>
        </Popup>
      )}
    </Marker>
  );
};
