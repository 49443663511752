import { IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.25rem 1rem max(env(safe-area-inset-bottom), 1.25rem);

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    strong {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: ${COLORS.PRIMARY};
    }

    span {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 150%;
      color: #334155;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0 3rem;
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    color: #94a3b8;
  }
`;

export const Button = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  margin-top: auto;
  padding: 0.875rem 1rem;
  background-color: ${COLORS.PRIMARY};
  border-radius: 0.5rem;

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled(IonSpinner)`
  width: 18px;
  height: 18px;
`;

export const BigSpinner = styled(IonSpinner)`
  --color: #334155;
`;
