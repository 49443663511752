import { useQuery } from "@tanstack/react-query";

import { getResources } from "@/services/schedulings";
import { queryClient } from "@/lib/react-query";

type useSchedulingParams = {
  appId: number;
  entityId: string;
};

export function useResources({ appId, entityId }: useSchedulingParams) {
  return useQuery({
    queryKey: ["resources", entityId, appId],
    queryFn: () => getResources({ appId, entityId }),
    enabled: !!entityId && !!appId,
  });
}

export async function prefetchResources({ appId, entityId }: useSchedulingParams) {
  await queryClient.prefetchQuery({
    queryKey: ["resources", entityId, appId],
    queryFn: () => getResources({ appId, entityId }),
  });
}
