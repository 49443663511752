import dayjs from "dayjs";

import { IDataPresentialScheduling } from "../..";
import { IBookingTime } from "../../Time/utils/getBookingTimes";

import { capitalize, formatCpf, formatPhone } from "@/utils/formats";
import { Booking, ResponseInstitution, Sector } from "@/services/presentialScheduling";

export const getSector = ({ id, institution }: { id: number; institution: ResponseInstitution }) =>
  institution?.setores.find((sector) => sector.id === id);

export const getService = ({ id, sector }: { id: number; sector: Sector }) =>
  sector.servicos.find((service) => service.id === id);

export const getData = ({
  id,
  sector,
  serviceId,
}: {
  id: number;
  serviceId: number;
  sector: Sector;
}) => getService({ id: serviceId, sector })?.agendamentos.find((booking) => booking.id === id);

const getHour = ({ id, booking }: { id: number; booking: Booking }) => ({
  period: dayjs().to(
    dayjs(
      (JSON.parse(booking.json_vagas_hora) as Array<IBookingTime>).find(
        (bookingTime) => bookingTime.id_agenda === id,
      )?.datetime,
    ),
  ),
  hour: dayjs(
    (JSON.parse(booking.json_vagas_hora) as Array<IBookingTime>).find(
      (bookingTime) => bookingTime.id_agenda === id,
    )?.datetime,
  ).format("HH:mm"),
});

export const getOutputViewValues = (
  institution: ResponseInstitution,
  dataPresentialSchedulingInstitution: IDataPresentialScheduling,
) => {
  const sector = getSector({
    institution,
    id: dataPresentialSchedulingInstitution.sector_id as number,
  });

  const service = getService({
    id: dataPresentialSchedulingInstitution.servico_agendamento_id as number,
    sector: sector as Sector,
  });

  const date = getData({
    id: dataPresentialSchedulingInstitution.id_agendamento as number,
    sector: sector as Sector,
    serviceId: service?.id as number,
  });

  const time = getHour({
    id: dataPresentialSchedulingInstitution.id_hora as number,
    booking: date as Booking,
  });

  return [
    { name: "booking.Setor", outputviews: [{ title: sector?.nome, subTitle: sector?.endereco }] },
    {
      name: "booking.Serviço",
      outputviews: [{ title: service?.nome, subTitle: service?.descricao }],
    },
    {
      name: "booking.Pessoal",
      outputviews: [
        {
          title: "CPF",
          subTitle: formatCpf(dataPresentialSchedulingInstitution.cpf as string),
        },
        {
          title: "Nome",
          subTitle: dataPresentialSchedulingInstitution.nome as string,
        },
        {
          title: "E-mail",
          subTitle: dataPresentialSchedulingInstitution.email as string,
        },
        {
          title: "Telefone",
          subTitle: formatPhone(dataPresentialSchedulingInstitution.telefone as string),
        },
      ],
    },
    {
      name: "booking.Data",
      outputviews: [
        {
          title: capitalize(
            dayjs(date?.data_aplicacao)
              .toDate()
              .toLocaleDateString("pt-BR", { weekday: "long" }),
          ),
          subTitle: dayjs(date?.data_aplicacao)
            .toDate()
            .toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }),
        },
      ],
    },
    {
      name: "booking.Hora",
      outputviews: [
        {
          title: time.hour,
          subTitle: time.period,
        },
      ],
    },
  ];
};
