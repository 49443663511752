import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import L, { LeafletMouseEvent, LatLngTuple } from "leaflet";
import styled from "styled-components";
import axios from "axios";
import { IonModal, IonInput, IonButton, IonIcon, IonAlert, IonToast } from "@ionic/react";
import { locationOutline } from "ionicons/icons";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { MapPreviewContainer } from "./MapPreviewContainer";
import { getAddress } from "./locationHelpers";
import { useConfig } from "@/hooks/useConfig";
import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Wrapper = styled.div`
  background: #ffffff;
  cursor: pointer;
  padding: 8px 8px;
  border: 1px solid rgb(226, 232, 240);
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const SearchLayout = styled.div`
  background: #ffffff;
  height: 60px;
  padding-left: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
`;

interface ContentLocationProps {
  error?: boolean;
}

export const ContentLocation = styled.div<ContentLocationProps>`
  width: 100%;
  padding: 0px 16px;
  /* border: 1px solid #E2E8F0; */
  border-radius: 8px;
  background-color: #ffffff;
  border: ${({ error }) =>
    !error ? "1px solid #E2E8F0 !important" : "1px solid #dc2626 !important"};
`;

export const ContentFields = styled.div`
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #777777;
`;

export const ContentTextArea = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px 16px 8px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 12px;
  color: #94a3b8;
`;
interface TextAreaProps {
  error?: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  font-size: 14px;
  padding: 18px 16px;
  color: #334155;
  border-radius: 8px;
  resize: none;
  background-color: #fff;

  border: ${({ error }) =>
    !error ? "1px solid #E2E8F0 !important" : "1px solid #dc2626 !important"};

  &:focus {
    outline: none;
    border: ${({ error }) =>
      !error ? "1px solid #94A3B8 !important" : "1px solid #dc2626 !important"};
  }
`;

const SearchAddrView = styled(IonInput)`
  color: black;
`;

let map: L.Map;
let marker: L.Marker;

export const TextError = styled.small`
  color: #dc2626;
  font-size: 12px;
`;

function startMap(
  searchMap: HTMLDivElement,
  lat: number,
  lng: number,
  address: string,
  _: any,
  setToast: any,
  setNoLocalization: any,
  generalDistanceLimit: any,
  action: any,
) {
  const CONTENT = getContent();

  const appLocation = localStorage.getItem(STORAGE.LOCATION.KEY)?.split(",") || [0, 0];

  const defaultLocation: LatLngTuple = [
    lat || Number(appLocation[0]),
    lng || Number(appLocation[1]),
  ];

  const defaultZoom = 12;
  map = L.map(searchMap).setView(defaultLocation, defaultZoom);
  marker = L.marker(defaultLocation).addTo(map);

  marker.bindPopup(CONTENT.GLOBAL.LOADING).openPopup();

  if (lat && lng) {
    defineData(lat, lng);
  }

  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 18,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    // tileSize: 512,
    // zoomOffset: -1,
  }).addTo(map);

  function defineData(lat: number, lng: number) {
    getAddress(lat, lng).then((res: any) => {
      action({
        lat,
        lng,
        address: res.formated,
      });

      marker.bindPopup(res.formated).openPopup();
    });
  }

  // Default current location by user geolocation

  function successLocation(data: any) {
    const { latitude, longitude } = data.coords;

    map.flyTo([latitude, longitude]);

    getAddress(latitude, longitude).then((res: any) => {
      action({
        lat: latitude,
        lng: longitude,
        address: res.formated,
      });

      marker.setLatLng([latitude, longitude]).bindPopup(res.formated).openPopup();
    });
  }

  function ErrorLocation() {
    action({
      lat: 0,
      lng: 0,
      address,
    });

    marker
      .setLatLng(defaultLocation)
      .addTo(map)
      .bindPopup("Localização manual - Clique para definir localização")
      .openPopup();
  }

  if (!lat && !lng) {
    navigator.geolocation.getCurrentPosition(successLocation, ErrorLocation);

    if (lat === 0 && lng === 0) {
      ErrorLocation();
    }
  }

  // Location defined by user clicking on map

  map.on("click", (arg: LeafletMouseEvent) => {
    const { lat, lng } = arg.latlng;

    let distanceLimit = 0;

    if (generalDistanceLimit && generalDistanceLimit > distanceLimit) {
      distanceLimit = generalDistanceLimit;
    }

    // if (subCategory.distancia_limite) {
    //   distanceLimit = subCategory.distancia_limite;
    // }

    const resultDistance = CalcRadiusDistance(
      lat,
      lng,
      appLocation[0],
      appLocation[1],
      distanceLimit,
    );

    if (resultDistance > distanceLimit) {
      setToast(true);
      setNoLocalization(true);
      return;
    } else {
      setNoLocalization(false);
    }

    map.flyTo([lat, lng]);

    marker.setLatLng([lat, lng]).bindPopup(CONTENT.GLOBAL.LOADING).openPopup().addTo(map);

    defineData(lat, lng);
  });
}

function CalcRadiusDistance(lat1: any, lon1: any, lat2: any, lon2: any, distanceLimit: any) {
  const RADIUSKILOMETERS = distanceLimit * 1000;

  const latR1 = (lat1 * Math.PI) / 180;
  const lonR1 = (lon1 * Math.PI) / 180;
  const latR2 = (lat2 * Math.PI) / 180;
  const lonR2 = (lon2 * Math.PI) / 180;
  const latDifference = latR2 - latR1;
  const lonDifference = lonR2 - lonR1;
  const a =
    Math.pow(Math.sin(latDifference / 2), 2) +
    Math.cos(latR1) * Math.cos(latR2) * Math.pow(Math.sin(lonDifference / 2), 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(c * RADIUSKILOMETERS * 10) / 10;
}

interface paramsPage {
  SLUG: string;
}

type GetLocationProtocolProps = {
  onLocationChange?: (data: any) => void;
  selectedSubCategory?: any;
  endereco: string;
  latitude: number;
  longitude: number;
};

export function GetLocationProtocol(props: GetLocationProtocolProps) {
  const params: paramsPage = useParams();

  const CONTENT = getContent();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmAddr, setShowModalConfirmAddr] = useState(false);

  const [address, setAdress] = useState(props.endereco ? props.endereco : "");
  const [currentAddr, setCurrentAddr] = useState("");
  const [lat, setLat] = useState(props.latitude ? props.latitude : 0);
  const [lng, setLng] = useState(props.longitude ? props.longitude : 0);
  const [latLngCenter, setLatLngCenter] = useState(
    props.longitude && props.longitude ? [props.longitude, props.latitude] : [0, 0],
  );
  const [showPreviewMap, setShowPreviewMap] = useState(
    !!latLngCenter && latLngCenter[0] !== 0 && latLngCenter[1] !== 0,
  );
  const [searchingAddr, setSearchingAddr] = useState("");
  const searchMap = useRef<HTMLDivElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [subCategory] = useState(props.selectedSubCategory);
  const [toast, setToast] = useState(false);
  const [noLocalization, setNoLocalization] = useState(false);

  const generalDistanceLimit = useConfig({
    label: "limite.distancia.protocolo",
    slug: params.SLUG,
  }) as number;

  function searchForAddress() {
    const http = axios.create({
      baseURL: `https://nominatim.openstreetmap.org/search/${encodeURI(
        searchingAddr,
      )}?format=json&addressdetails=1&limit=1`,
    });

    http
      .get("")
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const resLat = res.data[0].boundingbox[0];
          const resLng = res.data[0].boundingbox[2];

          let distanceLimit = 0;

          if (generalDistanceLimit && generalDistanceLimit > 0) {
            distanceLimit = Number(generalDistanceLimit);
          }

          if (subCategory.distancia_limite) {
            distanceLimit = subCategory.distancia_limite;
          }

          const appLocation = localStorage.getItem(STORAGE.LOCATION.KEY)?.split(",") || [0, 0];
          const resultDistance = CalcRadiusDistance(
            resLat,
            resLng,
            appLocation[0],
            appLocation[1],
            distanceLimit,
          );

          if (resultDistance > distanceLimit) {
            setToast(true);
            setNoLocalization(true);
            return;
          } else {
            setNoLocalization(false);
          }

          getAddress(resLat, resLng).then((res: any) => {
            setLat(resLat);
            setLng(resLng);
            setLatLngCenter([resLat, resLng]);
            setCurrentAddr(res.formated);
            setShowPreviewMap(true);
            const location: LatLngTuple = [resLat, resLng];
            map.flyTo(location);
            marker.setLatLng(location).bindPopup(searchingAddr).openPopup();
          });
        } else {
          setShowAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowAlert(true);
      });
  }

  function applayAddr(address: string) {
    setAdress(address);
    setShowPreviewMap(true);
    setSearchingAddr(address);
    if (props.onLocationChange) {
      props.onLocationChange({
        lat,
        lng,
        address,
      });
    }
  }

  // function addressValidate(adress: string) {
  //   console.log(adress);
  //   setError('endereco', { type: 'custom', message: CONTENT.PROTOCOLS.FIELD_REQUIRED });
  // }

  return (
    <Wrapper>
      <IonModal
        backdropDismiss={false}
        isOpen={showModal}
        onDidPresent={() => {
          setSearchingAddr("");
          if (searchMap.current) {
            startMap(
              searchMap.current,
              lat,
              lng,
              address,
              subCategory,
              setToast,
              setNoLocalization,
              generalDistanceLimit,
              (data: any) => {
                setLat(data.lat);
                setLng(data.lng);
                setLatLngCenter([data.lat, data.lng]);
                setCurrentAddr(data.address);
              },
            );
          }
        }}
        onDidDismiss={() => {
          if ((!searchingAddr || searchingAddr.length === 0) && lat !== 0 && lng !== 0) {
            applayAddr(currentAddr);
          } else if (lat !== 0 && lng !== 0 && !noLocalization) {
            setShowModalConfirmAddr(true);
          }

          setShowModal(false);
          setShowPreviewMap(true);
        }}
      >
        <SearchLayout>
          <SearchAddrView
            value={searchingAddr}
            placeholder={CONTENT.LOCATION.SEARCH_HERE}
            onIonInput={(e) => setSearchingAddr(e.detail.value + "")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                searchForAddress();
              }
            }}
          />
          <IonButton
            expand="block"
            onClick={() => {
              searchForAddress();
            }}
          >
            {CONTENT.LOCATION.SEARCH}
          </IonButton>
        </SearchLayout>

        <div ref={searchMap} style={{ height: "85%" }}>
          {" "}
        </div>

        <div style={{ display: "flex", width: "100%", background: "#FFFFFF" }}>
          <IonButton
            style={{ width: "50%" }}
            expand="block"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {CONTENT.GLOBAL.CLOSE}
          </IonButton>
          <IonButton
            disabled={lat === 0 && lng === 0}
            style={{ width: "50%" }}
            expand="block"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {CONTENT.LOCATION.DONE}
          </IonButton>
        </div>
      </IonModal>

      <ContentLocation
        error={!!errors.endereco?.message}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <ContentFields>
          <Label>{CONTENT.LOCATION.ADDRESS + " *"}</Label>
          <IonButton style={{ height: "36px" }}>
            {CONTENT.LOCATION.SEARCH}
            <IonIcon icon={locationOutline} />
          </IonButton>
        </ContentFields>
      </ContentLocation>

      {showPreviewMap && latLngCenter && (
        <>
          <div
            onClick={() => {
              setShowModal(true);
            }}
          >
            <MapPreviewContainer lat={lat} lng={lng} />
          </div>

          <ContentTextArea>
            <TextArea
              value={address}
              placeholder={CONTENT.LOCATION.ADDRESS + " *"}
              rows={3}
              {...register("endereco", {
                onChange: (e) => setAdress(e.target.value ? e.target.value : ""),
                required: {
                  value: true,
                  message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
                },
              })}
            ></TextArea>
            {errors.endereco && <TextError>{`${errors.endereco?.message}`}</TextError>}
          </ContentTextArea>
        </>
      )}

      <IonAlert
        isOpen={showModalConfirmAddr}
        onDidDismiss={() => {
          applayAddr(searchingAddr);
          setShowModalConfirmAddr(false);
        }}
        header={CONTENT.LOCATION.ALERT_SELECT_LOCATION_TITLE}
        buttons={[
          {
            text: currentAddr,
            handler: () => {
              applayAddr(currentAddr);
            },
          },
          {
            text: searchingAddr,
            handler: () => {
              applayAddr(searchingAddr);
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
        }}
        header={CONTENT.LOCATION.ALERT_FAIL_TITLE}
        message={CONTENT.LOCATION.ALERT_FAIL_MSG}
        buttons={["ok"]}
      />
      <IonToast
        isOpen={toast}
        position="top"
        color="dark"
        onDidDismiss={() => setToast(false)}
        message={CONTENT.LOCATION.ALERT_FAIL_MSG_LOCATION}
        duration={3000}
      />
    </Wrapper>
  );
}
