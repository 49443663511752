import { useEffect } from "react";
import socketCluster from "socketcluster-client";

interface useSocketParams {
  channel: string;
  watchMessage: (data: any) => void;
}

export const useSocket = ({ channel: channelName, watchMessage }: useSocketParams) => {
  useEffect(() => {
    const socket = socketCluster.create({
      hostname: "socketcluster.mobby.app.br",
      port: 8001,
      secure: true,
    });

    async function watchChannel() {
      const channel = socket.channel(channelName);

      channel.subscribe();

      for await (const data of channel) {
        watchMessage(data);
      }
    }

    watchChannel();

    return () => {
      socket.unsubscribe(channelName);
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName]);
};
