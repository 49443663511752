import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { Chat } from "@/pages/old/Protocol/components/Chat";
import { getProtocolById } from "@/services/old/servicos";
import { getContent } from "@/content/index";
import { STORAGE } from "@/data/storage";

interface ProtocolParams {
  id: string;
  SLUG: string;
}

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px 8px;
  background-color: #ffffff;
`;

export function ProtocolChat() {
  const CONTENT = getContent();

  const params: ProtocolParams = useParams();

  const apiName = localStorage.getItem(STORAGE.SOCKET_CHANNEL.KEY);

  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(22);
  const [blockSendMessage, setblockSendMessage] = useState(false);
  const [failed, setFailed] = useState(false);
  const [allowSendFile, setAllowSendFile] = useState(false);

  useEffect(() => {
    getProtocolById(params.id, params.SLUG)
      .then((res: any) => {
        setAllowSendFile(res.data.categoriaServico.accept_file);
        setId(res.data.id);
        setblockSendMessage(!res.data.replica);
        setIsLoading(false);
      })
      .catch(() => {
        setFailed(true);
      });
  }, []);

  if (isLoading) {
    return (
      <div>
        <PageTitle>{CONTENT.GLOBAL.LOADING} </PageTitle>
      </div>
    );
  } else if (failed) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.MESSAGES}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  }

  return (
    <Content>
      <Chat
        SLUG={params.SLUG}
        id={id}
        allow_file_upload={true || allowSendFile}
        api_name={apiName || ""}
        block_send_message={blockSendMessage}
        type={"protocol"}
      />
    </Content>
  );
}
