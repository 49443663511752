import { http } from "@/lib/axios";

interface confirmMessagesProtocolParams {
  appId: number;
  protocolId: number;
}

export async function confirmMessagesProtocol({
  appId,
  protocolId,
}: confirmMessagesProtocolParams) {
  return http.get(`v3/${appId}/protocolo/${protocolId}/confirmarMensagens`);
}
