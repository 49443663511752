import { useHistory } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";
import { useQuery } from "@tanstack/react-query";

import { CardNews } from "./card-news";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { getPreviewNews } from "@/services/news";

export function PreviewNews() {
  const history = useHistory();
  const slug = useAppSlug();
  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data: news } = useQuery({
    queryKey: ["preview_news", String(appId)],
    queryFn: () => getPreviewNews({ appId }),
    staleTime: 60 * 1000 * 5, // 5 minutos
  });

  function handleOpenNews(id: number) {
    history.push(`/${slug}/news/${id}`);
  }

  function handleOpenListNews() {
    history.push(`/${slug}/news`);
  }

  return (
    <section className="w-full bg-[#FF5301]">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-4 py-6">
        <header className="flex w-full items-center justify-between px-4">
          <Text asChild color="white" weight="medium" className="text-md lg:text-lg">
            <strong>Notícias</strong>
          </Text>

          <button
            className="text-sm font-medium text-white lg:text-md"
            onClick={() => handleOpenListNews()}
          >
            Ver tudo
          </button>
        </header>

        <ul className="flex w-full gap-4 overflow-x-auto px-4">
          {news?.map((item) => (
            <li key={item.id}>
              <CardNews
                title={item.title}
                category={item.category}
                categories={item.categories}
                imageUrl={item.imageUrl}
                like={item.like}
                time={item.time}
                handleOnClick={() => handleOpenNews(item.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
