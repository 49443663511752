import { http } from "@/lib/axios";

export async function getGallery() {
  try {
    const response = await http.get<{ imagens: Array<{ id: number; imagem: string }> }>(
      "/v2/77/informacoes/7713",
    );

    return response.data.imagens.map((item) => ({
      id: item.id,
      image: item.imagem,
    }));
  } catch (error) {
    console.error(error);
  }
}
