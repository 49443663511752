import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { atom, useAtom } from "jotai";
import { toast } from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";
import { Star } from "@phosphor-icons/react";

import { Button } from "@/components/Button";
import { http } from "@/lib/axios";
import { useUserStore } from "@/store/useUserStore";
import { useAppStore } from "@/store/useAppStore";
import { cn } from "@/lib/utils";
import { Modal } from "../Modal";

interface createAppReviewRequest {
  user_id: number | string;
  rating: number;
  comment: string;
}

export const isOpenAppReviewAtom = atom(false);

const appReviewFormSchema = z.object({
  rating: z.number().max(5),
  comment: z.string(),
});

type appReviewFormData = z.infer<typeof appReviewFormSchema>;

export function AppReview() {
  const app = useAppStore((state) => state.app);
  const user = useUserStore((state) => state.user);
  const updateUser = useUserStore((state) => state.updateUser);

  const [isOpen, setIsOpen] = useAtom(isOpenAppReviewAtom);

  const { register, handleSubmit, control } = useForm<appReviewFormData>({
    resolver: zodResolver(appReviewFormSchema),
    defaultValues: {
      rating: user?.review?.rating ?? 0,
      comment: user?.review?.comment ?? "",
    },
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: createAppReviewRequest) => {
      return http.post(`v3/${app?.id}/avaliacao`, data);
    },
  });

  function createAppReview(data: appReviewFormData) {
    mutateAsync(
      {
        ...data,
        user_id: user?.id ?? "",
      },
      {
        onSuccess: () => {
          toast.success("Avaliação cadastrada");

          updateReviewUser({
            rating: data.rating,
            comment: data.comment,
          });

          handleCloseModal();
        },
        onError: (err) => {
          console.error(err);
          toast.error("Não foi possível registrar");
        },
      },
    );
  }

  function updateReviewUser({ rating, comment }: { rating: number; comment: string }) {
    if (!user) {
      throw new Error("Not authenticated");
    }

    updateUser({
      ...user,
      review: {
        rating,
        comment,
      },
    });
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      showModal={isOpen}
      onClose={handleCloseModal}
      classname="ios:pb-[calc(var(--safe-area-inset-bottom)+2rem)]"
    >
      <div className="flex flex-col items-center text-center">
        <Text size="md" weight="medium" color="slate-700">
          Sua avaliação é muito importante para nós.
        </Text>

        <form
          id="appReviewForm"
          onSubmit={handleSubmit(createAppReview)}
          className="my-8 flex w-full flex-col items-center gap-8"
        >
          <div className="flex items-center gap-3">
            {[...Array(5)].map((_, index) => (
              <Controller
                key={index}
                name="rating"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Star
                    key={index}
                    size={44}
                    weight="fill"
                    onClick={() => onChange(index + 1)}
                    className={cn("text-slate-200", {
                      "text-primary-500": index + 1 <= value,
                    })}
                  />
                )}
              />
            ))}
          </div>

          <textarea
            rows={4}
            placeholder="Adicionar comentários (opcional)"
            className="w-full rounded-md border-slate-200 text-sm text-slate-700 placeholder:text-sm placeholder:text-slate-400 focus:border-primary-500"
            {...register("comment")}
          />
        </form>

        <Button
          variant="primary"
          size="lg"
          rounded="full"
          full
          form="appReviewForm"
          type="submit"
          loading={isLoading}
        >
          Avaliar
        </Button>
      </div>
    </Modal>
  );
}
