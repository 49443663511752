import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { Block } from "@/components/old/Block";
import { PostTitle } from "@/components/old/Typography";
import { ShareButton } from "@/components/old/ShareButton";
import { Line } from "@/components/old/Base";
import { HTMLRender } from "@/components/old/HtmlRender";
import { BlockMap } from "@/components/old/BlockMap";
import { BlockInfoDetail } from "@/pages/old/Infos/components/BlockInfoDetail";
import { Tag } from "@/components/old/BlockItemImage";
import { getInfoById } from "@/services/old/infos";
import { getContent } from "@/content/index";

interface BadgeProps {
  background?: string;
}

// const Image = styled.img`
//   width: 100%;
//   border-radius: 5px;
// `;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
`;

const Badge = styled.span<BadgeProps>`
  background-color: ${(props) => (props.background ? props.background : "#ffffff")};
  color: ${(props) => (props.background ? "#ffffff" : "#000000")};
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
`;

interface PostPageParams {
  id: string;
  SLUG: string;
}

export function InfoOpen() {
  const CONTENT = getContent();
  const params: PostPageParams = useParams();

  const { isLoading, data } = useQuery(
    ["getInfo", params.id],
    () => getInfoById(params.id, params.SLUG),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.GLOBAL.LOADING}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={CONTENT.INFORMATION.HEADER_TITLE2}>
      <Block>
        <Tags>
          {data?.data.tags.map((tag: Tag) => (
            <Badge key={tag.id} background={tag.cor}>
              {tag.nome}
            </Badge>
          ))}
        </Tags>

        <PostTitle> {data?.data.titulo} </PostTitle>

        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          className="h-full w-full overflow-hidden rounded-md"
        >
          {data?.data.imagem && (
            <SwiperSlide>
              <div className="mx-auto w-full max-w-lg overflow-hidden rounded-md">
                <img src={data.data.imagem} alt="" className="h-full w-full" />
              </div>
            </SwiperSlide>
          )}

          {data?.data.imagens.map((item: { id: number; imagem: string }) => (
            <SwiperSlide key={item.id}>
              <div className="mx-auto w-full max-w-lg overflow-hidden rounded-md">
                <img src={item.imagem} alt="" className="h-full w-full" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* <IonSlides pager={true} options={{ initialSlide: 0, speed: 400 }}>
          {data?.data.imagem && (
            <IonSlide
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingBottom: "35px",
              }}
            >
              <Image src={data?.data.imagem} />
            </IonSlide>
          )}

          {data?.data.imagens.map((item: any, index: number) => {
            if (item.imagem) {
              return (
                <IonSlide
                  key={index}
                  style={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingBottom: "35px",
                  }}
                >
                  <Image src={item.imagem} />
                </IonSlide>
              );
            }
          })}
        </IonSlides> */}

        <ShareButton
          url={"https://mobby.app.br/" + params.SLUG + "/info-abrir/" + params.id}
          title={data?.data.titulo}
        />

        <Line />

        <HTMLRender HTML_CONTENT={data?.data.conteudo} />
      </Block>

      {(data?.data.valor || data?.data.status) && (
        <BlockInfoDetail value={data?.data.valor} status={data?.data.status} />
      )}

      {data?.data.latitude && data?.data.longitude && (
        <BlockMap
          lat={data?.data.latitude}
          lng={data?.data.longitude}
          overrideAddress={data?.data.endereco}
        />
      )}
    </PageTemplate>
  );
}
