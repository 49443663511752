import React from "react";
import styled from "styled-components";
import { IonIcon, IonText, IonRouterLink } from "@ionic/react";

const Icon = styled(IonIcon)`
  color: #666666 !important;
  font-size: 23px;
`;

const Title = styled(IonText)`
  font-size: 16px;
`;

const Div = styled.div`
  padding: 14px 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
`;

interface SideMenuProps {
  title?: string;
  onClick?: React.MouseEventHandler;
  routerLink?: string;
  icon: string;
}

export function SideMenuButton(props: SideMenuProps) {
  return (
    <IonRouterLink onClick={props.onClick} routerLink={props.routerLink}>
      <Div>
        <Icon icon={props.icon} />
        <Title color="dark">{props.title}</Title>
      </Div>
    </IonRouterLink>
  );
}
