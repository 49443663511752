import dayjs from "dayjs";
import { ResponseBooking } from "@/services/presentialScheduling";
import { TFunction } from "i18next";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export const getFieldsBooking = (
  booking: ResponseBooking,
  t: TFunction<"translation", undefined>,
) => {
  const fields = [
    {
      label: t("booking.Setor"),
      content: booking.agendamento.servicos.setor.nome,
    },
    {
      label: t("booking.Serviço"),
      content: booking.agendamento.servicos.nome,
    },
    {
      label: t("booking.CPF"),
      content: booking.cpf,
    },
    {
      label: t("booking.Nome"),
      content: booking.nome_completo,
    },
    {
      label: t("booking.Telefone"),
      content: booking.telefone,
    },
    {
      label: t("booking.Data"),
      content: dayjs(booking.agendamento.data_aplicacao).format("DD [de] MMM [de] YYYY"),
    },
    {
      label: t("booking.Hora"),
      content: `${dayjs.tz(booking.agendamento.vagas_hora[0].datetime).format("HH:mm")}`,
    },
  ];
  return fields;
};
