import {
  CardRoot,
  CardContent,
  CardTitle,
  CardDescription,
  CardIndicator,
} from "@astrolabe-ui/react";

import { useStepsContext } from "../contexts/StepsContext";
import { useAssetSchedulingContext } from "../contexts/AssetsSchedulingContext";

export const Units = () => {
  const { institution } = useAssetSchedulingContext();

  const { handleNextStep, setDataAssetsScheduling, dataAssetsScheduling } = useStepsContext();

  const handleSelectUnit = (id: number) => {
    setDataAssetsScheduling &&
      setDataAssetsScheduling((prevState) => ({
        unidade_id: id,
        documento: prevState?.documento || "",
        nome_completo: prevState?.nome_completo || "",
        telefone: prevState?.telefone || "",
        email: prevState?.email || "",
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-5">
      {institution?.unidades.map((uni) => (
        <div key={uni.id} className="w-full">
          {/* <Card
            title={uni.titulo}
            description={uni.endereco}
            className={cn("", {
              "border-primary-500": uni.id === dataAssetsScheduling?.unidade_id,
            })}
            onClick={() => handleSelectUnit(uni.id)}
          /> */}

          <CardRoot
            data-selected={uni.id === dataAssetsScheduling?.unidade_id}
            className="data-[selected=true]:border-primary-500"
            onClick={() => handleSelectUnit(uni.id)}
          >
            <CardContent>
              <CardTitle>{uni.titulo}</CardTitle>
              <CardDescription>{uni.endereco}</CardDescription>
            </CardContent>

            <CardIndicator />
          </CardRoot>
        </div>
      ))}
    </div>
  );
};
