import { Button, Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton, Loading, ModalImage } from "@/components";
import { Steps } from "./steps";
import { getAppSlug } from "@/services/old/starter";
import { useAppStore } from "@/store/useAppStore";
import { useInstitution } from "./hooks/useInstitution";
import { InstitutionContext } from "./contexts/InstitutionContext";

export const PresentialScheduling = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [showBanner, setShowBanner] = useState(false);

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [open, setOpen] = useState(false);

  const { data, isLoading } = useInstitution({
    id: Number(params.id),
    appId,
  });

  function handleSteps() {
    setOpen((prevState) => !prevState);
  }

  function handleNavigate() {
    history.goBack();
  }

  useEffect(() => {
    if (data && data.banner_servico) {
      setShowBanner(!!data.visualizacao_banner_servico);
    }
  }, [data]);

  return (
    <>
      <Layout>
        <Header>
          <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
            <div className="flex-1">
              <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleNavigate} />
            </div>

            <Text size="md" weight="medium" leading="relaxed" className="truncate text-textHeader">
              {t("booking.Agendamento presencial")}
            </Text>
          </div>
        </Header>

        <Content>
          <div className="flex min-h-full w-full justify-center p-4">
            {isLoading ? (
              <Loading size="md" variant="secondary" />
            ) : (
              data && (
                <div className="flex flex-col justify-center">
                  <InstitutionContext.Provider value={{ institution: data }}>
                    <div className="flex justify-center">
                      <img
                        src={data.imagem}
                        className="w-full max-w-[200px] object-contain"
                        height="auto"
                        alt="Logo da instituição"
                      />
                    </div>

                    <div className="w-full">
                      <Text color="slate-700" className="text-center text-md" weight="medium">
                        {data.nome}
                      </Text>
                    </div>

                    <div
                      className="flex-1 px-3 pb-8 pt-4 text-center font-sans text-sm text-slate-400 [&_strong]:font-semibold"
                      dangerouslySetInnerHTML={{ __html: data.descricao }}
                    />

                    <div className="mt-auto flex flex-col gap-3">
                      <Button
                        variant="secondary"
                        size="lg"
                        full
                        onClick={() => history.push(`/${getAppSlug()}/presential-scheduling`)}
                      >
                        {t("booking.Consultar agendamento")}
                      </Button>

                      <Button size="lg" full onClick={() => handleSteps()}>
                        {t("booking.Realizar agendamento")}
                      </Button>
                    </div>

                    {open ? <Steps isOpen={open} handleClose={handleSteps} /> : null}
                  </InstitutionContext.Provider>
                </div>
              )
            )}
          </div>
        </Content>
      </Layout>

      {data?.banner_servico ? (
        <ModalImage
          show={showBanner}
          image={data?.banner_servico}
          onHidden={() => setShowBanner(false)}
          closeButton={true}
        />
      ) : null}
    </>
  );
};
