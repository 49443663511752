import { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";

import { cn } from "@/lib/utils";
import { HourButton } from "./HourButton";
import { useAssetSchedulingContext } from "../../contexts/AssetsSchedulingContext";
import { useStepsContext } from "../../contexts/StepsContext";

import { startWithBloqued, selectedWithBloqued } from "./utils/bloqued";
import { handleSelect } from "./utils/handleSelect";
import { selectInterval } from "./utils/selectInterval";
import { selectNext } from "./utils/selectNext";
import { getBookings } from "./utils/getBookings";
import { getDefaultTimeSelected } from "./utils/defaultTimeSelected";
import { getDefaultAmountTime } from "./utils/defaultAmountTime";
import { getWorkSchedule } from "./utils/getWorkSchedule";
import { getLengthTimeSteps } from "./utils/getLengthTimeSteps";
import { getArrayTimeSteps } from "./utils/getArrayTimeSteps";
import { handleConfirmInterval } from "./utils/handleConfirmInterval";

export interface IPlace {
  id: number;
  nome: string;
  step: number;
  descricao: string;
  antecedencia: number;
  minutos_antecedencia?: number | undefined;
  maximo: number;
  status: boolean;
  minimo: number;
}

export const Time = () => {
  const { institution } = useAssetSchedulingContext();

  const { dataAssetsScheduling, setDataAssetsScheduling, handleNextStep } = useStepsContext();

  const bookings = getBookings(institution, dataAssetsScheduling);

  const [amountTime, setAmountTime] = useState(getDefaultAmountTime(dataAssetsScheduling));

  const [timeSelected, setTimeSelected] = useState<Array<string>>(
    getDefaultTimeSelected(dataAssetsScheduling),
  );

  const unit = institution?.unidades.find((uni) => uni.id === dataAssetsScheduling?.unidade_id);

  const place: IPlace | undefined = unit?.espacos.find(
    (pl) => pl.id === dataAssetsScheduling?.espaco_id,
  );

  const { t } = useTranslation();

  const workSchedule = getWorkSchedule(institution, dataAssetsScheduling);

  const arrayTimeSteps = getArrayTimeSteps(
    getLengthTimeSteps(workSchedule, dataAssetsScheduling),
    workSchedule,
    dataAssetsScheduling,
  );

  const dateSelected = dataAssetsScheduling?.extra_fields?.date;

  if (!dateSelected) {
    throw new Error("Previous steps not defined");
  }

  const dateSelectedFormatted = dayjs(dateSelected).format("dddd[,] DD [de] MMMM [de] YYYY");

  return (
    <div className="flex flex-1 flex-col gap-4">
      <Text size="sm" color="slate-700" weight="medium" className="first-letter:uppercase">
        {dateSelectedFormatted}
      </Text>

      <div className="flex flex-1 flex-wrap">
        <div className="grid grow grid-cols-3 gap-2 self-start xs:grid-cols-4">
          {arrayTimeSteps.map((time, index) => (
            <div key={index}>
              <HourButton
                key={index}
                time={time}
                selected={timeSelected.includes(time)}
                onClick={() =>
                  handleSelect(
                    time,
                    timeSelected.includes(time),
                    setTimeSelected,
                    setAmountTime,
                    timeSelected,
                    dataAssetsScheduling,
                  )
                }
                className="px-5"
                textClassName="text-sm text-center"
                bloqued={startWithBloqued(time, timeSelected, bookings, place, workSchedule)}
                {...(timeSelected.length > 1 && {
                  interval: selectInterval(time, timeSelected),
                })}
                {...(timeSelected.length > 0 && {
                  next: selectNext(time, timeSelected, dataAssetsScheduling),
                  bloqued:
                    selectedWithBloqued(time, timeSelected, dataAssetsScheduling) ||
                    startWithBloqued(time, timeSelected, bookings, place, workSchedule),
                })}
              />
            </div>
          ))}
        </div>

        <Button
          full
          size="lg"
          className={cn("mt-5 self-end", {
            "animate-pulse bg-primary-300":
              (dataAssetsScheduling?.extra_fields?.min as number) - amountTime > 0 ||
              timeSelected.length !== 2,
          })}
          onClick={() =>
            handleConfirmInterval(
              timeSelected,
              dataAssetsScheduling,
              setDataAssetsScheduling,
              handleNextStep,
            )
          }
          disabled={
            (dataAssetsScheduling?.extra_fields?.min as number) - amountTime > 0 ||
            timeSelected.length !== 2
          }
        >
          {t("general.Continuar")}
        </Button>
      </div>
    </div>
  );
};
