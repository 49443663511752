import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  InfiniteScrollCustomEvent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";

interface InfiniteScrollProps {
  children: ReactNode;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
}

export function InfiniteScroll({ children, hasNextPage, fetchNextPage }: InfiniteScrollProps) {
  const { t } = useTranslation();

  return (
    <>
      {children}

      <IonInfiniteScroll
        threshold="200px"
        disabled={!hasNextPage}
        onIonInfinite={async (e: InfiniteScrollCustomEvent) => {
          await fetchNextPage();
          e.target.complete();
        }}
      >
        <IonInfiniteScrollContent
          loadingSpinner="dots"
          loadingText={t("general.Carregando")}
          className="font-sans text-sm font-medium text-slate-400"
        />
      </IonInfiniteScroll>
    </>
  );
}
