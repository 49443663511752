import { http } from "@/lib/axios";

type GetSchedulingParams = {
  appId: number;
  id: string;
};

type GetSchedulingResponse = {
  id: number;
  user_id: number | null;
  status: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;
  indeferido_texto: string | null;
  recurso: {
    id: number;
    titulo: string;
    texto_instrucoes: string | null;
    fila: boolean;
  };
  local: {
    id: number;
    titulo: string;
    endereco: {
      endereco_formatado: string;
      lat: string;
      long: string;
    };
  };
  hora: {
    id: number;
    hora: string;
  };
  data: {
    id: number;
    data_agendamento: string;
  };
  respostas: Array<{
    id: number;
    resposta: string;
    pergunta: {
      titulo: string;
    };
  }>;
  respostas_campos: Array<{
    id: number;
    resposta: string;
    campo: {
      nome: string;
      tipo: string;
    };
  }>;
};

export async function getScheduling({ appId, id }: GetSchedulingParams) {
  try {
    const response = await http.get<GetSchedulingResponse>(
      `v3/${appId}/mobile/show_cadastro/${id}`,
    );

    const data = response.data;

    return {
      id: data.id,
      userId: data.user_id,
      name: data.nome,
      cpf: data.cpf,
      email: data.email,
      phone: data.telefone,
      status: data.status,
      justificationForRejection: data.indeferido_texto,
      resource: {
        id: data.recurso.id,
        name: data.recurso.titulo,
        textInstructions: data.recurso.texto_instrucoes,
        enabledQueue: data.recurso.fila,
      },
      hour: {
        id: data.hora.id,
        date: data.hora.hora,
      },
      date: {
        id: data.data.id,
        date: data.data.data_agendamento,
      },
      local: {
        id: data.local.id,
        name: data.local.titulo,
        address: {
          address: data.local.endereco.endereco_formatado,
          lat: data.local.endereco.lat,
          lng: data.local.endereco.long,
        },
      },
      questions: data.respostas.map((item) => ({
        id: item.id,
        answer: item.resposta,
        question: item.pergunta.titulo,
      })),
      extraFields: data.respostas_campos.map((item) => ({
        id: item.id,
        answer: item.resposta,
        type: item.campo.tipo,
        field: item.campo.nome,
      })),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
