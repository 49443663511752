import styled, { css } from "styled-components";
import { IonTextarea } from "@ionic/react";

export const ChatWindow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
`;

export const MessageList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  overflow-y: scroll;
  padding: 0.5rem;
  padding-bottom: calc(4rem + env(safe-area-inset-bottom));

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MessageProps {
  attendant: boolean;
}

export const Message = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.attendant ? "flex-start" : "flex-end")};

  span {
    display: inline-block;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    max-width: 75vw;

    font-size: 0.875rem;
    line-height: 1.25rem;
    overflow-wrap: anywhere;

    ${(props) =>
      props.attendant &&
      css`
        border-bottom-left-radius: 0;
        background-color: #e2e8f0;
        color: #1e293b;
      `}

    ${(props) =>
      !props.attendant &&
      css`
        border-bottom-right-radius: 0;
        background-color: #6da0e5;
        color: #ffffff;
      `}
  }

  time {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #475569;
  }
`;

export const DateInfo = styled.time`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  padding: 0.125rem 0.5rem;
  border-radius: 99999px;
  align-self: center;

  background-color: #e2e8f0;
  color: #475569;
`;

interface DownloadMessageProps {
  attendant: boolean;
}

export const DownloadMessage = styled.div<DownloadMessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.attendant ? "flex-start" : "flex-end")};

  div {
    cursor: pointer;
    border: 0;
    background-color: transparent;

    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    ${(props) =>
      props.attendant &&
      css`
        border-bottom-left-radius: 0;
        background-color: #e2e8f0;
        color: #1e293b;
      `}

    ${(props) =>
      !props.attendant &&
      css`
        border-bottom-right-radius: 0;
        background-color: #6da0e5;
        color: #ffffff;
      `}

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: #ffffff;

    ${(props) =>
      props.attendant &&
      css`
        border: 1px solid #94a3b8;
      `}

    ${(props) =>
      !props.attendant &&
      css`
        border: 1px solid #312e81;
      `}

    ion-icon {
      font-size: 1rem;
      color: #475569;
    }
  }

  time {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #475569;
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding-top: calc(env(safe-area-inset-top) + 1rem);
  padding-right: calc(env(safe-area-inset-right) + 0.75rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
  padding-left: calc(env(safe-area-inset-left) + 0.75rem);
`;

export const InputBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  height: 3rem;
  width: 100%;
  padding: 0 0.75rem;

  border-radius: 1.5rem;
  background-color: #f3f4f6;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
`;

export const Input = styled(IonTextarea)`
  flex: 1;
  max-width: 100%;
  margin: 0;

  font-size: 1rem;
  line-height: 1.5rem;
  color: #1e293b;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #94a3b8;
  }

  &:-ms-input-placeholder {
    color: #94a3b8;
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #f3f4f6;

  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  ion-icon {
    font-size: 1.125rem;
    color: #6da0e5;
  }
`;

export const SendFileButton = styled.button`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  padding: 0;

  svg {
    font-size: 1.25rem;
    color: #6da0e5;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 45%;
  background-color: #94a3b8;
`;
