import { MouseEvent } from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "@astrolabe-ui/react";
import { UploadSimple, X } from "@phosphor-icons/react";

import { FieldProps } from "../question";
import { Loading } from "@/components/index";
import { useSendTemporaryFile } from "../../hooks/use-send-tempory-file";
import { useAppId } from "@/store/useAppStore";
import { cn } from "@/lib/utils";

export function FileField({ value, onChange, field }: FieldProps) {
  const appId = useAppId();

  const {
    formState: { errors },
  } = useFormContext();

  const { mutateAsync, isLoading } = useSendTemporaryFile();

  function handleChangeFile(file: Blob) {
    mutateAsync(
      { appId: Number(appId), file },
      {
        onSuccess: (url: string) => onChange(url),
        onError: (err) => console.error(err),
      },
    );
  }

  function removeFile(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    onChange("");
  }

  const error = errors[field.id]?.message ? String(errors[field.id]?.message) : undefined;

  return (
    <div className="flex flex-col gap-1">
      <label
        htmlFor={String(field.id)}
        className={cn(
          "flex flex-col items-center justify-center gap-3 rounded-md border-thin border-slate-200 p-4",
          {
            "border-red-500": !!error,
          },
        )}
      >
        <div className="flex items-center gap-2">
          <UploadSimple className="text-md text-primary-500" alt="Icon file" />
          <Text size="xs" color="primary-500">
            {field.name}
          </Text>
        </div>

        {value ? (
          <div className="flex w-full items-center justify-between gap-2">
            <Text color="slate-700" className="truncate">
              {value}
            </Text>
            <button
              onClick={removeFile}
              className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-slate-100"
            >
              <X weight="bold" size={12} className="text-slate-700" />
            </button>
          </div>
        ) : null}

        {isLoading ? <Loading variant="secondary" /> : null}

        <input
          id={String(field.id)}
          type="file"
          className="hidden"
          onChange={(event) => {
            if (event.target.files?.length) {
              handleChangeFile(event.target.files[0]);
            }
          }}
        />
      </label>

      {error ? (
        <Text size="xs" className="text-red-500">
          {error}
        </Text>
      ) : null}

      {!error && !!field.hint ? <Text size="xs">{field.hint}</Text> : null}
    </div>
  );
}
