import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Text, TextArea } from "@astrolabe-ui/react";

import { Button, Modal } from "@/components";
import { Scheduling } from "@/services/schedulings";
import { useQueryClient } from "@tanstack/react-query";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { CardSchedulingInfos } from "./card-scheduling-infos";
import { useCancelScheduling } from "../hooks/use-cancel-scheduling";

type CancelSchedulingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scheduling: Pick<Scheduling, "id" | "name" | "date" | "hour" | "local" | "resource">;
};

export function CancelSchedulingModal({ isOpen, onClose, scheduling }: CancelSchedulingModalProps) {
  const appId = useAppId();
  const slug = useAppSlug();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState("scheduling");
  const [justification, setJustification] = useState("");

  const { mutateAsync, isLoading } = useCancelScheduling();

  function handleConfirm(continueQueue: boolean) {
    if (currentStep === "scheduling") {
      handleChangeStep("justification");
      return;
    }
    mutateAsync(
      {
        appId: Number(appId),
        id: Number(scheduling.id),
        justification,
        continuarNaFila: continueQueue,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["my-schedulings", appId] });

          toast.success(
            continueQueue
              ? "Agendamento cancelado e retornou para a fila"
              : "Agendamento cancelado",
          );

          handleCloseModal();

          continueQueue
            ? history.push(`/${slug}/agendamentos-fila/${scheduling.id}`)
            : queryClient.invalidateQueries({
                queryKey: ["scheduling", String(scheduling.id), appId],
              });
        },
        onError: () => toast.error("Não foi possível cancelar"),
      },
    );
  }

  function handleChangeStep(step: string) {
    setCurrentStep(step);
  }

  function handleCloseModal() {
    onClose();
    setCurrentStep("scheduling");
    setJustification("");
  }

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className="flex w-full flex-col gap-8">
        {currentStep === "scheduling" ? (
          <div className="flex flex-col gap-6">
            <Text size="md" weight="medium" color="slate-700" className="px-4 text-center">
              Confirme o agendamento que deseja cancelar:
            </Text>

            <CardSchedulingInfos
              local={scheduling.local?.name}
              name={scheduling.name}
              date={scheduling.date?.date}
              hour={scheduling.hour?.date}
            />
          </div>
        ) : null}

        {currentStep === "justification" ? (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1 px-4 text-center">
              <Text size="md" weight="medium" color="slate-700">
                Por qual motivo deseja cancelar seu agendamento?
              </Text>
              <Text size="xs" color="slate-700">
                Ao clicar em confirmar, o seu agendamento será cancelado.
              </Text>
            </div>

            <TextArea
              id="justification"
              name="justification"
              placeholder="Escreve a sua justificativa"
              rows={4}
              value={justification}
              onChange={(event) => setJustification(event.target.value)}
            />
          </div>
        ) : null}

        <div className="flex flex-col items-center gap-3">
          <Button size="lg" variant="tertiary" full onClick={handleCloseModal}>
            Fechar
          </Button>
          {currentStep === "justification" &&
            (scheduling.resource?.enabledQueue ? (
              <>
                <Button
                  size="lg"
                  variant="secondary"
                  full
                  onClick={() => handleConfirm(true)}
                  loading={isLoading}
                >
                  Cancelar e voltar para fila
                </Button>

                <Button size="lg" full onClick={() => handleConfirm(false)} loading={isLoading}>
                  Cancelar agendamento
                </Button>
              </>
            ) : (
              <Button size="lg" full onClick={() => handleConfirm(false)} loading={isLoading}>
                Cancelar agendamento
              </Button>
            ))}
          {currentStep === "scheduling" ? (
            <Button
              size="lg"
              full
              onClick={() => currentStep === "scheduling" && handleChangeStep("justification")}
              loading={isLoading}
            >
              Confirmar cancelamento
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
