import { useHistory, useLocation, useParams } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { OutputView, Text } from "@astrolabe-ui/react";

import { Header } from "@/components/Header";
import { HeaderButton, Loading } from "@/components/index";
import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { getAppSlug } from "@/services/old/starter";
import { useAppStore } from "@/store/useAppStore";

import { useBooking } from "./hooks/useBooking";
import { getFieldsBooking } from "./utils/getFieldsBookings";
import errorIllustrations from "@/assets/illustrations/error.svg";

export const ShowPresentialBooking = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { id } = useParams<{ SLUG: string; id: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { data, isLoading, isError, isSuccess, isFetching } = useBooking(
    appId,
    id,
    String(query.get("agendamento_id")),
  );

  const fields = data && getFieldsBooking(data, t);

  const handleNavigate = () => history.replace(`/${getAppSlug()}/presential-scheduling`);

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text
            size="md"
            weight="medium"
            color="primary-500"
            leading="relaxed"
            className="truncate"
          >
            {t("booking.Agendamento presencial")}
          </Text>
        </div>
      </Header>
      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-5 px-4 pb-6 pt-4 ios:pb-2">
          {data && !isLoading && isSuccess && !isFetching ? (
            <div className="flex h-full flex-col gap-4">
              <Text color="primary-500">{t("booking.Agendamento")}</Text>
              <Text color="slate-700">
                {t("booking.Instituição")}: {data.agendamento.servicos.setor.instituicao.nome}
              </Text>
              <div className="flex flex-col gap-2">
                {fields?.map((field, index) => (
                  <OutputView
                    key={index}
                    heading={field.label}
                    content={field.content}
                    className="flex-1"
                  />
                ))}
              </div>
            </div>
          ) : isError ? (
            <div className="flex h-full flex-col items-center justify-center">
              <img src={errorIllustrations} alt="" />
              <Text className="text-center" weight="medium" color="slate-700">
                {t("booking.Desculpe, algo deu errado")}...
              </Text>
              <Text className="text-center">
                {t("booking.O nosso time de desenvolvedores esta resolvendo isso")}
              </Text>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <Loading variant="secondary" />
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};
