// Core CSS required for Ionic components to work properly
import "@ionic/react/css/core.css";

// Import Swiper styles
import "swiper/css";

// Leaflet
import "leaflet/dist/leaflet.css";

// Styles globals
import "./styles/globals.css";

// Config lib dayJs
import "@/lib/dayjs";

// Config lib i18next
import "@/lib/i18next";

import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import App from "./App";
import { queryClient } from "@/lib/react-query.ts";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", import.meta.env.VITE_API_URL],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
);
