import { IonContent, IonPage } from "@ionic/react";

import { FormOpenProtocol } from "./components/FormOpenProtocol";
import { Container } from "./styles";

export function OpenProtocol() {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Container id="container-step">
          <FormOpenProtocol />
        </Container>
      </IonContent>
    </IonPage>
  );
}
