import i18n from "i18next";
import { useAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { cpf } from "cpf-cnpj-validator";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { Switch, Text, TextInput } from "@astrolabe-ui/react";

import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { SelectRegion } from "../components/SelectRegion";
import { useConfig } from "@/hooks/useConfig";
import { useAppId, useAppSlug, useAppStore } from "@/store/useAppStore";
import { useConfigStore } from "@/store/useConfigStore";
import { useRegions } from "../hooks/useRegions";
import { formatCpf, formatPhone, removeCharacteres } from "@/utils/formats";
import { FormDataType, registerFormAtom } from "..";

type PersonalFormData = Omit<FormDataType, "password" | "passwordConfirmation">;

interface PersonalDataFormProps {
  handleClick: () => void;
}

const personalFormSchema = z.object({
  name: z.string().min(1, i18n.t("auth.Informe seu nome")),
  email: z
    .string()
    .min(1, i18n.t("auth.Informe seu e-mail"))
    .email(i18n.t("auth.Informe um e-mail válido")),
  phone: z
    .string()
    .transform((value) => removeCharacteres(value))
    .refine(
      (value) => {
        const {
          actions: { getConfig },
        } = useConfigStore.getState();

        const showPhone = getConfig("show.profile.phone")?.value === "true";
        const phoneRequired = getConfig("required.profile.phone")?.value === "true";

        return !showPhone || value || (!value && !phoneRequired);
      },
      {
        message: i18n.t("auth.Informe seu celular"),
      },
    ),
  cpf: z
    .string()
    .transform((value) => removeCharacteres(value))
    .refine(
      (value) => {
        const {
          actions: { getConfig },
        } = useConfigStore.getState();

        const showCPF = getConfig("cpf.opcional")?.value === "true";
        const cpfRequired = getConfig("cpf.obrigatorio")?.value === "true";

        return !showCPF || (value && cpf.isValid(value)) || (!value && !cpfRequired);
      },
      {
        message: i18n.t("auth.Informe um CPF válido"),
      },
    ),
  region: z
    .object({
      region: z.string(),
      visitor: z.boolean(),
    })
    .refine((data) => data.region || data.visitor, {
      message: i18n.t("auth.Informe sobre a região"),
    }),
});

export function PersonalForm({ handleClick }: PersonalDataFormProps) {
  const { t } = useTranslation();

  const appId = useAppId();
  const slug = useAppSlug();
  const hasCity = useAppStore((state) => state.app?.location?.city?.name);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [dataForm, setDataForm] = useAtom(registerFormAtom);

  const showCpf = useConfig({
    label: "cpf.opcional",
  });

  const showPhone = useConfig({
    label: "show.profile.phone",
  });

  const { data: regions } = useRegions({
    appId,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<PersonalFormData>({
    resolver: zodResolver(personalFormSchema),
    mode: "onChange",
    defaultValues: {
      name: dataForm.name,
      email: dataForm.email,
      cpf: dataForm.cpf ? cpf.format(dataForm.cpf) : "",
      phone: dataForm.phone ? formatPhone(dataForm.phone) : "",
      region: dataForm.region,
    },
  });

  function onSubmit(data: PersonalFormData) {
    const { name, email, cpf, phone, region } = data;

    setDataForm({
      ...dataForm,
      name,
      email,
      cpf,
      phone,
      region,
    });

    handleClick();
  }

  function handleOnChangeIsVisitor(value: boolean) {
    if (value) {
      setValue("region.region", "");
      clearErrors("region");
    }
  }

  const isVisitor = watch("region.visitor");

  return (
    <form
      id="personalForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={1}
        title={t("auth.Dados do usuário")}
        description={t("auth.Preencha os campos com os dados pessoais solicitados.")}
      />

      <div className="flex flex-col gap-6">
        <TextInput
          floating
          placeholder={t("auth.Nome completo")}
          error={errors.name?.message}
          {...register("name")}
        />

        <TextInput
          floating
          type="email"
          placeholder={t("auth.E-mail")}
          autoCapitalize="none"
          error={errors.email?.message}
          {...register("email")}
        />

        {showCpf ? (
          <TextInput
            floating
            id="cpf"
            type="tel"
            inputMode="numeric"
            placeholder={t("auth.CPF")}
            error={errors.cpf?.message}
            {...register("cpf", {
              onChange: (event) => setValue("cpf", formatCpf(event.target.value)),
            })}
          />
        ) : null}

        {showPhone ? (
          <TextInput
            floating
            id="phone"
            type="tel"
            inputMode="numeric"
            placeholder={t("auth.Celular")}
            maxLength={17}
            error={errors.phone?.message}
            {...register("phone", {
              onChange: (event) => setValue("phone", formatPhone(event.target.value)),
            })}
          />
        ) : null}

        <div className="space-y-3">
          <Controller
            control={control}
            name="region.visitor"
            render={({ field: { value, onChange } }) => (
              <div className="flex items-center justify-between">
                <Text>Não sou morador {hasCity ? "da cidade" : "do estado"}</Text>

                <Switch
                  checked={value}
                  onCheckedChange={(value) => {
                    handleOnChangeIsVisitor(value);
                    onChange(value);
                  }}
                />
              </div>
            )}
          />

          <Controller
            control={control}
            name="region.region"
            render={({ field: { value, onChange } }) => (
              <SelectRegion
                title={`${hasCity ? "Selecione o bairro" : "Selecione a cidade"}`}
                disabled={!!isVisitor}
                selected={value}
                error={errors.region?.message}
                regions={regions ?? []}
                onChange={(value) => {
                  clearErrors("region");
                  onChange(value);
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
}
