import styled from "styled-components";
import { IonText, IonRouterLink, IonIcon, IonButton } from "@ionic/react";
import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
  call,
  logoWhatsapp,
  person,
} from "ionicons/icons";

import { Block } from "@/components/old/Block";
import { COLORS } from "@/data/colors";
import { useAppStore } from "@/store/useAppStore";
import { removeCaracteresNaoNumericos } from "@/utils/removeCaracteresNaoNumericos";

const SellerInfoDiv = styled.div`
  margin-left: 10px;
`;

const TopDiv = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 10px;
  border-bottom: solid ${COLORS.BORDER} 1px;
`;

const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
`;

const SocialDiv = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: block;
`;

const SellerName = styled(IonText)`
  font-size: 16px;
  display: flex;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${COLORS.PRIMARY};
  font-style: normal;
  font-weight: normal;
`;

const SellerAddress = styled(IonText)`
  font-size: 14px;
  display: flex;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
`;

const SellerCategory = styled(IonText)`
  font-size: 14px;
  display: flex;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
`;

const SellerPhone = styled(IonText)`
  font-size: 14px;
  width: 50%;
  margin-top: 0px;
  height: 40px;
  padding-top: 13px;
  padding-left: 5px;
  margin-bottom: 0px;
  vertical-align: center;
  font-style: normal;
  font-weight: normal;
  flex-grow: 3;
`;

const SocialIcon = styled(IonIcon)`
  font-size: 30px;
`;

const WhatsAppButton = styled(IonButton)``;

const SellerlIcon = styled(IonIcon)`
  width: 30px;
  height: 30px;
  margin: 10px;
  color: gray;
`;

const PhoneIcon = styled(IonIcon)`
  margin-left: 5px;
  padding-top: 4px;
  font-size: 16px;
`;

const Container = styled(IonRouterLink)`
  display: block;
`;

const SocialBtm = styled.a`
  padding-top: 4px;
  width: 40px;
  height: 40px;
`;

interface BlockSellerProps {
  sellerName?: string;
  sellerCategory?: string;
  sellerImage?: string;
  sellerPhone?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  twitter?: string;
  address?: string;
  openURL?: string;
}

export function BlockSeller(props: BlockSellerProps) {
  const appName = useAppStore((state) => state.app?.name);

  function handleOpenWhatsapp() {
    const message = `Olá, encontrei seu perfil no aplicativo ${appName}. Gostaria de saber mais sobre os produtos/serviços que você oferece.`;

    const phone = removeCaracteresNaoNumericos(props.sellerPhone ?? "");

    window.open(`https://wa.me/55${phone}?text=${encodeURIComponent(message)}`, "_blank");
  }

  return (
    <Block noPadding data-testid="seller-div">
      <Container routerLink={props.openURL}>
        <TopDiv>
          {props.sellerImage && <Image src={props.sellerImage} alt="image" />}
          {!props.sellerImage && (
            <div
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "25px",
                backgroundColor: "lightgray",
              }}
            >
              <SellerlIcon icon={person} />
            </div>
          )}
          <SellerInfoDiv>
            <SellerName color="dark">{props.sellerName}</SellerName>
            <SellerCategory color="dark">{props.sellerCategory}</SellerCategory>
            <SellerAddress color="gray">{props.address}</SellerAddress>
          </SellerInfoDiv>
        </TopDiv>
      </Container>

      <BottomDiv>
        <PhoneIcon slot="icon-only" color="dark" icon={call} />
        <SellerPhone color="dark">{props.sellerPhone}</SellerPhone>
        <WhatsAppButton
          color="success"
          size="small"
          shape="round"
          fill="outline"
          onClick={handleOpenWhatsapp}
        >
          <IonIcon slot="start" icon={logoWhatsapp} size="small" />
          WhatsApp
        </WhatsAppButton>
      </BottomDiv>

      <SocialDiv>
        {props.facebook && (
          <SocialBtm href={props.facebook} data-testid="facebook">
            <SocialIcon slot="icon-only" icon={logoFacebook} data-testid="social-facebook" />
          </SocialBtm>
        )}

        {props.twitter && (
          <SocialBtm href={props.twitter} target="_blank" data-testid="twitter">
            <SocialIcon slot="icon-only" icon={logoTwitter} data-testid="social-twitter" />
          </SocialBtm>
        )}

        {props.instagram && (
          <SocialBtm href={props.instagram} target="_blank" data-testid="instagram">
            <SocialIcon slot="icon-only" icon={logoInstagram} data-testid="social-instagram" />
          </SocialBtm>
        )}

        {props.youtube && (
          <SocialBtm href={props.youtube} target="_blank" data-testid="youtube">
            <SocialIcon slot="icon-only" icon={logoYoutube} data-testid="social-youtube" />
          </SocialBtm>
        )}
      </SocialDiv>
    </Block>
  );
}
