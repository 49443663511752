import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type Territory = {
  id: number;
  name: string;
  cities: Array<string>;
};

export const territoriesAtom = atomWithStorage<Array<Territory>>("territories", []);

export const selectedTerritoryAtom = atom<number>(2);
