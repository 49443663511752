import { QRCodeSVG } from "qrcode.react";

import { Wallet as WalletProps } from "@/services/wallets";
import { cn } from "@/lib/utils";

interface WalletComponentProps {
  wallet: WalletProps;
  qrcodeUrl?: string;
  className?: string;
}

export function Wallet({ wallet, qrcodeUrl, className }: WalletComponentProps) {
  return (
    <div
      style={{
        backgroundImage: wallet.imageBackground ? `url(${wallet.imageBackground})` : undefined,
      }}
      className={cn(
        "relative mb-8 flex h-[calc(((100vw-2rem)*9)/16)] w-full flex-col justify-between overflow-hidden rounded-lg border-medium border-walletPrimary bg-walletPrimary/40 bg-cover bg-center bg-no-repeat p-[4%] print:mx-auto print:mb-0 print:h-[calc(((9.6cm)*9)/16)] print:w-[9.6cm] print:rounded-[0] print:border-[3px] print:p-[0.384cm]",
        className,
      )}
    >
      <div className={`z-[5] flex ${wallet?.holder.imageUrl ? "justify-between" : "justify-end"}`}>
        {wallet?.holder.imageUrl && (
          <div className="h-[20vw] w-[20vw] overflow-hidden rounded-xs border-thin border-walletPrimary print:h-[1.92cm] print:w-[1.92cm]">
            <img src={wallet.holder.imageUrl} alt="" className="h-full w-full object-cover" />
          </div>
        )}

        {qrcodeUrl ? (
          <div className="h-[19vw] w-[19vw] rounded-xs bg-white p-[1vw] print:h-[1.82cm] print:w-[1.82cm] print:p-[0.1cm]">
            <QRCodeSVG value={qrcodeUrl} className="h-full w-full object-cover" />
          </div>
        ) : null}
      </div>

      <div className="z-[5] flex items-end justify-between">
        <div className="flex flex-col">
          <strong className="font-bold text-[4vw] leading-[5.5vw] text-walletSecondary print:text-[0.384cm] print:leading-[0.52cm]">
            {wallet.holder.name}
          </strong>
          <span className="text-[3.2vw] font-medium leading-[5.5vw] text-walletSecondary print:text-[0.30cm] print:leading-[0.52cm]">
            {wallet.name}
          </span>
          <span className="text-[3.2vw] font-medium leading-[5.5vw] text-walletSecondary print:text-[0.30cm] print:leading-[0.52cm]">
            {wallet.issuingBody}
          </span>
        </div>

        {wallet.imageLogo ? (
          <div className="h-[10vw] max-w-[20vw] flex-shrink-0 print:h-[0.96cm]">
            <img src={wallet.imageLogo} alt="" className="h-full w-full object-contain" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
