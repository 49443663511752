import i18n from "i18next";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "@astrolabe-ui/react";

import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { FormDataType, registerFormAtom } from "..";

type SecurityFormData = Omit<FormDataType, "name" | "email" | "phone" | "cpf">;

interface SecurityDataFormProps {
  handleClick: () => void;
}

const securityFormSchema = z
  .object({
    password: z.string().min(6, i18n.t("auth.A senha deve possuir no mínimo 6 caracteres")).trim(),
    passwordConfirmation: z.string().min(6, i18n.t("auth.Informe a senha novamente")).trim(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message: i18n.t("auth.As senhas devem serem iguais"),
  });

export function SecurityForm({ handleClick }: SecurityDataFormProps) {
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useAtom(registerFormAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SecurityFormData>({
    resolver: zodResolver(securityFormSchema),
    mode: "onChange",
    defaultValues: {
      password: dataForm.password,
      passwordConfirmation: dataForm.passwordConfirmation,
    },
  });

  function onSubmit(data: SecurityFormData) {
    const { password, passwordConfirmation } = data;

    setDataForm({
      ...dataForm,
      password,
      passwordConfirmation,
    });

    handleClick();
  }

  return (
    <form
      id="securityForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={2}
        title={t("auth.Criação de senha")}
        description={t(
          "auth.Crie e confirme sua nova senha de acesso. Não se preocupe caso esquecer, você poderá criar uma nova senha.",
        )}
      />

      <div className="flex flex-col gap-6">
        <PasswordInput
          floating
          placeholder={t("auth.Senha")}
          textShow={t("Exibir")}
          textHide={t("Ocultar")}
          autoCapitalize="none"
          error={errors.password?.message}
          autoComplete="off"
          {...register("password", {
            onChange: (event) =>
              setDataForm({
                ...dataForm,
                password: event?.target.value,
              }),
          })}
        />

        <PasswordInput
          floating
          placeholder={t("auth.Confirmação da senha")}
          textShow={t("Exibir")}
          textHide={t("Ocultar")}
          autoCapitalize="none"
          error={errors.passwordConfirmation?.message}
          autoComplete="off"
          {...register("passwordConfirmation", {
            onChange: (event) =>
              setDataForm({
                ...dataForm,
                passwordConfirmation: event.target.value,
              }),
          })}
        />
      </div>
    </form>
  );
}
