import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";
import { IWorkSchedule } from "./getWorkSchedule";

export const getArrayTimeSteps = (
  length: number,
  workSchedule?: IWorkSchedule,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  Array.from({ length }, (_, index) =>
    dayjs(workSchedule?.hora_inicio)
      .add((dataAssetsScheduling?.extra_fields?.step as number) * index, "minutes")
      .format("HH:mm"),
  );
