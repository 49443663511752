import { Text } from "@astrolabe-ui/react";

interface StepIndicatorProps {
  step: number;
  title: string;
  description: string;
}

export function StepIndicator({ step, title, description }: StepIndicatorProps) {
  return (
    <header className="flex w-full flex-col items-center gap-2">
      <div className="flex h-9 w-9 items-center justify-center rounded-full border-thin border-slate-200">
        <Text size="lg" weight="medium" color="primary-500">
          {step}
        </Text>
      </div>

      <div className="flex flex-col items-center gap-1 text-center">
        <Text size="md" weight="medium" color="slate-700">
          {title}
        </Text>
        <Text size="xs" weight="medium" color="slate-400">
          {description}
        </Text>
      </div>
    </header>
  );
}
