import { http } from "@/lib/axios";
import { getAppId } from "./provider";
import { getAccessToken } from "./auth";
import { useAppStore } from "@/store/useAppStore";

export async function sendProtocolMessage(id: number, message: string, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http.post(
    "v2/" + APP_ID + "/protocolos/" + id + "/mensagens",
    { mensagem: message },
    headers,
  );
}

export async function getProtocolMessages(id: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  const { app } = useAppStore.getState();

  const appId = APP_ID ?? app?.id;

  return http.get("v2/" + appId + "/protocolos/" + id + "/mensagens");
}

export async function confirmProtocolMessages(id: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  const { app } = useAppStore.getState();

  const appId = APP_ID ?? app?.id;

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  return http.get("v2/" + appId + "/protocolo/" + id + "/confirmarMensagens", headers);
}

export async function getMeusProtocolos(SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  return http.get("v2/" + APP_ID + "/usuarios/mensagensNaoLidas", headers);
}

export async function sendProtocolFile(id: number, file: any, SLUG: string) {
  const formData = new FormData();

  formData.append("arquivo", file);

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http.post("v2/" + APP_ID + "/protocolos/" + id + "/mensagens/arquivo", formData, headers);
}
