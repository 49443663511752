import { useHistory, useParams } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import * as Accordion from "@radix-ui/react-accordion";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton } from "@/components";
import { LoadingFaqs } from "../../components/LoadingFaqs";
import { FilterQuestionByTitle } from "./components/FilterQuestionByTitle";
import { EmptyQuestions } from "./components/EmptyQuestions";
import { QuestionAccordion } from "./components/QuestionAccordion";
import { useFaqCategory } from "./hooks/useFaqCategory";
import { useAppId } from "@/store/useAppStore";

export function FAQCategory() {
  const history = useHistory();
  const appId = useAppId();

  const { faqId, categoryId } = useParams<{ faqId: string; categoryId: string }>();

  const { data, isLoading } = useFaqCategory({
    appId,
    faqId: faqId ? Number(faqId) : undefined,
    categoryId: faqId ? Number(categoryId) : undefined,
  });

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              icon={<CaretLeft weight="bold" />}
              onClick={handleGoBack}
              variant="secondary"
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {data?.faq.title}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="flex min-h-full w-full flex-col gap-4 px-4 pb-6 pt-4 ios:pb-2">
          <FilterQuestionByTitle />

          {!isLoading && data ? (
            <div className="flex flex-col gap-4">
              <Text size="md" weight="medium" color="slate-700">
                {data.title}
              </Text>

              <Accordion.Root type="multiple" className="flex flex-col gap-5">
                {data.questions.map((item) => (
                  <QuestionAccordion
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </Accordion.Root>
            </div>
          ) : null}

          {isLoading ? <LoadingFaqs /> : null}

          {!data?.questions.length && !isLoading ? <EmptyQuestions /> : null}
        </div>
      </Content>
    </Layout>
  );
}
