import { useQuery } from "@tanstack/react-query";
import { getTrackerEvent } from "@/services/events";

type UseTrackerEventParams = {
  id: number;
  enabled: boolean;
};

export function useTrackerEvent({ id, enabled }: UseTrackerEventParams) {
  return useQuery({
    queryKey: ["tracker-event", id],
    queryFn: () => getTrackerEvent({ id }),
    enabled: !!id && enabled,
    refetchInterval: 10000,
  });
}
