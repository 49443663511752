import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Avatar, Box, cn, Text } from "@astrolabe-ui/react";
import { ArrowArcRight, X } from "@phosphor-icons/react";

import { useAppStore } from "@/store/useAppStore";

import { http } from "@/lib/axios";

import { UpdateHolder } from "..";
import { Holder } from "@/services/holder/types";

export const ImageField = ({
  data,
  handleUserViewedField,
  isLoading,
}: {
  data: Holder;
  handleUserViewedField: (id: number) => void;
  isLoading?: boolean;
}) => {
  const { setValue, control, reset } = useFormContext<UpdateHolder>();

  const [showFeedback, setShowFeedBack] = useState(
    data?.reviews.length > 0 && data.reviews?.some((review) => review.field === "foto"),
  );

  const { t } = useTranslation();

  const appId = useAppStore((state) => state.app?.id);

  const imageUrlWatch = useWatch({ control, name: "foto" });

  const [imageLoading, setImageLoading] = useState(false);

  const handleFile = (fileList: FileList | null) => {
    if (fileList !== null && fileList.length > 0) {
      setShowFeedBack(true);
      setImageLoading(true);
      const formData = new FormData();
      formData.append("file", fileList[0]);
      http
        .post(`/v3/${appId}/upload/file`, formData)
        .then((res: any) => {
          setValue("foto", res.data.file_url);
        })
        .catch((error: any) => {
          console.log("ERROR handleFile =", error);
        })
        .finally(() => {
          setImageLoading(false);
        });
    }
  };

  const onReview = data.reviews.some(
    (review) => review.field === "foto" && review.status === "Em Revisão",
  );

  const rejected = data.reviews.some(
    (review) => review.field === "foto" && review.status === "Rejeitado",
  );

  const review = data.reviews.find((review) => review.field === "foto");

  useEffect(() => {
    setShowFeedBack(
      data?.reviews.length > 0 && data.reviews?.some((review) => review.field === "foto"),
    );
  }, [data]);

  return (
    <>
      <label htmlFor="imageUrl" className="flex gap-6">
        <Avatar alt="titular avatar" size="xl" src={data.foto as string} />
        {showFeedback && (
          <>
            <div
              className="flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <ArrowArcRight
                weight="bold"
                className={cn("text-[42px]", {
                  "text-primary-500": data.reviews.length === 0,
                  "text-orange-600": onReview,
                  "text-red-500": rejected,
                })}
              />
            </div>
            {imageLoading || isLoading ? (
              <LoadingImage />
            ) : (
              <Avatar
                alt="titular new avatar"
                className={cn({
                  "border-orange-600": onReview,
                  "border-red-500": rejected,
                })}
                size="xl"
                src={imageUrlWatch || review?.new_value}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
          </>
        )}
        <input
          id="imageUrl"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            handleFile(e.target.files);
          }}
        />
      </label>
      {review && (
        <>
          {rejected && !review?.user_viewed && (
            <Box className="flex w-full items-center justify-between border-red-500/10 bg-red-500/10 p-2">
              <Text color="red-500" className="whitespace-pre-line">
                {review.justification
                  ? `${review.justification.replaceAll("; ", "; \n")}`
                  : `A nova ${t(`holder.${review?.field}`)} foi rejeitada`}
              </Text>

              <button
                type="button"
                className="flex h-6 w-6 items-center justify-center bg-transparent"
                onClick={() => {
                  setShowFeedBack(false);
                  reset();
                  handleUserViewedField(Number(review?.id));
                }}
              >
                <X size={12} weight="bold" className="flex-shrink-0 text-slate-700" />
              </button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

const LoadingImage = () => (
  <div className="flex h-24 w-24 animate-fadeIn items-center justify-center overflow-hidden rounded-full border-medium border-primary-200">
    <div className="h-5 w-5 animate-spin rounded-full border-[3px] border-primary-200 border-e-primary-500"></div>
  </div>
);
