import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { OutputView, Switch, Text } from "@astrolabe-ui/react";

import { cn } from "@/lib/utils";
import { useAuthStore } from "@/store/useAuthStore";
import { Field, Subcategory } from "@/services/categoryServices";

interface ReviewsProps {
  fields: Array<Field>;
  service: Subcategory;
  allowsOpenAnonymously: boolean;
}

export const Review = ({ fields, service, allowsOpenAnonymously }: ReviewsProps) => {
  const { getValues, unregister, setValue } = useFormContext();

  const fieldsWithValue = fields.filter((field) =>
    (field.type === "arquivo" || field.type === "imagem") && getValues(field.id.toString())
      ? getValues(field.id.toString()).length !== 0
      : getValues(field.id.toString()),
  );

  const token = useAuthStore((state) => state.token);

  useEffect(() => {
    fields.forEach((field) => {
      if (
        (field.type === "arquivo" ||
          field.type === "imagem" ||
          field.type === "string" ||
          field.type === "data") &&
        (getValues(field.id.toString())?.length === 0 ||
          getValues(field.id.toString()) === "" ||
          getValues(field.id.toString()) === undefined)
      ) {
        unregister(field.id.toString());
      }
    });
  }, [fields, getValues, unregister]);

  return (
    <div
      className={cn("flex w-full flex-wrap gap-4", {
        grow: fieldsWithValue.length <= 0,
      })}
    >
      {fieldsWithValue.length > 0 ? (
        fieldsWithValue.map((field) => (
          <OutputView
            key={field.id}
            content={
              field.type === "location"
                ? getValues(field.id.toString()).address
                : getValues(field.id.toString())
            }
            heading={field.name}
            className="w-full"
          />
        ))
      ) : (
        <div className="flex items-center justify-center">
          <OutputView
            content={`Deseja abrir um protocolo para esse serviço?`}
            heading={service.title}
            className="w-full"
          />
        </div>
      )}

      {token && allowsOpenAnonymously ? (
        <div className="flex w-full items-center justify-end gap-2">
          <Text>Enviar como anônimo?</Text>
          <Switch
            onCheckedChange={(e) => {
              setValue("sem_identificacao", e);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
