import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { Modal } from "@/components/Modal";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Button } from "@/components/Button";
import { useAppSlug, useAppStore } from "@/store/useAppStore";
import { useConfig } from "@/hooks/useConfig";
import { cn } from "@/lib/utils";

import hand from "@/assets/illustrations/stop-palm.svg";

export function Onboarding() {
  const history = useHistory();
  const { t } = useTranslation();

  const slug = useAppSlug();

  if (!slug) {
    throw new Error("Not loaded app");
  }

  const images = useAppStore((state) => state.app?.images);

  const backgoundWhite = useConfig({
    label: "set.startscreen.background.white",
    slug,
  });

  const [showModal, setShowModal] = useState(false);

  function handleNavigate(path: string) {
    history.push(path);
  }

  function handleEnterAsGuest() {
    handleCloseModal();
    handleNavigate(`/${slug}`);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <Layout>
      <Content>
        <div
          className={cn(
            "mx-auto flex min-h-full w-full max-w-3xl flex-col items-center justify-between bg-primary-700 px-4 pb-[calc(var(--safe-area-inset-bottom)+1.5rem)] pt-[calc(var(--safe-area-inset-top)+2rem)] ios:pb-2",
            {
              "bg-white": backgoundWhite,
            },
          )}
        >
          <header className="flex w-full flex-col items-center">
            <img src={images?.splash} alt="" className="h-9 w-auto" />
          </header>

          {images?.logo ? (
            <img
              src={images.logo}
              alt="Avenida com dois prédios e homens trabalhando no asfalto."
              className="max-w-[90%]"
            />
          ) : null}

          <footer className="flex w-full flex-col gap-3">
            <Button
              size="lg"
              variant="secondary"
              onClick={() => handleNavigate(`/${slug}/auth/register`)}
              className={cn("border-white text-white", {
                "border-slate-200 text-primary-500": backgoundWhite,
              })}
            >
              {t("general.Cadastrar")}
            </Button>

            <Button
              size="lg"
              variant="primary"
              onClick={() => handleNavigate(`/${slug}/auth/login`)}
            >
              {t("auth.Entrar")}
            </Button>

            <Button
              size="lg"
              variant="tertiary"
              className={cn("text-white", { "text-primary-500": backgoundWhite })}
              onClick={() => setShowModal(true)}
            >
              {t("auth.Entrar como convidado")}
            </Button>
          </footer>
        </div>
      </Content>

      <Modal showModal={showModal} onClose={handleCloseModal}>
        <div className="flex flex-col items-center">
          <header className="flex flex-col items-center gap-5">
            <img src={hand} alt="Mão indicando a proibição de acesso." className="w-[120px]" />

            <Text
              asChild
              size="md"
              weight="medium"
              color="slate-700"
              className="w-[200px] text-center"
            >
              <strong>{t("auth.Você está entrando com acesso limitado")}</strong>
            </Text>
          </header>

          <div className="mt-7 flex flex-col gap-7 text-center">
            <Text>
              {t(
                "auth.Entrando como convidado você tem acesso limitado as funcionalidades do app.",
              )}
            </Text>
            <Text>{t("auth.Para ter uma experiência completa acesse através de uma conta.")}</Text>
          </div>

          <footer className="mt-14 flex w-full flex-col gap-3">
            <Button
              variant="secondary"
              size="lg"
              rounded="md"
              full
              className="border-slate-200 font-medium"
              onClick={handleCloseModal}
            >
              {t("auth.Criar conta ou fazer login")}
            </Button>

            <Button
              className="font-medium"
              variant="primary"
              size="lg"
              rounded="md"
              full
              onClick={handleEnterAsGuest}
            >
              {t("auth.Entrar como convidado")}
            </Button>
          </footer>
        </div>
      </Modal>
    </Layout>
  );
}
