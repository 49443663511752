import dayjs from "dayjs";

import { DateButton } from "./DateButton";

import { useAssetSchedulingContext } from "../../contexts/AssetsSchedulingContext";
import { useStepsContext } from "../../contexts/StepsContext";

import { getPrecedence } from "./utils/getPrecedence";
import { getOperationTime } from "./utils/getOperationTime";
import { getPlace } from "./utils/getPlace";
import { getDays } from "./utils/getDay";

export interface IDateButtonProps {
  dayOfWeek: number;
  day: number;
  selected?: boolean;
  status?: boolean;
}

export const Datepage = () => {
  const today = new Date();
  const todayOfWeek = today.getDate();

  const { institution } = useAssetSchedulingContext();
  const { dataAssetsScheduling, setDataAssetsScheduling, handleNextStep } = useStepsContext();

  const precedence = getPrecedence(institution, dataAssetsScheduling);

  const operationTime = getOperationTime(institution, dataAssetsScheduling);

  const place = getPlace(institution, dataAssetsScheduling);

  const daysWithDatejs: Array<IDateButtonProps> = getDays(
    precedence,
    dataAssetsScheduling,
    operationTime,
  );

  const handleSelectDate = (
    position: number,
    dayWeek: number,
    max?: number,
    min?: number,
    step?: number,
    date?: string,
  ) => {
    setDataAssetsScheduling &&
      setDataAssetsScheduling((prevState) => ({
        ...prevState,
        extra_fields: { position_date: position, week_number: dayWeek, max, min, step, date },
        hora_fim: undefined,
        hora_inicio: undefined,
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-wrap gap-5">
      {daysWithDatejs.map((day, index) => (
        <div key={index} className="w-1/5">
          <DateButton
            {...day}
            {...(day.status && {
              onClick: () =>
                handleSelectDate(
                  index,
                  dayjs().add(index, "day").day(),
                  place?.maximo,
                  place?.minimo,
                  place?.step,
                  new Date(today.setDate(todayOfWeek + index)).toISOString().split("T")[0],
                ),
            })}
          />
        </div>
      ))}
    </div>
  );
};
