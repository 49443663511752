import { useState } from "react";
import { Button } from "@astrolabe-ui/react";
import { Download } from "@phosphor-icons/react";

import { ModalImage } from "@/components";
import { IMessage } from "@/services/protocols";

import { isImage } from "./utils/isImage";
import { useChatContext } from ".";
import dayjs from "dayjs";

export const Client = ({ message }: { message: IMessage }) => {
  const [show, setShow] = useState(false);

  const { dummy } = useChatContext();

  if (message.is_file)
    return (
      <div className="mb-1 flex flex-row-reverse">
        <ModalImage image={message.message} show={show} onHidden={() => setShow(false)} />
        {isImage(message.message) ? (
          <div className="flex flex-col gap-1 text-right">
            <div className="rounded-b-md rounded-l-md bg-primary-500 p-2">
              <img
                src={message.message}
                onClick={() => setShow(true)}
                alt="image send by support"
                className="rounded-md"
                onLoad={() => {
                  dummy && dummy.current?.scrollIntoView();
                }}
              />
            </div>
            <span className="font-sans text-[10px] text-slate-500">{`ID: ${
              message.id
            }, recebido em: ${dayjs(message.created_at).format("DD/MM/YYYY - HH:mm")}`}</span>
          </div>
        ) : (
          <div className="flex flex-col gap-1 text-right">
            <div className="rounded-b-md rounded-l-md bg-primary-500 p-2">
              <Button
                onClick={() => window.open(message.message, "_blank")}
                className="mb-1 ml-auto w-[145px] bg-slate-200 text-primary-500"
              >
                <Download weight="fill" /> Abrir Arquivo
              </Button>
            </div>
            <span className="font-sans text-[10px] text-slate-500">{`ID: ${
              message.id
            }, recebido em: ${dayjs(message.created_at).format("DD/MM/YYYY - HH:mm")}`}</span>
          </div>
        )}
      </div>
    );

  return (
    <div className="flex flex-row-reverse flex-wrap text-right font-sans text-xs text-white [&_strong]:font-semibold">
      <p
        className="mb-1 rounded-b-md rounded-l-md bg-primary-500 p-2 [overflow-wrap:anywhere] [&_a]:underline"
        dangerouslySetInnerHTML={{ __html: message.message }}
      />
      {message.time && <div className="w-full text-slate-500">{message.time}</div>}
    </div>
  );
};
