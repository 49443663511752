import { useQuery } from "@tanstack/react-query";
import { getAvailableDays } from "@/services/schedulings";

type useAvailableDaysParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  localId: number;
  schedulingId?: number;
};

export function useAvailableDays({
  appId,
  entityId,
  resourceId,
  localId,
  schedulingId,
}: useAvailableDaysParams) {
  return useQuery({
    queryKey: ["available_days", entityId, resourceId, localId, schedulingId, appId],
    queryFn: () => getAvailableDays({ appId, entityId, resourceId, localId, schedulingId }),
    enabled: !!entityId && !!resourceId && !!localId && !!appId,
  });
}
