import { http } from "@/lib/axios";

export async function generateUrlTemporaryFile({ appId, file }: { appId: number; file: Blob }) {
  try {
    const formData = new FormData();

    formData.append("file", file);

    const response = await http.post<{ file_url: string }>(`/v3/${appId}/upload/file`, formData);

    return response.data.file_url;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
