import styled, { css } from "styled-components";
import { IonRippleEffect, IonRouterLink } from "@ionic/react";

import { Block } from "@/components/old/Block";
import { COLORS } from "@/data/colors";

interface ContentProps {
  imageUrl?: string;
  custom_height?: string;
  noShadow?: boolean;
}

interface BadgeProps {
  background?: string;
}

const Content = styled(IonRouterLink)<ContentProps>`
  width: 100%;
  height: ${(props) => (props.custom_height ? props.custom_height : "120px;")};
  padding: 15px;
  display: flex;
  align-items: flex-end;
  position: relative;
  background-color: ${COLORS.SECONDARY};
  background-size: cover;
  box-shadow: ${(props) => (props.noShadow ? "" : "inset 0px -25px 30px 0px rgba(0, 0, 0, 55%)")};
  background-position: center center;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.imageUrl &&
    css`
      background-image: url("${props.imageUrl}");
    `}

  ${(props) =>
    !props.imageUrl &&
    !props.custom_height &&
    css`
      height: 80px;
    `}

  ${(props) =>
    !props.imageUrl &&
    css`
      background-color: ${COLORS.LIGHT};
      /* box-shadow: none; */
    `}
`;

const Anchor = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
`;

const Badge = styled.span<BadgeProps>`
  background-color: ${(props) => (props.background ? props.background : "#ffffff")};
  color: ${(props) => (props.background ? "#ffffff" : "#000000")};
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
`;

const Title = styled.h1<ContentProps>`
  font-size: 1em;
  margin: 0;
  font-weight: 600;
  color: white;
`;

const Discription = styled.h6`
  font-size: 0.9em;
  margin: 5px;
  font-weight: 500;
  text-align: center;
`;

export type Tag = {
  id: number;
  nome: string;
  cor: string;
};

interface BlockItemImageProps {
  title?: string;
  discription?: string;
  href?: string;
  openNewWindow?: boolean;
  routerLink?: string;
  imageUrl?: string;
  custom_height?: string;
  noShadow?: boolean;
  tags?: Tag[];
  openExternal?: boolean;
  linkService?: string;
}

export function BlockItemImage(props: BlockItemImageProps) {
  return (
    <Block noPadding={true}>
      <Content
        data-testid="blockimage-container"
        routerLink={props.routerLink}
        noShadow={props.noShadow}
        imageUrl={props.imageUrl}
        custom_height={props.custom_height}
      >
        <Tags>
          {props.tags?.map((tag) => (
            <Badge key={tag.id} background={tag.cor}>
              {tag.nome}
            </Badge>
          ))}
        </Tags>

        <Title data-testid="blockimage-title" imageUrl={props.imageUrl}>
          {props.title || ""}
        </Title>

        <IonRippleEffect type="bounded" />

        {props.href && (
          <Anchor
            data-testid="blockimage-anchor"
            href={props.href}
            target={props.openNewWindow ? "_blank" : ""}
          />
        )}
      </Content>

      {props.discription && <Discription>{props.discription}</Discription>}
    </Block>
  );
}
