import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { IonAlert, IonLoading, IonButton } from "@ionic/react";
import { Geolocation } from "@capacitor/geolocation";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockFormItem } from "@/pages/old/Forms/components/BlockFormItem";
import { Block } from "@/components/old/Block";
import { HTMLRender } from "@/components/old/HtmlRender";
import { BlockForm } from "@/components/old/BlockForm";
import { BlockFormQuestion } from "../../components/BlockFormQuestion";
import { AnswerProps, formSendAnswers, getFormById } from "@/services/old/forms";
import { isLogged } from "@/services/old/auth";
import { getContent } from "@/content/index";

const AmountMSG = styled.b`
  width: 100%;
  text-align: center;
`;

interface FormParams {
  id: string;
  id_page: string;
  SLUG: string;
}

export function FormOpen() {
  const CONTENT = getContent();
  const params: FormParams = useParams();
  const history = useHistory();

  const [geolocation, setGeolocation] = useState<{ lat: number; lng: number } | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAlert, setIsLoadingAlert] = useState(false);
  const [data, setDataContent] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMSG, setAlertMSG] = useState("");

  const [currentPageIndex, setCurrentPageIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState<any>();

  const [answers, setAnswers] = useState<AnswerProps[]>([]);
  const [allOptionsIsRequired, setAllOptionsIsRequired] = useState(false);

  useEffect(() => {
    getFormById(params.id, params.SLUG).then((res: any) => {
      setDataContent(res);
      setIsLoading(false);
    });
  }, [params.SLUG, params.id]);

  useEffect(() => {
    const checkAndSetGeolocation = async () => {
      try {
        const permission = await Geolocation.checkPermissions();

        if (permission.location === "granted") {
          const position = await Geolocation.getCurrentPosition();

          setGeolocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      } catch (error) {
        console.error("Error getting location", error);
      }
    };

    checkAndSetGeolocation();
  }, []);

  function next() {
    setPageIndex(currentPageIndex + 1, true);
  }

  function prev() {
    setIsLoading(true);
    getFormById(params.id, params.SLUG).then((res) => {
      setDataContent(res);
      setPageIndex(currentPageIndex - 1, false);
    });
  }

  function validateAnswers() {
    if (answers.length < currentPage.min_quantidadeopcoes) {
      setAlertMSG(CONTENT.FORM.ALERT_NOT_ENOGHT_ANSWERS);
      setShowAlert(true);
      return false;
    } else if (answers.length > currentPage.quantidadeopcoes) {
      setAlertMSG(CONTENT.FORM.ALERT_TO_MUCH_ANSWERS + currentPage.quantidadeopcoes + ".");
      setShowAlert(true);
      return false;
    } else {
      let missingAnswer = false;
      answers.map((item: AnswerProps) => {
        if (!item.objetive && !item.date && !item.file && (!item.text || item.text.length === 0)) {
          missingAnswer = true;
        }

        return item;
      });
      if (missingAnswer) {
        setAlertMSG(CONTENT.FORM.ALERT_MISSING_ANSWERS);
        setShowAlert(true);
        return false;
      } else {
        return true;
      }
    }
  }

  function setPageIndex(pageIndex: number, advancing: boolean) {
    if (pageIndex >= 0 && pageIndex <= data.data.perguntas.length) {
      let _currentPage = data.data.perguntas[pageIndex];

      if (!advancing || pageIndex === 0 || answers.length === 0) {
        setCurrentPageIndex(pageIndex);
        _currentPage = initAnswers(_currentPage);
        setCurrentPage(_currentPage);
        if (_currentPage && _currentPage.opcoes) {
          setAllOptionsIsRequired(_currentPage.opcoes.length <= _currentPage.min_quantidadeopcoes);
        }
        setIsLoading(false);
      } else if (validateAnswers()) {
        setIsLoadingAlert(true);
        formSendAnswers(data.data.id, currentPage.id, answers, params.SLUG, geolocation)
          .then(() => {
            getFormById(params.id, params.SLUG).then((res) => {
              setDataContent(res);
              setCurrentPageIndex(pageIndex);
              _currentPage = initAnswers(_currentPage);
              setCurrentPage(_currentPage);
              if (_currentPage && _currentPage.opcoes) {
                setAllOptionsIsRequired(
                  _currentPage.opcoes.length <= _currentPage.min_quantidadeopcoes,
                );
              }
              setIsLoadingAlert(false);
            });
          })
          .catch((err) => {
            setIsLoadingAlert(false);
            setAlertMSG(err.data.error);
            setShowAlert(true);
          });
      }
    } else {
      setCurrentPageIndex(pageIndex);
      setAnswers([]);
      setIsLoading(false);
    }
  }

  function initAnswers(_currentPage: any) {
    if (!_currentPage || !_currentPage.opcoes) {
      setAnswers([]);
    } else {
      const newA: AnswerProps[] = [];
      _currentPage = intPrevAnswers(_currentPage);
      _currentPage.opcoes.map((item: any) => {
        if (item.oldAnswer) {
          if (item.oldAnswer.tipo_campo !== "date") {
            newA.push({
              id: item.id,
              willAnwser: true,
              objetive: item.objetiva,
              text: item.oldAnswer.resposta_texto,
            });
          } else {
            newA.push({
              id: item.id,
              willAnwser: true,
              objetive: item.objetiva,
              date: new Date(item.oldAnswer.resposta_texto),
            });
          }
        } else if (item.required) {
          newA.push({ id: item.id, willAnwser: item.required, objetive: item.objetiva });
        }

        return item;
      });
      setAnswers(newA);
    }

    return _currentPage;
  }

  function intPrevAnswers(_currentPage: any) {
    if (data.data.respostas.length > 0) {
      const oldAnswers = data.data.respostas[0].respostapergunta;
      if (_currentPage.opcoes) {
        _currentPage.opcoes.map((item: any) => {
          const current = item;

          const oldAnswer = oldAnswers.find((i: any) => i.opcao === item.id);

          if (oldAnswer) {
            current.oldAnswer = oldAnswer;
          }

          return current;
        });
      }
    }
    return _currentPage;
  }

  function updateAnswers(answer: AnswerProps) {
    const newA: AnswerProps[] = [];

    if (!answer.willAnwser) {
      answers.map((item: AnswerProps) => {
        if (item.id !== answer.id) {
          newA.push(item);
        }
        return item;
      });
      setAnswers(newA);
    } else {
      answers.map((item: AnswerProps) => {
        if (item.id !== answer.id) {
          newA.push(item);
        }
        return item;
      });
      if (newA.length >= currentPage.quantidadeopcoes) {
        setAlertMSG(CONTENT.FORM.ALERT_TO_MUCH_ANSWERS + currentPage.quantidadeopcoes + ".");
        setShowAlert(true);
      } else {
        newA.push(answer);
      }
      setAnswers(newA);
    }
  }

  if (!isLogged()) {
    return (
      <PageTemplate name={CONTENT.FORM.HEADER}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  } else if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FORM.HEADER}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  } else if (!data) {
    return <EmptyContentPageTemplate name={CONTENT.FORM.HEADER} />;
  } else if (currentPageIndex < 0 || data.data.perguntas.length === 0) {
    return (
      <PageTemplate name={CONTENT.FORM.HEADER}>
        <BlockFormItem
          title={data.data.titulo}
          description={data.data.descricao}
          dateStart={data.data.created_at}
          answered={data.data.ja_respondeu}
          dateEnd={data.data.dataencerramento}
          hideButton={true}
          expandLine={true}
        />
        {!(
          !data.data.ja_respondeu &&
          !data.data.habilitar_reserva &&
          data.data.maximo_respostas >= data.data.respostas_count
        ) &&
          data.data.habilitar_reserva && (
            <Block>
              <HTMLRender HTML_CONTENT={data.data.mensagem_reserva} />
            </Block>
          )}

        {data.data.ja_respondeu && !data.data.permitir_correcao && (
          <BlockForm>
            <b>{CONTENT.FORM.ANSWERED_MSG}</b>
          </BlockForm>
        )}
        {!data.data.ja_respondeu &&
          !data.data.habilitar_reserva &&
          data.data.maximo_respostas <= data.data.respostas_count && (
            <BlockForm>
              <b>{CONTENT.FORM.FULL_ANSWERED_MSG}</b>
            </BlockForm>
          )}

        <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
          <div style={{ width: "50%" }}></div>
          <IonButton
            style={{ width: data.data.habilitar_reserva ? "60%" : "50%" }}
            expand="block"
            onClick={next}
            disabled={
              (data.data.ja_respondeu && !data.data.permitir_correcao) ||
              (!data.data.ja_respondeu &&
                !data.data.habilitar_reserva &&
                data.data.maximo_respostas <= data.data.respostas_count)
            }
          >
            {data.data.habilitar_reserva ? CONTENT.FORM.BT_RESERVE_ANSWER : CONTENT.GLOBAL.NEXT}
          </IonButton>
        </div>
      </PageTemplate>
    );
  } else if (currentPageIndex >= data.data.perguntas.length) {
    return (
      <PageTemplate name={CONTENT.FORM.HEADER}>
        <BlockFormItem
          title={data.data.titulo}
          description={data.data.descricao}
          dateStart={data.data.created_at}
          answered={data.data.ja_respondeu}
          dateEnd={data.data.dataencerramento}
          hideButton={true}
        />
        <Block>
          <HTMLRender HTML_CONTENT={data.data.mensagem_encerramento} />
        </Block>
        <div style={{ display: "flex", width: "100%" }}>
          <IonButton style={{ width: "50%" }} expand="block" onClick={prev}>
            {CONTENT.GLOBAL.PREV}
          </IonButton>
          <IonButton
            style={{ width: "50%" }}
            expand="block"
            onClick={() => {
              history.go(-1);
            }}
          >
            {CONTENT.GLOBAL.CLOSE}
          </IonButton>
        </div>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={CONTENT.FORM.HEADER}>
      <BlockForm title={data.data.titulo}>
        <b>{currentPage.titulo}</b>
      </BlockForm>

      <BlockForm>
        <HTMLRender HTML_CONTENT={currentPage.descricao} />
        {currentPage.min_quantidadeopcoes > 1 &&
          answers.length < currentPage.min_quantidadeopcoes && (
            <AmountMSG>
              {CONTENT.FORM.MIN_SELECT_AMOUNT_P1 +
                currentPage.min_quantidadeopcoes +
                CONTENT.FORM.MIN_SELECT_AMOUNT_P2}
            </AmountMSG>
          )}
        {currentPage.opcoes.map((item: any) => {
          return (
            <BlockFormQuestion
              key={item.id}
              title={item.titulo}
              id={item.id}
              type={item.tipo_campo}
              requiered={item.required}
              objetive={item.objetiva}
              oldAnswer={item.oldAnswer ? item.oldAnswer.resposta_texto : null}
              maxAnswersAmount={currentPage.quantidadeopcoes}
              answeringsAmount={answers.length}
              rideCheckBox={allOptionsIsRequired}
              onAnswerChange={(answer) => {
                updateAnswers(answer);
              }}
            />
          );
        })}
      </BlockForm>

      <div style={{ display: "flex", width: "100%" }}>
        <IonButton style={{ width: "50%" }} expand="block" onClick={prev}>
          {CONTENT.GLOBAL.PREV}
        </IonButton>
        <IonButton style={{ width: "50%" }} expand="block" onClick={next}>
          {CONTENT.GLOBAL.NEXT}
        </IonButton>
      </div>

      <IonAlert
        isOpen={showAlert && !isLoadingAlert}
        onDidDismiss={() => {
          setShowAlert(false);
        }}
        message={alertMSG}
        buttons={[CONTENT.GLOBAL.CONTINUE]}
      />

      <IonLoading isOpen={isLoadingAlert} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
