import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { RadioGroup, RadioGroupItem, Text } from "@astrolabe-ui/react";

import { Subcategory } from "@/services/categoryServices";

const SubcategoryComponent = ({
  subcategory,
  onClick = () => {
    console.log("on click event triggered = ", subcategory.title);
  },
}: {
  subcategory: Subcategory;
  onClick?: (subcategory: Subcategory) => void;
}) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();

  return (
    <RadioGroupItem
      checked={subcategory.id === watch("subcategory_id")}
      value={subcategory.id.toString()}
      id={subcategory.title}
      onClick={() => {
        setValue("subcategory_id", subcategory.id);
        onClick(subcategory);
      }}
    >
      <Text size="md" color="slate-700">
        {t(subcategory.title)}
      </Text>

      {subcategory.description && (
        <Text size="md" color="slate-400">
          {t(subcategory.description)}
        </Text>
      )}
    </RadioGroupItem>
  );
};

export const SubCategories = ({
  subcategories,
  onClick = (subcategory: Subcategory) => {
    console.log("on click event triggered = ", subcategory.title);
  },
}: {
  subcategories: Array<Subcategory>;
  onClick?: (subcategory: Subcategory) => void;
}) => {
  return (
    <RadioGroup>
      {subcategories.map((subcategory) => (
        <SubcategoryComponent key={subcategory.id} onClick={onClick} subcategory={subcategory} />
      ))}
    </RadioGroup>
  );
};
