import { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import i18n from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { cpf } from "cpf-cnpj-validator";
import { z } from "zod";
import { useRecaptcha } from "react-hook-recaptcha";
import { useTranslation } from "react-i18next";
import { LinkButton, PasswordInput, Text, TextInput } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Button, Header, HeaderButton, NavLink, Modal } from "@/components/index";
import { ModalAccountDeletion } from "./components/ModalAccountDeletion";
import { ModalConfirmationEmail } from "./components/ModalConfirmationEmail";
import { ModalDataIncorrets } from "./components/ModalDataIncorrets";
import { ModalError } from "./components/ModalError";
import { loadUserData } from "@/services/old/auth";
import { useConfig } from "@/hooks/useConfig";
import { useLogin } from "./hooks/useLogin";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useUserStore } from "@/store/useUserStore";
import { useAuthStore } from "@/store/useAuthStore";
import { removeCharacteres } from "@/utils/formats";
import { STORAGE } from "@/data/storage";
import { isFilledDataUser } from "@/services/auth/login";

const loginFormSchema = z.object({
  username: z
    .string()
    .email(i18n.t("auth.Informe um dado válido"))
    .or(
      z
        .string()
        .transform((value) => removeCharacteres(value))
        .refine((value) => cpf.isValid(value), i18n.t("auth.Informe um dado válido")),
    ),
  password: z.string().min(6, i18n.t("auth.A senha deve possuir no mínimo 6 caracteres")).trim(),
});

type LoginFormType = z.infer<typeof loginFormSchema>;

interface onSubmitParams {
  data: LoginFormType;
  captchaResponse: string;
  cancel?: boolean;
}

const modalNameErrorMapper = new Map<number, string>();

modalNameErrorMapper.set(401, "dataIncorrets");
modalNameErrorMapper.set(406, "acctoundDeletion");
modalNameErrorMapper.set(412, "confirmationEmail");

const containerId = "recaptcha-container";

export function Login() {
  const history = useHistory();
  const { t } = useTranslation();

  const appId = useAppId();
  const slug = useAppSlug();
  const updateUser = useUserStore((state) => state.updateUser);
  const updateUserToken = useAuthStore((state) => state.updateToken);

  if (!appId || !slug) {
    throw new Error("App not loaded");
  }

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState("");

  const allowCPF = useConfig({
    label: "login.cpf",
    slug,
  });

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<LoginFormType>({
    resolver: zodResolver(loginFormSchema),
    mode: "onChange",
  });

  const { mutateAsync } = useLogin();

  const { execute, reset, recaptchaWidget } = useRecaptcha({
    containerId,
    successCallback,
    sitekey: import.meta.env.VITE_RECAPTCHA_KEY,
    size: "invisible",
  });

  function successCallback(response: string) {
    setCaptchaResponse(response);
    handleSubmit((data) => onSubmit({ data, captchaResponse: response }))();
  }

  async function executeCaptcha(event: FormEvent) {
    event.preventDefault();

    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    if (recaptchaWidget !== null) {
      reset();
      execute();
    }
  }

  function handleCancelAccountDeletion() {
    const data = {
      username: getValues("username"),
      password: getValues("password"),
    };

    onSubmit({
      data,
      captchaResponse,
      cancel: true,
    });
  }

  function handleNavigate() {
    history.push(`/${slug}/auth`);
  }

  function handleCloseModal() {
    setShowModal("");
  }

  async function onSubmit({ data, captchaResponse, cancel }: onSubmitParams) {
    try {
      const { username, password } = data;

      await mutateAsync(
        {
          appId: Number(appId),
          username,
          password,
          captchaResponse,
          cancel: !!cancel,
        },
        {
          onSuccess: async ({ user, token }) => {
            updateUser(user);

            updateUserToken(token);

            // Atualizações de Storage usado pelas telas antigas (retirar futuramente)
            loadUserData(String(slug));
            localStorage.setItem(STORAGE.AUTH_USER_EMAIL.KEY, user.email);
            localStorage.setItem(STORAGE.AUTH_ACCESS_TOKEN.KEY, token);

            reset();

            const isFilled = await isFilledDataUser({ appId: Number(appId) });

            if (isFilled) {
              history.replace(`/${slug}`);
              return;
            }

            history.replace(`/${slug}/dados-extra`);
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const statusCode = Number(err.response?.status);

              const error = modalNameErrorMapper.get(statusCode);

              setShowModal(error ?? "error");
            }

            console.error(err);
          },
          onSettled: () => setIsLoading(false),
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {t("auth.Entrar")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center px-4 py-6 ios:pb-2">
          <form
            id="loginForm"
            onSubmit={executeCaptcha}
            className="flex h-full w-full flex-col items-center gap-8"
          >
            <Text size="md" weight="medium" color="slate-700">
              {t("auth.Acesse sua conta")}
            </Text>

            <div className="flex w-full flex-col items-center gap-6">
              <TextInput
                floating
                placeholder={allowCPF ? "Email ou CPF" : "E-mail"}
                type={allowCPF ? "text" : "email"}
                autoCapitalize="none"
                error={errors.username?.message ?? undefined}
                {...register("username")}
              />

              <PasswordInput
                floating
                placeholder={t("auth.Senha")}
                textShow={t("Exibir")}
                textHide={t("Ocultar")}
                autoComplete="off"
                autoCapitalize="none"
                error={errors.password?.message}
                {...register("password")}
              />

              <NavLink to={`/${slug}/auth/forgot-my-password`}>
                <LinkButton className="underline-offset-1">
                  {t("auth.Esqueci minha senha")}
                </LinkButton>
              </NavLink>
            </div>
          </form>

          <footer className="flex flex-col items-center gap-3">
            <TermsGoogle />
            <Button
              size="lg"
              background="primary-500"
              full
              form="loginForm"
              type="submit"
              rounded="md"
              disabled={isLoading}
              loading={isLoading}
            >
              {t("auth.Acessar")}
            </Button>
          </footer>
        </div>
      </Content>

      <Modal showModal={showModal === "dataIncorrets"} onClose={handleCloseModal}>
        <ModalDataIncorrets handleCloseModal={handleCloseModal} />
      </Modal>

      <Modal showModal={showModal === "confirmationEmail"} onClose={handleCloseModal}>
        <ModalConfirmationEmail
          username={getValues("username")}
          password={getValues("password")}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal showModal={showModal === "acctoundDeletion"} onClose={handleCloseModal}>
        <ModalAccountDeletion
          handleCancelProcess={handleCancelAccountDeletion}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal showModal={showModal === "error"} onClose={handleCloseModal}>
        <ModalError handleCloseModal={handleCloseModal} />
      </Modal>

      <div id={containerId} />
    </Layout>
  );
}

function TermsGoogle() {
  const { t } = useTranslation();

  return (
    <Text size="xs">
      {t("auth.Esta aplicação é protegido por reCAPTCHA e a")}{" "}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        className="text-primary-500"
        rel="noreferrer"
      >
        {t("auth.Política de Privacidade")}
      </a>{" "}
      {t("auth.e os")}{" "}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        className="text-primary-500"
        rel="noreferrer"
      >
        {t("auth.Termos de Serviço")}
      </a>{" "}
      {t("auth.do Google se aplicam.")}
    </Text>
  );
}
