import { Text } from "@astrolabe-ui/react";
import { cn } from "@/lib/utils";

export const ProgressiveBar = ({
  className,
  loaded = 1,
  total = 5,
}: {
  className?: string;
  loaded?: number;
  total?: number;
}) => {
  const percentage = ((loaded / total) * 100).toFixed();
  return (
    <div className="flex w-full items-center">
      <div className={cn("h-1 w-full overflow-hidden rounded-sm bg-slate-100", className)}>
        <div
          className={`h-full  bg-primary-500`}
          style={{
            width: `${percentage}%`,
          }}
        />
      </div>

      <Text color="primary-500" className="ml-3" weight="medium">
        {percentage}%
      </Text>
    </div>
  );
};
