import { ElementType } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ExtraField } from "@/services/schedulings";

import { TextField } from "./fields/text-field";
import { NumberField } from "./fields/number-field";
import { SelectField } from "./fields/select-field";
import { FileField } from "./fields/file-field";
import { ImageField } from "./fields/image-field";

export interface FieldProps {
  value: string | number | boolean;
  onChange: (value: string | number | boolean) => void;
  field: ExtraField;
}

interface QuestionProps {
  field: ExtraField;
}

const fields: Record<string, ElementType> = {
  string: TextField,
  integer: NumberField,
  select: SelectField,
  arquivo: FileField,
  imagem: ImageField,
  data: TextField,
};

export function Field({ field }: QuestionProps) {
  const Field = fields[field.type];

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={String(field.id)}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <Field value={value ?? ""} onChange={onChange} field={field} />
      )}
    />
  );
}
