import { http } from "@/lib/axios";
import { CategoryService } from "./types";

type ResponseCategoriesService = {
  id: number;
  titulo: string;
  imagem: string;
  icon: string;
};

type getCategoriesServiceParams = {
  appId: number;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export async function getCategoriesService({
  appId,
  aggregatorOnly,
  aggregatorExcept,
}: getCategoriesServiceParams): Promise<Array<CategoryService>> {
  try {
    const response = await http.get<Array<ResponseCategoriesService>>(
      `/v3/${appId}/areaCategoriaServicos`,
      {
        params: {
          ...(aggregatorOnly ? { aggregator_only: aggregatorOnly } : {}),
          ...(aggregatorExcept ? { aggregator_except: aggregatorExcept } : {}),
        },
      },
    );

    return response.data.map((categoryService) => ({
      id: categoryService.id,
      title: categoryService.titulo,
      image: categoryService.imagem,
      icon: categoryService.icon,
    }));
  } catch (error) {
    console.error(error);
    throw new Error();
  }
}
