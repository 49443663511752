import { Dispatch, SetStateAction, useState } from "react";
import { RadioGroup, RadioGroupItem, Text } from "@astrolabe-ui/react";

import { CategoryService } from "@/services/categoryServices";
import { sortAreaCategories } from "../utils/sortAreaCategories";
import { Modal } from "@/components";

export default function OrderModal({
  showModal,
  setShowOrderModal,
  setAreasCategories,
}: {
  showModal: boolean;
  setShowOrderModal: (value: SetStateAction<boolean>) => void;
  setAreasCategories: Dispatch<SetStateAction<CategoryService[]>>;
}) {
  const [orderAreasCategories, setOrderAreasCategories] = useState<"asc" | "desc">("asc");

  const handleSorteAlphabetic: (order?: "asc" | "desc") => void = (order = "asc") => {
    setOrderAreasCategories(order);
    setAreasCategories(
      (prevState) => sortAreaCategories(prevState, order) as Array<CategoryService>,
    );
    setShowOrderModal(false);
  };

  return (
    <Modal onClose={() => setShowOrderModal(false)} showModal={showModal}>
      <div className="px-2">
        <RadioGroup>
          <RadioGroupItem
            {...(orderAreasCategories === "asc" && {
              checked: true,
            })}
            value="1"
            id="1"
            onClick={() => {
              handleSorteAlphabetic();
            }}
          >
            <Text color={orderAreasCategories === "asc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: A-Z
            </Text>
          </RadioGroupItem>

          <RadioGroupItem
            {...(orderAreasCategories === "desc" && {
              checked: true,
            })}
            value="2"
            id="2"
            onClick={() => {
              handleSorteAlphabetic("desc");
            }}
          >
            <Text color={orderAreasCategories === "desc" ? "slate-700" : "slate-300"}>
              Ordem alfabética: Z-A
            </Text>
          </RadioGroupItem>
        </RadioGroup>
      </div>
    </Modal>
  );
}
