import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import dayjs from "dayjs";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CalendarCheck } from "@phosphor-icons/react/dist/ssr";

import { Button } from "@/components";
import { useAppId } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAvailableHours } from "@/pages/schedulings/hooks/use-available-hours";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";
import { cn } from "@/lib/utils";

const selectHourFormSchema = z.object({
  hour: z.number(),
});

type selectHourFormData = z.infer<typeof selectHourFormSchema>;

export function SelectHour() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [{ resource, local, schedule, hour }, updateCreateSchedulingData] =
    useAtom(createSchedulingDataAtom);

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useAvailableHours({
    appId,
    entityId: Number(id),
    resourceId: Number(resource?.id),
    localId: Number(local?.id),
    scheduleId: Number(schedule?.id),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<selectHourFormData>({
    resolver: zodResolver(selectHourFormSchema),
    defaultValues: {
      hour: hour?.id,
    },
  });

  function onSubmit({ hour: hourId }: selectHourFormData) {
    const hour = data?.find((item) => item.id === hourId);

    if (hour) {
      updateCreateSchedulingData((current) => ({
        ...current,
        hour,
      }));

      handleChangeStep("validation-schedule");
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  return (
    <div className={cn("flex flex-1 flex-col gap-5 pb-8 pt-6", { "pb-40": isValid })}>
      <Text
        asChild
        size="lg"
        weight="medium"
        color="primary-500"
        className="px-4 text-[24px] leading-[28px]"
      >
        <h1>Selecione a hora para o agendamento</h1>
      </Text>

      <form
        id="select-resource"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-4 px-4"
      >
        {isLoading && !data ? <ContentLoading /> : null}

        {!isLoading && !data?.length ? <ContentEmpty /> : null}

        {!isLoading && data?.length ? (
          <div className="flex flex-col gap-6">
            <div className="flex w-fit items-center gap-1.5 rounded-full bg-slate-50 px-4 py-2">
              <CalendarCheck size={16} className="text-primary-500" />
              <Text size="xs" weight="medium" color="slate-700">
                {dayjs(schedule?.date).format("DD [de] MMMM [(]dddd[)]")}
              </Text>
            </div>

            <div className="grid grid-cols-3 gap-4">
              {data.map((hour) => (
                <Controller
                  key={hour.id}
                  control={control}
                  name="hour"
                  render={({ field: { value, onChange } }) => (
                    <button
                      type="button"
                      onClick={() => onChange(hour.id)}
                      className={cn(
                        "flex items-center justify-center rounded-md border-thin border-slate-200 py-4 transition-colors",
                        {
                          "border-primary-500 bg-primary-500": value === hour.id,
                        },
                      )}
                    >
                      <Text
                        size="md"
                        weight="semibold"
                        color={value === hour.id ? "white" : "slate-700"}
                      >
                        {hour.hour}
                      </Text>
                    </button>
                  )}
                />
              ))}
            </div>
          </div>
        ) : null}
      </form>

      <AnimatePresence>
        {isValid ? (
          <motion.footer
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            className="fixed inset-x-0 bottom-0 w-full bg-white px-4 py-8 shadow-[0_-1px_16px_0_rgba(0,0,0,0.15)]"
          >
            <Button
              type="submit"
              form="select-resource"
              full
              size="lg"
              className="mx-auto max-w-3xl"
            >
              {t("general.Continuar")}
            </Button>
          </motion.footer>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function ContentLoading() {
  return (
    <div className="grid grid-cols-3 gap-4">
      {Array.from(Array(18).keys()).map((index) => (
        <IonSkeletonText key={index} animated className="h-[58px]" />
      ))}
    </div>
  );
}

function ContentEmpty() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
      <Text weight="medium" color="slate-700">
        Não há horários com vagas disponíveis!
      </Text>
      <Text>
        No momento não existe nenhum horário com vaga disponível para ser realizado um agendamento.
      </Text>
    </div>
  );
}
