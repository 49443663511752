import { http } from "@/lib/axios";
import { Booking } from "./types";

export async function getBookings(appId: number, cpf?: string | null): Promise<Array<Booking>> {
  try {
    const response = await http.get<Array<Booking>>(
      `/v3/${appId}/agendamento_local/reservas?documento=${cpf}`,
    );

    return response.data;
  } catch (error) {
    console.error("error = ", error);
    throw new Error();
  }
}
