import { useRef, useState } from "react";
import { cn } from "@/lib/utils";

interface CodeInputProps {
  code: string;
  onChange: (code: string) => void;
  error?: boolean;
}

export function CodeInput({ code, onChange, error }: CodeInputProps) {
  const MAX_LENGHT = 5;
  const codeDigitsArray = new Array(MAX_LENGHT).fill(0);

  const inputRef = useRef<HTMLInputElement>(null);
  const [containerIsFocused, setContainerIsFocused] = useState(false);

  function handleClickContainer() {
    setContainerIsFocused(true);
    inputRef.current?.focus();
  }

  function handleOnBlur() {
    setContainerIsFocused(false);
  }

  function toCodeDigitInput(_value: number, index: number) {
    const emptyValue = " ";
    const digit = code[index] || emptyValue;

    const isCurrentDigit = index === code.length;
    const isLastDigit = index === MAX_LENGHT - 1;
    const isCodeFull = code.length === MAX_LENGHT;

    const isDigitFocused = isCurrentDigit || (isLastDigit && isCodeFull);

    return (
      <div
        key={index}
        onClick={handleClickContainer}
        className={cn(
          "flex h-14 w-full items-center justify-center rounded-xs border-medium border-slate-200 bg-white focus:border-primary-500",
          {
            "border-primary-500 bg-primary-50": isDigitFocused && containerIsFocused,
            "border-red-500": error && code.length === 0,
          },
        )}
      >
        <span className="font-sans text-md font-semibold text-slate-700 ">{digit}</span>
      </div>
    );
  }

  return (
    <>
      <div className="mx-3 flex justify-between gap-4">{codeDigitsArray.map(toCodeDigitInput)}</div>

      <input
        ref={inputRef}
        type="tel"
        maxLength={MAX_LENGHT}
        inputMode="numeric"
        value={code}
        onChange={(event) => onChange(event.target.value)}
        onBlur={handleOnBlur}
        className="h-px opacity-0"
      />
    </>
  );
}
