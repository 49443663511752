import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { App } from "@capacitor/app";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { ModalError } from "@/pages/schedulings/components/modal-error";
import { useSearchParams } from "@/hooks/useSearchParams";
import { HeaderScheduling } from "./components/header-scheduling";

import { SelectResource } from "./steps/select-resource";
import { DetailsQueue } from "./steps/details-queue";
import { AnswerQuestion } from "./steps/answer-question";
import { FormPersonal } from "./steps/form-personal";
import { ValidationQueue } from "./steps/validation-queue";
import { SelectLocal } from "./steps/select-local";
import { SelectDay } from "./steps/select-day";
import { SelectHour } from "./steps/select-hour";
import { ValidationSchedule } from "./steps/validation-schedule";

export function CreateScheduling() {
  const searchParams = useSearchParams();

  function renderStep() {
    switch (searchParams.get("step") ?? "resource") {
      case "resource":
        return <SelectResource />;
      case "queue":
        return <DetailsQueue />;
      case "questions":
        return <AnswerQuestion />;
      case "personal":
        return <FormPersonal />;
      case "validation-queue":
        return <ValidationQueue />;
      case "local":
        return <SelectLocal />;
      case "days":
        return <SelectDay />;
      case "hours":
        return <SelectHour />;
      case "validation-schedule":
        return <ValidationSchedule />;
      default:
        break;
    }
  }

  useIonViewWillEnter(() => {
    App.addListener("backButton", () => console.log(""));
  }, []);

  useIonViewWillLeave(() => {
    App.removeAllListeners();
  }, []);

  return (
    <Layout>
      <HeaderScheduling />

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl">{renderStep()}</div>
      </Content>

      <ModalError />
    </Layout>
  );
}
