import styled from "styled-components";
import { IonRouterLink, IonText } from "@ionic/react";

import { Block } from "@/components/old/Block";
import { getContent } from "@/content/index";

const Container = styled(IonRouterLink)`
  display: block;
  margin-bottom: 10px;
`;

const Content = styled.div`
  padding: 15px;
`;

const Item = styled(IonText)`
  display: block;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface BlockProtocolProps {
  address?: string;
  value?: string;
  status?: string;
}

export function BlockInfoDetail(props: BlockProtocolProps) {
  const CONTENT = getContent();

  return (
    <Container data-testid="blockprotocol-container">
      <Block noPadding>
        <Content>
          {props.status && (
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.STATE}: </b> {props.status}
            </Item>
          )}
          {props.address && (
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.ADDRESS}: </b> {props.address}
            </Item>
          )}
          {props.value && (
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.VALUE}: </b> {props.value}
            </Item>
          )}
        </Content>
      </Block>
    </Container>
  );
}
