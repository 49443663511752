import { TabTemplate } from "@/templates/old/TabTemplate";
import { TabAreaList } from "@/pages/old/Services/components/TabAreaList";
import { TabProtocolList } from "@/pages/old/Services/components/TabProtocolList";
import { getContent } from "@/content/index";

const firstTab = {
  value: "Serviços",
  content: <TabAreaList />,
};

const secondTab = {
  value: "Protocolos",
  content: <TabProtocolList />,
};

export function AreaList() {
  const CONTENT = getContent();

  return <TabTemplate first={firstTab} second={secondTab} name={CONTENT.SERVICES.HEADER_TITLE} />;
}
