import { OutputView, Text } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { Header } from "@/components/Header";
import { Button, HeaderButton, Loading } from "@/components/index";
import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { CaretLeft } from "@phosphor-icons/react";
import { useBooking } from "./hooks/useBooking";
import { useAppStore } from "@/store/useAppStore";
import { cancelBooking } from "@/services/assetsScheduling";
import { getFieldsBooking } from "./utils/getFieldsBookings";

import errorIllustrations from "@/assets/illustrations/error.svg";
import { getAppSlug } from "@/services/old/starter";
import { useUserStore } from "@/store/useUserStore";
import dayjs from "dayjs";

export const ShowBooking = () => {
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();
  const appId = useAppStore((state) => state.app?.id);
  const history = useHistory();
  const handleNavigate = () => history.replace(`/${getAppSlug()}/assets-scheduling`);

  const { SLUG, id } = useParams<{ SLUG: string; id: string }>();
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const { data, isLoading, isError, isSuccess, refetch, isFetching } = useBooking(
    appId as number,
    String(query.get("espaco_id")),
    id,
  );

  const fields = data && getFieldsBooking(data, t);

  const handleDeleteBooking = async () => {
    try {
      refetch();
      await cancelBooking(Number(appId), String(query.get("espaco_id")), id);
      toast.success("Seu pedido de cancelamento foi enviado...");
      history.replace(`/${SLUG}/assets-scheduling`);
    } catch (error) {
      console.error(error);
      toast.error("Desculpe, algo deu errodo...");
    }
  };

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text
            size="md"
            weight="medium"
            color="primary-500"
            leading="relaxed"
            className="truncate"
          >
            {t("booking.Agendamento de espaços")}
          </Text>
        </div>
      </Header>
      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-5 px-4 pb-6 pt-4 ios:pb-2">
          {data && !isLoading && isSuccess && !isFetching ? (
            <div className="flex h-full flex-col gap-4">
              <Text color="primary-500">{t("booking.Agendamento")}</Text>
              <Text color="slate-700">
                {t("booking.Instituição")}: {data.espaco.unidade.instituicao.nome}
              </Text>
              <div className="flex flex-col gap-2">
                {fields?.map((field, index) => (
                  <OutputView
                    key={index}
                    heading={field.label}
                    content={field.content}
                    className="flex-1"
                  />
                ))}
              </div>
              {data.documento === user?.cpf &&
                data.tipo !== "rejeitado" &&
                dayjs(data.hora_inicio).isAfter(dayjs()) && (
                  <div className="flex flex-grow flex-col justify-end">
                    <Button className="bg-red-500" onClick={() => handleDeleteBooking()}>
                      Solicitar cancelamento
                    </Button>
                  </div>
                )}
            </div>
          ) : isError ? (
            <div className="flex h-full flex-col items-center justify-center">
              <img src={errorIllustrations} alt="" />
              <Text className="text-center" weight="medium" color="slate-700">
                {t("booking.Desculpe, algo deu errado")}...
              </Text>
              <Text className="text-center">
                {t("booking.O nosso time de desenvolvedores esta resolvendo isso")}
              </Text>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <Loading variant="secondary" />
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};
