import { useInfiniteQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { useDebounce } from "@/hooks/useDebounce";
import { getEvents } from "@/services/events/get-events";
import { filterEventsAtom } from "../atoms/filter-atom";

type useEventsParams = {
  appId?: number;
};

export function useEvents({ appId }: useEventsParams) {
  const query = useAtomValue(filterEventsAtom);

  const queryDeffered = useDebounce(query, 700);

  return useInfiniteQuery({
    queryKey: ["events", queryDeffered],
    queryFn: ({ pageParam = 1 }) => getEvents({ appId, title: queryDeffered, page: pageParam }),
    getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.currentPage + 1 : undefined),
    enabled: !!appId,
  });
}
