import { useQuery } from "@tanstack/react-query";
import { getComments } from "@/services/posts/get-comments";

type UseCommentsParams = {
  appId: number;
  id: number;
};

export function useComments({ appId, id }: UseCommentsParams) {
  return useQuery({
    queryKey: ["comments", id, appId],
    queryFn: () => getComments({ appId, id }),
    enabled: !!id && !!appId,
  });
}
