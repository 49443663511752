import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, TextInput } from "@astrolabe-ui/react";
import { CaretLeft, FadersHorizontal, MagnifyingGlass } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton, IconButton, Loading } from "@/components";
import { Category } from "@/services/categoryServices";
import { useAppStore } from "@/store/useAppStore";
import { removeAcentAndLowerCaseString } from "@/utils/filterQueryString";

import { OrderModal } from "./Modal";
import { CardServices } from "./components/Card";
import { useService } from "./hooks/useServices";

import illustrationEmpty from "@/assets/illustrations/empty.svg";

export const Services = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const appId = useAppStore((state) => state.app?.id);
  const slug = useAppStore((state) => state.app?.slug);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { id } = useParams<{ id: string }>();

  const [query, setQuery] = useState<string>("");
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [services, setServices] = useState<Category[]>([]);

  const { data, isLoading } = useService(Number(id), appId);

  function handleNavigate() {
    history.replace(`/${slug}/categories-services`);
  }

  useEffect(() => {
    data &&
      setServices(
        query.length > 0
          ? data?.categories.filter((area) =>
              removeAcentAndLowerCaseString(area.title).includes(
                removeAcentAndLowerCaseString(query),
              ),
            )
          : data?.categories,
      );
  }, [data, query]);

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton onClick={handleNavigate} icon={<CaretLeft weight="bold" />} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t(params.get("area") || data?.title || " ")}
          </Text>
        </div>
      </Header>

      <Content>
        <OrderModal
          setServices={setServices}
          setShowOrderModal={setShowOrderModal}
          showOrderModal={showOrderModal}
        />

        {isLoading ? (
          <div className="flex min-h-full w-full items-center justify-center px-4 py-6 ios:pb-2">
            <Loading variant="secondary" size="md" />
          </div>
        ) : (
          <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-5 px-4 py-6 ios:pb-2">
            <header className="flex items-center gap-1.5">
              <TextInput
                icon={<MagnifyingGlass className="text-slate-700" />}
                placeholder={t("services.Filtrar por nome do serviço")}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />

              <IconButton
                icon={<FadersHorizontal className="text-slate-700" />}
                onClick={() => setShowOrderModal(true)}
                size="lg"
                variant="tertiary"
                rounded="full"
              />
            </header>

            <div className="flex flex-1 flex-col gap-4">
              {services && services.length > 0 ? (
                services?.map((categoryService) => (
                  <CardServices key={categoryService.id} category={categoryService} />
                ))
              ) : (
                <EmptyList />
              )}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

function EmptyList() {
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={illustrationEmpty} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          Nenhum resultado encontrado...
        </Text>
        <Text>
          Não foi possível encontrar serviços ofertados de acordo com seus filtros ativos.
        </Text>
      </div>
    </div>
  );
}
