import { useQuery } from "@tanstack/react-query";
import { getNewsCategories } from "@/services/news";

export function useNewsCategories(appId: number) {
  return useQuery({
    queryKey: ["news-categories"],
    queryFn: () => getNewsCategories(appId),
    enabled: !!appId,
    staleTime: 60 * 1000 * 5, // 5 minutos
  });
}
