export const totalVacancies = (sector: {
  servicos: Array<{
    nome: string;
    id: number;
    descricao: string;
    agendamentos: Array<{
      id: number;
      vagas_disponiveis: number;
      data_aplicacao: string; // get just date
      json_vagas_hora: string; // array of json stringfied
    }>;
  }>;
}) => {
  const totalHours = sector.servicos
    .map((service) => service.agendamentos.map((vacancie) => vacancie.vagas_disponiveis))
    .flat()
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return totalHours;
};
