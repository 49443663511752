import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { IDisplayAddress, IFormAddress } from ".";

import { useAppStore } from "@/store/useAppStore";

export interface IPopMessage {
  title: string;
  subtitle?: string;
}

const MapContext = createContext({
  center: {
    lat: 0,
    lng: 0,
  },
  popMessage: undefined,
} as {
  center: {
    lat: number;
    lng: number;
  };
  popMessage?: IPopMessage;
  setCenter: Dispatch<
    SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  textLocation: IDisplayAddress | undefined;
  setTextLocation: Dispatch<SetStateAction<IDisplayAddress | undefined>>;
  dataLocation: IFormAddress;
  setDataLocation: Dispatch<SetStateAction<IFormAddress>>;
  setSendByUser: Dispatch<SetStateAction<boolean>>;
  sendByUser: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
});

export const useMapContext = () => useContext(MapContext);

export const MapProvider = ({
  popMessage,
  setTextLocation,
  textLocation,
  dataLocation,
  setDataLocation,
  sendByUser,
  setSendByUser,
  loading,
  setLoading,
  children,
}: {
  popMessage?: IPopMessage;
  textLocation: IDisplayAddress | undefined;
  setTextLocation: Dispatch<SetStateAction<IDisplayAddress | undefined>>;
  dataLocation: IFormAddress;
  setDataLocation: Dispatch<SetStateAction<IFormAddress>>;
  setSendByUser: Dispatch<SetStateAction<boolean>>;
  sendByUser: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;

  children: ReactNode;
}) => {
  const location = useAppStore((state) => state.app?.location);

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      function (geolocationPositionError) {
        console.log("geolocationPositionError = ", geolocationPositionError);
        location &&
          setCenter({
            lat: Number(location.latitude),
            lng: Number(location.longitude),
          });
      },
    );
  }, [location]);

  return (
    <MapContext.Provider
      value={{
        center,
        popMessage,
        setCenter,
        setTextLocation,
        textLocation,
        dataLocation,
        setDataLocation,
        sendByUser,
        setSendByUser,
        loading,
        setLoading,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
