import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";
import { NotePencil } from "@phosphor-icons/react";

import { Button } from "@/components";
import { CardInfo } from "../../../components/card-info";
import { SuccessCreateScheduling } from "@/pages/schedulings/components/success-create-scheduling";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAppId } from "@/store/useAppStore";
import { useSchedulingCompletion } from "../hooks/use-scheduling-completion";
import { modalErrorSchedulingAtom } from "@/pages/schedulings/components/modal-error";
import { scheduleCompleteAtom } from "../atoms/schedule-completion-atom";

export function SchedulingCompletionValidation() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();
  const queryClient = useQueryClient();

  const appId = useAppId();
  const setModalErrorData = useSetAtom(modalErrorSchedulingAtom);

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const { mutateAsync, isLoading } = useSchedulingCompletion();

  const { entityId, resource, local, schedule, hour } = useAtomValue(scheduleCompleteAtom);

  async function handleEnterToQueue() {
    if (appId && entityId && resource && local && schedule && hour) {
      await mutateAsync(
        {
          appId,
          entityId,
          schedulingId: Number(id),
          resourceId: resource.id,
          localId: local.id,
          scheduleId: schedule.id,
          hourId: hour.id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["scheduling", String(id), appId],
            });

            queryClient.invalidateQueries({ queryKey: ["my-schedulings", appId] });

            setShowModalSuccess(true);
          },
          onError: (err) => {
            console.error(err);

            let title = "Não foi possível agendar";
            let message = "Não conseguimos realizar o agendamento no serviço desejado.";
            let enableGoSchedulings = false;
            let changeToStep = "";

            if (
              err instanceof AxiosError &&
              (err.response?.data?.type === "sem_vagas_data" ||
                err.response?.data?.type === "data_anterior")
            ) {
              title = "A data não está mais disponível";
              message = "Selecione outra data para continuar";
              changeToStep = "days";
            }

            if (
              err instanceof AxiosError &&
              (err.response?.data?.type === "sem_vagas_hora" ||
                err.response?.data?.type === "hora_anterior")
            ) {
              title = "O horário não está mais disponível";
              message = "Selecione outro horário para continuar";
              changeToStep = "hours";
            }

            if (err instanceof AxiosError && err.response?.data?.type === "cpf_agendado") {
              title = "Esse CPF já possui um agendamento";
              message = "O CPF possui um agendamento cadastrado no serviço solicitado";
              enableGoSchedulings = false;
            }

            setModalErrorData({
              isOpen: true,
              enableGoSchedulings,
              title,
              message,
            });

            if (changeToStep) {
              handleChangeStep(changeToStep);
            }
          },
        },
      );
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleCloseModal() {
    setShowModalSuccess(false);
  }

  return (
    <div className="flex w-full flex-1 flex-col gap-5 pb-40 pt-6">
      <Text
        asChild
        size="lg"
        weight="medium"
        color="primary-500"
        className="px-4 text-[24px] leading-[28px]"
      >
        <h1>Confirme os detalhes do seu agendamento</h1>
      </Text>

      <div className="flex flex-col gap-4 px-4">
        {schedule ? (
          <CardInfo.Root>
            <CardInfo.Title>Dia do agendamento</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Data</CardInfo.InfoTitle>
              <CardInfo.InfoText>{dayjs(schedule.date).format("DD/MM/YYYY")}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("days")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {hour ? (
          <CardInfo.Root>
            <CardInfo.Title>Horário do agendamento</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Horário</CardInfo.InfoTitle>
              <CardInfo.InfoText>{hour.hour}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("hours")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {local ? (
          <CardInfo.Root key={local.id}>
            <CardInfo.Title>Local</CardInfo.Title>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Local de atendimento</CardInfo.InfoTitle>
              <CardInfo.InfoText>{local.name}</CardInfo.InfoText>
            </CardInfo.Info>
            <CardInfo.Info>
              <CardInfo.InfoTitle>Endereço</CardInfo.InfoTitle>
              <CardInfo.InfoText>{local.address.address}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("local")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}
      </div>

      <footer className="fixed bottom-0 w-full bg-white px-4 py-8 shadow-[0_-1px_16px_0_rgba(0,0,0,0.15)]">
        <Button
          type="submit"
          form="form-personal"
          full
          size="lg"
          className="mt-auto"
          loading={isLoading}
          onClick={handleEnterToQueue}
        >
          {t("general.Confirmar")}
        </Button>
      </footer>

      {local && schedule && hour ? (
        <SuccessCreateScheduling
          showModal={showModalSuccess}
          onClose={handleCloseModal}
          id={Number(id)}
          local={local}
          schedule={schedule}
          hour={hour}
          textInstructions={resource?.textInstructions}
        />
      ) : null}
    </div>
  );
}
