import i18n from "@/lib/i18next";
import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { Text, TextInput } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { FormDataType, registerFormAtom } from "..";
import { useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { zodResolver } from "@hookform/resolvers/zod";

type EmailConfirmationFormData = Pick<FormDataType, "email">;

interface EmailConfirmationDataFormProps {
  handleClick: (data: FormDataType) => Promise<void>;
}

const EmailConfirmationFormSchema = z.object({
  email: z
    .string()
    .min(1, i18n.t("auth.Informe seu e-mail"))
    .email(i18n.t("auth.Informe um e-mail válido")),
});

export function EmailConfirmationForm({ handleClick }: EmailConfirmationDataFormProps) {
  const { t } = useTranslation();

  const [dataForm, setDataForm] = useAtom(registerFormAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailConfirmationFormData>({
    resolver: zodResolver(EmailConfirmationFormSchema),
    mode: "onChange",
    defaultValues: {
      email: dataForm.email,
    },
  });

  async function onSubmit(data: EmailConfirmationFormData) {
    const { email } = data;

    setDataForm({ ...dataForm, email });

    await handleClick({
      ...dataForm,
      email,
    });
  }

  return (
    <form
      id="emailConfirmationForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={3}
        title={t("auth.Confirmação de e-mail")}
        description={t("auth.Informe um email válido para realizar a alteração")}
      />

      <div>
        <TextInput
          floating
          type="email"
          placeholder={t("auth.Insira seu e-mail")}
          autoCapitalize="none"
          error={errors.email?.message}
          {...register("email")}
        />
        <Text className="mt-2" size="xs" color="slate-700">
          {t("auth.Confirme que esse é um e-mail existente")}
        </Text>
      </div>

      <div className="mt-8 flex flex-col gap-y-3 text-left">
        <Text size="md" color="slate-700" weight="medium">
          {t(
            "auth.Um e-mail será enviado para confirmar a sua identidade, contendo um botão de confirmação.",
          )}
        </Text>

        <Text size="sm" weight="normal">
          {t(
            "auth.Este processo é uma camada extra de  segurança, essencial para assegurar a validade e proteção dos seus dados.",
          )}
        </Text>
      </div>
    </form>
  );
}
