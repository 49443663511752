import { http } from "@/lib/axios";
import { ResponseBooking } from "./types";

export async function getBooking(
  appId: number,
  agendamentoId: string,
  id: string,
): Promise<ResponseBooking> {
  try {
    const response = await http.get<ResponseBooking>(
      `/v3/${appId}/agendamentos/${id}/cadastro/${agendamentoId}`,
    );

    return {
      ...response.data,
      agendamento: {
        ...response.data.agendamento,
        vagas_hora: response.data.agendamento.vagas_hora.filter((hora) =>
          hora.id_agendados.includes(response.data.id),
        ),
      },
    };
  } catch (error) {
    console.error("error = ", error);
    throw new Error();
  }
}
