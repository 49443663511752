import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import i18n from "i18next";
import { useAtomValue } from "jotai";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useChangePassword } from "../hooks/useChangePassword";
import { forgotMyPasswordFormAtom } from "..";

const passwordFormSchema = z
  .object({
    password: z.string().min(6, i18n.t("auth.A senha deve possuir no mínimo 6 caracteres")).trim(),
    passwordConfirmation: z.string().min(6, i18n.t("auth.Informe a senha novamente")).trim(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message: i18n.t("auth.As senhas devem serem iguais"),
  });

type PasswordFormData = z.infer<typeof passwordFormSchema>;

interface PasswordFormProps {
  handleReset: () => void;
}

export function PasswordForm({ handleReset }: PasswordFormProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const appId = useAppId();
  const slug = useAppSlug();
  const dataForm = useAtomValue(forgotMyPasswordFormAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordFormSchema),
    mode: "onChange",
  });

  const { mutateAsync, isLoading } = useChangePassword();

  async function onSubmit(data: PasswordFormData) {
    const { password } = data;
    const { username, code: token } = dataForm;

    await mutateAsync(
      {
        appId: Number(appId),
        username,
        token,
        password,
      },
      {
        onSuccess: () => {
          history.replace(`/${slug}/auth/forgot-my-password/success`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.status === 422) {
              toast.error(t("auth.Não encontramos o usuário"));
              return;
            }

            if (err.response?.status === 400) {
              toast.error(t("auth.Código expirado"));

              handleReset();

              return;
            }
          }

          toast.error(t("auth.Erro ao alterar senha"));

          console.error(err);
        },
      },
    );
  }

  return (
    <form
      id="personalForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={3}
        title={t("auth.Crie sua nova senha")}
        description={t(
          "auth.Após criar sua senha você poderá salvá-la para consulta em caso de uma nova perda.",
        )}
      />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-col gap-6">
          <PasswordInput
            floating
            placeholder={t("auth.Senha")}
            textShow={t("Exibir")}
            textHide={t("Ocultar")}
            error={errors.password?.message}
            autoComplete="off"
            {...register("password")}
          />

          <PasswordInput
            floating
            placeholder={t("auth.Confirmação da senha")}
            textShow={t("Exibir")}
            textHide={t("Ocultar")}
            error={errors.passwordConfirmation?.message}
            autoComplete="off"
            {...register("passwordConfirmation")}
          />
        </div>

        <footer className="mt-auto flex flex-col gap-3">
          <Button
            size="lg"
            background="primary-500"
            full
            rounded="md"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("auth.Redefinir senha")}
          </Button>
        </footer>
      </div>
    </form>
  );
}
