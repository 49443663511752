import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";

export const Root = styled(Accordion.Root)`
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;

export const Item = styled(Accordion.Item)`
  overflow: hidden;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
`;

export const Header = styled(Accordion.Header)`
  all: unset;
  display: flex;
`;

export const Trigger = styled(Accordion.Trigger)`
  all: unset;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  cursor: pointer;

  font-size: 0.75rem;
  line-height: 150%;
  color: #334155;

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }

  &[data-state="open"] > svg {
    transform: rotate(180deg);
  }

  svg {
    flex-shrink: 0;
    color: #334155;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

export const Content = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state="open"] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state="closed"] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

export const ContentText = styled.div`
  padding: 0 1rem 1rem;
  font-size: 0.75rem;
  line-height: 160%;
  color: #94a3b8;
  cursor: default;

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }
`;
