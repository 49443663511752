import { useAtom } from "jotai";

import { Container, Input, Row } from "./styles";

import { FilterTheme } from "../filter-theme/filter-theme";
import { Theme } from "../../hooks/use-proposals";
import { filterProposalsAtom } from "../../atoms/filter-proposals-atom";

interface FilterBarProps {
  themes: Array<Theme>;
}

export function FilterBar({ themes }: FilterBarProps) {
  const [filters, setFilters] = useAtom(filterProposalsAtom);

  function handleOnChange(value: string) {
    setFilters({
      ...filters!,
      query: value,
    });
  }

  return (
    <Container>
      <Row>
        <Input
          value={filters.query}
          onChange={(event) => handleOnChange(event.target.value)}
          placeholder="Buscar"
        />
        <FilterTheme themes={themes} />
      </Row>
    </Container>
  );
}
