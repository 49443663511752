import styled, { css } from "styled-components";
import { IonBadge, IonRouterLink, IonText } from "@ionic/react";

import { Block } from "@/components/old/Block";
import { getAppSlug } from "@/services/old/starter";
import { COLORS } from "@/data/colors";
import { getContent } from "@/content/index";

const Image = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
`;

const ImageDiv = styled.div`
  width: 30%;
  max-width: 200px;
  max-height: 150px;
  margin: 10px;
`;

interface ProductDivProps {
  noShadown?: boolean;
}

const ProductDiv = styled.div<ProductDivProps>`
  display: flex;

  ${(props) =>
    props.noShadown &&
    css`
      border-bottom: solid ${COLORS.BORDER} 1px;
    `}
`;

const ProductInfoDiv = styled.div`
  min-width: 55%;
  padding: 15px;
`;

const ProductTitle = styled(IonText)`
  font-size: 16px;
  display: block;
  padding: 0px;
  margin-top: 0px;
  font-style: normal;
  font-weight: normal;
`;

const ProductAmount = styled(IonText)`
  font-size: 12px;
  display: flex;
  padding: 0px;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
`;

const ProductBadge = styled(IonBadge)`
  padding: 5px;
  border-radius: 4px;
`;

const ProductPrice = styled(IonText)`
  font-size: 16px;
  display: flex;
  padding: 0px;
  margin-top: 10px;
  color: #1f985e;
  font-style: normal;
`;

const SellerDiv = styled.div`
  display: flex;
  margin: 10px;
  justify-content: space-between;
`;

const SellerName = styled(IonText)`
  font-size: 16px;
  padding: 0px;
  color: ${COLORS.PRIMARY};
  font-style: normal;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SellerNeighborhood = styled(IonText)`
  text-align: end;
  font-size: 16px;
  padding: 0px;
  font-style: normal;
  font-weight: normal;
`;

const Container = styled(IonRouterLink)`
  display: block;
`;

const CardProduct = styled.div`
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 4px;
  background-color: #fff;
`;

const Description = styled(IonText)`
  width: 100%;
  font-size: 16px;
  display: flex;
  padding: 0px;
  color: #000;
  font-style: normal;
  font-weight: normal;
`;

interface BlockProductProps {
  title: string;
  productCategory?: string;
  img_src?: string;
  productPrice?: string;
  productAmount?: string;
  productStatus?: number;
  sellerId?: string;
  sellerName?: string;
  sellerNeighborhood?: string;
  openURL?: string;
  description?: string;
}

export function BlockProduct(props: BlockProductProps) {
  const CONTENT = getContent();

  return (
    <CardProduct>
      <Container routerLink={props.openURL}>
        <ProductDiv noShadown={props.sellerNeighborhood !== null || props.sellerName !== null}>
          {props.img_src && (
            <ImageDiv>
              {/* <Block noPadding noShadown> */}
              <Image src={props.img_src} alt="image" />
              {/* </Block> */}
            </ImageDiv>
          )}

          <ProductInfoDiv>
            <ProductTitle color="dark">{props.title}</ProductTitle>
            {props.productPrice && (
              <ProductPrice data-testid="product-value">
                <b>R$</b> {props.productPrice}
              </ProductPrice>
            )}
            {!props.productPrice && (
              <ProductPrice data-testid="product-value">
                <b>R$</b> A combinar
              </ProductPrice>
            )}
            {props.productPrice && props.productAmount && (
              <ProductAmount color="dark" data-testid="product-amount">
                {props.productAmount}
              </ProductAmount>
            )}
            {props.productStatus === 0 && (
              <ProductBadge color="primary" data-testid="product-situacao">
                <b>{CONTENT.FEIRINHA.IN_REVISION}</b>
              </ProductBadge>
            )}
            {props.productStatus === 2 && (
              <ProductBadge color="warning" data-testid="product-situacao">
                <b>{CONTENT.FEIRINHA.HIDE_BY_SELLER}</b>
              </ProductBadge>
            )}
            {props.productStatus === 3 && (
              <ProductBadge color="danger" data-testid="product-situacao">
                <b>{CONTENT.FEIRINHA.FAILED_IN_REVISION}</b>
              </ProductBadge>
            )}
          </ProductInfoDiv>
        </ProductDiv>
      </Container>

      {props.sellerId && (
        <Container
          routerLink={"/" + getAppSlug() + "/abrir-vendedor/" + props.sellerId}
          data-testid="seller-div"
        >
          <SellerDiv>
            <SellerName>Ver perfil </SellerName>
            <SellerNeighborhood color="dark">{props.productCategory}</SellerNeighborhood>
          </SellerDiv>
        </Container>
      )}

      {props.description && <ProductDiv noShadown></ProductDiv>}

      {props.description && (
        <Container data-testid="description-div">
          <Block>
            <b>Descrição:</b>
            <br></br>
            <Description>{props.description}</Description>
          </Block>
        </Container>
      )}
    </CardProduct>
  );
}
