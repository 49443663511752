import { http } from "@/lib/axios";

import { AreaCategories, Category } from ".";

type ResponseService = {
  titulo: string;
  categorias: Array<{
    id: number;
    titulo: string;
    descricao?: string;
    imagem?: string;
    integracao: string;
    abrir_externo: boolean;
    abrir_anonimo: boolean;
  }>;
};

type ResponseCategoriesService = {
  id: number;
  titulo: string;
  imagem: string;
  icon: string;
};

type ResponseServiceList = Array<{
  id: number;
  titulo: string;
  descricao?: string;
  imagem?: string;
  integracao: string;
  abrir_externo: boolean;
  abrir_anonimo: boolean;
  area_categoria: ResponseCategoriesService;
}>;

export async function getServices(id: number, appId: number): Promise<AreaCategories> {
  try {
    const response = await http.get<ResponseService>(`/v3/${appId}/areaCategoriaServicos/${id}`);

    return {
      title: response.data.titulo,
      categories: response.data.categorias.map((category) => ({
        id: category.id,
        title: category.titulo,
        description: category.descricao,
        isPublic: category.abrir_anonimo,
        toBlank: category.abrir_externo,
        integration: category.integracao,
        image: category.imagem,
      })),
    };
  } catch (error) {
    console.error(error);
    throw new Error();
  }
}
type getServiceParams = {
  appId: number;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export async function getAllServices({
  appId,
  aggregatorOnly,
  aggregatorExcept,
}: getServiceParams): Promise<Category[]> {
  try {
    const response = await http.get<ResponseServiceList>(`/v3/${appId}/categoriaServicos`, {
      params: {
        orderby: "titulo:asc",
        ...(aggregatorOnly ? { aggregator_only: aggregatorOnly } : {}),
        ...(aggregatorExcept ? { aggregator_except: aggregatorExcept } : {}),
      },
    });

    return response.data.map((category) => ({
      id: category.id,
      title: category.titulo,
      description: category.descricao,
      isPublic: category.abrir_anonimo,
      toBlank: category.abrir_externo,
      integration: category.integracao,
      image: category.imagem,
      areaCategory: {
        id: category.area_categoria.id,
        title: category.area_categoria.titulo,
        image: category.area_categoria.imagem,
        icon: category.area_categoria.icon,
      },
    }));
  } catch (error) {
    console.error(error);
    throw new Error();
  }
}
