import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

interface ContentProps {
  height?: string;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  max-width: 100vw;
  font-size: 14px;
  color: #334155;

  height: ${({ height }) => height};

  position: relative;
  overflow: hidden;
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 12px;
`;

interface ContentValueImageProps {
  height?: string;
}

export const ContentValueImage = styled.div<ContentValueImageProps>`
  height: ${({ height }) => height};
`;

export const CustomImage = styled.img<{ height: string }>`
  width: auto;
  height: ${({ height }) => height};
  object-fit: cover;
  border-radius: 4px;
`;

interface ContentValueIconProps {
  height?: string;
}

export const ContentValueIcon = styled.div<ContentValueIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ height }) => height};
`;

export const Icon = styled(IonIcon)`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
`;

export const ContentTitle = styled.div`
  padding: 12px 0px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const Description = styled.span`
  font-size: 12px;
  color: #94a3b8;
`;

export const ContentIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 16px;
`;

export const IconRow = styled(IonIcon)`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
`;
