import { ReactNode, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IonRippleEffect } from "@ionic/react";
import {
  Button,
  Heading,
  Text,
  CardRoot,
  CardContent,
  CardTitle,
  CardDescription,
  CardIndicator,
  CardImage,
  CardInfo,
} from "@astrolabe-ui/react";

import { useAuthStore } from "@/store/useAuthStore";
import { Category } from "@/services/categoryServices";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { checkURLIsFile } from "@/utils/checkURLIsFile";
import { getFormatFile } from "@/utils/getFormatFile";
import { openFileInBrowser } from "@/utils/openFileInBrowser";
import { useSendClickService } from "../hooks/useSendClickService";
import { Modal } from "@/components";

export const CardServices = ({ category }: { category: Category }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [showModal, setShowModal] = useState(false);

  const token = useAuthStore((state) => state.token);
  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const { mutateAsync } = useSendClickService();

  function handleOpenService(service: Category) {
    sendClickService(category.id);

    if (!service.toBlank && service.integration) {
      let urlToNavigate;
      if (category.integration.startsWith("/")) {
        urlToNavigate = `/${slug}${category.integration}`;
      } else {
        const pathIsFile = isFile(service.integration);

        if (pathIsFile) {
          openFileInBrowser(service.integration);
          return;
        }

        urlToNavigate = `/${slug}/abrir?url=${encodeURIComponent(
          category.integration,
        )}&title=${encodeURIComponent(category.title)}`;
      }
      history.push(urlToNavigate, { from: location.pathname });
    } else {
      history.push(`/${slug}/categories-services/${id}/services/${category.id}/protocolo`);
    }
  }

  function sendClickService(id: number) {
    mutateAsync({
      appId: Number(appId),
      serviceId: id,
    });
  }

  function isFile(path: string) {
    const isValidFile = checkURLIsFile(path);
    const format = getFormatFile(path);
    return isValidFile && format !== "php" && format !== "html";
  }

  return !category.isPublic && !token ? (
    <>
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col items-center gap-4">
          <Heading size="lg">{t("general.Área Restrita")}</Heading>

          <Text className="text-center">
            Para ter acesso a esse serviço, por favor, faça login.
          </Text>

          <footer className="flex w-full flex-col gap-2">
            <Button size="lg" variant="tertiary" onClick={() => setShowModal(false)}>
              Fechar
            </Button>

            <Button
              size="lg"
              variant="primary"
              onClick={() => {
                setShowModal(false);
                window.location.href = `/${slug}/auth`;
              }}
            >
              Fazer Login
            </Button>
          </footer>
        </div>
      </Modal>

      <div onClick={() => setShowModal(true)}>
        <CardWrapper>
          <CardRoot>
            {category.image ? <CardImage src={category.image} /> : null}

            <CardContent>
              {category.areaCategory && (
                <CardInfo color={"slate-500"}>{category.areaCategory.title}</CardInfo>
              )}
              <CardTitle>{category.title}</CardTitle>
              {category.description ? (
                <CardDescription>{category.description}</CardDescription>
              ) : null}
            </CardContent>

            <CardIndicator />
          </CardRoot>
        </CardWrapper>
      </div>
    </>
  ) : category.toBlank && category.integration ? (
    <div
      onClick={() => {
        sendClickService(category.id);
        window.open(category.integration, "_blank");
      }}
    >
      <CardWrapper>
        <CardRoot>
          {category.image ? <CardImage src={category.image} /> : null}

          <CardContent>
            {category.areaCategory && (
              <CardInfo color={"slate-500"}>{category.areaCategory.title}</CardInfo>
            )}
            <CardTitle>{category.title}</CardTitle>
            {category.description ? (
              <CardDescription>{category.description}</CardDescription>
            ) : null}
          </CardContent>

          <CardIndicator />
        </CardRoot>
      </CardWrapper>
    </div>
  ) : (
    <div key={category.id} onClick={() => handleOpenService(category)}>
      <CardWrapper>
        <CardRoot>
          {category.image ? <CardImage src={category.image} /> : null}

          <CardContent>
            {category.areaCategory && (
              <CardInfo color={"slate-500"}>{category.areaCategory.title}</CardInfo>
            )}
            <CardTitle>{category.title}</CardTitle>
            {category.description ? (
              <CardDescription>{category.description}</CardDescription>
            ) : null}
          </CardContent>

          <CardIndicator />
        </CardRoot>
      </CardWrapper>
    </div>
  );
};

interface CardWrapperProps {
  children: ReactNode;
}

function CardWrapper({ children }: CardWrapperProps) {
  return (
    <div className="ion-activatable ripple-parent w-full">
      {children}
      <IonRippleEffect className="text-slate-200" />
    </div>
  );
}
