import { ratingUSer } from "@/services/protocols/ratingUser";
import { useMutation } from "@tanstack/react-query";

interface useRatingParams {
  appId: number;
  protocoloId: number;
  mood: number;
  stars: number | null;
  avaliationText?: string | null;
}

export function useRatingUser() {
  return useMutation({
    mutationFn: (data: useRatingParams) => {
      return ratingUSer(data);
    },
  });
}
