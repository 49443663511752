import { useTranslation } from "react-i18next";
import { TextInput } from "@astrolabe-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useAtom } from "jotai";
import { filterEventsAtom } from "../atoms/filter-atom";

export function FilterByQuery() {
  const { t } = useTranslation();

  const [query, setQuery] = useAtom(filterEventsAtom);

  return (
    <TextInput
      icon={<MagnifyingGlass className="text-slate-700" />}
      placeholder={t("general.Buscar")}
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
      }}
    />
  );
}
