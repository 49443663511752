import { createElement } from "react";
import { Link } from "react-router-dom";
import { menuController } from "@ionic/core/components";
import { Icon } from "@phosphor-icons/react";
import { IonRippleEffect } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";

interface NavLinkProps {
  path: string;
  title: string;
  icon: Icon;
}

export function NavLink({ path, title, icon }: NavLinkProps) {
  return (
    <Link
      to={path}
      className="ion-activatable ripple-parent flex w-full items-center gap-2 px-4 py-3"
      onClick={() => menuController.close("main")}
    >
      {createElement(icon, { size: 22, className: "text-slate-700" })}

      <Text size="md" color="slate-700">
        {title}
      </Text>

      <IonRippleEffect className="text-slate-200" />
    </Link>
  );
}
