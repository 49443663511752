import { atomWithStorage } from "jotai/utils";
import { Hour, Local, Resource, Schedule } from "@/services/schedulings";

type CreateSchedulingData = {
  resource: Resource | null;
  local: Local | null;
  schedule: Schedule | null;
  hour: Hour | null;
  questions: Array<{
    id: number;
    title: string;
    response: string;
  }>;
  personal: {
    name: string;
    cpf: string;
    phone: string;
    email: string;
  } | null;
  extraFields: Array<{
    id: number;
    title: string;
    type: "string" | "integer" | "data" | "imagem" | "arquivo" | "select";
    response: string | number;
  }>;
};

export const createSchedulingDataAtom = atomWithStorage<CreateSchedulingData>("scheduling-atom", {
  resource: null,
  personal: null,
  schedule: null,
  hour: null,
  local: null,
  questions: [],
  extraFields: [],
});
