import { http } from "@/lib/axios";

export type EnterToQueueParams = {
  appId: number;
  schedulingId: number;
  resourceId: number;
  personal: {
    name: string;
    cpf: string;
    email: string;
    phone: string;
  };
  extraFields: Array<{
    id: number;
    response: string | number;
  }>;
  questions: Array<{
    id: number;
    response: string | number;
  }>;
};

export async function enterToQueue({
  appId,
  schedulingId,
  resourceId,
  personal,
  extraFields,
  questions,
}: EnterToQueueParams) {
  try {
    const response = await http.post<{ previsao: number; cadastro: { id: number } }>(
      `v3/${appId}/mobile/entidade_agendamento/${schedulingId}/recursos/${resourceId}/entrada_fila`,
      {
        nome: personal.name,
        cpf: personal.cpf,
        email: personal.email,
        telefone: personal.phone,
        ...(extraFields.length
          ? {
              respostas_campos: extraFields.map((item) => ({
                id: item.id,
                resposta: item.response,
              })),
            }
          : {}),
        ...(questions.length
          ? {
              respostas_perguntas: questions.map((item) => ({
                id: item.id,
                resposta: item.response,
              })),
            }
          : {}),
      },
    );

    return {
      prevision: response.data.previsao,
      id: response.data.cadastro.id,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
