import { useQuery } from "@tanstack/react-query";
import { getScheduling } from "@/services/schedulings";

type useSchedulingParams = {
  appId: number;
  id: string;
};

export function useScheduling({ appId, id }: useSchedulingParams) {
  return useQuery({
    queryKey: ["scheduling", id, appId],
    queryFn: () => getScheduling({ appId, id }),
    enabled: !!appId && !!id,
  });
}
