import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useAtomValue } from "jotai/react";

import { FilterProps, filterProposalsAtom } from "../atoms/filter-proposals-atom";
import { ordinationProposalsAtom } from "../atoms/ordination-proposals-atom";
import { getAppId } from "@/services/old/provider";
import { getAccessToken } from "@/services/old/auth";
import { selectedTerritoryAtom } from "../../territories/atoms/territories-atom";
import { http } from "@/lib/axios";

export type Theme = {
  id: number;
  title: string;
};

export type Tag = {
  id: number;
  title: string;
};

export type Proposal = {
  id: number;
  title: string | null;
  description: string | null;
  image: string | null;
  status: "inactive" | "approved" | "refused";
  tags: Array<Tag>;
  themes: Array<Theme>;
  countVoters: number;
  hasUserVoted: boolean;
  username: string | null;
};

interface GetConsultResponse {
  propostas: Array<{
    id: number;
    titulo: string | null;
    descricao: string | null;
    imagem: string | null;
    status: "Inativo" | "Aprovado" | "Recusado";
    user: string | null;
    tags: Array<{
      id: number;
      titulo: string;
    }>;
    temas: Array<{
      id: number;
      titulo: string;
    }>;
    votos: number;
    temVotoProposta: boolean;
  }>;
  votosUsuario: number;
  propostasUser: number;
  propostas_count: number;
  current_page: string;
  next_page: boolean;
}

interface GetProposalsParams {
  SLUG: string;
  id?: number;
  page?: number;
  territory: number;
  filters: FilterProps;
  ordination: string;
  hasNextPage?: boolean;
}

interface GetProposalsResponse {
  proposals: Array<Proposal>;
  total: number;
  qtdVotersUser: number;
  qtdProposalsUser: number;
  currentPage: number;
  nextPage: boolean;
  fetchPage?: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetProposalsResponse, unknown>>;
}

const mapStatus: Record<string, "inactive" | "approved" | "refused"> = {
  Inativo: "inactive",
  Aprovado: "approved",
  Recusado: "approved",
};

async function getProposals({
  SLUG,
  id,
  page,
  filters,
  ordination,
  territory,
}: GetProposalsParams): Promise<GetProposalsResponse> {
  try {
    const appId = await getAppId(SLUG);

    const response = await http.get<GetConsultResponse>(`v3/${appId}/consultas/${id}/propostas`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: {
        page,
        perPage: 15,
        ...(territory ? { territorio: territory } : {}),
        ...(filters.query ? { search: filters.query } : {}),
        ...(filters.theme ? { theme: filters.theme } : {}),
        ...(ordination !== "random" ? { order: ordination } : {}),
      },
    });

    const data = response.data;

    const proposals: Array<Proposal> = response.data.propostas.map((item) => {
      const themes = item.temas?.map((theme) => ({
        id: theme.id,
        title: theme.titulo,
      }));

      const tags = item.tags?.map((tag) => ({
        id: tag.id,
        title: tag.titulo,
      }));

      let username = "";

      if (item.user) {
        const names = item.user.split(" ");
        username = `${names[0]}${names[names.length - 1] ? ` ${names[names.length - 1]}` : ""}`;
      }

      return {
        id: item.id,
        title: item.titulo,
        description: item.descricao,
        image: item.imagem,
        status: mapStatus[item.status],
        countVoters: item.votos,
        hasUserVoted: item.temVotoProposta,
        username,
        themes,
        tags,
      };
    });

    return {
      proposals,
      qtdVotersUser: data.votosUsuario,
      qtdProposalsUser: data.propostasUser,
      total: data.propostas_count,
      currentPage: Number(data.current_page),
      nextPage: data.next_page,
    };
  } catch (error) {
    console.error("Erro ao buscar propostas: ", error);
    throw error;
  }
}

export function useProposals({
  SLUG,
  id,
}: Omit<GetProposalsParams, "filters" | "ordination" | "territory">) {
  const filters = useAtomValue(filterProposalsAtom);
  const selectedTerritoryId = useAtomValue(selectedTerritoryAtom);
  const ordination = useAtomValue(ordinationProposalsAtom);

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["proposals", id, filters, selectedTerritoryId, ordination],
    queryFn: ({ pageParam = 1 }) =>
      getProposals({
        SLUG,
        id,
        filters,
        ordination,
        territory: selectedTerritoryId,
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.currentPage + 1 : undefined),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return {
    total: data?.pages[0].total,
    nextPage: data?.pages[0].nextPage,
    qtdVotersUser: data?.pages[0].qtdVotersUser,
    qtdProposalsUser: data?.pages[0].qtdProposalsUser,
    proposals: data?.pages.flatMap((item) => item.proposals),
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  };
}

interface SubmitVoteParams {
  SLUG: string;
  idConsult?: number;
  vote: "0" | "1";
  id: number;
  extra?: string;
}

async function handleSubmitVote({ SLUG, idConsult, id, vote, extra }: SubmitVoteParams) {
  const appId = await getAppId(SLUG);

  const body = {
    resposta: vote,
    ...(extra ? { extra } : {}),
  };

  return http.post(`v3/${appId}/consultas/${idConsult}/propostas/${id}/votar`, body, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

export function useVoteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SubmitVoteParams) => handleSubmitVote(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["proposals"] }),
  });
}
