import { atom } from "jotai";
import { focusAtom } from "jotai-optics";

import { NewsCategory } from "@/services/news";

type FilterTypes = {
  query: string;
  categories: Array<NewsCategory>;
};

const initialValues = {
  query: "",
  categories: [],
};

export const filterAtom = atom<FilterTypes>(initialValues);

export const filterByQueryAtom = focusAtom(filterAtom, (state) => state.prop("query"));

export const filterByCategoriesAtom = focusAtom(filterAtom, (state) => state.prop("categories"));
