import { useMemo } from "react";
import styled from "styled-components";
import { IonIcon, IonRouterLink, IonRippleEffect, IonText } from "@ionic/react";
import { Link as LinkPrimitive } from "react-router-dom";
import { square } from "ionicons/icons";

import { Block } from "@/components/old/Block";
import { getAppSlug } from "@/services/old/starter";
import { checkURLIsFile } from "@/utils/checkURLIsFile";
import { openFileInBrowser } from "@/utils/openFileInBrowser";
import { getFormatFile } from "@/utils/getFormatFile";

const Content = styled(IonRouterLink)`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  min-height: 130px;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`;

const CustomImage = styled.img`
  height: 45px;
  margin-bottom: 7px;
  display: inline;
`;

const Icon = styled(IonIcon)`
  font-size: 45px;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    font-size: 37px;
  }
`;

const Title = styled(IonText)`
  ::first-letter {
    text-transform: capitalize;
  }
  width: 100%;
  padding: 0;
  font-weight: 500;
  margin: 0;
  text-align: center;
  display: block;

  @media (max-width: 500px) {
    font-size: 0.9em;
  }
`;

const Anchor = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

const Link = styled(LinkPrimitive)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

const LinkFile = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
`;

interface BlockItemProps {
  title?: string;
  icon?: any;
  customImage?: string;
  routerLink?: string;
  href?: string;
  type?: string;
  openNewWindow?: boolean;
  onClick?: () => void;
}

export function BlockItem(props: BlockItemProps) {
  const isFile = useMemo(() => {
    if (props.href) {
      const isValidFile = checkURLIsFile(props.href);
      const format = getFormatFile(props.href);
      return isValidFile && format !== "php" && format !== "html";
    }
  }, [props.href]);

  function handleOpenFile() {
    if (props.href) {
      openFileInBrowser(props.href);
    }
  }

  return (
    <Block noPadding={true}>
      <Content
        data-testid="blockitem-content"
        routerLink={props.routerLink}
        {...(props.onClick ? { onClick: props.onClick } : {})}
      >
        {props.customImage && <CustomImage src={props.customImage} alt="icon" />}

        {!props.customImage && (
          <Icon data-testid="blockitem-icon" color="primary" icon={props.icon || square} />
        )}

        <Title data-testid="blockitem-title" color="dark">
          {props.title || "Block Item"}
        </Title>

        {props?.href && props.type === "link_externo" && (
          <Anchor data-testid="blockitem-anchor" target="_blank" href={props.href} />
        )}

        {props.href && props.type === "link_interno" && (
          <>
            {!isFile && (
              <Link
                to={`/${getAppSlug()}/abrir?url=${encodeURIComponent(
                  props.href,
                )}&title=${encodeURIComponent(props.title ?? "Mobby Cidade")}`}
              />
            )}

            {isFile && <LinkFile onClick={handleOpenFile} />}
          </>
        )}

        {props.routerLink && <IonRippleEffect type="bounded" />}
      </Content>
    </Block>
  );
}
