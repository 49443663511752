import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { selectedTerritoryResultsAtom } from "../atoms/selectedTerritoryResultsAtom";
import { getResults } from "@/services/ppa/get-results";

type UseResultsParams = {
  appId: number;
  consultId: string;
};

export function useResults({ appId, consultId }: UseResultsParams) {
  const selectedTerritory = useAtomValue(selectedTerritoryResultsAtom);

  return useQuery({
    queryKey: ["results", consultId, selectedTerritory],
    queryFn: () => getResults({ appId, consultId, territory: selectedTerritory }),
    enabled: !!appId && !!consultId && !!selectedTerritory,
  });
}
