import { STORAGE } from "@/data/storage";
import { http } from "@/lib/axios";

export function getAppSlug() {
  const APP_SLUG = localStorage.getItem(STORAGE.APP_SLUG.KEY);
  return APP_SLUG !== null && APP_SLUG.length > 0 ? APP_SLUG : "";
}

export async function getInitialData(SLUG: string) {
  return http.get("v2/app/slug/" + SLUG);
}

export async function getConfigs(APP_ID: string) {
  return http.get("v2/" + APP_ID + "/configs_modificadas");
}
