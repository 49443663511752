export function checkIOS() {
  const u = navigator.userAgent;
  return !!u.match(/Mac|iPod|iPad|iPhone/i);
}

export function checkAndroid() {
  const u = navigator.userAgent;
  return !!u.match(/Android/i);
}

export function checkPWA() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
