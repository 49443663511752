import { cn } from "@/lib/utils";

interface LoadingProps {
  variant?: string;
  className?: string;
  size?: "xs" | "md" | "lg";
}

const Dot = ({ variant, className, size }: LoadingProps) => (
  <span
    className={cn(
      "animate-blink rounded-full bg-white",
      {
        "bg-primary-500": variant === "secondary" || variant === "tertiary",
        "mx-px h-1.5 w-1.5": size === "xs",
        "mx-px h-2 w-2": size === "md",
        "mx-[2px] h-3 w-3": size === "lg",
      },
      className,
    )}
  />
);

export function Loading({ variant = "primary", className = " ", size = "xs" }: LoadingProps) {
  return (
    <span className={cn("inline-flex items-center gap-px py-[0.375rem]", className)}>
      {Array.from(Array(3).keys()).map((position) => (
        <Dot
          key={position}
          className={cn({
            "animation-delay-0": position === 0,
            "animation-delay-150": position === 1,
            "animation-delay-300": position === 2,
          })}
          variant={variant}
          size={size}
        />
      ))}
    </span>
  );
}
