import { FileOpener } from "@capacitor-community/file-opener";
import { Capacitor } from "@capacitor/core";
import { Filesystem, DownloadFileOptions, Directory } from "@capacitor/filesystem";
import { getFormatFile } from "./getFormatFile";

const MIMETypesMap: Record<string, string> = {
  pdf: "application/pdf",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
};

export async function downloadFile(url: string) {
  try {
    if (!Capacitor.isNativePlatform()) {
      window.open(url, "_blank");
      return;
    }

    const format = getFormatFile(url);

    const options: DownloadFileOptions = {
      url,
      path: `documento.${format}`,
      directory: Directory.Data,
    };

    const response = await Filesystem.downloadFile(options);

    if (response.path && format) {
      const mimeType = MIMETypesMap[format];

      FileOpener.open({
        filePath: response.path,
        contentType: mimeType,
      });
    }
  } catch (err) {
    console.error(err);
  }
}
