import { STORAGE } from "@/data/storage";

import ES from "./es";
import PTBR from "./pt-br";

export function getContent() {
  const APP = {
    APP_NAME: localStorage.getItem(STORAGE.APP_NAME.KEY) || "Base App",
    APP_VERSION: localStorage.getItem(STORAGE.VERSION.KEY),
    APP_LANG: localStorage.getItem(STORAGE.LANG.KEY),
  };

  const CONTENT_LANGUAGES = new Map([
    ["pt-br", { ...APP, ...PTBR }],
    ["es", { ...APP, ...ES }],
  ]);

  return CONTENT_LANGUAGES.get(APP.APP_LANG ?? "") ?? { ...APP, ...PTBR };
}
