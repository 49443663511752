import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .infos {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    strong {
      font-size: 1rem;
      font-weight: 400;
      line-height: 150%;
      color: #334155;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const Button = styled.button`
  font-size: 0.75rem;
  font-weight: 600;
  color: #fff;

  padding: 0.5rem 1rem;
  border-radius: 99999px;
  background-color: ${COLORS.PRIMARY};

  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:disabled {
    background-color: #94a3b8;
    color: #cbd5e1;
    cursor: not-allowed;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NotFound = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  img {
    width: 70%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    padding: 0 1.5rem;

    span {
      font-size: 0.875rem;
      line-height: 150%;
      color: #94a3b8;
    }

    a {
      text-decoration: none;
    }
  }
`;
