import { App } from "@/store/useAppStore";
import { Config } from "@/store/useConfigStore";
import { STORAGE } from "@/data/storage";
import { setColors, setHeaderColor, setInputBackgroundColor } from "@/theme/index";

export const styleDataStorageLabel = "ConfigData";

function cleatStorage(slug: string) {
  localStorage.removeItem(STORAGE.APP_NAME.KEY);
  localStorage.removeItem(STORAGE.APP_LOGO_IMG.KEY);
  localStorage.removeItem(STORAGE.ONESIGNAL_APP.KEY);
  localStorage.removeItem(STORAGE.ONESIGNAL_REST.KEY);
  localStorage.removeItem(STORAGE.LANG.KEY);
  localStorage.removeItem(STORAGE.PRIMARY_COLOR.KEY);
  localStorage.removeItem(STORAGE.SECONDARY_COLOR.KEY);
  localStorage.removeItem(STORAGE.HEADER_COLOR.KEY);
  localStorage.removeItem(STORAGE.BACKGROUND_COLOR.KEY);
  localStorage.removeItem(STORAGE.VERSION.KEY);
  localStorage.removeItem(STORAGE.LOCATION.KEY);
  localStorage.removeItem(STORAGE.SOCKET_CHANNEL.KEY);
  localStorage.removeItem(STORAGE.TERM_USE_LINK.KEY);
  localStorage.removeItem(STORAGE.TERM_USE_VERSION.KEY);
  localStorage.removeItem(`${STORAGE.SPLASH.KEY}_${slug}`);
  localStorage.removeItem(STORAGE.APP_ANDROID_STORE_LINK.KEY);
  localStorage.removeItem(STORAGE.APP_APPLE_STORE_LINK.KEY);
  localStorage.removeItem(`${slug}_${STORAGE.MAINTENANCE.KEY}`);
}

function saveConfigs(configs: Array<Config>, slug: string) {
  configs.forEach((config) => {
    localStorage.removeItem(`${slug}_${styleDataStorageLabel}_${config.slug}`);

    localStorage.setItem(
      `${slug}_${styleDataStorageLabel}_${config.slug}`,
      JSON.stringify({ value: config.value, type: config.type }),
    );
  });
}

export function updateStorage(app: App, configs: Array<Config>) {
  const { slug } = app;

  // Clear storage
  cleatStorage(app.slug);

  // Configs
  saveConfigs(configs, slug);

  // General data
  localStorage.setItem(`${slug}_ID_APP`, String(app.id));
  localStorage.setItem(STORAGE.APP_SLUG.KEY, slug);
  localStorage.setItem(STORAGE.APP_NAME.KEY, app.name);
  localStorage.setItem(`${slug}_${STORAGE.MAINTENANCE.KEY}`, String(app.maintenance));

  if (app?.language) {
    localStorage.setItem(STORAGE.LANG.KEY, app.language);
  }

  if (app?.urlPlaystore) {
    localStorage.setItem(STORAGE.APP_ANDROID_STORE_LINK.KEY, app.urlPlaystore);
  }

  if (app?.urlAppstore) {
    localStorage.setItem(STORAGE.APP_APPLE_STORE_LINK.KEY, app.urlAppstore);
  }

  // Content
  localStorage.setItem(STORAGE.VERSION.KEY, STORAGE.VERSION.VALUE);

  // Colors
  if (app.colors?.primary) {
    localStorage.setItem(STORAGE.PRIMARY_COLOR.KEY, app.colors.primary);
  }

  if (app.colors?.secondary) {
    localStorage.setItem(STORAGE.SECONDARY_COLOR.KEY, app.colors.secondary);
  }

  if (app.colors?.header) {
    localStorage.setItem(STORAGE.HEADER_COLOR.KEY, app.colors.header);
  }

  if (app.colors?.background) {
    localStorage.setItem(STORAGE.BACKGROUND_COLOR.KEY, app.colors.background);
  }

  if (app.colors?.buttons) {
    localStorage.setItem(STORAGE.BUTTONS_COLOR.KEY, app.colors.buttons);
  }

  // Images
  localStorage.setItem(STORAGE.APP_LOGO_IMG.KEY, app.images.logo);
  localStorage.setItem(`${STORAGE.SPLASH.KEY}_${slug}`, app.images.splash);

  // Terms of use
  if (app?.termsOfUse) {
    localStorage.setItem(STORAGE.TERM_USE_LINK.KEY, app.termsOfUse.url);
    localStorage.setItem(STORAGE.TERM_USE_VERSION.KEY, app.termsOfUse.version);
  }

  // Location
  if (app?.location?.latitude && app?.location?.longitude) {
    localStorage.setItem(
      STORAGE.LOCATION.KEY,
      String([app.location.latitude, app.location.longitude]),
    );
  }

  // Socket
  if (app?.socketChannel) {
    localStorage.setItem(STORAGE.SOCKET_CHANNEL.KEY, app.socketChannel);
  }

  // OneSignal
  if (app?.oneSignalAppId && app?.oneSignalRest) {
    localStorage.setItem(STORAGE.ONESIGNAL_APP.KEY, app.oneSignalAppId);
    localStorage.setItem(STORAGE.ONESIGNAL_REST.KEY, app.oneSignalRest);
  }

  // Update variables colors
  setColors(app.colors.primary, app.colors.secondary);
  setInputBackgroundColor(app.colors.buttons);
  setHeaderColor(app.colors.header, app.colors.textHeader);
}
