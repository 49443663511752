import { useState } from "react";
import { useAtom } from "jotai";
import { IonModal, IonRippleEffect } from "@ionic/react";
import { FadersHorizontal } from "@phosphor-icons/react";

import { ContainerModal, Content, HeaderModal, IconButton, ThemeBadge, Themes } from "./styles";

import { filterProposalsAtom } from "../../atoms/filter-proposals-atom";
import { Theme } from "../../hooks/use-proposals";

interface FilterThemeProps {
  themes: Array<Theme>;
}

export function FilterTheme({ themes }: FilterThemeProps) {
  const [filters, setFilters] = useAtom(filterProposalsAtom);

  const [isOpenModal, setIsOpenModal] = useState(false);

  function handleUpdateFilter(theme: number) {
    if (theme === filters.theme) {
      setFilters({
        ...filters!,
        theme: 0,
      });
    } else {
      setFilters({
        ...filters!,
        theme,
      });
    }

    handleCloseModal();
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <>
      <IconButton className="ion-activatable" onClick={() => setIsOpenModal(true)}>
        <FadersHorizontal size={24} color="#334155" />
        <IonRippleEffect style={{ color: "#94A3B8" }} />
      </IconButton>

      <IonModal
        isOpen={isOpenModal}
        onDidDismiss={() => handleCloseModal()}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        <ContainerModal>
          <Content>
            <HeaderModal>
              <strong>Selecione o tema que deseja filtrar</strong>
              <span>Caso deseje retirar filtro, basta clicar sobre o tema selecionado.</span>
            </HeaderModal>

            <Themes>
              {themes.map((item) => (
                <ThemeBadge
                  key={item.id}
                  selected={filters.theme === item.id}
                  onClick={() => handleUpdateFilter(item.id)}
                >
                  {item.title}
                </ThemeBadge>
              ))}
            </Themes>
          </Content>
        </ContainerModal>
      </IonModal>
    </>
  );
}
