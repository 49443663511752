import { http } from "@/lib/axios";

import { ResponseInstitution } from "./types";

interface getInstitutionParams {
  appId: number;
  id: number;
}

export async function getInstitution({
  appId,
  id,
}: getInstitutionParams): Promise<ResponseInstitution> {
  try {
    const response = await http.get<ResponseInstitution>(`/v2/${appId}/cad-unico/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);

    throw new Error(`Presential Scheduling Institution: ${error}`);
  }
}
