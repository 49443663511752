import { ChangeEvent, useRef, useState } from "react";
import styled from "styled-components";
import { arrowUp, imagesOutline, close } from "ionicons/icons";
import { IonIcon, IonText, IonLabel, IonButton, IonAlert, useIonToast } from "@ionic/react";

import { getContent } from "@/content/index";
import { isValidSizeInMB } from "@/utils/isValidSizeInMB";

const Container = styled.div`
  position: relative;
  padding: 8px 8px;
  border: 1px solid rgb(226, 232, 240);
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const InputFile = styled.input`
  width: 0%;
  height: 0%;
  padding: 10px;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  border: solid red 1px;
  z-index: 100;
`;

const ImagePreview = styled.div`
  max-height: 200px;
  background: #000000;
  overflow: hidden;
  border-radius: 8px;
`;

const Image = styled.img`
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

const RemoveButton = styled(IonButton)`
  position: absolute;
  top: 85px;
  left: 16px;
  z-index: 200;
`;

type uploadImageProtocolProps = {
  title?: string;
  placeHolderURL?: string;
  onChange?: ({ base64, file }: { base64: any; file: any }) => void;
  imageValue?: string;
};

export function UploadImageProtocol(props: uploadImageProtocolProps) {
  const CONTENT = getContent();

  const inputCamera = useRef<HTMLInputElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileMode, setFileMode] = useState(false);
  const [extensionError, setExtensionError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const reader = new FileReader();

  const [present] = useIonToast();

  function cleanInputFile() {
    setExtensionError(false);

    if (props.onChange) {
      props.onChange({
        base64: null,
        file: null,
      });
    }

    if (inputFile.current?.value) {
      inputFile.current.value = "";
    }
    if (inputCamera.current?.value) {
      inputCamera.current.value = "";
    }
  }

  async function onChangeHandle(e: ChangeEvent) {
    e.preventDefault();

    const files = fileMode ? inputFile.current?.files : inputCamera.current?.files;

    setExtensionError(false);

    if (files?.length) {
      const file = files[0];

      const isValid = await isValidFile(file);

      if (!isValid) {
        setExtensionError(true);
        return false;
      }

      reader.readAsDataURL(file);

      reader.onloadend = function () {
        if (props.onChange) {
          props.onChange({
            base64: reader.result,
            file,
          });
        }
      };
    }
  }

  async function isValidFile(file: File) {
    const validSize = isValidSizeInMB(file.size);
    const validFormat = isValidFormat(file.type);

    if (!validSize || !validFormat) {
      let message = "";

      if (!validSize && !validFormat) {
        message = "Tamanho e formato de arquivo inválido";
      } else if (!validSize) {
        message = "Tamanho de arquivo inválido (Max.: 9MB)";
      } else {
        message = "Formato de arquivo inválido";
      }

      await showToast({
        message,
        position: "bottom",
      });

      return false;
    }

    return true;
  }

  function isValidFormat(format: string) {
    const allowedExtensions = /(jpg|jpeg|png|gif)$/i;
    return allowedExtensions.test(format);
  }

  async function showToast({
    message,
    position,
  }: {
    message: string;
    position: "top" | "middle" | "bottom";
  }) {
    await present({
      message,
      duration: 2000,
      position,
    });
  }

  return (
    <Container>
      <div
        onClick={() => {
          if (!props.imageValue) {
            setShowModal(true);
          }
        }}
        style={{
          width: "100%",
          padding: "0px 16px",
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          background: "#fff !important",
          marginBottom: props.imageValue ? "16px" : "0px",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "53px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IonLabel
            style={{
              fontSize: "14px",
              color: "#777777",
            }}
          >
            {props.title}
          </IonLabel>
          {extensionError && <IonText color="danger"> ✘ {CONTENT.IMAGE.FILE_FAIL} </IonText>}

          <IonIcon
            icon={props.imageValue ? imagesOutline : arrowUp}
            size="small"
            slot="end"
            style={{ color: "#475569" }}
          />
        </div>
      </div>

      {props.imageValue && (
        <>
          <RemoveButton onClick={cleanInputFile} color="danger" size="small">
            <IonIcon slot="icon-only" icon={close} />
          </RemoveButton>

          <ImagePreview>
            <Image src={props.imageValue} alt="uploaded" />
          </ImagePreview>
        </>
      )}
      {!props.imageValue && props.placeHolderURL && (
        <ImagePreview>
          <Image src={props.placeHolderURL} alt="" />
        </ImagePreview>
      )}

      <InputFile onChange={onChangeHandle} ref={inputFile} type="file" accept="image/*" />
      <InputFile onChange={onChangeHandle} ref={inputCamera} type="file" accept="image/*" capture />

      <IonAlert
        isOpen={showModal}
        onDidDismiss={() => {
          setShowModal(false);
        }}
        header={CONTENT.GET_IMAGE.MESSAGE}
        buttons={[
          {
            text: CONTENT.GET_IMAGE.FILE,
            handler: () => {
              inputFile.current?.click();
              setFileMode(true);
            },
          },
          {
            text: CONTENT.GET_IMAGE.CAMERA,
            handler: () => {
              inputCamera.current?.click();
              setFileMode(false);
            },
          },
        ]}
      />
    </Container>
  );
}

UploadImageProtocol.defaultProps = {
  title: "Imagem",
};
