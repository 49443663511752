import { http } from "@/lib/axios";

import { Subcategories } from ".";

type ResponseSubcategory = {
  titulo: string;
  descricao: string;
  integracao: string;
  abrir_externo: boolean;
  abrir_anonimo: boolean;
  campos:
    | Array<{
        id: number;
        tipo: "string" | "integer" | "data" | "imagem" | "arquivo" | "location" | "select";
        nome: string;
        hint: string | null;
        required: boolean;
        opcoes_campo:
          | Array<{
              id: number;
              label: string;
            }>
          | [];
      }>
    | [];
  area_categoria: {
    titulo: string;
  };
  sub_categoria_servicos: Array<{
    id: number;
    titulo: string;
    descricao: string;
    campos:
      | Array<{
          id: number;
          tipo:
            | "string"
            | "integer"
            | "data"
            | "imagem"
            | "arquivo"
            | "location"
            | "select"
            | "textarea";
          nome: string;
          hint: string | null;
          required: boolean;
          opcoes_campo:
            | Array<{
                id: number;
                label: string;
              }>
            | [];
        }>
      | [];
    mensagem: string | null;
  }>;
};

export async function getSubcategories(serviceId: number, appId: number): Promise<Subcategories> {
  try {
    const response = await http.get<ResponseSubcategory>(
      `/v3/${appId}/categoriaServicos/${serviceId}`,
    );

    const fields = response.data.campos.map((cam) => ({
      id: cam.id,
      type: cam.tipo,
      name: cam.nome,
      required: cam.required,
      options: cam.opcoes_campo,
      hint: cam.hint,
    }));

    return {
      description: response.data.descricao,
      title: response.data.titulo,
      fields,
      integration: response.data.integracao,
      openAnonymous: response.data.abrir_anonimo,
      subCategories: response.data.sub_categoria_servicos.map((sub) => ({
        id: sub.id,
        title: sub.titulo,
        description: sub.descricao,
        message: sub.mensagem,
        fields: sub.campos.map((field) => ({
          id: field.id,
          type: field.tipo,
          name: field.nome,
          required: field.required,
          options: field.opcoes_campo,
          hint: field.hint,
        })),
      })),
    };
  } catch (error) {
    console.error(error);
    throw new Error();
  }
}
