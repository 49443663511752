import { Text } from "@astrolabe-ui/react";

import { DateButton } from "./DateButton";
import { getBookingsDays } from "./utils/getBookingsDays";
import { useStepsContext } from "../../contexts/StepsContext";
import { useInstitutionContext } from "../../contexts/InstitutionContext";

export const Datepage = () => {
  const { institution } = useInstitutionContext();

  const {
    dataPresentialSchedulingInstitution,
    setDataPresentialSchedulingInstitution,
    handleNextStep,
  } = useStepsContext();

  const bookingsDaysPerMonths = getBookingsDays(institution, dataPresentialSchedulingInstitution);

  const handleSelectDay = (id: number) => {
    setDataPresentialSchedulingInstitution &&
      setDataPresentialSchedulingInstitution((prevState) => ({
        ...prevState,
        id_hora: undefined,
        id_agendamento: id,
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-wrap gap-5">
      {bookingsDaysPerMonths?.map((daysPerMonth) => (
        <div key={daysPerMonth.month} className="flex grow flex-wrap gap-3">
          <div className="w-full">
            <Text color="slate-600" weight="semibold">
              {daysPerMonth.month.charAt(0).toUpperCase() + daysPerMonth.month.slice(1)}
            </Text>
          </div>

          <div className="grid grow grid-cols-5 gap-3">
            {daysPerMonth.days.map((dayPerMonth) => (
              <DateButton
                key={dayPerMonth.id}
                day={dayPerMonth.day}
                dayOfWeek={dayPerMonth.weekDay}
                selected={dataPresentialSchedulingInstitution?.id_agendamento === dayPerMonth.id}
                onClick={() => handleSelectDay(dayPerMonth.id)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
