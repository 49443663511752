import { Controller, useFormContext } from "react-hook-form";
import { useIonToast } from "@ionic/react";

import { ItemRadioButton, RadioButtons } from "@/components/old/Forms/RadioButton";
import { ProtocolData } from "../../FormOpenProtocol";
import { getContent } from "@/content/index";

import { Container } from "./styles";

type OptionSubcategory = ItemRadioButton & {
  message: string | null;
};

interface SelectSubcategoriesProps {
  handleNext: () => Promise<void>;
  name: "subcategory";
  options: OptionSubcategory[];
  disabledNext: boolean;
}

export function SelectSubcategories({
  handleNext,
  name,
  options,
  disabledNext,
}: SelectSubcategoriesProps) {
  const [showToast] = useIonToast();

  const CONTENT = getContent();

  const { control } = useFormContext<ProtocolData>();

  function showMessage(message: string) {
    showToast({
      message,
      duration: 3000,
      position: "top",
    });
  }

  return (
    <Container>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioButtons
            values={options}
            selected={value}
            onChange={(value) => {
              onChange(value);

              const message = options.find((item) => item.value === value.value)?.message;

              if (message) {
                showMessage(message);
              }
            }}
          />
        )}
      />

      <footer>
        <button type="button" onClick={handleNext} disabled={disabledNext}>
          {CONTENT.VINCULAR_TITULAR.ADVANCE}
        </button>
      </footer>
    </Container>
  );
}
