import { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { OutputView, Text } from "@astrolabe-ui/react";

import { Loading } from "@/components/Loading";
import { Button } from "@/components/Button";
import { http } from "@/lib/axios";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/store/useAppStore";
import { ResponseBooking } from "@/services/presentialScheduling";

import { Modal } from "./Modal";
import { useInstitutionContext } from "../../contexts/InstitutionContext";
import { useStepsContext } from "../../contexts/StepsContext";
import { getOutputViewValues } from "./utils/getOutputViewValues";
import { BookingContext } from "../../contexts/BookingContext";

type IStepOutputView = Array<{
  name: string;
  outputviews: Array<{
    title: string;
    subTitle: string | undefined;
  }>;
}>;

export const Review = () => {
  const { t } = useTranslation();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [status, setStatus] = useState<"success" | "error">();
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState<ResponseBooking | null>(null);

  const { institution } = useInstitutionContext();

  const {
    handleGoTo,
    dataPresentialSchedulingInstitution,
    setDataPresentialSchedulingInstitution,
  } = useStepsContext();

  const stepValues =
    dataPresentialSchedulingInstitution &&
    institution &&
    (getOutputViewValues(institution, dataPresentialSchedulingInstitution) as IStepOutputView);

  const handleSubmitBooking = () => {
    setLoading(true);
    setIsOpen(true);
    http
      .post(`/v3/${appId}/presential-scheduling`, dataPresentialSchedulingInstitution)
      .then((res) => {
        toast.success(t("booking.Agendamento realizado com sucesso"));
        setStatus("success");
        setDataPresentialSchedulingInstitution &&
          setDataPresentialSchedulingInstitution((prevState) => ({
            ...prevState,
            unidade_id: undefined,
          }));
        setBooking(res.data);
      })
      .catch((error: any) => {
        setStatus("error");
        toast.error(t("error.Problemas ao realizar a reserva"));
        setErrorMessage(error.response.data.error as string);
        console.log(error.response.data.error);
      })
      .finally(() => {
        setIsOpen(true);
        setLoading(false);
      });
  };

  return (
    <div className={cn("flex flex-col gap-10", { "h-full": loading })}>
      {loading ? (
        <div className="flex grow flex-col items-center justify-center">
          <Loading variant="secondary" />
          <Text className="animate-pulse">Enviando seu agendamento</Text>
        </div>
      ) : (
        <BookingContext.Provider value={booking}>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            status={status}
            {...(errorMessage && { error: errorMessage })}
          />
          {stepValues?.map((field, index) => (
            <div className="flex flex-col gap-4" key={index}>
              <div className="flex justify-between">
                <Text color="slate-700">
                  {t("booking.Etapa")} {index + 1}: {t(field.name)}
                </Text>

                <Text color="primary-500" onClick={() => handleGoTo && handleGoTo(index)}>
                  {t("general.Editar")}
                </Text>
              </div>

              {field.outputviews.map((outputview, index) => (
                <OutputView key={index} heading={outputview.title} content={outputview.subTitle} />
              ))}
            </div>
          ))}

          <Button size="lg" full onClick={() => handleSubmitBooking()} className="self-end">
            {t("general.Confirmar")}
          </Button>
        </BookingContext.Provider>
      )}
    </div>
  );
};
