import { ResponseInstitution } from "@/services/assetsScheduling";
import { IDataAssetsScheduling } from "../..";

export interface IWorkSchedule {
  id: number;
  dia_semana: string;
  hora_fim: string;
  hora_inicio: string;
  hora_intervalo_fim: string | null;
  hora_intervalo_inicio: string | null;
  habilitado: boolean;
}

export const getWorkSchedule = (
  unit?: ResponseInstitution,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  unit?.unidades
    .find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
    ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id)
    ?.funcionamentos.find(
      (func) => func.dia_semana === String(dataAssetsScheduling?.extra_fields?.week_number),
    );
