import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { TextInput } from "@astrolabe-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { filterQuestionByTitleAtom } from "../atoms/filterQuestionByTitleAtom";

export function FilterQuestionByTitle() {
  const { t } = useTranslation();

  const [query, setQuery] = useAtom(filterQuestionByTitleAtom);

  return (
    <TextInput
      icon={<MagnifyingGlass className="text-slate-700" />}
      placeholder={t("general.Buscar")}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  );
}
