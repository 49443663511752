import { http } from "@/lib/axios";
import { IconPhosphorProps } from "@/components/CustomIcon";
import { Faq } from "./types";

type getFaqRequest = {
  id: number;
  title: string;
  categories: Array<{
    id: number;
    title: string;
    icon: IconPhosphorProps;
  }>;
};

type getFaqParams = {
  appId?: number;
  id: string;
};

export async function getFaq({ appId, id }: getFaqParams): Promise<Faq> {
  try {
    const response = await http.get<getFaqRequest>(`v3/${appId}/faq/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
