import styled from "styled-components";
import { IonSpinner } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem 1rem max(env(safe-area-inset-bottom), 1rem);

  display: flex;
  flex-direction: column;
  gap: 3rem;

  & > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    text-align: center;
    padding: 0 0.875rem;

    strong {
      font-size: 1rem;
      font-weight: 500;
      line-height: 150%;
      color: #334155;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      line-height: 160%;
      color: #94a3b8;
    }
  }

  & > form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.875rem;

    & > span {
      font-size: 1rem;
      line-height: 150%;
      color: #334155;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      textarea {
        resize: none;
        width: 100%;
        background-color: transparent;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;

        padding: 0.5rem 0.75rem;

        font-size: 0.875rem;
        color: #334155;

        &:focus {
          outline: none;
          border-color: ${COLORS.PRIMARY};
        }

        &::placeholder {
          color: #94a3b8;
        }
      }
    }

    & > button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;

      margin-top: auto;
      padding: 0.875rem 1rem;
      background-color: ${COLORS.PRIMARY};
      border-radius: 0.5rem;

      font-size: 0.75rem;
      font-weight: 600;
      line-height: 150%;
      color: #ffffff;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const FieldError = styled.span`
  font-size: 0.75rem;
  line-height: 150%;
  color: #e43c3c;
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    color: #94a3b8;
  }
`;

export const Spinner = styled(IonSpinner)`
  width: 18px;
  height: 18px;
`;

export const BigSpinner = styled(IonSpinner)`
  --color: #334155;
`;

export const ErrorContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    line-height: 160%;
    color: #334155;
    text-align: center;
  }
`;
