import { useTranslation } from "react-i18next";
import {
  CardRoot,
  CardContent,
  CardTitle,
  CardDescription,
  CardInfo,
  CardIndicator,
} from "@astrolabe-ui/react";

import { useInstitutionContext } from "../../contexts/InstitutionContext";
import { useStepsContext } from "../../contexts/StepsContext";
import { totalVacanciesByService } from "./utils/totalVacanciesByService";

export const Services = () => {
  const { institution } = useInstitutionContext();

  const {
    dataPresentialSchedulingInstitution,
    setDataPresentialSchedulingInstitution,
    handleNextStep,
  } = useStepsContext();

  const { t } = useTranslation();

  const handleSelectPlace = (id: number) => {
    setDataPresentialSchedulingInstitution &&
      setDataPresentialSchedulingInstitution((prevState) => ({
        servico_agendamento_id: id,
        sector_id: prevState?.sector_id,
        cpf: prevState?.cpf || "",
        nome: prevState?.nome || "",
        telefone: prevState?.telefone || "",
        email: prevState?.email || "",
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-5">
      {institution?.setores
        .find((sector) => sector.id === dataPresentialSchedulingInstitution?.sector_id)
        ?.servicos.map((service) => {
          const qtdVacancies = totalVacanciesByService(service);

          const isActive = qtdVacancies > 0;
          const isSelected =
            dataPresentialSchedulingInstitution?.servico_agendamento_id === service.id;

          return (
            <CardRoot
              key={service.id}
              data-active={isActive}
              data-selected={isSelected}
              variant={isActive ? "outlined" : "filled"}
              className="data-[active=false]:cursor-not-allowed data-[selected=true]:border-primary-500"
              onClick={() => handleSelectPlace(service.id)}
            >
              <CardContent>
                <CardTitle>{service.nome}</CardTitle>

                <CardDescription>{service.descricao}</CardDescription>

                <CardInfo color={qtdVacancies > 0 ? "green-500" : "red-500"} weight="semibold">
                  {qtdVacancies} {t("booking.vagas")}
                </CardInfo>
              </CardContent>

              {isActive ? <CardIndicator /> : null}
            </CardRoot>
          );
        })}
    </div>
  );
};
