import { useState } from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { IonButton, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption } from "@ionic/react";
import styled from "styled-components";

import { getAppSlug } from "@/services/old/starter";
import { getInfoCategoryById, getTagsByCategoryId } from "@/services/old/infos";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockMapWithMarkers } from "@/pages/old/Infos/components/BlockMapWithMarkers";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockItemImage, Tag } from "@/components/old/BlockItemImage";
import { getContent } from "@/content/index";

const Filters = styled(IonItem)`
  --padding-start: none;
  border-radius: 4px;
  margin-top: 6px;
`;

const Label = styled(IonLabel)`
  margin-left: 12px;
`;

interface PostPageParams {
  id: string;
  SLUG: string;
}

export function InfoCategoryOpenHeaderless() {
  const CONTENT = getContent();

  const params: PostPageParams = useParams();

  const [currentSearchText] = useState("");
  const [filterTag, setFilterTag] = useState<number | null>(null);

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getInfoCategory", params.id, currentSearchText, filterTag],
    ({ pageParam = 1 }) =>
      getInfoCategoryById(
        {
          page: pageParam,
          category: params.id,
          search: currentSearchText,
          tag: filterTag,
        },
        params.SLUG,
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.next_page_url + "").split("page=")[1];
      },
    },
  );

  const { data: tags } = useQuery(["tags", params.id], () =>
    getTagsByCategoryId(Number(params.id), params.SLUG),
  );

  function loadMore() {
    fetchNextPage();
  }

  if (isLoading) {
    return <PageLoading description={CONTENT.GLOBAL.LOADING} />;
  }

  if (!data?.pages[0].data.data[0]?.categoria) {
    return (
      <IonPage>
        <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
      </IonPage>
    );
  }

  return (
    <IonPage>
      {data?.pages[0].data.data[0]?.categoria.exibirmapa && (
        <BlockMapWithMarkers
          showUserLocation={true}
          markers={[].concat.apply(
            [],
            data?.pages?.map((item: any) => {
              // eslint-disable-next-line array-callback-return
              return item.data.data?.map((item: any) => {
                if (item.latitude && item.longitude) {
                  return {
                    id: item.id,
                    lat: item.latitude,
                    lng: item.longitude,
                    img: item.imagem,
                    label: item.titulo,
                    icon: item.icon,
                    href: `/info-abrir-headerless/${item.id}`,
                  };
                }
              });
            }),
          )}
        />
      )}

      {!data?.pages[0].data.data[0]?.categoria.exibirmapa && (
        <>
          {data?.pages[0].data.data[0].categoria.tags.length > 0 && (
            <Filters>
              <Label>{CONTENT.INFORMATION.FILTER}</Label>
              <IonSelect
                placeholder="Tag"
                value={filterTag}
                onIonChange={(e) => {
                  if (e.detail.value >= 0 || e.detail.value === null) {
                    setFilterTag(e.detail.value);
                  }
                }}
              >
                <IonSelectOption value={null}>Todas</IonSelectOption>
                {tags?.data.map((tag: Tag) => (
                  <IonSelectOption key={tag.id} value={tag.id}>
                    {tag.nome}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </Filters>
          )}

          {!data || data?.pages.length === 0 || data?.pages[0].data.data.length === 0 ? (
            <div>
              <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
            </div>
          ) : (
            <>
              {data?.pages?.map((item: any) => {
                return item.data.data.map((item: any, index: number) => {
                  return (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      {!item.link_servico && (
                        <BlockItemImage
                          title={item.titulo}
                          discription={item.descricao}
                          tags={item.tags}
                          imageUrl={item.imagem}
                          routerLink={`/${getAppSlug()}/info-abrir-headerless/${item.id}`}
                        />
                      )}
                      {item.link_servico && !item.abrir_externo && (
                        <BlockItemImage
                          title={item.titulo}
                          discription={item.descricao}
                          tags={item.tags}
                          imageUrl={item.imagem}
                          routerLink={`/${getAppSlug()}/info-abrir-externo/${item.id}`}
                        />
                      )}
                      {item.link_servico && item.abrir_externo && (
                        <BlockItemImage
                          title={item.titulo}
                          discription={item.descricao}
                          tags={item.tags}
                          imageUrl={item.imagem}
                          openNewWindow={true}
                          href={item.link_servico}
                        />
                      )}
                    </div>
                  );
                });
              })}
            </>
          )}
        </>
      )}

      {(!data || data?.pages.length === 0 || data?.pages[0].data.data.length === 0) && !tags && (
        <div>
          <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
        </div>
      )}

      {data?.pages[data?.pages.length - 1].data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </IonPage>
  );
}
