import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  max-width: 100vw;
  font-size: 14px;
  color: #334155;

  position: relative;
  overflow: hidden;
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 12px;
`;

export const ContentValueImage = styled.div`
  width: 46px;
  height: 46px;
`;

export const CustomImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const ContentValueIcon = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(IonIcon)`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
`;

export const ContentTitle = styled.div`
  padding: 12px 0px;
  flex: 1 1 0%;
  display: flex;
  overflow: hidden;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const ContentIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0px 16px;
`;

export const IconRow = styled(IonIcon)`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
`;
