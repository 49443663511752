import { Button, Text } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";

import illustrationSuccess from "@/assets/illustrations/cell-success.svg";

interface SuccessfullyValidatedTokenProps {
  handleClose: () => void;
}

export function SuccessfullyValidatedToken({ handleClose }: SuccessfullyValidatedTokenProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col gap-6">
        <img src={illustrationSuccess} alt="Celular com check no centro." className="h-28" />

        <Text asChild size="md" weight="medium" color="slate-700">
          <strong>{t("auth.Verificação concluída")}</strong>
        </Text>
      </header>

      <Text className="mb-8 mt-6 text-center">
        {t("auth.Seu código foi validado. Continue para criar sua nova senha.")}
      </Text>

      <Button variant="primary" size="lg" rounded="full" full onClick={handleClose}>
        {t("general.Continuar")}
      </Button>
    </div>
  );
}
