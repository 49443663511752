import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  footer {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${COLORS.PRIMARY};
    border-radius: 6px;
    background-color: ${COLORS.PRIMARY};

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    height: 56px;
    width: 100%;

    &.outline-btn {
      background-color: transparent;
      border-color: #e2e8f0;
      color: ${COLORS.PRIMARY};
    }
  }
`;

export const ContentField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentTitleField = styled.div`
  width: 100%;
  padding: 6px 16px;
  color: #334155;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
`;

export const ContentTitle = styled.span`
  font-size: 12px;
  color: #94a3b8;
`;

export const ContentFieldValue = styled.div`
  font-size: 14px;
  color: #334155;
`;

export const SemValor = styled.span`
  font-size: 12px;
  color: #64748b;
`;

export const ImagePreview = styled.div`
  max-height: 200px;
  background: #27272a;
  overflow: hidden;
  border-radius: 8px;
`;

export const Image = styled.img`
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

export const FilePreview = styled.div`
  width: 100%;
  height: 100px;
  background: #27272a;
  border-radius: 8px;
  gap: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const IconFilePreview = styled(IonIcon)`
  font-size: 45px;
`;
