import { useEffect } from "react";
import { Marker, TileLayer, useMap } from "react-leaflet";

interface MapContainerProps {
  lat: number;
  lng: number;
}

export function MapContent({ lat, lng }: MapContainerProps) {
  const map = useMap();
  useEffect(() => {
    map.setView({ lat, lng });
  }, [lat, lng, map]);
  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={{ lat, lng }}></Marker>
    </>
  );
}
