import { useHistory, useParams } from "react-router-dom";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, Info } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton } from "@/components";
import { useAppId } from "@/store/useAppStore";
import { useInfoMedia } from "./hooks/use-info-media";

export function InfoMedia() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data: info, isLoading } = useInfoMedia({ appId, id: Number(id) });

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text
            size="md"
            weight="medium"
            leading="relaxed"
            className="line-clamp-1 text-textHeader"
          >
            Informação
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl pb-6 ios:pb-2">
          {isLoading && !info ? (
            <div className="flex w-full flex-col gap-8">
              <div className="flex flex-col gap-4">
                <IonSkeletonText animated className="h-32 w-full" />
                <div className="flex flex-col gap-1">
                  <IonSkeletonText animated />
                  <IonSkeletonText animated />
                </div>
              </div>

              <IonSkeletonText animated className="h-14 w-full rounded-full" />
            </div>
          ) : null}

          {!isLoading && info ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <img src={info.image} alt="" className="w-full object-contain" />

                <div className="px-4">
                  <Text size="lg" weight="semibold" color="slate-700">
                    {info.title}
                  </Text>

                  <Text>{info.description}</Text>
                </div>
              </div>

              <div className="flex flex-1 flex-col items-center gap-2 px-4">
                <audio controls src={info.source} className="mx-auto w-full"></audio>

                <div className="mt-auto flex w-fit items-center gap-1.5 rounded-full bg-orange-50 px-4 py-2">
                  <Info weight="bold" className="flex-shrink-0 text-orange-600" />
                  <Text size="xs" weight="medium" className="text-orange-600">
                    Para controlar o áudio, use os controles presentes no player acima!
                  </Text>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}
