import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

interface NavLinkProps {
  children: ReactElement;
  to: string;
  replace?: true;
}
export function NavLink({ children, to, replace }: NavLinkProps) {
  const history = useHistory();

  function handleNavigate() {
    replace ? history.replace(to) : history.push(to);
  }

  return <>{React.cloneElement(children, { onClick: handleNavigate })}</>;
}
