import { useQuery } from "@tanstack/react-query";

import { getFaq } from "@/services/faq";

export interface useFaqCategoriesParams {
  appId?: number;
  id: string;
}

export function useFaq({ appId, id }: useFaqCategoriesParams) {
  return useQuery({
    queryKey: ["faq", id, appId],
    queryFn: () => getFaq({ appId, id }),
    enabled: !!appId,
  });
}
