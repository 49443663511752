import dayjs from "dayjs";

import { IDataPresentialScheduling } from "../..";
import { ResponseInstitution } from "@/services/presentialScheduling";

export interface IBookingTime {
  datetime: string;
  id_agenda: number;
  id_agendados: Array<number>;
  vagas: number;
}

export const getBookingTimes: (
  institution?: ResponseInstitution,
  dataPresentialSchedulingInstitution?: IDataPresentialScheduling,
) => Array<IBookingTime> = (institution, dataPresentialSchedulingInstitution) =>
  JSON.parse(
    institution?.setores
      .find((sector) => sector.id === dataPresentialSchedulingInstitution?.sector_id)
      ?.servicos.find(
        (service) => service.id === dataPresentialSchedulingInstitution?.servico_agendamento_id,
      )
      ?.agendamentos.find(
        (booking) => booking.id === dataPresentialSchedulingInstitution?.id_agendamento,
      )?.json_vagas_hora as string,
  )
    .filter(
      (time: IBookingTime) => (time.vagas as number) > 0 && dayjs(time.datetime).isAfter(dayjs()),
    )
    .map((time: IBookingTime) => ({
      ...time,
      datetime: dayjs(time.datetime).format("HH:mm"),
    }));
