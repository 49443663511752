import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { Calendar } from "@/pages/schedulings/components/calendar";
import { useAppId } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAvailableDays } from "@/pages/schedulings/hooks/use-available-days";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";
import { cn } from "@/lib/utils";

const selectDayFormSchema = z.object({
  date: z.date(),
});

type selectDayFormData = z.infer<typeof selectDayFormSchema>;

export function SelectDay() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [{ resource, local, schedule }, updateCreateSchedulingData] =
    useAtom(createSchedulingDataAtom);

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useAvailableDays({
    appId,
    entityId: Number(id),
    resourceId: Number(resource?.id),
    localId: Number(local?.id),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<selectDayFormData>({
    resolver: zodResolver(selectDayFormSchema),
    defaultValues: {
      date: schedule ? new Date(schedule.date) : undefined,
    },
  });

  function onSubmit({ date }: selectDayFormData) {
    const schedule = data?.schedules.find((item) => item.date === date.toISOString());

    if (schedule) {
      updateCreateSchedulingData((current) => ({
        ...current,
        schedule,
      }));

      handleChangeStep("hours");
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  return (
    <div className={cn("flex flex-1 flex-col gap-5 pb-8 pt-6", { "pb-40": isValid })}>
      <Text
        asChild
        size="lg"
        weight="medium"
        color="primary-500"
        className="px-4 text-[24px] leading-[28px]"
      >
        <h1>Selecione a data para o agendamento</h1>
      </Text>

      <form
        id="select-resource"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-4 px-4"
      >
        {isLoading && !data ? <ContentLoading /> : null}

        {!isLoading && !data?.availableDays.length ? <ContentEmpty /> : null}

        {!isLoading && data?.availableDays.length ? (
          <Controller
            control={control}
            name="date"
            render={({ field: { value, onChange } }) => (
              <Calendar
                selectedDate={value}
                onSelectDate={(date) => onChange(date)}
                availableDays={data.availableDays}
              />
            )}
          />
        ) : null}
      </form>

      <AnimatePresence>
        {isValid ? (
          <motion.footer
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            className="fixed inset-x-0 bottom-0 w-full bg-white px-4 py-8 shadow-[0_-1px_16px_0_rgba(0,0,0,0.15)]"
          >
            <Button
              type="submit"
              form="select-resource"
              full
              size="lg"
              className="mx-auto max-w-3xl"
            >
              {t("general.Continuar")}
            </Button>
          </motion.footer>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

function ContentLoading() {
  return (
    <div className="flex flex-col gap-8">
      {Array.from(Array(3).keys()).map((index) => (
        <div className="flex flex-col gap-5" key={index}>
          <IonSkeletonText animated className="h-8 w-32" />
          <div className="grid w-full grid-cols-7 gap-2">
            {Array.from(Array(35).keys()).map((index) => (
              <IonSkeletonText key={index} animated className="aspect-square w-full" />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function ContentEmpty() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
      <Text weight="medium" color="slate-700">
        Não há datas com vagas disponíveis!
      </Text>
      <Text>
        No momento não existe nenhuma data com vaga disponível para ser realizado um agendamento.
      </Text>
    </div>
  );
}
