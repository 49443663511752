const monthsLong = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const monthsCut = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export function decodeFormatDate(
  dateSQL: string,
  pattern?: "cut" | "long",
  withPreposition = false,
): string {
  switch (pattern) {
    case "cut":
      return dateSQL
        .split("T")[0]
        .split("-")
        .reverse()
        .map((date, index) => (index === 1 ? monthsCut[Number(date) - 1] : date))
        .join(withPreposition ? " de " : " ");
    default:
      return dateSQL
        .split("T")[0]
        .split("-")
        .reverse()
        .map((date, index) => (index === 1 ? monthsLong[Number(date) - 1] : date))
        .join(withPreposition ? " de " : " ");
  }
}
