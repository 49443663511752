import { create } from "zustand";

export type Config = {
  id: number;
  slug: string;
  value: string;
  type: string;
};

interface IUseConfigsStoreParams {
  configs: Array<Config>;
  actions: {
    updateConfigs: (configs: Array<Config>) => void;
    getConfig: (slug: string) => Config | undefined;
  };
}

export const useConfigStore = create<IUseConfigsStoreParams>((set, get) => {
  return {
    configs: [],
    actions: {
      updateConfigs: (configs: Array<Config>) => set({ configs }),
      getConfig: (slug: string) => get().configs.find((item) => item.slug === slug),
    },
  };
});

export const useConfigActions = () => useConfigStore((state) => state.actions);
