import { useState } from "react";
import { useSetAtom } from "jotai";
import { useHistory, useParams } from "react-router-dom";
import { IonModal, useIonViewWillLeave } from "@ionic/react";
// import dayjs from "dayjs";
import { Text } from "@astrolabe-ui/react";
import { Clock } from "@phosphor-icons/react";

import { Button, Modal } from "@/components";
import { useAppSlug } from "@/store/useAppStore";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

import illustrationSuccessQueue from "@/assets/illustrations/success-queue.svg";

type SuccessEnterToQueueProps = {
  showModal: boolean;
  onClose: () => void;
  id: number;
  prevision: number;
  textHelp: string | null;
};

export function SuccessEnterToQueue({
  showModal,
  onClose,
  id: schedulingId,
  // prevision,
  textHelp,
}: SuccessEnterToQueueProps) {
  const navigation = useHistory();
  const { id } = useParams<{ id: string }>();
  const slug = useAppSlug();

  const [showModalHelp, setShowModalHelp] = useState(false);

  const setCreateSchedulingData = useSetAtom(createSchedulingDataAtom);

  // const formattedMessage = useMemo(() => {
  //   if (!prevision) {
  //     return `<strong>Você está na fila!</strong> Aguarde enquanto novas vagas serão disponibilizadas.</strong>`;
  //   }

  //   const now = dayjs(new Date());
  //   const date = now.add(prevision, "day");

  //   return `<strong>Você está na fila!</strong> O tempo aproximado para o agendamento é de <strong>${date.toNow(
  //     true,
  //   )}</strong>`;
  // }, [prevision]);

  const formattedMessage = `<strong>Você está na fila!</strong> Aguarde enquanto novas vagas serão disponibilizadas.</strong>`;

  useIonViewWillLeave(() => {
    setCreateSchedulingData({
      resource: null,
      personal: null,
      schedule: null,
      hour: null,
      local: null,
      questions: [],
      extraFields: [],
    });
  });

  function handleGoScheduling() {
    navigation.replace(`/${slug}/agendamentos-fila/${schedulingId}`);
  }

  function handleScheduling() {
    navigation.replace(`/${slug}/agendamentos/${id}`);
  }

  function handleCloseModalHelp() {
    setShowModalHelp(false);
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={onClose} backdropDismiss={false}>
      <div className="flex min-h-full w-full flex-col items-center gap-8 overflow-auto px-4 pb-6 pt-12">
        <img src={illustrationSuccessQueue} alt="" className="w-64" />

        <div className="flex flex-col gap-4">
          <div className="flex w-full items-center justify-center gap-2 rounded-md border-thin border-slate-300 px-2 py-4">
            <Clock weight="fill" className="flex-shrink-0 text-primary-500" size={42} />
            <Text
              size="xs"
              color="slate-700"
              dangerouslySetInnerHTML={{ __html: formattedMessage }}
            />
          </div>

          <Text size="sm" className="text-center">
            Fique atento! Quando chegar a sua vez, você será notificado no seu celular e via e-mail.
          </Text>
        </div>

        <footer className="mt-auto flex w-full flex-col gap-2">
          {textHelp ? (
            <Button
              size="lg"
              variant="tertiary"
              className="underline"
              onClick={() => setShowModalHelp(true)}
            >
              Em caso de dúvidas, clique aqui
            </Button>
          ) : null}
          <Button size="lg" variant="secondary" onClick={handleScheduling}>
            Adicionar outra pessoa a fila
          </Button>
          <Button size="lg" onClick={handleGoScheduling}>
            Detalhes da solicitação
          </Button>
        </footer>
      </div>

      <Modal showModal={showModalHelp} onClose={handleCloseModalHelp}>
        {textHelp ? (
          <div
            className="prose prose-slate mx-auto mt-2 font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
            dangerouslySetInnerHTML={{ __html: textHelp }}
          />
        ) : null}
      </Modal>
    </IonModal>
  );
}
