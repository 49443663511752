import { http } from "@/lib/axios";

export async function getAddress(lat: number, lgn: number) {
  return http
    .get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lgn}`)
    .then((res) => {
      const addr = res.data.address;

      const part1: string = addr.road || addr.residential || "";
      const part2: string = addr.neighbourhood || addr.suburb || "";

      const undefinedLocation =
        part1.length === 0 && part2.length === 0 ? "Localização indefinida" : "";
      const division = part1.length > 0 && part2.length > 0 ? "-" : "";

      const cep = addr.postcode ? "- " + addr.postcode : "";
      const name = addr.name || addr.amenity ? (addr.name || addr.amenity) + " -" : "";

      return {
        complete: res.data.address,
        formated: `${name} ${part1} ${division} ${part2} ${undefinedLocation} ${cep}`.trim(),
      };
    });
}
