import { useHistory } from "react-router-dom";
import { CardContent, CardIcon, CardIndicator, CardRoot, CardTitle } from "@astrolabe-ui/react";

import { CustomIcon, IconPhosphorProps } from "@/components/CustomIcon";

type CardFaqProps = {
  faqId: number;
  id: number;
  icon: IconPhosphorProps;
  title: string;
};

export function CardFaq({ faqId, id, icon, title }: CardFaqProps) {
  const history = useHistory();

  return (
    <CardRoot key={id} onClick={() => history.push(`${faqId}/categories/${id}`)}>
      <CardIcon>
        <CustomIcon icon={icon} size={20} className="text-slate-700" />
      </CardIcon>

      <CardContent>
        <CardTitle>{title}</CardTitle>
      </CardContent>

      <CardIndicator />
    </CardRoot>
  );
}
