import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IonButton, IonAlert, IonLoading, IonCheckbox } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { getAppSlug } from "@/services/old/starter";
import { logout, updateUserData } from "@/services/old/auth";
import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";
import { useUserStore } from "@/store/useUserStore";

const Wrapper = styled.div`
  height: calc(80vh - 100px);
  width: 100%;
`;

const ActionDiv = styled.div`
  height: 100px;
  width: 100%;
`;

const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

export function UserTerms() {
  const history = useHistory();
  const CONTENT = getContent();

  const userUpdateTermsOfUse = useUserStore((state) => state.updateTermsOfUse);

  const link = localStorage.getItem(STORAGE.TERM_USE_LINK.KEY);
  const version = localStorage.getItem(STORAGE.TERM_USE_VERSION.KEY);

  const [isChecked, setIsChecked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  function Accept() {
    if (isChecked) {
      updateData();
    } else {
      setShowAlert(true);
    }
  }

  function updateData() {
    const form = new FormData();

    form.append("link_termo_uso", link || "");
    form.append("versao_termo_uso", version || "");

    updateUserData(form)
      .then(() => {
        if (link && version) {
          userUpdateTermsOfUse({ url: link, version });
        }
        history.replace("/" + getAppSlug(), {});
      })
      .catch((err) => console.error(err))
      .finally(() => setShowLoadingDialog(false));
  }

  return (
    <PageTemplate
      name={CONTENT.USERTERMS.TITLE}
      hideHeaderUserButton={true}
      backPress={() => {
        logout();
        history.push("/" + getAppSlug() + "/auth");
      }}
    >
      <Wrapper>
        <IFrame style={{ background: "#FFFFFF" }} src={link || ""} />
      </Wrapper>

      <ActionDiv>
        <div style={{ display: "flex" }}>
          <IonCheckbox
            style={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}
            onIonChange={(e) => setIsChecked(e.detail.checked)}
          />
          <b>{CONTENT.USERTERMS.ACCEPT_MSG}</b>
        </div>

        <IonButton expand="block" onClick={() => Accept()}>
          {CONTENT.GLOBAL.ACCEPT}
        </IonButton>

        <IonButton
          expand="block"
          onClick={() => {
            logout();
            history.push("/" + getAppSlug() + "/auth");
          }}
        >
          {CONTENT.GLOBAL.LOGOUT}
        </IonButton>
      </ActionDiv>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={CONTENT.USERTERMS.ALERT_FAIL}
        message={CONTENT.USERTERMS.ALERT_FAIL_MSG}
        buttons={["OK"]}
      />

      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
