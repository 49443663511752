import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IonAlert,
  IonLoading,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonTextarea,
} from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { BlockForm } from "@/components/old/BlockForm";
import { ErrorMessage } from "@/components/old/Base";
import { getUserID } from "@/services/old/auth";
import {
  deleteProduct,
  getCategories,
  getModule,
  getModulesNoPaginate,
  getProductPost,
  getSellerLoged,
  productRegister,
} from "@/services/old/feirinha";
import useQueryParams from "@/utils/queryParamsHook";
import { getContent } from "@/content/index";
import { NewUploadImage } from "@/components/old/UploadImage/new";
import { Button } from "@astrolabe-ui/react";
import { useQueryClient } from "@tanstack/react-query";

interface ProductParams {
  id_product: string;
  SLUG: string;
}

type fileImage = {
  base64: string;
  file: File;
};

const DEFAULT_MODULE = { id: -1, titulo: "", habilitar_validacao: false };

export function NewProduct() {
  const queryParams = useQueryParams();
  const CONTENT = getContent();
  const history = useHistory();
  const [id, setId] = useState(-1);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [unity, setUnity] = useState("");
  const [isProduc, setIsProduc] = useState(false);
  const [placeHolderImg, setPlaceHolderImg] = useState();
  const [image, setImage] = useState<fileImage | null>(null);

  const [categories, setCategories] = useState([]);
  const [loadingCat, setIsloadingCat] = useState(true);
  const [defautCategory, setDefautCategory] = useState({ id: -1, titulo: "" });
  const [selectedCategory, setSelectedCategory] = useState(defautCategory);
  const [modules, setModules] = useState([]);
  const [loadingMod, setIsloadingMod] = useState(true);
  const [selectedModule, setSelectedModule] = useState(DEFAULT_MODULE);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const params: ProductParams = useParams();

  const queryClient = useQueryClient();

  useEffect(() => {
    setIsloadingMod(true);
    getSellerLoged(params.SLUG)
      .then((seller) => {
        const sellerData = seller.data[0];
        if (params.id_product) {
          getProductPost(params.id_product, params.SLUG)
            .then((res) => {
              const prod = res.data;

              if (sellerData.usuario_id === +getUserID()) {
                setIsProduc(prod.unidade || prod.valor);
                setId(prod.id);
                setTitle(prod.titulo);
                setDescription(prod.descricao ?? "");
                setUnity(prod.unidade ?? "");
                setValue(prod.valor ?? "");
                setDefautCategory(prod.categoria);
                setPlaceHolderImg(prod.imagem);
                setUpCategories(prod.modulo_id, prod.categoria.id);
                getModule(prod.modulo_id, params.SLUG)
                  .then((res) => res?.data)
                  .then((data) => setSelectedModule(data?.modulo ?? DEFAULT_MODULE))
                  .catch((err) => console.error(err));
              } else {
                history.go(-1);
              }
            })
            .catch(() => {
              loadModules(-1, -1);
            });
        } else {
          const moduleId = Number(queryParams.get("modulo_id"));

          moduleId
            ? getModule(moduleId.toString(), params.SLUG)
                .then((res) => res?.data)
                .then((data) => {
                  setSelectedModule(data?.modulo ?? DEFAULT_MODULE);
                  setUpCategories(data?.modulo.id ?? DEFAULT_MODULE.id, -1);
                })
                .catch((err) => console.error(err))
            : loadModules(-1, -1);
        }
      })
      .catch((err) => {
        console.error(err);
        history.go(-1);
      });

    setIsloadingMod(false);
  }, []);

  function loadModules(moduleId: number, defaultCategoryId: number) {
    getModulesNoPaginate(params.SLUG)
      .then((res) => {
        setModules(res.data);
        setIsloadingMod(false);

        if (moduleId > 0) {
          const currentModule = res.data.find((i: any) => i.id === moduleId);

          if (currentModule) {
            setSelectedModule(currentModule);
            setUpCategories(moduleId, defaultCategoryId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloadingMod(false);
      });
  }

  function setUpCategories(moduleId: number, defaultCategoryId: number) {
    setIsloadingCat(true);
    setSelectedCategory(defautCategory);
    getCategories(moduleId + "", params.SLUG)
      .then((res) => {
        setCategories(res.data);
        setIsloadingCat(false);
        if (defaultCategoryId > 0) {
          const defaultCategory = res.data.find((i: any) => i.id === defaultCategoryId);

          if (defaultCategory) {
            setSelectedCategory(defaultCategory);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloadingCat(false);
      });
  }

  function imageChangeHandle(data: any) {
    setImage(data);
  }

  const [loginError, setLoginError] = useState({ title: "", unity: "", category: "", module: "" });

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  function handlerRegister() {
    let titleMsg = "";
    const unityMsg = "";
    let categoryMsg = "";
    let moduleMsg = "";
    let wrongValue = false;

    if (title.length === 0) {
      titleMsg = CONTENT.FEIRINHA.ERROR_NEED_NAME;
      wrongValue = true;
    }
    if (selectedCategory.id < 0) {
      categoryMsg = CONTENT.FEIRINHA.ERROR_NEED_CATEGORY;
      wrongValue = true;
    }
    if (selectedModule.id < 0) {
      moduleMsg = CONTENT.FEIRINHA.ERROR_NEED_MODULE;
      wrongValue = true;
    }

    setLoginError({ title: titleMsg, unity: unityMsg, category: categoryMsg, module: moduleMsg });

    if (wrongValue) {
      return;
    }
    const formProduct = new FormData();
    formProduct.append("titulo", title);
    formProduct.append("descricao", description ?? "");
    if (isProduc) {
      formProduct.append("valor", value ?? "A combinar");
      formProduct.append("unidade", unity ?? "");
    } else {
      formProduct.append("valor", "");
      formProduct.append("unidade", "");
    }
    formProduct.append("categoria_id", selectedCategory.id + "");
    formProduct.append("modulo_id", selectedModule.id + "");

    if (id > 0) {
      formProduct.append("id", id + "");
    }

    if (description && description.length > 0) {
      formProduct.append("logradouro", description);
    }

    if (image?.file) {
      formProduct.append("imagem", image.file, image.file?.name);
    }

    setShowLoadingDialog(true);

    productRegister(formProduct, id, params.SLUG)
      .then(() => {
        setShowLoadingDialog(false);

        queryClient.invalidateQueries({ queryKey: ["mySeller"] });

        if (selectedModule.habilitar_validacao) {
          setIsOpenModal(true);
        } else {
          redirect();
        }
      })
      .catch((err) => {
        setShowLoadingDialog(false);
        let titleMsg = "";
        // let valueMsg = "";
        let unityMsg = "";
        let categoryMsg = "";
        let moduleMsg = "";

        if (err.data.error.titulo) {
          titleMsg = err.data.error.titulo;
        }

        if (err.data.error.unidade) {
          unityMsg = err.data.error.unidade;
        }
        if (err.data.error.categoria_id) {
          categoryMsg = err.data.error.categoria_id;
        }
        if (err.data.error.modulo_id) {
          moduleMsg = err.data.error.modulo_id;
        }

        setLoginError({
          title: titleMsg,
          unity: unityMsg,
          category: categoryMsg,
          module: moduleMsg,
        });
      });
  }

  function redirect() {
    history.go(-1);
  }

  function handlerDelete() {
    setShowLoadingDialog(true);
    deleteProduct(id, params.SLUG)
      .then(() => {
        setShowLoadingDialog(false);
        history.go(-2);
      })
      .catch((err) => {
        console.error(err);
        setShowLoadingDialog(false);
      });
  }

  return (
    <PageTemplate name={id > 0 ? CONTENT.FEIRINHA.EDIT_PRODUCT : CONTENT.FEIRINHA.PRODUCT_REGISTER}>
      <BlockForm>
        <NewUploadImage onChange={imageChangeHandle} placeHolderURL={placeHolderImg} />

        <IonInput
          className="has-highlight-border"
          label={CONTENT.USER.NAME}
          labelPlacement="stacked"
          mode="md"
          fill="outline"
          value={title}
          onIonInput={(e) => {
            setTitle(e.detail.value + "");
          }}
        />

        {loginError.title && <ErrorMessage>{loginError.title}</ErrorMessage>}

        <IonTextarea
          className="has-highlight-border"
          label={CONTENT.FEIRINHA.DESCRIPTION}
          labelPlacement="stacked"
          mode="md"
          fill="outline"
          autoGrow={true}
          value={description}
          onIonInput={(e) => {
            setDescription(e.detail.value + "");
          }}
        />
        {modules.length > 1 && !loadingMod && (
          <IonSelect
            className="has-highlight-border"
            label={CONTENT.FEIRINHA.MODULE}
            labelPlacement="stacked"
            mode="md"
            fill="outline"
            style={{ width: "100%" }}
            value={selectedModule}
            interfaceOptions={{
              header: CONTENT.FEIRINHA.MODULE,
            }}
            placeholder={
              selectedModule.id < 0 ? CONTENT.FEIRINHA.SELECT_MODULE : selectedModule.titulo
            }
            onIonChange={(e) => {
              if (e.detail.value) {
                setSelectedModule(e.detail.value);
                setUpCategories(e.detail.value.id, -1);
              }
            }}
          >
            {modules.map((item: any, index: number) => {
              return (
                <IonSelectOption key={index} value={item}>
                  {item.titulo}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        )}
        {loginError.module && <ErrorMessage>{loginError.module}</ErrorMessage>}

        {selectedModule.id > 0 && (categories.length > 1 || !loadingCat) && (
          <IonSelect
            className="has-highlight-border"
            label={CONTENT.FEIRINHA.CATEGORY}
            labelPlacement="stacked"
            fill="outline"
            mode="md"
            value={selectedCategory}
            placeholder={
              selectedCategory.id < 0 ? CONTENT.FEIRINHA.SELECT_CATEGORY : selectedCategory.titulo
            }
            interfaceOptions={{
              header: CONTENT.FEIRINHA.CATEGORY,
            }}
            onIonChange={(e) => {
              if (e.detail.value) {
                setSelectedCategory(e.detail.value);
              }
            }}
          >
            {categories.map((item: any, index: number) => {
              return (
                <IonSelectOption key={index} value={item}>
                  {item.titulo}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        )}
        {selectedModule.id > 0 && loginError.category && (
          <ErrorMessage>{loginError.category}</ErrorMessage>
        )}

        <IonToggle
          className="has-highlight-border"
          checked={isProduc}
          mode="md"
          onIonChange={(e) => {
            setIsProduc(e.detail.checked);
          }}
          slot="end"
        >
          O item é um produto ?
        </IonToggle>

        {isProduc && (
          <IonSelect
            className="has-highlight-border"
            label={CONTENT.FEIRINHA.UNITY}
            labelPlacement="stacked"
            fill="outline"
            mode="md"
            placeholder="Selecione a unidade"
            style={{ width: "100%" }}
            onIonChange={(e) => {
              setUnity(e.detail.value + "");
            }}
            value={unity}
          >
            <IonSelectOption value="Unidade">Unidade (un)</IonSelectOption>
            <IonSelectOption value="Quilograma">Quilograma (kg)</IonSelectOption>
            <IonSelectOption value="Grama">Grama (g)</IonSelectOption>
            <IonSelectOption value="Metro">Metro (m)</IonSelectOption>
            <IonSelectOption value="Quilômetro">Quilômetro (km)</IonSelectOption>
          </IonSelect>
        )}
        {isProduc && (
          <IonInput
            className="has-highlight-border"
            label={CONTENT.FEIRINHA.VALUE}
            labelPlacement="stacked"
            fill="outline"
            type={"number"}
            mode="md"
            placeholder="A combinar"
            value={value}
            onIonInput={(e) => {
              setValue(e.detail.value + "");
            }}
          />
        )}

        <Button
          onClick={() => {
            handlerRegister();
          }}
        >
          {id > 0 ? CONTENT.FEIRINHA.EDIT_PRODUCT : CONTENT.FEIRINHA.PRODUCT_REGISTER}
        </Button>

        {id > 0 && (
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            {CONTENT.FEIRINHA.DELETE_REGISTER}
          </Button>
        )}
      </BlockForm>

      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => {
          setShowDeleteAlert(false);
        }}
        header={CONTENT.FEIRINHA.DELETE_REGISTER}
        message={CONTENT.FEIRINHA.DELETE_REGISTER_MSG}
        buttons={[
          {
            text: CONTENT.GLOBAL.CLOSE,
            handler: () => {
              setShowDeleteAlert(false);
            },
          },
          {
            text: CONTENT.GLOBAL.ACCEPT,
            handler: () => {
              handlerDelete();
            },
          },
        ]}
      />
      <IonAlert
        isOpen={isOpenModal}
        backdropDismiss={false}
        cssClass="my-custom-class"
        header={CONTENT.FEIRINHA.WARNING_TITTLE}
        message={CONTENT.FEIRINHA.WARNING_TEXT}
        buttons={[
          {
            text: "Confirmar",
            handler: () => {
              setIsOpenModal(false);
              redirect();
            },
          },
        ]}
      />
      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
