import { http } from "@/lib/axios";
import { Holder } from "./types";

export async function getHolder({
  appId,
  titularId,
}: {
  appId: number;
  titularId: number;
}): Promise<Holder> {
  try {
    const response = await http.get<Holder>(`/v3/${appId}/titular_review/get_titular/${titularId}`);

    return response.data;
  } catch (error) {
    throw new Error();
  }
}
