import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { useQuery } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";

import { LoggedContent } from "./components/LoggedContent";
import { DisconnectedContent } from "./components/DisconnectedContent";
import { useConfig } from "@/hooks/useConfig";
import { useAppStore } from "@/store/useAppStore";
import { useUserToken } from "@/store/useAuthStore";

import logoMobbyCidade from "@/assets/images/Cidades.png";

export function Menu() {
  const isLogged = !!useUserToken();
  const appVersion = useAppStore((state) => state.app?.version);

  const enabledOTA = useConfig({
    label: "enabled.ota",
  });

  const { data: version } = useQuery({
    queryKey: ["appVersion"],
    queryFn: getAppVersion,
  });

  async function getAppVersion() {
    if (Capacitor.isNativePlatform()) {
      if (!enabledOTA) {
        const app = await App.getInfo();
        return app.version;
      }

      const currentApp = await CapacitorUpdater.current();
      return currentApp.bundle.version;
    }

    if (appVersion) {
      return appVersion.version;
    }
  }

  return (
    <div className="flex h-full w-full flex-col bg-white pb-[calc(var(--safe-area-inset-bottom)+1.5rem)]">
      {isLogged ? <LoggedContent /> : <DisconnectedContent />}

      <footer className="mt-auto flex flex-col items-center gap-4">
        <img src={logoMobbyCidade} alt="" className="w-2/5" />

        {version ? (
          <Text size="xs" color="slate-400" weight="medium">
            Versão {version}
          </Text>
        ) : null}
      </footer>
    </div>
  );
}
