import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { Version } from "@/store/useAppStore";

export async function checkUpdate({ url, version }: Version) {
  try {
    const currentApp = await CapacitorUpdater.current();

    if (currentApp.bundle.version === version) {
      return;
    }

    const updatedBundle = await CapacitorUpdater.download({
      url,
      version,
    });

    await CapacitorUpdater.set(updatedBundle);

    console.log("Update da aplicação realizado com sucesso!");
  } catch (err) {
    console.error(err);
    throw err;
  }
}
