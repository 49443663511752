import { useHistory } from "react-router-dom";
import { IonModal } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { useAppSlug } from "@/store/useAppStore";

import illustrationRemovedFromScheduling from "@/assets/illustrations/removed-from-scheduling.svg";

type RemovedFromSchedulingProps = {
  showModal: boolean;
  onClose: () => void;
  content: string | null;
};

export function RemovedFromScheduling({ showModal, onClose, content }: RemovedFromSchedulingProps) {
  const navigation = useHistory();
  const slug = useAppSlug();

  function handleGoHome() {
    navigation.replace(`/${slug}/home`);
  }

  function handleGoMySchedulings() {
    navigation.replace(`/${slug}/meus-agendamentos`);
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={onClose} backdropDismiss={false}>
      <div className="flex min-h-full w-full flex-col items-center gap-8 overflow-auto px-4 pb-6 pt-24">
        <img src={illustrationRemovedFromScheduling} alt="" className="w-40" />

        {content ? (
          <div
            className="prose prose-slate mx-auto mt-2 font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}

        {!content ? (
          <div className="flex flex-col items-center gap-3 text-center">
            <Text size="md" weight="medium" color="slate-700">
              Não foi possível continuar
            </Text>

            <Text color="slate-700">
              De acordo com suas respostas no formulário, não será possível continuar no processo de
              agendamento.
            </Text>
          </div>
        ) : null}

        <footer className="mt-auto flex w-full flex-col gap-2">
          <Button size="lg" variant="secondary" onClick={handleGoMySchedulings}>
            Consultar meus agendamentos
          </Button>
          <Button size="lg" onClick={handleGoHome}>
            Voltar ao início
          </Button>
        </footer>
      </div>
    </IonModal>
  );
}
