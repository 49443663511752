import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonLoading } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { TabTemplate } from "@/templates/old/TabTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { Chat } from "./components/Chat";
import { Details } from "./components/Details";
import { getProtocolById } from "@/services/old/servicos";
import { getContent } from "@/content/index";

const firstTab = {
  value: "Atendimento",
  content: <Chat />,
};

const secondTab = {
  value: "Detalhes",
  content: <Details />,
};

interface ProtocolParams {
  id: string;
  SLUG: string;
}

export function ProtocolNew() {
  const CONTENT = getContent();

  const params: ProtocolParams = useParams();

  const { isLoading, data } = useQuery(
    ["getProtocolById", params.id],
    () => getProtocolById(params.id, params.SLUG),
    { retry: 1 },
  );

  if (isLoading) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  } else if (!data) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.PROTOCOLS.CANT_ACCESS}</PageTitle>
      </PageTemplate>
    );
  }

  return <TabTemplate first={firstTab} second={secondTab} name={CONTENT.SERVICES.HEADER_TITLE} />;
}
