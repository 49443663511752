import { http } from "@/lib/axios";
import { Booking } from "./types";

export async function cancelBooking(
  appId: number,
  espacoId: string,
  reservaId: string,
): Promise<Booking> {
  try {
    const response = await http.post<Booking>(
      `/v3/${appId}/agendamento_local/espaco/${espacoId}/reserva/${reservaId}/cancelar`,
    );

    return response.data;
  } catch (error) {
    console.error("error = ", error);
    throw new Error();
  }
}
