import { Text } from "@astrolabe-ui/react";
import { Modal } from "@/components";

type InstructionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  textInstructions: string;
};

export function InstructionsModal({ isOpen, onClose, textInstructions }: InstructionsModalProps) {
  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className="flex w-full flex-col gap-3">
        <Text weight="medium" size="md" color="slate-700">
          Instruções de agendamento
        </Text>

        <div
          className="prose prose-slate mx-auto font-sans text-sm text-slate-700 [overflow-wrap:anywhere] prose-p:text-slate-700 prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p]:mt-0 [&>p]:last:mb-0"
          dangerouslySetInnerHTML={{ __html: textInstructions }}
        />
      </div>
    </Modal>
  );
}
