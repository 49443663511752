import { IonSpinner } from "@ionic/react";
import { useFormContext } from "react-hook-form";
import { documentOutline } from "ionicons/icons";
import format from "date-fns/format";

import { getContent } from "@/content/index";

import {
  Container,
  ContentTitleField,
  ContentField,
  ContentTitle,
  ContentFieldValue,
  ImagePreview,
  Image,
  SemValor,
  FilePreview,
  IconFilePreview,
} from "./styles";

interface ShowFormProtocolProps {
  handleBack: () => void;
  submitForm: () => Promise<void>;
  fields?: any;
  isLoading?: boolean;
}

export function ShowFormProtocol({
  handleBack,
  submitForm,
  fields,
  isLoading,
}: ShowFormProtocolProps) {
  const CONTENT = getContent();

  const { getValues } = useFormContext();

  return (
    <Container>
      <ContentField>
        {getValues("subcategory").label && (
          <ContentTitleField>
            <ContentTitle>Subcategoria</ContentTitle>
            <ContentFieldValue>{getValues("subcategory").label}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("nome") && (
          <ContentTitleField>
            <ContentTitle>Nome</ContentTitle>
            <ContentFieldValue>{getValues("nome")}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("imagem") && getValues("imagemValue") && (
          <ContentTitleField>
            <ContentTitle>Imagem</ContentTitle>
            <ImagePreview>
              <Image src={`${getValues("imagemValue")}`} alt="uploaded" />
            </ImagePreview>
          </ContentTitleField>
        )}

        {getValues("endereco") && (
          <ContentTitleField>
            <ContentTitle>Endereço</ContentTitle>
            <ContentFieldValue>{getValues("endereco")}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("mensagem") && (
          <ContentTitleField>
            <ContentTitle>Mensagem</ContentTitle>
            <ContentFieldValue>{getValues("mensagem")}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("identificador") && (
          <ContentTitleField>
            <ContentTitle>Identificador</ContentTitle>
            <ContentFieldValue>{getValues("identificador")}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("placaVeiculo") && (
          <ContentTitleField>
            <ContentTitle>Placa veículo</ContentTitle>
            <ContentFieldValue>{getValues("placaVeiculo")}</ContentFieldValue>
          </ContentTitleField>
        )}

        {getValues("campos") &&
          // eslint-disable-next-line array-callback-return
          getValues("campos").map((item: any, index: number) => {
            const resultField = fields?.find((i: any) => i.id === index);

            if (
              resultField.type === "string" ||
              resultField.type === "integer" ||
              resultField.type === "data"
            ) {
              return (
                <ContentTitleField key={index}>
                  <ContentTitle>{resultField.title}</ContentTitle>
                  <ContentFieldValue>
                    {!item ? (
                      <SemValor>Sem valor</SemValor>
                    ) : resultField.type !== "data" ? (
                      item
                    ) : (
                      format(new Date(item), "dd/MM/yyyy")
                    )}
                  </ContentFieldValue>
                </ContentTitleField>
              );
            }
            if (resultField.type === "imagem" || resultField.type === "arquivo") {
              if (!item) {
                return (
                  <ContentTitleField key={index}>
                    <ContentTitle>{resultField.title}</ContentTitle>
                    <ContentFieldValue>
                      <SemValor>Sem valor</SemValor>
                    </ContentFieldValue>
                  </ContentTitleField>
                );
              } else {
                const fileValue = item.file.type.split("/");
                const isImage = fileValue[0] === "image";

                return (
                  <ContentTitleField key={index}>
                    <ContentTitle>{resultField.title}</ContentTitle>
                    <ContentFieldValue>
                      {isImage && (
                        <ImagePreview>
                          <Image src={item.path} alt="" />
                        </ImagePreview>
                      )}
                      {!isImage && (
                        <FilePreview>
                          <IconFilePreview icon={documentOutline} />
                          <small>{item.file.name}</small>
                        </FilePreview>
                      )}
                    </ContentFieldValue>
                  </ContentTitleField>
                );
              }
            }
          })}
      </ContentField>
      <footer>
        <button type="button" onClick={handleBack} className="outline-btn" disabled={isLoading}>
          {CONTENT.VINCULAR_TITULAR.RETURN_STEP}
        </button>
        <button onClick={submitForm} disabled={isLoading}>
          {!isLoading && <span>{CONTENT.VINCULAR_TITULAR.CONCLUDE}</span>}
          {isLoading && <IonSpinner name="dots" color="#FFFFFF"></IonSpinner>}
        </button>
      </footer>
    </Container>
  );
}
