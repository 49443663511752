import { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";

import emptyImage from "@/assets/images/empty-image.png";
import { cn } from "@/lib/utils";
import { X } from "@phosphor-icons/react";

export const ModalImage = ({
  image,
  show,
  onHidden,
  closeButton,
}: {
  image: string;
  show: boolean;
  onHidden: Dispatch<SetStateAction<boolean>>;
  closeButton?: boolean;
}) => {
  return createPortal(
    <div
      className={cn(
        " absolute z-10 flex h-full w-full animate-like items-center justify-center p-8 backdrop-blur",
        {
          hidden: !show,
        },
      )}
      onClick={() => onHidden(false)}
    >
      {closeButton && (
        <button
          onClick={() => onHidden(false)}
          className="z-100 absolute right-6 top-6 rounded-xs border-medium border-slate-400 p-1"
        >
          <X size={20} className="text-slate-700" />
        </button>
      )}
      <img
        src={image || emptyImage}
        className="h-full w-full rounded-md object-contain"
        alt="image zoom"
      />
    </div>,
    document.body,
  );
};
