import { IonPage, useIonToast } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import axios from "axios";

import { BigSpinner, Container, ErrorContent, FieldError, Loading, Spinner } from "./styles";

import { MainContent } from "@/templates/old/ppa/MainContent";
import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { SelectTheme } from "./components/select-theme/select-theme";
import { Theme } from "../proposals/hooks/use-proposals";
import { useThemes } from "./hooks/use-themes";
import { SelectTerritory } from "./components/select-territory/select-territory";
import { Territory } from "../territories/atoms/territories-atom";
import { http } from "@/lib/axios";
import { getAccessToken } from "@/services/old/auth";
import { getAppId } from "@/services/old/provider";

import logoPPA from "@/assets/images/logo-ppa.png";

interface FormSuggestionData {
  suggestion: string;
  theme: Theme;
  territory: Territory;
}

interface RegisterSuggestionParams {
  consultId: string;
  suggestion: string;
  territory: string;
  idTheme: number;
}

export function SuggestProgram() {
  const { SLUG, id } = useParams<{
    SLUG: string;
    id: string;
  }>();

  const history = useHistory();

  const [showToast] = useIonToast();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormSuggestionData>();

  const { themes, isLoading, isError } = useThemes({
    SLUG,
    idConsult: Number(id),
  });

  const { mutateAsync, isLoading: isLoadingRegister } = useMutation({
    mutationFn: (data: RegisterSuggestionParams) => registerSuggestion(data),
  });

  async function handleSendSuggestion(data: FormSuggestionData): Promise<void> {
    const { suggestion, theme, territory } = data;

    await mutateAsync(
      {
        consultId: id,
        suggestion,
        territory: String(territory.id),
        idTheme: theme.id,
      },
      {
        onSuccess: () => {
          history.replace(`/${SLUG}/home`);

          showToast({
            message:
              "Atenção! A sua proposta passará por moderação exclusivamente para garantir que nenhum conteúdo inapropriado (desrespeitoso, de assédio ou discurso de ódio) seja inserido.",
            duration: 10000,
          });
        },
        onError: (err) => {
          console.error(err);

          if (axios.isAxiosError(err)) {
            if (err.response?.data.error.includes("Limite de propostas")) {
              showToast({
                message: "Limite de propostas atingido!",
                duration: 3000,
              });
              return;
            }
          }

          showToast({
            message: "Erro ao cadastrar, tente novamente!",
            duration: 3000,
          });
        },
      },
    );
  }

  async function registerSuggestion(data: RegisterSuggestionParams) {
    const appId = await getAppId(SLUG);

    if (!appId) {
      throw new Error();
    }

    const body = {
      descricao: data.suggestion,
      territorio: data.territory,
      temas: data.idTheme,
    };

    return http.post(`v3/${appId}/consultas/${data.consultId}/propostas`, body, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  function handleGoback() {
    history.goBack();
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoback} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="PPA RN" />
        </NavLink>
      </Header>
      <MainContent>
        <Container>
          {isError ? (
            <ErrorContent>
              <span>Tivemos um erro ao buscar os dados, tente novamente!</span>
            </ErrorContent>
          ) : !themes || isLoading ? (
            <Loading>
              <BigSpinner name="circular" />
              <span>Carregando dados</span>
            </Loading>
          ) : (
            <>
              <header>
                <strong>Cadastrar sugestão</strong>
                <p>Informe abaixo sua sugestão de ação para que o Governo Estadual realize.</p>
              </header>

              <form onSubmit={handleSubmit(handleSendSuggestion)}>
                <span>Informe os dados abaixo</span>

                <div>
                  <Controller
                    control={control}
                    name="theme"
                    render={({ field: { value, onChange } }) => (
                      <SelectTheme value={value} onChange={onChange} themes={themes} />
                    )}
                  />

                  <Controller
                    control={control}
                    name="territory"
                    render={({ field: { value, onChange } }) => (
                      <SelectTerritory value={value} onChange={onChange} />
                    )}
                  />

                  <div className="flex flex-col gap-1.5">
                    <label className="text-sm text-slate-700">Descreva a sua proposta</label>

                    <textarea
                      rows={10}
                      maxLength={400}
                      placeholder="Descreva (Max. 400 caracteres)"
                      spellCheck={false}
                      {...register("suggestion", {
                        required: {
                          value: true,
                          message: "Informe sua sugestão para enviar",
                        },
                      })}
                    />
                  </div>
                  {errors?.suggestion ? <FieldError>{errors.suggestion.message}</FieldError> : null}
                </div>

                <button>{isLoadingRegister ? <Spinner name="dots" /> : "Enviar sugestão"}</button>
              </form>
            </>
          )}
        </Container>
      </MainContent>
    </IonPage>
  );
}
