import { ComponentProps, createContext, useContext, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Text, Tabs, TabsList, TabsTrigger, TabsContent } from "@astrolabe-ui/react";
import { CaretUp, CaretLeft, CaretDown } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton, Loading } from "@/components";
import { useAppStore } from "@/store/useAppStore";

import { Details } from "./Details";
import { Chat } from "./Chat";
import { useProtocolById } from "./hooks/useProtocolById";

import errorIllustration from "@/assets/illustrations/error.svg";

interface TabContextData {
  isTop: boolean;
  setIsTop: (value: boolean) => void;
}

const TabContext = createContext<TabContextData>({} as TabContextData);

export const useTabContext = () => useContext(TabContext);

export const Protocol = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { id } = useParams<{ id: string }>();

  const [appId, slug] = useAppStore((state) => [state.app?.id, state.app?.slug]);

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [isTop, setIsTop] = useState(false);
  const tabsRef = useRef<HTMLDivElement>(null);
  const chatsRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isError } = useProtocolById({
    appId,
    protocolId: Number(id),
  });

  function handleOnChangeTab(value: string) {
    if (value === "details") {
      tabsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setIsTop(false);
    }
  }

  function handleClickButtonFloating() {
    if (isTop) {
      chatsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      tabsRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    setIsTop((prevState) => !prevState);
  }

  const handleNavigate = () => {
    history.replace(`/${slug}/protocols`);
  };
  return (
    <Layout>
      <Tabs
        ref={tabsRef}
        defaultValue={params.get("flag") || "chat"}
        onValueChange={handleOnChangeTab}
        className="flex grow flex-col"
      >
        <div className="sticky top-0">
          <Header variant="secondary" className="static">
            <div className="flex w-full items-center after:flex-1 after:content-['']">
              <div className="flex-1">
                <HeaderButton
                  icon={<CaretLeft weight="bold" />}
                  variant="secondary"
                  onClick={handleNavigate}
                />
              </div>

              <div className="text-center">
                <Text size="md" weight="medium" leading="relaxed" color="primary-500">
                  ID {id}
                </Text>
                <Text
                  size="xs"
                  weight="medium"
                  color={
                    data?.status.title === "Em Aberto"
                      ? "primary-500"
                      : data?.status.title === "Encerrado"
                      ? "green-600"
                      : "orange-600"
                  }
                >
                  {data?.status?.title === "Em Atendimento" && data?.tagCategoryService?.title
                    ? data?.tagCategoryService.title
                    : data?.status.title}
                </Text>
              </div>
            </div>
          </Header>
          <TabsList>
            <TabsTrigger value="chat">{t("general.Atendimento")}</TabsTrigger>
            <TabsTrigger value="details">{t("general.Detalhes")}</TabsTrigger>
          </TabsList>
        </div>

        <Content>
          <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 ios:pb-2">
            {isLoading ? (
              <div className="flex h-full flex-col items-center justify-center">
                <Loading variant="secondary" size="md" />
              </div>
            ) : isError ? (
              <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
                <img src={errorIllustration} alt="error illustration" />
                <div className="flex flex-col gap-2">
                  <Text weight="medium" color="slate-700">
                    {t("error.Erro ao carregar o protocolo")}
                  </Text>
                </div>
              </div>
            ) : (
              <TabContext.Provider value={{ isTop, setIsTop }}>
                <TabsContent value="chat">
                  <Chat protocol={data} dummyRef={chatsRef} />
                  <FloatingButton
                    isTop={isTop}
                    extraPadding={data.status.id !== 3}
                    onClick={handleClickButtonFloating}
                  />
                </TabsContent>
                <TabsContent value="details">
                  <Details protocol={data} />
                </TabsContent>
              </TabContext.Provider>
            )}
          </div>
        </Content>
      </Tabs>
    </Layout>
  );
};

interface FloatingButtonProps extends ComponentProps<"button"> {
  isTop: boolean;
  extraPadding: boolean;
}

function FloatingButton({ isTop, extraPadding, ...props }: FloatingButtonProps) {
  return (
    <button
      className={`absolute bottom-16 right-3 z-10 flex h-6 w-6 items-center justify-center rounded-full bg-slate-100 drop-shadow-md ${
        extraPadding ? "bottom-16" : "bottom-4"
      }`}
      {...props}
    >
      {isTop ? (
        <CaretDown weight="bold" className="text-primary-500" />
      ) : (
        <CaretUp weight="bold" className="text-primary-500" />
      )}
    </button>
  );
}
