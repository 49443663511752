import dayjs from "dayjs";
import { http } from "@/lib/axios";
import { News } from "./types";

type NewsDataHttpResponse = {
  id: number;
  titulo: string;
  imagem: string;
  categoria: { nome: string };
  created_at: string;
  multi_categorias: Array<{ nome: string; id: number }>;
};

interface getNewsHttpResponse {
  data: Array<NewsDataHttpResponse>;
  current_page: number;
  next_page_url: string | null;
  total: number;
}

interface GetPreviewNews {
  appId: number;
}

export async function getPreviewNews({ appId }: GetPreviewNews): Promise<Array<News>> {
  const response = await http.get<getNewsHttpResponse>(`v3/${appId}/noticias`, {
    params: {
      paginate: 1,
      page: 1,
      per_page: 5,
    },
  });

  const news = response.data.data.map((item) => ({
    id: item.id,
    title: item.titulo,
    imageUrl: item.imagem,
    category: item.categoria.nome,
    categories: item.multi_categorias,
    like: false,
    time: formatDate(item.created_at),
  }));

  return news;
}

function formatDate(date: string) {
  return dayjs(date).fromNow();
}
