import { http } from "@/lib/axios";

export type LikeParams = {
  appId: number;
  id: number;
};

export async function like({ appId, id }: LikeParams) {
  try {
    await http.post(`/v2/${appId}/modulos/fotos/${id}/curtir`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
