import { http } from "@/lib/axios";

export type CreateSchedulingParams = {
  appId: number;
  schedulingId: number;
  resourceId: number;
  localId: number;
  scheduleId: number;
  hourId: number;
  personal: {
    name: string;
    cpf: string;
    email: string;
    phone: string;
  };
  extraFields: Array<{
    id: number;
    response: string | number;
  }>;
  questions: Array<{
    id: number;
    response: string | number;
  }>;
};

export async function createScheduling({
  appId,
  schedulingId,
  resourceId,
  localId,
  scheduleId,
  hourId,
  personal,
  extraFields,
  questions,
}: CreateSchedulingParams) {
  try {
    const response = await http.post<{ cadastro: { id: number } }>(
      `v3/${appId}/mobile/entidade_agendamento/${schedulingId}/recursos/${resourceId}/agendamento`,
      {
        nome: personal.name,
        cpf: personal.cpf,
        telefone: personal.phone,
        local_id: localId,
        data_id: scheduleId,
        hora_id: hourId,
        ...(personal.email ? { email: personal.email } : {}),
        ...(extraFields.length
          ? {
              respostas_campos: extraFields.map((item) => ({
                id: item.id,
                resposta: item.response,
              })),
            }
          : {}),
        ...(questions.length
          ? {
              respostas_perguntas: questions.map((item) => ({
                id: item.id,
                resposta: item.response,
              })),
            }
          : {}),
      },
    );

    return {
      id: response.data.cadastro.id,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
