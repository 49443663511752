import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  background-color: #ffffff;
  color: #334155;

  footer {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 12px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const ContentImage = styled.div`
  /* margin-top: 48px; */
`;

export const Image = styled.img`
  width: 100%;
`;

export const TitleContent = styled.div`
  max-width: 180px;
  text-align: center;
  margin: 24px 0px 33px;
  font-size: 16px;
  font-weight: 500;
`;
export const CodeTitle = styled.div`
  font-size: 12px;
`;

export const ContentCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px 12px;
  padding: 4px 24px;
  gap: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
`;

export const Code = styled.span`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 16px;
`;

export const IconCopy = styled(IonIcon)`
  font-size: 20px;
  color: #e2e8f0;
  cursor: pointer;
`;

export const Description = styled.span`
  color: #94a3b8;
  font-size: 14px;
`;

export const ContentDescriptionNumber = styled.div`
  margin: 12px 0px 12px;
`;

export const Button = styled.button`
  background-color: ${COLORS.PRIMARY};
  color: #ffffff;
  font-weight: 500;

  width: 100%;
  padding: 16px 24px;
  border-radius: 6px;
`;

export const Link = styled.a`
  color: ${COLORS.PRIMARY};
  padding: 16px 24px;
`;
