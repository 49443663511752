import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";
import { IOperationTime } from "./getOperationTime";

export const getDays = (
  precedence?: number,
  dataAssetsScheduling?: IDataAssetsScheduling,
  operationTime?: Array<IOperationTime>,
) =>
  Array(precedence)
    .fill(null)
    .map((_, index) => ({
      dayOfWeek: dayjs().add(index, "day").day(),
      day: dayjs().add(index, "day").date(),
      selected: index === dataAssetsScheduling?.extra_fields?.position_date,
      status:
        operationTime &&
        operationTime.some((ot) => Number(ot.dia_semana) === dayjs().add(index, "day").day()) &&
        operationTime.find((ot) => Number(ot.dia_semana) === dayjs().add(index, "day").day())
          ?.habilitado,
    }));
