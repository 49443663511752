import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";

import illustrationError from "@/assets/illustrations/error.svg";

interface ModalAccountDeletionProps {
  handleCloseModal: () => void;
  handleCancelProcess: () => void;
}

export function ModalAccountDeletion({
  handleCancelProcess,
  handleCloseModal,
}: ModalAccountDeletionProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col items-center gap-8">
        <img src={illustrationError} alt="Celular com ponto de exclamação no centro." />
        <Text asChild size="md" weight="medium" color="slate-700">
          <strong>{t("auth.Erro no login")}</strong>
        </Text>
      </header>

      <div className="mt-8 flex flex-col text-center">
        <Text>
          {t(
            "auth.Sua conta está em processo de exclusão, caso queira encerrar esse processo clique no botão referente.",
          )}
        </Text>
      </div>

      <footer className="mt-12 flex w-full flex-col gap-3">
        <Button
          variant="secondary"
          size="lg"
          full
          onClick={handleCloseModal}
          className="border-slate-200"
        >
          {t("general.Tentar novamente")}
        </Button>

        <Button
          variant="primary"
          size="lg"
          rounded="md"
          onClick={() => {
            handleCloseModal();
            handleCancelProcess();
          }}
        >
          {t("auth.Encerrar processo")}
        </Button>
      </footer>
    </div>
  );
}
