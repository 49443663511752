import { http } from "@/lib/axios";

export type DeleteCommentParams = {
  appId: number;
  postId: number;
  id: number;
};

export async function deleteComment({ appId, postId, id }: DeleteCommentParams) {
  try {
    await http.delete(`/v2/${appId}/modulos/fotos/${postId}/comentar/${id}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
