import { useState } from "react";
import { Button, Text } from "@astrolabe-ui/react";
import { Download } from "@phosphor-icons/react";

import { ModalImage } from "@/components";
import { IMessage } from "@/services/protocols";

import { useChatContext } from ".";
import { isImage } from "./utils/isImage";
import dayjs from "dayjs";

export function Support({ message }: { message: IMessage }) {
  const { dummy } = useChatContext();

  const [show, setShow] = useState(false);

  if (message.kind === "info")
    return (
      <div
        dangerouslySetInnerHTML={{ __html: message.message }}
        className="rounded-md border-thin border-slate-200 p-2 text-center font-sans text-xs text-primary-500 [&_strong]:font-semibold"
      />
    );
  else if (message.is_file)
    return (
      <div className="mb-1 flex">
        <ModalImage image={message.message} show={show} onHidden={() => setShow(false)} />
        {isImage(message.message) ? (
          <div className="flex flex-col gap-1 text-left">
            <div className="mb-1 rounded-b-md rounded-r-md bg-slate-200 p-2 text-right">
              <img
                src={message.message}
                className="rounded-md"
                onClick={() => setShow(true)}
                alt="image send by support"
                onLoad={() => dummy && dummy.current?.scrollIntoView()}
              />
            </div>
            <span className="font-sans text-[10px] text-slate-500">{`ID: ${
              message.id
            }, recebido em: ${dayjs(message.created_at).format("DD/MM/YYYY - HH:mm")}`}</span>
          </div>
        ) : (
          <div className="flex flex-col gap-1 text-left">
            <div className="mb-1 rounded-b-md rounded-r-md bg-slate-200 p-2 text-right">
              <Button
                onClick={() => window.open(message.message, "_blank")}
                className="items-center"
              >
                <Download weight="fill" /> Abrir Arquivo
              </Button>
            </div>
            <span className="font-sans text-[10px] text-slate-500">{`ID: ${
              message.id
            }, recebido em: ${dayjs(message.created_at).format("DD/MM/YYYY - HH:mm")}`}</span>
          </div>
        )}
      </div>
    );
  else if (message.kind === "change_state")
    return (
      <div className="p-2 text-center font-sans text-xs [&_strong]:font-semibold">
        <Text color="slate-400" size="xs">
          {message.message.split(":")[0]}:
        </Text>
        <Text
          color={
            message.message.split(":")[1].includes("Atendimento")
              ? "orange-600"
              : message.message.split(":")[1].includes("Aberto")
              ? "primary-500"
              : "green-600"
          }
          size="xs"
          weight="medium"
        >
          {message.message.split(":")[1]}
        </Text>
      </div>
    );

  return (
    <div className="flex flex-wrap font-sans text-xs [&_strong]:font-semibold">
      <div
        className="mb-1 rounded-b-md rounded-r-md bg-slate-200 p-2 text-slate-700 [overflow-wrap:anywhere] [&_a]:text-blue-600 [&_a]:underline"
        dangerouslySetInnerHTML={{ __html: message.message }}
      />
      {message.time && <div className="w-full text-left text-slate-500">{message.time}</div>}
    </div>
  );
}
