import { http } from "@/lib/axios";
import { convertTextWithAnchors } from "@/utils/convert-text-with-anchors";

type GetMessagesMarketParams = {
  appId: number;
  id: number;
  userId?: number;
};

type GetMessagesMarketRequestResponse = {
  comerciante: {
    id: number;
    nome: string;
  };
  user: {
    id: number;
    name: string;
  };
  mensagens: Array<{
    id: number;
    mensagem: string;
    created_at: string;
    user_id: number;
    mensagem_comerciante: boolean;
  }>;
};

type MarketMessage = {
  id: number;
  message: string;
  created_at: string;
  userId: number;
  fromMerchant: boolean;
};

export async function getMessagesMarket({ appId, id, userId }: GetMessagesMarketParams) {
  try {
    const response = await http.get<GetMessagesMarketRequestResponse>(
      `/v3/${appId}/comerciante/${id}/mensagens`,
      { params: { ...(userId ? { user_id: userId } : {}) } },
    );

    const messages = response.data.mensagens.reduce(
      (dateMessage, message) => {
        const date = message.created_at.split("T")[0];

        const messagesOnDate = dateMessage[date] || (dateMessage[date] = []);

        messagesOnDate.push({
          id: message.id,
          created_at: message.created_at,
          message: convertTextWithAnchors(message.mensagem),
          userId: message.user_id,
          fromMerchant: message.mensagem_comerciante,
        });

        return dateMessage;
      },
      {} as Record<string, Array<MarketMessage>>,
    );

    return {
      merchant: {
        id: response.data.comerciante.id,
        name: response.data.comerciante.nome,
      },
      user: response.data.user,
      messages,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
