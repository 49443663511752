import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ptBR from "@/assets/locale/pt-BR.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  fallbackLng: "ptBR",
  resources: {
    ptBR,
  },
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
