import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";
import { IWorkSchedule } from "./getWorkSchedule";

export const getLengthTimeSteps = (
  workSchedule?: IWorkSchedule,
  dataAssetsScheduling?: IDataAssetsScheduling,
) => {
  const step = Number(dataAssetsScheduling?.extra_fields?.step);

  const qtdHoursInMinutes = dayjs(workSchedule?.hora_fim)
    .add(step, "minute")
    .diff(dayjs(workSchedule?.hora_inicio), "minutes");

  return qtdHoursInMinutes / step;
};
