import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { useDebounce } from "@/hooks/useDebounce";
import { getSchedulingsByCPF } from "@/services/schedulings";
import { filterSchedulingsAtom } from "../atoms/filter-schedulings-atom";

type useSchedulingsProps = {
  appId: number;
};

export function useSchedulings({ appId }: useSchedulingsProps) {
  const query = useAtomValue(filterSchedulingsAtom);

  const cpf = useDebounce(query, 700);

  return useQuery({
    queryKey: ["schedulings_cpf", cpf, appId],
    queryFn: () => getSchedulingsByCPF({ appId, cpf }),
    enabled: !!appId && cpf.length === 14,
  });
}
