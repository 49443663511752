import { cn } from "@/lib/utils";
import { Field } from "@/services/wallets";

interface BackWalletProps {
  fields: Array<Field>;
  className?: string;
}

export function BackWallet({ fields, className }: BackWalletProps) {
  const fieldsFormatted = fields.filter(
    (item) => item.label !== "Nome" && item.label !== "CPF" && item.label !== "Data de nascimento",
  );

  const fieldCPF = fields.find((item) => item.label === "CPF");
  const fieldDate = fields.find((item) => item.label === "Data de nascimento");

  return (
    <div
      className={cn(
        "mx-auto flex h-[calc(((9.6cm)*9)/16)] w-[9.6cm] flex-col-reverse gap-2 overflow-hidden border-[3px] border-walletPrimary bg-walletPrimary bg-cover bg-center bg-no-repeat px-[0.384cm] py-[0.288cm]",
        className,
      )}
    >
      <div className="flex rotate-180 items-baseline justify-between gap-2 text-xs text-walletSecondary">
        {fieldCPF ? (
          <div>
            <span className="truncate">
              <strong className="font-semibold tracking-tight">{fieldCPF.label}: </strong>
              {fieldCPF.value}
            </span>
          </div>
        ) : null}
        {fieldDate ? (
          <div>
            <span className="truncate">
              <strong className="font-semibold tracking-tight">{fieldDate.label}: </strong>
              {fieldDate.value}
            </span>
          </div>
        ) : null}
      </div>

      <div className="rotate-180 space-y-1.5">
        {fieldsFormatted.slice(0, 4).map((item) => (
          <div key={item.id} className="truncate text-xs tracking-tight text-walletSecondary">
            <strong>{item.label}</strong>
            <span className="block">{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
