import dayjs from "dayjs";

import { IPlace } from "..";
import { IDataAssetsScheduling } from "../..";
import { IWorkSchedule } from "./getWorkSchedule";

export const startWithBloqued = (
  time: string,
  timeSelected: Array<string>,
  bookings: string[][] | undefined,
  place?: IPlace,
  workSchedule?: IWorkSchedule,
) => {
  const endInterval = dayjs(workSchedule?.hora_intervalo_fim?.split(" ")[1], "HH:mm").add(
    place?.step as number,
    "minute",
  );

  const startInterval = dayjs(workSchedule?.hora_intervalo_inicio?.split(" ")[1], "HH:mm").subtract(
    place?.step as number,
    "minute",
  );

  const intervalTime =
    startInterval &&
    endInterval &&
    (dayjs(time, "HH:mm").isBetween(startInterval, endInterval) ||
      (dayjs(timeSelected[0], "HH:mm").isBefore(
        startInterval.add(place?.step as number, "minute"),
      ) &&
        dayjs(time, "HH:mm").isAfter(startInterval)));

  const lastTime =
    workSchedule &&
    timeSelected.length === 0 &&
    dayjs(workSchedule.hora_fim.split(" ")[1], "HH:mm").format("HH:mm") === time;

  const startTimeBooking =
    timeSelected.length === 0
      ? bookings &&
        bookings.some((booking) =>
          dayjs(time, "HH:mm").isBetween(
            dayjs(booking[0], "HH:mm").subtract(
              (place?.minimo as number) + (place?.step as number),
              "minute",
            ),
            dayjs(booking[0], "HH:mm"),
          ),
        )
      : timeSelected.length === 1 &&
        bookings &&
        bookings.some(
          (booking) =>
            dayjs(time, "HH:mm").isAfter(dayjs(booking[0], "HH:mm")) &&
            dayjs(timeSelected[0], "HH:mm").isBefore(dayjs(booking[0], "HH:mm")),
        );

  return (
    (bookings &&
      !!bookings.find(
        (bookin) =>
          dayjs(time, "HH:mm").isBetween(
            dayjs(dayjs(bookin[0], "HH:mm").format("HH:mm"), "HH:mm"),
            dayjs(dayjs(bookin[1], "HH:mm").format("HH:mm"), "HH:mm"),
          ) ||
          time === dayjs(bookin[0], "HH:mm").format("HH:mm") ||
          time === dayjs(bookin[1], "HH:mm").format("HH:mm") ||
          startTimeBooking,
      )) ||
    intervalTime ||
    lastTime
  );
};

export const selectedWithBloqued = (
  time: string,
  timeSelected: string[],
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  dayjs(time, "HH:mm").isBefore(dayjs(timeSelected[0], "HH:mm")) ||
  dayjs(time, "HH:mm").diff(dayjs(timeSelected[0], "HH:mm"), "minute") >
    (dataAssetsScheduling?.extra_fields?.max as number);
