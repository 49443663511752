import logoMobbyImg from "@/assets/images/mobby_logo.svg";

export function Page404() {
  return (
    <main className="relative flex min-h-screen flex-col items-center justify-center bg-white py-8">
      <img src={logoMobbyImg} alt="" className="absolute top-8 mx-auto h-10" />

      <div className="mx-auto flex max-w-3xl flex-col items-center gap-4 px-6">
        <strong className="text-center text-md font-medium text-slate-900">
          A página que você está tentando acessar não foi encontrada. As possíveis razões para isso
          incluem:
        </strong>

        <ol className="space-y-3 text-sm text-slate-600">
          <li>1. URL incorreta: Verifique cuidadosamente se o endereço digitado está correto.</li>
          <li>
            2. Alterações na navegação: A estrutura do aplicativo pode ter sido modificada,
            resoltando na indisponibilidade dessa página.
          </li>
          <li>
            3. Erro de sistema: Pode ter ocorrido um problema técnico que impediu o carregamento da
            página.
          </li>
        </ol>
      </div>
    </main>
  );
}
