import { FilterByQuery } from "./FilterByQuery";
import { FilterByCategories } from "./FilterByCategories";

export function FilterBar() {
  return (
    <header className="flex items-center gap-1.5">
      <FilterByQuery />
      <FilterByCategories />
    </header>
  );
}
