import { Text } from "@astrolabe-ui/react";

import { getNameDayOfWeek } from "@/utils/getNameDayOfWeek";
import { cn } from "@/lib/utils";

export const DateButton = ({
  dayOfWeek,
  day,
  status = true,
  onClick,
  grow = false,
  flex1 = false,
  selected = false,
}: {
  dayOfWeek: number;
  day: number;
  status?: boolean;
  onClick?: () => void;
  grow?: boolean;
  flex1?: boolean;
  selected?: boolean;
}) => {
  return (
    <div
      className={cn(
        "flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md border-thin border-slate-200 px-4 py-3",
        {
          grow,
          "border-none bg-slate-100": !status,
          "flex-1": flex1,
          "animate-fadeIn bg-primary-500": selected,
        },
      )}
      {...(onClick && { onClick })}
    >
      <Text
        className="text-sm"
        color={selected ? "white" : status ? "slate-400" : "slate-300"}
        {...(selected && { weight: "semibold" })}
      >
        {getNameDayOfWeek({ position: dayOfWeek })}
      </Text>

      <Text color={selected ? "white" : status ? "slate-700" : "slate-300"} weight="semibold">
        {day}
      </Text>

      <div
        className={cn("rounded-full bg-green-500 p-1", {
          "bg-slate-300": !status,
          "bg-primary-500": selected,
        })}
      />
    </div>
  );
};
