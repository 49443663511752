import { http } from "@/lib/axios";
import { FaqQuestion } from "./types";
import { IconPhosphorProps } from "@/components/CustomIcon";

type getFaqSearchRequest = Array<{
  id: number;
  title: string;
  solution_mark: string;
  category: {
    id: number;
    title: string;
    icon: IconPhosphorProps;
  };
}>;

type getFaqSearchParams = {
  appId?: number;
  id: string;
  query: string;
};

export async function getFaqSearch({
  appId,
  id,
  query,
}: getFaqSearchParams): Promise<Array<FaqQuestion>> {
  try {
    const response = await http.get<getFaqSearchRequest>(`v3/${appId}/faq/${id}/filtered`, {
      params: {
        search: query,
      },
    });

    const items = response.data.map((item) => ({
      id: item.id,
      title: item.title.replaceAll(query, `<strong class="text-primary-500">${query}</strong>`),
      content: item.solution_mark.replaceAll(
        query,
        `<strong class="text-primary-500">${query}</strong>`,
      ),
      category: item.category,
    }));

    return items;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
