import toast from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";

import { Button, Modal } from "@/components";
import { useQueryClient } from "@tanstack/react-query";
import { useAppId } from "@/store/useAppStore";
import { useCloseToQueue } from "../hooks/use-close-to-queue";

type CloseToQueueModalProps = {
  isOpen: boolean;
  onClose: () => void;
  schedulingId: number;
};

export function CloseToQueueModal({ isOpen, onClose, schedulingId }: CloseToQueueModalProps) {
  const appId = useAppId();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useCloseToQueue();

  function handleConfirm() {
    mutateAsync(
      {
        appId: Number(appId),
        id: schedulingId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["scheduling", String(schedulingId), appId] });

          queryClient.invalidateQueries({ queryKey: ["my-schedulings", appId] });

          toast.success("Você saiu da fila com sucesso");

          handleCloseModal();
        },
        onError: () => toast.error("Não foi possível sair"),
      },
    );
  }

  function handleCloseModal() {
    onClose();
  }

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className="flex w-full flex-col gap-6">
        <div className="flex flex-col gap-2 text-center">
          <Text size="md" weight="medium" color="slate-700">
            Tem certeza que deseja sair da fila?
          </Text>
          <Text size="xs" color="slate-700">
            Ao sair da fila, você perderá sua colocação e caso deseje retornar, irá precisar criar
            uma nova solicitação.
          </Text>
        </div>

        <div className="flex items-center gap-3">
          <Button size="lg" full rounded="full" onClick={handleConfirm} loading={isLoading}>
            Sim
          </Button>
          <Button size="lg" full rounded="full" variant="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </div>
      </div>
    </Modal>
  );
}
