import { createContext, useContext } from "react";
import { ResponseInstitution } from "@/services/presentialScheduling";

type InstitutionContextData = {
  institution?: ResponseInstitution;
};

export const InstitutionContext = createContext<InstitutionContextData>({
  institution: undefined,
});

export const useInstitutionContext = () => useContext(InstitutionContext);
