import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ArrowDown, X } from "@phosphor-icons/react";
import { Box, TextInput as TextInputComponent, Text, cn } from "@astrolabe-ui/react";

import { UpdateHolder } from "..";
import { Holder } from "@/services/holder/types";

export const TextInput = ({
  name,
  placeholder,
  holderField,
  data,
  handleUserViewedField,
}: {
  name: "email" | "socialName" | "telefone";
  holderField: "email" | "nome_social" | "telefone";
  placeholder: string;
  data: Holder;
  handleUserViewedField: (id: number) => void;
}) => {
  const { register, setValue } = useFormContext<UpdateHolder>();

  const { t } = useTranslation();

  const review = data.reviews.find((field) => field.field === holderField);

  const onReview = review?.status === "Em Revisão";

  const rejected = review?.status === "Rejeitado";

  return data.reviews.some((field) => field.field === holderField) ? (
    <div className="flex flex-col gap-1">
      <Box
        className={cn("relative px-4 py-6", {
          "border-orange-600/10 bg-orange-600/10": onReview,
          "border-red-500/10 bg-red-500/10": rejected,
        })}
      >
        <Text size="xs" className="absolute top-[5px]">
          {placeholder}
        </Text>
        <div className="flex flex-col items-center gap-1">
          <Text
            className={cn({
              "text-orange-600/70": onReview,
              "text-red-500": rejected,
            })}
          >
            {review?.old_value ?? t(`holder.Sem ${review?.field}`)}
          </Text>
          <ArrowDown
            weight="bold"
            size={20}
            className={cn({
              "animate-pulse text-orange-600": onReview,
              "text-red-500": rejected,
            })}
          />
          <Text
            weight="semibold"
            className={cn({
              "text-orange-600": onReview,
              "text-red-500 line-through": rejected,
            })}
          >
            {review?.new_value}
          </Text>
        </div>
      </Box>
      {rejected && !review.user_viewed && (
        <Box className="flex items-center justify-between border-red-500/10 bg-red-500/10 p-2">
          <Text color="red-500" className="whitespace-pre-line">
            {review.justification
              ? `${review.justification.replaceAll("; ", "; \n")}`
              : `O novo ${t(`holder.${review.field}`)} rejeitado`}
          </Text>

          <button
            type="button"
            className="flex h-6 w-6 items-center justify-center bg-transparent"
            onClick={() => handleUserViewedField(review.id)}
          >
            <X size={12} weight="bold" className="flex-shrink-0 text-slate-700" />
          </button>
        </Box>
      )}
    </div>
  ) : name === "telefone" ? (
    <TextInputComponent
      placeholder={placeholder}
      floating
      maxLength={17}
      {...register(name, {
        value: data.telefone,
        onChange: (e) => setValue(name, e.target.value),
      })}
    />
  ) : (
    <TextInputComponent
      placeholder={placeholder}
      floating
      {...register(name, {
        value: data[holderField],
      })}
    />
  );
};
