import { useState } from "react";
import { useAtom } from "jotai";
import { FadersHorizontal } from "@phosphor-icons/react";

import { IconButton } from "@/components/IconButton";
import { FilterCategoryModal } from "./FilterCategoryModal";
import { NewsCategory } from "@/services/news";
import { filterByCategoriesAtom } from "../atoms/filterAtom";
import { useNewsCategories } from "../hooks/useNewsCategories";
import { useAppStore } from "@/store/useAppStore";

export function FilterByCategories() {
  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [showModal, setShowModal] = useState(false);
  const [categoriesFiltered, setCategoriesFiltered] = useAtom(filterByCategoriesAtom);

  const { data: categories } = useNewsCategories(appId);

  function handleSelectCategoriesFiltered(categories: Array<NewsCategory>) {
    setCategoriesFiltered(categories);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <IconButton
        icon={<FadersHorizontal className="text-slate-700" />}
        size="lg"
        variant="tertiary"
        rounded="full"
        onClick={() => setShowModal(true)}
      />

      {categories ? (
        <FilterCategoryModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          categories={categories}
          categoriesFiltered={categoriesFiltered}
          handleSelectCategories={handleSelectCategoriesFiltered}
        />
      ) : null}
    </>
  );
}
