import React, { useEffect, useRef, useState, forwardRef } from "react";
import { TextInput, Text } from "@astrolabe-ui/react";
import { cn } from "@/lib/utils";

interface ISelectText {
  options: Array<{ label: string; value?: string }>;
  floating?: true | undefined;
  getValue?: (value: string) => void;
  getLabel?: (label: string) => void;
  emptyMessage?: string;
  show?: number | 5;
  placeholder?: string;
  defaultValue?: { label: string; value: string };
  value?: string;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean;
  length?: number;
  name?: string;
}

export const SelectText: React.ForwardRefExoticComponent<
  ISelectText & React.RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, ISelectText>(
  (
    {
      options,
      emptyMessage = "Nenhum resultado encontrado",
      getValue = (value = "getValueProp") => {
        console.log("getValue select component = ", value);
      },
      getLabel = (label = "getLabelProp") => {
        console.log("getLabel select component = ", label);
      },
      defaultValue,
      show = 5,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const [showOptions, setShowOptions] = useState(false);
    const [label, setLabel] = useState(defaultValue ? defaultValue.label : "");

    const selectRef = useRef(null);

    const [optionsFilter, setOptionsFilter] = useState<Array<{ label: string; value?: string }>>(
      [],
    );

    const handleSelectOption = (value: string, label: string) => {
      setLabel(label);
      getValue && getValue(value);
      getLabel && getLabel(label);
      setShowOptions(false);
    };

    const handleFilterOptions = (value: string) => {
      const filteredOptions = options
        .filter((opt) =>
          opt.label
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toLowerCase()
            .includes(
              value
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .toLowerCase(),
            ),
        )
        .filter((_, index) => index <= show);
      setOptionsFilter(filteredOptions);
      getValue && getValue(value);
      setLabel(value);
    };

    useEffect(() => {
      setOptionsFilter(
        options.length > 5 ? options.filter((_, index) => index <= (rest.length || 4)) : options,
      );
    }, [options, rest.length]);

    return (
      <div className="relative" ref={ref}>
        <TextInput
          onFocus={(e) => {
            e.target.value !== "" &&
              setOptionsFilter(options.filter((opt) => opt.label === e.target.value));
            setShowOptions(true);
          }}
          ref={selectRef}
          onMouseOut={(
            e: React.MouseEvent<HTMLInputElement, MouseEvent> & { target: { value: string } },
          ) => {
            if (e.target.value !== "") {
              setLabel(
                optionsFilter[0] && optionsFilter[0].label !== emptyMessage
                  ? optionsFilter[0].label
                  : e.target.value,
              );
              getValue &&
                getValue(
                  optionsFilter[0] && optionsFilter[0].value
                    ? optionsFilter[0].value
                    : e.target.value,
                );
            } else getValue && getValue("");
            setShowOptions(false);
          }}
          className="focus-within:rounded-b-[0px]"
          onChange={(e) => {
            handleFilterOptions(e.target.value);
            onChange && onChange(e);
          }}
          value={label || rest.value}
          {...rest}
        />
        <div
          className={cn(
            "border-2 absolute left-0 top-[54px] z-10 w-full rounded-b-md border-thin border-t-[0px] border-primary-500 bg-white p-2",
            { hidden: !showOptions },
          )}
        >
          {showOptions && optionsFilter.length > 0 ? (
            optionsFilter.map((option, index) => (
              <div
                key={index}
                className="p-1 hover:bg-slate-300 hover:text-white"
                onClick={() => option.value && handleSelectOption(option.value, option.label)}
              >
                <Text>{option.label}</Text>
              </div>
            ))
          ) : (
            <div className="p-1 hover:bg-slate-300 hover:text-white">
              <Text>{emptyMessage}</Text>
            </div>
          )}
        </div>
      </div>
    );
  },
);

SelectText.displayName = "SelectText";
