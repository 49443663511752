import { useState, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Geolocation } from "@capacitor/geolocation";
import { IonToggle, IonIcon, useIonToast } from "@ionic/react";
import { closeOutline, documentOutline, imageOutline } from "ionicons/icons";

import {
  Container,
  ContentFields,
  TextError,
  ConetentField,
  Label,
  Input,
  TextArea,
  ContentPlate,
  ContentToggle,
  ContentMessageSubCategory,
  LabelFile,
  TextFile,
  ContentLabel,
  ImagePreview,
  Image,
  ContentLabelTitle,
  IconRemoveFile,
  FilePreview,
  IconFilePreview,
} from "./styles";

import { GetLocationProtocol } from "../../GetLocationProtocol";
import { UploadImageProtocol } from "../../UploadImageProtocol";
import { isLogged } from "@/services/old/auth";
import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";
import { isValidSizeInMB } from "@/utils/isValidSizeInMB";

interface FormProtocolProps {
  handleBack: () => void;
  name: "form";
  category: any;
  subcategorySelected: any;
  fields?: any;
  disabledNext: boolean;
}

export function FormProtocol({
  handleBack,
  category,
  subcategorySelected,
  fields,
  disabledNext,
}: FormProtocolProps) {
  const {
    control,
    getValues,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const CONTENT = getContent();

  const [token] = useState(!!localStorage.getItem(STORAGE.AUTH_ACCESS_TOKEN.KEY));

  const [plate, setPlate] = useState("");
  const [plate2, setPlate2] = useState("");
  const [hideUser, setHideUser] = useState(false);

  const [present] = useIonToast();

  useEffect(() => {
    if (category.abrir_anonimo && !isLogged()) {
      setHideUser(true);
    }

    Geolocation.getCurrentPosition()
      .then((res) => {
        setValue("latitude", res.coords.latitude + "");
        setValue("longitude", res.coords.longitude + "");
      })
      .catch((err) => {
        console.log("Error getting location", err);
      });
  }, []);

  function imageChangeHandle(data: any) {
    setValue("imagem", data.file);
    setValue("imagemValue", data.base64);
  }

  function locationChangeHandle(data: any) {
    setValue("endereco", data.address);
    setValue("latitude", data.lat);
    setValue("longitude", data.lng);

    if (category.usarEndereco && data.address.length > 0) {
      setValue("endereco", data.address.replace(/\s\s+/g, " "));
    }
  }

  function changePlate(value: string) {
    setValue("placaVeiculo", value + plate2);
  }

  function changePlate2(value: string) {
    setValue("placaVeiculo", plate + value);
  }

  async function isValidFile(file: File, type: string) {
    const validSize = isValidSizeInMB(file.size);
    let validFormat = true;

    if (type === "imagem") {
      validFormat = isValidFormat(file.type);
    }

    if (!validSize || !validFormat) {
      let message = "";

      if (!validSize && !validFormat) {
        message = "Tamanho e formato de arquivo inválido";
      } else if (!validSize) {
        message = "Tamanho de arquivo inválido (Max.: 9MB)";
      } else {
        message = "Formato de arquivo inválido";
      }

      await showToast({
        message,
        position: "bottom",
      });

      return false;
    }

    return true;
  }

  function isValidFormat(format: string) {
    const allowedExtensions = /(jpg|jpeg|png|gif)$/i;
    return allowedExtensions.test(format);
  }

  async function showToast({
    message,
    position,
  }: {
    message: string;
    position: "top" | "middle" | "bottom";
  }) {
    await present({
      message,
      duration: 2000,
      position,
    });
  }

  const imageSelected = useWatch({
    control,
    name: "imagemValue",
  });

  return (
    <Container>
      <ContentFields>
        {category.abrir_anonimo && !subcategorySelected?.link_externo && token && (
          <ContentToggle>
            <Label>{CONTENT.PROTOCOLS.WITHOUT_IDENTIFICATION}</Label>
            <IonToggle
              onIonChange={() => {
                setHideUser(!hideUser);
                setValue("hideUser", !hideUser);
              }}
              checked={hideUser}
            />
          </ContentToggle>
        )}

        {subcategorySelected.mensagem && (
          <ContentMessageSubCategory>{subcategorySelected.mensagem}</ContentMessageSubCategory>
        )}

        {category.abrir_anonimo && !subcategorySelected.link_externo && hideUser && !token && (
          <div>
            <Input
              placeholder={CONTENT.PROTOCOLS.NAME}
              error={!!errors.nome?.message}
              {...register("nome", {
                required: {
                  value: true,
                  message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
                },
              })}
            />
            {errors.nome && <TextError>{`${errors.nome?.message}`}</TextError>}
          </div>
        )}

        {!subcategorySelected.link_externo && (
          <ContentFields>
            {category.usarEndereco && (
              <ConetentField id="geo-location">
                <GetLocationProtocol
                  onLocationChange={locationChangeHandle}
                  selectedSubCategory={subcategorySelected}
                  endereco={getValues("endereco")}
                  latitude={Number(getValues("latitude"))}
                  longitude={Number(getValues("longitude"))}
                />

                {errors.endereco && (!getValues("latitude") || !getValues("longitude")) && (
                  <TextError>{`${errors.endereco?.message}`}</TextError>
                )}
              </ConetentField>
            )}

            <ConetentField>
              <UploadImageProtocol
                onChange={imageChangeHandle}
                title={CONTENT.PROTOCOLS.IMAGE}
                imageValue={imageSelected}
              />
            </ConetentField>

            <ConetentField>
              <Label>{"Mensagem *"}</Label>
              <TextArea
                placeholder={"Digite sua mensagem"}
                error={!!errors.mensagem?.message}
                {...register("mensagem", {
                  minLength: {
                    value: 3,
                    message: "Você deve inserir pelo menos 3 caracteres.",
                  },
                  required: {
                    value: true,
                    message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
                  },
                })}
              ></TextArea>
              {errors.mensagem && <TextError>{`${errors.mensagem?.message}`}</TextError>}
            </ConetentField>

            {category.usarIdentificador && (
              <ConetentField>
                <Label>
                  {category.nomeIdentificador
                    ? category.nomeIdentificador
                    : CONTENT.PROTOCOLS.INDENTIFIER}
                </Label>
                <TextArea
                  placeholder={"Informe o identificador"}
                  {...register("identificador")}
                ></TextArea>
              </ConetentField>
            )}

            {category.usarEndereco && false && (
              <ConetentField>
                <Label>{CONTENT.PROTOCOLS.ADDRESS}</Label>
                <TextArea
                  placeholder={CONTENT.PROTOCOLS.ADDRESS_PLACEHOLDER}
                  {...register("endereco")}
                ></TextArea>
              </ConetentField>
            )}

            {category.usarPlaca && (
              <ConetentField>
                <ContentPlate>
                  <Label>Placa:</Label>
                  <Input
                    placeholder="XXX"
                    maxLength={3}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length < 4)
                        setPlate(e.target.value ? e.target.value : "");
                      changePlate(e.target.value);
                    }}
                    style={{ textAlign: "end" }}
                  />
                  <Label>-</Label>
                  <Input
                    placeholder="XXX"
                    maxLength={4}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length < 5)
                        setPlate2(e.target.value ? e.target.value : "");
                      changePlate2(e.target.value);
                    }}
                  />
                </ContentPlate>

                {category.usarPlaca &&
                  ((plate.length > 0 && plate.length !== 3) ||
                    (plate2.length > 0 && plate2.length !== 4)) && (
                    <TextError>A placa informada é inválida.</TextError>
                  )}
              </ConetentField>
            )}

            {fields &&
              // eslint-disable-next-line array-callback-return
              fields.map((item: any) => {
                if (item.type === "string" || item.type === "integer" || item.type === "data") {
                  return (
                    <ConetentField key={item.id}>
                      <Label>{item.required ? item.title + " *" : item.title}</Label>
                      <Controller
                        name={`campos.${item.id}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: {
                            value: item.required,
                            message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            type={
                              item.type === "string"
                                ? "text"
                                : item.type === "integer"
                                ? "number"
                                : "date"
                            }
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        )}
                      />
                      {/* @ts-ignore */}
                      {errors.campos?.[item.id] && (
                        /* @ts-ignore */
                        <TextError>{`${errors.campos?.[item.id]?.message}`}</TextError>
                      )}
                    </ConetentField>
                  );
                }

                if (item.type === "imagem" || item.type === "arquivo") {
                  watch("campos");

                  const fileValue = getValues("campos")?.[item.id];
                  let typeFile = null;
                  let isImage = null;

                  if (fileValue && fileValue.file.type) {
                    typeFile = fileValue.file.type.split("/");
                    isImage = typeFile[0] === "image";
                  }

                  return (
                    <ConetentField key={item.id}>
                      <Label>{item.required ? item.title + " *" : item.title}</Label>

                      {fileValue && (
                        <IconRemoveFile
                          icon={closeOutline}
                          onClick={() => {
                            setValue(`campos.${item.id}`, "");
                          }}
                        />
                      )}

                      {/* @ts-ignore */}
                      <ContentLabel error={!!errors?.campos?.[item.id]?.message}>
                        <LabelFile htmlFor={"file_" + item.id}>
                          <ContentLabelTitle>
                            <TextFile>Clique para buscar arquivo</TextFile>
                            <IonIcon
                              icon={item.type === "imagem" ? imageOutline : documentOutline}
                              size="small"
                              slot="end"
                              style={{ color: "#475569" }}
                            />
                          </ContentLabelTitle>

                          {fileValue && (
                            <>
                              {isImage && (
                                <ImagePreview>
                                  <Image src={fileValue.path} alt="" />
                                </ImagePreview>
                              )}
                              {!isImage && (
                                <FilePreview>
                                  <IconFilePreview icon={documentOutline} />
                                  <small>{fileValue.file.name}</small>
                                </FilePreview>
                              )}
                            </>
                          )}
                        </LabelFile>
                      </ContentLabel>

                      <Controller
                        control={control}
                        name={`campos.${item.id}`}
                        defaultValue=""
                        rules={{
                          required: {
                            value: item.required,
                            message: CONTENT.PROTOCOLS.FIELD_REQUIRED,
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <Input
                            type="file"
                            id={"file_" + item.id}
                            value={value?.file?.fileName ?? ""}
                            {...(item.type === "imagem" && { accept: "image/*" })}
                            onChange={async (e) => {
                              if (e.target.files?.length) {
                                const file = e.target.files[0];

                                const isValid = await isValidFile(file, item.type);

                                if (isValid) {
                                  onChange({
                                    path: URL.createObjectURL(file),
                                    file,
                                  });
                                } else {
                                  onChange("");
                                }
                              }
                            }}
                            hidden
                          />
                        )}
                      />
                      {/* @ts-ignore */}
                      {errors.campos?.[item.id] && (
                        /* @ts-ignore */
                        <TextError>{`${errors.campos?.[item.id]?.message}`}</TextError>
                      )}
                    </ConetentField>
                  );
                }
              })}
          </ContentFields>
        )}
      </ContentFields>
      <footer>
        <button type="button" onClick={handleBack} className="outline-btn">
          {CONTENT.VINCULAR_TITULAR.RETURN_STEP}
        </button>
        <button disabled={disabledNext}>{CONTENT.VINCULAR_TITULAR.ADVANCE}</button>
      </footer>
    </Container>
  );
}
