import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";

export const getDefaultTimeSelected = (dataAssetsScheduling?: IDataAssetsScheduling) =>
  dataAssetsScheduling?.hora_inicio && dataAssetsScheduling?.hora_fim
    ? [
        dayjs(dataAssetsScheduling?.hora_inicio).format("HH:mm"),
        dayjs(dataAssetsScheduling?.hora_fim).format("HH:mm"),
      ]
    : [];
