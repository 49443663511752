import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem 1rem max(env(safe-area-inset-bottom), 2rem);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  img {
    height: 10rem;
    object-fit: contain;

    @media (min-width: 1024px) {
      height: 24vh;
    }
  }
`;

export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 1024px) {
    gap: 0.875rem;
  }

  strong {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
    color: #334155;

    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 160%;
    color: #94a3b8;

    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }
`;

export const Faq = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;

  strong {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
    color: #334155;

    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  padding: 1rem;
  border-radius: 0.75rem;
  background-color: #f8fafc;

  strong {
    font-size: 0.875rem;
    font-weight: 600;
    color: #0b49b7;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding-inline-start: 1rem;

  text-align: left;

  li {
    font-size: 0.875rem;
    color: #334155;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;

  h2 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #0b49b7;
  }
`;
