import { useQuery } from "@tanstack/react-query";

import { getInstitution } from "@/services/assetsScheduling";

interface useInstitutionParams {
  appId: number;
  id: number;
  staleTime?: number;
}

export function useInstitution({ appId, id, staleTime }: useInstitutionParams) {
  return useQuery({
    queryKey: ["assets-scheduling-institution", appId],
    queryFn: () => getInstitution({ appId, id }),
    enabled: !!appId,
    ...(staleTime && { staleTime }),
  });
}
