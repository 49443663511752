import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { IonHeader, IonRippleEffect, IonToolbar } from "@ionic/react";
import styled from "styled-components";

import { COLORS } from "@/data/colors";

interface HeaderProps {
  children: ReactNode;
  variant?: string;
}

export function Header({ children, variant }: HeaderProps) {
  return (
    <IonHeader className={variant === "secondary" ? "ion-no-border" : ""}>
      <Toolbar variant={variant} className="ion-no-padding">
        <HeaderContent variant={variant}>{children}</HeaderContent>
      </Toolbar>
    </IonHeader>
  );
}

interface ElementProps {
  variant?: string;
}

const HeaderContent = styled.div<ElementProps>`
  background-color: ${(props) => (props.variant === "secondary" ? COLORS.PRIMARY : "#ffffff")};
  width: 100%;
  height: 100%;
  padding: 0.25rem 0.875rem;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    flex: 1;
  }

  div {
    flex: 1;
  }

  img {
    height: 2.5rem;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => (props.variant === "secondary" ? "#ffffff" : COLORS.PRIMARY)};
  }
`;

const Toolbar = styled(IonToolbar)<ElementProps>`
  --background: ${(props) => (props.variant === "secondary" ? COLORS.PRIMARY : "#ffffff")};
`;

interface HeaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
  icon: JSX.Element;
  handleOnClick?: () => void;
}

export const Button = styled.button`
  background-color: transparent;
  height: 2rem;
  width: 2rem;
  border: 1px solid transparent;
  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.4;
  }

  svg {
    flex-shrink: 0;
  }
`;

export function HeaderButton({
  variant = "primary",
  icon,
  handleOnClick,
  ...props
}: HeaderButtonProps) {
  return (
    <Button {...props} className="ion-activatable ripple-parent" onClick={handleOnClick}>
      {React.cloneElement(icon, {
        size: 20,
        color: variant === "secondary" ? "#ffffff" : COLORS.PRIMARY,
      })}
      <IonRippleEffect style={{ color: "#94A3B8" }} />
    </Button>
  );
}
