import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Text, Button } from "@astrolabe-ui/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { useAppSlug } from "@/store/useAppStore";
import passwordSucess from "@/assets/illustrations/password-sucess.svg";
import { Header, HeaderButton } from "@/components/index";
import { CaretLeft } from "@phosphor-icons/react";

export function RecoverPasswordSuccess() {
  const { t } = useTranslation();
  const history = useHistory();

  const slug = useAppSlug();

  function handleNavigate() {
    history.replace(`/${slug}/auth/login`);
  }

  return (
    <Layout>
      <Content>
        <div className="flex min-h-full w-full flex-col items-center gap-24 bg-white px-4 py-6 ios:pb-2">
          <Header variant="secondary">
            <div className="flex w-full items-center after:flex-1 after:content-['']">
              <div className="flex-1">
                <HeaderButton
                  variant="secondary"
                  icon={<CaretLeft weight="bold" />}
                  onClick={handleNavigate}
                />
              </div>

              <Text size="md" weight="medium" leading="relaxed" color="primary-500">
                {t("auth.Redefinição de senha")}
              </Text>
            </div>
          </Header>

          <div className="flex flex-col items-center gap-8">
            <img src={passwordSucess} alt="Blocos com ícones de check." className="max-w-[80%]" />
            <Text asChild size="md" weight="medium" color="slate-700">
              <strong>{t("auth.Sua senha foi redefinida!")}</strong>
            </Text>

            <Text className="text-center">
              {t("auth.Retorne a página de acesso para realizar o login.")}
            </Text>
          </div>

          <Button
            variant="primary"
            size="lg"
            rounded="md"
            full
            className="mt-auto"
            onClick={handleNavigate}
          >
            {t("auth.Acessar minha conta")}
          </Button>
        </div>
      </Content>
    </Layout>
  );
}
