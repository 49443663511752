import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { IonRouterLink } from "@ionic/react";

import { Block } from "@/components/old/Block";
import { BlockMapWithMarkers } from "@/pages/old/Infos/components/BlockMapWithMarkers";
import { getInfoCategoryById } from "@/services/old/infos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

const Content = styled(IonRouterLink)`
  cursor: pointer;
  box-shadow: inset 0px -30px 50px 0px rgb(0 0 0 / 65%);
`;

type BlockInfoMapProps = {
  id: number;
  title?: string;
  routerLink?: string;
  SLUG: string;
};

export function BlockInfoMap(props: BlockInfoMapProps) {
  const CONTENT = getContent();

  const { isLoading, data } = useQuery(
    ["getInfoCategoryItem", props.id],
    ({ pageParam = 1 }) =>
      getInfoCategoryById(
        {
          page: pageParam,
          category: props.id + "",
        },
        props.SLUG,
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.data.next_page_url + "").split("page=")[1];
      },
    },
  );

  if (isLoading) {
    return <Block title={CONTENT.GLOBAL.LOADING}>{}</Block>;
  }

  return (
    <Block noPadding>
      <Content routerLink={"/" + getAppSlug() + "/info-category-abrir/" + props.id}>
        <BlockMapWithMarkers
          animated={true}
          hideDetail={true}
          hideUserControl={true}
          hideNavegationButton={true}
          overlayTitle={props.title}
          customMapHeigh={"250px"}
          // eslint-disable-next-line array-callback-return
          markers={data?.data.data.map((item: any) => {
            if (item.latitude && item.longitude) {
              return {
                id: item.id,
                lat: item.latitude,
                lng: item.longitude,
                img: item.imagem,
                label: item.titulo,
                icon: item.icon,
              };
            }
          })}
        />
      </Content>
    </Block>
  );
}
