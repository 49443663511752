import {
  ButtonReset,
  ButtonSearch,
  Content,
  ContentButton,
  ContentInputButton,
  Icon,
  IconClose,
  Input,
  Title,
} from "./styles";
import { search, closeOutline } from "ionicons/icons";

interface BlockFormSearchServicesProps {
  id: string;
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (id: string) => Promise<void>;
  onReset: () => void;
}

export function BlockFormSearchServices(props: BlockFormSearchServicesProps) {
  return (
    <Content>
      <Title>{props.title}</Title>
      <ContentInputButton>
        <Input
          type="number"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSubmit(props.id);
            }
          }}
        />
        <ContentButton>
          <ButtonSearch color="danger" onClick={() => props.onSubmit(props.id)}>
            <Icon icon={search} />
          </ButtonSearch>
          <ButtonReset color="danger" onClick={props.onReset}>
            <IconClose icon={closeOutline} />
          </ButtonReset>
        </ContentButton>
      </ContentInputButton>
    </Content>
  );
}
