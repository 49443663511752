export function formatCpf(cpf: string) {
  return cpf
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function unformatCpf(cpf: string) {
  return cpf.replaceAll(".", "").replaceAll("-", "");
}

export function formatCep(cpf: string) {
  return cpf
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

export function formatPhone(phone: string) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{2})(\d{1})/, "($1) $2")
    .replace(/\((\d{2})\)(\d{4,5})/, "($1) $2")
    .replace(/\((\d{2})\)\s(\d{4,5})(\d{4})$/, "($1) $2 - $3");
}

export function formatDate(date: string) {
  if (!date) return "";
  const dateScaped = date.replaceAll(/[^0-9]/g, "");

  const dayValidRegex = [
    /^([0-3]|0[1-9]|[1-2][0-9]|3[0-1])$/,
    /^([0-3]|0[1-9]|[1-2][0-9]|3[0-1])[0-1]$/,
    /^(0[1-9]|[1-2][0-9]|3[0-1])(0[1-9]|1[0-2])$/,
    /^(0[1-9]|[1-2][0-9]|3[0-1])(0[1-9]|1[0-2])\d{1,4}$/,
  ];

  if (dayValidRegex.some((r) => dateScaped.match(r)))
    return (dateScaped.length === 1 ? dateScaped : dateScaped.padStart(2, "0"))
      .replace(/(\d{2})(\d{1,2})/, "$1/$2")
      .replace(/(\d{2}\/\d{2})(\d{1,4})/, "$1/$2");
  return "";
}

export function formatDescriptiveDate(date: string) {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date.replace(/-/g, "/")));
}

export function removeCharacteres(value: string) {
  return value.replace(/[^0-9]/g, "");
}

export function removeTagHTML(value: string) {
  return value.replaceAll(/<\/?[\w\s]*>|<.+[\W]>/g, "");
}

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
