import { IonIcon } from "@ionic/react";
import styled from "styled-components";

export const TextProtocol = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 20px;
  color: #334155;
`;

export const Icon = styled(IonIcon)`
  font-size: 18px;
  color: #94a3b8;
  margin-right: 4px;
`;
