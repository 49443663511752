import { Fragment } from "react";
import { IonContent, IonSkeletonText, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, Ticket } from "@phosphor-icons/react";

import illustrationEmpty from "@/assets/illustrations/empty.svg";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton, InfiniteScroll } from "@/components";
import { CardEvents } from "./components/card-events";
import { FilterByQuery } from "./components/filter-by-query";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useEvents } from "./hooks/use-get-events";

export function Events() {
  const { t } = useTranslation();
  const history = useIonRouter();

  const appId = useAppId();
  const slug = useAppSlug();

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useEvents({ appId });

  async function handleFetchNextPage() {
    await fetchNextPage();
  }

  function handleOpenEvent(id: number) {
    history.push(`/${slug}/evento/${id}`);
  }

  function handleGoBack() {
    history.push(`/${slug}/home`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text size="lg" weight="medium" leading="relaxed" className="text-textHeader">
            {t("modules.Eventos")}
          </Text>
        </div>
      </Header>

      <IonContent>
        <Content>
          <div className="mx-auto flex min-h-full w-full max-w-2xl flex-col gap-4 px-4 py-6 ios:pb-2">
            <FilterByQuery />

            {isLoading ? <ContentLoading /> : null}

            {!isLoading && isError ? (
              <Text size="md" weight="medium" className="pt-6 text-center">
                {t("events.Ocorreu um erro ao carregar os eventos")}
              </Text>
            ) : null}

            {!data?.pages[0].data.length && !isLoading ? <EmptyListEvent /> : null}

            {data?.pages[0].data.length ? (
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-start gap-1">
                  <Ticket size={20} className="text-slate-500" />
                  <Text size="md" weight="normal" className="text-slate-700">
                    {data?.pages[0]?.data?.length || 0} {t("events.Evento(s)")}
                  </Text>
                </div>

                <InfiniteScroll hasNextPage={!!hasNextPage} fetchNextPage={handleFetchNextPage}>
                  {data?.pages.map((page, index) => (
                    <Fragment key={index}>
                      {page.data.map((events) => (
                        <CardEvents
                          key={events.id}
                          banner={events.imageUrl}
                          localization={events.localization}
                          title={events.title}
                          time={events.time}
                          handleOnClick={() => handleOpenEvent(events.id)}
                        />
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
              </div>
            ) : null}
          </div>
        </Content>
      </IonContent>
    </Layout>
  );
}

function EmptyListEvent() {
  const { t } = useTranslation();

  return (
    <div className="flex h-[50vh] w-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={illustrationEmpty} alt="" />
      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("events.Não encontramos eventos")}
        </Text>
        <Text>
          {t("events.Não foi possível encontrar eventos de acordo com os filtros informados")}
        </Text>
      </div>
    </div>
  );
}

function ContentLoading() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-start">
        <IonSkeletonText animated className="h-6 w-24" />
      </div>

      <div className="flex flex-col justify-between gap-4 ">
        <IonSkeletonText animated className="h-24 w-full rounded-md" />
        <IonSkeletonText animated className="h-24 w-full rounded-md" />
        <IonSkeletonText animated className="h-24 w-full rounded-md" />
        <IonSkeletonText animated className="h-24 w-full rounded-md" />
      </div>
    </div>
  );
}
