import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RadioGroup, RadioGroupItem, Text, TextInput } from "@astrolabe-ui/react";
import { MagnifyingGlass, X } from "@phosphor-icons/react";

import { IconButton } from "@/components";
import { useDebounce } from "@/hooks/useDebounce";
import { compare } from "@/utils/compareStrings";

export type Item = {
  value: string | number;
  label: string;
};

interface SelectItemProps {
  items: Array<Item>;
  selectedItem: string | null;
  title?: string;
  virtualized?: boolean;
  initialSearch?: string;
  onCancel: () => void;
  onChange: (item: string) => void;
}

type FormData = {
  search: string;
  selected: string;
};

export function SelectItem({
  items,
  selectedItem,
  title = "Selecionar item",
  virtualized = false,
  initialSearch,
  onCancel,
  onChange,
}: SelectItemProps) {
  const { t } = useTranslation();

  const [filteredItems, setFilteredItems] = useState<Array<Item>>(
    virtualized ? items.slice(0, 15) : [...items],
  );

  const { control, register } = useForm<FormData>({
    defaultValues: {
      search: initialSearch ?? "",
      selected: selectedItem ?? undefined,
    },
  });

  const search = useWatch({
    name: "search",
    control,
  });

  const searchDeffered = useDebounce(search, 700);

  useEffect(() => {
    if (!searchDeffered || searchDeffered.length < 2) {
      setFilteredItems(virtualized ? items.slice(0, 15) : [...items]);
      return;
    }

    setFilteredItems(items.filter((item) => compare(item.label, searchDeffered)));
  }, [items, virtualized, searchDeffered]);

  function handleOnChange(value: string) {
    onChange(value);
  }

  return (
    <form className="flex h-full flex-col">
      <header className="flex flex-col gap-4 bg-slate-100 px-4 pb-6 pt-[calc(var(--safe-area-inset-top)+1.5rem)]">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-start">
            <IconButton
              type="button"
              rounded="full"
              size="md"
              icon={<X className="text-slate-700" />}
              onClick={onCancel}
            />
          </div>

          <Text size="md" color="slate-700">
            {title}
          </Text>

          <div className="flex-1"></div>
        </div>

        <TextInput
          placeholder="Buscar..."
          icon={<MagnifyingGlass />}
          variant="secondary"
          {...register("search")}
        />
      </header>

      <div className="flex flex-1 flex-col gap-8 overflow-auto px-4 pb-[calc(var(--safe-area-inset-bottom)+1.5rem)] pt-6">
        <Controller
          control={control}
          name="selected"
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onValueChange={(value) => {
                onChange(value);
                handleOnChange(value);
              }}
            >
              {filteredItems.length ? (
                <>
                  {filteredItems.map((item) => (
                    <RadioGroupItem
                      key={item.value}
                      id={String(item.value)}
                      value={String(item.value)}
                    >
                      {item.label}
                    </RadioGroupItem>
                  ))}
                </>
              ) : (
                <Text className="text-center">
                  {t("select.Não foi possível encontrar nenhum resultado!")}
                </Text>
              )}
            </RadioGroup>
          )}
        />

        {virtualized && !searchDeffered ? (
          <Text className="text-center" color="slate-400">
            {t("select.Para mais opções, use o campo de busca acima informando o nome do item.")}
          </Text>
        ) : null}
      </div>
    </form>
  );
}
