import { useEffect } from "react";
import { useAtom } from "jotai";
import { TextInput } from "@astrolabe-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { formatCpf } from "@/utils/formats";
import { useSearchParams } from "@/hooks/useSearchParams";
import { filterSchedulingsAtom } from "../atoms/filter-schedulings-atom";

export function FilterQuery() {
  const params = useSearchParams();

  const [query, setQuery] = useAtom(filterSchedulingsAtom);

  useEffect(() => {
    const cpf = params.get("cpf");
    if (cpf) {
      setQuery(cpf);
    }
  }, [params, setQuery]);

  return (
    <TextInput
      icon={<MagnifyingGlass className="text-slate-700" />}
      placeholder="Buscar CPF"
      type="tel"
      inputMode="numeric"
      value={query}
      onChange={(e) => setQuery(formatCpf(e.target.value))}
    />
  );
}
