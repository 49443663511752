import { Button as ButtonLib, ButtonProps as ButtonLibProps } from "@astrolabe-ui/react";
import { IonRippleEffect } from "@ionic/react";
import { cn } from "@/lib/utils";

interface ButtonProps extends ButtonLibProps {
  loading?: boolean;
}

export function Button({ loading, className, ...props }: ButtonProps) {
  return (
    <ButtonLib
      className={cn(
        "ion-activatable ripple-parent leading-[1.25rem]",
        { "disabled:bg-slate-100 disabled:text-slate-400": !loading },
        className,
      )}
      {...props}
    >
      {loading ? <Dots variant={props.variant ?? "primary"} /> : props.children}

      {!props.disabled ? (
        <IonRippleEffect
          className={cn("text-primary-400", {
            "text-slate-200": props.variant === "tertiary" || props.variant === "secondary",
          })}
        />
      ) : null}
    </ButtonLib>
  );
}

interface DotsProps {
  variant: string;
}

function Dots({ variant }: DotsProps) {
  return (
    <span className="inline-flex items-center gap-px py-[0.438rem]">
      <span
        className={cn("mx-px h-1.5 w-1.5 animate-blink rounded-full bg-white", {
          "bg-primary-500": variant === "secondary" || variant === "tertiary",
        })}
      />

      <span
        className={cn("mx-px h-1.5 w-1.5 animate-blink rounded-full bg-white animation-delay-200", {
          "bg-primary-500": variant === "secondary" || variant === "tertiary",
        })}
      />

      <span
        className={cn(
          "mx-px h-1.5 w-1.5 animate-blink rounded-full bg-white animation-delay-[400ms]",
          {
            "bg-primary-500": variant === "secondary" || variant === "tertiary",
          },
        )}
      />
    </span>
  );
}
