import { useEffect, useState } from "react";
import {
  CalendarCheck,
  CaretLeft,
  CaretRight,
  Clock,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { Box, Switch, Text, TextInput } from "@astrolabe-ui/react";
import { cpf as cpfValidate } from "cpf-cnpj-validator";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, Loading } from "@/components";
import { ResponseBooking } from "@/services/presentialScheduling";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useUserStore } from "@/store/useUserStore";
import { formatCpf, unformatCpf } from "@/utils/formats";
import { useBookings } from "./hooks/useBookings";

import errorIllustration from "@/assets/illustrations/error.svg";
import emptyIllustration from "@/assets/illustrations/empty.svg";

dayjs.extend(timezone);

export const ListPresentialBooking = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId || !slug) {
    throw new Error("Not loaded app!");
  }

  const user = useUserStore((state) => state.user);

  const [cpf, setCpf] = useState<string | null | undefined>(user?.cpf);
  const { data, isLoading, isError } = useBookings(Number(appId), cpf);
  const [query, setQuery] = useState<string>("");
  const [myBookings, setMyBookings] = useState<boolean>(!!user);

  const [bookings, setBookings] = useState<Array<ResponseBooking> | undefined>(data);

  const handleCpfField = (cpfValue: string) => {
    setQuery(formatCpf(cpfValue));
    cpfValidate.isValid(cpfValue) ? setCpf(unformatCpf(cpfValue)) : setCpf("");
  };

  useEffect(() => {
    data && setBookings(data);
  }, [data]);

  const handleNavigate = () => history.goBack();

  function handleNavigateToBooking(id: number, schedulingId: number) {
    history.replace(`/${slug}/presential-scheduling/${id}?agendamento_id=${schedulingId}`);
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text
            size="md"
            weight="medium"
            color="primary-500"
            leading="relaxed"
            className="truncate"
          >
            {t("booking.Agendamento presencial")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className=" flex min-h-full w-full flex-col gap-5 px-4 pb-6 pt-4 ios:pb-2">
          <header className="mx-auto flex max-w-3xl flex-col gap-4">
            <div className="flex w-full gap-1.5">
              <TextInput
                icon={<MagnifyingGlass className="text-slate-700" />}
                placeholder={t("booking.Informe um CPF válido")}
                value={query}
                inputMode="numeric"
                onChange={(e) => handleCpfField(e.target.value)}
                {...(query &&
                  query.length === 14 &&
                  !cpfValidate.isValid(cpf as string) && { error: t("booking.CPF inválido") })}
                disabled={myBookings}
              />
            </div>
            {user?.cpf && (
              <div
                className="flex gap-2"
                onClick={() => {
                  if (!myBookings) {
                    setCpf(user.cpf);
                    setQuery("");
                  } else {
                    setCpf(undefined);
                  }
                  setMyBookings((prevState) => !prevState);
                }}
              >
                <Switch checked={myBookings} />
                <Text>Meus agendamentos</Text>
              </div>
            )}
          </header>
          {!cpf ? (
            <WithoutCpf />
          ) : isLoading ? (
            <Loading />
          ) : data && data?.length <= 0 ? (
            <Empty />
          ) : isError ? (
            <ErrorComponent />
          ) : (
            bookings &&
            bookings.map((booking) => (
              <Box
                key={booking.id}
                variant="primary"
                className="flex flex-col gap-2 p-3"
                onClick={() => handleNavigateToBooking(booking.id, booking.agendamento.id)}
              >
                <div className="flex justify-between">
                  <Text color="slate-700" weight="medium">
                    {booking.agendamento.servicos.nome}
                  </Text>

                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-50">
                    <CaretRight size={12} weight="bold" className="text-primary-500" />
                  </div>
                </div>

                <Text>{booking.agendamento.servicos.setor.nome}</Text>

                <div className="flex items-center gap-1">
                  <CalendarCheck className="text-[18px] text-primary-500" />
                  <Text color="slate-600" className="mr-2 leading-[100%]">
                    {dayjs(booking.agendamento.data_inicio_atendimento).format("DD MMM YYYY")}
                  </Text>

                  <Clock className="text-[18px] text-primary-500" />
                  <Text color="slate-600" className="mr-2 leading-[100%]">
                    {dayjs.tz(booking.agendamento.vagas_hora[0]?.datetime).format("HH:mm")}
                  </Text>
                </div>
              </Box>
            ))
          )}
        </div>
      </Content>
    </Layout>
  );
};

const ErrorComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={errorIllustration} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Desculpe, algo deu errado")}...
        </Text>
        <Text>{t("booking.O nosso time de desenvolvedores esta resolvendo isso")}</Text>
      </div>
    </div>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={emptyIllustration} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Nenhuma reserva encontrada")}...
        </Text>
        <Text weight="medium" color="slate-700">
          {t("booking.Nenhum agendamento cadastrado nesse CPF")}...
        </Text>
      </div>
    </div>
  );
};

const WithoutCpf = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <div className="flex animate-pulse flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Digite um CPF")}...
        </Text>
        <Text>{t("booking.É necessário um CPF para buscar as reservas")}</Text>
      </div>
    </div>
  );
};
