import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import i18n from "i18next";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { cpf } from "cpf-cnpj-validator";
import { useTranslation } from "react-i18next";
import { TextInput } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { useConfig } from "@/hooks/useConfig";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { removeCharacteres } from "@/utils/formats";
import { useTriggerEmail } from "../hooks/useTriggerEmail";
import { forgotMyPasswordFormAtom } from "..";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const usernameFormSchema = z.object({
  username: z
    .string()
    .email(i18n.t("auth.Informe um dado válido"))
    .or(
      z
        .string()
        .transform((value) => removeCharacteres(value))
        .refine((value) => cpf.isValid(value), i18n.t("auth.Informe um dado válido")),
    ),
});

type PersonalFormData = z.infer<typeof usernameFormSchema>;

interface UsernameFormProps {
  handleNextStep: () => void;
}

export function UsernameForm({ handleNextStep }: UsernameFormProps) {
  const { t } = useTranslation();

  const history = useHistory();
  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const [dataForm, setDataForm] = useAtom(forgotMyPasswordFormAtom);

  const allowHelp = useConfig({
    label: "external.suport.help",
  });

  const [showHelp, setShowHelp] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalFormData>({
    resolver: zodResolver(usernameFormSchema),
    mode: "onChange",
  });

  const { mutateAsync, isLoading } = useTriggerEmail();

  async function onSubmit(data: PersonalFormData) {
    const { username } = data;

    await mutateAsync(
      {
        appId: Number(appId),
        username,
      },
      {
        onSuccess: () => {
          handleNextStep();

          setDataForm({
            ...dataForm,
            username,
          });
        },
        onError: (err) => {
          setShowHelp(true);
          if (err instanceof AxiosError) {
            if (err.response?.status === 422) {
              toast.error(t("auth.Não encontramos o usuário"));
              return;
            }
          }

          toast.error(t("auth.Erro ao buscar usuário"));

          console.error(err);
        },
      },
    );
  }

  function loginProblems() {
    history.push(`/${slug}/help`);
  }

  return (
    <form
      id="personalForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={1}
        title={t("auth.Informe seu e-mail")}
        description={t(
          "auth.Precisamos validar alguns dados. Você receberá um código de verificação em seu email de cadastro.",
        )}
      />

      <div className="flex flex-1 flex-col">
        <TextInput
          floating
          type="email"
          placeholder="E-mail"
          error={errors.username?.message}
          {...register("username")}
        />
        {allowHelp && showHelp ? (
          <Button size="lg" variant="tertiary" full onClick={loginProblems}>
            {t("auth.Preciso de ajuda")}
          </Button>
        ) : null}

        <footer className="mt-auto flex flex-col gap-3">
          <Button
            size="lg"
            background="primary-500"
            full
            rounded="md"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("general.Continuar")}
          </Button>
        </footer>
      </div>
    </form>
  );
}
