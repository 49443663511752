import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  CalendarCheck,
  CaretLeft,
  CaretRight,
  Clock,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { Box, Switch, Text, TextInput, cn } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";
import { cpf as cpfValidate } from "cpf-cnpj-validator";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, Loading } from "@/components";
import { Booking } from "@/services/assetsScheduling";
import { getAppSlug } from "@/services/old/starter";
import { useAppStore } from "@/store/useAppStore";
import { useUserStore } from "@/store/useUserStore";
import { formatCpf, unformatCpf } from "@/utils/formats";
import { useBooking } from "./hooks/useBooking";

import errorIllustration from "@/assets/illustrations/error.svg";
import emptyIllustration from "@/assets/illustrations/empty.svg";

export const ListBooking = () => {
  const appId = useAppStore((state) => state.app?.id);
  const user = useUserStore((state) => state.user);
  const [cpf, setCpf] = useState<string | null | undefined>(user?.cpf);
  const { data, isLoading, isFetching, isError } = useBooking(appId as number, cpf);

  const [query, setQuery] = useState<string>("");
  const [myBookings, setMyBookings] = useState<boolean>(!!user);
  const [bookings, setBookings] = useState<Array<Booking> | undefined>(data);

  const { t } = useTranslation();
  const history = useHistory();

  const handleNavigate = () => history.goBack();

  const handleCpfField = (cpfValue: string) => {
    setQuery(formatCpf(cpfValue));
    cpfValidate.isValid(cpfValue) ? setCpf(unformatCpf(cpfValue)) : setCpf("");
  };

  useEffect(() => {
    data && setBookings(data);
  }, [data]);

  function handleNavigateToBooking(id: number, spaceId: number) {
    history.replace(`/${getAppSlug()}/assets-scheduling/${id}?espaco_id=${spaceId}`);
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleNavigate}
            />
          </div>

          <Text
            size="md"
            weight="medium"
            color="primary-500"
            leading="relaxed"
            className="truncate"
          >
            {t("booking.Agendamento de espaços")}
          </Text>
        </div>
      </Header>
      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-5 px-4 pb-6 pt-4 ios:pb-2">
          <header className="flex flex-col gap-3">
            <div className="flex w-full gap-1.5">
              <TextInput
                icon={<MagnifyingGlass className="text-slate-700" />}
                placeholder={t("booking.Informe um CPF válido")}
                value={query}
                inputMode="numeric"
                onChange={(e) => handleCpfField(e.target.value)}
                {...(query &&
                  query.length === 14 &&
                  !cpfValidate.isValid(cpf as string) && { error: t("booking.CPF inválido") })}
                disabled={myBookings}
              />
            </div>
            {user?.cpf && (
              <div
                className="flex gap-2"
                onClick={() => {
                  if (!myBookings) {
                    setCpf(user.cpf);
                    setQuery("");
                  } else {
                    setCpf(undefined);
                  }
                  setMyBookings((prevState) => !prevState);
                }}
              >
                <Switch checked={myBookings} />
                <Text>Meus agendamentos</Text>
              </div>
            )}
          </header>

          {data && cpf ? (
            bookings && bookings.length > 0 ? (
              bookings.map((booking) => (
                <Box
                  key={booking.id}
                  variant="primary"
                  className={cn("flex flex-col gap-2 p-3", {
                    "border-l-orange-600": booking.tipo === "reservado",
                    "border-l-red-500": booking.tipo === "rejeitado",
                    "border-l-primary-500": booking.tipo === "aprovado",
                  })}
                  onClick={() => handleNavigateToBooking(booking.id, booking.espaco.id)}
                >
                  <div className="flex justify-between">
                    <Text color="slate-700">{booking.espaco.nome}</Text>

                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-50">
                      <CaretRight size={12} weight="bold" className="text-primary-500" />
                    </div>
                  </div>

                  <Text>{booking.espaco.unidade.titulo}</Text>

                  <div className="flex items-center gap-1">
                    <CalendarCheck className="text-primary-500 xs:text-lg" />
                    <Text color="slate-700" className="mr-2 leading-[100%] xxs:text-xs xs:text-sm">
                      {dayjs(booking.hora_fim).format("DD MMM YY")}
                    </Text>

                    <Clock className="text-primary-500 xs:text-lg" />
                    <Text color="slate-700" className="mr-2 leading-[100%] xxs:text-xs xs:text-sm">
                      {dayjs(booking.hora_inicio).format("HH:mm")}-
                      {dayjs(booking.hora_fim).format("HH:mm")}
                    </Text>

                    <div className="flex flex-grow justify-end">
                      <Text
                        className={cn("rounded-md px-2 py-1", {
                          "bg-red-500/10 text-red-500": booking.tipo === "rejeitado",
                          "bg-primary-500/10 text-primary-500": booking.tipo === "aprovado",
                          "bg-orange-600/10 text-orange-600": booking.tipo === "reservado",
                        })}
                      >
                        {booking.tipo.charAt(0).toUpperCase() + booking.tipo.slice(1)}
                      </Text>
                    </div>
                  </div>
                </Box>
              ))
            ) : (
              <Empty />
            )
          ) : isLoading && isFetching ? (
            <div className="flex h-full w-full items-center justify-center">
              <Loading variant="secondary" size="md" />
            </div>
          ) : isError && cpf ? (
            <Error />
          ) : (
            <WithoutCpf />
          )}
        </div>
      </Content>
    </Layout>
  );
};

const WithoutCpf = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <div className="flex animate-pulse flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Digite um CPF")}...
        </Text>
        <Text>{t("booking.É necessário um CPF para buscar as reservas")}</Text>
      </div>
    </div>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={errorIllustration} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Desculpe, algo deu errado")}...
        </Text>
        <Text>{t("booking.O nosso time de desenvolvedores esta resolvendo isso")}</Text>
      </div>
    </div>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={emptyIllustration} alt="" />

      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("booking.Nenhuma reserva encontrada")}...
        </Text>
        <Text weight="medium" color="slate-700">
          {t("booking.Nenhum agendamento cadastrado nesse CPF")}...
        </Text>
      </div>
    </div>
  );
};
