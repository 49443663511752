import { useState } from "react";
import { IonModal, IonRippleEffect } from "@ionic/react";
import { useAtomValue } from "jotai";
import { CaretRight } from "@phosphor-icons/react";

import { territoriesAtom, Territory } from "../../../territories/atoms/territories-atom";

interface SelectTerritoryProps {
  value?: Territory;
  onChange: (theme: Territory) => void;
}

export function SelectTerritory({ value, onChange }: SelectTerritoryProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const territories = useAtomValue(territoriesAtom);

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm text-slate-700">Informe o território</label>

        <div
          onClick={() => setIsOpenModal(true)}
          className="flex cursor-pointer items-center justify-between overflow-hidden rounded-md border border-slate-200 px-4 py-3"
        >
          <span
            data-hasValue={!!value}
            className="flex-1 text-ellipsis text-sm font-normal leading-normal text-slate-400 data-[hasValue=true]:font-medium data-[hasValue=true]:text-slate-700"
          >
            {value?.name ?? "Selecione o território"}
          </span>

          <CaretRight size={16} weight="bold" color="#94A3B8" />

          <IonRippleEffect style={{ color: "#94A3B8" }} />
        </div>
      </div>

      <IonModal
        isOpen={isOpenModal}
        onDidDismiss={() => handleCloseModal()}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        <div className="relative flex flex-col gap-6 overflow-auto bg-white px-4 py-8">
          <strong className="text-sm font-medium text-slate-700">Selecione o território</strong>

          <div className="flex flex-wrap items-center gap-3.5 overflow-auto">
            {territories.map((item) => (
              <span
                key={item.id}
                data-selected={item.id === value?.id}
                className="cursor-pointer rounded-full border border-slate-200 bg-transparent px-3 py-1.5 text-sm font-medium leading-normal text-slate-400 transition-colors data-[selected=true]:border-primary-500 data-[selected=true]:bg-primary-500 data-[selected=true]:text-white"
                onClick={() => {
                  onChange(item);
                  handleCloseModal();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </IonModal>
    </>
  );
}
