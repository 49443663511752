import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAtom } from "jotai";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { Text, TextArea } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { useAppId } from "@/store/useAppStore";
import { ratingAtom } from "../index";
import { useRatingUser } from "../hooks/useRatingUser";

const appReviewFormSchema = z.object({
  avaliationText: z.string().optional(),
});

type AppReviewFormData = z.infer<typeof appReviewFormSchema>;

type TextAvaliationProps = {
  handleChangeStep: (step: number) => void;
  enableAvaliationService: boolean;
};

export function TextAvaliationModal({
  handleChangeStep,
  enableAvaliationService,
}: TextAvaliationProps) {
  const { t } = useTranslation();

  const appId = useAppId();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const [rating] = useAtom(ratingAtom);

  const { mutateAsync, isLoading } = useRatingUser();

  const { register, handleSubmit } = useForm<AppReviewFormData>({
    resolver: zodResolver(appReviewFormSchema),
    defaultValues: {
      avaliationText: "",
    },
  });

  const handleRating = async ({
    mood,
    stars,
    avaliationText,
  }: {
    mood: number;
    stars: number | null;
    avaliationText?: string | null;
  }) => {
    await mutateAsync(
      {
        appId: Number(appId),
        protocoloId: Number(id),
        mood,
        stars,
        avaliationText,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["protocols", Number(id)], { refetchType: "active" });
          handleChangeStep(4);
        },
        onError: (err) => {
          console.error(err);
          toast.error("Não foi possível realizar avaliação");
        },
      },
    );
  };

  function onSubmit(data: AppReviewFormData) {
    handleRating({ mood: rating.mood, stars: rating.stars, avaliationText: data.avaliationText });
  }

  return (
    <form id="stepThreeForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-1">
          <Text size="md" className="text-center" color="slate-700" weight="semibold">
            {t("avaliationResearch.Como podemos melhorar?")}
          </Text>
          <Text size="sm" color="slate-400" className="text-center">
            {t("avaliationResearch.Sua resposta é importante para nós.")}
          </Text>
        </div>

        <div className="mx-auto flex w-full max-w-md flex-col gap-6">
          <TextArea
            rows={4}
            placeholder={t("avaliationResearch.Adicionar comentários (opcional)")}
            className="w-full rounded-md border-slate-200 text-sm text-slate-700 placeholder:text-sm placeholder:text-slate-400 focus:border-primary-500"
            {...register("avaliationText")}
          />

          <div className="space-y-2">
            <Button
              full
              type="button"
              variant="tertiary"
              size="lg"
              onClick={() => handleChangeStep(enableAvaliationService ? 2 : 1)}
            >
              {t("general.Retornar")}
            </Button>
            <Button full type="submit" loading={isLoading} size="lg">
              {t("general.Enviar")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
