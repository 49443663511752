import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  color: #334155;
  gap: 4px;
`;

export const RowTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-size: 12px;
`;

export const ContentTitle = styled.div`
  flex: 1 1 0%;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const RowDescription = styled.div`
  font-size: 12px;
  color: #94a3b8;
`;

export const RowStatusId = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContentStatus = styled.div`
  padding: 3px 10px;
  border-radius: 16px;
`;

export const Status = styled.span`
  font-size: 12px;
`;

export const ContentId = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Id = styled.span`
  letter-spacing: 4px;
`;

export const ContentCopy = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextIconCopy = styled.div`
  font-size: 12px;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
`;

export const TextCopy = styled.span``;

export const IconCopy = styled(IonIcon)`
  padding: 0px 4px;
`;

export const FilesPreview = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 12px;
  background: #27272a;
  border-radius: 8px;
  color: #ffffff;
`;

export const LinkFiles = styled.a`
  text-decoration: none;
`;

export const IconFilesPreview = styled(IonIcon)`
  font-size: 45px;
`;

export const DescriptionFiles = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ImagePreview = styled.div`
  width: 100%;
  max-height: 200px;
  background: #27272a;
  overflow: hidden;
  border-radius: 8px;
`;

export const Image = styled.img`
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;
