import { Text, cn } from "@astrolabe-ui/react";

export const Tag = ({
  variant,
  text,
}: {
  variant: "primary" | "warning" | "success";
  text: string;
}) => {
  return (
    <div
      className={cn("rounded-full px-2 py-1", {
        "bg-primary-500/10": variant === "primary",
        "bg-orange-600/10": variant === "warning",
        "bg-green-500/10": variant === "success",
      })}
    >
      <Text
        className={cn("", {
          "text-primary-500": variant === "primary",
          "text-orange-600": variant === "warning",
          "text-green-500": variant === "success",
        })}
        size="xs"
      >
        {text}
      </Text>
    </div>
  );
};
