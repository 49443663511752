import { IonRippleEffect, IonRouterLink } from "@ionic/react";
import { chevronForwardCircleOutline, enterOutline } from "ionicons/icons";
import {
  Content,
  ContentIcon,
  ContentValueImage,
  ContentValueIcon,
  Icon,
  ContentTitle,
  Title,
  ContentIconRow,
  IconRow,
  CustomImage,
} from "./styles";

interface BlockItemAreaListProps {
  title?: string;
  icon?: any;
  customImage?: string;
  routerLink?: string;
  href?: string;
}

export function BlockItemAreaList(props: BlockItemAreaListProps) {
  return (
    <IonRouterLink routerLink={props.routerLink}>
      <Content>
        <ContentIcon>
          {props.customImage ? (
            <ContentValueImage>
              <CustomImage src={props.customImage} alt="icon" />
            </ContentValueImage>
          ) : (
            <ContentValueIcon>
              <Icon icon={enterOutline} />
            </ContentValueIcon>
          )}
        </ContentIcon>
        <ContentTitle>
          <Title>{props.title}</Title>
        </ContentTitle>
        <ContentIconRow>
          <IconRow icon={chevronForwardCircleOutline} />
        </ContentIconRow>

        {props.routerLink && <IonRippleEffect type="bounded" style={{ color: "#e2e8f0" }} />}
      </Content>
    </IonRouterLink>
  );
}
