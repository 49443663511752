import { atom } from "jotai";
import { Hour, Local, Schedule } from "@/services/schedulings";

type SchedulingCompletionData = {
  resource: { id: number; textInstructions: string | null } | null;
  entityId: number | null;
  local: Local | null;
  schedule: Schedule | null;
  hour: Hour | null;
};

export const scheduleCompleteAtom = atom<SchedulingCompletionData>({
  entityId: null,
  resource: null,
  local: null,
  schedule: null,
  hour: null,
});
