import {
  InfiniteScrollCustomEvent,
  IonContent,
  IonInfiniteScroll,
  IonPage,
  IonSkeletonText,
} from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";

import { Container, Content, Error, HeaderContent, ScrollInfiniteContent } from "./styles";

import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { MainContent } from "@/templates/old/ppa/MainContent";
import { FilterBar } from "./components/filter-bar/filter-bar";
import { ListProposals, ListProposalsSkeleton } from "./components/list-proposals/list-proposals";
import { useProposals } from "./hooks/use-proposals";
import { useConsult } from "./hooks/use-consult";

import logoPPA from "@/assets/images/logo-ppa.png";
import errorImg from "@/assets/images/error.jpg";

export function PPAProposals() {
  const { SLUG } = useParams<{ SLUG: string; id: string }>();
  const history = useHistory();

  const {
    consult,
    isLoading: isLoadingConsult,
    isError: isErrorConsult,
  } = useConsult({
    SLUG,
    id: 1,
  });

  const {
    total,
    proposals,
    isLoading: isLoadingProposals,
    isError: isErrorProposals,
    hasNextPage,
    fetchNextPage,
  } = useProposals({
    SLUG,
    id: 1,
  });

  function handleGoBack() {
    history.goBack();
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoBack} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="PPA RN" />
        </NavLink>
      </Header>

      <IonContent style={{ backgroundColor: "#fff" }}>
        <MainContent style={{ minHeight: "100%", display: "flex" }}>
          <Container>
            <Content>
              {!consult && isLoadingConsult ? <HeaderSkeleton /> : null}

              {consult ? (
                <HeaderContent>
                  <h1>{consult?.title}</h1>
                  <FilterBar themes={consult.themes} />
                </HeaderContent>
              ) : null}

              {!proposals && isLoadingProposals ? <ListProposalsSkeleton /> : null}

              {proposals ? (
                <>
                  <ListProposals total={total} proposals={proposals} />

                  <IonInfiniteScroll
                    threshold="200px"
                    disabled={!hasNextPage}
                    onIonInfinite={async (e: InfiniteScrollCustomEvent) => {
                      await fetchNextPage();
                      e.target.complete();
                    }}
                  >
                    <ScrollInfiniteContent loadingSpinner="dots" loadingText="Carregando..." />
                  </IonInfiniteScroll>
                </>
              ) : null}

              {isErrorConsult || isErrorProposals ? (
                <Error>
                  <img src={errorImg} alt="Erro" />

                  <div>
                    <strong>Não foi possível buscar os dados</strong>
                    <p>
                      Tivemos problema ao buscar as informações, por favor recarregue e tente
                      novamente.
                    </p>
                  </div>
                </Error>
              ) : null}
            </Content>
          </Container>
        </MainContent>
      </IonContent>
    </IonPage>
  );
}

export function HeaderSkeleton() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
      }}
    >
      <IonSkeletonText animated style={{ height: 20, width: "80%" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "1rem",
        }}
      >
        <IonSkeletonText animated style={{ height: 39, width: "100%" }} />
        <IonSkeletonText animated style={{ height: 35, width: "25%" }} />
      </div>
    </div>
  );
}
