import { Dispatch, SetStateAction } from "react";
import { IonModal } from "@ionic/react";

import { Success } from "./Success";
import { Error } from "./Error";

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  status?: "success" | "error";
  error?: string;
}

export const Modal = ({ isOpen, setIsOpen, status, error = "" }: ModalProps) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      {status === "success" ? (
        <Success setIsOpen={setIsOpen} />
      ) : (
        <Error setIsOpen={setIsOpen} error={error} />
      )}
    </IonModal>
  );
};
