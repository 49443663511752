import { atom } from "jotai";

export type FilterProps = {
  query: string;
  theme: number;
};

export const filterProposalsAtom = atom<FilterProps>({
  query: "",
  theme: 0,
});
