import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";
import { NotePencil } from "@phosphor-icons/react";

import { Button } from "@/components";
import { CardInfo } from "../../../components/card-info";
import { SuccessEnterToQueue } from "../components/success-enter-to-queue";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAppId } from "@/store/useAppStore";
import { useEnterToQueue } from "../hooks/use-enter-to-queue";
import { formatCpf, formatPhone } from "@/utils/formats";
import { modalErrorSchedulingAtom } from "@/pages/schedulings/components/modal-error";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

export function ValidationQueue() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();
  const queryClient = useQueryClient();

  const appId = useAppId();

  const [showModalSuccess, setShowModalSuccess] = useState({ isOpen: false, prevision: 0, id: 0 });

  const { mutateAsync, isLoading } = useEnterToQueue();

  const { personal, resource, questions, extraFields } = useAtomValue(createSchedulingDataAtom);
  const setModalErrorData = useSetAtom(modalErrorSchedulingAtom);

  async function handleEnterToQueue() {
    if (resource && personal) {
      await mutateAsync(
        {
          appId: Number(appId),
          schedulingId: Number(id),
          resourceId: resource.id,
          personal,
          extraFields,
          questions,
        },
        {
          onSuccess: ({ prevision, id }) => {
            queryClient.invalidateQueries({ queryKey: ["my-schedulings", appId] });

            setShowModalSuccess({ isOpen: true, prevision, id });
          },
          onError: (err) => {
            console.error(err);

            let title = "Não foi possível se cadastrar na fila";
            let message = "Não conseguimos cadastrar a pessoa na fila do serviço de agendamento.";
            let enableGoSchedulings = false;

            if (err instanceof AxiosError && err.response?.data.type === "sem_fila") {
              title = "O serviço não possui fila";
              message = "O serviço a qual você deseja entrar na fila, não possui fila configurada.";
            }

            if (err instanceof AxiosError && err.response?.data.type === "cpf_fila") {
              title = "Esse CPF já está cadastrado na fila";
              message = "A pessoa com esse CPF já tem posição na fila no serviço desejado.";
              enableGoSchedulings = true;
            }

            setModalErrorData({
              isOpen: true,
              enableGoSchedulings,
              title,
              message,
            });
          },
        },
      );
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleEditQuestion(id: number) {
    const index = questions.findIndex((item) => item.id === id);

    searchParams.delete("step");
    searchParams.append("step", "questions");
    searchParams.append("question", String(index));

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleCloseModal() {
    setShowModalSuccess({ isOpen: false, prevision: 0, id: 0 });
  }

  return (
    <div className="flex w-full flex-1 flex-col gap-5 px-4 py-6 ios:pb-2">
      <Text
        asChild
        size="lg"
        weight="medium"
        color="primary-500"
        className="text-[24px] leading-[28px]"
      >
        <h1>Confirme os dados inseridos</h1>
      </Text>

      <div className="flex flex-col gap-4">
        {personal ? (
          <CardInfo.Root>
            <CardInfo.Info>
              <CardInfo.InfoTitle>Nome</CardInfo.InfoTitle>
              <CardInfo.InfoText>{personal.name}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Info>
              <CardInfo.InfoTitle>CPF</CardInfo.InfoTitle>
              <CardInfo.InfoText>{formatCpf(personal.cpf)}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Info>
              <CardInfo.InfoTitle>Celular</CardInfo.InfoTitle>
              <CardInfo.InfoText>{formatPhone(personal.phone)}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Info>
              <CardInfo.InfoTitle>E-mail</CardInfo.InfoTitle>
              <CardInfo.InfoText>{personal.email}</CardInfo.InfoText>
            </CardInfo.Info>

            {extraFields.flatMap((item) =>
              item.response ? (
                <CardInfo.Info key={item.id}>
                  <CardInfo.InfoTitle>{item.title}</CardInfo.InfoTitle>

                  {item.type === "arquivo" ? (
                    <CardInfo.InfoFile>{item.response}</CardInfo.InfoFile>
                  ) : null}

                  {item.type === "imagem" ? (
                    <CardInfo.InfoImage src={item.response.toString()} />
                  ) : null}

                  {item.type !== "imagem" && item.type !== "arquivo" ? (
                    <CardInfo.InfoText>{item.response}</CardInfo.InfoText>
                  ) : null}
                </CardInfo.Info>
              ) : (
                []
              ),
            )}

            <CardInfo.Action onClick={() => handleChangeStep("personal")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}

        {questions.map((item) => (
          <CardInfo.Root key={item.id}>
            <CardInfo.Info>
              <CardInfo.InfoTitle>{item.title}</CardInfo.InfoTitle>
              <CardInfo.InfoText>{item.response}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleEditQuestion(item.id)}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ))}

        {resource ? (
          <CardInfo.Root>
            <CardInfo.Info>
              <CardInfo.InfoTitle>Serviço</CardInfo.InfoTitle>
              <CardInfo.InfoText>{resource.name}</CardInfo.InfoText>
            </CardInfo.Info>

            <CardInfo.Action onClick={() => handleChangeStep("resource")}>
              <Text size="xs" color="primary-500">
                Editar
              </Text>
              <NotePencil size={12} />
            </CardInfo.Action>
          </CardInfo.Root>
        ) : null}
      </div>

      <Button
        type="submit"
        form="form-personal"
        full
        size="lg"
        className="mt-auto"
        loading={isLoading}
        onClick={handleEnterToQueue}
      >
        {t("general.Confirmar")}
      </Button>

      {resource ? (
        <SuccessEnterToQueue
          showModal={showModalSuccess.isOpen}
          onClose={handleCloseModal}
          id={showModalSuccess.id}
          prevision={showModalSuccess.prevision}
          textHelp={resource.textFinalization}
        />
      ) : null}
    </div>
  );
}
