import { useParams } from "react-router";
import { useAtomValue } from "jotai";
import { UserCircle } from "@phosphor-icons/react";
import { IonSkeletonText } from "@ionic/react";

import { Badge, Badges, Button, Container, Content, Creator, Spinner, Text } from "./styles";

import { selectedTerritoryAtom } from "../../../territories/atoms/territories-atom";
import { Tag, Theme, useVoteMutation } from "../../hooks/use-proposals";

interface ProposalProps {
  id: number;
  title: string | null;
  creator: string | null;
  voters: number;
  hasVoter: boolean;
  tags: Array<Tag>;
  themes: Array<Theme>;
  blocked: boolean;
  isExpired?: boolean;
}

export function Proposal({
  id,
  title,
  creator,
  voters,
  hasVoter,
  tags,
  themes,
  blocked,
  isExpired,
}: ProposalProps) {
  const { SLUG, id: idConsult } = useParams<{ SLUG: string; id: string }>();

  const selectedTerritory = useAtomValue(selectedTerritoryAtom);

  const { mutateAsync, isLoading } = useVoteMutation();

  async function handleVote() {
    handleSendVote(selectedTerritory);
  }

  async function handleSendVote(territory: number) {
    await mutateAsync({
      SLUG,
      idConsult: Number(idConsult),
      id,
      vote: "1",
      extra: String(territory),
    });
  }

  async function handleRemoveVote() {
    await mutateAsync({
      SLUG,
      idConsult: Number(idConsult),
      id,
      vote: "0",
    });
  }

  return (
    <Container>
      <Content>
        {themes.length || tags.length ? (
          <Badges>
            {tags.length
              ? tags.map((item) => (
                  <Badge key={item.id} variant="tag">
                    {item.title}
                  </Badge>
                ))
              : null}
            {themes.length
              ? themes.map((item) => (
                  <Badge key={item.id} variant="theme">
                    {item.title}
                  </Badge>
                ))
              : null}
          </Badges>
        ) : null}

        <Text>{title}</Text>

        {creator ? (
          <Creator>
            <UserCircle size={20} color="#334155" />
            <span>{creator}</span>
          </Creator>
        ) : null}
      </Content>

      <footer>
        <p>
          {voters}
          <span> {voters > 1 ? "VOTOS" : "VOTO"}</span>
        </p>

        <Button
          variant={hasVoter ? "outline" : "primary"}
          onClick={hasVoter ? handleRemoveVote : handleVote}
          blocked={blocked}
          disabled={isLoading || blocked}
        >
          {isLoading ? (
            <Spinner name="dots" />
          ) : (
            <>
              {isExpired
                ? "Consulta encerrada"
                : hasVoter
                ? "Remover"
                : blocked
                ? "Sem votos restantes"
                : "Votar"}
            </>
          )}
        </Button>
      </footer>
    </Container>
  );
}

export function ProposalSkeleton() {
  return (
    <Container>
      <Content>
        <Badges>
          <IonSkeletonText animated style={{ height: 22, width: 80 }} />
        </Badges>

        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
        </div>
      </Content>

      <footer>
        <IonSkeletonText animated style={{ width: 60 }} />
        <IonSkeletonText animated style={{ width: 84, height: 36, borderRadius: 99999 }} />
      </footer>
    </Container>
  );
}
