import { useMutation } from "@tanstack/react-query";
import { sendClickService } from "@/services/categoryServices";

interface useSendClickServiceParams {
  appId: number;
  serviceId: number;
}

export function useSendClickService() {
  return useMutation({
    mutationFn: ({ appId, serviceId }: useSendClickServiceParams) => {
      return sendClickService({ appId, serviceId });
    },
  });
}
