import { TextInput } from "@astrolabe-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";

import { filterByQueryAtom } from "../atoms/filterAtom";

export function FilterByQuery() {
  const { t } = useTranslation();

  const [query, setQuery] = useAtom(filterByQueryAtom);

  return (
    <TextInput
      icon={<MagnifyingGlass className="text-slate-700" />}
      placeholder={t("general.Buscar")}
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  );
}
