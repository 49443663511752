import { IonApp, setupIonicReact } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

import { useStartApp } from "@/hooks/useStartApp";

import { Router } from "@/router/Router";
import { Maintenance } from "@/pages/old/Maintenance";
import { Page404 } from "@/pages/old/404";
import { Splash } from "@/components/old/Splash";
import { WithoutConnectionModal, AppReview, CustomToaster } from "@/components";

setupIonicReact();

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}

export default function App() {
  const { data, isLoading } = useStartApp();

  if (isLoading) {
    return <Splash />;
  }

  if (data?.error) {
    return <Page404 />;
  }

  return (
    <IonApp>
      <CustomToaster />
      <WithoutConnectionModal />
      <AppReview />
      {data?.maintenance ? <Maintenance /> : <Router />}
    </IonApp>
  );
}
