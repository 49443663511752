import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IonLoading, IonInput, IonButton, IonIcon, IonAlert } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { BlockForm } from "@/components/old/BlockForm";
import { ErrorMessage } from "@/components/old/Base";
import { getAppSlug } from "@/services/old/starter";
import { setNewPassword } from "@/services/old/auth";
import { getContent } from "@/content/index";

const PasswordDiv = styled.div`
  width: 100%;
  display: flex;
`;

export function PasswordChange() {
  const CONTENT = getContent();

  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loginError, setLoginError] = useState({ currentPassword: "", password: "" });
  const [form, setForm] = useState(new FormData());

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  function handlerRegister() {
    setForm(new FormData());

    let passwordMsg = "";
    let currentPasswordMsg = "";
    let wrongValue = false;

    if (password.length === 0) {
      passwordMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_NEED_PASSWORD;
      wrongValue = true;
    } else if (currentPassword.length === 0) {
      currentPasswordMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_NEED_CURRENT_PASSWORD;
      wrongValue = true;
    } else if (password !== confirmPassword) {
      passwordMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_DIFERENTS_PASSWORD;
      wrongValue = true;
    } else if (password === currentPassword) {
      passwordMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_EQUALS_TO_CURRENT;
      currentPasswordMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_EQUALS_TO_CURRENT;
      wrongValue = true;
    }

    setLoginError({ currentPassword: currentPasswordMsg, password: passwordMsg });

    if (wrongValue) {
      return;
    }

    setShowLoadingDialog(true);
    setForm(new FormData());
    form.append("password", currentPassword);
    form.append("newPassword", password);
    setNewPassword(form, getAppSlug())
      .then(() => {
        setShowLoadingDialog(false);
        setShowAlert(true);
      })
      .catch((err) => {
        console.error(err);

        setShowLoadingDialog(false);

        const passwordMsg = "";
        let currentPasswordMsg = "";

        if (err.data?.error === "Senha atual não é válida.") {
          currentPasswordMsg = "Senha atual incorreta";
        }

        setLoginError({ currentPassword: currentPasswordMsg, password: passwordMsg });
      });
  }

  return (
    <PageTemplate name={CONTENT.USER.CHANGE_PASSWORD} hideHeaderUserButton={true}>
      <BlockForm>
        <PasswordDiv>
          <div style={{ width: "88%" }}>
            <IonInput
              className="has-highlight-border"
              fill="outline"
              labelPlacement="stacked"
              mode="md"
              label={`${CONTENT.USER.CHANGE_PASSWORD_CURRENT}:`}
              placeholder="••••••••••••••••"
              type={showPassword ? "text" : "password"}
              onIonInput={(e) => {
                setCurrentPassword(e.detail.value + "");
              }}
            />
          </div>
          <IonButton
            style={{ width: "12%" }}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            size="large"
            fill="outline"
            color="medium"
            className="ion-button-border-thin m-0 h-14 text-xs"
          >
            <IonIcon icon={showPassword ? eyeOff : eye} />
          </IonButton>
        </PasswordDiv>
        {loginError.currentPassword && <ErrorMessage>{loginError.currentPassword}</ErrorMessage>}

        <PasswordDiv>
          <div style={{ width: "88%" }}>
            <IonInput
              className="has-highlight-border"
              fill="outline"
              labelPlacement="stacked"
              mode="md"
              label={`${CONTENT.USER.CHANGE_PASSWORD_NEW}:`}
              placeholder="••••••••••••••••"
              type={showPassword2 ? "text" : "password"}
              onIonInput={(e) => {
                setPassword(e.detail.value + "");
              }}
            ></IonInput>
          </div>
          <IonButton
            style={{ width: "12%" }}
            onClick={() => {
              setShowPassword2(!showPassword2);
            }}
            size="large"
            fill="outline"
            color="medium"
            className="ion-button-border-thin m-0 h-14 text-xs"
          >
            <IonIcon icon={showPassword2 ? eyeOff : eye} />
          </IonButton>
        </PasswordDiv>
        {loginError.password && <ErrorMessage>{loginError.password}</ErrorMessage>}

        <PasswordDiv>
          <div style={{ width: "88%" }}>
            <IonInput
              className="has-highlight-border me-2"
              fill="outline"
              labelPlacement="stacked"
              mode="md"
              label={`${CONTENT.USER.CHANGE_PASSWORD_REPEAT_NEW}:`}
              placeholder="••••••••••••••••"
              type={showPassword3 ? "text" : "password"}
              onIonInput={(e) => {
                setConfirmPassword(e.detail.value + "");
              }}
            />
          </div>
          <IonButton
            style={{ width: "12%" }}
            onClick={() => {
              setShowPassword3(!showPassword3);
            }}
            size="large"
            fill="outline"
            color="medium"
            className="ion-button-border-thin m-0 h-14 text-xs"
          >
            <IonIcon icon={showPassword3 ? eyeOff : eye} />
          </IonButton>
        </PasswordDiv>
        {loginError.password && <ErrorMessage>{loginError.password}</ErrorMessage>}

        <IonButton
          expand="block"
          onClick={() => {
            handlerRegister();
          }}
        >
          {CONTENT.USER.CHANGE_PASSWORD}
        </IonButton>
      </BlockForm>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
          history.push("/" + getAppSlug() + "/login");
        }}
        header={CONTENT.USER.ALERT_PASSWORD_UPDATE_TITLE}
        message={CONTENT.USER.ALERT_PASSWORD_UPDATE_MSG}
        buttons={[CONTENT.USER.ALERT_PASSWORD_UPDATE_BT]}
      />

      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
