import { useQuery } from "@tanstack/react-query";
import { getProtocolByid } from "@/services/protocols";

interface useProtocolByIdParams {
  appId: number;
  protocolId: number;
}

export function useProtocolById({ appId, protocolId }: useProtocolByIdParams) {
  return useQuery({
    queryKey: ["protocols", protocolId],
    queryFn: () => getProtocolByid({ appId, protocolId }),
    enabled: !!appId,
  });
}
