import { IonRadio, IonRadioGroup } from "@ionic/react";
import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  width: fit-content;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 9999px;

  span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
    color: #334155;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
  background-color: #ffffff;

  strong {
    margin: 0;

    font-size: 0.875rem;
    font-weight: 500;
    color: #94a3b8;
  }
`;

export const Radios = styled(IonRadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

interface RadioProps {
  selected: boolean;
}

export const Radio = styled.div<RadioProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  label {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${(props) => (props.selected ? COLORS.PRIMARY : "#475569")};
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
`;

export const RadioButton = styled(IonRadio)`
  --border-radius: 50%;
  --inner-border-radius: 50%;
  --color: #94a3b8;
  --color-checked: ${COLORS.PRIMARY};
`;
