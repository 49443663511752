import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components/index";
import { CardItem } from "@/pages/schedulings/components/card-item";
import { RemovedFromScheduling } from "../components/removed-from-scheduling";
import { useSearchParams } from "@/hooks/useSearchParams";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

export function AnswerQuestion() {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const currentIndexQuestion = Number(searchParams.get("question")) ?? 0;

  const [{ resource, questions }, updateCreateSchedulingData] = useAtom(createSchedulingDataAtom);

  const [showModalRemoved, setShowModalRemoved] = useState<{
    isOpen: boolean;
    content: string | null;
  }>({
    isOpen: false,
    content: "",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<Record<string, string>>();

  const currentQuestion = useMemo(
    () => resource?.questions[currentIndexQuestion],
    [resource?.questions, currentIndexQuestion],
  );

  useEffect(() => {
    if (currentQuestion) {
      const question = questions.find((item) => item.id === currentQuestion.id);

      if (question?.response) {
        reset({
          [currentQuestion.id]: question.response,
        });
      }
    }
  }, [currentQuestion, questions, reset]);

  function onSubmit(data: Record<string, string>) {
    if (resource && currentQuestion) {
      updateCreateSchedulingData((current) => {
        const question = current.questions.find((item) => item.id === currentQuestion.id);

        let questions = [];

        if (!question) {
          questions = [
            ...current.questions,
            {
              id: currentQuestion.id,
              title: currentQuestion.title,
              response: data[currentQuestion.id],
            },
          ];
        } else {
          questions = current.questions.map((item) => {
            if (item.id === question.id) {
              return { ...item, response: data[currentQuestion.id] };
            } else {
              return item;
            }
          });
        }

        return {
          ...current,
          questions,
        };
      });

      reset();

      if (
        currentQuestion.type === "remover_fila" &&
        currentQuestion.positiveResponse === data[currentQuestion.id]
      ) {
        setShowModalRemoved({
          isOpen: true,
          content: currentQuestion.removalJustification,
        });
        return;
      }

      if (currentIndexQuestion + 1 < resource.questions.length) {
        handleChangeQuestion(currentIndexQuestion + 1);
        return;
      }

      handleChangeStep();
    }
  }

  function handleChangeQuestion(question: number) {
    searchParams.delete("question");

    searchParams.append("question", String(question));

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleChangeStep() {
    searchParams.delete("question");

    searchParams.delete("step");

    searchParams.append("step", "personal");

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  function handleCloseModalRemoved() {
    setShowModalRemoved({ isOpen: false, content: "" });
  }

  if (!currentQuestion) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col gap-5 px-4 py-6 ios:pb-2">
      <div className="space-y-1">
        <Text
          asChild
          size="lg"
          weight="medium"
          color="primary-500"
          className="text-[24px] leading-[28px]"
        >
          <h1>{currentQuestion.title}</h1>
        </Text>
        <Text size="xs">{currentQuestion.description}</Text>
      </div>

      <form
        id="answer-form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-4"
      >
        <Controller
          control={control}
          name={String(currentQuestion.id)}
          rules={{
            required: {
              value: true,
              message: "Esse campo é obrigatório",
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CardItem
              type="button"
              title={currentQuestion.positiveResponse}
              data-selected={value === currentQuestion.positiveResponse}
              onClick={() => onChange(currentQuestion.positiveResponse)}
            />
          )}
        />

        <Controller
          control={control}
          name={String(currentQuestion.id)}
          rules={{
            required: {
              value: true,
              message: "Esse campo é obrigatório",
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CardItem
              type="button"
              title={currentQuestion.negativeResponse}
              data-selected={value === currentQuestion.negativeResponse}
              onClick={() => onChange(currentQuestion.negativeResponse)}
            />
          )}
        />
      </form>

      <Button
        type="submit"
        form="answer-form"
        disabled={!isValid}
        full
        size="lg"
        className="mt-auto"
      >
        {t("general.Continuar")}
      </Button>

      <RemovedFromScheduling
        showModal={showModalRemoved.isOpen}
        onClose={handleCloseModalRemoved}
        content={showModalRemoved.content}
      />
    </div>
  );
}
