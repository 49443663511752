import { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { OutputView, Text } from "@astrolabe-ui/react";
import { CaretDown, User } from "@phosphor-icons/react";

import { Field } from "@/services/wallets";

interface InfosProps {
  fields: Array<Field>;
}

export function Infos({ fields }: InfosProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <article className="flex flex-col gap-4 print:hidden">
      <header className="flex items-center gap-2">
        <User size={20} className="text-slate-400" />
        <Text color="slate-700">{t("wallets.Dados de cadastro")}</Text>
      </header>

      <motion.ul className="space-y-2">
        {fields.slice(0, 3).map((field) => (
          <OutputView
            key={field.id}
            heading={field.label}
            content={field.value}
            titleWeight="medium"
          />
        ))}

        {isOpen
          ? fields
              .slice(3, fields.length)
              .map((field) => (
                <OutputView
                  key={field.id}
                  heading={field.label}
                  content={field.value}
                  titleWeight="medium"
                />
              ))
          : null}
      </motion.ul>

      {fields.length > 3 && (
        <motion.button
          layout
          className="mx-auto mb-10 flex items-center gap-2"
          onClick={toggleOpen}
          style={{ WebkitTapHighlightColor: "transparent" }}
        >
          <span className="text-sm leading-6 text-primary-500 sm:text-base">
            {t("wallets.Ver dados")} {isOpen ? t("wallets.resumidos") : t("wallets.completos")}
          </span>

          <motion.div animate={{ rotate: isOpen ? -180 : 0 }} transition={{ duration: 0.3 }}>
            <CaretDown size={16} className="text-primary-500" />
          </motion.div>
        </motion.button>
      )}
    </article>
  );
}
