import { IonSkeletonText } from "@ionic/react";
import { ArrowRight } from "@phosphor-icons/react";
import { Wallet } from "@/services/wallets";

interface CardWalletProps {
  wallet: Wallet;
  onClick: () => void;
}

export function CardWallet({ wallet, onClick }: CardWalletProps) {
  return (
    <article className="flex flex-col justify-between gap-10 rounded-md border-medium border-slate-200 bg-transparent pt-4">
      <header className="flex gap-3 pl-4 pr-6">
        {wallet.imageLogo && (
          <div className="h-9 flex-shrink-0 rounded-full sm:h-12">
            <img src={wallet.imageLogo} alt="" className="h-full w-auto object-cover" />
          </div>
        )}

        <strong className="flex-1 text-base font-medium text-slate-700 sm:text-lg">
          {wallet.name}
        </strong>
      </header>

      <footer className="flex items-center justify-center border-t-thin border-slate-200 px-4 py-5">
        <button
          onClick={onClick}
          className="flex items-center gap-2 text-primary-500"
          style={{ WebkitTapHighlightColor: "transparent" }}
        >
          <span className="text-sm leading-6 sm:text-base">Visualizar carteira</span>
          <ArrowRight size={20} />
        </button>
      </footer>
    </article>
  );
}

export function CardWalletSkeleton() {
  return (
    <div className="flex  flex-col justify-between gap-10 rounded-lg bg-slate-100 pt-4">
      <div className="flex gap-3 pl-4 pr-6">
        <IonSkeletonText animated className="h-9 w-9 flex-shrink-0 rounded-full bg-slate-200/60" />
        <div className="flex flex-1 flex-col gap-2">
          <IonSkeletonText animated className="h-7 w-4/5 rounded-full bg-slate-200/60" />
          <IonSkeletonText animated className="h-7 w-1/3 rounded-full bg-slate-200/60" />
        </div>
      </div>

      <div className="flex items-center justify-center border-t border-slate-200/60 px-4 py-6">
        <IonSkeletonText animated className="h-5 w-1/2 rounded-full bg-slate-200/60" />
      </div>
    </div>
  );
}
