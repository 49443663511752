import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IonLoading, IonInput } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockForm } from "@/components/old/BlockForm";
import { ErrorMessage } from "@/components/old/Base";
import { GetNeighborhood, Neighborhood } from "@/components/old/GetNeighbordhood";
import { getUserEmail } from "@/services/old/auth";
import { getSellerLoged, sellerRegister } from "@/services/old/feirinha";
import { getContent } from "@/content/index";
import { formatPhone } from "@/utils/formats";
import { NewUploadImage } from "@/components/old/UploadImage/new";
import { Button } from "@/components/Button";

interface paramsPage {
  SLUG: string;
}

export function RegisterSeller() {
  const CONTENT = getContent();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(-1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState(getUserEmail());
  const [phone, setPhone] = useState("");
  const [addr, setAddr] = useState("");
  const [number, setNumber] = useState("");
  const [placeHolderImg, setPlaceHolderImg] = useState();
  const [image, setImage] = useState({
    base64: "",
    file: { name: "" },
  });

  const [loginError, setLoginError] = useState({
    name: "",
    phone: "",
    neighborhood: "",
    email: "",
  });
  const [form, setForm] = useState(new FormData());

  const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood>();

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  const params: paramsPage = useParams();

  useEffect(() => {
    setIsLoading(true);
    getSellerLoged(params.SLUG)
      .then((res) => {
        const seller = res.data[0];
        setId(seller.id);
        setName(seller.nome);
        setEmail(seller.email);
        setPhone(seller.telefone);
        setAddr(seller.logradouro);
        setNumber(seller.numero);
        setPlaceHolderImg(seller.imagem);
        setSelectedNeighborhood({
          id: seller.bairro_id,
          title: "",
          lat: 0,
          lng: 0,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  function imageChangeHandle(data: any) {
    setImage(data);
  }

  function b64toBlob(data: string) {
    const block = data.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];

    const sliceSize = 512;

    const byteCharacters = atob(realData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function handlerRegister() {
    let nameMsg = "";
    let neighborhoodMsg = "";
    let emailMsg = "";
    let phoneMsg = "";
    let wrongValue = false;

    if (!name || name.length === 0) {
      nameMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_NEED_NAME;
      wrongValue = true;
    }
    if (!email || email.length === 0) {
      emailMsg = CONTENT.USER.CHANGE_PASSWORD_ERROR_NEED_EMAIL;
      wrongValue = true;
    }
    if (!phone || phone.length === 0) {
      phoneMsg = CONTENT.FEIRINHA.ERROR_NEED_PHONE;
      wrongValue = true;
    }
    if (!selectedNeighborhood) {
      neighborhoodMsg = CONTENT.LOCATION.ERROR_NEED_NEIGHBORHOOD;
      wrongValue = true;
    }

    setLoginError({
      name: nameMsg,
      phone: phoneMsg,
      email: emailMsg,
      neighborhood: neighborhoodMsg,
    });

    if (wrongValue) {
      return;
    }
    setForm(new FormData());
    form.append("nome", name);
    form.append("email", email);
    form.append("telefone", phone);
    form.append("bairro_id", selectedNeighborhood?.id + "");

    if (id > 0) {
      form.append("id", id + "");
    }
    if (addr && addr.length > 0) {
      form.append("logradouro", addr);
    }

    if (image.base64) {
      form.append("imagem", b64toBlob(image.base64), image.file.name);
    }
    if (number && number.length > 0) {
      form.append("numero", number);
    }
    setShowLoadingDialog(true);
    sellerRegister(form, id, params.SLUG)
      .then((response) => {
        setShowLoadingDialog(false);

        const data = response.data;

        if (data?.id) {
          history.replace(`/${params.SLUG}/abrir-vendedor/${data.id}`);
        } else {
          history.go(-1);
        }
      })
      .catch((res: any) => {
        setShowLoadingDialog(false);
        let emailMsg = "";
        let phoneMsg = "";
        let nameMsg = "";

        if (res.data.error.telefone) {
          phoneMsg = res.data.error.telefone;
        }

        if (res.data.error.email) {
          emailMsg = res.data.error.email;
        }

        if (res.data.error.nome) {
          nameMsg = res.data.error.nome;
        }

        setLoginError({ email: emailMsg, phone: phoneMsg, name: nameMsg, neighborhood: "" });
      });
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={id > 0 ? CONTENT.USER.PROFILE_UPDATE : CONTENT.FEIRINHA.SELLER_REGISTER}>
      <BlockForm>
        <NewUploadImage
          onChange={imageChangeHandle}
          title={CONTENT.USER.PROFILE_IMAGE}
          placeHolderURL={placeHolderImg}
        />

        <IonInput
          className="has-highlight-border"
          label="Nome do empreendimento"
          labelPlacement="stacked"
          mode="md"
          value={name}
          fill="outline"
          placeholder={name || "Maria Silva"}
          onIonInput={(e) => {
            setName(e.detail.value + "");
          }}
        ></IonInput>

        {loginError.name && <ErrorMessage>{loginError.name}</ErrorMessage>}

        <IonInput
          className="has-highlight-border"
          label="Telefone"
          labelPlacement="stacked"
          fill="outline"
          mode="md"
          value={phone}
          placeholder="(XX) XXXX-XXXX"
          maxlength={17}
          // value={phone}
          type="tel"
          inputMode="numeric"
          onIonInput={(e) => {
            if (e.detail.value) {
              setPhone(formatPhone(e.detail.value));
            }
          }}
        ></IonInput>

        {loginError.phone && <ErrorMessage>{loginError.phone}</ErrorMessage>}

        <GetNeighborhood
          SLUG={params.SLUG}
          currentNeighborhood={selectedNeighborhood?.id}
          onValueChange={(n: any) => {
            setSelectedNeighborhood(n);
          }}
        />
        {loginError.neighborhood && <ErrorMessage>{loginError.neighborhood}</ErrorMessage>}

        <IonInput
          className="has-highlight-border"
          label="Rua"
          labelPlacement="stacked"
          fill="outline"
          mode="md"
          value={addr}
          placeholder={"Av. example"}
          onIonInput={(e) => {
            setAddr(e.detail.value + "");
          }}
        />
        <IonInput
          className="has-highlight-border"
          label="Numero"
          labelPlacement="stacked"
          mode="md"
          fill="outline"
          value={number}
          placeholder={"Nº 7722"}
          onIonInput={(e) => {
            setNumber(e.detail.value + "");
          }}
        />

        <Button
          variant="primary"
          size="lg"
          rounded="full"
          full
          onClick={() => {
            handlerRegister();
          }}
        >
          {id > 0 ? CONTENT.USER.PROFILE_UPDATE : CONTENT.FEIRINHA.SELLER_REGISTER}
        </Button>
      </BlockForm>

      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
