import { useQuery } from "@tanstack/react-query";
import { getRegions } from "@/services/auth/register";

interface useRegionsParams {
  appId: number;
}

export function useRegions({ appId }: useRegionsParams) {
  return useQuery({
    queryKey: ["regions", appId],
    queryFn: () => getRegions({ appId }),
    staleTime: 5 * (60 * 1000), // 5 minutos
  });
}
