import styled, { css } from "styled-components";
import { IonRouterLink, IonText, IonRippleEffect } from "@ionic/react";

import { Block } from "@/components/old/Block";
import { getContent } from "@/content/index";

interface StatusDivProps {
  status?: string;
}

const Container = styled(IonRouterLink)`
  border-radius: 8px;
`;

const Content = styled.div`
  padding: 15px;
`;

const StatusBorder = styled.div<StatusDivProps>`
  padding-left: 3px;
  border-radius: 8px;
  background-color: #fff;
  display: block;
  margin-bottom: 10px;

  ${(props) =>
    props.status === "Encerrado" &&
    css`
      border-left: solid #33cb84 5px;
    `}
  ${(props) =>
    props.status === "Em Atendimento" &&
    css`
      border-left: solid #f67906 5px;
    `}
  ${(props) =>
    props.status === "Em aberto" &&
    css`
      border-left: solid #206de1 5px;
    `}
`;

const Item = styled(IonText)`
  display: block;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface BlockProtocolProps {
  routerLink?: string;
  type: string;
  number: string;
  category: string;
  address?: string;
  identifier?: string;
  identifierLabel?: string;
  plate?: string;
  status?: string;
  createdAt: Date;
}

export function BlockProtocol(props: BlockProtocolProps) {
  const CONTENT = getContent();

  const date = props.createdAt;

  return (
    <StatusBorder status={props.status}>
      <Container routerLink={props.routerLink} data-testid="blockprotocol-container">
        <Block noPadding title={props.type}>
          <Content>
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.PROTOCOL}: </b> {props.number}
            </Item>
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.CATEGORY}: </b> {props.category}
            </Item>
            <Item color="dark">
              <b> {CONTENT.PROTOCOLS.CREATE_AT}: </b>
              {(date.getDate() < 10 ? "0" : "") +
                date.getDate() +
                "/" +
                (date.getMonth() + 1 < 10 ? "0" : "") +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear() +
                "  " +
                (date.getHours() < 10 ? "0" : "") +
                date.getHours() +
                ":" +
                (date.getMinutes() < 10 ? "0" : "") +
                date.getMinutes()}
            </Item>
            {props.status && (
              <Item color="dark">
                <b> {CONTENT.PROTOCOLS.STATE}: </b> {props.status}
              </Item>
            )}
            {props.address && (
              <Item color="dark">
                <b> {CONTENT.PROTOCOLS.ADDRESS}: </b> {props.address}
              </Item>
            )}
            {props.identifier && (
              <Item color="dark">
                <b>
                  {props.identifierLabel ? props.identifierLabel : CONTENT.PROTOCOLS.INDENTIFIER}:
                </b>

                {props.identifier}
              </Item>
            )}
            {props.plate && (
              <Item color="dark">
                <b> {CONTENT.PROTOCOLS.PLATE}: </b> {props.plate}
              </Item>
            )}
          </Content>
          {props.routerLink && <IonRippleEffect type="bounded" />}
        </Block>
      </Container>
    </StatusBorder>
  );
}
