import { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IonButton, IonToast } from "@ionic/react";

import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockPost } from "@/components/old/BlockPost";
import { getGalleryModulesList } from "@/services/old/feed";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function ModuleFeed() {
  const [toast, setToast] = useState(false);
  const [redirected, setRedirected] = useState(false);

  const history = useHistory();
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getGalleryModulesList"],
    ({ pageParam = 1 }) => getGalleryModulesList(pageParam, params.SLUG),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.data.next_page_url + "").split("/modulos/fotos?page=")[1];
      },
    },
  );

  function loadMore() {
    setToast(true);
    fetchNextPage();
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.CAMERA.MODULES}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data || data?.pages[0].data.data.length === 0) {
    return <EmptyContentPageTemplate name={CONTENT.CAMERA.MODULES} />;
  }

  return (
    <PageTemplate name={CONTENT.CAMERA.MODULES}>
      <IonToast
        isOpen={toast}
        position="middle"
        color="dark"
        onDidDismiss={() => setToast(false)}
        message={CONTENT.GLOBAL.DONE}
        duration={600}
      />

      {data?.pages.map((item: any, index: number) => {
        if (item.data.total === 1 && item.data.current_page === 1 && !redirected) {
          setRedirected(true);
          history.push("/" + getAppSlug() + "/modules-open/" + item.data.data[0].id);
        }

        return (
          <Fragment key={index}>
            {item.data.data.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <BlockPost
                    id={item.id}
                    title={item.titulo}
                    detail={item.descricao}
                    image={item.imagem}
                    openURL={"/" + getAppSlug() + "/modules-open/" + item.id}
                  />
                </div>
              );
            })}
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
