import { IonRippleEffect, IonRouterLink } from "@ionic/react";
import { chevronForwardCircleOutline, enterOutline } from "ionicons/icons";

import {
  Content,
  ContentIcon,
  ContentIconRow,
  ContentTitle,
  ContentValueIcon,
  CustomImage,
  Description,
  Icon,
  IconRow,
  Title,
} from "./styles";

interface BlockItemAreaServicesListProps {
  title?: string;
  description?: string;
  icon?: any;
  customImage?: string;
  routerLink?: string;
  href?: string;
  type?: string;
  openNewWindow?: boolean;
  onClick?: () => void;
}

export function BlockItemAreaServicesList(props: BlockItemAreaServicesListProps) {
  const contentSize = props.description ? "83px" : "64px";
  const iconSize = props.description ? "52px" : "46px";

  return (
    <IonRouterLink
      routerLink={props.routerLink}
      {...(props.href && props.openNewWindow && { href: props.href, target: "_blank" })}
      {...(props.onClick ? { onClick: props.onClick } : {})}
    >
      <Content height={contentSize}>
        <ContentIcon>
          {props.customImage ? (
            <CustomImage height={iconSize} src={props.customImage} alt="icon" />
          ) : (
            <ContentValueIcon height={iconSize}>
              <Icon icon={enterOutline} />
            </ContentValueIcon>
          )}
        </ContentIcon>

        <ContentTitle>
          <Title>{props.title}</Title>
          {props.customImage && <Description>{props.description}</Description>}
        </ContentTitle>

        <ContentIconRow>
          <IconRow icon={chevronForwardCircleOutline} />
        </ContentIconRow>

        {props.routerLink && <IonRippleEffect type="bounded" style={{ color: "#e2e8f0" }} />}
      </Content>
    </IonRouterLink>
  );
}
