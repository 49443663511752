import { IonSkeletonText } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { Article } from "@phosphor-icons/react";

import { Container, Content, Header, List, NotFound } from "./styles";

import { Ordination } from "../ordination/ordination";
import { Proposal, ProposalSkeleton } from "../proposal/proposal";
import { Proposal as ProposalType } from "../../hooks/use-proposals";

import notFoundImg from "@/assets/images/not-found.jpg";

interface ListProposalsProps {
  total?: number;
  proposals: Array<ProposalType>;
}

export function ListProposals({ total, proposals }: ListProposalsProps) {
  const location = useLocation();

  const initalOrdination = new URLSearchParams(location.search).get("order");

  return (
    <Container>
      {!proposals.length || !total ? (
        <NotFoundContent />
      ) : (
        <Content>
          <Header>
            <div className="infos">
              <Article size={24} color="#94A3B8" />
              <strong>{`${total} ${total === 1 ? "programa" : "programas"}`}</strong>
            </div>

            <Ordination initialOrdination={initalOrdination} />
          </Header>

          <List>
            {proposals.map((item) => (
              <Proposal
                key={item.id}
                title={item.description}
                creator={item.username}
                voters={item.countVoters}
                hasVoter={item.hasUserVoted}
                themes={item.themes}
                tags={item.tags}
              />
            ))}
          </List>
        </Content>
      )}
    </Container>
  );
}

export function ListProposalsSkeleton() {
  return (
    <>
      <Header>
        <div>
          <IonSkeletonText animated style={{ width: 20, height: 20 }} />
          <IonSkeletonText animated style={{ width: 120, height: 24 }} />
        </div>
      </Header>

      {Array.from(Array(3).keys()).map((item) => (
        <ProposalSkeleton key={item} />
      ))}
    </>
  );
}

export function NotFoundContent() {
  return (
    <NotFound>
      <img src={notFoundImg} alt="Jovens com placas em protesto" />

      <div>
        <span>
          Não há programas cadastrados, venha participar da construção de um estado melhor!
        </span>
      </div>
    </NotFound>
  );
}
