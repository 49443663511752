import { useQuery } from "@tanstack/react-query";
import { getWallet } from "@/services/wallets";

interface useWalletParams {
  appId: number;
  walletId: number;
  holderId: number;
  hashWallet: string;
}

export function useWallet({ appId, walletId, holderId, hashWallet }: useWalletParams) {
  return useQuery({
    queryKey: ["wallet", walletId, holderId, hashWallet, appId],
    queryFn: () => getWallet({ appId, walletId, holderId, hashWallet }),
    enabled: !!appId && !!walletId && !!holderId && !!hashWallet,
  });
}
