import { Holder } from "@/services/holder/types";
import { UpdateHolder } from "..";

export const handleData = ({ data, holder }: { data: UpdateHolder; holder?: Holder }) => {
  const { email, foto, socialName, telefone } = data;
  const {
    id,
    email: holderEmail,
    nome_social: nomeSocial,
    telefone: holderTelefone,
  } = holder as Holder;

  return {
    id_titular: id,
    ...(email !== holderEmail && { email }),
    ...(socialName && socialName !== nomeSocial && { nome_social: socialName }),
    ...(telefone !== holderTelefone && { telefone }),
    ...(foto && { foto }),
  };
};
