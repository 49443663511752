import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";

import { Button, Modal } from "@/components";
import { useAppId } from "@/store/useAppStore";
import { useDeleteComment } from "../hooks/useDeleteComment";

type ModalConfirmationDeleteCommentProps = {
  isOpen: boolean;
  onClose: () => void;
  comment: {
    id: number;
    content: string;
  };
};

export function ModalConfirmationDeleteComment({
  isOpen,
  onClose,
  comment,
}: ModalConfirmationDeleteCommentProps) {
  const { id, moduleId } = useParams<{ id: string; moduleId: string }>();
  const appId = useAppId();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useDeleteComment();

  function handleDeleteComment() {
    mutateAsync(
      {
        appId: Number(appId),
        postId: Number(id),
        id: comment.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["comments", Number(id), appId] });

          queryClient.invalidateQueries({
            queryKey: ["getGalleryModulosPhotosModulo", moduleId],
          });

          onClose();

          toast.success("Comentário excluído");
        },
      },
    );
  }

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-6">
        <div className="space-y-2 text-center">
          <Text weight="medium" size="md" color="slate-700">
            Excluir comentário
          </Text>

          <Text>
            Caso você deseje excluir o comentário{" "}
            <span className="font-medium text-slate-700">{`${comment.content}`}</span>, clique no
            botão <span className="font-medium">Confirmar exclusão</span> abaixo.
          </Text>
        </div>

        <footer className="flex flex-col gap-2">
          <Button type="button" size="lg" variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="button" size="lg" loading={isLoading} onClick={handleDeleteComment}>
            Confirmar exclusão
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
