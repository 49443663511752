import { ChangeEvent, useRef, useState } from "react";
import styled from "styled-components";
import { arrowUp, imagesOutline, close } from "ionicons/icons";
import {
  IonIcon,
  IonText,
  IonItem,
  IonLabel,
  IonButton,
  IonAlert,
  useIonToast,
} from "@ionic/react";

import { getContent } from "@/content/index";
import { isValidSizeInMB } from "@/utils/isValidSizeInMB";

const Container = styled.div`
  position: relative;
`;

const InputFile = styled.input`
  width: 0%;
  height: 0%;
  display: none;
  padding: 10px;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  border: solid red 1px;
  z-index: 100;
`;

const ImagePreview = styled.div`
  max-height: 200px;
  background: #000000;
  overflow: hidden;
`;

const UploadItem = styled(IonItem)`
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid var(--ion-color-step-300, #b3b3b3);
  --ion-item-background: #fff;
`;

const Image = styled.img`
  border-radius: 8px;
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

const RemoveButton = styled(IonButton)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 200;
`;

type uploadImageProps = {
  title?: string;
  placeHolderURL?: string;
  onChange?: ({ base64, file }: { base64: any; file: any }) => void;
  verifyMaxSize?: () => number;
};

export function NewUploadImage(props: uploadImageProps) {
  const CONTENT = getContent();

  const inputCamera = useRef<HTMLInputElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileMode, setFileMode] = useState(false);
  const [extensionError, setExtensionError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [image, setImage] = useState<any>("");
  const reader = new FileReader();

  const [present] = useIonToast();

  function cleanInputFile() {
    setExtensionError(false);
    clearImage();
  }

  async function onChangeHandle(e: ChangeEvent) {
    e.preventDefault();

    const files = fileMode ? inputFile.current?.files : inputCamera.current?.files;

    if (files?.length) {
      const file = files[0];

      const validMaxSizeAllFiles = isValidMaxSizeAllFiles(file.size);
      const validSize = isValidSizeInMB(file.size);
      const validFormat = isValidFormat(file.type);

      if (!validMaxSizeAllFiles || !validSize || !validFormat) {
        let message = "";

        if (!validMaxSizeAllFiles) {
          message = "Ultrapassa o tamanho máximo de arquivos (Max.: 40MB)";
        } else if (!validSize && !validFormat) {
          message = "Tamanho e formato de imagem inválido";
        } else if (!validSize) {
          message = "Tamanho de imagem inválido (Max.: 9MB)";
        } else {
          message = "Formato de imagem inválido";
        }

        setExtensionError(true);

        clearImage();

        await showToast({
          message,
          position: "bottom",
        });

        return false;
      }

      setExtensionError(false);

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (props.onChange) {
          props.onChange({
            base64: reader.result,
            file: file ?? null,
          });
        }

        setImage(reader.result);
      };
    }
  }

  function clearImage() {
    setImage(null);

    if (props.onChange) {
      props.onChange({
        base64: null,
        file: null,
      });
    }

    if (fileMode && inputFile.current) {
      inputFile.current.value = "";
    } else if (inputCamera.current) {
      inputCamera.current.value = "";
    }
  }

  function isValidMaxSizeAllFiles(size: number) {
    if (props.verifyMaxSize) {
      const currentSize = props.verifyMaxSize();

      const sizeInMB = Number(((currentSize + size) / 1048576).toFixed(2));

      return sizeInMB < 40;
    } else {
      return true;
    }
  }

  function isValidFormat(format: string) {
    const allowedExtensions = /(jpg|jpeg|png|gif)$/i;
    return allowedExtensions.test(format);
  }

  async function showToast({
    message,
    position,
  }: {
    message: string;
    position: "top" | "middle" | "bottom";
  }) {
    await present({
      message,
      duration: 2000,
      position,
    });
  }

  return (
    <Container>
      {image && (
        <ImagePreview>
          <Image src={image} alt="uploaded" />
        </ImagePreview>
      )}
      {!image && props.placeHolderURL && (
        <ImagePreview>
          <Image src={props.placeHolderURL} alt="" />
        </ImagePreview>
      )}

      <UploadItem
        mode="md"
        detailIcon="chevron-forward"
        lines="full"
        onClick={() => {
          if (!image) {
            setShowModal(true);
          }
        }}
      >
        <IonLabel>
          <b> {props.title} </b>
        </IonLabel>
        {extensionError && <IonText color="danger"> ✘ {CONTENT.IMAGE.FILE_FAIL} </IonText>}

        <IonIcon icon={image ? imagesOutline : arrowUp} size="small" slot="end" />
      </UploadItem>

      <InputFile onChange={onChangeHandle} ref={inputFile} type="file" accept="image/*" />

      <InputFile onChange={onChangeHandle} ref={inputCamera} type="file" accept="image/*" capture />

      {image && (
        <RemoveButton onClick={cleanInputFile} color="danger" size="small">
          <IonIcon slot="icon-only" icon={close} />
        </RemoveButton>
      )}

      <IonAlert
        isOpen={showModal}
        onDidDismiss={() => {
          setShowModal(false);
        }}
        header={CONTENT.GET_IMAGE.MESSAGE}
        buttons={[
          {
            text: CONTENT.GET_IMAGE.FILE,
            handler: () => {
              inputFile.current?.click();
              setFileMode(true);
            },
          },
          {
            text: CONTENT.GET_IMAGE.CAMERA,
            handler: () => {
              inputCamera.current?.click();
              setFileMode(false);
            },
          },
        ]}
      />
    </Container>
  );
}

NewUploadImage.defaultProps = {
  title: "Imagem",
};
