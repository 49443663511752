import { Button, Text } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";

import illustrationError from "@/assets/illustrations/error.svg";

interface ModalErrorProps {
  handleCloseModal: () => void;
}

export function ModalError({ handleCloseModal }: ModalErrorProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col gap-8 text-center">
        <img src={illustrationError} alt="Celular com ponto de exclamação no centro." />
        <Text asChild size="md" weight="medium" color="slate-700">
          <strong>{t("auth.Erro no login")}</strong>
        </Text>
      </header>

      <div className="mt-8 flex flex-col text-center">
        <Text>Tivemos um problema durante o seu login, espere um pouco e tente novamente.</Text>
      </div>

      <footer className="mt-12 w-full">
        <Button variant="primary" size="lg" rounded="md" full onClick={handleCloseModal}>
          {t("general.Tentar novamente")}
        </Button>
      </footer>
    </div>
  );
}
