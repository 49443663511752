const weekLong = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"];
const weekCut = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

interface getNameDayOfWeekParams {
  position: number;
  pattern?: "cut" | "long";
}

export function getNameDayOfWeek({ position, pattern = "cut" }: getNameDayOfWeekParams): string {
  return pattern === "cut" ? weekCut[position] : weekLong[position];
}
