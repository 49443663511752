import { http } from "@/lib/axios";

export type SchedulingCompletionParams = {
  appId: number;
  schedulingId: number;
  entityId: number;
  resourceId: number;
  localId: number;
  scheduleId: number;
  hourId: number;
};

export async function schedulingCompletion({
  appId,
  entityId,
  schedulingId,
  resourceId,
  localId,
  scheduleId,
  hourId,
}: SchedulingCompletionParams) {
  try {
    await http.post(`v3/${appId}/mobile/entidade_agendamento/${entityId}/agendamento_fila`, {
      cadastro_id: schedulingId,
      recurso_id: resourceId,
      local_id: localId,
      data_id: scheduleId,
      hora_id: hourId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
