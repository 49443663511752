import { Box, Text } from "@astrolabe-ui/react";
import { IonSkeletonText } from "@ionic/react";

interface CardNewsProps {
  title: string;
  categories: Array<{ nome: string; id: number }>;
  category: string;
  imageUrl: string;
  time: string;
  like?: boolean;
  handleOnClick: () => void;
}

export function CardNews({
  title,
  categories,
  category,
  imageUrl,
  time,
  handleOnClick,
}: CardNewsProps) {
  return (
    <Box
      asChild
      px={3}
      py={3}
      className="flex flex-col gap-2 border-thin hover:cursor-pointer"
      onClick={handleOnClick}
    >
      <article>
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-1 overflow-hidden">
            <Text size="xs" className="first-letter:uppercase">
              {time}
            </Text>
            <Text weight="medium" color="slate-700" className="line-clamp-3">
              {title}
            </Text>
          </div>

          <figure
            role="presentation"
            className="aspect-[13/9] w-[120px] shrink-0 overflow-hidden rounded-xs"
          >
            <img src={imageUrl} alt="" className="h-full w-full object-cover" />
          </figure>
        </div>

        {categories.length ? (
          <footer className="flex items-center gap-1">
            {categories.slice(0, 2).map((item) => (
              <span
                key={item.id}
                className="w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500"
              >
                {item.nome}
              </span>
            ))}
            {categories.length > 2 && (
              <span className="w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500">
                {categories.length - 2 > 0 ? ` + ${categories.length - 2}` : null}
              </span>
            )}
          </footer>
        ) : (
          <footer className="flex items-center gap-1">
            <span className="w-fit rounded-xs bg-primary-50 px-3 py-1 font-sans text-xs/normal font-medium text-primary-500">
              {category}
            </span>
          </footer>
        )}
      </article>
    </Box>
  );
}

export function CardNewsSkeleton() {
  return (
    <article className="flex flex-col gap-2 px-2 pt-3">
      <div className="flex justify-between gap-4">
        <div className="flex w-full flex-col justify-between overflow-hidden">
          <IonSkeletonText animated className="w-16" />
          <div className="flex w-full flex-col gap-1">
            <IonSkeletonText animated className="w-4/5" />
            <IonSkeletonText animated className="w-3/5" />
            <IonSkeletonText animated className="w-1/3" />
          </div>
        </div>

        <IonSkeletonText animated className="h-20 w-32 shrink-0" />
      </div>

      <footer className="flex items-center justify-between">
        <IonSkeletonText animated className="w-20 leading-normal" />
        <IonSkeletonText animated className="h-8 w-8" />
      </footer>
    </article>
  );
}
