import { useState } from "react";
import { useHistory } from "react-router";

import IconValidate from "@/assets/images/icon-validate.svg";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { ValidateData } from "./ValidateData";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

import { Container, Title, Text, Infos, Buttons, Button, Link } from "./styles";

export function BindHolder() {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const history = useHistory();
  const CONTENT = getContent();

  function startValidation() {
    setIsOpenModal(true);
  }

  function goBack() {
    history.push(`/${getAppSlug()}`);
  }

  return (
    <PageTemplate name={CONTENT.VINCULAR_TITULAR.TITLE} backgroundColor="#ffffff">
      <Container>
        <Infos>
          <img src={IconValidate} alt="" />
          <Title>{CONTENT.VINCULAR_TITULAR.VALIDATE}</Title>

          <Text>{CONTENT.VINCULAR_TITULAR.COMPLETE}</Text>
          <Text>{CONTENT.VINCULAR_TITULAR.CHECKOUT}</Text>
        </Infos>
        <Buttons>
          <Button onClick={startValidation}>{CONTENT.VINCULAR_TITULAR.START}</Button>
          <Link onClick={goBack}>{CONTENT.VINCULAR_TITULAR.RETURN}</Link>
        </Buttons>

        <ValidateData isOpen={isOpenModal} onClose={setIsOpenModal} />
      </Container>
    </PageTemplate>
  );
}
