import { Link, NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { IonPage } from "@ionic/react";
import { CaretLeft, ChartBar } from "@phosphor-icons/react";

import { Container, Cards, BlockText, Faq } from "./styles";

import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { MainContent } from "@/templates/old/ppa/MainContent";
import { Accordion } from "../../components/accordion/index";
// import { useUserToken } from "@/store/useAuthStore";

import logoOPRN from "@/assets/images/logo-oprn.svg";
import logoPPA from "@/assets/images/logo-ppa.png";
import { openFileInBrowser } from "@/utils/openFileInBrowser";

export function OPRN() {
  const { SLUG } = useParams<{ SLUG: string }>();
  const history = useHistory();

  // const [showToast] = useIonToast();

  // const token = useUserToken();

  // function handleOpen(topRated?: boolean) {
  //   if (!token) {
  //     showToast({
  //       message: "Realize primeiramente o login para responder a consulta",
  //       duration: 5000,
  //     });

  //     history.push(`/${SLUG}/auth`);
  //   } else {
  //     history.push(`/${SLUG}/consultas/6/propostas${topRated ? "?order=topRated" : ""}`);
  //   }
  // }

  function handleOpenResults() {
    history.push(`/${SLUG}/consultas/6/resultados`);
  }

  function handleGoBack() {
    history.replace(`/${SLUG}/`);
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoBack} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="OPP RN" className="w-40" />
        </NavLink>
      </Header>

      <MainContent>
        <Container>
          <img src={logoOPRN} alt="PPA RN" />

          <Cards>
            <h2>Participe do OP/RN</h2>

            <div className="flex w-full flex-col justify-center gap-3 lg:flex-row">
              <button
                className="flex flex-col items-center gap-1.5 rounded-md bg-primary-500 px-4 py-3"
                onClick={() => handleOpenResults()}
              >
                <ChartBar size={32} className="text-white" />

                <div className="flex flex-col gap-0.5">
                  <strong className="text-md font-semibold text-white">
                    Propostas Selecionadas
                  </strong>
                  <span className="text-xs text-white">
                    Confira as propostas escolhidas por território no OP/RN 2024
                  </span>
                </div>
              </button>

              {/* <button
                className="flex flex-col items-center gap-1.5 rounded-md bg-primary-500 px-4 py-3"
                onClick={() => handleOpen(true)}
              >
                <ChartBar size={32} className="text-white" />

                <div className="flex flex-col gap-0.5">
                  <strong className="text-md font-semibold text-white">
                    Listagem Geral das Propostas
                  </strong>
                  <span className="text-xs text-white">
                    Confira todas as propostas submetidas para votação no OP/RN 2024
                  </span>
                </div>
              </button> */}
            </div>
          </Cards>

          <BlockText>
            <strong>O que é o Orçamento Participativo - OP/RN?</strong>
            <p>
              O Orçamento Participativo do Rio Grande do Norte (OP/RN) é um processo pelo qual a
              população decide, de forma direta, a aplicação dos recursos em obras e serviços que
              serão executados pelo Governo do Estado do Rio Grande do Norte. Inicia-se com as
              reuniões preparatórias, quando o Poder Executivo Estadual presta contas do exercício
              passado e apresenta o Plano de Investimentos e Serviços (PI) para o ano seguinte. Os
              órgãos e entidades estaduais acompanham estas reuniões, prestando esclarecimentos
              sobre os critérios que norteiam o processo e a viabilidade das demandas. Na estrutura
              do Governo do Estado do Rio Grande do Norte o OP/RN está sob a coordenação da
              Secretaria de Estado do Planejamento, do Orçamento e Gestão (SEPLAN), com o suporte do
              Gabinete Civil da Governadora do Estado (GAC).
            </p>

            <span className="text-md text-slate-400">
              <button
                className="text-[#0b49b7] underline"
                onClick={() =>
                  openFileInBrowser(
                    "https://dados-suporte.s3.sa-east-1.amazonaws.com/DOC000000000336566.pdf",
                  )
                }
              >
                Clique aqui
              </button>{" "}
              para acessar o Decreto que regulamenta o OP/RN.
            </span>
          </BlockText>

          <BlockText>
            <strong>Por que sua contribuição é importante?</strong>
            <p>
              A contribuição na elaboração do Plano Plurianual em 2023 é importante para ajudar na
              construção de um futuro com garantia dos direitos públicos. Por meio da sua
              participação, os Governos Federal e Estadual poderão avançar na formação de políticas
              públicas eficientes e eficazes visando o desenvolvimento da sua região.
            </p>
          </BlockText>

          <BlockText>
            <strong>Como funciona o Orçamento Participativo?</strong>
            <p>
              Nas plenárias regionais, que serão realizadas em 5 territórios e pelo aplicativo “RN
              Participativo” , a população elege as prioridades de investimentos, seus conselheiros,
              e define o número de delegados da cidade para os seus respectivos fóruns regionais e
              grupos de discussões temáticas. As plenárias presenciais do OP/RN estarão acontecendo
              durante o <strong>RN Com a Gente</strong>,{" "}
              <Link to={`/${SLUG}/rn-com-a-gente`} className="text-[#0b49b7] underline">
                clique aqui
              </Link>{" "}
              para saber mais sobre.
            </p>
          </BlockText>

          <Faq>
            <strong>Conheça mais sobre o OP/RN</strong>

            <div>
              <Accordion
                title="O que é o Conselho do OP/RN"
                content="
                <p>
                O Conselho do Orçamento Participativo do Rio Grande do Norte (COP/RN) é um espaço de participação popular destinado a discussões acerca da realidade local e das ações desenvolvidas pelo Governo do Estado nos Territórios da Cidadania e é composto por conselheiros/as regionais e conselheiros/as estaduais, democraticamente eleitos/as, em votação aberta, nas plenárias Microrregionais e Regionais, respectivamente.
                </p>
                "
              />

              <Accordion
                title="Como se dá o processo de execução do Orçamento Participativo do Rio Grande do Norte (OP/RN)?"
                content="
                <p>
               	O Orçamento Participativo do Rio Grande do Norte (OP/RN) ocorrerá com periodicidade anual e é composto por 5 (cinco) etapas, a saber:
                <br /><br />
                1) Reunião de Sensibilização e Mobilização - etapa onde o poder público apresenta o cronograma anual de execução do OP/RN e sensibiliza a população a participar do processo;
                <br /><br />
                2) Plenárias Regionais Deliberativas - consiste no processo de eleição (anual) dos (as) Delegados (as) representantes de cada um dos Territórios da Cidadania, assim como de deliberação acerca das ações a serem priorizadas pela administração pública Estadual no ano subsequente;
                <br /><br />
                3) Fóruns de Delegados - momento em que, com base nas ações eleitas nas Plenárias Regionais Deliberativas, os (as) Delegados (as) do OP/RN definem, de forma regionalizada e quantificada física e financeiramente, as obras e/ou serviços prioritários a serem executados no ano subsequente. Ou seja, trata-se do refinamento/detalhamento das ações priorizadas pelos (as) Delegados (as) eleitos (as) por Território;
                <br /><br />
                4) Conferência Estadual do Orçamento Participativo - consiste no momento de apresentação das prioridades eleitas pela população potiguar, ou seja, é a consolidação de todo o processo;
                <br /><br />
                5) Reuniões de Monitoramento e Avaliação - após a realização de todas as etapas especificadas anteriormente, no restante do ano, o Governo do Estado do Rio Grande do Norte realiza reuniões periódicas com os (as) Delegados (as) eleitos (as) visando o monitoramento e a avaliação do processo e do andamento das obras e/ou dos serviços demandados pela população."
              />

              <Accordion
                title="Por que é importante participar do OP/RN?"
                content="
                <p>
                O Orçamento Participativo do Rio Grande do Norte (OP/RN) também é um espaço de promoção e educação para a cidadania, onde a população exercita suas formas de organização da sociedade e consolida o seu protagonismo nos rumos a serem tomados pela administração estadual, além de se capacitar, anualmente, no que se refere a importância e as possibilidades de fortalecimento do controle social sobre o ciclo das políticas públicas em âmbito estadual.
                <br /><br />
	              Além disso, a participação social, no sentido mais amplo de seu significado:
                <br />
                - Estabelece um pacto e compromisso sobre a governabilidade democrática;
                <br />
                - Cria uma responsabilidade coletiva nas decisões do governo local;
                <br />
                - Viabiliza maior eficiência, eficácia e efetividade na coprodução de políticas públicas; e, dentre outras possibilidades
                <br />
                - Permite o envolvimento de mais pessoas no processo de coprodução das políticas públicas locais, fixando um padrão de solução cooperativa para os conflitos, que são naturais dentro de um processo democrático.
                </p>"
              />
            </div>
          </Faq>
        </Container>
      </MainContent>
    </IonPage>
  );
}
