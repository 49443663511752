import { http } from "@/lib/axios";

type GetInfoMediaParams = {
  appId: number;
  id: number;
};

type GetInfoMediaResponse = {
  id: number;
  titulo: string;
  descricao: string;
  imagem: string;
  link_servico: string;
};

export async function getInfoMedia({ appId, id }: GetInfoMediaParams) {
  try {
    const response = await http.get<GetInfoMediaResponse>(`v2/${appId}/informacoes/${id}`);

    return {
      id: response.data.id,
      title: response.data.titulo,
      description: response.data.descricao,
      image: response.data.imagem,
      source: response.data.link_servico,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
