import { Link, useHistory, useParams } from "react-router-dom";
import { IonRippleEffect, IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton } from "@/components";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useChats } from "./hooks/useChats";

export function Chats() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data: chats, isLoading } = useChats({ appId, id: Number(id) });

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text
            size="md"
            weight="medium"
            leading="relaxed"
            className="line-clamp-1 text-textHeader"
          >
            Conversas
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 py-6 ios:pb-2">
          {isLoading && !chats
            ? Array.from(Array(5).keys()).map((index) => (
                <IonSkeletonText key={index} animated className="h-16 w-full" />
              ))
            : null}

          {!isLoading && chats?.length
            ? chats.map((item) => (
                <Link
                  key={item.id}
                  to={`/${slug}/comerciantes/${id}/conversas/${item.user.id}`}
                  className="ion-activatable ripple-parent flex w-full gap-2 px-4 py-3"
                >
                  {item.user.image ? (
                    <img
                      src={item.user.image}
                      alt=""
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : null}

                  <div className="flex w-full flex-col">
                    <div className="flex w-full items-center justify-between">
                      <Text weight="medium" color="slate-700">
                        {item.user.name}
                      </Text>
                      <Text size="xs">{item.time}</Text>
                    </div>

                    <Text>{item.message}</Text>
                  </div>

                  <IonRippleEffect className="text-slate-400" />
                </Link>
              ))
            : null}

          {!isLoading && !chats?.length ? (
            <div className="flex flex-col items-center gap-0.5 px-4 py-8 text-center">
              <Text weight="medium" color="slate-700">
                Não há conversas!
              </Text>
              <Text color="slate-400">Até o momento, nenhuma conversa foi iniciada com você.</Text>
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}
