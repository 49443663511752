import { IonIcon } from "@ionic/react";
import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  padding: 16px;
  color: #334155;
`;

export const CountProtocol = styled.div`
  padding: 0px 12px 12px;
  display: flex;
  align-items: center;
  color: #334155;
`;

export const Icon = styled(IonIcon)`
  font-size: 20px;
  color: #94a3b8;
  margin-right: 4px;
`;

export const NotContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #334155;
`;
