import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

import { Line } from "@/components/old/Base";
import { BlockItemImage } from "@/components/old/BlockItemImage";
import { PageTitle } from "@/components/old/SideMenu";
import { getAccessToken } from "@/services/old/auth";
import { getServicos } from "@/services/old/servicos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function addTokenUrl(url: string) {
  const token = getAccessToken();

  if (token) {
    return `${url}${url.includes("?") ? "&" : "?"}token=${token}`;
  }

  return url;
}

export function TabServiceList() {
  const CONTENT = getContent();

  const { isLoading, data } = useQuery(["getservicos"], () => getServicos(params.SLUG), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const params: paramsPage = useParams();

  if (isLoading || !data) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING}</PageTitle>;
  }

  if (!(data && data?.data.length)) {
    return (
      <>
        <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
      </>
    );
  }

  return (
    <>
      <IonGrid style={{ width: "100%", paddingBottom: "16px" }}>
        {data?.data
          .filter((area: any) => !!area.categorias.length)
          .map((area: any, index: number) => {
            return (
              <div key={index}>
                <Line>{area.titulo}</Line>

                <IonRow style={{ padding: "0 8px" }}>
                  {area.categorias?.map((categoriaItem: any, categoriaIndex: number) => {
                    return (
                      <IonCol size={area.categorias.length === 1 ? "12" : "6"} key={categoriaIndex}>
                        {categoriaItem.integracao && categoriaItem.abrir_externo && (
                          <BlockItemImage
                            openNewWindow={true}
                            imageUrl={categoriaItem.imagem}
                            title={categoriaItem.titulo}
                            href={
                              categoriaItem.adicionarToken
                                ? addTokenUrl(categoriaItem.integracao)
                                : categoriaItem.integracao
                            }
                          />
                        )}
                        {categoriaItem.integracao && !categoriaItem.abrir_externo && (
                          <BlockItemImage
                            routerLink={`/${getAppSlug()}/services-abrir-externo/${
                              categoriaItem.id
                            }`}
                            imageUrl={categoriaItem.imagem}
                            title={categoriaItem.titulo}
                          />
                        )}
                        {!categoriaItem.integracao && !categoriaItem.abrir_externo && (
                          <BlockItemImage
                            routerLink={`/${getAppSlug()}/services-abrir/${categoriaItem.id}`}
                            imageUrl={categoriaItem.imagem}
                            title={categoriaItem.titulo}
                          />
                        )}
                      </IonCol>
                    );
                  })}
                </IonRow>
              </div>
            );
          })}
      </IonGrid>
    </>
  );
}
