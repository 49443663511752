import { Capacitor } from "@capacitor/core";

import { http } from "@/lib/axios";
import { STORAGE } from "@/data/storage";

import { OneSignalLogin } from "./oneSginal";
import { getSellerByUserID } from "./feirinha";
import { getAppId } from "./provider";

export function getAccessToken() {
  const token = localStorage.getItem(STORAGE.AUTH_ACCESS_TOKEN.KEY);
  return token !== null && token.length > 0 ? token : "";
}

export function isLogged() {
  const token = localStorage.getItem(STORAGE.AUTH_ACCESS_TOKEN.KEY);
  return token !== null && token.length > 0;
}

export async function getNeighborhood(SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/${APP_ID}/bairros`);
}

export function getUserImage() {
  const userImg = localStorage.getItem(STORAGE.AUTH_USER_IMAGE.KEY);
  return userImg !== null && userImg !== "null" && userImg.length > 0 ? userImg : "";
}

export async function getUserData() {
  const slug = localStorage.getItem("APP_SLUG");

  let appId;

  if (slug) {
    appId = localStorage.getItem(`${slug}_ID_APP`);
  }

  const headers = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return http.get(`v2/${appId}/usuarios/perfil`, headers);
}

export async function updateUserData(formData: FormData) {
  const headers = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  if (formData) {
    formData.append("_method", "PUT");
  }

  const slug = localStorage.getItem("APP_SLUG");
  const appId = localStorage.getItem(`${slug}_ID_APP`);

  return http.post(`v2/${appId}/usuarios/perfil`, formData, headers);
}

function clearUserPerfilData() {
  localStorage.removeItem(STORAGE.AUTH_USER_EMAIL.KEY);
  localStorage.removeItem(STORAGE.AUTH_USER_IMAGE.KEY);
  localStorage.removeItem(STORAGE.AUTH_USER_NAME.KEY);
  localStorage.removeItem(STORAGE.AUTH_USER_ID.KEY);
  localStorage.removeItem(STORAGE.AUTH_USER_SELLER_ID.KEY);
}

export function logout() {
  localStorage.removeItem(STORAGE.AUTH_ACCESS_TOKEN.KEY);
  localStorage.removeItem(STORAGE.AUTH_REFRESH_TOKEN.KEY);
  clearUserPerfilData();
}

export async function loadUserData(SLUG: string) {
  if (isLogged()) {
    clearUserPerfilData();

    return getUserData()
      .then((res) => {
        localStorage.setItem(STORAGE.AUTH_USER_ID.KEY, res.data.id);
        localStorage.setItem(STORAGE.AUTH_USER_EMAIL.KEY, res.data.email);
        localStorage.setItem(STORAGE.AUTH_USER_IMAGE.KEY, res.data.imagem);
        localStorage.setItem(STORAGE.AUTH_USER_NAME.KEY, res.data.name);
        if (res.data.titulares)
          localStorage.setItem(STORAGE.AUTH_USER_HOLDER.KEY, JSON.stringify(res.data.titulares));

        getSellerByUserID(res.data.id, SLUG);

        if (Capacitor.isNativePlatform()) {
          OneSignalLogin(res.data.id + "");
        }

        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export function getUserEmail() {
  const email = localStorage.getItem(STORAGE.AUTH_USER_EMAIL.KEY);
  return email !== null && email.length > 0 ? email : "";
}

export function getUserName() {
  const email = localStorage.getItem(STORAGE.AUTH_USER_NAME.KEY);
  return email !== null && email.length > 0 ? email : "";
}

async function getLoginToken() {
  return http.get("tokens");
}

export async function login(
  username: string,
  password: string,
  SLUG: string,
  recaptch: string,
  cancel = false,
) {
  logout();

  const res = await getLoginToken();

  const loginToken = res.data.pessoal.token;
  const loginId = res.data.pessoal.id;

  const headers = {
    grant_type: "password",
    client_id: loginId,
    client_secret: loginToken,
    frontend_id: await getAppId(SLUG),
    username,
    password,
    captcha_response: recaptch,
    cancelar: cancel,
  };

  const appId = localStorage.getItem(`${SLUG}_ID_APP`);

  const resLogin = await http.post(`v2/${appId}/usuarios/login`, headers);

  if (resLogin.status && resLogin.status >= 200 && resLogin.status < 300) {
    localStorage.setItem(STORAGE.AUTH_USER_EMAIL.KEY, username);
    localStorage.setItem(STORAGE.AUTH_ACCESS_TOKEN.KEY, resLogin.data.access_token);
    localStorage.setItem(STORAGE.AUTH_REFRESH_TOKEN.KEY, resLogin.data.refresh_token);

    return resLogin.data;
  }

  await loadUserData(SLUG);
}

interface ILoginV3Params {
  slug: string;
  username: string;
  password: string;
  captchaResponse: string;
  cancel: boolean;
}

interface ILoginV3Response {
  access_token: string;
  user_profile: {
    id: number;
    name: string;
    email: string;
    cpf: string | null;
    imagem: string | null;
    fone: number | null;
    bairro: number | null;
    email_verified_at: string | null;
    link_termo_uso: string | null;
    versao_termo_uso: string | null;
    titulares: Array<{
      id: number;
      nome: string;
      email: string | null;
      cpf: string;
      foto: string | null;
      telefone: string | null;
      data_nascimento: string;
    }>;
    adresses: Array<{
      title: string;
      address: string;
      neighborhood: string;
      city: string;
      uf: string;
      postcode: string;
      street: string;
      number: string;
    }>;
    avaliacao?: {
      rating: number;
      comment: string;
    };
  };
}

export async function loginV3({
  slug,
  username,
  password,
  captchaResponse,
  cancel,
}: ILoginV3Params) {
  try {
    logout();

    const appId = await getAppId(slug);

    const response = await http.post<ILoginV3Response>(`/v3/${appId}/usuarios/login`, {
      username,
      password,
      captchaResponse,
      cancelar: cancel ? 1 : undefined,
    });

    loadUserData(slug);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function getUserID() {
  const id = localStorage.getItem(STORAGE.AUTH_USER_ID.KEY);
  return id !== null && id.length > 0 ? id : "";
}

export async function cancelarConta(password: string, SLUG: string, userId: any) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const form = new FormData();

  const appId = await getAppId(SLUG);

  if (!appId) {
    return;
  }

  form.append("frontend_id", appId);
  form.append("password", password);
  form.append("user_id", userId);

  return http.post("confirmar_senha", form, headers);
}

export async function retriverPassword(email: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http
    .get(`v2/${APP_ID}/recuperar_senha/disparar_email/${email}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export async function updateUserImage(formData: FormData) {
  const headers = {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  if (formData) {
    formData.append("_method", "PUT");
  }

  return http.post("usuarios/perfil/alterarImagem", formData, headers);
}

export async function setNewPassword(formData: any, SLUG: string) {
  const headers = {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "content-type": "multipart/form-data",
      _method: "PUT",
    },
  };

  const APP_ID = await getAppId(SLUG);

  formData.append("_method", "PUT");

  return http
    .post(`v2/${APP_ID}/usuarios/perfil/alterar_senha`, formData, headers)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export async function registUser(formData: any, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const appId = localStorage.getItem(`${SLUG}_ID_APP`);

  return http
    .post(`v2/${appId}/usuarios`, formData, headers)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export async function confirmToken(formData: any, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .post(`v2/${APP_ID}/recuperar_senha/validar_token`, formData, headers)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export async function changePassword(formData: any, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .post(`v2/${APP_ID}/recuperar_senha/alterar_senha`, formData, headers)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}
