import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Text } from "@astrolabe-ui/react";
import { X } from "@phosphor-icons/react";

import { useStepsContext } from "@/pages/AssetsScheduling/contexts/StepsContext";

import illustrationError from "@/assets/illustrations/error.svg";
import { useAppStore } from "@/store/useAppStore";

export const Error = ({
  setIsOpen,
  error,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  error: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const slug = useAppStore((state) => state.app?.slug);

  const { handleGoTo } = useStepsContext();

  return (
    <div className="flex grow flex-col items-center justify-between gap-2 p-4">
      <div className="flex justify-end self-end">
        <IconButton
          variant="outlined"
          icon={<X className="text-primary" />}
          onClick={() => {
            setIsOpen(false);
            history.replace(`/${slug}`);
          }}
          rounded="full"
        />
      </div>

      <div className="flex grow flex-col items-center justify-center">
        <img
          src={illustrationError}
          alt="Blocos com ícones de check."
          className="mb-14 max-w-[80%]"
        />

        <div className="flex flex-col gap-7">
          <Text weight="medium" size="md" color="slate-700" className="text-center">
            {t("error.Problemas ao realizar a reserva")}
          </Text>
          <Text className="text-center">{error}</Text>
        </div>
      </div>

      <Button
        full
        size="lg"
        variant="tertiary"
        onClick={() => {
          setIsOpen(false);
          history.replace(`/${slug}`);
        }}
      >
        {t("general.Retornar a página inicial")}
      </Button>

      <Button
        full
        size="lg"
        onClick={() => {
          handleGoTo && handleGoTo(0);
          setIsOpen(false);
        }}
      >
        {t("general.Tentar novamente")}
      </Button>
    </div>
  );
};
