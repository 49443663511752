import { useQuery } from "@tanstack/react-query";
import { getMessages } from "@/services/protocols";

interface useMessagesParams {
  appId: number;
  protocolId: number;
}

export function useMessages({ appId, protocolId }: useMessagesParams) {
  return useQuery({
    queryKey: ["protocol", "message", protocolId],
    queryFn: () => getMessages({ appId, protocolId }),
    enabled: !!appId,
  });
}
