import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { CardItem, CardItemSkeleton } from "@/pages/schedulings/components/card-item";
import { useAppId } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useResources } from "../hooks/use-resources";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

const selectResourceFormSchema = z.object({
  resource: z.number(),
});

type selectResourceFormData = z.infer<typeof selectResourceFormSchema>;

export function SelectResource() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [{ resource }, updateCreateSchedulingData] = useAtom(createSchedulingDataAtom);

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useResources({ appId, entityId: id });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<selectResourceFormData>({
    resolver: zodResolver(selectResourceFormSchema),
    defaultValues: {
      resource: resource?.id,
    },
  });

  function onSubmit({ resource: resourceId }: selectResourceFormData) {
    const resource = data?.find((item) => item.id === resourceId);

    if (resource) {
      updateCreateSchedulingData((current) => ({
        ...current,
        resource,
        local: null,
        schedule: null,
        hour: null,
        questions: [],
      }));

      if (resource.enabledQueue) {
        handleChangeStep("queue");
        return;
      }

      if (resource.questions.length) {
        handleChangeStep("questions", true);
        return;
      }

      handleChangeStep("personal");
    }
  }

  function handleChangeStep(newStep: string, question?: boolean) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    if (question) {
      searchParams.append("question", "0");
    }

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  return (
    <div className="flex flex-1 flex-col gap-5 px-4 py-6 ios:pb-2">
      <div className="space-y-1">
        <Text
          asChild
          size="lg"
          weight="medium"
          color="primary-500"
          className="text-[24px] leading-[28px]"
        >
          <h1>Selecione o serviço para atendimento</h1>
        </Text>
        <Text size="xs">Selecione qual o serviço você deseja realizar o agendamento.</Text>
      </div>

      <form
        id="select-resource"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-4"
      >
        {isLoading && !data ? <ContentLoading /> : null}

        {!isLoading && !data?.length ? <ContentEmpty /> : null}

        {!isLoading && data?.length
          ? data.map((item) => (
              <Controller
                key={item.id}
                control={control}
                name="resource"
                render={({ field: { value, onChange } }) => (
                  <CardItem
                    type="button"
                    title={item.name}
                    data-selected={value === item.id}
                    onClick={() => onChange(item.id)}
                  />
                )}
              />
            ))
          : null}
      </form>

      <Button
        type="submit"
        form="select-resource"
        disabled={!isValid}
        full
        size="lg"
        className="mt-auto"
      >
        {t("general.Continuar")}
      </Button>
    </div>
  );
}

function ContentLoading() {
  return Array.from(Array(5).keys()).map((index) => <CardItemSkeleton key={index} />);
}

function ContentEmpty() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
      <Text weight="medium" color="slate-700">
        Não há serviços disponíveis!
      </Text>
      <Text>
        No momento não existe nenhum serviço disponível para ser realizado um agendamento.
      </Text>
    </div>
  );
}
