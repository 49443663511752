import { IonSkeletonText } from "@ionic/react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Article, Plus } from "@phosphor-icons/react";

import { Button, Container, Content, List, NotFound } from "./styles";

import { Ordination } from "../ordination/ordination";
import { Proposal, ProposalSkeleton } from "../proposal/proposal";
import { Proposal as ProposalType } from "../../hooks/use-proposals";

import notFoundImg from "@/assets/images/not-found.jpg";

interface ListProposalsProps {
  total?: number;
  proposals: Array<ProposalType>;
  blockedVote: boolean;
  hasSuggest: boolean;
  blockedSuggest: boolean;
  isExpired?: boolean;
}

export function ListProposals({
  total,
  proposals,
  hasSuggest,
  blockedVote,
  blockedSuggest,
  isExpired,
}: ListProposalsProps) {
  const location = useLocation();

  const initalOrdination = new URLSearchParams(location.search).get("order");

  return (
    <Container>
      {!proposals.length || !total ? (
        <NotFoundContent blockedSuggest={blockedSuggest} />
      ) : (
        <Content>
          <div className="flex flex-col items-start justify-between gap-3 lg:flex-row lg:items-center">
            <div className="flex items-center gap-2">
              <Article size={24} color="#94A3B8" />
              <strong className="text-md font-medium leading-normal text-slate-700">
                {`${total} ${total === 1 ? "programa" : "programas"}`}
              </strong>
            </div>

            <div className="flex items-center gap-3">
              {hasSuggest ? <ButtonSuggest blockedSuggest={blockedSuggest} /> : null}
              <Ordination initialOrdination={initalOrdination} />
            </div>
          </div>

          <List>
            {proposals.map((item) => (
              <Proposal
                key={item.id}
                isExpired={isExpired}
                id={item.id}
                title={item.description}
                creator={item.username}
                voters={item.countVoters}
                hasVoter={item.hasUserVoted}
                blocked={!item.hasUserVoted && blockedVote}
                themes={item.themes}
                tags={item.tags}
              />
            ))}
          </List>
        </Content>
      )}
    </Container>
  );
}

export function ListProposalsSkeleton() {
  return (
    <>
      <div className="flex items-center gap-2">
        <IonSkeletonText animated style={{ width: 24, height: 24 }} />
        <IonSkeletonText animated style={{ width: 120, height: 24 }} />
      </div>

      {Array.from(Array(3).keys()).map((item) => (
        <ProposalSkeleton key={item} />
      ))}
    </>
  );
}

interface NotFoundContentProps {
  blockedSuggest: boolean;
}

export function NotFoundContent({ blockedSuggest }: NotFoundContentProps) {
  return (
    <NotFound>
      <img src={notFoundImg} alt="Jovens com placas em protesto" />

      <div>
        <span>
          Não há programas cadastrados, venha participar da construção de um estado melhor!
        </span>

        <ButtonSuggest blockedSuggest={blockedSuggest} />
      </div>
    </NotFound>
  );
}

interface ButtonSuggestProps {
  blockedSuggest: boolean;
}

function ButtonSuggest({ blockedSuggest }: ButtonSuggestProps) {
  const { SLUG, id } = useParams<{ SLUG: string; id: string }>();

  if (blockedSuggest) {
    return <Button disabled>Limite atingido</Button>;
  }

  return (
    <NavLink to={`/${SLUG}/consultas/${id}/sugestao`}>
      <Button>
        Fazer sugestão
        <Plus size={16} weight="bold" color="#fff" />
      </Button>
    </NavLink>
  );
}
