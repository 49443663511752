import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import {
  CameraPreview,
  CameraPreviewOptions,
  CameraPreviewPictureOptions,
} from "@capacitor-community/camera-preview";
import { IonButton, IonLoading, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";

import { getS3Aws } from "@/services/old/feed";
import { COLORS } from "@/data/colors";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { getContent } from "@/content/index";

interface CameraPreviewContentProps {
  height: string;
  width: string;
}

const Layout = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LayoutContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: ${COLORS.BACKGROUND_COLOR};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CameraPreviewWrapper = styled.div<CameraPreviewContentProps>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const CameraPreviewContent = styled.div<CameraPreviewContentProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  object-fit: cover;
`;

const OverlayImage = styled.img<CameraPreviewContentProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const OverlaysList = styled.div`
  width: 100vw;
  text-align: center;
  background: ${COLORS.PRIMARY};
`;

const ImgOverlay = styled.img`
  height: auto;
  width: 20vw;
  max-width: 90px;
  border: 1px solid gray;
  padding: 5px;
  margin: 5px;
  margin-bottom: 0px;
  border-radius: 10%;
  background: ${COLORS.LIGHT};
`;

const Footer = styled.footer`
  flex: 1;
  width: 100%;
  background-color: ${COLORS.BACKGROUND_COLOR};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonShot = styled(IonButton)`
  background-color: red;
  border: 8px solid white;
  border-radius: 40px;
  height: 80px;
  width: 80px;
  --background: transparent;
  --box-shadow: none;
`;

interface CameraObjProps {
  OverlayImage: string;
  onPictureSelected: (base64: any) => void;
  imageSelected?: boolean;
  ListOverlayImages: any;
}

export function CameraObj(props: CameraObjProps) {
  const CONTENT = getContent();

  const { height, width } = useWindowDimensions();

  const cameraSize =
    (height > width ? width : height) > 800 ? 800 : height > width ? width : height;

  const [res, setRes] = useState<string>();
  const layoutRef = useRef<HTMLDivElement | null>(null);

  const statePreview = 1;
  const stateResult = 2;

  const [state, setState] = useState(statePreview);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  const [isCameraActive, setIsCameraActive] = useState(false);
  const [overlayImg, setOverlayImg] = useState("");

  const openCamera = useCallback(async () => {
    try {
      if (!isCameraActive) {
        setIsCameraActive(true);

        setState(statePreview);

        const y = layoutRef.current?.getBoundingClientRect().y;

        const cameraPreviewOptions: CameraPreviewOptions = {
          parent: "cameraPreview",
          className: "cameraPreview",
          height: cameraSize,
          width: cameraSize,
          y,
          toBack: true,
          lockAndroidOrientation: true,
          disableAudio: true,
        };

        await CameraPreview.start(cameraPreviewOptions);
      }
    } catch (err) {
      console.error(err);
      setIsCameraActive(false);
    }
  }, [isCameraActive, cameraSize]);

  const stopCamera = useCallback(async () => {
    try {
      if (isCameraActive) {
        setIsCameraActive(false);

        await CameraPreview.stop();
      }
    } catch (error) {
      console.error(error);
    }
  }, [isCameraActive]);

  useIonViewWillEnter(async () => {
    setOverlayImg(props.OverlayImage);
    if (!isCameraActive && layoutRef.current) {
      await openCamera();
    }
  }, [openCamera]);

  useIonViewDidLeave(async () => {
    if (isCameraActive) {
      await stopCamera();
    }
  }, [isCameraActive, stopCamera]);

  function changeOverlay(overlayUrl: any) {
    setOverlayImg(overlayUrl);
  }

  function takePicture() {
    const cameraSampleOptions: CameraPreviewPictureOptions = {
      quality: 100,
    };

    CameraPreview.capture(cameraSampleOptions)
      .then(async (res: any) => {
        setState(stateResult);
        setShowLoadingDialog(true);
        await stopCamera();

        const picture = `data:image/jpeg;base64,${res.value}`;
        const canvas: HTMLCanvasElement = document.createElement("canvas");

        const image = new Image();
        image.src = picture;

        image.onload = () => {
          canvas.width = 1200;
          canvas.height = 1200;

          const ctx = canvas.getContext("2d");

          // if (Capacitor.getPlatform() === "android") {
          //   ctx?.clearRect(0, 0, canvas.width, canvas.height);

          //   ctx?.save();

          //   ctx?.translate(canvas.width / 2, canvas.height / 2);

          //   const degrees = Capacitor.getPlatform() === "android" ? 270 : 0;

          //   ctx?.rotate((degrees * Math.PI) / 180);

          //   ctx?.drawImage(
          //     image,
          //     -canvas.width / 2,
          //     -canvas.height / 2,
          //     canvas.width,
          //     canvas.height,
          //   );

          //   ctx?.restore();
          // } else {
          //   const multiplier = 1200 / image.width;
          //   const move = (-1 * (image.height * multiplier - 1200)) / 2;

          //   ctx?.drawImage(
          //     image,
          //     0,
          //     0,
          //     image.width,
          //     image.height,
          //     0,
          //     move,
          //     1200,
          //     image.height * multiplier,
          //   );
          // }

          const multiplier = 1200 / image.width;
          const move = (-1 * (image.height * multiplier - 1200)) / 2;

          ctx?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            move,
            1200,
            image.height * multiplier,
          );

          getS3Aws(overlayImg)
            .then(async (res) => {
              const url = `data:image/jpeg;base64,${res.data}`;
              const response = await fetch(url);
              const blob = await response.blob();
              readFileCorrect(canvas, blob);
            })
            .catch((err) => {
              console.log("Erro ao buscar moldura", err);
              setShowLoadingDialog(false);
            });
        };
      })
      .catch((err) => {
        console.error("Erro ao capturar imagem", err);
      });
  }

  function readFileCorrect(canvas: any, dataaws: any) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(dataaws);

      reader.onload = () => {
        exportCanvas(reader, canvas);
      };
      reader.onerror = () => {
        readFileCorrect(canvas, dataaws);
      };
      reader.onabort = () => {
        readFileCorrect(canvas, dataaws);
      };
    } catch (error) {
      console.log(error + "");
    }
  }

  function exportCanvas(reader: any, canvas: any) {
    try {
      const base64 = reader.result;

      const molduraCanva = new Image();

      molduraCanva.src = base64;

      molduraCanva.onload = () => {
        canvas.getContext("2d").drawImage(molduraCanva, 0, 0);

        const r: string = canvas.toDataURL();
        setRes(r);
        setShowLoadingDialog(false);
      };

      molduraCanva.onerror = () => {
        exportCanvas(reader, canvas);
      };

      molduraCanva.onabort = () => {
        exportCanvas(reader, canvas);
      };
    } catch (error) {
      console.error(error);
    }
  }

  function selectImage() {
    props.onPictureSelected(res);
  }

  return (
    <Layout ref={layoutRef}>
      {state === statePreview ? (
        <>
          <CameraPreviewWrapper height={`${cameraSize}px`} width={`${cameraSize}px`}>
            <CameraPreviewContent
              id="cameraPreview"
              height={`${cameraSize}px`}
              width={`${cameraSize}px`}
            />

            <OverlayImage src={overlayImg} height={`${cameraSize}px`} width={`${cameraSize}px`} />
          </CameraPreviewWrapper>
          <OverlaysList>
            {props.ListOverlayImages.length > 1 &&
              props.ListOverlayImages.map((overlay: any, index: number) => {
                return (
                  <ImgOverlay
                    onClick={() => changeOverlay(overlay.imagem)}
                    src={overlay.imagem}
                    key={index}
                  />
                );
              })}
          </OverlaysList>

          <Footer>
            <ButtonShot onClick={takePicture}></ButtonShot>
          </Footer>
        </>
      ) : (
        <>
          <LayoutContent>
            <img alt="" src={res}></img>

            <div style={{ display: "flex" }}>
              <IonButton onClick={openCamera}>{CONTENT.CAMERA.TRY_AGAIN}</IonButton>

              <IonButton onClick={selectImage}>{CONTENT.CAMERA.USE_THIS}</IonButton>
            </div>
          </LayoutContent>

          <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
        </>
      )}
    </Layout>
  );
}
