import styled, { CSSProperties, css } from "styled-components";
import { darken, transparentize } from "polished";
import { IonText } from "@ionic/react";

import { COLORS } from "@/data/colors";

interface BlockContainerProps {
  marginTop?: string;
  maxWidth?: string;
  noShadown?: boolean;
}

const BlockContainer = styled.div<BlockContainerProps>`
  border-radius: 8px;
  overflow: hidden;
  border: solid white 2px;
  height: auto;
  width: 100%;
  box-shadow: 0px 1px 2px ${transparentize(0.85, darken(0.4, COLORS.PRIMARY))};
  background-color: ${COLORS.LIGHT};
  position: relative;
  margin: 0 auto;

  ${(props) =>
    props.noShadown &&
    css`
      box-shadow: 0px 0px 0px;
    `}
  ${(props) => css`
    max-width: ${props.maxWidth};
  `}
  ${(props) => css`
    margin-top: ${props.marginTop};
  `}
`;

interface BlockContentProps {
  noPadding?: boolean;
}

const BlockContent = styled.div<BlockContentProps>`
  padding: 10px;
  height: auto;

  @media (max-width: 700px) {
    padding: 1opx;

    ${(props) =>
      props.noPadding &&
      css`
        padding: 0px;
      `}
  }

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0px;
    `}
`;

export const BlockTitle = styled(IonText)`
  border-bottom: solid ${COLORS.BORDER} 1px;
  width: 100%;
  margin: 0;
  display: block;
  font-weight: 600;
  padding: 12px 15px 12px 15px;
`;

interface BlockProps {
  noPadding?: boolean;
  title?: string;
  children: any;
  maxWidth?: string;
  noShadown?: boolean;
  marginTop?: string;
  className?: string;
  style?: CSSProperties;
  hiddenBlock?: boolean;
}

export function Block({
  noPadding,
  title,
  children,
  maxWidth,
  noShadown,
  marginTop,
  ...rest
}: BlockProps) {
  return (
    <>
      {rest.hiddenBlock ? (
        <>{children}</>
      ) : (
        <BlockContainer
          maxWidth={maxWidth}
          noShadown={noShadown}
          marginTop={marginTop || "0px"}
          {...rest}
        >
          {title && <BlockTitle color="dark"> {title} </BlockTitle>}
          <BlockContent noPadding={noPadding}>{children}</BlockContent>
        </BlockContainer>
      )}
    </>
  );
}
