import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtomValue } from "jotai";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { useSearchParams } from "@/hooks/useSearchParams";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";

import queueDataIllustration from "@/assets/illustrations/queue-data.svg";
import queueWatchingIllustration from "@/assets/illustrations/queue-watching.svg";
import queueImportantIllustration from "@/assets/illustrations/queue-important.svg";

export function DetailsQueue() {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const { resource } = useAtomValue(createSchedulingDataAtom);

  function handleContinue() {
    if (resource) {
      if (resource.questions.length) {
        handleChangeStep("questions", true);
        return;
      }

      handleChangeStep("personal");
    }
  }

  function handleChangeStep(newStep: string, question?: boolean) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    if (question) {
      searchParams.append("question", "0");
    }

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  return (
    <div className="flex flex-1 flex-col gap-5 px-4 pb-20 pt-6 ios:pb-2">
      <div className="flex flex-col items-center gap-5 rounded-md bg-slate-100 px-4 py-5 text-center">
        <img src={queueDataIllustration} alt="" />
        <Text color="slate-700">
          Após preencher seus dados, você será inserido na fila, que é contínua e vale por tempo
          indeterminado.
        </Text>
      </div>

      <div className="flex flex-col items-center gap-5 rounded-md bg-slate-100 px-4 py-5 text-center">
        <img src={queueWatchingIllustration} alt="" />
        <Text color="slate-700">
          Aguarde até ser notificado para escolher um horário disponível para o seu agendamento. As
          notificações são enviadas de acordo com a disponibilidade de vagas, sem um prazo
          específico, por meio de e-mail e notificação no seu aparelho celular.
        </Text>
      </div>

      <div className="flex flex-col items-center gap-5 rounded-md bg-slate-100 px-4 py-5 text-center">
        <img src={queueImportantIllustration} alt="" />
        <Text color="slate-700">
          <strong className="font-semibold">Importante:</strong> se não realizar o agendamento
          dentro do prazo estabelecido após ser notificado, você será removido automaticamente da
          fila e precisará reiniciar o processo.
        </Text>
      </div>

      <Button
        size="lg"
        className="fixed inset-x-0 bottom-4 mx-4 shadow-2xl"
        onClick={handleContinue}
      >
        {t("general.Continuar")}
      </Button>
    </div>
  );
}
