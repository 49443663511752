import { http } from "@/lib/axios";

type GetSchedulingQueueParams = {
  appId: number;
  id: string;
};

type GetSchedulingQueueResponse = {
  id: number;
  user_id: number | null;
  status: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;
  indeferido_texto: string | null;
  chamado_data: string | null;
  entidade_agendamento: {
    id: number;
  };
  recurso: {
    id: number;
    titulo: string;
    texto_finalizacao_html: string | null;
    texto_instrucoes: string | null;
    tempo_para_agendamento: number | null;
    chamado_data: string | null;
  };
};

export async function getSchedulingQueue({ appId, id }: GetSchedulingQueueParams) {
  try {
    const response = await http.get<GetSchedulingQueueResponse>(
      `v3/${appId}/mobile/show_cadastro/${id}`,
    );

    const data = response.data;

    return {
      id: data.id,
      userId: data.user_id,
      name: data.nome,
      cpf: data.cpf,
      email: data.email,
      phone: data.telefone,
      status: data.status,
      justificationForRejection: data.indeferido_texto,
      entityId: data.entidade_agendamento.id,
      calledDate: data.chamado_data,
      resource: {
        id: data.recurso.id,
        name: data.recurso.titulo,
        textFinalization: data.recurso.texto_finalizacao_html,
        timeForCompletion: data.recurso.tempo_para_agendamento,
        textInstructions: data.recurso.texto_instrucoes,
        calledDate: data.recurso.chamado_data,
      },
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
