import { Category, CategoryService } from "@/services/categoryServices";
import { removeAcentAndLowerCaseString } from "@/utils/filterQueryString";

export const sortAreaCategories = (list: CategoryService[] | Category[], order: "asc" | "desc") =>
  list.sort((current, next) => {
    if (
      order === "asc" &&
      removeAcentAndLowerCaseString(current.title) > removeAcentAndLowerCaseString(next.title)
    )
      return 1;
    if (
      order === "asc" &&
      removeAcentAndLowerCaseString(current.title) < removeAcentAndLowerCaseString(next.title)
    )
      return -1;
    if (
      order === "asc" &&
      removeAcentAndLowerCaseString(current.title) === removeAcentAndLowerCaseString(next.title)
    )
      return 0;
    if (
      order === "desc" &&
      removeAcentAndLowerCaseString(current.title) < removeAcentAndLowerCaseString(next.title)
    )
      return 1;
    if (
      order === "desc" &&
      removeAcentAndLowerCaseString(current.title) > removeAcentAndLowerCaseString(next.title)
    )
      return -1;
    return 0;
  });
