import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";

import warningCircle from "@/assets/illustrations/Warning-circle.svg";

interface ModalDataIncorretsProps {
  handleCloseModal: () => void;
}

export function ModalDataIncorrets({ handleCloseModal }: ModalDataIncorretsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <header className="flex flex-col items-center gap-8">
        <img src={warningCircle} alt="Celular com ponto de exclamação no centro." />
        <Text className="text-center" asChild size="md" weight="medium" color="slate-700">
          <strong>{t("auth.Erro no CPF / e-mail ou senha cadastrada")}</strong>
        </Text>
      </header>

      <div className="mt-8 flex flex-col text-center">
        <Text>{t("auth.Verifique suas informações e tente novamente.")}</Text>
      </div>

      <footer className="mt-12 flex w-full flex-col gap-3">
        <Button
          className="font-medium"
          variant="primary"
          size="lg"
          rounded="md"
          onClick={handleCloseModal}
        >
          {t("general.Tentar novamente")}
        </Button>
      </footer>
    </div>
  );
}
