import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CalendarBlank, CaretLeft, CheckCircle, WarningCircle } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Button, Header, HeaderButton } from "@/components";
import { CardInfo } from "../../components/card-info";
import { CloseToQueueModal } from "./components/close-to-queue-modal";
import { HelpModal } from "./components/help-modal";
import { ScheduleCompletionManage } from "./steps/scheduling-completion-manage";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useSchedulingQueue } from "./hooks/use-scheduling-queue";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useUserToken } from "@/store/useAuthStore";
import { useUserStore } from "@/store/useUserStore";
import { formatCpf, formatPhone } from "@/utils/formats";

const mapTitleForStatus: Record<string, string> = {
  chamado: "Chegou sua vez!",
  aguardando: "Quase lá!",
  indeferido: "Agendamento",
};

const mapDescriptionForStatus: Record<string, string> = {
  chamado: "Clique no botão abaixo para realizar o agendamento.",
  aguardando: "Quando chegar sua vez você poderá realizar o agendamento aqui.",
  indeferido:
    "Seu agendamento foi indeferido, caso não entenda o motivo entre em contato a com instituição.",
};

export function SchedulingQueue() {
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenHelplModal, setIsOpenHelpModal] = useState(false);

  const appId = useAppId();
  const slug = useAppSlug();
  const userId = useUserStore((state) => state.user?.id);
  const isAuthenticated = !!useUserToken();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useSchedulingQueue({ appId, id });

  function handleCloseCancelModal() {
    setIsOpenCancelModal(false);
  }

  function handleCloseHelpModal() {
    setIsOpenHelpModal(false);
  }

  function handleOpenSchedulingCompletion() {
    searchParams.append("openModal", "true");
    searchParams.append("step", "local");
    navigation.replace({ pathname: "", search: searchParams.toString() });
  }

  function handleGoBack() {
    if (!isAuthenticated) {
      navigation.replace(`/${slug}/buscar-agendamentos?cpf=${data?.cpf}`);
      return;
    }

    navigation.replace(`/${slug}/meus-agendamentos`);
  }

  const enabledQuitQueue =
    data?.status === "aguardando" && (!data.userId || data.userId === userId);

  return (
    <Layout>
      <Header variant="secondary">
        <HeaderButton
          variant="secondary"
          icon={<CaretLeft weight="bold" />}
          onClick={handleGoBack}
        />
      </Header>

      <Content>
        <div className="relative mx-auto flex min-h-full w-full max-w-3xl px-4 py-6 ios:pb-2">
          {isLoading && !data ? <ContentLoading /> : null}

          {!isLoading && data ? (
            <div className="flex w-full flex-col gap-6">
              <div className="flex flex-col text-center">
                <Text size="lg" color="slate-700">
                  {mapTitleForStatus[data.status]}
                </Text>
                <Text size="xs">{mapDescriptionForStatus[data.status]}</Text>
              </div>

              {data.status === "chamado" ? (
                <button
                  onClick={handleOpenSchedulingCompletion}
                  className="flex items-center gap-3 rounded-md bg-primary-500 px-5 py-4"
                >
                  <CalendarBlank size={32} weight="fill" className="text-white" />
                  <Text size="xs" weight="semibold" color="white" className="underline">
                    Clique para realizar seu agendamento.
                  </Text>
                </button>
              ) : null}

              {data.status === "indeferido" && data.justificationForRejection ? (
                <BoxJustificationForRejection justification={data.justificationForRejection} />
              ) : null}

              <CardInfo.Root>
                <CardInfo.Title>Dados da solicitação</CardInfo.Title>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Serviço</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data.resource.name}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Nome</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data.name}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>CPF</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{formatCpf(data.cpf)}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>E-mail</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{data.email}</CardInfo.InfoText>
                </CardInfo.Info>

                <CardInfo.Info>
                  <CardInfo.InfoTitle>Celular</CardInfo.InfoTitle>
                  <CardInfo.InfoText>{formatPhone(data.phone)}</CardInfo.InfoText>
                </CardInfo.Info>
              </CardInfo.Root>

              {data.status === "aguardando" ? (
                <div className="flex w-full items-center justify-center gap-2 rounded-md border-thin border-slate-300 px-2 py-4">
                  <CheckCircle weight="fill" className="flex-shrink-0 text-primary-500" size={42} />
                  <Text size="xs" color="slate-700">
                    <strong>Você está na fila!</strong> Aguarde enquanto novas vagas serão
                    disponibilizadas.
                  </Text>
                </div>
              ) : null}

              {data.status === "chamado" && data.resource.timeForCompletion ? (
                <div className="flex w-full items-center justify-center gap-2 rounded-md border-thin border-slate-300 px-2 py-4">
                  <WarningCircle
                    weight="fill"
                    className="flex-shrink-0 text-primary-500"
                    size={42}
                  />
                  <Text size="xs" color="slate-700">
                    Atenção! Você tem até{" "}
                    <strong className="font-semibold">
                      {dayjs(data.calledDate)
                        .add(data.resource.timeForCompletion, "hour")
                        .format("DD/MM/YYYY [às] HH[h]mm")}{" "}
                    </strong>
                    para realizar o agendamento. Após esse período, caso deseje retornar, irá
                    precisar criar uma nova solicitação.
                  </Text>
                </div>
              ) : null}

              <footer className="mt-auto flex flex-col gap-3">
                {data.resource.textFinalization ? (
                  <Button
                    size="lg"
                    full
                    variant="tertiary"
                    className="underline"
                    onClick={() => setIsOpenHelpModal(true)}
                  >
                    Em caso de dúvidas, clique aqui
                  </Button>
                ) : null}

                <Button
                  size="lg"
                  full
                  variant="secondary"
                  onClick={() => setIsOpenCancelModal(true)}
                  disabled={!enabledQuitQueue}
                >
                  Sair da fila
                </Button>

                <Button size="lg" full onClick={() => window.print()}>
                  Imprimir comprovante
                </Button>
              </footer>

              <CloseToQueueModal
                schedulingId={data.id}
                isOpen={isOpenCancelModal}
                onClose={handleCloseCancelModal}
              />

              <HelpModal
                isOpen={isOpenHelplModal && !!data.resource.textFinalization}
                onClose={handleCloseHelpModal}
                textHelp={data.resource.textFinalization}
              />

              <ScheduleCompletionManage
                entityId={data.entityId}
                resource={data.resource}
                status={data.status}
              />
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}

function ContentLoading() {
  return (
    <div className="flex w-full flex-col gap-6">
      {Array.from(Array(3).keys()).map((index) => (
        <IonSkeletonText key={index} animated className="h-[400px] w-full bg-slate-50" />
      ))}
    </div>
  );
}

function BoxJustificationForRejection({ justification }: { justification: string }) {
  return (
    <div className="flex flex-col gap-2 rounded-xs bg-red-500/10 px-5 py-4">
      <div className="flex items-center gap-1">
        <WarningCircle weight="fill" className="text-red-500" />
        <Text weight="medium" color="red-500">
          Justificativa para indeferimento
        </Text>
      </div>

      <Text color="slate-700">{justification}</Text>
    </div>
  );
}
