import { SetStateAction } from "react";
import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";

export const handleConfirmInterval = (
  timeSelected: Array<string>,
  dataAssetsScheduling?: IDataAssetsScheduling,
  setDataAssetsScheduling?: (value: SetStateAction<IDataAssetsScheduling | undefined>) => void,
  handleNextStep?: () => void,
) => {
  setDataAssetsScheduling &&
    setDataAssetsScheduling((prevState) => ({
      ...prevState,
      hora_inicio: `${dataAssetsScheduling?.extra_fields?.date} ${timeSelected[0]}`,
      hora_fim: `${dataAssetsScheduling?.extra_fields?.date} ${
        timeSelected[1]
          ? timeSelected[1]
          : dayjs(timeSelected[0], "HH:mm")
              .add(dataAssetsScheduling?.extra_fields?.step as number, "minutes")
              .format("HH:mm")
      }`,
    }));
  handleNextStep && handleNextStep();
};
