import { useHistory } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";
import { useQuery } from "@tanstack/react-query";

import { CardEvent } from "./card-event";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { getPreviewEvents } from "@/services/events";

export function PreviewEvents() {
  const history = useHistory();
  const slug = useAppSlug();
  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data: news } = useQuery({
    queryKey: ["preview_events", String(appId)],
    queryFn: () => getPreviewEvents({ appId }),
    staleTime: 60 * 1000 * 5, // 5 minutos
  });

  function handleOpenEvent(id: number) {
    history.push(`/${slug}/evento/${id}`);
  }

  function handleOpenListEvents() {
    history.push(`/${slug}/eventos`);
  }

  return (
    <section className="w-full bg-[#FDF03A]">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-4 py-6">
        <header className="flex w-full items-center justify-between px-4">
          <Text asChild color="slate-700" weight="medium" className="text-md lg:text-lg">
            <strong>Agenda das plenárias</strong>
          </Text>

          <button
            className="text-sm font-medium text-[#00629D] lg:text-md"
            onClick={() => handleOpenListEvents()}
          >
            Ver tudo
          </button>
        </header>

        <ul className="flex w-full gap-4 overflow-x-auto px-4">
          {news?.map((item) => (
            <li key={item.id}>
              <CardEvent
                key={item.id}
                banner={item.imageUrl}
                localization={item.localization}
                title={item.title}
                time={item.time}
                handleOnClick={() => handleOpenEvent(item.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
