import { ResponseInstitution } from "@/services/assetsScheduling";
import { IDataAssetsScheduling } from "../..";

export interface IOperationTime {
  id: number;
  dia_semana: string;
  hora_fim: string;
  hora_inicio: string;
  hora_intervalo_fim: string | null;
  hora_intervalo_inicio: string | null;
  habilitado: boolean;
}

export const getOperationTime = (
  institution?: ResponseInstitution,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  institution?.unidades
    .find((unit) => unit.id === dataAssetsScheduling?.unidade_id)
    ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id)?.funcionamentos;
