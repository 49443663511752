import React, { useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IonRippleEffect } from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, PaperPlaneRight } from "@phosphor-icons/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { Header, HeaderButton } from "@/components";
import { MyMessage } from "../components/my-message";
import { MarketMessage } from "../components/market-message";
import { convertTextWithAnchors } from "@/utils/convert-text-with-anchors";
import { useAppId, useAppStore } from "@/store/useAppStore";
import { useSocket } from "@/hooks/useSocket";
import { useUserStore } from "@/store/useUserStore";
import { useSendMessageMarket } from "../hooks/use-send-message-market";
import { useMessagesMarketUser } from "./hooks/use-messages-market-user";

const formChatUserSchema = z.object({
  message: z.string().min(1),
});

type FormChatMarketData = z.infer<typeof formChatUserSchema>;

export function ChatUser() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const inputRef = useRef<HTMLDivElement | null>(null);
  const containerInputRef = useRef<HTMLFormElement | null>(null);
  const queryClient = useQueryClient();

  const socketChannel = useAppStore((state) => state.app?.socketChannel);
  const userId = useUserStore((state) => state.user?.id);

  const { control, handleSubmit } = useForm<FormChatMarketData>({
    resolver: zodResolver(formChatUserSchema),
    defaultValues: {
      message: "",
    },
  });

  const { mutateAsync: sendMessageMarket } = useSendMessageMarket();
  const { data: chat, isLoading } = useMessagesMarketUser({
    appId,
    id: Number(id),
  });

  const { mutateAsync } = useMutation({
    mutationFn: async (data: {
      id: number;
      userId: number;
      mensagem: string;
      created_at: string;
      fromMerchant: boolean;
    }) => {
      const messagesCopy = { ...chat?.messages };

      const message = {
        id: data.id,
        created_at: data.created_at,
        userId: data.userId,
        message: convertTextWithAnchors(data.mensagem),
        fromMerchant: data.fromMerchant,
      };

      const date = data.created_at.split("T")[0];

      messagesCopy[date] ? messagesCopy[date].push(message) : (messagesCopy[date] = [message]);

      queryClient.setQueryData(["messages-market-user", Number(id), Number(appId)], {
        ...chat,
        messages: messagesCopy,
      });
    },
  });

  const watchMessage = (data: {
    id: number;
    user_id: number;
    mensagem: string;
    created_at: string;
    mensagem_comerciante: boolean;
  }) => {
    mutateAsync(
      {
        id: data.id,
        userId: data.user_id,
        mensagem: data.mensagem,
        created_at: data.created_at,
        fromMerchant: data.mensagem_comerciante,
      },
      {
        onSuccess: () => handleScrollBottom(),
      },
    );
  };

  useSocket({ channel: `${socketChannel}.comerciante.${id}.${userId}`, watchMessage });

  function handleSendMessage(data: FormChatMarketData) {
    sendMessageMarket(
      {
        appId: Number(appId),
        id: Number(id),
        message: data.message,
      },
      {
        onSuccess: () => {
          if (inputRef.current) inputRef.current.innerHTML = "";
        },
        onError: (err) => console.error(err),
      },
    );
  }

  function handleGoBack() {
    history.goBack();
  }

  function handleScrollBottom() {
    if (containerInputRef.current) {
      containerInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }

  useEffect(() => {
    setTimeout(() => handleScrollBottom(), 100);
  }, [chat?.messages]);

  const merchant = useMemo(() => chat?.merchant, [chat]);
  const messages = useMemo(() => chat?.messages, [chat]);

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text
            size="md"
            weight="medium"
            leading="relaxed"
            className="line-clamp-1 text-textHeader"
          >
            {merchant?.name ?? "Comerciante"}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl px-4 py-6 ios:pb-2">
          <form
            ref={containerInputRef}
            onSubmit={handleSubmit(handleSendMessage)}
            className="flex min-h-full w-full flex-col gap-4 scroll-smooth"
          >
            <div className="flex flex-col gap-2">
              {!isLoading && messages && Object.keys(messages).length
                ? Object.keys(messages).map((date) => (
                    <React.Fragment key={date}>
                      <Text asChild size="xs" color="slate-400" className="block text-center">
                        <time dateTime={date}>{dayjs(date).format("DD [de] MMMM YY")}</time>
                      </Text>

                      {messages[date]?.map((message) =>
                        !message.fromMerchant && message.userId === userId ? (
                          <MyMessage
                            key={message.id}
                            message={message.message}
                            time={message.created_at}
                          />
                        ) : (
                          <MarketMessage
                            key={message.id}
                            message={message.message}
                            time={message.created_at}
                          />
                        ),
                      )}
                    </React.Fragment>
                  ))
                : null}

              {!isLoading && messages && !Object.keys(messages).length ? (
                <div className="gap-0.5 flex flex-col items-center py-8 text-center">
                  <Text weight="medium" color="slate-700">
                    Não há mensagens!
                  </Text>
                  <Text color="slate-400">
                    Utilize o campo abaixo para enviar uma mensagem ao comerciante.
                  </Text>
                </div>
              ) : null}
            </div>

            <div className="mt-auto flex justify-center gap-2 overflow-hidden rounded-lg bg-slate-100 px-3 py-2">
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange } }) => (
                  <div
                    ref={inputRef}
                    contentEditable
                    className="hide-scrollbar block max-h-28 grow self-center overflow-auto font-sans text-xs text-slate-700 focus:outline-none [&:empty:not(:focus):before]:text-slate-400 [&:empty:not(:focus):before]:content-['Digite_aqui...'] [&_strong]:font-semibold"
                    onInput={(e) => onChange(e.currentTarget.innerHTML)}
                  />
                )}
              />

              <button
                type="submit"
                className="ion-activatable ripple-parent flex h-7 w-7 flex-shrink-0 items-center justify-center self-end"
              >
                <PaperPlaneRight
                  alt="send icon"
                  size={20}
                  weight="fill"
                  className="text-primary-500"
                />

                <IonRippleEffect className="text-slate-100" />
              </button>
            </div>
          </form>
        </div>
      </Content>
    </Layout>
  );
}
