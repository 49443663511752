import { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

const Main = styled.main`
  flex: 1;
  overflow: auto;
  background-color: #ffffff;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MainContentProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

export function MainContent({ children, ...rest }: MainContentProps) {
  return <Main {...rest}>{children}</Main>;
}
