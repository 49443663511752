import { useMutation } from "@tanstack/react-query";
import { login } from "@/services/auth/login";

interface useLoginParams {
  appId: number;
  username: string;
  password: string;
  captchaResponse: string;
  cancel: boolean;
}

export function useLogin() {
  return useMutation({
    mutationFn: (data: useLoginParams) => {
      return login(data);
    },
  });
}
