import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonLoading, IonToast } from "@ionic/react";
import { barcodeOutline } from "ionicons/icons";

import { Content, CountProtocol, Icon, NotContent } from "./styles";

import { BlockItemProtocolsList } from "../BlockItemProtocolsList";
import { BlockFormSearchServices } from "../BlockFormSearchServices";
import { isLogged } from "@/services/old/auth";
import { getMyProtocols, getProtocolByIdV2 } from "@/services/old/servicos";
import { getContent } from "@/content/index";
import { getAppSlug } from "@/services/old/starter";

interface paramsPage {
  SLUG: string;
}

export function TabProtocolList() {
  const [searchProtocolId, setSearchProtocolId] = useState("");
  const [protocol, setProtocol] = useState<any>(undefined);
  const CONTENT = getContent();
  const params: paramsPage = useParams();

  const { isInitialLoading, data } = useQuery({
    queryKey: ["getMyProtocols"],
    queryFn: () => getMyProtocols(params.SLUG),
    enabled: isLogged(),
  });

  const [loading, setLoading] = useState(false);

  async function getProtocolId(id: any) {
    if (!id) {
      return resetProtocol();
    }

    setLoading(true);

    getProtocolByIdV2(id, params.SLUG)
      .then((response) => {
        setProtocol(response.data);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 404) {
          setProtocol(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function resetProtocol() {
    setProtocol(undefined);
    setSearchProtocolId("");
  }

  if (isInitialLoading || loading) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  }

  return (
    <Content>
      <BlockFormSearchServices
        id={searchProtocolId}
        title="Buscar protocolo"
        placeholder="Digite seu código"
        value={searchProtocolId}
        onChange={(e) => {
          setSearchProtocolId(e.target.value);
        }}
        onSubmit={getProtocolId}
        onReset={resetProtocol}
      />

      {data && data.data.length > 0 && (
        <>
          {protocol === undefined && (
            <CountProtocol>
              <Icon icon={barcodeOutline} /> {data.data.length} Protocolos
            </CountProtocol>
          )}
          {data &&
            protocol === undefined &&
            data?.data.map((item: any, index: number) => {
              return (
                <BlockItemProtocolsList
                  key={index}
                  id={item.id}
                  category={item.categoriaServico?.titulo}
                  status={item.status.titulo}
                  createdAt={item.created_at}
                  routerLink={"/" + getAppSlug() + "/protocol/" + item.id}
                />
              );
            })}
        </>
      )}

      {protocol && (
        <>
          {protocol && (
            <BlockItemProtocolsList
              key={protocol.id}
              id={protocol.id}
              category={protocol.categoriaServico?.titulo}
              status={protocol.status.titulo}
              createdAt={protocol.created_at}
              routerLink={"/" + getAppSlug() + "/protocol/" + protocol.id}
            />
          )}
        </>
      )}

      {data && data.data.length === 0 && protocol === undefined && (
        <NotContent>
          <span> {CONTENT.GLOBAL.NOCONTENT} </span>
        </NotContent>
      )}

      {protocol === null && (
        <IonToast
          isOpen={true}
          position="bottom"
          color="dark"
          message="Protocolo não encontrado."
          duration={7000}
        />
      )}
    </Content>
  );
}
