import { IonMenu, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";

import { Menu } from "@/components";

import { UserTerms } from "@/pages/old/UserTerms";
import { MyProfile } from "@/pages/old/Auth/Profile";
import { PasswordChange } from "@/pages/old/Auth/PasswordChange";
import { Forms } from "@/pages/old/Forms";
import { FormOpen } from "@/pages/old/Forms/pages/FormOpen";
import { BindHolder } from "@/pages/old/BindHolder";
import { LinkSuccess } from "@/pages/old/BindHolder/LinkSuccess";
import { ModuleFeed } from "@/pages/old/ModuleFeed";
import { ModuleFeedOpen } from "@/pages/old/ModuleFeed/FeedOpen";
import { ModuleFeedCamera } from "@/pages/old/ModuleFeed/Camera";
import { Page404 } from "@/pages/old/404";
import { InfoOpen } from "@/pages/old/Infos/pages/InfoOpen";
import { InfoOpenHeaderless } from "@/pages/old/Infos/pages/InfoOpenHeaderless";
import { InfoCatergory } from "@/pages/old/Infos";
import { InfoCategoryOpen } from "@/pages/old/Infos/pages/InfoCategoryOpen";
import { InfoCategoryOpenHeaderless } from "@/pages/old/Infos/pages/InfoCategoryOpenHeaderless";
import { Feirinha } from "@/pages/old/Feirinha";
import { OpenProduct } from "@/pages/old/Feirinha/pages/OpenProduct";
import { OpenModule } from "@/pages/old/Feirinha/pages/OpenModule";
import { OpenSeller } from "@/pages/old/Feirinha/pages/OpenSeller";
import { NewProduct } from "@/pages/old/Feirinha/pages/NewProduct";
import { RegisterSeller } from "@/pages/old/Feirinha/pages/RegisterSeller";
import { ProtocolPage } from "@/pages/old/Protocol";
import { ProtocolChat } from "@/pages/old/Protocol/components/ProtocolChat";
import { ProtocolNew } from "@/pages/old/NewProtocol";
import { MyServices } from "@/pages/old/Services/pages/MyServices";
import { MyProtocols } from "@/pages/old/Services/pages/MyProtocols";
import { Services as OldServices } from "@/pages/old/Services";
import { ServiceIframe } from "@/pages/old/Services/pages/ServicesIframe";
import { ServicesIcons } from "@/pages/old/Services/pages/ServicesIcons";
import { ServicesGroupsPage } from "@/pages/old/Services/pages/ServicesGroup";
import { ServicesGroupsImages } from "@/pages/old/Services/pages/ServicesGroupImage";
import { ServiceOpen } from "@/pages/old/Services/pages/ServiceOpen";
import { AreaList } from "@/pages/old/Services/pages/AreaList";
import { CategoryList } from "@/pages/old/Services/pages/CategoryList";
import { OpenProtocol } from "@/pages/old/Services/pages/OpenProtocol";
import { ConfirmationProtocol } from "@/pages/old/Services/pages/ConfirmationProtocol";
import { Iframe } from "@/pages/old/Iframe";

import { ListNews } from "@/pages/News";
import { News } from "@/pages/News/pages/News";

import { AssetsScheduling } from "@/pages/AssetsScheduling";
import { ListBooking } from "@/pages/AssetsScheduling/List";
import { ShowBooking } from "@/pages/AssetsScheduling/Show";

import { PresentialScheduling } from "@/pages/PresentialScheduling";
import { ListPresentialBooking } from "@/pages/PresentialScheduling/List";
import { ShowPresentialBooking } from "@/pages/PresentialScheduling/Show";

import { CategoriesServices } from "@/pages/CategoriesServices";
import { Services } from "@/pages/CategoriesServices/pages/Services";
import { CreateProtocol } from "@/pages/CategoriesServices/pages/Services/pages/Protocol";

import { Protocols } from "@/pages/Protocols";
import { Protocol } from "@/pages/Protocols/pages/Protocol";
import { EditHolderPage } from "@/pages/Holder";
import { useUserStore } from "@/store/useUserStore";
import { Unauthorized } from "@/pages/Unauthorized";

import { Wallets } from "@/pages/Wallets";
import { WalletPage } from "@/pages/Wallets/pages/Wallet";
import { WalletPagePrint } from "@/pages/Wallets/pages/Wallet/WalletPrint";

import { FAQ } from "@/pages/FAQ";

import { Login } from "@/pages/Auth/pages/Login";
import { ForgotMyPassword } from "@/pages/Auth/pages/ForgotMyPassword";
import { RecoverPasswordSuccess } from "@/pages/Auth/pages/ForgotMyPassword/pages/RecoverPasswordSuccess";
import { RegisterUserSuccess } from "@/pages/Auth/pages/Register/pages/RegisterUserSuccess";
import { RegisterUser } from "@/pages/Auth/pages/Register";
import { Onboarding } from "@/pages/Auth";
import { AlterEmail } from "@/pages/Auth/pages/Register/pages/AlterEmail";
import { HelperCenter } from "@/pages/HelperCenter";
import { FAQCategory } from "@/pages/FAQ/pages/Category";
import { AllServices } from "@/pages/CategoriesServices/pages/AllServices";

import {
  CreateScheduling,
  EntityScheduling,
  MySchedulings,
  Scheduling,
  SchedulingQueue,
  SearchSchedulings,
} from "@/pages/schedulings";
import { Events, EventOpen } from "@/pages/events";
import { PostComments } from "@/pages/post-comments/post-comments";
import { Chats } from "@/pages/market/chats/chats";
import { ChatUser } from "@/pages/market/chat-user/chat-user";
import { ChatMerchant } from "@/pages/market/chat-merchant/chat-merchant";
import { InfoMedia } from "@/pages/infos/info-media";

import { PPA } from "@/pages/ppa";
import { FormUser } from "@/pages/ppa/forms/form-user/form-user";
import { Territories } from "@/pages/ppa/pages/territories/territories";
import { Proposals } from "@/pages/ppa/pages/proposals";
import { SuggestProgram } from "@/pages/ppa/pages/suggest-program/suggest-program";
import { PPAProposals } from "@/pages/ppa/pages/ppa-proposals";
import { OPRN } from "@/pages/ppa/pages/op-rn/op-rn";
import { RNComAGente } from "@/pages/ppa/pages/rn-com-a-gente/rn-com-a-gente";
import { HomePPA } from "@/pages/home-ppa/home-ppa";
import { Results } from "@/pages/ppa/pages/results/results";

export const history = createBrowserHistory();

export function Router() {
  const user = useUserStore((state) => state.user);

  const { t } = useTranslation();

  return (
    <IonReactRouter history={history}>
      <IonMenu
        side="start"
        menuId="main"
        contentId="main-content"
        swipeGesture={false}
        className="pointer-events-auto"
      >
        <Menu />
      </IonMenu>

      <IonRouterOutlet id="main-content">
        {/* Home */}
        <Route path="/:SLUG/home" component={HomePPA} exact />
        <Route path="/:SLUG/user-terms" component={UserTerms} />

        {/* Perfil */}
        <Route path="/:SLUG/my-profile" component={MyProfile} />
        <Route path="/:SLUG/change-password" component={PasswordChange} />

        {/* Eventos */}
        <Route path="/:SLUG/eventos" component={Events} exact />
        <Route path="/:SLUG/evento/:id" component={EventOpen} />

        {/* Formulários */}
        <Route path="/:SLUG/forms" component={Forms} />
        <Route path="/:SLUG/form-open/:id/:id_page?" component={FormOpen} />

        {/* Vincular-se a titular */}
        <Route path="/:SLUG/link" exact component={BindHolder} />
        <Route path="/:SLUG/link/success" component={LinkSuccess} />

        {/* Feed */}
        <Route path="/:SLUG/modules" component={ModuleFeed} />
        <Route path="/:SLUG/modules-open/:id" exact component={ModuleFeedOpen} />
        <Route path="/:SLUG/modules-open/:moduleId/posts/:id" component={PostComments} />
        <Route path="/:SLUG/modules-open/:id/camera" component={ModuleFeedCamera} />

        {/* Informações */}
        <Route path="/:SLUG/info-category" component={InfoCatergory} />
        <Route path="/:SLUG/info-category-abrir/:id" component={InfoCategoryOpen} />
        <Route
          path="/:SLUG/info-category-abrir-headerless/:id"
          component={InfoCategoryOpenHeaderless}
        />
        <Route path="/:SLUG/info-abrir/:id" component={InfoOpen} />
        <Route path="/:SLUG/info-abrir-headerless/:id" component={InfoOpenHeaderless} />
        <Route path="/:SLUG/info-media/:id" component={InfoMedia} />

        {/* Feirinha */}
        <Route path="/:SLUG/market" component={Feirinha} />
        <Route path="/:SLUG/abrir-produto/:id" component={OpenProduct} />
        <Route path="/:SLUG/abrir-modulo/:id" component={OpenModule} />
        <Route path="/:SLUG/abrir-vendedor/:id" component={OpenSeller} />
        <Route path="/:SLUG/novo-produto/:id_product?" component={NewProduct} />
        <Route path="/:SLUG/seller-register" component={RegisterSeller} />

        {/* Protocolo */}
        <Route path="/:SLUG/protocolo/:id" component={ProtocolPage} />
        <Route path="/:SLUG/protocolo-chat/:id" component={ProtocolChat} />
        <Route path="/:SLUG/protocol/:id" component={ProtocolNew} />

        {/* Serviços */}
        <Route path="/:SLUG/my-services" component={MyServices} />
        <Route path="/:SLUG/my-protocols" component={MyProtocols} />

        <Route path="/:SLUG/all-services" exact component={AllServices} />
        <Route path="/:SLUG/services" component={OldServices} />
        <Route path="/:SLUG/services-icons" component={ServicesIcons} />
        <Route path="/:SLUG/services-groups" component={ServicesGroupsPage} />
        <Route path="/:SLUG/services-groups-images" component={ServicesGroupsImages} />
        <Route path="/:SLUG/services-abrir/:id" component={ServiceOpen} />

        <Route path="/:SLUG/area-services" exact component={AreaList} />
        <Route path="/:SLUG/area-services/:id" component={CategoryList} />
        <Route path="/:SLUG/open-protocol/:id" component={OpenProtocol} />
        <Route path="/:SLUG/confirmation-open-protocol/:id" component={ConfirmationProtocol} />

        <Route path="/:SLUG/services-abrir-externo/:id" component={ServiceIframe} />

        {/* Iframe */}
        <Route path="/:SLUG/abrir" component={Iframe} exact />

        {/* -- Novas páginas -- */}

        {/* Notícias */}
        <Route path="/:SLUG/news" component={ListNews} exact />
        <Route path="/:SLUG/news/:id" component={News} />

        {/* Agendamento presencial */}
        <Route path="/:SLUG/presential-scheduling/institution/:id" exact>
          <PresentialScheduling />
        </Route>
        <Route path="/:SLUG/presential-scheduling" exact>
          <ListPresentialBooking />
        </Route>
        <Route path="/:SLUG/presential-scheduling/:id" exact>
          <ShowPresentialBooking />
        </Route>

        {/* Agendamento de ativos */}
        <Route path="/:SLUG/assets-scheduling/institution/:id" exact>
          <AssetsScheduling />
        </Route>
        <Route path="/:SLUG/assets-scheduling" exact>
          <ListBooking />
        </Route>
        <Route path="/:SLUG/assets-scheduling/:id" exact>
          <ShowBooking />
        </Route>

        {/* Processo de criação de protocolo */}
        <Route path="/:SLUG/categories-services" exact>
          <CategoriesServices />
        </Route>
        <Route path="/:SLUG/categories-services/:id/services" exact>
          <Services />
        </Route>
        <Route path="/:SLUG/categories-services/:id/services/:serviceId/protocolo" exact>
          <CreateProtocol />
        </Route>

        {/* Protocolos */}
        <Route path="/:SLUG/protocols" exact>
          <Protocols />
        </Route>
        <Route path="/:SLUG/protocols/:id" exact>
          <Protocol />
        </Route>

        {/* Holder */}
        <Route path="/:SLUG/holder" exact>
          {user?.holder ? (
            <EditHolderPage />
          ) : (
            <Unauthorized
              message={t("holder.Você não possui um titular cadastrado")}
              redirectUrl="/"
            />
          )}
        </Route>

        {/* Carteiras */}
        <Route path="/:SLUG/carteiras" exact>
          <Wallets />
        </Route>
        <Route path="/:SLUG/carteiras/:id" exact>
          <WalletPage />
        </Route>
        <Route path="/:SLUG/carteiras/:id/print">
          <WalletPagePrint />
        </Route>

        {/* FAQ */}
        <Route path="/:SLUG/faq/:id" exact>
          <FAQ />
        </Route>
        <Route path="/:SLUG/faq/:faqId/categories/:categoryId" exact>
          <FAQCategory />
        </Route>

        {/* Auth */}
        <Route path="/:SLUG/auth" exact>
          <Onboarding />
        </Route>
        <Route path="/:SLUG/auth/login" exact>
          <Login />
        </Route>
        <Route path="/:SLUG/auth/forgot-my-password" exact>
          <ForgotMyPassword />
        </Route>
        <Route path="/:SLUG/auth/forgot-my-password/success">
          <RecoverPasswordSuccess />
        </Route>
        <Route path="/:SLUG/auth/register" exact>
          <RegisterUser />
        </Route>
        <Route path="/:SLUG/auth/register/success" exact>
          <RegisterUserSuccess />
        </Route>
        <Route path="/:SLUG/auth/register/alter-email" exact>
          <AlterEmail />
        </Route>
        <Route path="/:SLUG/help">
          <HelperCenter />
        </Route>

        {/* Agendamentos */}
        <Route path="/:SLUG/agendamentos/:id" exact>
          <EntityScheduling />
        </Route>
        <Route path="/:SLUG/agendamentos/:id/criar-agendamento" exact>
          <CreateScheduling />
        </Route>
        <Route path="/:SLUG/meus-agendamentos" exact>
          <MySchedulings />
        </Route>
        <Route path="/:SLUG/meus-agendamentos/:id">
          <Scheduling />
        </Route>
        <Route path="/:SLUG/agendamentos-fila/:id">
          <SchedulingQueue />
        </Route>
        <Route path="/:SLUG/buscar-agendamentos">
          <SearchSchedulings />
        </Route>

        <Route path="/:SLUG/comerciantes/:id/conversas" exact>
          <Chats />
        </Route>
        <Route path="/:SLUG/comerciantes/:comerciante_id/conversas/:id" exact>
          <ChatMerchant />
        </Route>
        <Route path="/:SLUG/comerciantes/:id/conversa">
          <ChatUser />
        </Route>

        <Route path="/:SLUG/dados-extra" exact>
          <FormUser />
        </Route>
        <Route path="/:SLUG/ppa" exact>
          <PPA />
        </Route>
        <Route path="/:SLUG/ppa/propostas" exact>
          <PPAProposals />
        </Route>
        <Route path="/:SLUG/op-rn" exact>
          <OPRN />
        </Route>
        <Route path="/:SLUG/rn-com-a-gente" exact>
          <RNComAGente />
        </Route>
        <Route path="/:SLUG/consultas/:id/territorios" exact>
          <Territories />
        </Route>
        <Route path="/:SLUG/consultas/:id/propostas" exact>
          <Proposals />
        </Route>
        <Route path="/:SLUG/consultas/:id/sugestao" exact>
          <SuggestProgram />
        </Route>
        <Route path="/:SLUG/consultas/:id/resultados" exact>
          <Results />
        </Route>

        {/* Padrão */}
        <Route
          path="/:SLUG"
          render={({ match }) => <Redirect to={`/${match.params.SLUG}/home`} />}
          exact
        />
        <Route path="/" render={() => <Redirect to="/ppa-rn" />} exact />
        <Route path="/404" component={Page404} />
        <Route component={Page404} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
}
