import { forwardRef } from "react";
import styled from "styled-components";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckFat } from "@phosphor-icons/react";
import { COLORS } from "@/data/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    font-size: 0.875rem;
    line-height: 150%;
    color: #94a3b8;
  }
`;

const Root = styled(CheckboxPrimitive.Root)`
  all: unset;
  box-sizing: border-box;

  height: 1.25rem;
  width: 1.25rem;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  cursor: pointer;

  &[data-state="checked"] {
    background-color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};
  }

  &[data-state="checked"] + input + label {
    color: #334155;
  }

  &[data-state="unchecked"] + input + label {
    color: #94a3b8;
  }
`;

const Indicator = styled(CheckboxPrimitive.Indicator)`
  display: flex;
  justify-content: center;
`;

export interface CheckboxProps extends CheckboxPrimitive.CheckboxProps {
  label?: string;
}

export const Checkbox = forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ label, ...props }, ref) => {
    return (
      <Container>
        <Root ref={ref} {...props}>
          <Indicator>
            <CheckFat size={12} weight="fill" color="#ffffff" />
          </Indicator>
        </Root>

        <label htmlFor={props.id}>{label}</label>
      </Container>
    );
  },
);

Checkbox.displayName = "Checkbox";
