import { getContent } from "@/content/index";

import { TabTemplate } from "@/templates/old/TabTemplate";
import { TabServiceListIcons } from "@/pages/old/Services/components/TabServiceListIcons";
import { TabProtocolsList } from "@/pages/old/Services/components/TabProtocolsList";

const firstTab = {
  value: "Serviços",
  content: <TabServiceListIcons />,
};

const secondTab = {
  value: "Protocolos",
  content: <TabProtocolsList />,
};

export function ServicesIcons() {
  const CONTENT = getContent();

  const params = new URLSearchParams(window.location.search);

  const queryParams = params.get("tab" || "services");

  return (
    <TabTemplate
      selected={queryParams === "protocols" ? secondTab.value : firstTab.value}
      first={firstTab}
      second={secondTab}
      name={CONTENT.SERVICES.HEADER_TITLE}
    />
  );
}
