import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, Heading } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { NewsCategory } from "@/services/news/types";

interface FormData {
  categories: Array<NewsCategory>;
}

interface FilterCategoryModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  categories: Array<NewsCategory>;
  categoriesFiltered: Array<NewsCategory>;
  handleSelectCategories: (categories: Array<NewsCategory>) => void;
}

export function FilterCategoryModal({
  showModal,
  handleCloseModal,
  categories,
  categoriesFiltered,
  handleSelectCategories,
}: FilterCategoryModalProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      categories: categoriesFiltered,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "categories",
    control,
  });

  function selectCategories({ categories }: FormData) {
    handleSelectCategories(categories);
    handleCloseModal();
  }

  function handleClearCategories() {
    setValue("categories", []);
  }

  return (
    <Modal showModal={showModal} onClose={handleCloseModal} classname="p-0 pt-12">
      <div className="relative flex flex-col items-center">
        <Heading weight="medium">{t("news.Categorias de notícias")}</Heading>

        <form
          id="selectCategories"
          onSubmit={handleSubmit(selectCategories)}
          className="mb-12 mt-6 flex w-full flex-wrap items-center gap-3 px-4"
        >
          {categories.map((category) => (
            <Checkbox
              key={category.categoryId}
              id={String(category.categoryId)}
              checked={fields.some((item) => item.categoryId === category.categoryId)}
              onCheckedChange={(value) =>
                value
                  ? append(category)
                  : remove(fields.findIndex((item) => item.categoryId === category.categoryId))
              }
              label={category.title}
              variant="secondary"
            />
          ))}
        </form>

        <footer className="sticky bottom-4 flex w-full items-center justify-between border-t-thin border-slate-200 bg-white p-4 pb-[calc(var(--safe-area-inset-bottom)+1rem)]">
          <Button
            variant="secondary"
            size="lg"
            rounded="full"
            className="px-9"
            onClick={handleClearCategories}
          >
            {t("general.Limpar")}
          </Button>
          <Button size="lg" rounded="full" className="px-9" form="selectCategories" type="submit">
            {t("general.Aplicar filtros")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
