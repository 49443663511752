import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { IDataPresentialScheduling } from "../steps";

export interface StepsContextData {
  currentStep?: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  total?: number;
  handleNextStep?: () => void;
  handlePrevStep?: () => void;
  handleGoTo?: (page: number) => false | void;
  setDataPresentialSchedulingInstitution?: Dispatch<
    SetStateAction<IDataPresentialScheduling | undefined>
  >;
  dataPresentialSchedulingInstitution?: IDataPresentialScheduling | undefined;
  extraFields?: {
    dayId?: number;
  };
}

export const StepsContext = createContext<StepsContextData>({});

export const useStepsContext = () => useContext(StepsContext);
