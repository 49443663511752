import { http } from "@/lib/axios";

export type AddCommentParams = {
  appId: number;
  id: number;
  comment: string;
};

export async function addComment({ appId, id, comment }: AddCommentParams) {
  try {
    await http.post(`/v2/${appId}/modulos/fotos/${id}/comentar`, {
      comentario: comment,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
