import { Modal } from "@/components/Modal";

type HelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  textHelp?: string | null;
};

export function HelpModal({ isOpen, onClose, textHelp }: HelpModalProps) {
  return (
    <Modal showModal={isOpen} onClose={onClose}>
      {textHelp ? (
        <div
          className="prose prose-slate mx-auto mt-2 font-sans text-sm text-slate-700 [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
          dangerouslySetInnerHTML={{ __html: textHelp }}
        />
      ) : null}
    </Modal>
  );
}
