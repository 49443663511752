import { useMutation } from "@tanstack/react-query";
import { alterEmail } from "@/services/users/alterEmail";

interface useAlterEmailParams {
  email: string;
  novoEmail: string;
  password?: string;
  appId?: number;
}

export function useAlterEmail() {
  return useMutation({
    mutationFn: (data: useAlterEmailParams) => {
      return alterEmail(data);
    },
  });
}
