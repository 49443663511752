import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { COLORS } from "@/data/colors";
import { Checkbox } from "../inputs/checkbox";

interface MultipleProps {
  name: string;
  value: Array<string>;
  label: string;
  onChange: (value: Array<string>) => void;
  options: Array<string>;
}

interface handleOnChangeParams {
  checked: string | boolean;
  option: string;
}

export function Multiple({ value, onChange, name, options, label }: MultipleProps) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  function handleOnChange({ checked, option }: handleOnChangeParams) {
    let newValue = [...value];

    if (checked) {
      newValue = [...value, option];
    } else {
      newValue = newValue.filter((item) => item !== option);

      if (value.includes("Outro") && !newValue.includes("Outro")) {
        setValue(`orther${name}`, "");
      }
    }

    onChange(newValue);
  }

  const error = errors[name]?.message ? String(errors[name]?.message) : undefined;

  const errorOrther = errors[`orther${name}`]?.message
    ? String(errors[`orther${name}`]?.message)
    : undefined;

  return (
    <InputIdentificaiton>
      <label>{label}</label>

      <Options>
        {options.map((item) => (
          <Checkbox
            key={item}
            id={item}
            name={item}
            label={item}
            checked={value.includes(item)}
            onCheckedChange={(checked) => handleOnChange({ checked, option: item })}
          />
        ))}
      </Options>

      {error ? <FieldError>{error}</FieldError> : null}

      {value.includes("Outro") ? (
        <OrtherInput>
          <label htmlFor={`orther${name}`}>Informe qual</label>

          <TextInput
            id={`orther${name}`}
            type="text"
            placeholder="Digite aqui"
            maxLength={30}
            {...register(`orther${name}`, {
              required: {
                value: true,
                message: "Informe para prosseguir",
              },
            })}
          />

          {errorOrther ? <FieldError>{errorOrther}</FieldError> : null}
        </OrtherInput>
      ) : null}
    </InputIdentificaiton>
  );
}

const InputIdentificaiton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > label {
    font-size: 0.875rem;
    line-height: 150%;
    color: #334155;
  }
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;
`;

export const FieldError = styled.span`
  font-size: 0.75rem;
  line-height: 150%;
  color: #e43c3c;
`;

export const OrtherInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.75rem;
    line-height: 150%;
    color: #334155;
  }
`;

export const TextInput = styled.input`
  appearance: none;

  height: 3rem;
  width: 100%;
  padding: 1rem 0.75rem;

  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  line-height: 150%;
  color: #334155;

  outline: none;

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    border-color: ${COLORS.PRIMARY};
  }
`;
