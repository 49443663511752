import { http } from "@/lib/axios";
import dayjs from "dayjs";

type getEventsParams = {
  appId?: number;
  page: number;
  title: string;
};

type eventsTypes = {
  id: number;
  titulo: string;
  banner: string;
  inicio_ts: string;
  endereco_extenso: string | null;
  descricao: string;
};

interface getEventsHttpResponse {
  data: Array<eventsTypes>;
  current_page: number;
  next_page_url: string | null;
  total: number;
}

export async function getEvents({ page, appId, title }: getEventsParams) {
  const response = await http.get<getEventsHttpResponse>(
    `v2/${appId}/eventos?apenas_pais=1&page=${page}&paginate=1`,
    {
      params: {
        paginate: 1,
        ...(title ? { conditions: `titulo:unaccent:${title}` } : {}),
      },
    },
  );

  const events = response.data.data.map((item) => ({
    id: item.id,
    title: item.titulo,
    imageUrl: item.banner,
    time: formatDate(item.inicio_ts),
    localization: item.endereco_extenso,
    description: item.descricao,
  }));

  return {
    currentPage: response.data.current_page,
    nextPage: !!response.data.next_page_url,
    total: response.data.total,
    data: events,
  };
}

function formatDate(date: string) {
  return dayjs(date).format("DD/MM/YYYY [•] HH[h]mm");
}
