import { useAtom } from "jotai";

import { MapRN } from "./map";
import { selectedTerritoryResultsAtom } from "../atoms/selectedTerritoryResultsAtom";
import { territories } from "@/pages/ppa/data/cities";

export function FilterBar() {
  const [selectedTerritory, setSelectedTerritory] = useAtom(selectedTerritoryResultsAtom);

  return (
    <div className="flex flex-col items-center gap-8">
      <div className="flex flex-wrap items-center gap-2">
        {territories.map((territory) => (
          <button
            key={territory.id}
            data-selected={territory.id === selectedTerritory}
            onClick={() => setSelectedTerritory(territory.id)}
            className="flex w-fit items-center gap-1 rounded-full border border-slate-200 px-3 py-1.5 text-sm font-medium leading-normal text-slate-700 transition-colors data-[selected=true]:border-primary-800 data-[selected=true]:bg-primary-800 data-[selected=true]:text-white"
          >
            {territory.territory}
          </button>
        ))}
      </div>

      <MapRN />
    </div>
  );
}
