import { isLogged, getAccessToken, getUserID } from "./auth";
import { getAppId } from "./provider";
import { http } from "@/lib/axios";
import { STORAGE } from "@/data/storage";

export async function getCategories(moduleId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  return http.get(
    `v2/` +
      APP_ID +
      `/feirinha/categoria?conditions=modulo_id:=:${moduleId}&orderby=id:desc&paginate=0`,
  );
}

export async function getModule(moduleId: string, SLUG: string) {
  if (isLogged()) {
    const headers = {
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    const APP_ID = await getAppId(SLUG);

    return http.get(
      `v2/` + APP_ID + `/feirinha/modulo_produto/${moduleId}?conditions=titulo:ilike:null`,
      headers,
    );
  } else return null;
}

export async function sellerRegister(formData: any, id: number, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .post(`v2/` + APP_ID + `/feirinha/comerciante${id < 0 ? "" : "/" + id}`, formData, headers)
    .then((response) => {
      localStorage.setItem(STORAGE.AUTH_REFRESH_TOKEN.KEY, "YES");
      getSellerLoged(SLUG);
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function productUpdateStatus(id: string, status: number, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  const formData = new FormData();

  formData.append("_method", "PUT");
  formData.append("situacao", status.toString());

  return http
    .post(`v2/` + APP_ID + `/feirinha/produto/${id}`, formData, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function productRegister(formData: any, id: number, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .post(`v2/` + APP_ID + `/feirinha/produto${id < 0 ? "" : "/" + id}`, formData, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function deleteProduct(id: number, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .delete(`v2/` + APP_ID + `/feirinha/produto/${id}`, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function sendSellerImage(sellerId: number, SLUG: string, params?: any) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .post(`v2/` + APP_ID + `/feirinha/comerciante/${sellerId}`, params, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function getModules(page: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/feirinha/modulo?paginate=1&page=${page}`);
}

export async function getModulesNoPaginate(SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/feirinha/modulo?orderby=id:desc&paginate=0`);
}

interface getProductsProps {
  page: number;
  modulo_id?: string;
  seller_id?: number;
  category?: number;
  search?: string;
}

export async function getProducts(props: getProductsProps, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  let url = `v2/` + APP_ID + `/feirinha/produto?conditions=`;

  if (props.modulo_id) {
    url = url + `modulo_id:=:${props.modulo_id}`;
  }

  if (props.seller_id) {
    url = url + (props.modulo_id ? ";" : "") + `comerciante_id:=:${props.seller_id}`;
  }

  if (props.search && props.search.length > 0) {
    url = url + `;titulo:ilike:${props.search}`;
  }

  if (props.category && props.category >= 0) {
    url = url + `;categoria_id:=:${props.category}`;
  }

  url = url + `&orderby=id:asc&paginate=1&page=${props.page}`;

  if (isLogged()) {
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    return http.get(url, headers);
  }
  return http.get(url);
}

export async function getProductPost(postId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    };
    return http.get(`v2/${APP_ID}/feirinha/produto/${postId}`, headers);
  }

  return http.get(`v2/${APP_ID}/feirinha/produto/${postId}`);
}

export async function getSeller(sellerId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    return http.get(
      `v2/` + APP_ID + `/feirinha/comerciante?conditions=id:=:${sellerId}&paginate=0`,
      headers,
    );
  }

  return http.get(`v2/` + APP_ID + `/feirinha/comerciante?conditions=id:=:${sellerId}&paginate=0`);
}

export async function getAuthSeller(SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    return http.get(`v2/` + APP_ID + `/feirinha/meu_empreendimento`, headers);
  }
  return null;
}

export async function getSellerLoged(SLUG: string) {
  return getSellerByUserID(getUserID(), SLUG);
}

export function getSellerIdLoged() {
  const id = localStorage.getItem(STORAGE.AUTH_USER_SELLER_ID.KEY);
  return id !== null && id.length > 0 ? +id : -1;
}

export async function getSellerByUserID(userId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    return http.get(
      `v2/` + APP_ID + `/feirinha/comerciante?conditions=usuario_id:=:${userId}&paginate=0`,
      headers,
    );
  }

  return http
    .get(`v2/` + APP_ID + `/feirinha/comerciante?conditions=usuario_id:=:${userId}&paginate=0`)
    .then((res) => {
      if (res.data && res.data.length > 0) {
        localStorage.setItem(STORAGE.AUTH_USER_SELLER_ID.KEY, res.data[0].id);
        return res.data[0];
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
}
