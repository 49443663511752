import dayjs from "dayjs";

import { IDataPresentialScheduling } from "../..";
import { ResponseInstitution } from "@/services/presentialScheduling";
import { IBookingTime } from "../../Time/utils/getBookingTimes";

export const getBookingsDays = (
  institution?: ResponseInstitution,
  dataPresentialSchedulingInstitution?: IDataPresentialScheduling,
) => {
  const days = institution?.setores
    .find((sector) => sector.id === dataPresentialSchedulingInstitution?.sector_id)
    ?.servicos.find(
      (service) => service.id === dataPresentialSchedulingInstitution?.servico_agendamento_id,
    )
    ?.agendamentos.filter((booking) =>
      JSON.parse(booking.json_vagas_hora).some(
        (item: IBookingTime) => item.vagas > 0 && dayjs(item.datetime).isAfter(dayjs()),
      ),
    )
    ?.map((booking) => ({
      day: dayjs(booking.data_aplicacao.split(" ")[0], "YYYY-MM-DD").date(),
      id: booking.id,
      weekDay: dayjs(booking.data_aplicacao.split(" ")[0], "YYYY-MM-DD").day(),
      month: dayjs(booking.data_aplicacao.split(" ")[0], "YYYY-MM-DD")
        .toDate()
        .toLocaleString("pt-BR", { month: "long" }),
    }));

  const daysPerMonths = days
    ?.map((day) => day.month)
    .filter((day, index, self) => self.indexOf(day) === index)
    .map((month) => ({ month, days: days.filter((day) => day.month === month) }));

  return daysPerMonths;
};
