import { useQuery } from "@tanstack/react-query";
import { getMySchedulings } from "@/services/schedulings";

type useMySchedulingsParams = {
  appId: number;
};

export function useMySchedulings({ appId }: useMySchedulingsParams) {
  return useQuery({
    queryKey: ["my-schedulings", appId],
    queryFn: () => getMySchedulings({ appId }),
    enabled: !!appId,
  });
}
