import { SelectHTMLAttributes, forwardRef } from "react";
import styled from "styled-components";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  hasValue?: boolean;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, hasValue, ...props }, ref) => {
    return (
      <SelectStyled>
        <SelectNative {...props} ref={ref} hasValue={hasValue}>
          {children}
        </SelectNative>
      </SelectStyled>
    );
  },
);

Select.displayName = "Select";

const SelectStyled = styled.div`
  height: 3.25rem;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  line-height: 1.1;
  color: #334155;
  cursor: pointer;
`;

interface SelectNativeProps {
  hasValue?: boolean;
}

const SelectNative = styled.select<SelectNativeProps>`
  appearance: none;

  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 1rem 0.75rem;
  margin: 0;

  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  color: ${(props) => (props.hasValue ? "inherit" : "#94a3b8")};

  outline: none;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;

  &:invalid {
    color: #94a3b8;
  }

  &::-ms-expand {
    display: none;
  }
`;
