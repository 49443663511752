import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { useIonViewWillLeave } from "@ionic/react";
import { atom, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Modal, Header, HeaderButton } from "@/components";
import { Content } from "@/templates/Content";
import { Button } from "@/components/Button";
import { ErrorRequest } from "./components/ErrorRequest";
import { PersonalForm } from "./steps/PersonalForm";
import { SecurityForm } from "./steps/SecurityForm";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useRegisteUser } from "./hooks/useRegisterUser";
import { EmailConfirmationForm } from "./steps/EmailConfirmationForm";

export type FormDataType = {
  name: string;
  email: string;
  cpf?: string;
  phone?: string;
  uf: string;
  city: string;
  region: {
    region?: string;
    visitor: boolean;
  };
  password: string;
  passwordConfirmation: string;
  emailConfirmation: string;
};

const initialValues = {
  name: "",
  email: "",
  cpf: "",
  phone: "",
  uf: "",
  city: "",
  region: {
    region: "",
    visitor: false,
  },
  password: "",
  passwordConfirmation: "",
  emailConfirmation: "",
};

export const registerFormAtom = atom<FormDataType>(initialValues);

export function RegisterUser() {
  const { t } = useTranslation();
  const history = useHistory();

  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId || !slug) {
    throw new Error("Not loaded app");
  }

  const setValuesRegisterForm = useSetAtom(registerFormAtom);

  const [step, setStep] = useState("personal");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  const { mutateAsync, isLoading } = useRegisteUser();

  useIonViewWillLeave(() => {
    setValuesRegisterForm(initialValues);
  });

  function handleBack() {
    if (step === "emailConfirmation") {
      setStep("security");
    } else if (step === "security") {
      setStep("personal");
    } else {
      history.replace(`/${slug}/auth`);
    }
  }

  function handleNextStep() {
    if (step === "personal") {
      setStep("security");
    } else if (step === "security") {
      setStep("emailConfirmation");
    }
  }

  function handleCloseModal() {
    setShowModalError(false);
    setErrorMessage("");
  }

  function handleNavigateToHome() {
    handleCloseModal();
    history.replace(`/${slug}/auth`);
  }

  async function handleRegister(data: FormDataType) {
    const {
      name,
      email,
      cpf,
      phone,
      region: { region },
      password,
    } = data;

    await mutateAsync(
      {
        appId: Number(appId),
        name,
        email,
        password,
        ...(cpf ? { cpf } : {}),
        ...(phone ? { fone: phone } : {}),
        ...(region ? { bairro: Number(region) } : {}),
      },
      {
        onSuccess: () => {
          history.replace(`/${slug}/auth/register/success`, { email });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const message = err.response?.data.type;

            setErrorMessage(Array.isArray(message) ? message[0] : message);

            setShowModalError(true);
          }

          console.error(err);
        },
      },
    );
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleBack}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {t("general.Cadastro")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center gap-4 px-4 py-6 ios:pb-2">
          <TabsPrimitive.Root className="h-full w-full" value={step} onValueChange={setStep}>
            <TabsPrimitive.Content value="personal" className="h-full w-full">
              <PersonalForm handleClick={handleNextStep} />
            </TabsPrimitive.Content>

            <TabsPrimitive.Content value="security" className="h-full w-full">
              <SecurityForm handleClick={handleNextStep} />
            </TabsPrimitive.Content>

            <TabsPrimitive.Content value="emailConfirmation" className="h-full w-full">
              <EmailConfirmationForm handleClick={handleRegister} />
            </TabsPrimitive.Content>
          </TabsPrimitive.Root>

          <Button
            size="lg"
            background="primary-500"
            full
            weight="medium"
            form={`${step}Form`}
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            rounded="md"
          >
            {step === "emailConfirmation"
              ? t("auth.Enviar confirmação para o meu e-mail")
              : t("general.Continuar")}
          </Button>
        </div>
      </Content>

      <Modal showModal={showModalError} onClose={handleCloseModal}>
        <ErrorRequest
          message={errorMessage}
          handleCloseModal={handleCloseModal}
          handleNavigateHome={handleNavigateToHome}
        />
      </Modal>
    </Layout>
  );
}
