import { ResponseInstitution } from "@/services/assetsScheduling";
import { createContext, useContext } from "react";

type AssetSchedulingContextData = {
  institution?: ResponseInstitution;
};

export const AssetSchedulingContext = createContext<AssetSchedulingContextData>({
  institution: undefined,
});

export const useAssetSchedulingContext = () => useContext(AssetSchedulingContext);
