import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonIcon, IonButton, IonGrid, IonInput } from "@ionic/react";
import { refresh, search } from "ionicons/icons";

import { PageTitle } from "@/components/old/SideMenu";
import { Block } from "@/components/old/Block";
import { BlockProtocol } from "@/components/old/BlockProtocol";
import { getAppSlug } from "@/services/old/starter";
import { getMyProtocols, getProtocolByIdV2 } from "@/services/old/servicos";
import { isLogged } from "@/services/old/auth";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function TabProtocolsList() {
  const [searchProtocolId, setSearchProtocolId] = useState("");
  const [protocol, setProtocol] = useState<any>(undefined);

  const CONTENT = getContent();
  const params: paramsPage = useParams();

  const { isLoading, data } = useQuery(["getMyProtocols"], () => getMyProtocols(params.SLUG), {
    enabled: isLogged(),
  });

  async function getProtocolId(id: any) {
    getProtocolByIdV2(id, params.SLUG)
      .then((response) => {
        setProtocol(response.data);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 404) {
          setProtocol(null);
        }
      });
  }

  function resetProtocol() {
    setProtocol(undefined);
    setSearchProtocolId("");
  }

  if (isLoading) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING}</PageTitle>;
  } else if (!data && !isLogged()) {
    return (
      <>
        {/* <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle> */}
        <IonGrid style={{ padding: "16px 0" }}>
          <Block title="Buscar Protocolo">
            <div style={{ display: "flex" }}>
              <IonInput
                type="number"
                placeholder="000"
                value={searchProtocolId}
                onIonInput={(e) => {
                  let text = "";
                  if (e.detail.value) {
                    text = e.detail.value;
                  }
                  text = text.replace(/\D/g, "");
                  text = text.replace(".", "");
                  text = text.replace(",", "");
                  text = text.replace("-", "");
                  setSearchProtocolId(text);
                }}
              />
              <IonButton onClick={() => getProtocolId(searchProtocolId)}>
                <IonIcon icon={search} />
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => {
                  resetProtocol();
                }}
              >
                <IonIcon icon={refresh} />
              </IonButton>
            </div>
          </Block>
          <div style={{ height: "25px" }}></div>
          {protocol && (
            <BlockProtocol
              key={protocol.id}
              routerLink={"/" + getAppSlug() + "/protocolo/" + protocol.id}
              type={protocol.categoriaServico?.titulo}
              number={protocol.id}
              category={protocol.subCategoriaServico?.titulo}
              createdAt={new Date(protocol.created_at)}
            />
          )}
        </IonGrid>
        {protocol === null && <PageTitle> {CONTENT.PROTOCOLS.CANT_ACCESS} </PageTitle>}
      </>
    );
  }

  return (
    <>
      {/* <PageTitle> {CONTENT.PROTOCOLS.PAGE_TITLE} </PageTitle> */}
      <IonGrid style={{ padding: "16px" }}>
        <Block title="Buscar Protocolo">
          <div style={{ display: "flex" }}>
            <IonInput
              type={"number"}
              placeholder={"XXX"}
              value={searchProtocolId}
              onIonInput={(e) => {
                let text = "";
                if (e.detail.value) {
                  text = e.detail.value;
                }
                text = text.replace(/\D/g, "");
                text = text.replace(".", "");
                text = text.replace(",", "");
                text = text.replace("-", "");
                setSearchProtocolId(text);
              }}
            />
            <IonButton
              onClick={() => {
                getProtocolId(searchProtocolId);
              }}
            >
              <IonIcon icon={search} />
            </IonButton>
            <IonButton
              color="danger"
              onClick={() => {
                resetProtocol();
              }}
            >
              <IonIcon icon={refresh} />
            </IonButton>
          </div>
        </Block>
        <div style={{ height: "25px" }}></div>
        {data && data.data.length === 0 && protocol === undefined && (
          <>
            <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
          </>
        )}
        {data &&
          protocol === undefined &&
          data?.data.map((item: any, index: number) => {
            return (
              <BlockProtocol
                key={index}
                routerLink={"/" + getAppSlug() + "/protocolo/" + item.id}
                type={item.categoriaServico?.titulo}
                number={item.id}
                category={item.subCategoriaServico?.titulo}
                createdAt={new Date(item.created_at)}
                status={item.status.titulo}
              />
            );
          })}
        {protocol && (
          <BlockProtocol
            key={protocol.id}
            routerLink={"/" + getAppSlug() + "/protocolo/" + protocol.id}
            type={protocol.categoriaServico?.titulo}
            number={protocol.id}
            category={protocol.subCategoriaServico?.titulo}
            createdAt={new Date(protocol.created_at)}
            status={protocol.status.titulo}
          />
        )}
      </IonGrid>
      {protocol === null && <PageTitle> {CONTENT.PROTOCOLS.CANT_ACCESS} </PageTitle>}
    </>
  );
}
