import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonGrid, IonRow, IonCol } from "@ionic/react";

import { PageTitle } from "@/components/old/SideMenu";
import { Line } from "@/components/old/Base";
import { BlockItem } from "@/components/old/BlockItem";
import { getAppSlug } from "@/services/old/starter";
import { getServicos } from "@/services/old/servicos";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function TabServiceListIcons() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data } = useQuery(["getservicos"], () => getServicos(params.SLUG), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  if (isLoading || !data) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING}</PageTitle>;
  }

  if (!data || data?.data.length === 0) {
    return (
      <>
        <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
      </>
    );
  }

  return (
    <>
      {/* <PageTitle> {CONTENT.SERVICES.PAGE_TITLE} </PageTitle> */}
      <IonGrid style={{ width: "100%", paddingBottom: "16px" }}>
        {data?.data.map((area: any, index: number) => {
          return (
            <div key={index}>
              <Line>{area.titulo}</Line>

              <IonRow style={{ padding: "0 8px" }}>
                {area.categorias?.map((categoriaItem: any, categoriaIndex: number) => {
                  return (
                    <IonCol size={area.categorias.length === 1 ? "12" : "6"} key={categoriaIndex}>
                      {categoriaItem.integracao && categoriaItem.abrir_externo && (
                        <BlockItem
                          openNewWindow={true}
                          href={categoriaItem.integracao}
                          customImage={categoriaItem.imagem}
                          title={categoriaItem.titulo}
                          type={categoriaItem.abrir_externo ? "link_externo" : "link_interno"}
                        />
                      )}
                      {categoriaItem.integracao && !categoriaItem.abrir_externo && (
                        <BlockItem
                          routerLink={
                            "/" + getAppSlug() + `/services-abrir-externo/${categoriaItem.id}`
                          }
                          customImage={categoriaItem.imagem}
                          title={categoriaItem.titulo}
                        />
                      )}
                      {!categoriaItem.integracao && !categoriaItem.abrir_externo && (
                        <BlockItem
                          routerLink={"/" + getAppSlug() + `/services-abrir/${categoriaItem.id}`}
                          customImage={categoriaItem.imagem}
                          title={categoriaItem.titulo}
                        />
                      )}
                    </IonCol>
                  );
                })}
              </IonRow>
            </div>
          );
        })}
      </IonGrid>
    </>
  );
}
