import styled from "styled-components";
import { IonText, IonIcon } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";

import { Block } from "@/components/old/Block";

const Container = styled.div`
  margin-top: 10px;
`;

const Content = styled.div`
  padding: 15px;
`;

const Link = styled.a`
  text-decoration: none;
`;

const ImageValue = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

const Image = styled.img`
  width: auto;
  max-height: 150px;
`;

const Item = styled(IonText)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TextTitle = styled.div`
  width: 100%;
  font-weight: bolder;
  color: #222428;
`;

const TextValue = styled.div`
  width: 100%;
`;

const TextValueSmall = styled.small`
  width: 100%;
`;

interface BlockExtrasFieldsProps {
  title?: string;
  values?: any;
  maxHeigh?: string;
}

function formatDate(date: string) {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date.replace(/-/g, "/")));
}

export function BlockExtrasFields(props: BlockExtrasFieldsProps) {
  return (
    <Container>
      <Block noPadding title={props.title}>
        <Content>
          {props.values.map((item: any, index: number) => {
            return (
              <Item key={index}>
                <TextTitle> {item.nome_campo}: </TextTitle>
                {(item.tipo === "string" || item.tipo === "integer") && item.resposta && (
                  <TextValue>{item.resposta}</TextValue>
                )}
                {item.tipo === "data" && item.resposta && (
                  <TextValue>{formatDate(item.resposta)}</TextValue>
                )}
                {item.tipo === "imagem" && item.resposta && (
                  <ImageValue>
                    <Link href={item.resposta} target="_blank">
                      <Image src={item.resposta} alt="" />
                    </Link>
                  </ImageValue>
                )}
                {item.tipo === "arquivo" && item.resposta && (
                  <Link href={item.resposta} target="_blank">
                    Baixar <IonIcon style={{ "font-size": "1.125rem" }} icon={downloadOutline} />
                  </Link>
                )}
                {!item.resposta && <TextValueSmall>Campo não respondido.</TextValueSmall>}
              </Item>
            );
          })}
        </Content>
      </Block>
    </Container>
  );
}
