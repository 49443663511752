import { create } from "zustand";
import { TermsOfUse } from "./useAppStore";

export type Review = {
  rating: number;
  comment: string;
};

export type Holder = {
  id: number;
  name: string;
  email: string | null;
  cpf: string;
  birthdayDate: string;
  imageUrl: string | null;
  phone: string | null;
};

export type Address = {
  title: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  postcode: string;
  street: string;
  number: string;
};

export type User = {
  id: number;
  name: string;
  email: string;
  imageUrl: string | null;
  phone: string | null;
  cpf: string | null;
  location: number | null;
  addresses: Array<Address>;
  verifiedEmail: boolean;
  holder: Holder | null;
  review: Review | null;
  termsOfUse: TermsOfUse | null;
};

interface IUseUserStoreParams {
  user: User | null;
  updateUser: (user: User | null) => void;
  getUserId: () => number | undefined;
  updateTermsOfUse: (terms: TermsOfUse) => void;
}

export const useUserStore = create<IUseUserStoreParams>((set, get) => {
  return {
    user: null,

    updateUser: (user: User | null) => set({ user }),

    getUserId: () => {
      const { user } = get();
      return user?.id;
    },

    updateTermsOfUse: (terms: TermsOfUse) => {
      const { user } = get();
      set({
        user: {
          ...user!,
          termsOfUse: terms,
        },
      });
    },
  };
});

export const useHolder = () => useUserStore((state) => state.user?.holder);
