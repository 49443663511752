import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";
import { ReactPortal } from "@/components/ReactPortal";
import { Modal } from "@/components";

interface ModalLogoutProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onConfirmation: () => void;
}

export function ModalLogout({ isOpen, onCloseModal, onConfirmation }: ModalLogoutProps) {
  const { t } = useTranslation();

  return (
    <ReactPortal>
      <Modal showModal={isOpen} onClose={onCloseModal}>
        <div className="flex flex-col items-center gap-6">
          <Text size="md" color="slate-700">
            Realmente deseja sair da conta?
          </Text>

          <div className="w-full space-y-3">
            <Button variant="secondary" size="lg" rounded="full" full onClick={onCloseModal}>
              Cancelar
            </Button>

            <Button variant="primary" size="lg" rounded="full" full onClick={onConfirmation}>
              {t("profile.Sair da conta")}
            </Button>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  );
}
