import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";

import { Icon, Link, Button, ModalContent } from "./styles";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  status: "error" | "success" | "warn";
  textButton: string;
  textLink?: string;
  onActionPrimary: () => void;
  onActionSecondary?: () => void;
}

const iconsByStatus = {
  error: <FiAlertCircle size={24} color="#dc2626" />,
  success: <FiCheckCircle size={24} color="#16a34a" />,
  warn: <FiAlertCircle size={24} color="#ca8a04" />,
};

export function Modal({
  isOpen,
  onClose,
  title,
  description,
  status,
  textButton,
  textLink,
  onActionPrimary,
  onActionSecondary,
}: ModalProps) {
  function handleActionButton() {
    onClose();
    onActionPrimary();
  }

  function handleActionLink() {
    onClose();

    if (onActionSecondary) {
      onActionSecondary();
    }
  }

  return (
    <ModalContent isOpen={isOpen} onDidDismiss={onClose}>
      <div className="content">
        <Icon status={status}>{iconsByStatus[status]}</Icon>

        <h3>{title}</h3>

        <span>{description}</span>

        <footer>
          <Button onClick={handleActionButton}>{textButton}</Button>
          {textLink && onActionSecondary && <Link onClick={handleActionLink}>{textLink}</Link>}
        </footer>
      </div>
    </ModalContent>
  );
}
