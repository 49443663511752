import { useLocation } from "react-router-dom";

import { TabTemplate } from "@/templates/old/TabTemplate";
import { TabProtocolsList } from "@/pages/old/Services/components/TabProtocolsList";
import { TabServiceListExpand } from "@/pages/old/Services/components/TabServiceListExpand";
import { getContent } from "@/content/index";

const firstTab = {
  value: "Serviços",
  content: <TabServiceListExpand />,
};

const secondTab = {
  value: "Protocolos",
  content: <TabProtocolsList />,
};

export function ServicesGroupsPage() {
  const location = useLocation();

  const CONTENT = getContent();

  const params = new URLSearchParams(location.search);

  const queryParams = params.get("tab" || "services");

  return (
    <TabTemplate
      selected={queryParams === "protocols" ? secondTab.value : firstTab.value}
      first={firstTab}
      second={secondTab}
      name={CONTENT.SERVICES.HEADER_TITLE}
    />
  );
}
