import { http } from "@/lib/axios";
import { CreateHolderReviewError, Holder } from "./types";
import { CreateReview } from "@/pages/Holder/hooks/useHolder";

export async function createHolder({
  appId,
  data,
}: {
  appId: number;
  data: CreateReview;
}): Promise<Holder> {
  try {
    const response = await http.post<Holder>(`/v3/${appId}/titular_review/store_reviews`, data);

    return response.data;
  } catch (error) {
    const errorMessage = error as CreateHolderReviewError;
    throw new Error(errorMessage.response.data.error as string);
  }
}
