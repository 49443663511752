import { useMutation } from "@tanstack/react-query";
import { validateCode } from "@/services/auth/forgotMyPassword";

interface useValidateCodeParams {
  appId: number;
  code: string;
}

export function useValidateCode() {
  return useMutation({
    mutationFn: ({ appId, code }: useValidateCodeParams) => {
      return validateCode({ appId, code });
    },
  });
}
