import dayjs from "dayjs";

type MarketMessageProps = {
  message: string;
  time: string;
};

export function MarketMessage({ message, time }: MarketMessageProps) {
  return (
    <div className="flex flex-wrap text-left font-sans text-xs [&_strong]:font-semibold">
      <div
        className="mb-1 rounded-b-md rounded-r-md bg-slate-100 p-2 text-slate-700 [overflow-wrap:anywhere] [&_a]:text-blue-600 [&_a]:underline"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <time dateTime={time} className="w-full text-slate-400">
        {dayjs(time).format("HH[h]mm")}
      </time>
    </div>
  );
}
