import { useMutation } from "@tanstack/react-query";
import { confirmMessagesProtocol } from "@/services/protocols";

interface useConfirmMessagesParams {
  appId: number;
  protocolId: number;
}

export function useConfirmMessages() {
  return useMutation({
    mutationFn: ({ appId, protocolId }: useConfirmMessagesParams) => {
      return confirmMessagesProtocol({ appId, protocolId });
    },
  });
}
