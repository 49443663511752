import { Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { IonButton, useIonViewDidEnter } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockSeller } from "@/pages/old/Feirinha/components/BlockSeller";
import { BlockMap } from "@/components/old/BlockMap";
import { ShareButton } from "@/components/old/ShareButton";
import { BlockProduct } from "@/pages/old/Feirinha/components/BlockProduct";
import { getProducts, getSeller } from "@/services/old/feirinha";
import { getAppSlug } from "@/services/old/starter";
import useQueryParams from "@/utils/queryParamsHook";
import { getContent } from "@/content/index";
import { Button } from "@astrolabe-ui/react";
import { useUserStore } from "@/store/useUserStore";
import { useUserToken } from "@/store/useAuthStore";

interface ProductPostParams {
  id: string;
  SLUG: string;
}

export function OpenSeller() {
  const params: ProductPostParams = useParams();
  const queryParams = useQueryParams();
  const history = useHistory();
  const CONTENT = getContent();

  const isAuthenticated = !!useUserToken();

  const userId = useUserStore((state) => state.user?.id);

  const seller = useQuery(["getSeller", params.id], () => getSeller(params.id, params.SLUG), {
    cacheTime: 0,
  });

  const { isLoading, data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
    ["getproductsSeller", params.id],
    ({ pageParam = 1 }) =>
      getProducts(
        {
          seller_id: +params.id,
          page: pageParam,
          modulo_id: queryParams.get("modulo_id") ?? undefined,
        },
        params.SLUG,
      ),
    {
      getNextPageParam: (res) => (res.data.next_page_url ? res.data.current_page + 1 : undefined),
    },
  );

  useIonViewDidEnter(() => refetch());

  function loadMore() {
    fetchNextPage();
  }

  function handleOpenChat() {
    if (!isAuthenticated) {
      history.push(`/${params.SLUG}/auth/login`);
      return;
    }

    history.push(`/${params.SLUG}/comerciantes/${params.id}/conversa`);
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={seller.data?.data[0].nome}>
      <BlockSeller
        sellerImage={seller.data?.data[0].imagem}
        sellerName={seller.data?.data[0].nome}
        facebook={seller.data?.data[0].social?.facebook}
        instagram={seller.data?.data[0].social?.instagram}
        sellerPhone={seller.data?.data[0].telefone}
        twitter={seller.data?.data[0].social?.twitter}
        youtube={seller.data?.data[0].social?.youtube}
        sellerCategory={seller.data?.data[0].categoria?.titulo}
        address={`${seller.data?.data[0].logradouro ?? ""}${
          seller.data?.data[0].numero ? "," : ""
        } ${seller.data?.data[0].numero ?? ""}${seller.data?.data[0].bairro ? "," : ""}  ${
          seller.data?.data[0].bairro?.titulo ?? ""
        }`}
      />

      {userId === seller.data?.data[0].usuario_id && (
        <Link to={`/${getAppSlug()}/seller-register`}>
          <Button variant="primary" className="mt-3" full>
            Editar perfil de comerciante
          </Button>
        </Link>
      )}

      {userId === seller.data?.data[0].usuario_id && (
        <Link to={`/${getAppSlug()}/novo-produto?modulo_id=${queryParams.get("modulo_id")}`}>
          <Button variant="primary" className="mt-3" full>
            Cadastrar Item
          </Button>
        </Link>
      )}

      {userId === seller.data?.data[0].usuario_id && (
        <Link to={`/${getAppSlug()}/comerciantes/${seller.data?.data[0].id}/conversas`}>
          <Button variant="primary" className="mt-3" full>
            Visualizar mensagens
          </Button>
        </Link>
      )}

      {seller.data?.data[0].latitude && seller.data?.data[0].longitude && (
        <BlockMap lat={seller.data?.data[0].latitude} lng={seller.data?.data[0].longitude} />
      )}

      <div className="mt-2 flex items-center gap-2">
        <ShareButton
          url={"https://mobby.app.br/" + params.SLUG + "/abrir-vendedor/" + params.id}
          title={seller.data?.data[0].nome}
        />

        {data?.pages?.[0].data?.data[0]?.modulo.habilitar_chat &&
        seller.data?.data[0].usuario_id !== userId ? (
          <Button variant="secondary" onClick={handleOpenChat}>
            Enviar mensagem
          </Button>
        ) : null}
      </div>

      {data?.pages.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            {item.data.data.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <BlockProduct
                    title={item.titulo}
                    productPrice={item.valor}
                    productAmount={item.unidade}
                    img_src={item.imagem}
                    productStatus={item.situacao}
                    openURL={"/" + getAppSlug() + "/abrir-produto/" + item.id}
                  />
                </div>
              );
            })}
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
