import { createElement } from "react";
import { IonRippleEffect } from "@ionic/react";
import { Icon } from "@phosphor-icons/react";
import { Text } from "@astrolabe-ui/react";

interface NavLinkProps {
  title: string;
  icon: Icon;
  onClick: () => void;
}

export function NavButton({ title, icon, onClick }: NavLinkProps) {
  return (
    <button
      onClick={onClick}
      className="ion-activatable ripple-parent flex w-full items-center gap-2 px-4 py-3"
    >
      {createElement(icon, { size: 22, className: "text-slate-700" })}

      <Text size="md" color="slate-700">
        {title}
      </Text>

      <IonRippleEffect className="text-slate-200" />
    </button>
  );
}
