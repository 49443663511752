import * as Accordion from "@radix-ui/react-accordion";
import { Text } from "@astrolabe-ui/react";
import { CaretDown } from "@phosphor-icons/react";

import { CustomIcon, IconPhosphorProps } from "@/components/CustomIcon";

type CardFilteredQuestionProps = {
  id: number;
  title: string;
  content: string;
  category: {
    title: string;
    icon: IconPhosphorProps;
  };
};

export function CardFilteredQuestion({ id, title, content, category }: CardFilteredQuestionProps) {
  return (
    <Accordion.Item
      key={id}
      value={id.toString()}
      className="w-full rounded-md border-thin border-slate-200 px-3 py-4"
    >
      <Accordion.Header>
        <Accordion.Trigger className="flex w-full items-center justify-between gap-2">
          <div className="flex flex-col items-start gap-2">
            <div className="flex items-center gap-2">
              <CustomIcon icon={category.icon} size={14} className="text-slate-400" />
              <Text size="xs" color="slate-400" weight="medium">
                {category.title}
              </Text>
            </div>

            <Text
              size="xs"
              weight="medium"
              color="slate-700"
              className="text-left"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <CaretDown
            className="flex flex-shrink-0 text-slate-700 transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180"
            aria-hidden
          />
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content className="text-xs text-slate-400">
        <div className="pt-2" dangerouslySetInnerHTML={{ __html: content }} />
      </Accordion.Content>
    </Accordion.Item>
  );
}
