import { useQuery } from "@tanstack/react-query";

import { getLocals } from "@/services/schedulings";

type useSchedulingParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  schedulingId?: number;
};

export function useLocals({ appId, entityId, resourceId, schedulingId }: useSchedulingParams) {
  return useQuery({
    queryKey: ["locals", entityId, resourceId, schedulingId, appId],
    queryFn: () => getLocals({ appId, entityId, resourceId, schedulingId }),
    enabled: !!entityId && !!resourceId && !!appId,
  });
}
