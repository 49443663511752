import { useMutation } from "@tanstack/react-query";
import { triggerEmail } from "@/services/auth/forgotMyPassword";

interface useTriggerEmailParams {
  appId: number;
  username: string;
}

export function useTriggerEmail() {
  return useMutation({
    mutationFn: ({ appId, username }: useTriggerEmailParams) => {
      return triggerEmail({ appId, username });
    },
  });
}
