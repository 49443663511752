import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z, ZodSchema } from "zod";
import { cpf } from "cpf-cnpj-validator";
import { useAtom } from "jotai";
import { Text, TextInput } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { Field } from "../components/question";
import { formatCpf, formatPhone, removeCharacteres } from "@/utils/formats";
import { createSchedulingDataAtom } from "../atoms/create-scheduling-data-atom";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useUserStore } from "@/store/useUserStore";
import i18n from "@/lib/i18next";

type PersonalSchemaData = {
  name: string;
  cpf: string;
  phone: string;
  email: string;
};

type PersonalFormData = PersonalSchemaData & Record<string, string | number>;

export function FormPersonal() {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [{ resource, personal, extraFields }, updateCreateSchedulingData] =
    useAtom(createSchedulingDataAtom);

  const user = useUserStore((state) => state.user);

  const fields: Array<{ name: string; fieldType: ZodSchema }> =
    resource?.extraFields.map((item) => ({
      name: String(item.id),
      fieldType: generateFieldType({ type: item.type, required: item.required }),
    })) ?? [];

  let emailSchema = z.string();

  if (resource?.enabledQueue) {
    emailSchema = emailSchema
      .min(1, i18n.t("auth.Informe seu e-mail"))
      .email(i18n.t("auth.Informe um e-mail válido"));
  }

  const schema = z.object({
    name: z.string().min(1, i18n.t("auth.Informe seu nome")),
    cpf: z
      .string()
      .transform((value) => removeCharacteres(value))
      .refine((value) => cpf.isValid(value), {
        message: i18n.t("auth.Informe um CPF válido"),
      }),
    phone: z
      .string()
      .min(1, i18n.t("auth.Informe seu celular"))
      .transform((value) => removeCharacteres(value)),
    email: emailSchema,
    ...Object.fromEntries(fields.map((field) => [field.name, field.fieldType])),
  });

  const methods = useForm<PersonalFormData>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      name: personal?.name,
      cpf: personal?.cpf ? formatCpf(personal.cpf) : undefined,
      email: personal?.email,
      phone: personal?.phone ? formatPhone(personal.phone) : undefined,
    },
  });

  useEffect(() => {
    if (user && !personal) {
      const values = methods.getValues();
      methods.reset({
        ...(!values.name ? { name: user.name } : {}),
        ...(!values.cpf && user.cpf ? { cpf: formatCpf(user.cpf) } : {}),
        ...(!values.email ? { email: user.email } : {}),
        ...(!values.phone && user.phone ? { phone: formatPhone(user.phone) } : {}),
      });
    }
  }, [methods, personal, user]);

  useEffect(() => {
    extraFields.forEach((field) => {
      methods.setValue(String(field.id), field.response);
    });
  }, [extraFields, methods]);

  function onSubmit({ name, cpf, email, phone, ...fields }: PersonalFormData) {
    const extraFields = getFormattedExtraFields(fields);

    updateCreateSchedulingData((current) => ({
      ...current,
      personal: {
        name,
        cpf,
        email,
        phone,
      },
      extraFields,
    }));

    if (resource?.enabledQueue) {
      handleChangeStep("validation-queue");
      return;
    }

    handleChangeStep("local");
  }

  function getFormattedExtraFields(fields: { [x: number]: string | number }) {
    return Object.entries(fields).reduce(
      (acc, [id, response]) => {
        const extraField = resource?.extraFields?.find((item) => item.id === Number(id));

        if (extraField) {
          acc.push({
            id: Number(id),
            title: extraField.name,
            type: extraField.type,
            response,
          });
        }

        return acc;
      },
      [] as Array<{
        id: number;
        title: string;
        type: "string" | "integer" | "data" | "imagem" | "arquivo" | "select";
        response: string | number;
      }>,
    );
  }

  function generateFieldType({ type, required }: { type: string; required: boolean }) {
    let fieldType = type === "integer" ? z.number() : z.string();

    if (required) {
      fieldType = fieldType.min(1, "Esse campo é obrigatório");
    }

    return fieldType;
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;

  return (
    <div className="flex w-full flex-1 flex-col gap-5 px-4 py-6 ios:pb-2">
      <div className="space-y-1">
        <Text
          asChild
          size="lg"
          weight="medium"
          color="primary-500"
          className="text-[24px] leading-[28px]"
        >
          <h1>Informe os dados da pessoa que deseja agendar</h1>
        </Text>
        <Text size="xs">
          Você pode realizar o agendamento para outra pessoa. Um parente, por exemplo.
        </Text>
      </div>

      <FormProvider {...methods}>
        <form
          id="form-personal"
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-1 flex-col gap-4"
        >
          <TextInput
            floating
            placeholder="Nome completo"
            size="lg"
            error={errors.name?.message}
            {...register("name")}
          />

          <TextInput
            floating
            id="cpf"
            type="tel"
            inputMode="numeric"
            placeholder={t("auth.CPF")}
            error={errors.cpf?.message}
            {...register("cpf", {
              onChange: (event) => setValue("cpf", formatCpf(event.target.value)),
            })}
          />

          <TextInput
            floating
            id="phone"
            type="tel"
            inputMode="numeric"
            placeholder={t("auth.Celular")}
            maxLength={17}
            error={errors.phone?.message}
            {...register("phone", {
              onChange: (event) => setValue("phone", formatPhone(event.target.value)),
            })}
          />

          <TextInput
            floating
            type="email"
            placeholder={t("auth.E-mail")}
            autoCapitalize="none"
            error={errors.email?.message}
            {...register("email")}
          />

          {resource?.extraFields.map((item) => <Field key={item.id} field={item} />)}
        </form>
      </FormProvider>

      <Button
        type="submit"
        form="form-personal"
        disabled={!isValid}
        full
        size="lg"
        className="mt-auto"
      >
        {t("general.Continuar")}
      </Button>
    </div>
  );
}
