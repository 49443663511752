import axios from "axios";

import { http } from "@/lib/axios";
import { getAppId } from "./provider";
import { getAccessToken } from "./auth";
import { CapacitorHttp } from "@capacitor/core";

export async function getGalleryOverlayImage(module: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/modulos/fotos/${module}`);
}

export async function getGalleryModulesList(page: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/modulos/fotos?page=${page}&paginate=1`);
}

export async function getGalleryModulesById(idModule: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/modulos/fotos/${idModule}`);
}

export async function getGalleryModulosPhotos(idModule: string, page: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/` + APP_ID + `/modulos/fotos/${idModule}/fotos?page=${page}&paginate=1`);
}

export async function sendSelfie(data: FormData, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  const headers = {
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const request = http.post(`v2/` + APP_ID + "/modulos/fotos/fotos", data, headers);

  return request;
}

export function getS3AwsURL(endpoint: string) {
  const endpointFormated = endpoint;
  // Verifica se a url é da amazon s3 e está no padrão que gera erro de cors
  // Ambas funcionam, mas é relatado instabilidade no dominio/bucket com cors
  // Errado   => dominio/bucket => https://s3-us-east-2.amazonaws.com/prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png
  // Correto  => bucket.dominio => https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/15de15d909e4f9.png

  const regexMatch = endpointFormated.match(/http.:..s3-([^"]*).amazonaws.com.([^"]*)/);

  if (regexMatch && regexMatch[2]) {
    // https://s3-us-east-2.amazonaws.com/prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png

    // regexMatch[2]                                                =  prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png

    const bucketName = regexMatch[2].split("/")[0];
    // regexMatch[2].split('/')[0];                                 =  prefeituradofuturo-prefeitura-4.0

    endpoint = endpoint.replace(bucketName + "/", "");
    // endpoint.replace(bucketName + "/", "")                       = https://s3-us-east-2.amazonaws.com/15de15d909e4f9.png

    const requestType = endpoint.split("://")[0] + "://";
    // endpoint.split("://")[0] + "://                              = https://

    endpoint = endpoint.replace(requestType, requestType + bucketName + ".");
    // endpoint.replace(requestType, requestType + bucketName + ".") = https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/15de15d909e4f9.png
  }

  return endpoint;
}

export async function getS3Aws(endpoint: string) {
  // const endpointFormated =
  //   Capacitor.isNativePlatform() && endpoint.match(/^http.+s3.+amazonaws\.com.+$/)
  //     ? getS3AwsModify(endpoint)
  //     : endpoint;
  // Verifica se a url é da amazon s3 e está no padrão que gera erro de cors
  // Ambas funcionam, mas é relatado instabilidade no dominio/bucket com cors
  // Errado   => dominio/bucket => https://s3-us-east-2.amazonaws.com/prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png
  // Correto  => bucket.dominio => https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/15de15d909e4f9.png

  return CapacitorHttp.get({
    url: endpoint,
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
}

export function getS3AwsModify(endpoint: string) {
  return (
    "https://" +
    endpoint
      .split("/")
      .slice(2, 4)
      .reverse()
      .join(".")
      .concat(endpoint.slice(endpoint.lastIndexOf("/")))
  );
}

export async function getS3AwsOrg(endpoint: string) {
  const endpointFormated = endpoint;
  // Verifica se a url é da amazon s3 e está no padrão que gera erro de cors
  // Ambas funcionam, mas é relatado instabilidade no dominio/bucket com cors
  // Errado   => dominio/bucket => https://s3-us-east-2.amazonaws.com/prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png
  // Correto  => bucket.dominio => https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/15de15d909e4f9.png

  const regexMatch = endpointFormated.match(/http.:..s3-([^"]*).amazonaws.com.([^"]*)/);

  if (regexMatch && regexMatch[2]) {
    // https://s3-us-east-2.amazonaws.com/prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png

    // regexMatch[2]                                                =  prefeituradofuturo-prefeitura-4.0/15de15d909e4f9.png

    const bucketName = regexMatch[2].split("/")[0];
    // regexMatch[2].split('/')[0];                                 =  prefeituradofuturo-prefeitura-4.0

    endpoint = endpoint.replace(bucketName + "/", "");
    // endpoint.replace(bucketName + "/", "")                       = https://s3-us-east-2.amazonaws.com/15de15d909e4f9.png

    const requestType = endpoint.split("://")[0] + "://";
    // endpoint.split("://")[0] + "://                              = https://

    endpoint = endpoint.replace(requestType, requestType + bucketName + ".");
    // endpoint.replace(requestType, requestType + bucketName + ".") = https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/15de15d909e4f9.png
  }

  const http = axios.create({
    baseURL: endpoint,
  });

  return http.get(endpoint, { responseType: "blob" });
}
