import { http } from "@/lib/axios";
import dayjs from "dayjs";

import { News, NewsCategory } from "./types";

type NewsDataHttpResponse = {
  id: number;
  titulo: string;
  imagem: string;
  categoria: { nome: string };
  created_at: string;
  multi_categorias: Array<{ nome: string; id: number }>;
};

interface getNewsHttpResponse {
  data: Array<NewsDataHttpResponse>;
  current_page: number;
  next_page_url: string | null;
  total: number;
}

interface getNewsResponse {
  data: Array<News>;
  currentPage: number;
  nextPage: boolean;
  total: number;
}

interface getNewsParams {
  appId: number;
  page: number;
  filters: {
    title: string;
    categories: Array<NewsCategory>;
  };
}

export async function getNews({ appId, page, filters }: getNewsParams): Promise<getNewsResponse> {
  const { title, categories } = filters;

  const filtersFormatted = {
    ...(title ? { title: `titulo:unaccent:${title}` } : ""),
  };

  const filtersCleared = Object.values(filtersFormatted)
    .filter((item) => item !== "")
    .join(";");

  const response = await http.get<getNewsHttpResponse>(`v3/${appId}/noticias`, {
    params: {
      paginate: 1,
      page,
      ...(filtersCleared ? { conditions: filtersCleared } : {}),
      ...(categories.length ? { categorias: `${categories.map((item) => item.categoryId)}` } : ""),
    },
  });

  const news = response.data.data.map((item) => ({
    id: item.id,
    title: item.titulo,
    imageUrl: item.imagem,
    category: item.categoria.nome,
    categories: item.multi_categorias,
    like: false,
    time: formatDate(item.created_at),
  }));

  return {
    currentPage: response.data.current_page,
    nextPage: !!response.data.next_page_url,
    total: response.data.total,
    data: news,
  };
}

function formatDate(date: string) {
  return dayjs(date).fromNow();
}
