import { IonPage, IonContent, IonSpinner } from "@ionic/react";
import styled from "styled-components";

import mobbyLogoImg from "@/assets/images/mobby_logo.svg";

const Center = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Logo = styled.img`
  margin: 0 auto;
  max-width: 280px;

  @media (max-width: 400px) {
    max-width: 180px;
  }
`;

export function Splash() {
  return (
    <IonPage>
      <IonContent>
        <Center>
          <Logo src={mobbyLogoImg} />
          <IonSpinner />
          <span> Bem vindo!</span>
        </Center>
      </IonContent>
    </IonPage>
  );
}
