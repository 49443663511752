import { extendTailwindMerge } from "tailwind-merge";
import { borderRadius, borderWidhts, space } from "@astrolabe-ui/tokens";

export const customTwMerge = extendTailwindMerge({
  theme: {
    colors: ["primary", "header", "textHeader"],
    borderRadius: Object.keys(borderRadius),
    borderWidth: Object.keys(borderWidhts),
    spacing: Object.keys(space),
  },
});
