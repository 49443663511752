import { useInfiniteQuery } from "@tanstack/react-query";
import { GetProtocolsParamsFilter, getProtocols } from "@/services/protocols";

interface useProtocolsParams {
  appId: number;
  filters?: GetProtocolsParamsFilter;
  orderby?: string;
}

export function useProtocols({ appId, filters, orderby = "id:desc" }: useProtocolsParams) {
  return useInfiniteQuery({
    queryKey: ["my-protocols", filters, orderby, appId],
    queryFn: ({ pageParam = 1 }) => getProtocols({ appId, filters, page: pageParam, orderby }),
    getNextPageParam: (lastPage) => (lastPage.next_page ? lastPage.current_page + 1 : undefined),
    enabled: !!appId,
  });
}
