import { ReactNode } from "react";
import { IonPage } from "@ionic/react";

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <IonPage>
      <main className="hide-scrollbar flex min-h-full flex-col overflow-auto bg-white">
        {children}
      </main>
    </IonPage>
  );
}
