import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { IonSkeletonText, useIonRouter } from "@ionic/react";
import {
  CalendarCheck,
  CalendarStar,
  CalendarX,
  CaretLeft,
  Compass,
  Copy,
  NavigationArrow,
  ShareNetwork,
} from "@phosphor-icons/react";
import { Text } from "@astrolabe-ui/react";

import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";
import { ScheduleCard } from "../../components/schedule-card";
import { Header } from "@/components/Header";
import { Button, HeaderButton } from "@/components/index";
import { DynamicMap } from "@/components/DynamicMap/DynamicMap";
import { useUserToken } from "@/store/useAuthStore";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useSubscribeToEvent, useUnsubscribeFromEvent } from "./hooks/use-subscribe-post";
import { useEventPost } from "./hooks/use-event-post";
import { useTrackerEvent } from "./hooks/use-tracker-event";

export function EventOpen() {
  const history = useIonRouter();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const params = useParams<{ id: string }>();
  const eventId = Number(params.id);
  const slug = useAppSlug();

  const isLogged = !!useUserToken();

  const { mutateAsync: subscribe, isLoading: subscribeLoading } = useSubscribeToEvent();
  const { mutateAsync: unsubscribe, isLoading: unsubscribeLoading } = useUnsubscribeFromEvent();

  const { data, isLoading, isError } = useEventPost({ appId, id: eventId });

  const isDuritingEvent = useMemo(() => {
    if (!data) return false;
    return dayjs().isBetween(dayjs(data.startDate), dayjs(data.endDate), "milliseconds", "[]");
  }, [data]);

  const { data: tracker } = useTrackerEvent({ id: eventId, enabled: isDuritingEvent });

  const lat = Number(data?.latitude);
  const long = Number(data?.longitude);

  const copyAddress = async (address: string | null) => {
    if (!address) return;

    try {
      await navigator.clipboard.writeText(address);
      toast.success("Endereço copiado!");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Erro ao copiar o endereço!");
    }
  };

  const openInGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${lat},${long}`;
    window.open(url, "_blank");
  };

  const handleSubscribe = async () => {
    await subscribe(
      { appId, eventId },
      {
        onSuccess: () => {
          toast.success("Inscrito com sucesso no evento!");
          queryClient.invalidateQueries(["event", eventId]);
        },
        onError: () => {
          toast.error("Erro ao inscrever-se no evento.");
        },
      },
    );
  };

  const handleUnsubscribe = async () => {
    await unsubscribe(
      { appId, eventId },
      {
        onSuccess: () => {
          toast.success("Inscrição cancelada com sucesso!");
          queryClient.invalidateQueries(["event", eventId]);
        },
        onError: () => {
          toast.error("Erro ao cancelar inscrição.");
        },
      },
    );
  };

  function handleGoBack() {
    history.goBack();
  }

  function handleOpenNews(id: number) {
    history.push(`/${slug}/evento/${id}`);
  }

  async function share({ title, url }: { title?: string; url: string }) {
    try {
      if (!Capacitor.isNativePlatform()) {
        if (window.navigator.share) {
          window.navigator.share({
            text: title,
            title,
            url,
          });
          return;
        }

        await copyToClipboard(url);

        toast.success("Copiado para área de transferência");
      } else {
        Share.share({
          dialogTitle: "Compartilhar",
          text: title,
          title,
          url,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center justify-between">
          <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />

          <Text size="lg" weight="medium" leading="relaxed" className="text-textHeader">
            {t("modules.Eventos")}
          </Text>

          <HeaderButton
            icon={<ShareNetwork className="text-white" />}
            onClick={() =>
              share({
                url: `https://web.mobby.app.br/${slug}/evento/${eventId}`,
                title: data?.title,
              })
            }
          />
        </div>
      </Header>

      <Content>
        <div className="mx-auto min-h-full w-full max-w-3xl pb-6 ios:pb-2">
          {isLoading && !data ? <EventOpenSkeleton /> : null}

          {!isLoading && isError ? (
            <Text size="md" weight="medium" className="pt-6 text-center">
              {t("events.Ocorreu um erro ao carregar os eventos")}
            </Text>
          ) : null}

          {!isLoading && data ? (
            <div className="flex flex-col gap-4">
              <img src={data.image} alt="img" className="w-full" />

              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-3 px-4">
                  <Text size="lg" weight="medium" className="text-slate-700">
                    {data.title}
                  </Text>

                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <CalendarCheck
                        size={20}
                        weight="fill"
                        color="#94A3B8"
                        className="flex-shrink-0"
                      />
                      <Text weight="medium" className="text-slate-700">
                        {data.startDateFormatted}
                      </Text>
                    </div>

                    {data.endDateFormatted ? (
                      <>
                        <Text weight="medium" className="text-slate-700">
                          -
                        </Text>
                        <Text weight="medium" className="text-slate-700">
                          {data.endDateFormatted}
                        </Text>
                      </>
                    ) : null}
                  </div>

                  {data.place ? (
                    <div className="flex items-center gap-2">
                      <NavigationArrow
                        size={20}
                        weight="fill"
                        className="flex-shrink-0 text-slate-500"
                      />
                      <Text weight="medium" color="slate-700">
                        {data.place}
                      </Text>
                    </div>
                  ) : null}

                  {isLogged && data.subscribeEnabled && dayjs(data.endDate).isAfter(dayjs()) ? (
                    <Button
                      full
                      variant="secondary"
                      size="lg"
                      onClick={data.registered ? handleUnsubscribe : handleSubscribe}
                      loading={subscribeLoading || unsubscribeLoading}
                      {...(!subscribeLoading && !unsubscribeLoading
                        ? { leftIcon: data.registered ? <CalendarX /> : <CalendarStar /> }
                        : {})}
                    >
                      {data.registered ? "Remover da agenda" : "Adicionar à agenda"}
                    </Button>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1 px-4">
                  <Text size="lg" weight="medium" className="text-slate-700">
                    {t("events.Descrição do evento")}
                  </Text>
                  <div
                    className="prose prose-slate mx-auto mt-2 font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </div>

                {data.childrens.length ? (
                  <ScheduleCard
                    handleOpenNews={handleOpenNews}
                    title={data.tituloFilhos}
                    events={data.childrens}
                  />
                ) : null}

                {data.place ? (
                  <div className="flex flex-col gap-4">
                    <Text size="lg" weight="medium" className="px-4 text-slate-700">
                      {t("events.Localização")}
                    </Text>

                    {lat && long ? (
                      <div className="z-40 h-32 w-full">
                        <DynamicMap
                          latitude={lat}
                          longitude={long}
                          {...(tracker
                            ? {
                                trackerCoordinates: {
                                  latitude: tracker.latitude,
                                  longitude: tracker.longitude,
                                },
                              }
                            : {})}
                        />
                      </div>
                    ) : null}

                    <div className="flex flex-col px-4">
                      <Text size="md" weight="normal" className="text-slate-700">
                        {t("events.Endereço")}
                      </Text>
                      <Text size="sm" weight="normal" className="text-slate-500">
                        {data.place}
                      </Text>
                    </div>

                    <div className="flex gap-2 px-4">
                      <Button
                        leftIcon={<Compass />}
                        variant="tertiary"
                        className="border-medium border-slate-100"
                        onClick={openInGoogleMaps}
                      >
                        {t("events.Abrir no mapa")}
                      </Button>

                      <Button
                        leftIcon={<Copy />}
                        variant="tertiary"
                        onClick={() => copyAddress(data.place)}
                      >
                        {t("events.Copiar endereço")}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}

export function EventOpenSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      <IonSkeletonText animated className="h-60 w-full" style={{ height: "200px" }} />
      <div className="flex flex-col gap-3 px-4">
        <IonSkeletonText animated className="h-16 w-full" />

        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <IonSkeletonText animated className="h-6 w-6" />
            <IonSkeletonText animated className="h-6 w-10" />
          </div>

          <div className="flex items-center gap-2">
            <IonSkeletonText animated className="h-6 w-6" />
            <IonSkeletonText animated className="h-6 w-24" />
          </div>
        </div>

        <IonSkeletonText animated className="mt-2 h-6 w-full" />

        <div className="flex items-center gap-2">
          <IonSkeletonText animated className="h-6 w-6" />
          <IonSkeletonText animated className="h-6 w-3/4" />
        </div>

        <IonSkeletonText animated className="mt-2 h-6 w-full" />

        <IonSkeletonText animated className="mt-2 h-32 w-full" />
      </div>
    </div>
  );
}
