import styled from "styled-components";

import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";
import maintenanceImg from "@/assets/images/maintenance.gif";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  text-transform: uppercase;
  background-color: #fff;
`;

export function Maintenance() {
  const SLUG = getSlugFromURL();

  const { GLOBAL } = getContent();

  const maintenanceKey = `${SLUG}_${STORAGE.MAINTENANCE.KEY}`;

  if (localStorage.getItem(maintenanceKey) !== "true") {
    window.location.reload();
  }

  function getSlugFromURL() {
    let url = window.location.href;

    url = url.replace("https://www.mobby.app.br/", "");
    url = url.replace("http://localhost:8100/", "");
    url = url.replace("http://localhost:3000/", "");

    const slug = url.split("/")[0];

    return slug;
  }

  return (
    <Container>
      <div>
        <h4>{GLOBAL.MAINTENANCE}</h4>
      </div>
      <div>
        <img src={maintenanceImg} alt="Em manutenção" />
      </div>
    </Container>
  );
}
