import { Text } from "@astrolabe-ui/react";
import { InstagramEmbed } from "react-social-media-embed";

export default function InstagramSection() {
  return (
    <section className="mx-auto flex w-full max-w-6xl flex-col gap-4 bg-white pb-6 pt-2">
      <header className="flex w-full items-center justify-between px-4">
        <Text asChild color="slate-700" weight="medium" className="text-md lg:text-lg">
          <strong>Instagram</strong>
        </Text>
      </header>

      <div className="flex justify-center overflow-hidden px-4">
        <InstagramEmbed
          url="https://www.instagram.com/participativorn"
          className="w-full max-w-[400px]"
        />
      </div>
    </section>
  );
}
