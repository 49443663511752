import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-hot-toast";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { Clock } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";

import { Button } from "@/components/Button";
import { StepIndicator } from "@/pages/Auth/components/StepIndicator";
import { CodeInput } from "../components/CodeInput";
import { SuccessfullyValidatedToken } from "../components/SuccessfullyValidatedToken";
import { useValidateCode } from "../hooks/useValidateToken";
import { useTriggerEmail } from "../hooks/useTriggerEmail";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { forgotMyPasswordFormAtom } from "..";
import { Modal } from "@/components";
import { useConfig } from "@/hooks/useConfig";

const usernameFormSchema = z.object({
  code: z.string().min(5, "Informe o código").max(5),
});

type PersonalFormData = z.infer<typeof usernameFormSchema>;

interface UsernameFormProps {
  handleNextStep: () => void;
  handleSaveCode?: (code: string) => void;
}

export function CodeForm({ handleNextStep }: UsernameFormProps) {
  const { t } = useTranslation();

  const history = useHistory();
  const slug = useAppSlug();
  const appId = useAppId();

  const [dataForm, setDataForm] = useAtom(forgotMyPasswordFormAtom);

  const [showModal, setShowModal] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [currentSeconds, setCurrentSeconds] = useState(60);

  const allowHelp = useConfig({
    label: "external.suport.help",
  });
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (showTimer) {
      const interval = setInterval(() => {
        const newValue = currentSeconds - 1;

        if (newValue === 0) {
          setCurrentSeconds(60);
          setShowTimer(false);
          return;
        }

        setCurrentSeconds(newValue);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [currentSeconds, showTimer]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalFormData>({
    resolver: zodResolver(usernameFormSchema),
    mode: "onChange",
    defaultValues: {
      code: dataForm.code,
    },
  });

  const { mutateAsync, isLoading } = useValidateCode();

  const { mutateAsync: mutateAsyncResendEmail, isLoading: isLoadingResendEmail } =
    useTriggerEmail();

  async function onSubmit(data: PersonalFormData) {
    const { code } = data;

    await mutateAsync(
      {
        appId: Number(appId),
        code,
      },
      {
        onSuccess: () => {
          handleNextStep();

          setDataForm({
            ...dataForm,
            code,
          });

          setShowModal(true);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setShowHelp(true);
            if (err.response?.status === 422) {
              toast.error(t("auth.Código inválido"));
              return;
            }
          }

          toast.error(t("auth.Erro ao validar código"));

          console.error(err);
        },
      },
    );
  }

  async function handleResendEmail() {
    setShowHelp(true);
    await mutateAsyncResendEmail(
      {
        appId: Number(appId),
        username: dataForm.username,
      },
      {
        onSuccess: () => {
          toast.success(t("auth.Código reenviado"));
          setShowTimer(true);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response?.status === 422) {
              toast.error(t("auth.Não encontramos o usuário"));
              return;
            }
          }

          toast.error(t("auth.Erro ao buscar usuário"));

          console.error(err);
        },
      },
    );
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function loginProblems() {
    history.push(`/${slug}/help`);
  }

  return (
    <form
      id="personalForm"
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col gap-8"
    >
      <StepIndicator
        step={2}
        title={t("auth.Digite o código de verificação")}
        description={t(
          "auth.Você recebeu um número de 5 dígitos por email. Caso não encontre em sua caixa de entrada, procure em seu spam.",
        )}
      />

      <div className="flex flex-1 flex-col">
        <Controller
          name="code"
          control={control}
          render={({ field: { value, onChange } }) => (
            <CodeInput code={value} onChange={onChange} error={!!errors.code} />
          )}
        />

        <footer className="mt-auto flex flex-col gap-3">
          {allowHelp && showHelp ? (
            <Button size="lg" variant="tertiary" full onClick={loginProblems}>
              {t("auth.Preciso de ajuda")}
            </Button>
          ) : null}

          <Button
            size="lg"
            variant="tertiary"
            full
            rounded="md"
            type="button"
            className="border-slate-200 [&>svg]:h-4 [&>svg]:w-4"
            onClick={handleResendEmail}
            loading={isLoadingResendEmail}
            disabled={showTimer}
          >
            {showTimer ? (
              <>
                <Clock weight="bold" />
                {currentSeconds.toString().padStart(2, "0")} {t("general.segundos")}
              </>
            ) : (
              t("auth.Reenviar código")
            )}
          </Button>

          <Button
            size="lg"
            background="primary-500"
            full
            rounded="md"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("auth.Validar código")}
          </Button>
        </footer>
      </div>

      <Modal showModal={showModal} onClose={handleCloseModal}>
        <SuccessfullyValidatedToken handleClose={handleCloseModal} />
      </Modal>
    </form>
  );
}
