import { useTranslation } from "react-i18next";
import {
  CardRoot,
  CardContent,
  CardTitle,
  CardDescription,
  CardInfo,
  CardIndicator,
} from "@astrolabe-ui/react";

import { useInstitutionContext } from "../../contexts/InstitutionContext";
import { useStepsContext } from "../../contexts/StepsContext";
import { totalVacancies } from "./utils/totalVacancies";

export const Sectors = () => {
  const { t } = useTranslation();

  const { institution } = useInstitutionContext();

  const {
    handleNextStep,
    setDataPresentialSchedulingInstitution,
    dataPresentialSchedulingInstitution,
  } = useStepsContext();

  const handleSelectIntitution = (id: number) => {
    setDataPresentialSchedulingInstitution &&
      setDataPresentialSchedulingInstitution((prevState) => ({
        sector_id: id,
        cpf: prevState?.cpf || "",
        nome: prevState?.nome || "",
        telefone: prevState?.telefone || "",
        email: prevState?.email || "",
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-5">
      {institution?.setores.map((sector) => {
        const qtdVacancies = totalVacancies(sector);

        const isActive = qtdVacancies > 0;
        const isSelected = sector.id === dataPresentialSchedulingInstitution?.sector_id;

        return (
          <CardRoot
            key={sector.id}
            data-active={isActive}
            data-selected={isSelected}
            variant={isActive ? "outlined" : "filled"}
            className="data-[active=false]:cursor-not-allowed data-[selected=true]:border-primary-500"
            onClick={() => {
              if (isActive) {
                handleSelectIntitution(sector.id);
              }
            }}
          >
            <CardContent>
              <CardTitle>{sector.nome}</CardTitle>

              <CardDescription>{sector.endereco}</CardDescription>

              <CardInfo color={qtdVacancies > 0 ? "green-500" : "red-500"} weight="semibold">
                {qtdVacancies} {t("booking.vagas")}
              </CardInfo>
            </CardContent>

            {isActive ? <CardIndicator /> : null}
          </CardRoot>
        );
      })}
    </div>
  );
};
