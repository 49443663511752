import { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { IonSkeletonText } from "@ionic/react";
import { Heading, Text } from "@astrolabe-ui/react";
import { useTranslation } from "react-i18next";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton, ShareButton } from "@/components";
import { useNewsById } from "../hooks/useNewsById";
import { useAppStore } from "@/store/useAppStore";
import { ImageGallery } from "../components/ImageGallery";

export function News() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const newsId = Number(params.id);

  const contentRef = useRef<HTMLDivElement>(null);

  const app = useAppStore((state) => state.app);

  if (!app) {
    throw new Error("Not loaded app");
  }

  const appId = app.id;
  const slug = app.slug;

  const { data: news, isLoading } = useNewsById({
    appId,
    newsId,
  });

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.querySelectorAll("a").forEach((anchor) => {
          anchor.onclick = (ev) => {
            ev.preventDefault();
            window.open(anchor.href, "_self");
          };
        });
      }
    }, 1000);
  }, []);

  function handleGoBack() {
    history.replace(`/${slug}/news`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("news.Notícias")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto min-h-full w-full max-w-3xl px-4 py-6 ios:pb-2">
          {!news && isLoading ? (
            <div className="flex h-full w-full flex-col gap-4">
              <NewsPostSkeleton />
            </div>
          ) : null}

          {news ? (
            <div className="flex h-full w-full flex-col gap-4">
              <header className="flex flex-col gap-4">
                <div className="h-full w-full overflow-hidden rounded-md">
                  <img src={news.cover} alt="" className="h-full w-full object-cover" />
                </div>
                <Heading size="lg" weight="medium" color="slate-700">
                  {news.title}
                </Heading>

                <div className="flex items-center justify-between">
                  <div className="flex flex-wrap items-center gap-2">
                    {news.categories?.length
                      ? news.categories.map((category) => (
                          <span
                            key={category.categoryId}
                            className="rounded-xs border-thin border-slate-200 px-2 py-px font-sans text-xs/normal text-slate-700"
                          >
                            {category.title}
                          </span>
                        ))
                      : news.category && (
                          <span
                            key={news.category.categoryId}
                            className="rounded-xs border-thin border-slate-200 px-2 py-px font-sans text-xs/normal text-slate-700"
                          >
                            {news.category.title}
                          </span>
                        )}
                  </div>

                  <div className="flex flex-shrink-0 items-center gap-1">
                    <ShareButton
                      title={news.title}
                      url={`https://web.mobby.app.br/${slug}/news/${news.id}`}
                    />
                  </div>
                </div>
              </header>

              <div className="h-px w-full bg-slate-200" />

              <div
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: news.content }}
                className="prose prose-slate text-left font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden"
              />

              {news.images.length ? (
                <>
                  <div className="h-px w-full bg-slate-200" />
                  <ImageGallery images={news.images} />
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}

function NewsPostSkeleton() {
  return (
    <div className="flex h-full w-full flex-col gap-4">
      <header className="flex flex-col gap-4">
        <IonSkeletonText animated className="h-40 w-full rounded-md" />

        <div className="flex flex-col gap-1">
          <IonSkeletonText animated className="w-full leading-relaxed" />
          <IonSkeletonText animated className="w-4/5 leading-relaxed" />
          <IonSkeletonText animated className="w-2/3 leading-relaxed" />
        </div>

        <div className="flex items-center justify-between">
          <IonSkeletonText animated className="h-[22px] w-16" />

          <div className="flex items-center gap-1">
            <IonSkeletonText animated className="h-8 w-8" />{" "}
            <IonSkeletonText animated className="h-8 w-8" />
          </div>
        </div>
      </header>

      <div className="h-px w-full bg-slate-200" />

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <IonSkeletonText animated className="w-full" />
          <IonSkeletonText animated className="w-4/5" />
          <IonSkeletonText animated className="w-3/4" />
          <IonSkeletonText animated className="w-5/6" />
          <IonSkeletonText animated className="w-11/12" />
          <IonSkeletonText animated className="w-1/3" />
        </div>

        <div className="flex flex-col gap-2">
          <IonSkeletonText animated className="w-full" />
          <IonSkeletonText animated className="w-11/12" />
          <IonSkeletonText animated className="w-4/5" />
          <IonSkeletonText animated className="w-full" />
          <IonSkeletonText animated className="w-2/3" />
          <IonSkeletonText animated className="w-1/3" />
        </div>

        <div className="flex flex-col gap-2">
          <IonSkeletonText animated className="w-full" />
          <IonSkeletonText animated className="w-11/12" />
          <IonSkeletonText animated className="w-4/5" />
          <IonSkeletonText animated className="w-full" />
        </div>
      </div>
    </div>
  );
}
