import dayjs from "dayjs";
import { http } from "@/lib/axios";

type GetCommentsParams = {
  appId: number;
  id: number;
};

type GetCommentsResponse = Array<{
  id: number;
  comentario: string;
  created_at: string;
  user: {
    id: number;
    name: string;
  };
}>;

export async function getComments({ appId, id }: GetCommentsParams) {
  try {
    const response = await http.get<GetCommentsResponse>(
      `/v2/${appId}/modulos/fotos/${id}/comentarios`,
    );

    return response.data.map((item) => ({
      id: item.id,
      comment: item.comentario,
      time: dayjs(item.created_at).fromNow(true),
      user: {
        id: item.user.id,
        name: item.user.name,
      },
    }));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
