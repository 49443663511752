import { useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, TextInput } from "@astrolabe-ui/react";
import { CaretLeft, MagnifyingGlass } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton } from "@/components";
import { LoadingFaqs } from "./components/LoadingFaqs";
import { EmptyFaqs } from "./components/EmptyFaqs";
import { CardFaq } from "./components/CardFaq";
import { FilteredQuestions } from "./components/FilteredQuestions";
import { useAppId } from "@/store/useAppStore";
import { useFaq } from "./hooks/useFaq";

export function FAQ() {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const [query, setQuery] = useState("");

  const { data, isLoading } = useFaq({ appId, id });

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              icon={<CaretLeft weight="bold" />}
              onClick={handleGoBack}
              variant="secondary"
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {data?.title}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="flex min-h-full w-full flex-col gap-4 px-4 pb-6 pt-4 ios:pb-2">
          <header className="flex items-center gap-1.5">
            <TextInput
              icon={<MagnifyingGlass className="text-slate-700" />}
              placeholder={t("general.Buscar")}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </header>

          {query.length ? (
            <FilteredQuestions query={query} />
          ) : (
            <>
              {isLoading ? <LoadingFaqs /> : null}

              {!data?.categories.length && !isLoading ? <EmptyFaqs /> : null}

              {data?.categories.length && !isLoading ? (
                <>
                  <Text size="md" color="slate-700" weight="medium">
                    {t("faq.Navegue por área")}
                  </Text>

                  {data.categories.map((category) => (
                    <CardFaq
                      key={category.id}
                      faqId={data.id}
                      id={category.id}
                      title={category.title}
                      icon={category.icon}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}
