import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { IonAlert, IonButton, IonLoading, IonTextarea } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { TransparentTemplate } from "@/templates/old/TransparentTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { CameraObj } from "./components/Camera";
import { getGalleryOverlayImage, sendSelfie } from "@/services/old/feed";
import { getContent } from "@/content/index";

const Image = styled.img`
  width: 100%;
  border-radius: 5px;
`;

const ContentDiv = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid gray;
`;

const AnswerArea = styled.div`
  width: 100%;
`;

interface PostPageParams {
  id: string;
  SLUG: string;
}

export function ModuleFeedCamera() {
  const params: PostPageParams = useParams();
  const history = useHistory();

  const CONTENT = getContent();

  const { isLoading, data: overlayData } = useQuery(["getModuleOverlay", params.id], () =>
    getGalleryOverlayImage(+params.id, params.SLUG),
  );

  const [selectedImage, setSelectedImage] = useState("");
  const [comment, setComment] = useState("");
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  function sendImage() {
    setShowLoadingDialog(true);

    const formData = new FormData();
    const blob = b64toBlob(selectedImage);

    formData.append("imagem", blob);
    formData.append("descricao", comment);
    formData.append("modulo_foto", params.id);

    sendSelfie(formData, params.SLUG)
      .then(() => {
        setShowLoadingDialog(false);
        setIsOpenModal(true);
      })
      .catch((err) => {
        console.log(err);
        setShowLoadingDialog(false);
      });
  }

  function b64toBlob(data: string) {
    const block = data.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];

    const sliceSize = 512;

    const byteCharacters = atob(realData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.NEWS.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!selectedImage) {
    // const ol = ['https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/PNG_transparency_demonstration_1.png/200px-PNG_transparency_demonstration_1.png',
    // overlayData?.data.molduras[0].imagem.replace('https','http'),
    // 'https://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/1615b3c402a1fa.png',
    // 'http://prefeituradofuturo-prefeitura-4.0.s3-us-east-2.amazonaws.com/1615b3c402a1fa.png']

    return (
      <TransparentTemplate name={CONTENT.CAMERA.TITLE}>
        <CameraObj
          OverlayImage={overlayData?.data.molduras[0].imagem}
          ListOverlayImages={overlayData?.data.molduras}
          onPictureSelected={(image) => setSelectedImage(image)}
          imageSelected={false}
        />
      </TransparentTemplate>
    );
  } else {
    return (
      <PageTemplate name={CONTENT.CAMERA.TITLE}>
        <Image src={selectedImage} alt="" />

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <IonButton onClick={() => setSelectedImage("")}>{CONTENT.CAMERA.TRY_AGAIN}</IonButton>

          <h6>{CONTENT.CAMERA.ADD_DESCRIPTION}</h6>

          <ContentDiv>
            <AnswerArea>
              <IonTextarea
                autoGrow={true}
                value={comment}
                placeholder={CONTENT.CAMERA.DESCRIPTION + "..."}
                onIonInput={(e) => {
                  setComment(e.detail.value ? e.detail.value : "");
                }}
              />
            </AnswerArea>
          </ContentDiv>
          <IonButton onClick={sendImage} disabled={!comment}>
            {CONTENT.CAMERA.SEND}
          </IonButton>
        </div>
        <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
        <IonAlert
          isOpen={isOpenModal}
          backdropDismiss={false}
          cssClass="my-custom-class"
          header={CONTENT.CAMERA.WARNING_TITTLE}
          message={CONTENT.CAMERA.WARNING_TEXT}
          buttons={[
            {
              text: CONTENT.CAMERA.WARNING_CLOSE,
              handler: () => history.goBack(),
            },
          ]}
        />
      </PageTemplate>
    );
  }
}
