import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Text } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { CardItem, CardItemSkeleton } from "@/pages/schedulings/components/card-item";
import { useAppId } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useLocals } from "@/pages/schedulings/hooks/use-locals";
import { queryClient } from "@/lib/react-query";
import { scheduleCompleteAtom } from "../atoms/schedule-completion-atom";

const selectLocalFormSchema = z.object({
  local: z.number(),
});

type selectLocalFormData = z.infer<typeof selectLocalFormSchema>;

export function SelectLocal() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const [{ entityId, resource, local }, updateSchedulingCompletionData] =
    useAtom(scheduleCompleteAtom);

  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useLocals({
    appId,
    entityId: Number(entityId),
    resourceId: Number(resource?.id),
    schedulingId: Number(id),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm<selectLocalFormData>({
    resolver: zodResolver(selectLocalFormSchema),
    defaultValues: {
      local: local?.id,
    },
  });

  async function onSubmit({ local: localId }: selectLocalFormData) {
    const local = data?.find((item) => item.id === localId);

    if (local) {
      updateSchedulingCompletionData((current) => ({
        ...current,
        schedule: null,
        hour: null,
        local,
      }));

      handleChangeStep("days");
    } else {
      await queryClient.invalidateQueries({
        queryKey: ["locals", Number(entityId), Number(resource?.id), Number(resource?.id), appId],
      });

      setValue("local", 0);
    }
  }

  function handleChangeStep(newStep: string) {
    searchParams.delete("step");

    searchParams.append("step", newStep);

    navigation.push({ pathname: "", search: searchParams.toString() });
  }

  return (
    <div className="flex flex-1 flex-col gap-5 px-4 py-6 ios:pb-2">
      <div className="space-y-1">
        <Text
          asChild
          size="lg"
          weight="medium"
          color="primary-500"
          className="text-[24px] leading-[28px]"
        >
          <h1>Selecione o local para atendimento</h1>
        </Text>
        <Text size="xs">Selecione o local onde deseja ser atendido em seu agendamento.</Text>
      </div>

      <form
        id="select-resource"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-4"
      >
        {isLoading ? <ContentLoading /> : null}

        {!isLoading && !data?.length ? <ContentEmpty /> : null}

        {!isLoading && data?.length
          ? data.map((item) => (
              <Controller
                key={item.id}
                control={control}
                name="local"
                render={({ field: { value, onChange } }) => (
                  <CardItem
                    type="button"
                    title={item.name}
                    data-selected={value === item.id}
                    onClick={() => onChange(item.id)}
                  />
                )}
              />
            ))
          : null}
      </form>

      <Button
        type="submit"
        form="select-resource"
        disabled={!isValid}
        full
        size="lg"
        className="mt-auto"
      >
        {t("general.Continuar")}
      </Button>
    </div>
  );
}

function ContentLoading() {
  return Array.from(Array(5).keys()).map((index) => <CardItemSkeleton key={index} />);
}

function ContentEmpty() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
      <Text weight="medium" color="slate-700">
        Não há locais disponíveis!
      </Text>
      <Text>No momento não existe nenhum local disponível para ser realizado um agendamento.</Text>
    </div>
  );
}
