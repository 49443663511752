import { useState, useRef } from "react";
import styled from "styled-components";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonMenuToggle,
} from "@ionic/react";
import { useLocation, useHistory } from "react-router-dom";
import { arrowBack, search, ellipsisHorizontalOutline, reorderThreeOutline } from "ionicons/icons";

import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { useConfig } from "@/hooks/useConfig";
import { cn } from "@/lib/utils";

type HeaderPops = {
  name: string;
  icon?: string;
  backPress?: any;
  hideUserButton?: boolean;
  profile?: boolean;
  setShowActionSheet?: (value: boolean) => void;
  onSearchTextChange?: (search: string) => void;
  openMenu?: () => void;
};

const Tittle = styled(IonTitle)`
  width: calc(100% - 5px);
`;

const ContrastedIcon = styled(IonIcon)`
  color: var(--ion-color-textheader, black) !important;
`;

export function Header({ hideUserButton = true, ...props }: HeaderPops) {
  const location = useLocation();
  const noHomePage = !(
    location.pathname === "/" + getAppSlug() + "/home" ||
    location.pathname === "/" + getAppSlug() ||
    location.pathname === `/${getAppSlug()}/`
  );
  const history = useHistory();

  const [showSearch, setShowSearch] = useState(false);
  const [currentSearchText, setCurrentSearchText] = useState("");

  // eslint-disable-next-line no-undef
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);

  const CONTENT = getContent();

  const enableCenterLogo = useConfig({ label: "header.logo.center" });

  function onSearch() {
    if (props.onSearchTextChange) {
      props.onSearchTextChange(currentSearchText);
    }
    setShowSearch(false);
    setCurrentSearchText("");
  }

  if (showSearch) {
    return (
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonSearchbar
            ref={searchBarRef}
            slot="start"
            mode="md"
            placeholder={CONTENT.GLOBAL.SEARCH}
            value={currentSearchText}
            showCancelButton={"always"}
            onIonCancel={() => {
              setShowSearch(false);
            }}
            onIonInput={(e) => setCurrentSearchText(e.detail.value + "")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />

          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onSearch();
              }}
            >
              <IonIcon icon={search} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    );
  }

  return (
    <IonHeader>
      <IonToolbar color="tertiary">
        <IonButtons slot="start">
          {noHomePage && (
            <IonButton
              onClick={() => {
                if (props.backPress) {
                  props.backPress();
                } else {
                  if (history.length <= 2) {
                    history.replace("/" + getAppSlug() + "/home");
                  } else {
                    history.goBack();
                  }
                }
              }}
            >
              <ContrastedIcon icon={arrowBack} />
            </IonButton>
          )}
        </IonButtons>

        <IonButtons slot="end">
          {props.onSearchTextChange && (
            <IonButton
              onClick={() => {
                setShowSearch(true);
                if (searchBarRef.current) {
                  searchBarRef.current.setFocus();
                }
              }}
            >
              <ContrastedIcon icon={search} />
            </IonButton>
          )}

          {!hideUserButton && (
            <IonMenuToggle>
              <IonButton>
                <ContrastedIcon size="large" icon={reorderThreeOutline} />
              </IonButton>
            </IonMenuToggle>
          )}

          {props.profile && (
            <IonButton
              onClick={() =>
                props.setShowActionSheet ? props.setShowActionSheet(true) : alert("aldfhkasd")
              }
            >
              <ContrastedIcon icon={ellipsisHorizontalOutline} />
            </IonButton>
          )}
        </IonButtons>

        <div style={{ display: "flex" }}>
          {!noHomePage && props.icon && (
            <div className={cn("flex w-full", { "justify-center": !!enableCenterLogo })}>
              <img
                src={props.icon}
                alt=""
                className={cn("h-[50px]", { "ml-[52px]": !!enableCenterLogo })}
              />
            </div>
          )}
          {(noHomePage || !props.icon) && (
            <Tittle
              style={{
                color: "var(--ion-color-textheader, blue)",
              }}
            >
              {props.name}
            </Tittle>
          )}
        </div>
      </IonToolbar>
    </IonHeader>
  );
}
