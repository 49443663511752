import { http } from "@/lib/axios";

export type SendMessageMarketParams = {
  appId: number;
  id: number;
  message: string;
  userId?: number;
};

export async function sendMessageMarket({ appId, id, userId, message }: SendMessageMarketParams) {
  try {
    const response = await http.post(`/v3/${appId}/comerciante/${id}/mensagens`, {
      mensagem: message,
      ...(userId ? { user_id: userId } : {}),
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
