import { Fragment, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { IonButton, IonToast } from "@ionic/react";
import { cameraOutline } from "ionicons/icons";

import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { getGalleryModulesById, getGalleryModulosPhotos } from "@/services/old/feed";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { BlockPostItem } from "./components/BlockPostItem";

interface paramsPage {
  SLUG: string;
  id: string;
}

export function ModuleFeedOpen() {
  const history = useHistory();
  const params: paramsPage = useParams();

  const CONTENT = getContent();

  const [toast, setToast] = useState(false);

  const { isLoading: loadingModule, data: module } = useQuery(
    ["module", params.id],
    () => getGalleryModulesById(params.id, params.SLUG),
    { enabled: true },
  );

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getGalleryModulosPhotosModulo", params.id],
    ({ pageParam = 1 }) => getGalleryModulosPhotos(params.id, pageParam, params.SLUG),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => {
        return (res.data.data.next_page_url + "").split("/fotos?paginate=1&page=")[1];
      },
    },
  );

  function loadMore() {
    setToast(true);
    fetchNextPage();
  }

  if (isLoading || loadingModule) {
    return (
      <PageTemplate name={CONTENT.GLOBAL.LOADING}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data || data?.pages[0].data.data.length === 0) {
    return (
      <PageTemplate
        name={module?.data.titulo}
        showAddButton={true}
        fabIcon={cameraOutline}
        onAddButtonClick={() => history.push(`/${getAppSlug()}/modules-open/${params.id}/camera/`)}
      >
        <EmptyContentPageTemplate name={module?.data.titulo} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      name={module?.data.titulo}
      showAddButton={true}
      fabIcon={cameraOutline}
      onAddButtonClick={() => {
        history.push(`/${getAppSlug()}/modules-open/${params.id}/camera/`);
      }}
    >
      <IonToast
        isOpen={toast}
        position="middle"
        color="dark"
        onDidDismiss={() => setToast(false)}
        message={CONTENT.GLOBAL.DONE}
        duration={600}
      />

      {data?.pages?.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            {item?.data?.data?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <BlockPostItem
                    id={item.id}
                    title={item.autor ? item.autor.name : null}
                    resume={item.descricao}
                    image={item.imagem}
                    userImg={item.autor ? item.autor.imagem : null}
                    unlimitedHeigh={true}
                    comments={item.comentarios.length}
                    likes={item.curtidas}
                    userLike={!!item.curtida_user}
                  />
                </div>
              );
            })}
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
