import { http } from "@/lib/axios";

export type CancelSchedulingProps = {
  appId: number;
  id: number;
  justification: string;
  continuarNaFila: boolean;
};

export async function cancelScheduling({
  appId,
  id,
  justification,
  continuarNaFila,
}: CancelSchedulingProps) {
  try {
    await http.post(`v3/${appId}/mobile/cancelar_agendamento/${id}`, {
      ...(justification ? { justificativa: justification } : {}),
      continuarNaFila,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
