import { useQuery } from "@tanstack/react-query";

import { getFaqSearch } from "@/services/faq";
import { useDebounce } from "@/hooks/useDebounce";

type useFaqsGeneralParams = {
  appId: number;
  id: string;
  query: string;
};

export function useFaqsGeneral({ appId, id, query }: useFaqsGeneralParams) {
  const queryDeffered = useDebounce(query, 700);

  return useQuery({
    queryKey: ["faq", "filters", appId, id, queryDeffered],
    queryFn: () => getFaqSearch({ appId, id, query: queryDeffered }),
    enabled: !!appId && !!query,
  });
}
