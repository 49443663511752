import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { IDataAssetsScheduling } from "../steps";

export interface IStepContext {
  currentStep?: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  total?: number;
  handleNextStep?: () => void;
  handlePrevStep?: () => void;
  handleGoTo?: (page: number) => false | void;
  setDataAssetsScheduling?: Dispatch<SetStateAction<IDataAssetsScheduling | undefined>>;
  dataAssetsScheduling?: IDataAssetsScheduling | undefined;
}

export const StepsContext = createContext<IStepContext>({});

export const useStepsContext = () => useContext(StepsContext);
