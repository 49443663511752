import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Basket, UserPlus } from "@phosphor-icons/react";

import { getAuthSeller } from "@/services/old/feirinha";
import { useUserToken } from "@/store/useAuthStore";

interface paramsPage {
  SLUG: string;
}

interface paramsMySeller {
  module_id?: number;
}

export function BlockMySeller(props: paramsMySeller) {
  const token = useUserToken();
  const params: paramsPage = useParams();
  const [productCount, setProductCount] = useState<Array<number>>();
  const history = useHistory();

  const { data } = useQuery({
    queryKey: ["mySeller"],
    queryFn: () => getAuthSeller(params.SLUG),
  });

  function handleCreateProfileClick() {
    if (!token) {
      history.replace("/" + params.SLUG + "/auth");
      return;
    }

    history.push("/" + params.SLUG + "/seller-register");
  }

  function handleSellerClick() {
    history.push(`/${params.SLUG}/abrir-vendedor/${data?.data.id}`);
  }

  function handleCreateProduct() {
    history.push(`/${params.SLUG}/novo-produto?modulo_id=${props.module_id ?? "null"}`);
  }

  useEffect(() => {
    if (data) {
      const countStates: Array<number> = [0, 0, 0, 0];

      data.data.produtos.forEach((product: any) => {
        countStates[product.situacao] += 1;
      });

      setProductCount(countStates);
    }
  }, [data]);

  return token && data ? (
    <div className="m-1 rounded-lg bg-white">
      <div className="rounded-tl-lg rounded-tr-lg border-medium border-b-default border-slate-200 p-3">
        <div className="leading-6 flex text-slate-700">
          <Basket className="mb-2 mr-2 h-6 w-6"></Basket>
          <p className="text-base font-medium">{data?.data.nome ?? "Carregando"}</p>
        </div>
        <div className="mb-0 text-sm font-semibold text-slate-700">Produtos</div>
        <div className="flex px-2">
          <div className="flex-1 text-slate-700">
            <p className="text-sm font-medium">Aprovados</p>
            <p className="font-semibold">
              {productCount?.length ? productCount[1] + productCount[2] : 0}
            </p>
          </div>
          <div className="m-[auto] mr-4 h-8 w-[1px] bg-slate-300"></div>
          <div className="flex-1 text-slate-700">
            <p className="text-sm font-medium">Em análise</p>
            <p className="font-semibold">{productCount?.length ? productCount[0] : 0}</p>
          </div>
          <div className="m-[auto] mr-4 h-8 w-[1px] bg-slate-300"></div>
          <div className="flex-1 text-slate-700">
            <p className="text-sm font-medium">Reprovados</p>
            <p className="font-semibold">{productCount?.length ? productCount[3] : 0}</p>
          </div>
        </div>
      </div>
      <div className="flex gap-[1px]">
        <div
          className="flex-1 cursor-pointer rounded-bl-lg bg-primary-700 py-2 text-center text-sm font-medium text-white"
          onClick={() => handleSellerClick()}
        >
          Ver Perfil
        </div>
        <div
          className="flex-1 cursor-pointer rounded-br-lg bg-primary-700 py-2 text-center text-sm font-medium text-white"
          onClick={() => handleCreateProduct()}
        >
          Criar Produto
        </div>
      </div>
    </div>
  ) : (
    <div className="m-1 rounded-lg bg-white">
      <div className="rounded-tl-lg rounded-tr-lg border-medium border-b-default border-slate-200 p-3">
        <div className="leading-6 flex text-slate-700">
          <UserPlus className="mb-2 mr-2 h-6 w-6"></UserPlus>
          <p className="text-base font-medium">Crie seu perfil de vendedor</p>
        </div>
        <div className="font-thin mb-0 text-sm text-slate-700">
          Siga o passo a passo e configure seu perfil para poder cadastrar seus serviços ou produtos
        </div>
      </div>
      <div className="flex gap-[1px]">
        <div
          className="flex-1 cursor-pointer rounded-bl-lg rounded-br-lg bg-primary-700 py-2 text-center text-sm font-medium text-white"
          onClick={() => handleCreateProfileClick()}
        >
          Criar meu perfil
        </div>
      </div>
    </div>
  );
}
