import { isLogged, getAccessToken } from "./auth";
import { http } from "@/lib/axios";
import { removeCaracteresNaoNumericos } from "@/utils/removeCaracteresNaoNumericos";

interface BindHolderProps {
  cpf: string;
  montherName: string;
  birthDate: string;
}

export async function validateCPF(cpf: string) {
  if (isLogged()) {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };

    const SLUG = localStorage.getItem("APP_SLUG");
    const APP_ID = localStorage.getItem(`${SLUG}_ID_APP`);

    return http.get(`v2/${APP_ID}/titular/check_cpf?cpf=${removeCaracteresNaoNumericos(cpf)}`, {
      headers,
    });
  }
}

export async function bindHolder({ cpf, montherName, birthDate }: BindHolderProps) {
  if (isLogged()) {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };

    const SLUG = localStorage.getItem("APP_SLUG");
    const APP_ID = localStorage.getItem(`${SLUG}_ID_APP`);

    return http.post(
      `v2/${APP_ID}/titular/associar_user_titular`,
      {
        cpf,
        nome_mae: montherName,
        data_nascimento: birthDate,
      },
      { headers },
    );
  }
}
