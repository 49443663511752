import { Text } from "@astrolabe-ui/react";
import { cn } from "@/lib/utils";

export const HourButton = ({
  time,
  bloqued,
  interval,
  selected,
  next,
  className,
  textClassName,
  onClick,
}: {
  time: string;
  className?: string;
  textClassName?: string;
  selected?: boolean;
  next?: boolean;
  bloqued?: boolean;
  interval?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        `cursor-pointer rounded-md border-thin border-slate-200 py-4 text-slate-400 shadow-sm ${className}`,
        {
          "bg-primary-500": selected,
          "animate-pulse bg-primary-100": next && !selected && !interval && !bloqued,
          "bg-primary-100": interval,
          "bg-slate-300 shadow-none": bloqued,
        },
      )}
      {...(!bloqued && { onClick })}
    >
      <Text
        color="slate-700"
        className={cn(textClassName, {
          "font-semibold text-white": selected,
          "text-white": bloqued,
          "font-semibold text-primary-500": interval,
        })}
      >
        {time}
      </Text>
    </div>
  );
};
