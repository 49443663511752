import styled from "styled-components";
import { COLORS } from "@/data/colors";

type StepProps = {
  status: string;
};

const colorsByStatus: Record<string, string> = {
  current: COLORS.PRIMARY,
  complete: COLORS.SECONDARY,
  upcoming: "#cbd5e1",
};

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface ListlProps {
  size?: string;
}

export const List = styled.ol<ListlProps>`
  list-style: none;
  display: flex;
  gap: 16px;
  margin: 0;
  padding: 0;

  li {
    width: ${({ size }) => size};
  }
`;

export const StepElement = styled.div<StepProps>`
  height: 4px;
  width: 100%;
  border-radius: 4px;
  background-color: ${(props) => colorsByStatus[props.status]};
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #94a3b8;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${COLORS.PRIMARY};
  }
`;
