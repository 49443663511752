import styled from "styled-components";
import { Block } from "@/components/old/Block";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
`;

interface BlockFormProps {
  title?: string;
  children: any;
}

export function BlockForm(props: BlockFormProps) {
  return (
    <Block noPadding title={props.title}>
      <Content>{props.children}</Content>
    </Block>
  );
}
