import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { toast } from "react-hot-toast";
import { ShareNetwork } from "@phosphor-icons/react";

import { IconButton } from "@/components/IconButton";

interface ShareButtonProps {
  title?: string;
  url: string;
}

export function ShareButton({ title, url }: ShareButtonProps) {
  async function share() {
    try {
      if (!Capacitor.isNativePlatform()) {
        if (window.navigator.share) {
          window.navigator.share({
            text: title,
            title,
            url,
          });
          return;
        }

        await copyToClipboard(url);

        toast.success("Copiado para área de transferência");
      } else {
        Share.share({
          dialogTitle: "Compartilhar",
          text: title,
          title,
          url,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
  }

  return (
    <IconButton
      icon={<ShareNetwork className="text-primary-500" />}
      rounded="full"
      onClick={share}
    />
  );
}
