import { http } from "@/lib/axios";

interface regiserUserParams {
  appId: number;
  name: string;
  email: string;
  cpf?: string;
  fone?: string;
  bairro?: number;
  password: string;
}

export function regiserUser({
  appId,
  name,
  email,
  cpf,
  fone,
  bairro,
  password,
}: regiserUserParams) {
  return http.post(`v3/${appId}/usuarios`, {
    name,
    email,
    cpf,
    fone,
    bairro,
    password,
  });
}
