import OneSignal from "onesignal-cordova-plugin";

import { STORAGE } from "@/data/storage";
import { getAppSlug } from "./starter";

const ONESIGNAL_APP = localStorage.getItem(STORAGE.ONESIGNAL_APP.KEY);
const ONESIGNAL_REST = localStorage.getItem(STORAGE.ONESIGNAL_REST.KEY);

type NotificationResponse = {
  id: number;
  tipo: string;
};

export function OneSignalLogin(userId: string) {
  if (ONESIGNAL_APP && ONESIGNAL_REST) {
    OneSignal.sendTag("user_id", userId);
  }
}

interface OneSignalInitProps {
  onesignalAppId: string;
}

export function OneSignalInit({ onesignalAppId }: OneSignalInitProps) {
  OneSignal.setAppId(onesignalAppId);

  OneSignal.setNotificationOpenedHandler((data) => {
    if (data.notification.additionalData) {
      notificationOpenedCallback(data.notification.additionalData as NotificationResponse);
    }
  });

  OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
    console.log(`User accepted notifications: ${accepted}`);
  });
}

function notificationOpenedCallback(notification: NotificationResponse) {
  try {
    const slug = getAppSlug();

    const pagesMap: Record<string, string> = {
      noticia: "news-open",
      mensagemProtocolo: "protocol",
    };

    const module = pagesMap[notification.tipo] ?? "home";

    const url = `/${slug}/${module === "home" ? "" : `${module}/${notification.id}`}`;

    window.history.replaceState(null, "", url);
  } catch (err) {
    console.error(err);
  }
}
