import { COLORS } from "@/data/colors";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  img {
    height: 7.5rem;
    object-fit: contain;

    @media (min-width: 1024px) {
      height: 20vh;
    }
  }
`;

export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 1024px) {
    gap: 0.875rem;
  }

  strong {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;
    color: #334155;

    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 160%;
    color: #94a3b8;

    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }
`;

export const Faq = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & > strong {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 150%;
      color: #334155;

      @media (min-width: 1024px) {
        font-size: 1rem;
      }
    }

    & > span {
      margin: 0;
      font-size: 0.875rem;
      line-height: 150%;
      color: #94a3b8;

      @media (min-width: 1024px) {
        font-size: 1rem;
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ActionButton = styled.button`
  all: unset;
  box-sizing: border-box;
  position: fixed;
  bottom: max(env(safe-area-inset-bottom), 1.25rem);
  left: 1rem;
  right: 1rem;
  width: calc(100% - 2rem);

  padding: 0.875rem 1rem;
  background-color: ${COLORS.PRIMARY};
  border-radius: 0.5rem;

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
