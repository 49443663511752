import styled from "styled-components";
import { COLORS } from "@/data/colors";

interface ElementProps {
  hasData: boolean;
}

export const Container = styled.div<ElementProps>`
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  cursor: pointer;
  position: relative;
  overflow: hidden;

  span {
    flex: 1;
    font-size: 0.875rem;
    font-weight: ${(props) => (props.hasData ? 500 : 400)};
    line-height: 150%;
    color: ${(props) => (props.hasData ? "#334155" : "#94A3B8")};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  overflow: auto;
  background-color: #ffffff;

  footer {
    margin-top: auto;
    width: 100%;
    position: sticky;
    bottom: 1rem;

    background-color: #ffffff;
    border-top: 1px solid #e2e8f0;
    padding: 1rem;
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  padding: 2rem 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  strong {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #334155;
  }
`;

export const Themes = styled.div`
  width: 100%;
  overflow: auto;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.875rem;
`;

interface ThemeProps {
  selected?: boolean;
}

export const ThemeBadge = styled.span<ThemeProps>`
  padding: 0.375rem 0.75rem;
  background-color: ${(props) => (props.selected ? COLORS.PRIMARY : "transparent")};

  border: 1px solid ${(props) => (props.selected ? COLORS.PRIMARY : "#E2E8F0")};
  border-radius: 9999px;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => (props.selected ? "#ffffff" : "#94A3B8")};

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  cursor: pointer;
`;
