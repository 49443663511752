import { ResponseInstitution } from "@/services/assetsScheduling";
import { IDataAssetsScheduling } from "../..";

export const getPrecedence = (
  institution?: ResponseInstitution,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  institution?.unidades
    .find((unit) => unit.id === dataAssetsScheduling?.unidade_id)
    ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id)?.antecedencia;
