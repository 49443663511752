import { http } from "@/lib/axios";

export type CloseToQueueProps = {
  appId: number;
  id: number;
};

export async function closeToQueue({ appId, id }: CloseToQueueProps) {
  try {
    await http.post(`v3/${appId}/mobile/sair_fila/${id}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
