import { useFormContext } from "react-hook-form";

import { SelectItems } from "@/components/SelectItems";
import { FieldProps } from "../question";

export function SelectField({ value, onChange, field }: FieldProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message ? String(errors[field.id]?.message) : undefined;

  const options = field.options.map((opt) => ({
    value: opt.id.toString(),
    label: opt.label,
  }));

  return (
    <SelectItems
      hint={field.hint}
      options={options}
      selected={String(value)}
      title={field.name}
      error={error}
      onChange={onChange}
    />
  );
}
