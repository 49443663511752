import { http } from "@/lib/axios";

interface validateCodeParams {
  appId: number;
  code: string;
}

export function validateCode({ appId, code }: validateCodeParams) {
  return http.post(`v3/${appId}/recuperar_senha/validar_token`, {
    token: code,
  });
}
