import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IUserTokenStoreParams {
  token: string | null;
  updateToken: (token: string | null) => void;
}

export const useAuthStore = create<IUserTokenStoreParams>()(
  persist(
    (set, _) => {
      return {
        token: null,
        updateToken: (token: string | null) => {
          set({ token });
        },
      };
    },
    { name: "token" },
  ),
);

export const useUserToken = () => useAuthStore((state) => state.token);
