import { useForm } from "react-hook-form";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cpf } from "cpf-cnpj-validator";
import { TextInput } from "@astrolabe-ui/react";

import { Button } from "@/components/Button";
import { useUserStore } from "@/store/useUserStore";
import { formatCpf, formatPhone, removeCharacteres } from "@/utils/formats";
import { useStepsContext } from "../contexts/StepsContext";

interface IDataIdetification {
  cpf?: string;
  nome?: string;
  telefone?: string;
  email?: string;
}

const identificationFormSchema = z.object({
  nome: z.string().min(1, i18n.t("auth.Informe seu nome")),
  email: z
    .string()
    .min(1, i18n.t("auth.Informe seu e-mail"))
    .email(i18n.t("auth.Informe um e-mail válido")),
  telefone: z
    .string()
    .min(1, i18n.t("auth.Informe seu telefone"))
    .transform((value) => removeCharacteres(value)),
  cpf: z
    .string()
    .min(1, i18n.t("auth.Informe seu CPF"))
    .transform((value) => removeCharacteres(value))
    .refine((value) => !value || cpf.isValid(value), i18n.t("auth.Informe um CPF válido")),
});

export const Identification = () => {
  const { dataPresentialSchedulingInstitution, setDataPresentialSchedulingInstitution } =
    useStepsContext();

  const { handleNextStep } = useStepsContext();

  const user = useUserStore((state) => state.user);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDataIdetification>({
    resolver: zodResolver(identificationFormSchema),
    mode: "onChange",
    defaultValues: {
      nome:
        dataPresentialSchedulingInstitution?.nome ||
        user?.name ||
        (process.env.NODE_ENV === "development" ? "Teste no user" : undefined),
      email:
        dataPresentialSchedulingInstitution?.email ||
        user?.email ||
        (process.env.NODE_ENV === "development" ? "test@mail.com" : undefined),
      cpf: dataPresentialSchedulingInstitution?.cpf
        ? formatCpf(dataPresentialSchedulingInstitution.cpf)
        : user?.cpf
        ? formatCpf(user.cpf)
        : process.env.NODE_ENV === "development"
        ? formatCpf("76313051025")
        : undefined,
      telefone: dataPresentialSchedulingInstitution?.telefone
        ? formatPhone(dataPresentialSchedulingInstitution.telefone)
        : user?.phone
        ? formatPhone(user.phone)
        : process.env.NODE_ENV === "development"
        ? formatPhone("00000000000")
        : undefined,
    },
  });

  const onSubmit = (data: IDataIdetification) => {
    setDataPresentialSchedulingInstitution &&
      setDataPresentialSchedulingInstitution((prevState) => ({ ...prevState, ...data }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 grow flex-col gap-6">
      <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-wrap items-start gap-3">
        <div className="flex flex-wrap gap-3">
          <TextInput
            floating
            inputMode="numeric"
            placeholder={t("auth.CPF")}
            error={errors.cpf?.message}
            {...register("cpf", {
              onChange: (event) => setValue("cpf", formatCpf(event.target.value)),
            })}
          />

          <TextInput
            floating
            placeholder={t("auth.Nome completo")}
            error={errors.nome?.message}
            {...register("nome")}
          />

          <TextInput
            floating
            type="tel"
            inputMode="numeric"
            placeholder={t("auth.Telefone")}
            maxLength={17}
            error={errors.telefone?.message}
            {...register("telefone", {
              onChange: (event) => setValue("telefone", formatPhone(event.target.value)),
            })}
          />

          <TextInput
            floating
            placeholder={t("auth.E-mail")}
            error={errors.email?.message}
            {...register("email")}
          />
        </div>

        <Button size="lg" full className="self-end">
          {t("general.Continuar")}
        </Button>
      </form>
    </div>
  );
};
