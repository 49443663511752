import { useQuery } from "@tanstack/react-query";
import { getAllServices } from "@/services/categoryServices";

type useServiceParams = {
  appId: number;
  aggregatorOnly?: string | null;
  aggregatorExcept?: string | null;
};

export function useService({ appId, aggregatorExcept, aggregatorOnly }: useServiceParams) {
  return useQuery({
    queryKey: ["all-services", appId, aggregatorOnly, aggregatorExcept],
    queryFn: () => getAllServices({ appId, aggregatorOnly, aggregatorExcept }),
    enabled: !!appId,
  });
}
