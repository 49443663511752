import { useAuthStore } from "@/store/useAuthStore";
import axios from "axios";

export const http = axios.create({
  baseURL: import.meta.env.VITE_APP_SERVER,
});

http.interceptors.request.use(
  async (config) => {
    const { token } = useAuthStore.getState();

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export const locationAPI = axios.create({
  baseURL: "https://nominatim.openstreetmap.org",
});
