export const STORAGE = {
  LANG: {
    KEY: "APP_LANG",
  },

  APP_APPLE_STORE_LINK: {
    KEY: "APP_APPLE_STORE_LINK",
  },

  APP_ANDROID_STORE_LINK: {
    KEY: "APP_ANDROID_STORE_LINK",
  },

  APP_NAME: {
    KEY: "APP_NAME",
  },

  APP_LOGO_IMG: {
    KEY: "APP_LOGO_IMG",
  },

  VERSION: {
    KEY: "APP_VERSION",
    VALUE: "0.0.1",
  },

  PRIMARY_COLOR: {
    KEY: "PRIMARY_COLOR",
  },
  SECONDARY_COLOR: {
    KEY: "SECONDARY_COLOR",
  },
  HEADER_COLOR: {
    KEY: "HEADER_COLOR",
  },
  BACKGROUND_COLOR: {
    KEY: "BACKGROUND_COLOR",
  },
  BUTTONS_COLOR: {
    KEY: "BUTTONS_COLOR",
  },

  LOCATION: {
    KEY: "APP_LOCATION",
  },
  AUTH_ACCESS_EXPIRE: {
    KEY: "AUTH_ACCESS_EXPIRE",
  },
  AUTH_REFRESH_TOKEN: {
    KEY: "AUTH_REFRESH_TOKEN",
  },
  AUTH_ACCESS_TOKEN: {
    KEY: "AUTH_ACCESS_TOKEN",
  },
  AUTH_USER_EMAIL: {
    KEY: "AUTH_USER_EMAIL",
  },
  AUTH_USER_ID: {
    KEY: "AUTH_USER_ID",
  },
  AUTH_USER_SELLER_ID: {
    KEY: "AUTH_USER_SELLER_ID",
  },
  AUTH_USER_NAME: {
    KEY: "AUTH_USER_NAME",
  },
  AUTH_USER_HOLDER: {
    KEY: "AUTH_USER_HOLDER",
  },
  AUTH_USER_IMAGE: {
    KEY: "AUTH_USER_IMAGE",
  },

  SOCKET_CHANNEL: {
    KEY: "SOCKET_CHANNEL",
  },

  TERM_USE_LINK: {
    KEY: "TERM_USE_LINK",
  },

  TERM_USE_VERSION: {
    KEY: "TERM_USE_VERSION",
  },

  ONESIGNAL_APP: {
    KEY: "ONESIGNAL_APP",
  },

  ONESIGNAL_REST: {
    KEY: "ONESIGNAL_REST",
  },

  APP_SLUG: {
    KEY: "APP_SLUG",
  },

  SPLASH: {
    KEY: "SPLASH",
  },

  STYLE_DATA: {
    KEY: "STYLE_DATA",
  },

  STORE_EVALUATION: {
    KEY: "STORE_EVALUATION",
  },

  MAINTENANCE: {
    KEY: "MAINTENANCE",
  },
};
