import { useHistory } from "react-router-dom";
import { atom, useAtom, useSetAtom } from "jotai";
import { Text } from "@astrolabe-ui/react";

import { Button, Modal, ReactPortal } from "@/components";
import { createSchedulingDataAtom } from "../pages/create-scheduling/atoms/create-scheduling-data-atom";
import { useUserToken } from "@/store/useAuthStore";
import { useAppSlug } from "@/store/useAppStore";

import illustrationModalError from "@/assets/illustrations/queue-important.svg";

export const modalErrorSchedulingAtom = atom({
  isOpen: false,
  enableGoSchedulings: false,
  title: "",
  message: "",
});

export function ModalError() {
  const navigation = useHistory();
  const slug = useAppSlug();

  const [modalErrorData, setModalErrorData] = useAtom(modalErrorSchedulingAtom);
  const setCreateSchedulingData = useSetAtom(createSchedulingDataAtom);

  const isAuthenticated = !!useUserToken();

  function handleCloseModal() {
    setModalErrorData((current) => ({
      ...current,
      isOpen: false,
    }));
  }

  function handleMySchedulings() {
    handleCloseModal();

    if (!isAuthenticated) {
      navigation.replace(`/${slug}/buscar-agendamentos?not-back=true`);
      return;
    }

    navigation.replace(`/${slug}/meus-agendamentos`);

    clearSchedulingData();
  }

  function clearSchedulingData() {
    setCreateSchedulingData({
      resource: null,
      personal: null,
      schedule: null,
      hour: null,
      local: null,
      questions: [],
      extraFields: [],
    });
  }

  return (
    <ReactPortal>
      <Modal showModal={modalErrorData.isOpen} onClose={handleCloseModal}>
        <div className="flex flex-col items-center gap-6">
          <img src={illustrationModalError} alt="" className="w-[200px]" />

          <div className="flex flex-col items-center text-center">
            <Text size="md" color="slate-700" weight="medium">
              {modalErrorData.title}
            </Text>
            <Text size="xs" color="slate-500">
              {modalErrorData.message}
            </Text>
          </div>

          <div className="flex w-full flex-col gap-2">
            {modalErrorData.enableGoSchedulings ? (
              <Button variant="secondary" size="lg" full onClick={handleMySchedulings}>
                Consultar meus agendamentos
              </Button>
            ) : null}

            <Button size="lg" full onClick={handleCloseModal}>
              Voltar
            </Button>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  );
}
