import { IonContent, IonPage, IonToast } from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { copyOutline } from "ionicons/icons";

import confirmationProtocolImg from "@/assets/images/confirmation_protocol.svg";

import {
  Container,
  Content,
  ContentImage,
  Image,
  TitleContent,
  CodeTitle,
  ContentCode,
  Code,
  IconCopy,
  Description,
  ContentDescriptionNumber,
  Button,
  Link,
} from "./styles";
import { getAppSlug } from "@/services/old/starter";

interface ServiceParams {
  id: string;
  SLUG: string;
}

export function ConfirmationProtocol() {
  const history = useHistory();
  const params: ServiceParams = useParams();
  const [toast, setToast] = useState(false);

  function copyId(e: any) {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(params.id.toString());
    setToast(true);
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <Container>
          <Content>
            <ContentImage>
              <Image src={confirmationProtocolImg} alt="" />
            </ContentImage>
            <TitleContent>Seu protocolo foi criado com sucesso!</TitleContent>
            <CodeTitle>CÓDIGO ID</CodeTitle>
            <ContentCode onClick={(e) => copyId(e)}>
              <Code>{params.id}</Code>
              <IconCopy icon={copyOutline} />
            </ContentCode>
            <ContentDescriptionNumber>
              <Description>Esse é o seu número de protocolo.</Description>
            </ContentDescriptionNumber>
          </Content>
          <footer>
            <Link
              onClick={() => {
                history.push(`/${getAppSlug()}`);
              }}
            >
              Retornar a página inicial
            </Link>
            <Button
              onClick={() => {
                history.push(`/${getAppSlug()}/protocol/${params.id}`);
              }}
            >
              Detalhes do protocolo
            </Button>
          </footer>
        </Container>
        <IonToast
          isOpen={toast}
          position="top"
          color="dark"
          onDidDismiss={() => setToast(false)}
          message="Copiado com sucesso."
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
}
