import { http } from "@/lib/axios";

interface triggerEmailParams {
  appId: number;
  username: string;
}

export function triggerEmail({ appId, username }: triggerEmailParams) {
  return http.post(`v3/${appId}/recuperar_senha/disparar_email`, {
    username,
  });
}
