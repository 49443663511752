import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Geolocation } from "@capacitor/geolocation";
import {
  IonLoading,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonToggle,
  IonInput,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import {
  removeOutline,
  caretForwardOutline,
  caretBackOutline,
  checkmarkOutline,
} from "ionicons/icons";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockForm } from "@/components/old/BlockForm";
import { ErrorMessage } from "@/components/old/Base";
import { UploadImage } from "@/components/old/UploadImage";
import { UploadFile } from "@/components/old/UploadFile";
import { GetLocation } from "@/pages/old/Services/components/GetLocation";
import { getServicoCategoryById, registProtocol } from "@/services/old/servicos";
import { getAppSlug } from "@/services/old/starter";
import { isLogged } from "@/services/old/auth";
import { STORAGE } from "@/data/storage";
import { COLORS } from "@/data/colors";
import { getContent } from "@/content/index";

interface ServiceParams {
  id: string;
  SLUG: string;
}

type ExtraFields = {
  fieldable_id: number;
  fieldable_type: string;
  id: number;
  manterDados: boolean;
  nome: string;
  required: boolean;
  tipo: string;
};

type ExtraFieldsResponses = {
  id: number;
  required: boolean;
  tipo: string;
  fieldable_type: string;
  value: string;
};

type fileSize = {
  id: number;
  size: number;
};

export function ServiceOpen() {
  const CONTENT = getContent();

  const params: ServiceParams = useParams();

  const history = useHistory();

  const { isLoading, data } = useQuery(["getServicoCategoryById", params.id], () =>
    getServicoCategoryById(params.id, params.SLUG),
  );

  const [protocolId, setProtocolId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [needsLogin, setNeedsLogin] = useState(false);
  const [showAlertTitle, setShowAlertTitle] = useState("");
  const [showAlertMsg, setShowAlertMsg] = useState("");
  const [showErroMsgs, setErroMsgs] = useState(false);
  const [defautSubCategory] = useState({
    id: -1,
    titulo: "",
    mensagem: "",
    link_externo: null,
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState(defautSubCategory);
  const [form, setForm] = useState(new FormData());
  const [image, setImage] = useState({
    base64: "",
    file: { name: "" },
  });
  const [message, setMessage] = useState("");
  const [plate, setPlate] = useState("");
  const [plate2, setPlate2] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [backgroundAddrLat, setBackgroundAddrLat] = useState("");
  const [backgroundAddrLng, setBackgroundAddrLng] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [address, setAdress] = useState("");

  const [subCategorias, setSubCategorias] = useState([]);
  const [loadingSubCat, setIsloadingSubCat] = useState(true);
  const [hideUser, setHideUser] = useState(false);
  const [token] = useState(!!localStorage.getItem(STORAGE.AUTH_ACCESS_TOKEN.KEY));
  const [userId, setUserId] = useState(localStorage.getItem(STORAGE.AUTH_USER_ID.KEY));
  const [name, setName] = useState("");
  const [step, setStep] = useState(0);
  const [extraFields, setExtraFields] = useState<ExtraFields[]>([]);
  const [extraFieldsResponses, setExtraFieldsResponses] = useState<ExtraFieldsResponses[]>([]);

  const [fileSizes, setFileSizes] = useState<fileSize[]>([]);

  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (!token) {
      setHideUser(true);
    }
  }, [subCategorias, setUserId, token]);

  useEffect(() => {
    getServicoCategoryById(params.id, params.SLUG)
      .then((res) => {
        setIsloadingSubCat(false);
        setSubCategorias(res.data.sub_categoria_servicos);

        const extraFieldsValues: any = [];
        const extraFieldsResponse: ExtraFieldsResponses[] = [];

        if (res.data.campo.length > 0) {
          res.data.campo.forEach(
            (element: { fieldable_type: string; id: number; required: boolean; tipo: string }) => {
              extraFieldsValues.push(element);

              extraFieldsResponse.push({
                id: element.id,
                required: element.required,
                tipo: element.tipo,
                fieldable_type: element.fieldable_type,
                value: "",
              });
            },
          );
        }

        setExtraFields(extraFieldsValues);
        setExtraFieldsResponses(extraFieldsResponse);

        if (!res.data.abrir_anonimo && !isLogged()) {
          presentAlert({
            message: CONTENT.GLOBAL.NEEDTOBELOGED,
            backdropDismiss: false,
            buttons: [
              {
                text: CONTENT.GLOBAL.CLOSE,
                handler: () => history.go(-1),
              },
              {
                text: CONTENT.USER.LOGIN,
                handler: () => history.replace("/" + getAppSlug() + "/auth"),
              },
            ],
          });
          return;
        }

        if (res.data.sub_categoria_servicos && res.data.sub_categoria_servicos.length === 1) {
          setSelectedSubCategory(res.data.sub_categoria_servicos[0]);
        }

        if (!isHorarioAtendimento(res.data.horaInicial, res.data.horaFinal)) {
          setShowAlertMsg(CONTENT.PROTOCOLS.HORARIOATENDIMENTO);
          setShowAlert(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    Geolocation.getCurrentPosition()
      .then((res) => {
        setBackgroundAddrLat(res.coords.latitude + "");
        setBackgroundAddrLng(res.coords.longitude + "");
      })
      .catch((err) => {
        console.error("Error getting location", err);
      });
  }, []);

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  function isHorarioAtendimento(horaInicial: string, horaFinal: string) {
    return isHoraAntesAgora(horaInicial) && isHoraDepoisAgora(horaFinal);
  }

  function isHoraAntesAgora(hora: string) {
    const hh = +hora.split(":")[0];
    const hm = +hora.split(":")[1];

    const d = new Date();
    const h = +d.getHours();
    const m = +d.getMinutes();

    return hh < h || (hh === h && hm < m);
  }

  function isHoraDepoisAgora(hora: string) {
    const hh = +hora.split(":")[0];
    const hm = +hora.split(":")[1];

    const d = new Date();
    const h = +d.getHours();
    const m = +d.getMinutes();

    return hh > h || (hh === h && hm > m);
  }

  function imageChangeHandle(data: any) {
    if (data.file) {
      setFileSizes([
        {
          id: 0,
          size: data.file.size,
        },
      ]);

      setImage(data);
    }
  }

  function locationChangeHandle(data: any) {
    setLat(data.lat + "");
    setLng(data.lng + "");
    setAdress(data.address + "");
  }

  function submit() {
    if (subCategorias.length === 1) {
      setSelectedSubCategory(subCategorias[0]);
    }

    if (
      (data?.data.usarEndereco && (!lat || !lng)) ||
      selectedSubCategory.id === -1 ||
      message.length === 0 ||
      (plate.length > 0 && plate.length !== 3) ||
      (plate2.length > 0 && plate2.length !== 4)
    ) {
      setErroMsgs(true);
      return;
    }

    if (extraFieldsResponses.some((item) => item.required && item.value === "")) {
      setErroMsgs(true);
      return;
    }

    setForm(new FormData());

    form.append("categoriaServico", params.id);

    if (data?.data.usarEndereco && lat.length > 0 && lng.length) {
      form.append("latitude", lat);
      form.append("longitude", lng);
    } else if (
      !data?.data.usarEndereco &&
      backgroundAddrLat.length > 0 &&
      backgroundAddrLng.length > 0
    ) {
      form.append("latitude", backgroundAddrLat);
      form.append("longitude", backgroundAddrLng);
    }
    form.append("subCategoriaServico", selectedSubCategory.id + "");
    form.append("mensagem", message);

    if (image && image.base64 && image.file && image.file.name) {
      form.append("imagem", b64toBlob(image.base64), image.file.name);
    }

    if (data?.data.usarIdentificador) {
      form.append("identificador", identifier);
    }

    if (data?.data.usarPlaca && plate.length > 0) {
      form.append("placaVeiculo", plate + "-" + plate2);
    }
    if (data?.data.usarEndereco && address.length > 0) {
      form.append("endereco", address);
    }

    if (hideUser && !token) {
      form.append("nome", name);
    } else if (hideUser && token && userId) {
      form.append("autor", userId);
    }

    extraFieldsResponses.forEach((element, index) => {
      form.append(`campos_extras[${index}]`, element.id.toString());
      form.append(`campo_value[${index}]`, element.value ? element.value : "");
    });

    setNeedsLogin(false);
    setShowAlertTitle("Solicitação cadastrada");
    setShowAlertMsg("");

    setShowLoadingDialog(true);
    registProtocol(form, params.SLUG, hideUser)
      .then((res) => {
        setShowLoadingDialog(false);
        if (!res) {
          setShowAlertTitle("Falha");
          setShowAlertMsg("Para cadastrar, é necessário realizar login.");
          setNeedsLogin(true);
          setShowAlert(true);
        } else {
          setProtocolId(res.data.id);
          if (
            data?.data.mensagemForaAtendimento &&
            data?.data.horaFinal &&
            data?.data.horaInicial
          ) {
            const d = new Date();
            const h = d.getHours();
            const m = d.getMinutes();
            const maxH = data?.data.horaFinal.split(":")[0];
            const maxM = data?.data.horaFinal.split(":")[1];
            const minH = data?.data.horaInicial.split(":")[0];
            const minM = data?.data.horaInicial.split(":")[1];

            const show = !(
              (minH < h && h < maxH) ||
              (h === minH && m >= minM) ||
              (h === maxH && m < maxM)
            );
            if (show) {
              setShowAlertMsg(data?.data.mensagemForaAtendimento);
            }
          }
          setShowAlert(true);
        }
      })
      .catch((res) => {
        setShowLoadingDialog(false);

        setShowAlertTitle(CONTENT.PROTOCOLS.ERROR_CREATE_TITLE);
        let msg = CONTENT.PROTOCOLS.ERROR_CREATE_NEED_WAIT;

        if (res.data.error.imagem) {
          msg = res.data.error.imagem;
        } else if (res.data.error.placaVeiculo) {
          msg = res.data.error.placaVeiculo;
        } else if (res.data.error.categoriaServico) {
          msg = res.data.error.categoriaServico;
        } else if (res.data.error.latitude) {
          msg = res.data.error.latitude;
        } else if (res.data.error.longitude) {
          msg = res.data.error.longitude;
        } else if (res.data.error.subCategoriaServico) {
          msg = res.data.error.subCategoriaServico;
        } else if (res.data.error.mensagem) {
          msg = res.data.error.mensagem;
        } else if (res.data.error.identificador) {
          msg = res.data.error.identificador;
        } else if (res.data.error.endereco) {
          msg = res.data.error.endereco;
        } else if (res.data.error.nome) {
          msg = res.data.error.nome;
        }

        setShowAlertMsg(msg);

        setNeedsLogin(true);
        setShowAlert(true);
      });
  }

  function b64toBlob(data: string) {
    const block = data.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];

    const sliceSize = 512;

    const byteCharacters = atob(realData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function selectSubCategory(value: any) {
    const extraFieldsValues: any = [];
    const extraFieldsResponsesValues: any = [];

    extraFields.forEach((element: any) => {
      if (element.fieldable_type === "App\\CategoriaServico") {
        extraFieldsValues.push(element);
      }
    });

    extraFieldsResponses.forEach((element: any) => {
      if (element.fieldable_type === "App\\CategoriaServico") {
        extraFieldsResponsesValues.push({
          id: element.id,
          required: element.required,
          tipo: element.tipo,
          fieldable_type: element.fieldable_type,
          value: "",
        });
      }
    });

    if (value?.campo) {
      value.campo.forEach((element: any) => {
        extraFieldsValues.push(element);

        extraFieldsResponsesValues.push({
          id: element.id,
          required: element.required,
          tipo: element.tipo,
          fieldable_type: element.fieldable_type,
          value: "",
        });
      });
    }

    setExtraFields(extraFieldsValues);
    setExtraFieldsResponses(extraFieldsResponsesValues);
    setSelectedSubCategory(value);
  }

  function handleChangeFieldText(value: string | number | null | undefined, id: number) {
    const extraFieldsUpdated = extraFieldsResponses.map((item) => {
      if (item.id === id) {
        if (value) {
          item.value = value.toString();
        } else {
          item.value = "";
        }
      }

      return item;
    });

    setExtraFieldsResponses(extraFieldsUpdated);
  }

  async function handleChangeFieldFile(value: any, id: number) {
    if (!value) {
      setFileSizes((oldState) => oldState.filter((item) => item.id !== id));
      return;
    }

    const extraFieldsUpdated = extraFieldsResponses.map((item) => {
      if (item.id === id) {
        if (item.value) {
          setFileSizes((oldState) => oldState.filter((item) => item.id !== id));
        }

        if (value) {
          item.value = value;
        } else {
          item.value = "";
        }
      }
      return item;
    });

    setFileSizes((oldState) => [...oldState, { id, size: value.size }]);

    setExtraFieldsResponses(extraFieldsUpdated);
  }

  function calculateFileSizes() {
    let size = 0;

    fileSizes.forEach((item) => {
      size += item.size;
    });

    return size;
  }

  function handleChangeStep(number: number) {
    if (number === 1) {
      if (subCategorias.length === 1) {
        setSelectedSubCategory(subCategorias[0]);
      }

      if (
        (data?.data.usarEndereco && (!lat || !lng)) ||
        selectedSubCategory.id === -1 ||
        message.length === 0 ||
        (plate.length > 0 && plate.length !== 3) ||
        (plate2.length > 0 && plate2.length !== 4)
      ) {
        setErroMsgs(true);
        return;
      }

      setStep(1);
    } else {
      setStep(0);
    }
  }

  if (isLoading || loadingSubCat) {
    return (
      <PageTemplate name={data?.data.titulo}>
        <PageTitle>{CONTENT.GLOBAL.LOADING} </PageTitle>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate name={data?.data.titulo}>
      {extraFields.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#356de1",
            fontSize: "3rem",
          }}
        >
          {step === 0 && (
            <>
              <IonIcon icon={removeOutline} />
              <IonIcon style={{ color: "#4b5563" }} icon={removeOutline} />
            </>
          )}
          {step === 1 && (
            <>
              <IonIcon style={{ color: "#4b5563" }} icon={removeOutline} />
              <IonIcon icon={removeOutline} />
            </>
          )}
        </div>
      )}
      <BlockForm title={data?.data.descricao}>
        {
          <>
            {step === 0 && (
              <>
                {data?.data.abrir_anonimo && !selectedSubCategory.link_externo && token && (
                  <IonItem>
                    <IonLabel>
                      <b>{CONTENT.PROTOCOLS.WITHOUT_IDENTIFICATION}</b>
                    </IonLabel>
                    <IonToggle onIonChange={() => setHideUser(!hideUser)} checked={hideUser} />
                  </IonItem>
                )}

                <IonItem>
                  <IonLabel>
                    <b>{CONTENT.PROTOCOLS.SELECT_CATEGORY}</b>
                  </IonLabel>
                  <IonSelect
                    id="selectSubCategory"
                    value={selectedSubCategory}
                    onIonChange={(e) => {
                      if (e.detail.value) {
                        selectSubCategory(e.detail.value);
                      }
                    }}
                    disabled={subCategorias.length === 1}
                    placeholder={
                      selectedSubCategory.id < 0
                        ? CONTENT.GLOBAL.TOUCH_TO_OPEN
                        : selectedSubCategory.titulo
                    }
                  >
                    {data?.data.sub_categoria_servicos.map((item: any, index: number) => {
                      return (
                        <IonSelectOption key={index} value={item}>
                          {item.titulo}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>

                {selectedSubCategory.mensagem && <h6>{selectedSubCategory.mensagem}</h6>}
                {showErroMsgs && selectedSubCategory.id === -1 && (
                  <ErrorMessage>{CONTENT.PROTOCOLS.ERROR_SELECT_CATEGORY}</ErrorMessage>
                )}

                {data?.data.abrir_anonimo &&
                  !selectedSubCategory.link_externo &&
                  hideUser &&
                  !token && (
                    <IonItem>
                      <IonLabel>
                        <b>{CONTENT.PROTOCOLS.NAME}:</b>
                      </IonLabel>
                      <IonInput
                        onIonInput={(e) => {
                          setName(e.detail.value ? e.detail.value : "");
                        }}
                      />
                    </IonItem>
                  )}

                {data?.data.abrir_anonimo &&
                  !selectedSubCategory.link_externo &&
                  hideUser &&
                  !token &&
                  showErroMsgs &&
                  message.length === 0 && (
                    <ErrorMessage>{CONTENT.PROTOCOLS.ERROR_NAME}</ErrorMessage>
                  )}

                {selectedSubCategory.link_externo && (
                  <>
                    <IonButton target="_blank" href={selectedSubCategory.link_externo || ""}>
                      {CONTENT.PROTOCOLS.ETERNAL_SERVICE}
                    </IonButton>
                  </>
                )}

                {!selectedSubCategory.link_externo && (
                  <>
                    {data?.data.usarEndereco && (
                      <GetLocation
                        onLocationChange={locationChangeHandle}
                        selectedSubCategory={selectedSubCategory}
                      />
                    )}

                    {data?.data.usarEndereco && showErroMsgs && (!lat || !lng) && (
                      <ErrorMessage>{CONTENT.PROTOCOLS.ERROR_SELECT_LOCATION}</ErrorMessage>
                    )}

                    {data?.data.usar_imagem && (
                      <UploadImage
                        verifyMaxSize={calculateFileSizes}
                        onChange={imageChangeHandle}
                        title={CONTENT.PROTOCOLS.IMAGE}
                      />
                    )}

                    <IonItem>
                      <IonLabel>
                        <b>{CONTENT.PROTOCOLS.OBSERVATIONS}: </b>
                      </IonLabel>
                      <IonTextarea
                        value={message}
                        placeholder={CONTENT.FEIRINHA.DESCRIPTION}
                        onIonInput={(e) => {
                          setMessage(e.detail.value ? e.detail.value : "");
                        }}
                      />
                    </IonItem>
                    {showErroMsgs && message.length === 0 && (
                      <ErrorMessage>{CONTENT.PROTOCOLS.ERROR_MESSAGE}</ErrorMessage>
                    )}

                    {data?.data.usarIdentificador && (
                      <IonItem>
                        <IonLabel>
                          <b>
                            {data?.data.nomeIdentificador
                              ? data?.data.nomeIdentificador
                              : CONTENT.PROTOCOLS.INDENTIFIER}
                            :{" "}
                          </b>
                        </IonLabel>
                        <IonTextarea
                          autoGrow={true}
                          placeholder=" "
                          onIonInput={(e) => {
                            setIdentifier(e.detail.value ? e.detail.value : "");
                          }}
                        />
                      </IonItem>
                    )}

                    {data?.data.usarEndereco && false && (
                      <IonItem>
                        <IonLabel>
                          <b>{CONTENT.PROTOCOLS.ADDRESS}: </b>
                        </IonLabel>
                        <IonTextarea
                          autoGrow={true}
                          placeholder={CONTENT.PROTOCOLS.ADDRESS_PLACEHOLDER}
                          onIonInput={(e) => {
                            setAdress(e.detail.value ? e.detail.value : "");
                          }}
                        />
                      </IonItem>
                    )}

                    {data?.data.usarPlaca && (
                      <div style={{ display: "flex" }}>
                        <IonItem>
                          <IonLabel>
                            <b>{CONTENT.PROTOCOLS.PLATE}: </b>
                          </IonLabel>
                          <IonTextarea
                            style={{ textAlign: "end" }}
                            maxlength={3}
                            autoGrow={true}
                            placeholder="XXX"
                            onIonInput={(e) => {
                              if (e.detail.value && e.detail.value.length < 4)
                                setPlate(e.detail.value ? e.detail.value : "");
                            }}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel>
                            <b>-</b>
                          </IonLabel>
                          <IonTextarea
                            maxlength={4}
                            autoGrow={true}
                            placeholder="XXXX"
                            onIonInput={(e) => {
                              if (e.detail.value && e.detail.value.length < 5)
                                setPlate2(e.detail.value ? e.detail.value : "");
                            }}
                          />
                        </IonItem>
                      </div>
                    )}
                    {data?.data.usarPlaca &&
                      showErroMsgs &&
                      ((plate.length > 0 && plate.length !== 3) ||
                        (plate2.length > 0 && plate2.length !== 4)) && (
                        <ErrorMessage>A placa informada é inválida.</ErrorMessage>
                      )}

                    {data?.data.campo.length > 0 && (
                      <IonButton
                        onClick={() => {
                          handleChangeStep(1);
                        }}
                      >
                        <IonIcon icon={caretForwardOutline} />
                        Próximo
                      </IonButton>
                    )}
                    {data?.data.campo.length === 0 && (
                      <IonButton color="success" onClick={submit}>
                        <IonIcon icon={checkmarkOutline} />
                        {CONTENT.PROTOCOLS.REGISTER}
                      </IonButton>
                    )}
                  </>
                )}
              </>
            )}

            {step === 1 && extraFields.length > 0 && (
              <>
                {extraFields.map((item: any, index: number) => {
                  return (
                    <div key={item.id}>
                      {item.tipo === "string" && (
                        <IonItem>
                          <IonLabel class="ion-text-wrap">
                            <b>{item.nome}:</b>
                          </IonLabel>
                          <IonInput
                            value={extraFieldsResponses[index].value}
                            onIonInput={(event) =>
                              handleChangeFieldText(event.target.value, item.id)
                            }
                          />
                        </IonItem>
                      )}
                      {item.tipo === "integer" && (
                        <IonItem>
                          <IonLabel class="ion-text-wrap">
                            <b>{item.nome}:</b>
                          </IonLabel>
                          <IonInput
                            type="number"
                            value={extraFieldsResponses[index].value}
                            onIonInput={(event) =>
                              handleChangeFieldText(event.target.value, item.id)
                            }
                          />
                        </IonItem>
                      )}
                      {item.tipo === "data" && (
                        <IonItem>
                          <IonLabel class="ion-text-wrap">
                            <b>{item.nome}:</b>
                          </IonLabel>
                          <IonInput
                            type="date"
                            value={extraFieldsResponses[index].value}
                            onIonInput={(event) =>
                              handleChangeFieldText(event.target.value, item.id)
                            }
                          />
                        </IonItem>
                      )}
                      {item.tipo === "imagem" && (
                        <UploadImage
                          verifyMaxSize={calculateFileSizes}
                          title={item.nome + ":"}
                          onChange={(event) => {
                            handleChangeFieldFile(event.file, item.id);
                          }}
                        />
                      )}
                      {item.tipo === "arquivo" && (
                        <>
                          <div
                            style={{
                              backgroundColor: `${COLORS.INPUTCOLOR}`,
                              paddingTop: "0.75rem",
                              marginBottom: "0",
                            }}
                          >
                            <IonLabel class="ion-text-wrap" style={{ padding: "0 1rem" }}>
                              <b>{item.nome}:</b>
                            </IonLabel>
                            <UploadFile
                              noMarginBottom
                              verifyMaxSize={calculateFileSizes}
                              onChange={(event) => {
                                handleChangeFieldFile(event.file, item.id);
                              }}
                            />
                          </div>
                        </>
                      )}

                      {showErroMsgs &&
                        extraFieldsResponses[index].required &&
                        extraFieldsResponses[index].value === "" && (
                          <ErrorMessage>{"Campo " + item.nome + " é obrigatório."}</ErrorMessage>
                        )}
                    </div>
                  );
                })}

                <IonButton
                  onClick={() => {
                    handleChangeStep(0);
                  }}
                >
                  <IonIcon icon={caretBackOutline} />
                  Voltar
                </IonButton>

                <IonButton color="success" onClick={submit}>
                  <IonIcon icon={checkmarkOutline} />
                  {CONTENT.PROTOCOLS.REGISTER}
                </IonButton>
              </>
            )}
          </>
        }
      </BlockForm>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
          if (protocolId !== 0 && !needsLogin) {
            history.replace("/" + getAppSlug() + "/protocolo/" + protocolId);
          }
        }}
        header={showAlertTitle}
        message={showAlertMsg}
        buttons={["OK"]}
      />

      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
    </PageTemplate>
  );
}
