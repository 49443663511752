import { http } from "@/lib/axios";
import { Resource } from "./types";

type GetResourcesParams = {
  appId: number;
  entityId: string;
};

type GetResourcesResponse = Array<{
  id: number;
  titulo: string;
  texto_finalizacao_html: string | null;
  texto_instrucoes: string | null;
  fila: boolean;
  vagas_disponiveis_amplaconcorrencia: number;
  vagas_disponiveis_prioridade: number;
  perguntas: Array<{
    id: number;
    titulo: string;
    descricao: string;
    resposta_positiva: string;
    resposta_negativa: string;
    tipo: string;
    justificativa_remocao: string | null;
  }>;
  campos: Array<{
    id: number;
    tipo: "string" | "integer" | "data" | "imagem" | "arquivo" | "select";
    nome: string;
    hint: string | null;
    required: boolean;
    opcoes_campo: Array<{
      id: number;
      label: string;
    }>;
  }>;
}>;

export async function getResources({
  appId,
  entityId,
}: GetResourcesParams): Promise<Array<Resource>> {
  try {
    const response = await http.get<GetResourcesResponse>(
      `v3/${appId}/mobile/entidade_agendamento/${entityId}/recursos`,
    );

    const data = response.data;

    const resources = data.map((item) => ({
      id: item.id,
      name: item.titulo,
      textFinalization: item.texto_finalizacao_html,
      textInstructions: item.texto_instrucoes,
      enabledQueue: item.fila,
      vacanciesForBroadCompetition: item.vagas_disponiveis_amplaconcorrencia,
      priorityVacancies: item.vagas_disponiveis_prioridade,
      extraFields: item.campos.map((item) => ({
        id: item.id,
        name: item.nome,
        hint: item.hint,
        required: item.required,
        type: item.tipo,
        options: item.opcoes_campo,
      })),
      questions: item.perguntas.map((question) => ({
        id: question.id,
        title: question.titulo,
        description: question.descricao,
        removalJustification: question.justificativa_remocao,
        positiveResponse: question.resposta_positiva,
        negativeResponse: question.resposta_negativa,
        type: question.tipo,
      })),
    }));

    return resources;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
