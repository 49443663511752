import { http } from "@/lib/axios";
import { Holder } from "./types";

export async function alterUserViewedReviewHolder({
  appId,
  id,
  idField,
}: {
  appId: number;
  id: number;
  idField?: number;
}): Promise<Holder> {
  try {
    const response = await http.put<Holder>(
      `/v3/${appId}/titular_review/alter_viewed/${id}`,
      idField
        ? {
            id: idField,
          }
        : {},
    );

    return response.data;
  } catch (error) {
    throw new Error();
  }
}
