import styled from "styled-components";
import { IonIcon, IonRouterLink } from "@ionic/react";
import { COLORS } from "@/data/colors";

export const Ancho = styled(IonRouterLink)``;

export const Content = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #334155;
`;

export const RowTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const ContentTitle = styled.div`
  flex: 1 1 0%;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const ContentIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconRow = styled(IonIcon)`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
`;

export const RowDate = styled.div`
  font-size: 12px;
  color: #94a3b8;
`;

export const RowStatusId = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
`;

export const ContentStatus = styled.div`
  padding: 3px 10px;
  border-radius: 16px;
`;

export const Status = styled.span`
  font-size: 12px;
`;

export const ContentId = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Id = styled.span`
  color: #334155;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 8px;
`;

export const IconCopy = styled(IonIcon)`
  font-size: 20px;
  color: #cbd5e1;
`;
