import { ComponentProps } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";
import { Icon, Smiley, SmileyAngry, SmileyMeh, SmileySad, SmileyWink } from "@phosphor-icons/react";

import { Button } from "@/components/Button";
import { cn } from "@/lib/utils";
import { useAppId } from "@/store/useAppStore";

import { ratingAtom } from "../index";
import { useRatingUser } from "../hooks/useRatingUser";

const appReviewFormSchema = z.object({
  rating: z.number().min(1).max(5),
});

type appReviewFormData = z.infer<typeof appReviewFormSchema>;

type MoodAvaliationProps = {
  handleChangeStep: (step: number) => void;
  enableAvaliationService: boolean;
};

type ButtonMoodProps = ComponentProps<"button"> & {
  text: string;
  icon: Icon;
  mood: string;
};

function ButtonMood({ text, icon: Icon, mood, ...rest }: ButtonMoodProps) {
  return (
    <div className="flex flex-col items-center gap-1">
      <button
        {...rest}
        type="button"
        className={cn(
          "flex h-10 w-10 items-center justify-center rounded-md border-thin border-solid bg-white transition-colors data-[selected=true]:text-white",
          {
            "border-[#ff5757] text-[#ff5757] data-[selected=true]:bg-[#ff5757]": mood === "angry",
            "border-[#ff9641] text-[#ff9641] data-[selected=true]:bg-[#ff9641]": mood === "sad",
            "border-[#ffca0f] text-[#ffca0f] data-[selected=true]:bg-[#ffca0f]": mood === "meh",
            "border-[#48c548] text-[#48c548] data-[selected=true]:bg-[#48c548]": mood === "smile",
            "border-[#16e274] text-[#16e274] data-[selected=true]:bg-[#16e274]": mood === "wink",
          },
        )}
      >
        <Icon size={20} />
      </button>
      <Text size="xs">{text}</Text>
    </div>
  );
}

const dataButtons: Record<number, { icon: Icon; text: string; mood: string }> = {
  1: { icon: SmileyAngry, text: t("avaliationResearch.Péssimo"), mood: "angry" },
  2: { icon: SmileySad, text: t("avaliationResearch.Ruim"), mood: "sad" },
  3: { icon: SmileyMeh, text: t("avaliationResearch.Regular"), mood: "meh" },
  4: { icon: Smiley, text: t("avaliationResearch.Bom"), mood: "smile" },
  5: { icon: SmileyWink, text: t("avaliationResearch.Ótimo"), mood: "wink" },
};

export function MoodAvaliationModal({
  handleChangeStep,
  enableAvaliationService,
}: MoodAvaliationProps) {
  const { t } = useTranslation();

  const appId = useAppId();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const [rating, setRating] = useAtom(ratingAtom);

  const { mutateAsync, isLoading } = useRatingUser();

  const { control, handleSubmit } = useForm<appReviewFormData>({
    resolver: zodResolver(appReviewFormSchema),
    defaultValues: {
      rating: rating.mood,
    },
  });

  const handleRating = async ({ mood }: { mood: number }) => {
    if (!mood) {
      return;
    }

    await mutateAsync(
      {
        appId: Number(appId),
        mood,
        stars: null,
        protocoloId: Number(id),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["protocols", Number(id)], { refetchType: "active" });
          handleChangeStep(4);
        },
        onError: (err) => {
          console.error(err);
          toast.error("Não foi possível realizar avaliação");
        },
      },
    );
  };

  function onSubmit(data: appReviewFormData) {
    setRating((prev) => ({ ...prev, mood: data.rating }));

    if (enableAvaliationService) {
      handleChangeStep(2);
      return;
    }

    handleRating({ mood: data.rating });
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-1">
        <Text size="md" className="text-center" color="slate-700" weight="semibold">
          {t("avaliationResearch.Como você avalia nosso atendimento?")}
        </Text>
        <Text size="sm" color="slate-400" className="max-w-xs text-center">
          {t("avaliationResearch.Avalie como te atenderam, independente do resultado do serviço.")}
        </Text>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-8 flex w-full flex-col items-center gap-8"
      >
        <div className="flex items-center gap-6">
          {[...Array(5)].map((_, index) => (
            <Controller
              key={index}
              name="rating"
              control={control}
              render={({ field: { value, onChange } }) => {
                const data = dataButtons[index + 1];

                return (
                  <ButtonMood
                    text={data.text}
                    icon={data.icon}
                    mood={data.mood}
                    onClick={() => onChange(index + 1)}
                    data-selected={value === index + 1}
                  />
                );
              }}
            />
          ))}
        </div>

        <Button full type="submit" size="lg" className="max-w-md" loading={isLoading}>
          {t("general.Continuar")}
        </Button>
      </form>
    </div>
  );
}
