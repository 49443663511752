import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { TabTemplate } from "@/templates/old/TabTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { ProtocolChat } from "./components/ProtocolChat";
import { ProtocolDetail } from "./components/ProtocolDetail";
import { getProtocolById } from "@/services/old/servicos";
import { getContent } from "@/content/index";

const firstTab = {
  value: "Chat",
  content: <ProtocolChat />,
};

const secondTab = {
  value: "Detalhes",
  content: <ProtocolDetail />,
};

interface ProtocolParams {
  id: string;
  SLUG: string;
}
export function ProtocolPage() {
  const CONTENT = getContent();

  const params: ProtocolParams = useParams();

  const { isLoading, data } = useQuery(
    ["getProtocolById", params.id],
    () => getProtocolById(params.id, params.SLUG),
    { refetchOnWindowFocus: false },
  );

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.GLOBAL.LOADING} </PageTitle>
      </PageTemplate>
    );
  } else if (!data) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.PROTOCOLS.CANT_ACCESS}</PageTitle>
      </PageTemplate>
    );
  }

  return <TabTemplate first={firstTab} second={secondTab} name={CONTENT.SERVICES.HEADER_TITLE} />;
}
