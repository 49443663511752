import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, Info } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton } from "@/components";
import { CardWallet, CardWalletSkeleton } from "./components/CardWallet";
import { Wallet } from "@/services/wallets";
import { useAppStore } from "@/store/useAppStore";
import { useWallets } from "./hooks/useWallets";

export function Wallets() {
  const { t } = useTranslation();
  const history = useHistory();

  const [appId, slug] = useAppStore((state) => [state.app?.id, state.app?.slug]);

  if (!appId || !slug) {
    throw new Error("Not loaded app!");
  }

  const { data: wallets, isLoading } = useWallets({ appId });

  function handleGoBack() {
    history.replace(`/${slug}`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("wallets.Carteiras")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
          <div className="flex flex-1 flex-col gap-5 bg-white">
            {isLoading || !wallets ? (
              <WalletsSkeleton />
            ) : wallets.length > 0 ? (
              <ListWallets wallets={wallets} />
            ) : (
              <EmptyList />
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
}

interface ListWalletsProps {
  wallets: Array<Wallet>;
}

function ListWallets({ wallets }: ListWalletsProps) {
  const history = useHistory();

  const slug = useAppStore((state) => state.app?.slug);

  function handleOpenWallet(wallet: Wallet) {
    history.push(
      `/${slug}/carteiras/${wallet.id}?idTitular=${wallet?.holder.id}&hashCarteira=${wallet?.hash}`,
    );
  }

  return (
    <>
      <h1 className="font-medium text-slate-700 sm:text-lg">Minhas carteiras</h1>
      <div className="flex flex-col gap-8">
        {wallets.map((wallet) => (
          <CardWallet key={wallet.id} wallet={wallet} onClick={() => handleOpenWallet(wallet)} />
        ))}
      </div>
    </>
  );
}

function WalletsSkeleton() {
  return (
    <>
      <div className="h-6 w-1/3 rounded-full bg-slate-200/60" />
      <div className="flex flex-col gap-8">
        {Array.from(Array(4).keys()).map((index) => (
          <CardWalletSkeleton key={index} />
        ))}
      </div>
    </>
  );
}

function EmptyList() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 text-center">
      <span className="flex items-center gap-2 font-medium text-slate-700">
        <Info size={20} />
        {t("wallets.Sem carteiras associadas")}
      </span>
      <p className="px-6 text-xs leading-5 text-slate-400">
        ({t("wallets.Caso isso seja um engano, entre em contato com a instituição da carteira")})
      </p>
    </div>
  );
}
