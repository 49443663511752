import { IonFab, IonFabButton, IonIcon, IonPage, IonContent } from "@ionic/react";
import { add } from "ionicons/icons";

import { Header } from "@/components/old/Header";
import { PageContentTransparent } from "@/components/old/Base";

interface PageTemplateProps {
  name: string;
  children: any;
  backPress?: any;
  hideHeaderUserButton?: boolean;
  showAddButton?: boolean;
  onAddButtonClick?: () => void;
  onSearchTextChange?: (search: string) => void;
}

export function TransparentTemplate(props: PageTemplateProps) {
  return (
    <IonPage>
      <Header
        name={props.name}
        backPress={props.backPress}
        onSearchTextChange={props.onSearchTextChange}
        hideUserButton={props.hideHeaderUserButton}
      />

      <IonContent fullscreen style={{ "--background": "transparent" }}>
        <PageContentTransparent>{props.children}</PageContentTransparent>

        {props.showAddButton && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={props.onAddButtonClick}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  );
}
