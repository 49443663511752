import { Capacitor } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { isPlatform } from "@ionic/core";

import { checkAndroid, checkIOS } from "@/utils/checks";

export const usePhotoGallery = () => {
  const { t } = useTranslation();

  async function takePhoto(source: "camera" | "photos") {
    try {
      const permissionType = source === "camera" ? "camera" : "photos";

      const permission = await Camera.checkPermissions();

      if (permission[permissionType]) {
        const file = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: getSource(source),
          quality: 100,
        });

        if (file) {
          const savedPhoto = await savePhoto(file);

          return savedPhoto;
        }
      } else {
        const permissions = await Camera.requestPermissions();

        if (permissions[permissionType] === "granted") {
          takePhoto(permissionType);
        }
      }
    } catch (error: any) {
      console.error(error);

      if (error?.message === "User cancelled photos app" || error?.message === "No image picked") {
        return;
      }

      toast.error(t("general.Erro ao buscar imagem"));
    }
  }

  async function savePhoto(photo: Photo) {
    try {
      let base64Data: string;

      if (isPlatform("hybrid")) {
        if (!photo.path) {
          return;
        }

        const file = await Filesystem.readFile({
          path: photo.path,
        });

        base64Data = String(file.data);
      } else {
        if (!photo.webPath) {
          return;
        }

        base64Data = await base64FromPath(photo.webPath);
      }

      const response = await fetch(
        isPlatform("hybrid") ? `data:image/jpeg;base64,${base64Data}` : base64Data,
      );

      const blob = await response.blob();

      return {
        file: blob,
        filePath: base64Data,
      };
    } catch (error) {
      console.error(error);
    }
  }

  async function base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onerror = reject;

      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        }
      };

      reader.readAsDataURL(blob);
    });
  }

  function getSource(source: "camera" | "photos") {
    if (checkAndroid() && isPlatform("mobileweb")) {
      return CameraSource.Photos;
    }

    if (
      checkIOS() &&
      (isPlatform("mobileweb") || (Capacitor.getPlatform() === "web" && source === "camera"))
    ) {
      return CameraSource.Camera;
    }

    return source === "camera" ? CameraSource.Camera : CameraSource.Photos;
  }

  return { takePhoto, savePhoto };
};
