import { useParams } from "react-router-dom";

import { useAppId } from "@/store/useAppStore";
import { useResults } from "../hooks/use-results";
import { CardProposal, CardProposalSkeleton } from "./card-proposal";

export function ListResults() {
  const { id } = useParams<{ id: string }>();
  const appId = useAppId();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data } = useResults({ appId, consultId: id });

  return (
    <div className="flex flex-col gap-4">
      {data?.length
        ? data?.map((item) => (
            <CardProposal
              key={item.id}
              title={item.description}
              creator={item.username}
              tags={item.tags}
              themes={item.themes}
            />
          ))
        : null}

      {!data?.length
        ? Array.from({ length: 3 }).map((_, index) => <CardProposalSkeleton key={index} />)
        : null}
    </div>
  );
}
