import styled from "styled-components";
import { IonSpinner } from "@ionic/react";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem 1rem calc(env(safe-area-inset-bottom) + 1.5rem);

  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #334155;
    text-align: center;
  }
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    color: #94a3b8;
  }
`;

export const BigSpinner = styled(IonSpinner)`
  --color: #334155;
`;

export const Error = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 320px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.375rem;

    strong {
      font-size: 1rem;
      font-weight: 600;
      color: #334155;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      color: #94a3b8;
    }
  }
`;
