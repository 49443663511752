import styled from "styled-components";
import { IonModal } from "@ionic/react";
import { COLORS } from "@/data/colors";

interface IconProps {
  status: "error" | "success" | "warn";
}

export const ModalContent = styled(IonModal)`
  --width: fit-content;
  --min-width: 250px;
  --max-width: 90vw;
  --height: fit-content;
  --background: #ffffff;
  --border-radius: 10px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  div.content {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: ${COLORS.PRIMARY};
    margin: 0;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: #94a3b8;
    text-align: center;
  }

  footer {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const Icon = styled.div<IconProps>`
  margin: 0 auto;
  height: 48px;
  width: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${(props) =>
    props.status === "error" ? "#fee2e2" : props.status === "success" ? "#dcfce7" : "#fef9c3"};
`;

export const Button = styled.button`
  background-color: ${COLORS.PRIMARY};
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  padding: 16px 24px;
  border-radius: 6px;
`;

export const Link = styled.a`
  color: ${COLORS.PRIMARY};
  margin-bottom: 8px;
  cursor: pointer;
`;
