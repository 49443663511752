import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App } from "@capacitor/app";
import { IonSpinner, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Button, Header, HeaderButton } from "@/components";
import { ModalInfoAuth } from "./components/modal-info-auth";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useUserToken } from "@/store/useAuthStore";
import { useEntityScheduling } from "./hooks/use-entity-scheduling";

import errorIllustration from "@/assets/illustrations/error.svg";

export function EntityScheduling() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ from?: string }>();

  const searchParams = useSearchParams();
  const navigation = useHistory();
  const slug = useAppSlug();
  const isAuthenticated = !!useUserToken();

  const appId = useAppId();

  const [isOpenModal, setIsOpenModal] = useState(false);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { data, isLoading } = useEntityScheduling({ appId, id });

  useIonViewWillEnter(() => {
    if (!searchParams.get("finished")) {
      localStorage.setItem("prevPath-scheduling", location.state?.from ?? "");
    }
  }, [searchParams, location.state?.from]);

  useIonViewWillEnter(() => {
    App.addListener("backButton", () => handleGoBack());
  }, []);

  useIonViewWillLeave(() => {
    App.removeAllListeners();
  }, []);

  function handleCreateScheduling() {
    if (!isAuthenticated) {
      setIsOpenModal(true);
      return;
    }

    searchParams.delete("finished");
    searchParams.delete("step");
    searchParams.append("step", "resource");

    navigation.push({
      pathname: `/${slug}/agendamentos/${id}/criar-agendamento`,
      search: searchParams.toString(),
    });
  }

  function handleGoMySchedulings() {
    const url = !isAuthenticated ? `/${slug}/buscar-agendamentos` : `/${slug}/meus-agendamentos`;
    navigation.push(url);
  }

  function handleGoBack() {
    const prevPath = localStorage.getItem("prevPath-scheduling");

    if (prevPath) {
      navigation.replace(prevPath);
      return;
    }

    navigation.replace(`/${slug}/home`);
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleGoBack}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-primary-500">
            {t("schedulings.Agendamento")}
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl px-4 py-6 ios:pb-2">
          {!isLoading && data ? (
            <div className="flex flex-1 flex-col justify-between">
              <div className="flex flex-col items-center gap-16">
                <div className="text-center">
                  <Text asChild size="lg" color="slate-700">
                    <strong>{data.title}</strong>
                  </Text>
                  <Text size="xs" color="slate-400">
                    {data.description}
                  </Text>
                </div>

                <img src={data.image} alt="" className="h-40 w-full object-contain" />

                <div
                  className="font-sans text-sm text-slate-700 [&>p>br]:hidden [&>strong]:font-medium"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>

              <footer className="flex flex-col gap-3">
                <Button size="lg" variant="secondary" onClick={handleGoMySchedulings}>
                  {t("schedulings.Consultar meus agendamentos")}
                </Button>
                <Button size="lg" onClick={handleCreateScheduling}>
                  {t("general.Continuar")}
                </Button>
              </footer>

              <ModalInfoAuth isOpen={isOpenModal} onClose={handleCloseModal} />
            </div>
          ) : null}

          {isLoading && !data ? <ContentLoading /> : null}

          {!isLoading && !data ? <ContentError /> : null}
        </div>
      </Content>
    </Layout>
  );
}

function ContentLoading() {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <IonSpinner className="text-primary-500" />
    </div>
  );
}

function ContentError() {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-5 text-center">
      <img src={errorIllustration} alt="Dois celulares de" />

      <div className="space-y-1">
        <Text asChild weight="medium" size="md" color="slate-700">
          <strong>{t("schedulings.Erro ao buscar informações")}</strong>
        </Text>
        <Text>
          {t(
            "schedulings.Não carregar os dados do agendamento ao qual você acessou, nos desculpe e tente novamente depois.",
          )}
        </Text>
      </div>
    </div>
  );
}
