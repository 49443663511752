import { http } from "@/lib/axios";

interface useRatingParams {
  appId: number;
  mood: number;
  stars: number | null;
  protocoloId: number;
  avaliationText?: string | null;
}

export async function ratingUSer({
  appId,
  mood,
  stars,
  avaliationText,
  protocoloId,
}: useRatingParams) {
  return http.post(`v3/${appId}/pesquisa_protocolo/${protocoloId}`, {
    nota_atendente: mood,
    nota_servico: stars ?? "",
    texto_avaliacao: avaliationText ?? "",
  });
}
