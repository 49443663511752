import { MapContainer } from "react-leaflet";
import { MapContent } from "./MapContent";

interface MapContainerProps {
  lat: number;
  lng: number;
}

export function MapPreviewContainer({ lat, lng }: MapContainerProps) {
  return (
    <MapContainer
      center={{ lat, lng }}
      zoom={13}
      scrollWheelZoom={false}
      style={{
        width: "100%",
        height: "250px",
        marginTop: "16px",
        marginBottom: "16px",
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
      }}
    >
      <MapContent lat={lat} lng={lng} />
    </MapContainer>
  );
}
