import React, { ReactNode } from "react";
import * as AccordionRadix from "@radix-ui/react-accordion";
import { AccordionSingleProps, AccordionMultipleProps } from "@radix-ui/react-accordion";
import { Text } from "@astrolabe-ui/react";
import { CaretDown } from "@phosphor-icons/react";

import { cn } from "@/lib/utils";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface IAccordion {
  items: Array<{ title: string | ReactNode; content: ReactNode; value: string }>;
  className?: string;
  itemClassName?: string;
  contentClassName?: string;
  titleClassName?: string;
}

export type Ref = HTMLButtonElement & HTMLDivElement;

const Accordion = ({
  items,
  className,
  titleClassName,
  itemClassName,
  contentClassName,
  ...props
}: IAccordion & (AccordionSingleProps | AccordionMultipleProps)) => (
  <AccordionRadix.Root className={cn("w-full", className)} {...props}>
    {items.map((item) => (
      <AccordionItem
        key={item.value}
        value={item.value}
        {...(itemClassName && { className: itemClassName })}
      >
        <AccordionTrigger>
          {typeof item.title === "string" ? (
            <Text color="slate-700" {...(titleClassName && { className: titleClassName })}>
              {item.title}
            </Text>
          ) : (
            item.title
          )}
        </AccordionTrigger>
        <AccordionContent {...(contentClassName && { className: contentClassName })}>
          {item.content}
        </AccordionContent>
      </AccordionItem>
    ))}
  </AccordionRadix.Root>
);

const AccordionItem = React.forwardRef<Ref, Props & { value: string }>(
  ({ children, className, ...props }, forwardedRef) => (
    <AccordionRadix.Item
      className={cn("mt-px overflow-hidden", className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </AccordionRadix.Item>
  ),
);

const AccordionTrigger = React.forwardRef<Ref, Props>(
  ({ children, className, ...props }, forwardedRef) => (
    <AccordionRadix.Header className="group flex items-center" ref={forwardedRef}>
      <AccordionRadix.Trigger
        className={cn("flex flex-1 items-center justify-between", { className })}
        {...props}
      >
        {children}

        <CaretDown
          className="flex transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180"
          aria-hidden
        />
      </AccordionRadix.Trigger>
    </AccordionRadix.Header>
  ),
);

const AccordionContent = React.forwardRef<Ref, Props>(
  ({ children, className, ...props }, forwardedRef) => (
    <AccordionRadix.Content
      className={cn(
        "overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown",
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      <div className="py-4">{children}</div>
    </AccordionRadix.Content>
  ),
);

AccordionTrigger.displayName = "AccordionTrigger";
AccordionContent.displayName = "AccordionContent";
AccordionItem.displayName = "AccordionItem";

export { Accordion };
