import styled, { css } from "styled-components";
import { IonSpinner } from "@ionic/react";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.875rem 1.25rem;

    & > p {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1;
      color: #334155;

      span {
        font-size: 0.75rem;
        font-weight: 300;
      }
    }
  }
`;

export const Content = styled.div`
  border-bottom: 1px solid #e5e7eb;
  flex: 1;
  padding: 1.25rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

interface BadgeProps {
  variant: "theme" | "tag";
}

export const Badge = styled.span<BadgeProps>`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.variant === "theme" ? "#dbecfe" : "#f1f5f9")};

  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  color: ${(props) => (props.variant === "theme" ? COLORS.PRIMARY : "#334155")};
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 160%;
  color: #334155;

  &.clamp {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
`;

export const Creator = styled.div`
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 9999px;

  display: flex;
  align-items: center;
  gap: 0.375rem;

  span {
    font-size: 0.75rem;
    font-weight: 500;
    color: #334155;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

interface ButtonProps {
  variant: string;
  blocked: boolean;
}

export const Button = styled.button<ButtonProps>`
  all: unset;
  box-sizing: border-box;

  cursor: pointer;
  position: relative;
  overflow: hidden;

  min-width: 84px;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ variant }) =>
    variant === "primary" &&
    css`
      border-color: ${COLORS.PRIMARY};
      background-color: ${COLORS.PRIMARY};
      color: #ffffff;
    `}

  ${({ variant }) =>
    variant === "outline" &&
    css`
      border-color: #e2e8f0;
      background-color: transparent;
      color: #e43c3c;
    `}


  ${({ blocked }) =>
    blocked &&
    css`
      border-color: #94a3b8;
      background-color: #94a3b8;
      color: #cbd5e1;
      cursor: not-allowed;
    `}
`;

export const Spinner = styled(IonSpinner)`
  width: 18px;
  height: 18px;
  color: inherit;
`;
