import { useQuery } from "@tanstack/react-query";

import { getBookings } from "@/services/assetsScheduling";

export function useBooking(appId: number, cpf?: string | null, staleTime?: number) {
  return useQuery({
    queryKey: ["list-assets-scheduling-institution", appId],
    queryFn: () => getBookings(appId, cpf),
    enabled: !!appId && !!cpf,
    retry: false,
    ...(staleTime && { staleTime }),
  });
}
