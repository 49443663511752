import { useQuery } from "@tanstack/react-query";
import { getAvailableHours } from "@/services/schedulings";

type useAvailableHoursParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  localId: number;
  scheduleId: number;
  schedulingId?: number;
};

export function useAvailableHours({
  appId,
  entityId,
  resourceId,
  localId,
  scheduleId,
  schedulingId,
}: useAvailableHoursParams) {
  return useQuery({
    queryKey: ["available_hours", entityId, resourceId, localId, scheduleId, schedulingId, appId],
    queryFn: () =>
      getAvailableHours({ appId, entityId, resourceId, scheduleId, schedulingId, localId }),
    enabled: !!entityId && !!resourceId && !!localId && !!scheduleId && !!appId,
  });
}
