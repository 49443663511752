import { KeyboardEvent, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextArea, TextInput } from "@astrolabe-ui/react";
import { Calendar } from "@phosphor-icons/react";

import { LocationInput, SelectItems, valueType } from "@/components";
import { Field } from "@/services/categoryServices";
import { formatDate } from "@/utils/formats";

import { FileInput } from "./FileInput";

export const Fields = ({ fields, isValid }: { fields: Array<Field>; isValid: boolean }) => {
  const {
    register,
    setValue,
    getValues,
    watch,
    setError,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  useEffect(() => {
    !isValid && trigger();
  }, [isValid, trigger]);

  return (
    <div className="flex w-full flex-wrap gap-4">
      {fields.map((field) => (
        <div key={field.id} className="w-full">
          {field.type === "integer" ? (
            <TextInput
              hint={field.hint}
              placeholder={field.name}
              floating
              id={field.id.toString()}
              {...register(field.id.toString(), {
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              })}
              inputMode="numeric"
              type="number"
              {...(errors[field.id] && {
                error: (errors[field.id]?.message as string) || "",
              })}
            />
          ) : field.type === "string" ? (
            <TextInput
              hint={field.hint}
              placeholder={field.name}
              id={field.id.toString()}
              {...register(field.id.toString(), {
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              })}
              floating
              {...(errors[field.id] && {
                error: (errors[field.id]?.message as string) || "",
              })}
            />
          ) : field.type === "select" ? (
            <Controller
              control={control}
              name={field.id.toString()}
              rules={{
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <SelectItems
                  hint={field.hint}
                  options={field.options.map((opt) => ({
                    value: opt.id.toString(),
                    label: opt.label,
                  }))}
                  selected={value}
                  title={field.name}
                  {...(errors[field.id] && {
                    error: (errors[field.id]?.message as string) || "",
                  })}
                  onChange={onChange}
                />
              )}
            />
          ) : field.type === "arquivo" ? (
            <Controller
              control={control}
              name={field.id.toString()}
              defaultValue=""
              rules={{
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FileInput
                  hint={field.hint}
                  id={field.id.toString()}
                  name={field.name}
                  {...(errors[field.id] && {
                    error: (errors[field.id]?.message as string) || "",
                  })}
                  {...(field.required &&
                    !isValid &&
                    value?.length === 0 && {
                      required: field.required,
                    })}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          ) : field.type === "data" ? (
            <Controller
              control={control}
              name={field.id.toString()}
              rules={{
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  hint={field.hint}
                  placeholder={field.name}
                  icon={<Calendar />}
                  name={field.id.toString()}
                  floating
                  value={value ?? ""}
                  inputMode="numeric"
                  maxLength={10}
                  onKeyDown={(
                    e: KeyboardEvent<HTMLInputElement> & {
                      target: { value: string };
                    },
                  ) => {
                    if (e.key === "Backspace") {
                      setError(field.id.toString(), {});
                      return onChange(value.slice(0, -1));
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length < 10) {
                      setError(field.id.toString(), {
                        message: "Data incompleta",
                      });
                      setValue(field.id.toString(), "");
                    } else {
                      setError(field.id.toString(), {});
                    }
                  }}
                  onChange={(e) => {
                    const data = formatDate(e.target.value);
                    if (!data) {
                      setError(field.id.toString(), {
                        message: "Data inválida",
                      });
                    } else {
                      onChange(data as string);
                      setError(field.id.toString(), {});
                    }
                  }}
                  {...(errors[field.id] && {
                    error: errors[field.id]?.message?.toString(),
                  })}
                />
              )}
            />
          ) : field.type === "imagem" ? (
            <Controller
              control={control}
              name={field.id.toString()}
              defaultValue=""
              rules={{
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FileInput
                  hint={field.hint}
                  id={field.id.toString()}
                  name={field.name}
                  image
                  {...(errors[field.id] && {
                    error: (errors[field.id]?.message as string) || "",
                  })}
                  {...(field.required &&
                    !isValid &&
                    value?.length === 0 && {
                      required: field.required,
                    })}
                  defaultValue={value}
                  onChange={(e) => {
                    console.log(e);
                    onChange(e);
                  }}
                />
              )}
            />
          ) : field.type === "textarea" ? (
            <TextArea
              hint={field.hint}
              label={field.name}
              id={field.id.toString()}
              {...register(field.id.toString(), {
                required: {
                  value: field.required,
                  message: t("error.Campo obrigatório.") || "",
                },
              })}
              {...(errors[field.id] && {
                error: (errors[field.id]?.message as string) || "",
              })}
            />
          ) : (
            <LocationInput
              hint={field.hint}
              name={field.name}
              {...(field.required &&
                !isValid &&
                !watch(field.id.toString()) && {
                  required: {
                    value: true,
                    message: t("error.Campo obrigatório.") || "",
                  },
                })}
              popMessage={{
                title: "Local da ocorrência",
                subtitle: "Se não for o local, ajuste no mapa",
              }}
              getLocation={(value: valueType) => {
                setValue(field.id.toString(), value);
              }}
              {...(watch(field.id.toString()) && {
                defaultValue: getValues(field.id.toString()),
              })}
              removeClick={() => {
                setValue(field.id.toString(), undefined);
              }}
              onLoad={() =>
                register(field.id.toString(), { value: undefined, required: field.required })
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};
