import { ReactNode } from "react";
import { createPortal } from "react-dom";

interface ReactPortalProps {
  children: ReactNode;
  wrapperId?: string;
}

export function ReactPortal({ children, wrapperId = "root" }: ReactPortalProps) {
  let element = document.getElementById(wrapperId);

  function createWrapperAndAppendToBody(wrapperId: string) {
    const wrapperElement = document.createElement("div");

    wrapperElement.setAttribute("id", wrapperId);

    document.body.appendChild(wrapperElement);

    return wrapperElement;
  }

  if (!element) {
    element = createWrapperAndAppendToBody(wrapperId);
  }

  return createPortal(children, element);
}
