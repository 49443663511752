import { ComponentProps, ReactNode } from "react";
import { Text } from "@astrolabe-ui/react";

function Root({ children }: { children: ReactNode }) {
  return (
    <div className="bg-slate-50 relative flex flex-col gap-4 rounded-sm px-4 py-3">{children}</div>
  );
}

function Title({ children }: { children: ReactNode }) {
  return (
    <Text size="md" weight="medium" color="primary-500" className="pr-12">
      {children}
    </Text>
  );
}

function Action({ children, ...rest }: ComponentProps<"button">) {
  return (
    <button className="absolute right-3 top-3 flex items-center gap-1 text-primary-500" {...rest}>
      {children}
    </button>
  );
}

function Info({ children }: { children: ReactNode }) {
  return <div className="gap-0.5 flex flex-col">{children}</div>;
}

function InfoTitle({ children }: { children: ReactNode }) {
  return (
    <Text weight="medium" color="slate-700" className="pr-12">
      {children}
    </Text>
  );
}

function InfoText({ children }: { children: ReactNode }) {
  return <Text>{children}</Text>;
}

function InfoFile({ children }: { children: ReactNode }) {
  return (
    <div className="w-ful mt-1.5 flex items-center justify-center rounded-md border-thin border-dashed border-primary-500	bg-white p-4">
      <Text size="xs" weight="medium" color="slate-700">
        {children}
      </Text>
    </div>
  );
}

function InfoImage({ src }: { src: string }) {
  return <img src={src} alt="" className="mt-1.5 h-32 w-full rounded-sm object-cover" />;
}

export const CardInfo = { Root, Title, Action, Info, InfoTitle, InfoText, InfoFile, InfoImage };
