import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { OutputView, Text } from "@astrolabe-ui/react";
import dayjs from "dayjs";

import { Button, Loading } from "@/components";
import { formatCpf, formatPhone } from "@/utils/formats";
import { useAppStore } from "@/store/useAppStore";
import { http } from "@/lib/axios";
import { cn } from "@/lib/utils";

import { Modal } from "./Modal";
import { IDataAssetsScheduling } from "..";

import { useAssetSchedulingContext } from "../../contexts/AssetsSchedulingContext";
import { useStepsContext } from "../../contexts/StepsContext";
import { Booking } from "@/services/assetsScheduling";
import { BookingContext } from "../../contexts/BookingContext";

type IFields = Array<{
  name: string;
  outputviews: Array<{
    step: string;
    value: string | undefined;
  }>;
}>;

export const Review = () => {
  const { dataAssetsScheduling, handleGoTo, setDataAssetsScheduling } = useStepsContext();
  const { institution } = useAssetSchedulingContext();

  const [booking, setBooking] = useState<Booking | null>(null);
  const [status, setStatus] = useState<"success" | "error">();
  const [error, setError] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const app = useAppStore((state) => state.app);

  const handleSubmitBooking = () => {
    setLoading(true);
    const { ...data } = dataAssetsScheduling as IDataAssetsScheduling;

    http
      .post(`/v3/${app?.id}/agendamento_local/espaco/${data.espaco_id}/reserva`, data)
      .then((res) => {
        toast.success(t("booking.Agendamento realizado com sucesso"));
        setStatus("success");
        setDataAssetsScheduling &&
          setDataAssetsScheduling((prevState) => ({
            ...prevState,
            unidade_id: undefined,
          }));
        console.log("res = ", res);
        setBooking(res.data);
      })
      .catch((error: any) => {
        setStatus("error");
        toast.error(t("error.Problemas ao realizar a reserva"));
        setError(error.response.data.error as string);
        console.log(error.response.data.error);
      })
      .finally(() => {
        setIsOpen(true);
        setLoading(false);
      });
  };

  const fields: IFields = [
    {
      name: "booking.Seleção de local",
      outputviews: [
        {
          step: "booking.Local",
          value: institution?.unidades.find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
            ?.titulo,
        },
      ],
    },
    {
      name: "booking.Seleção de espaço",
      outputviews: [
        {
          step: "booking.Espaço",
          value: institution?.unidades
            .find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
            ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id)?.nome,
        },
      ],
    },
    {
      name: "booking.Data",
      outputviews: [
        {
          step: "booking.Data",
          value: dayjs(dataAssetsScheduling?.extra_fields?.date as string)
            .toDate()
            .toLocaleDateString("pt-BR", {
              year: "numeric",
              month: "long",
              day: "numeric",
              weekday: "long",
            })
            .split(" ")
            .map((word) =>
              word !== "de" ? word.charAt(0).toLocaleUpperCase() + word.slice(1) : word,
            )
            .join(" "),
        },
      ],
    },
    {
      name: "booking.Hora",
      outputviews: [
        {
          step: "booking.Hora",
          value:
            dayjs(dataAssetsScheduling?.hora_inicio).format("HH:mm") +
            " às " +
            dayjs(dataAssetsScheduling?.hora_fim).format("HH:mm"),
        },
      ],
    },
    {
      name: "booking.Dados pessoais",
      outputviews: [
        {
          step: "general.CPF",
          value: dataAssetsScheduling && formatCpf(dataAssetsScheduling?.documento as string),
        },
        {
          step: "auth.Nome completo",
          value: dataAssetsScheduling?.nome_completo,
        },
        {
          step: "auth.Telefone",
          value: dataAssetsScheduling && formatPhone(dataAssetsScheduling?.telefone as string),
        },
        {
          step: "auth.E-mail",
          value: dataAssetsScheduling?.email,
        },
      ],
    },
    {
      name: "booking.Informações adicionais",
      outputviews: [
        {
          step: "booking.Justificativa",
          value: dataAssetsScheduling?.pauta,
        },
      ],
    },
  ];

  return (
    <div className={cn("flex flex-col gap-10", { "h-full": loading })}>
      {loading ? (
        <div className="flex grow flex-col items-center justify-center">
          <Loading variant="secondary" />
          <Text className="animate-pulse">Enviando seu agendamento</Text>
        </div>
      ) : (
        <BookingContext.Provider value={booking}>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} status={status} {...(error && { error })} />

          {fields.map((field, index) => (
            <div className="flex flex-col gap-4" key={index}>
              <div className="flex justify-between">
                <Text color="slate-700">
                  {t("booking.Etapa")} {index + 1}: {t(field.name)}
                </Text>
                <Text color="primary-500" onClick={() => handleGoTo && handleGoTo(index)}>
                  {t("general.Editar")}
                </Text>
              </div>
              {field.outputviews.map((outputview, index) => (
                <OutputView key={index} heading={t(outputview.step)} content={outputview.value} />
              ))}
            </div>
          ))}

          <Button size="lg" full onClick={() => handleSubmitBooking()} className="self-end">
            {t("general.Continuar")}
          </Button>
        </BookingContext.Provider>
      )}
    </div>
  );
};
