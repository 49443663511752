import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockProtocol } from "@/components/old/BlockProtocol";
import { BlockImage } from "@/components/old/BlockImage";
import { BlockMap } from "@/components/old/BlockMap";
import { BlockExtrasFields } from "@/pages/old/Protocol/components/BlockExtraFields";
import { getProtocolById } from "@/services/old/servicos";
import { getContent } from "@/content/index";

interface ProtocolParams {
  id: string;
  SLUG: string;
}

export function ProtocolDetail() {
  const params: ProtocolParams = useParams();

  const CONTENT = getContent();

  const { isLoading, data } = useQuery(["getProtocolById", params.id], () =>
    getProtocolById(params.id, params.SLUG),
  );

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.GLOBAL.LOADING} </PageTitle>
      </PageTemplate>
    );
  } else if (!data) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  }

  return (
    <div style={{ padding: "16px 12px" }}>
      <BlockProtocol
        type={data.data.categoriaServico?.titulo}
        number={params.id}
        category={data.data.subCategoriaServico?.titulo}
        createdAt={new Date(data.data.created_at)}
        status={data.data.status.titulo || ""}
        address={data.data.endereco || ""}
        plate={data.data.placaVeiculo || ""}
        identifier={data.data.identificador || ""}
        identifierLabel={data.data.categoriaServico?.nomeIdentificador}
      />

      {data.data.imagem && (
        <BlockImage maxHeigh="70vh" title={CONTENT.PROTOCOLS.IMAGE} src={data.data.imagem} />
      )}

      {data.data.latitude && data.data.longitude && data.data.categoriaServico.usarEndereco && (
        <BlockMap lat={data.data.latitude} lng={data.data.longitude} />
      )}

      {data.data.respostasCampos?.length > 0 && (
        <BlockExtrasFields
          maxHeigh="70vh"
          title={"Formulário"}
          values={data.data.respostasCampos}
        />
      )}
    </div>
  );
}
