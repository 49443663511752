import { getEventPost } from "@/services/events";
import { useQuery } from "@tanstack/react-query";

type useEventsPostParams = {
  appId?: number;
  id?: number;
};

export function useEventPost({ appId, id }: useEventsPostParams) {
  return useQuery({
    queryKey: ["event", id],
    queryFn: () => getEventPost({ appId, id }),
    enabled: !!appId,
  });
}
