import dayjs from "dayjs";
import { http } from "@/lib/axios";

type GetAvailableHoursParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  localId: number;
  scheduleId: number;
  schedulingId?: number;
};

type GetAvailableHoursResponse = Array<{
  id: number;
  hora: string;
}>;

export async function getAvailableHours({
  appId,
  entityId,
  resourceId,
  localId,
  scheduleId,
  schedulingId,
}: GetAvailableHoursParams) {
  try {
    const response = await http.get<GetAvailableHoursResponse>(
      `v3/${appId}/mobile/entidade_agendamento/${entityId}/recursos/${resourceId}/locais/${localId}/agendas/${scheduleId}`,
      {
        params: {
          ...(schedulingId ? { cadastro_id: schedulingId } : {}),
        },
      },
    );

    const data = response.data;

    return data.map((item) => ({
      id: item.id,
      hour: dayjs(item.hora).format("HH:mm"),
    }));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
