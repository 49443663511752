import { getContent } from "@/content/index";

import { Nav, List, StepElement, Infos } from "./styles";

export type Step = {
  id: number;
  title: string;
  status: string;
};

interface StepsProps {
  steps: Step[];
  currentStepId: number;
  sizeList?: string;
}

export function Steps({ steps, currentStepId, sizeList }: StepsProps) {
  const CONTENT = getContent();

  return (
    <Nav aria-label="Progress">
      <List size={sizeList || "25%"}>
        {steps.map((step) => (
          <li key={step.id}>
            <StepElement status={step.status}></StepElement>
          </li>
        ))}
      </List>

      <Infos>
        <small>
          {CONTENT.VINCULAR_TITULAR.STEP} {steps.findIndex((step) => step.status === "current") + 1}{" "}
          de {steps.length}
        </small>
        <span>{steps.find((step) => step.id === currentStepId)?.title}</span>
      </Infos>
    </Nav>
  );
}
