import { http } from "@/lib/axios";
import { Wallet } from "./type";

interface GetWalletsResponse {
  data: {
    data: Array<{
      id: number;
      nome: string;
      cpf: string;
      data_nascimento: string;
      foto: string;
      carteiras: Array<{
        id: number;
        nome: string;
        logo: string | null;
        orgao_emissor: string;
        background: string | null;
        back_background?: string | null;
        cor_primaria: string;
        cor_secundaria: string;
      }>;
      titulares_carteiras: Array<{
        id_carteira: number;
        hash_verif: string;
      }>;
    }>;
  };
}

interface getWalletsParams {
  appId: number;
}

export async function getWallets({ appId }: getWalletsParams): Promise<Array<Wallet>> {
  const response = await http.get<GetWalletsResponse>(`v2/${appId}/carteiras`);

  const data = response.data.data.data[0];

  const holder = {
    id: data.id,
    name: data.nome,
    cpf: data.cpf,
    dateOfBirth: data.data_nascimento,
    imageUrl: data.foto,
  };

  const wallets = data.carteiras.map((wallet) => ({
    id: wallet.id,
    name: wallet.nome,
    imageLogo: wallet.logo,
    issuingBody: wallet.orgao_emissor,
    imageBackground: wallet.background,
    imageBackBackground: wallet?.back_background ?? null,
    colorPrimary: wallet.cor_primaria,
    colorSecondary: wallet.cor_secundaria,
    hash: data.titulares_carteiras?.find(
      (titular: Record<string, unknown>) => titular.id_carteira === wallet.id,
    )?.hash_verif,
    holder,
    fields: [],
  }));

  return wallets;
}
