import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { useDebounce } from "@/hooks/useDebounce";
import { getFaqCategory } from "@/services/faq";
import { filterQuestionByTitleAtom } from "../atoms/filterQuestionByTitleAtom";

type useFaqCategoryParams = {
  appId?: number;
  faqId?: number;
  categoryId?: number;
};

export function useFaqCategory({ appId, faqId, categoryId }: useFaqCategoryParams) {
  const query = useAtomValue(filterQuestionByTitleAtom);

  const queryDeffered = useDebounce(query, 700);

  return useQuery({
    queryKey: ["faq", "category", appId, faqId, categoryId, queryDeffered],
    queryFn: () => getFaqCategory({ appId, faqId, categoryId, query: queryDeffered }),
    enabled: !!appId && !!faqId && !!categoryId,
  });
}
