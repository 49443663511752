import dayjs from "dayjs";

import { http } from "@/lib/axios";
import { useAuthStore } from "@/store/useAuthStore";

type getEventsParams = {
  appId?: number;
  id?: number;
};

type eventsTypes = {
  data: {
    evento: {
      id: number;
      titulo: string;
      banner: string;
      titulo_filhos: string;
      inicio_ts: string;
      endereco_extenso: string | null;
      descricao: string;
      encerramento_ts: string | null;
      latitude: string | null;
      inscricao_habilitada: boolean;
      longitude: string | null;
      rastreamento_habilitado: boolean;
      eventos_filhos: [
        {
          id: number;
          titulo: string;
          descricao: string;
          banner: string;
          inicio_ts: string;
          encerramento_ts: string;
        },
      ];
    };
  };
};

type SubscribeResponseType = {
  data: { estou_inscrito: boolean };
};

export async function getEventPost({ appId, id }: getEventsParams) {
  try {
    const response = await http.get<eventsTypes>(`v2/${appId}/eventos/${id}`);
    const data = response.data.data.evento;

    if (!data) {
      console.error("No data received for evento");
      return;
    }

    const { token } = useAuthStore.getState();
    const isLogged = !!token;

    let isRegistered = false;

    if (isLogged) {
      const inscritoResponse = await http.get<SubscribeResponseType>(
        `v2/${appId}/eventos/${id}/estou_inscrito`,
      );

      isRegistered = inscritoResponse.data.data.estou_inscrito;
    }

    const event = {
      id: data.id,
      title: data.titulo,
      tituloFilhos: data.titulo_filhos,
      description: data.descricao,
      image: data.banner,
      place: data.endereco_extenso,
      startDate: data.inicio_ts,
      startDateFormatted: formatDate(data.inicio_ts),
      endDate: data.encerramento_ts,
      endDateFormatted: data.encerramento_ts ? formatDate(data.encerramento_ts) : null,
      latitude: data.latitude,
      longitude: data.longitude,
      registered: isRegistered,
      enabledTracker: data.rastreamento_habilitado,
      subscribeEnabled: data.inscricao_habilitada,
      childrens: data.eventos_filhos.map((child) => ({
        id: child.id,
        title: child.titulo,
        description: child.descricao,
        banner: child.banner,
        date: dayjs(child.inicio_ts).format("DD MMM YY "),
        hour: dayjs(child.inicio_ts).format("HH[h]mm"),
      })),
    };

    return event;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function formatDate(date: string) {
  return dayjs(date).format("DD MMM YY [•] HH[h]mm");
}
