import * as Accordion from "@radix-ui/react-accordion";
import { Text } from "@astrolabe-ui/react";
import { CaretDown } from "@phosphor-icons/react";

type QuestionAccordionProps = {
  id: number;
  title: string;
  content: string;
};

export function QuestionAccordion({ id, title, content }: QuestionAccordionProps) {
  return (
    <Accordion.Item
      key={id}
      value={String(id)}
      className="w-full rounded-md border-thin border-slate-200 px-3 py-4"
    >
      <Accordion.Header>
        <Accordion.Trigger className="flex w-full items-center justify-between gap-2">
          <Text
            size="xs"
            weight="medium"
            color="slate-700"
            className="text-left"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <CaretDown
            className="flex flex-shrink-0 text-slate-700 transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180"
            aria-hidden
          />
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content className="text-xs text-slate-400">
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="prose prose-slate mx-auto mt-2 font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
        />
      </Accordion.Content>
    </Accordion.Item>
  );
}
