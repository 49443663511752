import { IonSpinner } from "@ionic/react";

import { HorizontalAlign, Spacer } from "@/components/old/Base";

interface PageLoadingProps {
  description: string;
}

export function PageLoading(props: PageLoadingProps) {
  return (
    <HorizontalAlign columns={true}>
      <Spacer space={70} />
      <IonSpinner color="primary" />
      <Spacer space={30} />
      <span data-testid="pageloading-description">{props.description}</span>
    </HorizontalAlign>
  );
}
