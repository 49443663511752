import { Controller, useFormContext } from "react-hook-form";

import { ItemRadioButton, RadioButtons } from "@/components/old/Forms/RadioButton";
import { ValidationData } from "../..";
import { getContent } from "@/content/index";

import { Container } from "./styles";

interface SelectMontherNameProps {
  handleNext: () => Promise<void>;
  handleBack: () => void;
  name: "motherName" | "birthDate";
  options: ItemRadioButton[];
}

export function SelectRadioButton({
  handleNext,
  handleBack,
  name,
  options,
}: SelectMontherNameProps) {
  const { control } = useFormContext<ValidationData>();
  const CONTENT = getContent();

  return (
    <Container>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioButtons values={options} selected={value} onChange={onChange} />
        )}
      />

      <footer>
        <button type="button" onClick={handleBack} className="secondary">
          {CONTENT.VINCULAR_TITULAR.RETURN_STEP}
        </button>
        <button type="button" onClick={handleNext}>
          {CONTENT.VINCULAR_TITULAR.ADVANCE}
        </button>
      </footer>
    </Container>
  );
}
