export type City = {
  name: string;
  territory: string;
  territory_code: number;
};

export const territories = [
  { territory: "Terra Potiguaras", id: 2 },
  { territory: "Assú, Sertão Central, Cabugi e Litoral Norte", id: 3 },
  { territory: "Agreste Litoral Sul", id: 4 },
  { territory: "Trairi", id: 5 },
  { territory: "Potengi", id: 6 },
  { territory: "Seridó", id: 7 },
  { territory: "Alto Oeste", id: 8 },
  { territory: "Mossoró", id: 9 },
  { territory: "Mato Grande", id: 10 },
  { territory: "Sertão do Apodi", id: 11 },
];

export const cities = [
  { territory: "Assú, Sertão Central, Cabugi e Litoral Norte", name: "Assú", territory_code: 9 },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Alto do Rodrigues",
    territory_code: 9,
  },
  { territory: "Mossoró", name: "Areia Branca", territory_code: 9 },
  { territory: "Mossoró", name: "Baraúna", territory_code: 9 },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Carnaubais",
    territory_code: 9,
  },
  { territory: "Mossoró", name: "Grossos", territory_code: 9 },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Ipanguaçu",
    territory_code: 9,
  },
  { territory: "Assú, Sertão Central, Cabugi e Litoral Norte", name: "Itajá", territory_code: 9 },
  { territory: "Mossoró", name: "Mossoró", territory_code: 9 },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Pendências",
    territory_code: 9,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Porto do Mangue",
    territory_code: 9,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "São Rafael",
    territory_code: 9,
  },
  { territory: "Mossoró", name: "Serra do Mel", territory_code: 9 },
  { territory: "Mossoró", name: "Tibau", territory_code: 9 },
  { territory: "Alto Oeste", name: "Água Nova", territory_code: 8 },
  { territory: "Alto Oeste", name: "Alexandria", territory_code: 8 },
  { territory: "Alto Oeste", name: "Almino Afonso", territory_code: 8 },
  { territory: "Alto Oeste", name: "Antônio Martins", territory_code: 8 },
  { territory: "Alto Oeste", name: "Coronel João Pessoa", territory_code: 8 },
  { territory: "Alto Oeste", name: "Doutor Severiano", territory_code: 8 },
  { territory: "Alto Oeste", name: "Encanto", territory_code: 8 },
  { territory: "Alto Oeste", name: "Francisco Dantas", territory_code: 8 },
  { territory: "Alto Oeste", name: "Frutuoso Gomes", territory_code: 8 },
  { territory: "Alto Oeste", name: "João Dias", territory_code: 8 },
  { territory: "Alto Oeste", name: "José da Penha", territory_code: 8 },
  { territory: "Alto Oeste", name: "Lucrécia", territory_code: 8 },
  { territory: "Alto Oeste", name: "Luís Gomes", territory_code: 8 },
  { territory: "Alto Oeste", name: "Major Sales", territory_code: 8 },
  { territory: "Alto Oeste", name: "Marcelino Vieira", territory_code: 8 },
  { territory: "Alto Oeste", name: "Martins", territory_code: 8 },
  { territory: "Alto Oeste", name: "Paraná", territory_code: 8 },
  { territory: "Alto Oeste", name: "Pau dos Ferros", territory_code: 8 },
  { territory: "Alto Oeste", name: "Pilões", territory_code: 8 },
  { territory: "Alto Oeste", name: "Portalegre", territory_code: 8 },
  { territory: "Alto Oeste", name: "Rafael Fernandes", territory_code: 8 },
  { territory: "Alto Oeste", name: "Riacho da Cruz", territory_code: 8 },
  { territory: "Alto Oeste", name: "Riacho de Santana", territory_code: 8 },
  {
    territory: "Alto Oeste",
    name: "São Francisco do Oeste",
    territory_code: 8,
  },
  { territory: "Alto Oeste", name: "São Miguel", territory_code: 8 },
  { territory: "Alto Oeste", name: "Serrinha dos Pintos", territory_code: 8 },
  { territory: "Alto Oeste", name: "Tabuleiro Grande", territory_code: 8 },
  { territory: "Alto Oeste", name: "Tenente Ananias", territory_code: 8 },
  { territory: "Alto Oeste", name: "Venha-Ver", territory_code: 8 },
  { territory: "Alto Oeste", name: "Viçosa", territory_code: 8 },
  { territory: "Agreste Litoral Sul", name: "Arês", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Baía Formosa", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Lagoa D'Anta", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Brejinho", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Canguaretama", territory_code: 4 },
  {
    territory: "Agreste Litoral Sul",
    name: "Espírito Santo",
    territory_code: 4,
  },
  { territory: "Agreste Litoral Sul", name: "Goianinha", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Jundiá", territory_code: 4 },
  {
    territory: "Agreste Litoral Sul",
    name: "Lagoa de Pedras",
    territory_code: 4,
  },
  {
    territory: "Agreste Litoral Sul",
    name: "Lagoa Salgada",
    territory_code: 4,
  },
  { territory: "Agreste Litoral Sul", name: "Montanhas", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Monte Alegre", territory_code: 4 },
  {
    territory: "Agreste Litoral Sul",
    name: "Nísia Floresta",
    territory_code: 4,
  },
  { territory: "Agreste Litoral Sul", name: "Nova Cruz", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Passagem", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Pedro Velho", territory_code: 4 },
  {
    territory: "Agreste Litoral Sul",
    name: "Santo Antônio",
    territory_code: 4,
  },
  {
    territory: "Agreste Litoral Sul",
    name: "São José de Mipibu",
    territory_code: 4,
  },
  {
    territory: "Agreste Litoral Sul",
    name: "Senador Georgino Avelino",
    territory_code: 4,
  },
  { territory: "Agreste Litoral Sul", name: "Serrinha", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Tibau do Sul", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Várzea", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Vera Cruz", territory_code: 4 },
  { territory: "Agreste Litoral Sul", name: "Vila Flor", territory_code: 4 },
  { territory: "Mato Grande", name: "Bento Fernandes", territory_code: 10 },
  { territory: "Mato Grande", name: "Caiçara do Norte", territory_code: 10 },
  { territory: "Mato Grande", name: "Ceará-Mirim", territory_code: 10 },
  { territory: "Mato Grande", name: "Jandaira", territory_code: 10 },
  { territory: "Mato Grande", name: "João Câmara", territory_code: 10 },
  { territory: "Mato Grande", name: "Maxaranguape", territory_code: 10 },
  { territory: "Mato Grande", name: "Parazinho", territory_code: 10 },
  { territory: "Mato Grande", name: "Pedra Grande", territory_code: 10 },
  { territory: "Mato Grande", name: "Poço Branco", territory_code: 10 },
  { territory: "Mato Grande", name: "Pureza", territory_code: 10 },
  { territory: "Mato Grande", name: "Rio do Fogo", territory_code: 10 },
  { territory: "Mato Grande", name: "São Bento do Norte", territory_code: 10 },
  {
    territory: "Mato Grande",
    name: "São Miguel do Gostoso",
    territory_code: 10,
  },
  { territory: "Mato Grande", name: "Taipu", territory_code: 10 },
  { territory: "Mato Grande", name: "Touros", territory_code: 10 },
  { territory: "Potengi", name: "Barcelona", territory_code: 6 },
  { territory: "Potengi", name: "Bom Jesus", territory_code: 6 },
  { territory: "Potengi", name: "Ielmo Marinho", territory_code: 6 },
  { territory: "Potengi", name: "Lagoa de Velhos", territory_code: 6 },
  { territory: "Potengi", name: "Riachuelo", territory_code: 6 },
  { territory: "Potengi", name: "Ruy Barbosa", territory_code: 6 },
  { territory: "Potengi", name: "Santa Maria", territory_code: 6 },
  { territory: "Potengi", name: "São Paulo do Potengi", territory_code: 6 },
  { territory: "Potengi", name: "São Pedro", territory_code: 6 },
  { territory: "Potengi", name: "São Tomé", territory_code: 6 },
  { territory: "Potengi", name: "Senador Elói de Souza", territory_code: 6 },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Afonso Bezerra",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Angicos",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Caiçara do Rio do Vento",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Fernando Pedroza",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Galinhos",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Guamaré",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Jardim de Angicos",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Lajes",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Macau",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Pedra Preta",
    territory_code: 3,
  },
  {
    territory: "Assú, Sertão Central, Cabugi e Litoral Norte",
    name: "Pedro Avelino",
    territory_code: 3,
  },
  { territory: "Seridó", name: "Acari", territory_code: 7 },
  { territory: "Seridó", name: "Bodó", territory_code: 7 },
  { territory: "Seridó", name: "Caicó", territory_code: 7 },
  { territory: "Seridó", name: "Carnaúba dos Dantas", territory_code: 7 },
  { territory: "Seridó", name: "Cerro Corá", territory_code: 7 },
  { territory: "Seridó", name: "Cruzeta", territory_code: 7 },
  { territory: "Seridó", name: "Currais Novos", territory_code: 7 },
  { territory: "Seridó", name: "Equador", territory_code: 7 },
  { territory: "Seridó", name: "Florânia", territory_code: 7 },
  { territory: "Seridó", name: "Ipueira", territory_code: 7 },
  { territory: "Seridó", name: "Jardim de Piranhas", territory_code: 7 },
  { territory: "Seridó", name: "Jardim do Seridó", territory_code: 7 },
  { territory: "Seridó", name: "Jucurutu", territory_code: 7 },
  { territory: "Seridó", name: "Lagoa Nova", territory_code: 7 },
  { territory: "Seridó", name: "Ouro Branco", territory_code: 7 },
  { territory: "Seridó", name: "Parelhas", territory_code: 7 },
  { territory: "Seridó", name: "Santana do Matos", territory_code: 7 },
  { territory: "Seridó", name: "Santana do Seridó", territory_code: 7 },
  { territory: "Seridó", name: "São Fernando", territory_code: 7 },
  { territory: "Seridó", name: "São João do Sabugi", territory_code: 7 },
  { territory: "Seridó", name: "São José do Seridó", territory_code: 7 },
  { territory: "Seridó", name: "São Vicente", territory_code: 7 },
  { territory: "Seridó", name: "Serra Negra do Norte", territory_code: 7 },
  { territory: "Seridó", name: "Tenente Laurentino Cruz", territory_code: 7 },
  { territory: "Seridó", name: "Timbaúba dos Batistas", territory_code: 7 },
  { territory: "Sertão do Apodi", name: "Apodi", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Campo Grande", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Caraúbas", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Felipe Guerra", territory_code: 11 },
  {
    territory: "Sertão do Apodi",
    name: "Governador Dix-Sept Rosado",
    territory_code: 11,
  },
  { territory: "Sertão do Apodi", name: "Itaú", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Janduis", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Messias Targino", territory_code: 11 },
  {
    territory: "Sertão do Apodi",
    name: "Olho-D'Água do Borges",
    territory_code: 11,
  },
  { territory: "Sertão do Apodi", name: "Paraú", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Patu", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Rafael Godeiro", territory_code: 11 },
  {
    territory: "Sertão do Apodi",
    name: "Rodolfo Fernandes",
    territory_code: 11,
  },
  { territory: "Sertão do Apodi", name: "Severiano Melo", territory_code: 11 },
  {
    territory: "Sertão do Apodi",
    name: "Triunfo Potiguar",
    territory_code: 11,
  },
  { territory: "Sertão do Apodi", name: "Umarizal", territory_code: 11 },
  { territory: "Sertão do Apodi", name: "Upanema", territory_code: 11 },
  { territory: "Trairi", name: "Boa Saúde", territory_code: 5 },
  { territory: "Trairi", name: "Campo Redondo", territory_code: 5 },
  { territory: "Trairi", name: "Coronel Ezequiel", territory_code: 5 },
  { territory: "Trairi", name: "Jaçanã", territory_code: 5 },
  { territory: "Trairi", name: "Japi", territory_code: 5 },
  { territory: "Trairi", name: "Lajes Pintadas", territory_code: 5 },
  { territory: "Trairi", name: "Monte das Gameleiras", territory_code: 5 },
  { territory: "Trairi", name: "Passa e Fica", territory_code: 5 },
  { territory: "Trairi", name: "Santa Cruz", territory_code: 5 },
  { territory: "Trairi", name: "São Bento do Trairi", territory_code: 5 },
  { territory: "Trairi", name: "São José do Campestre", territory_code: 5 },
  { territory: "Trairi", name: "Serra Caiada", territory_code: 5 },
  { territory: "Trairi", name: "Serra de São Bento", territory_code: 5 },
  { territory: "Trairi", name: "Sítio Novo", territory_code: 5 },
  { territory: "Trairi", name: "Tangara", territory_code: 5 },
  { territory: "Terra Potiguaras", name: "Extremoz", territory_code: 2 },
  { territory: "Terra Potiguaras", name: "Macaíba", territory_code: 2 },
  { territory: "Terra Potiguaras", name: "Natal", territory_code: 2 },
  { territory: "Terra Potiguaras", name: "Parnamirim", territory_code: 2 },
  {
    territory: "Terra Potiguaras",
    name: "São Gonçalo do Amarante",
    territory_code: 2,
  },
  {
    territory: "Terra Potiguaras",
    name: "Rio Grande do Norte COD.1",
    territory_code: 2,
  },
];
