import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    margin-top: auto;
    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    background-color: ${COLORS.PRIMARY};

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    height: 56px;
    width: 100%;
  }

  button.secondary {
    background: #e2e8f0;
    color: #64748b;
  }
`;
