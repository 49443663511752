import { ChangeEvent, useState, useRef, useEffect, Fragment, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IonLoading, IonAlert, IonIcon } from "@ionic/react";
import { paperPlane, downloadOutline } from "ionicons/icons";
import { BsFileEarmarkPlusFill } from "react-icons/bs";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import ptBR from "date-fns/locale/pt-BR";

import {
  confirmProtocolMessages,
  getProtocolMessages,
  sendProtocolFile,
  sendProtocolMessage,
} from "@/services/old/chat";
import { useSocket } from "@/hooks/useSocket";
import { downloadFile } from "@/utils/downloadFile";
import { getContent } from "@/content/index";

import {
  ChatWindow,
  MessageList,
  DateInfo,
  Message,
  DownloadMessage,
  Footer,
  Input,
  InputBox,
  SendFileButton,
  Divider,
  SendButton,
} from "./styles";
import { useUserStore } from "@/store/useUserStore";

interface ChatNewProps {
  hostname?: string;
  port?: number;
  api_name: string;
  allow_file_upload?: boolean;
  block_send_message?: boolean;
  id: number;
  SLUG: string;
  type?: "protocol" | "any";
}

type MessageProps = {
  id: number;
  message: string;
  attendant: boolean;
  isFile: boolean;
  sendAt: string;
  createdAt: string;
};

type MessageResponse = {
  id: number;
  mensagem: string;
  interno: boolean;
  is_file: boolean;
  created_at: string;
};

export function ChatNew(props: ChatNewProps) {
  const CONTENT = getContent();

  const history = useHistory();

  const username = useUserStore((state) => state.user?.name);

  const [messages, setMessages] = useState<Record<string, MessageProps[]>>();
  const [writtenMessage, setWrittenMessage] = useState("");

  const [isLoading, setIsloading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isFirstLoading, setIsFirstloading] = useState(true);
  const [showError, setShowError] = useState("");

  const messagesRef = useRef<HTMLDivElement>(null);
  const InputFileRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line no-undef
  const InputMessageRef = useRef<HTMLIonTextareaElement>(null);

  let channel = "";

  if (props.type === "protocol") {
    channel = `${props.api_name}.protocolo.${props.id}`;
  }

  useSocket({
    channel,
    watchMessage: addMessageToChat,
  });

  useEffect(() => {
    loadMessages();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function convertTextWithAnchors(text: string): string {
    return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
      messagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  const loadMessages = () => {
    if (showError === "" && !isLoading) {
      if (isFirstLoading) {
        setIsloading(true);
      }

      setIsFirstloading(false);

      if (props.type === "protocol") {
        confirmProtocolMessages(props.id, props.SLUG);

        getProtocolMessages(props.id, props.SLUG)
          .then((response) => {
            if (response.data) {
              const groupedMessages = response.data.reduce(
                (
                  groupedMessages: Record<string, Array<MessageProps>>,
                  message: MessageResponse,
                  _: any,
                ) => {
                  const test = new Date(message.created_at);

                  test.setHours(0);
                  test.setMinutes(0);
                  test.setSeconds(0);

                  const group =
                    groupedMessages[test.toISOString()] ||
                    (groupedMessages[test.toISOString()] = []);

                  group.push({
                    id: message.id,
                    message: message.is_file
                      ? message.mensagem
                      : convertTextWithAnchors(message.mensagem),
                    attendant: message.interno,
                    isFile: message.is_file,
                    sendAt: formatDate(message.created_at),
                    createdAt: message.created_at,
                  });

                  return groupedMessages;
                },
                {} as Record<string, Array<MessageProps>>,
              );

              setMessages(groupedMessages);
            }
          })
          .catch((error) => {
            console.error(error);
            setShowError("error");
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    }
  };

  function addMessageToChat(message: MessageResponse) {
    setMessages((messages) => {
      const updatedMessages = { ...messages };

      const date = new Date(message.created_at);

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      let group = updatedMessages?.[date.toISOString()];

      if (updatedMessages && !group) {
        group = [];
      }

      group?.push({
        id: message.id,
        message: message.is_file ? message.mensagem : convertTextWithAnchors(message.mensagem),
        attendant: message.interno,
        isFile: message.is_file,
        sendAt: formatDate(message.created_at),
        createdAt: message.created_at,
      });

      return updatedMessages;
    });
  }

  const sendMensage = () => {
    if (props.block_send_message || writtenMessage.length < 2) return;

    if (showError === "") {
      if (InputMessageRef.current) {
        InputMessageRef.current.setFocus();
      }

      if (props.type === "protocol") {
        setSendingMessage(true);

        sendProtocolMessage(props.id, writtenMessage, props.SLUG)
          .catch((error) => {
            console.error(error);
            setShowError("error");
          })
          .finally(() => {
            setSendingMessage(false);
            setWrittenMessage("");
          });
      }
    }
  };

  const onChangeHandle = (e: ChangeEvent) => {
    e.preventDefault();

    setIsloading(true);

    const files = InputFileRef.current?.files;
    const allowedExtensions = /(pdf|jpg|jpeg|png|gif)$/i;

    if (files?.length && !allowedExtensions.test(files[0].type)) {
      setShowError("extension");
      return setIsloading(false);
    }

    if (files?.length) {
      sendProtocolFile(props.id, files[0], props.SLUG)
        .catch((error) => {
          console.log(error);
          setIsloading(false);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  };

  const ChooseFile = () => {
    if (InputFileRef.current) {
      InputFileRef.current.click();
    }
  };

  function formatDate(date: string) {
    return format(new Date(date), "HH:mm");
  }

  function renderDate(date: string) {
    const newDate = new Date(date);

    const dateFormatted = format(newDate, "dd 'de' LLLL", { locale: ptBR });

    return <DateInfo dateTime={date}>{isToday(newDate) ? "Hoje" : dateFormatted}</DateInfo>;
  }

  if (isLoading) {
    <IonLoading isOpen={isLoading} message={CONTENT.ALERT_DIALOG.TITTLE} />;
  }

  const firstName = useMemo(() => username?.split(" ")[0], [username]);

  return (
    <ChatWindow>
      <MessageList ref={messagesRef}>
        {messages &&
          Object.keys(messages).map((item) => (
            <Fragment key={item}>
              {renderDate(item)}

              {messages[item].map((message) => (
                <div key={message.id}>
                  {message.isFile ? (
                    <DownloadMessage attendant={message.attendant}>
                      <div>
                        <label htmlFor={`btn-download-${message.id}`}>
                          <span>{CONTENT.CHAT.FILE}</span>
                          <button
                            id={`btn-download-${message.id}`}
                            type="button"
                            onClick={() => downloadFile(message.message)}
                          >
                            <IonIcon icon={downloadOutline} />
                          </button>
                        </label>
                      </div>

                      <footer className="flex items-baseline gap-1">
                        <small className="text-xs text-slate-700">
                          {message.attendant ? "Atendente" : firstName ?? "Usuário"}
                        </small>
                        <time className="text-slate-400" dateTime={message.createdAt}>
                          {message.sendAt}
                        </time>
                      </footer>
                    </DownloadMessage>
                  ) : (
                    <Message attendant={message.attendant}>
                      <span
                        className="[overflow-wrap:anywhere] [&_a]:underline"
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      />

                      <footer className="flex items-baseline gap-1">
                        <small className="text-xs text-slate-700">
                          {message.attendant ? "Atendente" : firstName ?? "Usuário"}
                        </small>
                        <time className="text-slate-400" dateTime={message.createdAt}>
                          {message.sendAt}
                        </time>
                      </footer>
                    </Message>
                  )}
                </div>
              ))}
            </Fragment>
          ))}
      </MessageList>

      {!props.block_send_message && (
        <Footer>
          <InputBox>
            {props.allow_file_upload && (
              <>
                <SendFileButton onClick={ChooseFile}>
                  <BsFileEarmarkPlusFill />
                </SendFileButton>

                <input type="file" hidden={true} ref={InputFileRef} onChange={onChangeHandle} />

                <Divider />
              </>
            )}

            <Input
              ref={InputMessageRef}
              value={writtenMessage}
              placeholder={CONTENT.CHAT.INPUT_TEXT_HINT}
              rows={1}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  sendMensage();
                }
              }}
              onIonInput={(e) => setWrittenMessage(e.detail.value ? e.detail.value : "")}
            />
          </InputBox>

          <SendButton
            data-testid="bt_send"
            type="submit"
            disabled={sendingMessage}
            onClick={sendMensage}
          >
            <IonIcon icon={paperPlane} />
          </SendButton>
        </Footer>
      )}

      <IonAlert
        isOpen={showError === "error" && !isLoading}
        message={CONTENT.ALERT_DIALOG.CHAT_ERROR}
        buttons={["OK"]}
        onDidDismiss={() => {
          setShowError("");
          history.goBack();
        }}
      />

      <IonAlert
        isOpen={showError === "extension" && !isLoading}
        message="Formato de arquivo inválido. Formatos válidos: PDF, PNG, JPG e JPEG."
        buttons={["OK"]}
        onDidDismiss={() => setShowError("")}
      />
    </ChatWindow>
  );
}
