import { http } from "@/lib/axios";
import { Scheduling } from "./types";

type getMySchedulingsResponse = Array<{
  id: number;
  status: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;
  local: {
    id: number;
    titulo: string;
    endereco: {
      endereco_formatado: string;
      lat: string;
      long: string;
    };
  };
  hora: {
    id: number;
    hora: string;
  };
  data: {
    id: number;
    data_agendamento: string;
  };
}>;

type getMySchedulingsParams = {
  appId: number;
};

export async function getMySchedulings({ appId }: getMySchedulingsParams) {
  try {
    const response = await http.get<getMySchedulingsResponse>(`v3/${appId}/mobile/meus_cadastros`);

    const queuedAppointments: Array<Scheduling> = [];
    const schedulings: Array<Scheduling> = [];

    response.data.forEach((item) => {
      if (item.local) {
        schedulings.push({
          id: item.id,
          name: item.nome,
          cpf: item.cpf,
          email: item.email,
          phone: item.telefone,
          status: item.status,
          hour: {
            id: item.hora.id,
            date: item.hora.hora,
          },
          date: {
            id: item.data.id,
            date: item.data.data_agendamento,
          },
          local: {
            id: item.local.id,
            name: item.local.titulo,
            address: {
              address: item.local.endereco.endereco_formatado,
              lat: item.local.endereco.lat,
              lng: item.local.endereco.long,
            },
          },
        });
      } else {
        queuedAppointments.push({
          id: item.id,
          name: item.nome,
          cpf: item.cpf,
          email: item.email,
          phone: item.telefone,
          status: item.status,
        });
      }
    });

    return {
      schedulings,
      queuedAppointments,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
