import { ReactNode } from "react";
import { Capacitor } from "@capacitor/core";
import { cn } from "@/lib/utils";

interface ContentProps {
  children: ReactNode;
  className?: string;
}

export function Content({ children, className }: ContentProps) {
  const platform = Capacitor.getPlatform();

  return (
    <div
      data-platform={platform}
      className={cn("flex grow pb-[--safe-area-inset-bottom]", className)}
    >
      {children}
    </div>
  );
}
