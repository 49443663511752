import { Holder } from "@/services/holder/types";

interface useUserViewedParams {
  handleUserViewedApproved: () => Promise<void>;
  enable: boolean;
  holder?: Holder;
}

export async function useUserViewed({
  enable,
  holder,
  handleUserViewedApproved,
}: useUserViewedParams) {
  if (
    enable &&
    holder &&
    holder.reviews.length &&
    holder.reviews.some((review) => review.status === "Aprovado" && !review.user_viewed)
  ) {
    await handleUserViewedApproved();
  }
}
