import { useMemo } from "react";
import { useConfigActions } from "@/store/useConfigStore";

interface useConfigProps {
  label: string;
  slug?: string;
}

export function useConfig({ label }: useConfigProps) {
  const { getConfig } = useConfigActions();

  const config = useMemo(() => getConfig(label), [label, getConfig]);

  if (!config) {
    return null;
  }

  if (config.type === "string") {
    return String(config.value);
  } else if (config.type === "number") {
    return Number(config.value);
  } else if (config.type === "boolean") {
    return config.value === "true";
  }
}
