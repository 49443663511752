import { useState } from "react";
import { cn } from "@/lib/utils";

type ImageGallery = {
  images: Array<string>;
};

export function ImageGallery({ images }: ImageGallery) {
  const [selectedImage, setSelectedImage] = useState<string>(images[0]);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  return (
    <div className="flex flex-col">
      <img
        src={selectedImage}
        alt="Selected"
        className="mb-5 h-auto w-full rounded-xs object-contain"
      />

      <div className="flex gap-4 overflow-x-auto">
        {images.map((image) => (
          <img
            key={image}
            src={image}
            alt=""
            className={cn(
              "h-16 w-16 flex-shrink-0 cursor-pointer rounded-md border-medium border-slate-300 object-cover",
              {
                "border-primary-500": selectedImage === image,
              },
            )}
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>
    </div>
  );
}
