import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { loadUserData } from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

import { Container, Title, Text, Infos, Button, Icon } from "./styles";

export function LinkSuccess() {
  const params = useParams<{ SLUG: string }>();
  const history = useHistory();
  const CONTENT = getContent();

  async function goBack() {
    await loadUserData(params.SLUG);
    history.push(`/${getAppSlug()}`);
  }

  return (
    <PageTemplate
      name={CONTENT.VINCULAR_TITULAR.TITLE}
      backgroundColor="#ffffff"
      backPress={goBack}
    >
      <Container>
        <Infos>
          <Icon>
            <FiCheckCircle size={32} color="#16a34a" />
          </Icon>

          <Title>{CONTENT.VINCULAR_TITULAR.VALIDATE_SUCCESS}</Title>

          <Text>{CONTENT.VINCULAR_TITULAR.ORIENTATION}</Text>
          <Text>{CONTENT.VINCULAR_TITULAR.CONTACT_MESSAGE}</Text>
        </Infos>

        <Button onClick={goBack}>{CONTENT.VINCULAR_TITULAR.RETURN}</Button>
      </Container>
    </PageTemplate>
  );
}
