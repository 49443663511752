import { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { IonButton, IonSelect, IonSelectOption, IonFab, IonIcon, IonFabButton } from "@ionic/react";
import { storefront } from "ionicons/icons";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockProduct } from "@/pages/old/Feirinha/components/BlockProduct";
import { getCategories, getModule, getProducts, getSellerLoged } from "@/services/old/feirinha";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { useUserToken } from "@/store/useAuthStore";

const FilterCategory = styled(IonSelect)`
  margin-top: 16px;
  margin-bottom: 8px;
  background-color: #fff;
`;

interface ModuloParams {
  id: string;
  SLUG: string;
}

export function OpenModule() {
  const params: ModuloParams = useParams();
  const history = useHistory();
  const token = useUserToken();

  const CONTENT = getContent();

  const [defautCategory] = useState({
    id: -1,
    titulo: CONTENT.FEIRINHA.ALL_CATEGORIES,
  });
  const [selectedCategory, setSelectedCategory] = useState(defautCategory);
  const [currentSearchText, setCurrentSearchText] = useState("");
  const [isLoadingSeller, setIsLoaddingSeller] = useState(false);

  const categories = useQuery(["categories"], () => getCategories(params.id, params.SLUG));

  const { data: module } = useQuery(["modulo", params.id], () => getModule(params.id, params.SLUG));

  const { data, fetchNextPage, fetchPreviousPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery(
      ["getproducts", selectedCategory.titulo, currentSearchText, params.id],
      ({ pageParam = 1 }) =>
        getProducts(
          {
            modulo_id: params.id,
            page: pageParam,
            category: selectedCategory.titulo !== defautCategory.titulo ? selectedCategory.id : -1,
            search: currentSearchText,
          },
          params.SLUG,
        ),
      {
        cacheTime: 0,
        getNextPageParam: (res) => (res.data.next_page_url ? res.data.current_page + 1 : undefined),
      },
    );

  function loadMore() {
    fetchNextPage();
  }

  function selectCategory(category: any) {
    if (category === defautCategory.titulo) {
      setSelectedCategory(defautCategory);
    } else {
      categories?.data?.data.map((item: any) => {
        if (item.titulo === category) {
          setSelectedCategory(item);
        }
        return true;
      });
    }

    fetchPreviousPage({ pageParam: 1 });
  }

  function clearCategory() {
    selectCategory(defautCategory.titulo);
    fetchPreviousPage({ pageParam: 1 });
  }

  function makeSearch(search: string) {
    setCurrentSearchText(search);
    fetchPreviousPage({ pageParam: 1 });
  }

  function clearSearch() {
    setCurrentSearchText("");
    fetchPreviousPage({ pageParam: 1 });
  }

  function addProdutoButtomClick() {
    setIsLoaddingSeller(true);

    getSellerLoged(params.SLUG)
      .then((res) => {
        setIsLoaddingSeller(false);
        if (res && res.data && res.data.length) {
          history.push(
            `/${getAppSlug()}/abrir-vendedor/${res.data[0].id}?modulo_id=${module?.data.modulo.id}`,
          );
        } else {
          history.push("/" + getAppSlug() + "/seller-register");
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoaddingSeller(false);
      });
  }

  if (categories?.isLoading || isLoading || isLoadingSeller) {
    return (
      <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      name={
        currentSearchText.length > 0
          ? currentSearchText
          : !module
          ? CONTENT.FEIRINHA.HEADER_TITLE
          : module?.data?.modulo?.titulo
      }
      backPress={
        currentSearchText.length > 0 ? clearSearch : selectedCategory.id < 0 ? null : clearCategory
      }
      onSearchTextChange={(search) => {
        makeSearch(search);
      }}
      showAddButton={!!token}
      onAddButtonClick={addProdutoButtomClick}
      fabIcon={storefront}
    >
      <FilterCategory
        mode="md"
        className="has-highlight-border"
        label="Categorias"
        labelPlacement="stacked"
        fill="outline"
        value={selectedCategory}
        placeholder={
          selectedCategory.id < 0 ? CONTENT.FEIRINHA.ALL_CATEGORIES : selectedCategory.titulo
        }
        onIonChange={(e) => {
          selectCategory(e.detail.value);
        }}
      >
        <IonSelectOption value={defautCategory.titulo}>{defautCategory.titulo}</IonSelectOption>

        {categories?.data?.data.map((item: any, index: number) => {
          return (
            <IonSelectOption key={index} value={item.titulo}>
              {item.titulo}
            </IonSelectOption>
          );
        })}
      </FilterCategory>

      {!data || data?.pages[0].data.data.length === 0 ? (
        <div>
          <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>

          {module?.data.pode_criar_perfil && (
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton onClick={addProdutoButtomClick}>
                <IonIcon icon={storefront} />
              </IonFabButton>
            </IonFab>
          )}
        </div>
      ) : (
        <>
          {data?.pages.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                {item.data.data.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <BlockProduct
                        title={item.titulo}
                        productCategory={item.categoria?.titulo}
                        productPrice={item.valor}
                        productAmount={item.unidade}
                        productStatus={item.situacao}
                        img_src={item.imagem}
                        sellerId={item.comerciante?.id}
                        sellerName={item.comerciante?.nome}
                        sellerNeighborhood={item.comerciante?.bairro?.titulo}
                        openURL={"/" + getAppSlug() + "/abrir-produto/" + item.id}
                      />
                    </div>
                  );
                })}
              </Fragment>
            );
          })}
        </>
      )}

      {data?.pages[data?.pages.length - 1].data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
