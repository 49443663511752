import { IDisplayAddress, IFormAddress } from "@/components";
import { cities } from "@/data/cities";
import { ufs } from "@/data/ufs";

interface IAddress {
  city_district?: string;
  country: string;
  municipality: string;
  postcode?: string;
  region: string;
  state: string;
  town?: string;
  city?: string;
  suburb?: string;
  hamlet?: string;
  road?: string;
  lat: number;
  lng: number;
  "ISO3166-2-lvl4": string;
}

export async function formatMapDisplayName(
  address: IAddress,
  number = "s/n",
): Promise<{
  displayName: IDisplayAddress;
  data: IFormAddress;
}> {
  const street = address.road;
  if (!street) throw new Error("location not found");
  const neighborhood = address.suburb || address.hamlet;

  const uf = ufs.UF.find((u) => u.nome === address.state)?.sigla;

  const cityLeaflet = address.town || address.city || address.city_district;

  const city = `${cities?.estados
    .find((c) => c.sigla === uf)
    ?.cidades.find((c) => c === cityLeaflet)}`;

  const title = street
    ? `${street}, ${number || "s/n"} `
    : address.municipality
        ?.replaceAll("Região Geográfica Imediata de ", "")
        .split(/-|,/)[0]
        .toString()
        .split("")
        .filter((c, index, self) => (self.length === index + 1 ? c !== " " : true))
        .join("") || " ";

  const subtitle = `${neighborhood || ""} ${(city || cityLeaflet) ?? ""}`;

  return {
    displayName: {
      title,
      subtitle,
    },
    data: {
      street: street ?? "",
      city: city ?? "",
      neighborhood: neighborhood ?? "",
      number: number ?? "",
      uf: uf && city ? uf : "",
      lat: address.lat,
      lng: address.lng,
      postcode: address.postcode,
    },
  };
}
