import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IonButton, IonGrid, IonRow, IonCol } from "@ionic/react";

import { EmptyContentPageTemplate } from "@/templates/old/EmptyContentTemplate";
import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { BlockItemImageWithoutTitle } from "@/pages/old/Feirinha/components/BlockItemImageWithoutTitle";
import { getAppSlug } from "@/services/old/starter";
import { getModules } from "@/services/old/feirinha";
import { getContent } from "@/content/index";
import { BlockMySeller } from "./components/BlockMySeller";

interface paramsPage {
  SLUG: string;
}

export function Feirinha() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getMarketModules"],
    ({ pageParam = 1 }) => getModules(pageParam, params.SLUG),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (res) => (res.data.next_page_url ? res.data.current_page + 1 : undefined),
    },
  );

  function loadMore() {
    fetchNextPage();
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data || data?.pages[0].data.data.length === 0) {
    return <EmptyContentPageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE} />;
  }

  console.log(data);
  return (
    <PageTemplate name={CONTENT.FEIRINHA.HEADER_TITLE}>
      <BlockMySeller></BlockMySeller>

      {data?.pages.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <IonGrid style={{ width: "100%" }}>
              <IonRow>
                {item.data.data.map((item: any, index: number) => {
                  return (
                    <IonCol key={index} size={"12"}>
                      <BlockItemImageWithoutTitle
                        key={index}
                        routerLink={"/" + getAppSlug() + "/abrir-modulo/" + item.id}
                        imageUrl={item.imagem}
                      />
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          </Fragment>
        );
      })}

      {data?.pages[data?.pages.length - 1].data.next_page_url && (
        <IonButton
          color={isFetchingNextPage ? "dark" : "primary"}
          className="ion-no-padding"
          disabled={isFetchingNextPage}
          expand="block"
          onClick={loadMore}
        >
          {isFetchingNextPage ? CONTENT.GLOBAL.LOADING : CONTENT.GLOBAL.LOAD_MORE}
        </IonButton>
      )}
    </PageTemplate>
  );
}
