import { http } from "@/lib/axios";
import { Protocols } from "./types";

type ResponseProtocol = {
  id: string;
  titulo: string;
  status: { id: number; titulo: string };
  subCategoriaServico: { titulo: string };
  tagCategoriaServico: { id: number; titulo: string; cor: string };
  created_at: string;
};

type ResponseProtocols = {
  current_page: number;
  next_page_url?: string;
  has_next_page?: boolean;
  per_page: number;
  total: number;
  data: Array<ResponseProtocol>;
};

export interface GetProtocolsParamsFilter {
  search?: string;
  status: Array<number>;
  areaServices: Array<number>;
}

interface GetProtocolsParams {
  appId?: number;
  page?: number;
  filters?: GetProtocolsParamsFilter;
  orderby?: string;
}

export async function getProtocols({
  appId,
  page,
  filters,
  orderby = "id:desc",
}: GetProtocolsParams): Promise<Protocols> {
  try {
    const response = await http.get<ResponseProtocols>(`v3/${appId}/protocolos/buscaAberta`, {
      params: {
        paginate: 1,
        page,
        orderby,
        ...(filters && filters.search ? { search: filters.search } : {}),
        ...(filters && filters.areaServices && { areaServices: filters.areaServices }),
        ...(filters && filters.status && { status: filters.status }),
      },
    });

    return {
      current_page: response.data.current_page,
      per_page: response.data.per_page,
      next_page: response.data.has_next_page || !!response.data.next_page_url,
      total: response.data.total,
      protocols: response.data?.data.map((protocol) => ({
        id: protocol.id,
        title: protocol.titulo,
        status: { id: protocol.status.id, title: protocol.status.titulo },
        tagCategoryService: {
          id: protocol.tagCategoriaServico?.id,
          title: protocol.tagCategoriaServico?.titulo,
          color: protocol.tagCategoriaServico?.cor,
        },
        subcategoryService: { title: protocol.subCategoriaServico.titulo },
        created_at: protocol.created_at,
      })),
    };
  } catch (error: any) {
    console.error("Error to get my protocols = ", error.message);
    throw error;
  }
}
