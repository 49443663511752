import { IonPage } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Toast } from "@capacitor/toast";
import { NavLink } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";

import { BigSpinner, Button, Container, Form, Loading, Spinner } from "./styles";

import { MainContent } from "@/templates/old/ppa/MainContent";
import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { SelectField } from "../components/fields/select";
import { Multiple } from "../components/fields/multiple";
import { DeficiencyField } from "../components/fields/deficiency";
import { http } from "@/lib/axios";
import { getAppId } from "@/services/old/provider";
import { getAccessToken } from "@/services/old/auth";

import logoPPA from "@/assets/images/logo-ppa.png";

const income = [
  "Superior a R$ 22 mil",
  "Entre R$ 7,1 mil e R$ 22 mil",
  "Entre R$ 2,9 mil e R$ 7,1 mil",
  "Entre R$ 2,9 mil e R$ 1,3 mil",
  "Inferior a R$ 1,3 mil",
];

const race = ["Amarelo", "Branco", "Preto", "Pardo", "Outro"];

const gender = ["Feminino", "Masculino", "Transexual", "Outros"];

const identifications = ["Cigano", "Indígena", "Quilombola", "LGBTQI+", "Outro"];

const scholarity = [
  "Analfabeto",
  "Até 5º Ano Incompleto",
  "5º Ano Completo",
  "6º ao 9º Ano do Fundamental",
  "Ensino Fundamental Completo",
  "Ensino Médio Incompleto",
  "Ensino Médio Completo",
  "Ensino Superior Incompleto",
  "Ensino Superior Completo",
  "Mestrado",
  "Doutorado",
];

const cities = [
  "Acari",
  "Açu",
  "Afonso Bezerra",
  "Água Nova",
  "Alexandria",
  "Almino Afonso",
  "Alto do Rodrigues",
  "Angicos",
  "Antônio Martins",
  "Apodi",
  "Areia Branca",
  "Arês",
  "Augusto Severo",
  "Baía Formosa",
  "Baraúna",
  "Barcelona",
  "Bento Fernandes",
  "Bodó",
  "Bom Jesus",
  "Brejinho",
  "Caiçara do Norte",
  "Caiçara do Rio do Vento",
  "Caicó",
  "Campo Redondo",
  "Canguaretama",
  "Caraúbas",
  "Carnaúba dos Dantas",
  "Carnaubais",
  "Ceará-Mirim",
  "Cerro Corá",
  "Coronel Ezequiel",
  "Coronel João Pessoa",
  "Cruzeta",
  "Currais Novos",
  "Doutor Severiano",
  "Encanto",
  "Equador",
  "Espírito Santo",
  "Extremoz",
  "Felipe Guerra",
  "Fernando Pedroza",
  "Florânia",
  "Francisco Dantas",
  "Frutuoso Gomes",
  "Galinhos",
  "Goianinha",
  "Governador Dix-Sept Rosado",
  "Grossos",
  "Guamaré",
  "Ielmo Marinho",
  "Ipanguaçu",
  "Ipueira",
  "Itajá",
  "Itaú",
  "Jaçanã",
  "Jandaíra",
  "Janduís",
  "Januário Cicco",
  "Japi",
  "Jardim de Angicos",
  "Jardim de Piranhas",
  "Jardim do Seridó",
  "João Câmara",
  "João Dias",
  "José da Penha",
  "Jucurutu",
  "Jundiá",
  "Lagoa d'Anta",
  "Lagoa de Pedras",
  "Lagoa de Velhos",
  "Lagoa Nova",
  "Lagoa Salgada",
  "Lajes",
  "Lajes Pintadas",
  "Lucrécia",
  "Luís Gomes",
  "Macaíba",
  "Macau",
  "Major Sales",
  "Marcelino Vieira",
  "Martins",
  "Maxaranguape",
  "Messias Targino",
  "Montanhas",
  "Monte Alegre",
  "Monte das Gameleiras",
  "Mossoró",
  "Natal",
  "Nísia Floresta",
  "Nova Cruz",
  "Olho-d'Água do Borges",
  "Ouro Branco",
  "Paraná",
  "Paraú",
  "Parazinho",
  "Parelhas",
  "Parnamirim",
  "Passa e Fica",
  "Passagem",
  "Patu",
  "Pau dos Ferros",
  "Pedra Grande",
  "Pedra Preta",
  "Pedro Avelino",
  "Pedro Velho",
  "Pendências",
  "Pilões",
  "Poço Branco",
  "Portalegre",
  "Porto do Mangue",
  "Presidente Juscelino",
  "Pureza",
  "Rafael Fernandes",
  "Rafael Godeiro",
  "Riacho da Cruz",
  "Riacho de Santana",
  "Riachuelo",
  "Rio do Fogo",
  "Rodolfo Fernandes",
  "Ruy Barbosa",
  "Santa Cruz",
  "Santa Maria",
  "Santana do Matos",
  "Santana do Seridó",
  "Santo Antônio",
  "São Bento do Norte",
  "São Bento do Trairí",
  "São Fernando",
  "São Francisco do Oeste",
  "São Gonçalo do Amarante",
  "São João do Sabugi",
  "São José de Mipibu",
  "São José do Campestre",
  "São José do Seridó",
  "São Miguel",
  "São Miguel de Touros",
  "São Paulo do Potengi",
  "São Pedro",
  "São Rafael",
  "São Tomé",
  "São Vicente",
  "Senador Elói de Souza",
  "Senador Georgino Avelino",
  "Serra de São Bento",
  "Serra do Mel",
  "Serra Negra do Norte",
  "Serrinha",
  "Serrinha dos Pintos",
  "Severiano Melo",
  "Sítio Novo",
  "Taboleiro Grande",
  "Taipu",
  "Tangará",
  "Tenente Ananias",
  "Tenente Laurentino Cruz",
  "Tibau",
  "Tibau do Sul",
  "Timbaúba dos Batistas",
  "Touros",
  "Triunfo Potiguar",
  "Umarizal",
  "Upanema",
  "Várzea",
  "Venha-Ver",
  "Vera Cruz",
  "Viçosa",
  "Vila Flor",
];

const optionsAbout = ["Instagram", "Site", "Movimento social", "Rádio", "Televisão"];

export interface FormUserData {
  gender: string;
  city: string;
  scholarity: string;
  income: string;
  folk: string;
  identification: Array<string>;
  about: Array<string>;
  enabledDeficiency: string;
  deficiency: string;
  ortherfolk: string;
  ortheridentification: string;
}

interface SubmitFormParams {
  formId: number;
  pageId: number;
  answers: Omit<FormUserData, "ortherrace" | "ortheridentification" | "ortherDeficiency">;
}

export function FormUser() {
  const { SLUG } = useParams<{ SLUG: string }>();
  const history = useHistory();
  const location = useLocation<{ isFirst?: boolean }>();

  const methods = useForm<FormUserData>({
    defaultValues: {
      gender: "",
      city: "",
      scholarity: "",
      income: "",
      folk: "",
      deficiency: "",
      enabledDeficiency: "",
      ortherfolk: "",
      ortheridentification: "",
      identification: [],
      about: [],
    },
  });

  const { handleSubmit, control, reset } = methods;

  const { isLoading } = useQuery({
    queryKey: ["formUser"],
    queryFn: () => getForm(65),
    onSuccess: (data) => {
      if (data.hasData) {
        reset(data.awnser);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutateAsync, isLoading: isSubmiting } = useMutation({
    mutationFn: (data: FormUserData) =>
      submitForm({
        formId: 65,
        pageId: 151,
        answers: data,
      }),
  });

  async function handleOnSubmit(data: FormUserData) {
    if (data.ortherfolk) {
      data.folk = data.ortherfolk;
    }

    if (data.ortheridentification) {
      data.identification = [
        ...data.identification.filter((item) => item !== "Outro"),
        data.ortheridentification,
      ];
    }

    await mutateAsync(data, {
      onSuccess: async () => {
        handleNavigate();
      },
      onError: async (err) => {
        console.error(err);

        await Toast.show({
          text: "Erro ao salvar dados, tente novamente!",
        });
      },
    });
  }

  async function submitForm({ formId, pageId, answers }: SubmitFormParams) {
    const body = new FormData();

    body.append(`opcoes[0]`, "785");
    body.append(`respostas_textos[0]`, answers.gender);

    body.append(`opcoes[1]`, "786");
    body.append(`respostas_textos[1]`, answers.city);

    body.append(`opcoes[2]`, "787");
    body.append(`respostas_textos[2]`, answers.scholarity);

    body.append(`opcoes[3]`, "788");
    body.append(`respostas_textos[3]`, answers.income);

    body.append(`opcoes[5]`, "790");
    body.append(`respostas_textos[5]`, answers.folk);

    if (answers.identification.length) {
      body.append(`opcoes[4]`, "789");
      body.append(`respostas_textos[4]`, answers.identification.join(", "));
    }

    if (answers.about.length) {
      body.append(`opcoes[6]`, "791");
      body.append(`respostas_textos[6]`, answers.about.join(", "));
    }

    if (answers.enabledDeficiency === "Sim" && answers.deficiency) {
      body.append(`opcoes[7]`, "4499");
      body.append(`respostas_textos[7]`, answers.deficiency);
    }

    const appId = await getAppId(SLUG);

    return http.post(`v2/${appId}/pesquisas/${formId}/perguntas/${pageId}/responder`, body, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  async function getForm(formId: number) {
    const appId = await getAppId(SLUG);

    const response = await http.get(`v2/${appId}/pesquisas/${formId}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    const data = response.data?.respostas[0]?.respostapergunta;

    const awnser = {
      gender: "",
      city: "",
      scholarity: "",
      income: "",
      folk: "",
      deficiency: "",
      enabledDeficiency: "Não",
      identification: [],
      about: [],
      ortherfolk: "",
      ortheridentification: "",
    };

    data?.forEach((item: any) => {
      switch (item.opcao) {
        case 785:
          awnser.gender = item.resposta_texto;
          break;
        case 786:
          awnser.city = item.resposta_texto;
          break;
        case 787:
          awnser.scholarity = item.resposta_texto;
          break;
        case 788:
          awnser.income = item.resposta_texto;
          break;
        case 789:
          // eslint-disable-next-line no-case-declarations
          let identication = item.resposta_texto?.split(", ");

          identication.forEach((item: any) => {
            if (!identifications.includes(item)) {
              identication = [...identication.filter((i: any) => i !== item), "Outro"];
              awnser.ortheridentification = item;
            }
          });

          awnser.identification = identication;
          break;
        case 790:
          if (!race.includes(item.resposta_texto)) {
            awnser.folk = "Outro";
            awnser.ortherfolk = item.resposta_texto;
            break;
          }

          awnser.folk = item.resposta_texto;
          break;
        case 791:
          awnser.about = item.resposta_texto?.split(", ");
          break;
        case 4499:
          if (item.resposta_texto) {
            awnser.enabledDeficiency = "Sim";
            awnser.deficiency = item.resposta_texto;
            break;
          }

          awnser.enabledDeficiency = "Não";
          awnser.deficiency = "";
          break;
        default:
          break;
      }
    });

    return {
      hasData: !!data?.length,
      awnser,
    };
  }

  function handleNavigate() {
    history.replace(`/${SLUG}/`);
  }

  return (
    <IonPage style={{ backgroundColor: "#fff" }}>
      <Header>
        <div>
          <HeaderButton
            icon={<CaretLeft size={20} weight="bold" />}
            handleOnClick={handleNavigate}
            disabled={!!location.state?.isFirst}
          />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="PPA RN" />
        </NavLink>
      </Header>

      <MainContent>
        <Container>
          {isLoading ? (
            <Loading>
              <BigSpinner name="circular" />
              <span>Carregando dados</span>
            </Loading>
          ) : (
            <>
              <header>
                <strong>Formulário de consulta popular</strong>
                <span>Dados de cadastro</span>
              </header>

              <FormProvider {...methods}>
                <Form id="formUser" onSubmit={handleSubmit(handleOnSubmit)}>
                  <SelectField name="city" label="Município de residência" options={cities} />

                  <SelectField
                    name="scholarity"
                    label="Grau de escolaridade"
                    options={scholarity}
                  />

                  <SelectField name="income" label="Renda familiar" options={income} />

                  <SelectField name="gender" label="Gênero" options={gender} />

                  <SelectField name="folk" label="Raça" options={race} />

                  <Controller
                    control={control}
                    name="identification"
                    render={({ field: { value, onChange } }) => (
                      <Multiple
                        label="Você se identifica com algum dos grupos abaixo?"
                        name="identification"
                        value={value}
                        onChange={onChange}
                        options={identifications}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="about"
                    render={({ field: { value, onChange } }) => (
                      <Multiple
                        label="Como ficou sabendo da Consulta Popular?"
                        name="about"
                        value={value}
                        onChange={onChange}
                        options={optionsAbout}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="enabledDeficiency"
                    rules={{
                      required: {
                        value: true,
                        message: "Esse campo é obrigatóorio",
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <DeficiencyField value={value} onChange={onChange} />
                    )}
                  />
                </Form>
              </FormProvider>

              <Button form="formUser">{isSubmiting ? <Spinner name="dots" /> : "Concluir"}</Button>
            </>
          )}
        </Container>
      </MainContent>
    </IonPage>
  );
}
