import { http } from "@/lib/axios";

type GetAvailableDaysParams = {
  appId: number;
  entityId: number;
  resourceId: number;
  localId: number;
  schedulingId?: number;
};

type GetAvailableDaysResponse = Array<{
  id: number;
  data_agendamento: string;
}>;

export async function getAvailableDays({
  appId,
  entityId,
  resourceId,
  localId,
  schedulingId,
}: GetAvailableDaysParams) {
  try {
    const response = await http.get<GetAvailableDaysResponse>(
      `v3/${appId}/mobile/entidade_agendamento/${entityId}/recursos/${resourceId}/locais/${localId}/agendas`,
      {
        params: {
          ...(schedulingId ? { cadastro_id: schedulingId } : {}),
        },
      },
    );

    const data = response.data;

    const schedules: Array<{ id: number; date: string }> = [];
    const availableDays: Array<string> = [];

    data.forEach((item) => {
      const date = new Date(item.data_agendamento);

      date.setHours(0, 0, 0);

      const dateFormatted = date.toISOString();

      schedules.push({
        id: item.id,
        date: dateFormatted,
      });

      availableDays.push(dateFormatted);
    });

    return {
      schedules,
      availableDays,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
