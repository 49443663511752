import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const IconButton = styled.div`
  height: 2.5rem;
  width: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background-color: transparent;
  border-radius: 9999px;

  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  overflow: auto;
  background-color: #ffffff;

  footer {
    margin-top: auto;
    width: 100%;
    position: sticky;
    bottom: 1rem;

    background-color: #ffffff;
    border-top: 1px solid #e2e8f0;
    padding: 1rem;
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  padding: 2rem 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  strong {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #334155;
  }

  span {
    margin: 0;
    font-size: 0.75rem;
    line-height: 150%;
    color: #94a3b8;
  }
`;

export const Themes = styled.div`
  width: 100%;
  overflow: auto;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.875rem;
`;

interface ThemeProps {
  selected?: boolean;
}

export const ThemeBadge = styled.span<ThemeProps>`
  padding: 0.375rem 0.75rem;
  background-color: ${(props) => (props.selected ? COLORS.PRIMARY : "transparent")};

  border: 1px solid ${(props) => (props.selected ? COLORS.PRIMARY : "#E2E8F0")};
  border-radius: 9999px;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => (props.selected ? "#ffffff" : "#94A3B8")};

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  cursor: pointer;
`;

interface ButtonProps {
  variant?: string;
}

export const Button = styled.div<ButtonProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: ${(props) => (props.variant === "outline" ? COLORS.PRIMARY : "#ffffff")};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.75rem 2rem;
  border-radius: 9999px;
  border: 1px solid ${(props) => (props.variant === "outline" ? "#e2e8f0" : "transparent")};

  background-color: ${(props) => (props.variant === "outline" ? "#ffffff" : COLORS.PRIMARY)};
`;
