import { IonMenuToggle } from "@ionic/react";
import { List } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { Header, HeaderButton } from "@/components";
import { Banners } from "./components/banners";
import { Steps } from "./components/steps";
import { PreviewEvents } from "./components/preview-events";
import { PreviewNews } from "./components/preview-news";
import { Gallery } from "./components/gallery";
import { Footer } from "./components/footer";

import logoImg from "@/assets/images/logo-ppa.png";
import InstagramSection from "./components/instagram-section";

export function HomePPA() {
  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center before:flex-1 before:content-['']">
          <img src={logoImg} alt="" className="h-12 lg:h-16" />

          <div className="flex-1">
            <IonMenuToggle className="flex justify-end">
              <HeaderButton icon={<List weight="bold" />} />
            </IonMenuToggle>
          </div>
        </div>
      </Header>

      <Content>
        <main className="min-h-full w-full">
          <Banners />

          <Steps />

          <PreviewEvents />

          <PreviewNews />

          <Gallery />

          <InstagramSection />

          <Footer />
        </main>
      </Content>
    </Layout>
  );
}
