import { Link } from "react-router-dom";
import styled from "styled-components";

const HidenImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
`;

interface BlockItemImageProps {
  routerLink: string;
  imageUrl?: string;
}

export function BlockItemImageWithoutTitle(props: BlockItemImageProps) {
  return (
    <Link to={props.routerLink}>
      <HidenImage src={props.imageUrl} />
    </Link>
  );
}
