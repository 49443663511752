import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  padding: 16px;
`;

export const ContentItem = styled.div`
  margin-bottom: 16px;
`;

export const NotContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-size: 14px;
  color: #334155;
`;
