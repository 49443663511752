import { useQuery } from "@tanstack/react-query";

import { getBooking } from "@/services/assetsScheduling/getBooking";

export function useBooking(appId: number, espacoId: string, reservaId: string, staleTime?: number) {
  return useQuery({
    queryKey: ["get-assets-scheduling-institution", appId],
    queryFn: () => getBooking(appId, espacoId, reservaId),
    enabled: !!appId && !!appId && !!espacoId,
    retry: false,
    ...(staleTime && { staleTime }),
  });
}
