import { ComponentProps } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";
import { Star } from "@phosphor-icons/react";

import { Button } from "@/components/Button";
import { useAppId } from "@/store/useAppStore";
import { ratingAtom } from "../index";
import { useRatingUser } from "../hooks/useRatingUser";

const appReviewFormSchema = z.object({
  rating: z.number().min(1).max(5),
});

type appReviewFormData = z.infer<typeof appReviewFormSchema>;

type RatingAvaliationProps = {
  handleChangeStep: (step: number) => void;
};

const ButtonStar = (props: ComponentProps<"button">) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <button
        type="button"
        className="flex h-10 w-10 items-center justify-center rounded-md border-thin border-solid border-[#E2E8F0] bg-[white] text-[#E2E8F0] transition-colors data-[selected=true]:border-[#FFCA0F] data-[selected=true]:bg-[#FFCA0F] data-[selected=true]:text-[#FFFFFF]"
        {...props}
      >
        <Star weight="fill" size={20} />
      </button>
    </div>
  );
};

export function RatingAvaliationModal({ handleChangeStep }: RatingAvaliationProps) {
  const { t } = useTranslation();

  const appId = useAppId();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const [rating, setRating] = useAtom(ratingAtom);

  const { mutateAsync, isLoading } = useRatingUser();

  const { control, handleSubmit } = useForm<appReviewFormData>({
    resolver: zodResolver(appReviewFormSchema),
    defaultValues: {
      rating: rating.stars ?? 0,
    },
  });

  const handleRating = async ({ mood, stars }: { mood: number; stars: number }) => {
    if (!mood || !stars) {
      return;
    }

    await mutateAsync(
      {
        appId: Number(appId),
        protocoloId: Number(id),
        mood,
        stars,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["protocols", Number(id)], { refetchType: "active" });
          handleChangeStep(4);
        },
        onError: (err) => {
          console.error(err);
          toast.error("Não foi possível realizar avaliação");
        },
      },
    );
  };

  function onSubmit(data: appReviewFormData) {
    setRating((prev) => ({ ...prev, stars: data.rating }));

    if (rating.mood < 4 || data.rating < 4) {
      handleChangeStep(3);
    } else {
      handleRating({ mood: rating.mood, stars: data.rating });
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-1">
        <Text size="md" color="slate-700" className="text-center" weight="semibold">
          {t("avaliationResearch.Qual seu nível de satisfação com a resolução recebida?")}
        </Text>
        <Text size="sm" color="slate-400" className="text-center">
          {t("avaliationResearch.Avalie a execução do serviço.")}
        </Text>
      </div>

      <form
        id="stepTwoForm"
        onSubmit={handleSubmit(onSubmit)}
        className="mt-7 flex w-full flex-col items-center gap-6"
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-7">
            {[...Array(5)].map((_, index) => (
              <Controller
                key={index}
                name="rating"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ButtonStar
                    onClick={() => onChange(index + 1)}
                    data-selected={value >= index + 1}
                  />
                )}
              />
            ))}
          </div>

          <div className="flex w-full justify-between">
            <Text size="xs">{t("avaliationResearch.Insatisfeito(a)")}</Text>
            <Text size="xs">{t("avaliationResearch.Satisfeito(a)")}</Text>
          </div>
        </div>

        <div className="w-full space-y-2">
          <Button
            full
            type="button"
            variant="tertiary"
            size="lg"
            disabled={isLoading}
            onClick={() => handleChangeStep(1)}
          >
            {t("general.Retornar")}
          </Button>
          <Button full type="submit" loading={isLoading} size="lg">
            {t("general.Continuar")}
          </Button>
        </div>
      </form>
    </div>
  );
}
