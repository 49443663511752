import { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonModal, useIonViewWillLeave } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import dayjs from "dayjs";
import { Text } from "@astrolabe-ui/react";
import { CalendarCheck, MapPin, ArrowSquareOut } from "@phosphor-icons/react";

import { Button, Modal } from "@/components";
import { useAppSlug } from "@/store/useAppStore";
import { Hour, Local, Schedule } from "@/services/schedulings";
import { useUserToken } from "@/store/useAuthStore";

import illustrationSuccessCreateScheduling from "@/assets/illustrations/success-create-scheduling.svg";

type SuccessCreateSchedulingProps = {
  showModal: boolean;
  onClose: () => void;
  id: number;
  local: Local;
  schedule: Schedule;
  hour: Hour;
  textHelp?: string | null;
  textInstructions?: string | null;
  cpf?: string;
  onFinishedModal?: () => void;
};

export function SuccessCreateScheduling({
  showModal,
  onClose,
  id,
  local,
  schedule,
  hour,
  textHelp,
  textInstructions,
  cpf,
  onFinishedModal,
}: SuccessCreateSchedulingProps) {
  const navigation = useHistory();
  const slug = useAppSlug();
  const isAuthenticated = !!useUserToken();

  const [showModalHelp, setShowModalHelp] = useState(false);

  function handleGoScheduling() {
    navigation.replace(`/${slug}/meus-agendamentos/${id}`);
  }

  function handleGoMySchedulings() {
    if (!isAuthenticated) {
      navigation.replace(`/${slug}/buscar-agendamentos${cpf ? `?cpf=${cpf}` : ""}`);
      return;
    }

    navigation.replace(`/${slug}/meus-agendamentos`);
  }

  function handleCloseModalHelp() {
    setShowModalHelp(false);
  }

  function handleOpenMapNative({ lat, lng }: { lat: string; lng: string }) {
    const address = `${lat}, ${lng}`;

    let url = "";

    if (!Capacitor.isNativePlatform()) {
      url = `http://maps.google.com?q=${encodeURIComponent(address)}`;
    } else if (Capacitor.getPlatform() === "ios") {
      url = `http://maps.apple.com/maps?q=${encodeURIComponent(address)}`;
    } else {
      url = `geo:?q=${encodeURIComponent(address)}`;
    }

    window.open(url, "_system", "location=no");
  }

  useIonViewWillLeave(() => {
    if (onFinishedModal) {
      onFinishedModal();
    }
  });

  return (
    <IonModal isOpen={showModal} onDidDismiss={onClose} backdropDismiss={false}>
      <div className="flex min-h-full w-full flex-col items-center gap-8 overflow-auto px-4 pb-6 pt-12">
        <img src={illustrationSuccessCreateScheduling} alt="" className="w-40" />

        <div className="flex flex-col text-center">
          <Text size="lg" color="slate-700">
            Parabéns!
          </Text>
          <Text size="md" className="px-12">
            Seu agendamento foi concluído com sucesso.
          </Text>
        </div>

        <div className="flex w-full flex-col gap-4 rounded-lg bg-slate-50 px-6 py-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1.5">
              <MapPin size={16} className="text-primary-500" />
              <Text weight="medium" color="slate-700">
                {local.name}
              </Text>
            </div>

            <button
              className="flex items-center gap-1 text-left"
              onClick={() =>
                handleOpenMapNative({ lat: local.address.lat, lng: local.address.lng })
              }
            >
              <Text color="primary-500" className="underline">
                {local.address.address}
              </Text>
              <ArrowSquareOut size={16} className="flex-shrink-0 text-primary-500" />
            </button>
          </div>

          <div className="flex items-center gap-1.5">
            <CalendarCheck size={16} className="text-primary-500" />
            <Text weight="medium" color="slate-700">
              {dayjs(schedule.date).format("DD [de] MMMM [(]dddd[)]")}
              {" - "}
              {hour.hour}
            </Text>
          </div>
        </div>

        {textInstructions ? (
          <div className="flex w-full flex-col gap-3 rounded-lg bg-red-50 px-6 py-4">
            <Text weight="medium" color="slate-700">
              Instruções de agendamento
            </Text>
            <div
              className="prose-p:slate-600 prose prose-slate mx-auto font-sans text-sm text-slate-700 [overflow-wrap:anywhere] prose-p:text-slate-700 prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p]:mt-0 [&>p]:last:mb-0"
              dangerouslySetInnerHTML={{ __html: textInstructions }}
            />
          </div>
        ) : null}

        <footer className="mt-auto flex w-full flex-col gap-2">
          {textHelp ? (
            <Button size="lg" variant="tertiary" className="underline">
              Em caso de dúvidas, clique aqui
            </Button>
          ) : null}
          <Button size="lg" variant="secondary" onClick={handleGoScheduling}>
            Detalhes do agendamento
          </Button>
          <Button size="lg" onClick={handleGoMySchedulings}>
            Consultar meus agendamentos
          </Button>
        </footer>
      </div>

      <Modal showModal={showModalHelp} onClose={handleCloseModalHelp}>
        {textHelp ? (
          <div
            className="prose prose-slate mx-auto mt-2 font-sans text-sm [overflow-wrap:anywhere] prose-strong:font-medium prose-img:w-full prose-img:rounded-sm prose-video:aspect-video prose-video:w-full prose-video:rounded-sm [&>iframe]:aspect-video [&>iframe]:w-full [&>iframe]:rounded-sm [&>p>br]:hidden [&>p]:last:mb-0"
            dangerouslySetInnerHTML={{ __html: textHelp }}
          />
        ) : null}
      </Modal>
    </IonModal>
  );
}
