import { useQuery } from "@tanstack/react-query";
import { getChats } from "@/services/market/get-chats";

type UseChatsParams = {
  appId: number;
  id: number;
};

export function useChats({ appId, id }: UseChatsParams) {
  return useQuery({
    queryKey: ["chats", id, appId],
    queryFn: () => getChats({ appId, id }),
    enabled: !!appId && !!id,
  });
}
