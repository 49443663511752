import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { cpf } from "cpf-cnpj-validator";
import { IonSpinner, IonToast } from "@ionic/react";
import axios from "axios";
import { useHistory } from "react-router";

import { NextCPFProps, ValidationData } from "../..";
import { Input } from "@/components/old/Forms/Input";
import { Modal } from "@/components/old/Modal";
import { getUserData, updateUserData } from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { validateCPF } from "@/services/old/bind_holder";
import { removeCaracteresNaoNumericos } from "@/utils/removeCaracteresNaoNumericos";
import { getContent } from "@/content/index";

import { Container } from "./styles";

interface ValidateCPFProps {
  handleNext: ({ optionsName, optionsDate }: NextCPFProps) => Promise<void>;
  onClose: () => void;
}

export function ValidateCPF({ handleNext, onClose }: ValidateCPFProps) {
  const [loading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState("");
  const [isOpenQuestion, setIsOpenQuestion] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState("");
  const [valueCPF, setValueCPF] = useState("");

  const { formState, control, getValues, reset, trigger, setValue } =
    useFormContext<ValidationData>();
  const { errors } = formState;

  const history = useHistory();
  const CONTENT = getContent();

  const getCPF = useCallback(() => {
    getUserData()
      .then((response) => {
        if (response.data.cpf) {
          setValue("cpf", cpf.format(response.data.cpf));
          setValueCPF(response.data.cpf);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setValue]);

  useEffect(() => {
    getCPF();
  }, [getCPF]);

  async function handleAdvanced() {
    const isValid = await trigger();
    if (!isValid) return;

    if (!valueCPF) {
      setIsOpenQuestion(true);
    } else {
      await handleValidateCPF();
    }
  }

  async function handleUpdateCPF() {
    try {
      const form = new FormData();

      const cpf = getValues().cpf;

      if (!cpf) {
        return;
      }

      form.append("cpf", removeCaracteresNaoNumericos(cpf));

      await updateUserData(form);

      setIsOpenToast("updated");

      await handleValidateCPF();
    } catch (error) {
      console.error(error);

      setIsOpenToast("error");

      await handleValidateCPF();
    }
  }

  async function handleNotUpdateCPF() {
    handleCloseQuestion();
    await handleValidateCPF();
  }

  async function handleValidateCPF() {
    try {
      setIsLoading(true);

      const cpf = getValues().cpf;

      const response = await validateCPF(cpf);
      const data = response?.data;

      const optionsName = data.nomes.map((item: string) => ({
        value: item,
        label: item,
      }));

      const optionsDate = data.datas.map((item: string) => ({
        value: item,
        label: formatDate(item),
      }));

      await handleNext({
        optionsName,
        optionsDate,
      });
    } catch (error) {
      console.error(error);
      reset({ cpf: "" });

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.type === "usuario_bloqueado") {
            return setIsOpenModal("blockedUser");
          }

          if (error.response.data.type === "titular_nao_encontrado") {
            return setIsOpenModal("holderNotFound");
          }

          if (error.response.data.type === "cpf_em_uso") {
            return setIsOpenModal("inUseCpf");
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleCloseModal() {
    setIsOpenModal("");
  }

  function handleCloseQuestion() {
    setIsOpenQuestion(false);
  }

  function handleCloseToast() {
    setIsOpenToast("");
  }

  function handleBack() {
    handleCloseModal();
    onClose();
    history.push(`/${getAppSlug()}`);
  }

  function formatDate(date: string) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date.replace(/-/g, "/")));
  }

  return (
    <Container>
      <Controller
        name="cpf"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <Input
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={14}
            formNoValidate={false}
            value={value}
            error={errors.cpf}
            onChange={(e) => {
              e.target.value = cpf.format(e.target.value);
              onChange(e);
            }}
          />
        )}
      />

      <button type="button" onClick={handleAdvanced}>
        {loading ? <IonSpinner name="crescent" /> : CONTENT.VINCULAR_TITULAR.ADVANCE}
      </button>

      <Modal
        isOpen={isOpenQuestion}
        onClose={handleCloseQuestion}
        title={CONTENT.VINCULAR_TITULAR.CPF_UPDATED}
        description={CONTENT.VINCULAR_TITULAR.CPF_UPDATED_MESSAGE}
        status="success"
        textButton={CONTENT.VINCULAR_TITULAR.UPDATED}
        textLink={CONTENT.VINCULAR_TITULAR.NOT}
        onActionPrimary={handleUpdateCPF}
        onActionSecondary={handleNotUpdateCPF}
      />

      <Modal
        isOpen={isOpenModal === "holderNotFound"}
        onClose={handleCloseModal}
        title={CONTENT.VINCULAR_TITULAR.CPF_NOT_FOUND}
        description={CONTENT.VINCULAR_TITULAR.CPF_NOT_FOUND_MESSAGE}
        status="error"
        textButton={CONTENT.VINCULAR_TITULAR.TRY_AGAIN}
        textLink={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleCloseModal}
        onActionSecondary={handleBack}
      />

      <Modal
        isOpen={isOpenModal === "inUseCpf"}
        onClose={handleCloseModal}
        title={CONTENT.VINCULAR_TITULAR.CPF_IN_USE}
        description={CONTENT.VINCULAR_TITULAR.CPF_IN_USE_MESSAGE}
        status="error"
        textButton={CONTENT.VINCULAR_TITULAR.TRY_AGAIN}
        textLink={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleCloseModal}
        onActionSecondary={handleBack}
      />

      <Modal
        isOpen={isOpenModal === "blockedUser"}
        onClose={handleBack}
        title={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED}
        description={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED_MESSAGE}
        status="error"
        textButton={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleBack}
      />

      <IonToast
        isOpen={isOpenToast === "updated"}
        onDidDismiss={handleCloseToast}
        message={CONTENT.VINCULAR_TITULAR.CPF_UPDATED_SUCCESS}
        color="success"
        duration={2000}
      />

      <IonToast
        isOpen={isOpenToast === "error"}
        onDidDismiss={handleCloseToast}
        message={CONTENT.VINCULAR_TITULAR.CPF_UPDATED_ERROR}
        color="danger"
        duration={2000}
      />
    </Container>
  );
}
