import { useTranslation } from "react-i18next";
import { Button, Text } from "@astrolabe-ui/react";

import acesssDenied from "@/assets/illustrations/access-denied.svg";

interface ErrorRequestProps {
  message: string;
  handleCloseModal: () => void;
  handleNavigateHome: () => void;
}

export function ErrorRequest({ message, handleCloseModal, handleNavigateHome }: ErrorRequestProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      {message === "cpf" ? (
        <div className="flex flex-col items-center">
          <header className="flex flex-col items-center gap-8">
            <img src={acesssDenied} alt="Celular com ponto de exclamação no centro." />
            <Text asChild size="md" weight="medium" color="slate-700">
              <strong>{t("auth.CPF em uso")}</strong>
            </Text>
          </header>

          <div className="mt-8 flex flex-col text-center">
            <Text>
              {t(
                "auth.Tente realizar o cadastro com outro CPF ou faça login na conta já existente.",
              )}
            </Text>
          </div>
        </div>
      ) : message === "email" ? (
        <div className="flex flex-col items-center">
          <header className="flex flex-col items-center gap-8">
            <img src={acesssDenied} alt="Celular com ponto de exclamação no centro." />
            <Text asChild size="md" weight="medium" color="slate-700">
              <strong>{t("auth.E-mail em uso")}</strong>
            </Text>
          </header>

          <div className="mt-8 flex flex-col text-center">
            <Text>
              {t(
                "auth.Tente realizar o cadastro com outro e-mail ou faça login na conta já existente.",
              )}
            </Text>
          </div>
        </div>
      ) : null}

      <footer className="mt-12 flex w-full flex-col gap-3">
        <Button
          className="font-medium"
          variant="secondary"
          size="lg"
          rounded="md"
          full
          onClick={handleNavigateHome}
        >
          {t("auth.Fazer login")}
        </Button>

        <Button
          className="font-medium"
          variant="primary"
          size="lg"
          rounded="md"
          onClick={handleCloseModal}
        >
          {t("general.Tentar novamente")}
        </Button>
      </footer>
    </div>
  );
}
