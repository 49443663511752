import { Link } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";

import { useAppSlug } from "@/store/useAppStore";
import step1 from "@/assets/rn-participativo/ETAPA-1.png";
import step2 from "@/assets/rn-participativo/ETAPA-2.png";
import step3 from "@/assets/rn-participativo/ETAPA-3.png";

export function Steps() {
  const slug = useAppSlug();

  return (
    <section className="w-full bg-[#DBEBFF]">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-6 px-4 py-6">
        <Text asChild color="slate-700" weight="medium" className="text-md lg:text-lg">
          <strong>Etapas do RN Participativo</strong>
        </Text>

        <div className="relative mx-auto grid w-full max-w-2xl grid-cols-3">
          <div className="z-10 flex w-full flex-col items-center gap-4">
            <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#ED3762]">
              <Text size="lg" className="text-[#FEF7FF]" weight="semibold">
                1
              </Text>
            </div>
            <div className="flex h-12 w-full items-center justify-center rounded-l-md bg-[#ED3762]">
              <Text weight="semibold" className="text-xs text-[#FEF7FF] lg:text-md">
                Plano Plurianual
              </Text>
            </div>
          </div>

          <div className="z-10 flex w-full flex-col items-center gap-4">
            <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#02CBD5]">
              <Text size="lg" className="text-[#FEF7FF] " weight="semibold">
                2
              </Text>
            </div>
            <div className="flex h-12 w-full items-center justify-center bg-[#02CBD5]">
              <Text weight="semibold" className="text-center text-xs text-[#FEF7FF] lg:text-md">
                Orçamento Participativo
              </Text>
            </div>
          </div>

          <div className="z-10 flex w-full flex-col items-center gap-4">
            <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#43BC61]">
              <Text size="lg" className="text-[#FEF7FF]" weight="semibold">
                3
              </Text>
            </div>
            <div className="flex h-12 w-full items-center justify-center rounded-r-md bg-[#43BC61]">
              <Text weight="semibold" className="text-xs text-[#FEF7FF] lg:text-md">
                Monitoramento
              </Text>
            </div>
          </div>

          <div className="absolute inset-x-0 top-6 h-10 w-full rounded-full border-thin border-[#8CACFF] bg-[#F2ECEC]" />
        </div>

        <div className="mx-auto grid w-full max-w-sm grid-cols-3 gap-4">
          <Link to={`/${slug}/ppa`}>
            <div className="flex w-full flex-col items-center gap-2 text-center">
              <div className="flex h-[82px] w-full items-center justify-center rounded-md border-thin border-[#FE2C61] bg-white">
                <img src={step1} alt="" />
              </div>

              <Text color="slate-700" weight="medium" className="text-xs lg:text-sm">
                Plano Plurianual Participativo
              </Text>
            </div>
          </Link>

          <Link to={`/${slug}/op-rn`}>
            <div className="flex w-full flex-col items-center gap-2 text-center">
              <div className="flex h-[82px] w-full items-center justify-center rounded-md border-thin border-[#02CBD5] bg-white">
                <img src={step2} alt="" />
              </div>

              <Text color="slate-700" weight="medium" className="text-xs lg:text-sm">
                Orçamento Participativo
              </Text>
            </div>
          </Link>

          <div className="flex w-full flex-col items-center gap-2 text-center">
            <div className="flex h-[82px] w-full items-center justify-center rounded-md border-thin border-[#43BC61] bg-white">
              <img src={step3} alt="" />
            </div>

            <Text color="slate-700" weight="medium" className="text-xs lg:text-sm">
              Monitoramento do OP
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
}
