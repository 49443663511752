import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { IonModal } from "@ionic/react";

import { Content } from "@/templates/Content";
import { useSearchParams } from "@/hooks/useSearchParams";

import { SelectLocal } from "./select-local";
import { SelectDay } from "./select-day";
import { SelectHour } from "./select-hour";

import { scheduleCompleteAtom } from "../atoms/schedule-completion-atom";
import { HeaderScheduleComplete } from "../components/header-schedule-complete";
import { SchedulingCompletionValidation } from "./schedule-complete-validation";
import { ModalError } from "@/pages/schedulings/components/modal-error";

type ManageScheduleCompletionProps = {
  entityId: number;
  resource: { id: number; textInstructions: string | null };
  status: string;
};

export function ScheduleCompletionManage({
  entityId,
  resource,
  status,
}: ManageScheduleCompletionProps) {
  const navigation = useHistory();
  const searchParams = useSearchParams();

  const updateScheduleCompletionData = useSetAtom(scheduleCompleteAtom);

  useEffect(() => {
    if (searchParams.get("openModal")) {
      updateScheduleCompletionData((current) => ({
        ...current,
        entityId,
        resource,
      }));
    }
  }, [entityId, resource, updateScheduleCompletionData, searchParams]);

  function renderStep() {
    switch (searchParams.get("step") ?? "local") {
      case "local":
        return <SelectLocal />;
      case "days":
        return <SelectDay />;
      case "hours":
        return <SelectHour />;
      case "validation":
        return <SchedulingCompletionValidation />;
      default:
        break;
    }
  }

  function handleOnCloseModal() {
    searchParams.delete("openModal");
    navigation.replace({ pathname: "", search: searchParams.toString() });
  }

  return (
    <IonModal
      isOpen={searchParams.get("openModal") === "true" && status === "chamado"}
      onDidDismiss={handleOnCloseModal}
      backdropDismiss={false}
    >
      <div className="flex min-h-full w-full flex-col overflow-auto">
        <HeaderScheduleComplete />

        <Content>
          <div className="mx-auto flex min-h-full w-full max-w-3xl">{renderStep()}</div>
        </Content>
      </div>

      <ModalError />
    </IonModal>
  );
}
