import { http } from "@/lib/axios";

type GetEntityParams = {
  appId: number;
  id: string;
};

type GetEntityResponse = {
  id: number;
  titulo: string;
  descricao: string;
  texto_entrada_html: string;
  capa: string;
};

export async function getEntity({ appId, id }: GetEntityParams) {
  try {
    const response = await http.get<GetEntityResponse>(
      `v3/${appId}/mobile/entidade_agendamento/${id}`,
    );

    const data = response.data;

    return {
      id: data.id,
      title: data.titulo,
      description: data.descricao,
      content: data.texto_entrada_html,
      image: data.capa,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
