import { COLORS } from "@/data/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    margin-top: auto;
    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    background-color: ${COLORS.PRIMARY};

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    height: 56px;
    width: 100%;
  }

  button.secondary {
    background: #e2e8f0;
    color: #64748b;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 375px) {
    gap: 12px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  small {
    font-size: 14px;
    color: #475569;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${COLORS.PRIMARY};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d3d3d3;
`;
