import { useCallback, useEffect, useRef, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { Button, Text } from "@astrolabe-ui/react";
import { Layout } from "@/templates/Layout";

import { Header } from "@/components/Header";
import { HeaderButton } from "@/components/Header/HeaderButton";
import { CaretLeft, X } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { Modal } from "../components";

type IframeTemplateProps = {
  src: string;
  name: string;
  closeButton?: boolean;
  closeText?: string;
  closeTitle?: string;
  showCloseModal?: boolean;
};

interface DownloadFileProps {
  file: Blob;
  name: string;
}

export function Iframe(props: IframeTemplateProps) {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  const downloadFile = useCallback(async ({ file, name }: DownloadFileProps) => {
    if (Capacitor.isNativePlatform()) {
      const fileBase64 = await convertBlobToBase64(file);

      const currentDate = new Date().getTime();

      const { uri } = await Filesystem.writeFile({
        path: `${name}-${currentDate}.pdf`,
        data: fileBase64,
        directory: Directory.Documents,
      });

      await FileOpener.open({
        filePath: uri,
        contentType: "application/pdf",
      });
    } else {
      const data = window.URL.createObjectURL(file);

      window.open(data, "_blank");

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  }, []);

  const handleLoadIframe = useCallback(() => {
    const channel = new MessageChannel();

    channel.port2.onmessage = async (evt) => {
      await downloadFile(evt.data);
    };

    iframeRef.current?.contentWindow?.postMessage("", "*", [channel.port1]);
  }, [downloadFile]);

  useEffect(() => {
    if (iframeRef?.current) {
      const iframe = iframeRef.current;

      iframe.addEventListener("load", handleLoadIframe);

      return () => {
        iframe.removeEventListener("load", handleLoadIframe);
      };
    }
  }, [iframeRef, handleLoadIframe]);

  function handleNavigate() {
    if (props.showCloseModal) setShowModal(true);
    else {
      history.goBack();
    }
  }

  function convertBlobToBase64(data: Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result as string);

      reader.readAsDataURL(data);
    });
  }

  function handleCloseIframe() {
    history.goBack();
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              onClick={handleNavigate}
              icon={props.closeButton ? <X weight="bold" /> : <CaretLeft weight="bold" />}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {props.name}
          </Text>
        </div>
      </Header>

      <iframe
        className="h-full w-full"
        ref={iframeRef}
        src={props.src}
        allow="clipboard-write"
        sandbox="allow-downloads allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation allow-forms allow-modals"
      ></iframe>

      <Modal showModal={showModal} onClose={handleCloseModal}>
        <div className="flex flex-col items-center gap-6">
          <Text size="md" weight="semibold" color="slate-700">
            {props.closeTitle ?? "Atenção"}
          </Text>
          <Text className="text-center">
            {props.closeText ?? "Confirma o fechamento da página atual ?"}
          </Text>

          <div className="w-full space-y-3">
            <Button variant="secondary" size="lg" rounded="full" full onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button variant="primary" size="lg" rounded="full" full onClick={handleCloseIframe}>
              Sair
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
