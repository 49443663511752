import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LinkButton, Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { HeaderButton, Header } from "@/components/index";
import { Content } from "@/templates/Content";
import { Layout } from "@/templates/Layout";

import emptyIllustration from "@/assets/illustrations/empty.svg";
import { getAppSlug } from "@/services/old/starter";

export const Unauthorized = ({
  message,
  redirectUrl,
  children,
}: {
  message?: string;
  redirectUrl?: string | boolean;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const slug = getAppSlug();

  function handleNavigate() {
    history.goBack();
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center gap-4 after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleNavigate} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="truncate text-textHeader">
            {t("general.Sem permissão")}
          </Text>
        </div>
      </Header>
      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col items-center justify-center gap-3 p-4">
          <img src={emptyIllustration} alt="" />
          {message && <Text>{message}...</Text>}
          {children}
          {redirectUrl && (
            <LinkButton size="md" onClick={() => history.replace(`/${slug}${redirectUrl}`)}>
              {t("general.Voltar")}
            </LinkButton>
          )}
        </div>
      </Content>
    </Layout>
  );
};
