import { UserCircle } from "@phosphor-icons/react";
import { IonSkeletonText } from "@ionic/react";

import { Badge, Badges, Container, Content, Creator, Text } from "./styles";

import { Tag, Theme } from "../../hooks/use-proposals";

interface ProposalProps {
  title: string | null;
  creator: string | null;
  voters: number;
  hasVoter: boolean;
  tags: Array<Tag>;
  themes: Array<Theme>;
}

export function Proposal({ title, creator, voters, tags, themes }: ProposalProps) {
  return (
    <Container>
      <Content>
        {themes.length || tags.length ? (
          <Badges>
            {tags.length
              ? tags.map((item) => (
                  <Badge key={item.id} variant="tag">
                    {item.title}
                  </Badge>
                ))
              : null}
            {themes.length
              ? themes.map((item) => (
                  <Badge key={item.id} variant="theme">
                    {item.title}
                  </Badge>
                ))
              : null}
          </Badges>
        ) : null}

        <Text>{title}</Text>

        {creator ? (
          <Creator>
            <UserCircle size={20} color="#334155" />
            <span>{creator}</span>
          </Creator>
        ) : null}
      </Content>

      <footer>
        <p>
          {voters}
          <span> {voters > 1 ? "VOTOS" : "VOTO"}</span>
        </p>
      </footer>
    </Container>
  );
}

export function ProposalSkeleton() {
  return (
    <Container>
      <Content>
        <Badges>
          <IonSkeletonText animated style={{ height: 22, width: 80 }} />
        </Badges>

        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
        </div>
      </Content>

      <footer>
        <IonSkeletonText animated style={{ width: 60 }} />
        <IonSkeletonText animated style={{ width: 84, height: 36, borderRadius: 99999 }} />
      </footer>
    </Container>
  );
}
