import { useState } from "react";
import styled from "styled-components";
import { Capacitor } from "@capacitor/core";
import { useQuery } from "@tanstack/react-query";
import L, { LatLngTuple } from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { IonModal, IonButton, IonIcon } from "@ionic/react";
import { mapOutline } from "ionicons/icons";

import { Block } from "@/components/old/Block";
import { getAddress } from "@/services/old/location";
import { getContent } from "@/content/index";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Address = styled.div`
  margin-bottom: 15px;
`;

function startMap(lat: number, lng: number, address: string) {
  const defaultZoom = 14;
  const defaultLocation: LatLngTuple = [lat, lng];
  const map = L.map("blockmap").setView(defaultLocation, defaultZoom);
  const marker = L.marker(defaultLocation).addTo(map);

  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 18,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    tileSize: 512,
    zoomOffset: -1,
  }).addTo(map);

  marker.bindPopup(address).openPopup();
}

type BlockMapProps = {
  lat: number;
  lng: number;
  overrideAddress?: string;
  hiddenTitle?: boolean;
};

export function BlockMap({ lat, lng, overrideAddress, hiddenTitle = false }: BlockMapProps) {
  const [showModal, setShowModal] = useState(false);

  const { data, isLoading, isFetched } = useQuery(["getAddress", lat, lng], () =>
    getAddress(lat, lng),
  );

  const originCenter: LatLngTuple = [lat, lng];

  const CONTENT = getContent();

  return (
    <Block title={CONTENT.LOCATION.TITLE} {...(hiddenTitle && { hiddenBlock: true })}>
      {isLoading && <div>{CONTENT.LOCATION.LOADING}</div>}

      {isFetched && (
        <div>
          <MapContainer
            style={{ width: "100%", height: "200px", marginBottom: "20px" }}
            zoomControl={false}
            dragging={false}
            center={originCenter}
            zoom={16}
            scrollWheelZoom={false}
          >
            <TileLayer url={"https://tile.openstreetmap.org/{z}/{x}/{y}.png"} />
            <Marker position={originCenter}></Marker>
          </MapContainer>

          {overrideAddress && (
            <Address>
              <b> {CONTENT.PROTOCOLS.ADDRESS}: </b> {overrideAddress}
            </Address>
          )}
          {!overrideAddress && (
            <>
              {data?.complete.road && (
                <Address>
                  <b> {CONTENT.LOCATION.ROAD}: </b> {data?.complete.road || "―"}
                </Address>
              )}
              {data?.complete.neighbourhood && (
                <Address>
                  <b> {CONTENT.LOCATION.NEIGHBORHOOD}: </b> {data?.complete.neighbourhood || "―"}
                </Address>
              )}
              {data?.complete.postcode && (
                <Address>
                  <b> {CONTENT.LOCATION.POSTCODE}: </b> {data?.complete.postcode || "―"}
                </Address>
              )}
              {data?.complete.state && (
                <Address>
                  <b> {CONTENT.LOCATION.ESTATE}: </b> {data?.complete.state || "―"}
                </Address>
              )}
            </>
          )}

          <IonModal
            isOpen={showModal}
            onDidDismiss={() => setShowModal(false)}
            onDidPresent={() => {
              startMap(lat, lng, data?.formated || CONTENT.LOCATION.UNKONW_LOCATION);
            }}
          >
            <div id="blockmap" style={{ height: "100%" }}></div>

            <IonButton onClick={() => setShowModal(false)}>{CONTENT.LOCATION.CLOSE_MAP}</IonButton>
          </IonModal>

          <IonButton
            onClick={() => {
              if (!Capacitor.isNativePlatform()) {
                setShowModal(true);
              } else if (Capacitor.getPlatform() === "android") {
                window.location.href = `geo:0,0?q=${lat},${lng}`;
              } else {
                window.location.href = `maps://maps.apple.com/?q=${lat},${lng}`;
              }
            }}
          >
            <IonIcon slot="start" icon={mapOutline} />
            {CONTENT.LOCATION.OPEN_MAP}
          </IonButton>
        </div>
      )}
    </Block>
  );
}
