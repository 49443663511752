import { useQuery } from "@tanstack/react-query";
import { getMessagesMarket } from "@/services/market/get-messages-market";

type UseMessagesMarketParams = {
  appId: number;
  id: number;
};

export function useMessagesMarketUser({ appId, id }: UseMessagesMarketParams) {
  return useQuery({
    queryKey: ["messages-market-user", id, appId],
    queryFn: () => getMessagesMarket({ appId, id }),
    enabled: !!appId && !!id,
  });
}
