import { ChangeEvent, useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { documentOutline } from "ionicons/icons";
import { IonIcon, IonButton, useIonToast } from "@ionic/react";

import { downloadFile } from "@/utils/downloadFile";
import { getContent } from "@/content/index";
import { isValidSizeInMB } from "@/utils/isValidSizeInMB";

type uploadImageProps = {
  isImage?: boolean;
  uploadedURL?: string;
  noMarginBottom?: boolean;
  onChange?: ({ base64, file }: { base64: any; file: any }) => void;
  verifyMaxSize?: () => number;
};

export const FileInput = styled.input``;

export const FileIcon = styled(IonIcon)`
  color: black;
  padding: 0px;
  margin: 5px;
  font-size: 50px;
`;

export const Button = styled(IonButton)`
  padding: 0px;
  width: 80%;
  margin-top: 10px;
  font-size: 14px;
`;

interface BlockContentProps {
  noMarginBottom?: boolean;
}

export const Container = styled.div<BlockContentProps>`
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  ${(props) =>
    !props.noMarginBottom &&
    css`
      margin-bottom: 10px;
    `}
  width: 100%;
`;

export const FilePreview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Image = styled.img`
  border-radius: 3px;
  height: 100%;
  margin: 0 auto;
  display: block;
`;

export function UploadFile(props: uploadImageProps) {
  const CONTENT = getContent();

  const InputFileRef = useRef<HTMLInputElement>(null);
  const reader = new FileReader();
  const [image, setImage] = useState<any>("");

  const [fileName, setFileName] = useState("");

  const [present] = useIonToast();

  useEffect(() => {
    if (props.isImage && props.uploadedURL) {
      setImage(props.uploadedURL);
    }
  }, [props.isImage, props.uploadedURL]);

  const ChooseFile = () => {
    InputFileRef.current?.click();
  };

  const onChangeHandle = async (e: ChangeEvent) => {
    e.preventDefault();

    const files = InputFileRef.current?.files;

    if (files?.length) {
      const file = files[0];

      const validMaxSizeAllFiles = isValidMaxSizeAllFiles(file.size);
      const validSize = isValidSizeInMB(file.size);

      if (!validMaxSizeAllFiles || !validSize) {
        if (InputFileRef.current) {
          InputFileRef.current.value = "";
        }

        setFileName("");

        let message = "";

        if (!validMaxSizeAllFiles) {
          message = "Ultrapassa o tamanho máximo de arquivos (Max.: 40MB)";
        } else if (!validSize) {
          message = "Tamanho de documento inválido (Max.: 9MB)";
        }

        await showToast({
          message,
          position: "bottom",
        });

        return false;
      }

      reader.readAsDataURL(file);

      reader.onloadend = function () {
        if (props.onChange) {
          props.onChange({
            base64: reader.result,
            file: file ?? null,
          });
        }

        if (file) {
          setFileName(file.name);
        }

        setImage(reader.result);
      };
    }
  };

  function isValidMaxSizeAllFiles(size: number) {
    if (props.verifyMaxSize) {
      const currentSize = props.verifyMaxSize();

      const sizeInMB = Number(((currentSize + size) / 1048576).toFixed(2));

      return sizeInMB < 40;
    } else {
      return true;
    }
  }

  async function showToast({
    message,
    position,
  }: {
    message: string;
    position: "top" | "middle" | "bottom";
  }) {
    await present({
      message,
      duration: 2000,
      position,
    });
  }

  return (
    <Container noMarginBottom={props.noMarginBottom}>
      {props.isImage && (
        <FileInput
          type="file"
          hidden={true}
          ref={InputFileRef}
          onChange={onChangeHandle}
          accept="image/*"
        />
      )}

      {!props.isImage && (
        <FileInput
          type="file"
          hidden={true}
          ref={InputFileRef}
          onChange={onChangeHandle}
          accept="text/plain, application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      )}

      <FilePreview>
        {!props.isImage && !!fileName && <FileIcon icon={documentOutline} />}

        {props.isImage && (!!fileName || (props.isImage && props.uploadedURL)) && (
          <Image src={image} alt="uploaded" />
        )}

        {!props.isImage && (fileName || CONTENT.GLOBAL.SELECT_FILE)}
      </FilePreview>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            ChooseFile();
          }}
        >
          {fileName.length > 0 || (props.isImage && props.uploadedURL)
            ? CONTENT.GLOBAL.CHANGE_FILE
            : CONTENT.GLOBAL.SEARCH}
        </Button>

        {props.uploadedURL && (
          <Button
            onClick={() => {
              if (props.uploadedURL) downloadFile(props.uploadedURL);
            }}
          >
            {CONTENT.GLOBAL.DOWNLOAD_LAST_SENT}
          </Button>
        )}
      </div>
    </Container>
  );
}
