import { Modal as ModalPrimitive, ModalProps as ModalPropsPrimitive } from "@astrolabe-ui/react";
import { ReactPortal } from "../ReactPortal";

export type ModalProps = ModalPropsPrimitive;

export function Modal(props: ModalProps) {
  return (
    <ReactPortal>
      <ModalPrimitive classname="[&>*:last-child]:max-w-3xl [&>*:last-child]:mx-auto" {...props} />
    </ReactPortal>
  );
}
