import { useQuery } from "@tanstack/react-query";
import { getWallets } from "@/services/wallets";

interface useWalletsParams {
  appId: number;
}

export function useWallets({ appId }: useWalletsParams) {
  return useQuery({
    queryKey: ["wallets", appId],
    queryFn: () => getWallets({ appId }),
    enabled: !!appId,
  });
}
