import dayjs from "dayjs";

import { ResponseInstitution } from "@/services/assetsScheduling";
import { IDataAssetsScheduling } from "../..";

export const getBookings = (
  unit?: ResponseInstitution,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  unit?.unidades
    .find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
    ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id)
    ?.reservas.map((booking) => [
      dayjs(booking.hora_inicio).format("YYYY-MM-DD HH:mm"),
      dayjs(booking.hora_fim).format("YYYY-MM-DD HH:mm"),
    ])
    .filter((booking) => booking[0].includes(dataAssetsScheduling?.extra_fields?.date as string))
    .map((booking) => [dayjs(booking[0]).format("HH:mm"), dayjs(booking[1]).format("HH:mm")]);
