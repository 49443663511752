import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Text } from "@astrolabe-ui/react";
import { IonRippleEffect } from "@ionic/react";
import { CalendarCheck, CaretRight, Clock, WarningCircle } from "@phosphor-icons/react";

import { useAppSlug } from "@/store/useAppStore";
import { cn } from "@/lib/utils";

type CardSchedulingProps = {
  id: number;
  local?: string;
  name: string;
  date?: string;
  hour?: string;
  status: string;
};

const mapStatus: Record<string, JSX.Element> = {
  agendado: (
    <div className="w-fit rounded-full bg-green-100 px-5 py-1">
      <span className="font-sans text-xs font-medium text-green-500">Agendado</span>
    </div>
  ),
  atendido: (
    <div className="w-fit rounded-full bg-green-100 px-5 py-1">
      <span className="font-sans text-xs font-medium text-green-500">Atendido</span>
    </div>
  ),
  indeferido: (
    <div className="w-fit rounded-full bg-red-100 px-5 py-1">
      <span className="font-sans text-xs font-medium text-red-500">Indeferido</span>
    </div>
  ),
  nao_compareceu: (
    <div className="w-fit rounded-full bg-red-100 px-5 py-1">
      <span className="font-sans text-xs font-medium text-red-500">Não compareceu</span>
    </div>
  ),
};

export function CardScheduling({ id, local, name, date, hour, status }: CardSchedulingProps) {
  const slug = useAppSlug();

  return (
    <div className="flex flex-col gap-3">
      {status === "indeferido" && dayjs().isBefore(dayjs(hour)) ? (
        <div className="flex items-center gap-2 rounded-md bg-[#fef2f2] px-3 py-2">
          <WarningCircle className="flex-shrink-0 text-red-500" weight="fill" />

          <Text size="xs" color="red-500">
            Atenção! Seu agendamento no nome de {name}, para a data{" "}
            {dayjs(hour).format("DD/MM - HH[h]mm")}, foi indeferido.{" "}
            <Link to={`/${slug}/meus-agendamentos/${id}`} className="font-medium underline">
              Clique aqui para saber mais
            </Link>
          </Text>
        </div>
      ) : null}

      <Link to={`/${slug}/meus-agendamentos/${id}`}>
        <div
          className={cn(
            "ion-activatable ripple-parent ion-activable flex items-center justify-between gap-4 rounded-md border-thin border-l-medium border-slate-200 p-3",
            {
              "border-l-green-500": status === "agendado" || status === "atendido",
              "border-l-red-500": status === "indeferido",
              "border-l-orange-500": status === "nao_compareceu",
            },
          )}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <Text color="slate-700">{local}</Text>

              <Text size="xs">{name}</Text>

              <div className="mt-1 flex gap-3">
                <div className="flex items-center gap-1">
                  <CalendarCheck className="flex-shrink-0 text-primary-500" />
                  <Text size="xs" color="slate-700">
                    {dayjs(date).format("DD MMM YY")}
                  </Text>
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="flex-shrink-0 text-primary-500" />
                  <Text size="xs" color="slate-700">
                    {dayjs(hour).format("HH:mm")}
                  </Text>
                </div>
              </div>
            </div>

            {mapStatus[status]}
          </div>

          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-50">
            <CaretRight weight="bold" size={12} className="text-primary-500" />
          </div>

          <IonRippleEffect className="text-slate-100" />
        </div>
      </Link>
    </div>
  );
}
