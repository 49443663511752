import { http } from "@/lib/axios";

type GetResultsParams = {
  appId: number;
  consultId: string;
  territory: number;
};

type GetResultsResponse = Array<{
  id: number;
  titulo: string | null;
  descricao: string | null;
  imagem: string | null;
  status: "Inativo" | "Aprovado" | "Recusado";
  tags: Array<{
    id: number;
    titulo: string;
  }>;
  temas: Array<{
    id: number;
    titulo: string;
  }>;
  total_votos: number;
  user: {
    id: number;
    name: string;
  } | null;
}>;

const mapStatus: Record<string, "inactive" | "approved" | "refused"> = {
  Inativo: "inactive",
  Aprovado: "approved",
  Recusado: "approved",
};

export async function getResults({ appId, consultId, territory }: GetResultsParams) {
  try {
    const response = await http.get<GetResultsResponse>(
      `v3/${appId}/consultas/${consultId}/resultados`,
      {
        params: {
          ...(territory ? { territorio: territory } : {}),
        },
      },
    );

    const proposals = response.data.map((item) => {
      const themes = item.temas?.map((theme) => ({
        id: theme.id,
        title: theme.titulo,
      }));

      const tags = item.tags?.map((tag) => ({
        id: tag.id,
        title: tag.titulo,
      }));

      let username = "";

      if (item.user) {
        const names = item.user.name.split(" ");
        username = `${names[0]}${names[names.length - 1] ? ` ${names[names.length - 1]}` : ""}`;
      }

      return {
        id: item.id,
        title: item.titulo,
        description: item.descricao,
        image: item.imagem,
        status: mapStatus[item.status],
        voters: item.total_votos,
        username,
        themes,
        tags,
      };
    });

    return proposals;
  } catch (error) {
    console.error("Erro ao buscar propostas: ", error);
    throw error;
  }
}
