/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from "react";
import { IonModal, createAnimation } from "@ionic/react";

export const FilterIonModal = ({
  children,
  onDidDismiss,
  isOpen,
  ...props
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onDidDismiss: () => void;
}) => {
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    if (root) {
      const backdropAnimation = createAnimation()
        // @ts-ignore
        .addElement(root.querySelector("ion-backdrop"))
        .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

      const wrapperAnimation = createAnimation()
        // @ts-ignore
        .addElement(root.querySelector(".modal-wrapper"))
        .keyframes([
          { opacity: 1, transform: "translateX(100%)" },
          { opacity: 1, transform: "translateX(0)" },
        ]);
      return createAnimation()
        .addElement(baseEl)
        .easing("cubic-bezier(0.1, 0.7, 0.1, 1)")
        .duration(400)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    }
  };

  const leaveAnimation = (baseEl: HTMLElement) => enterAnimation(baseEl)?.direction("reverse");

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      // @ts-ignore
      enterAnimation={enterAnimation}
      // @ts-ignore
      leaveAnimation={leaveAnimation}
      {...props}
    >
      {children}
    </IonModal>
  );
};
