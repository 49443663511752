import { SetStateAction } from "react";
import dayjs from "dayjs";

import { IDataAssetsScheduling } from "../..";

export const handleSelect = (
  time: string,
  isSelected: boolean,
  setTimeSelected: (value: SetStateAction<string[]>) => void,
  setAmountTime: (value: SetStateAction<number>) => void,
  timeSelected: string[],
  dataAssetsScheduling?: IDataAssetsScheduling,
) => {
  if (
    isSelected &&
    !!dataAssetsScheduling &&
    dataAssetsScheduling.extra_fields &&
    dataAssetsScheduling.extra_fields.step
  ) {
    setTimeSelected((prevState) => prevState.filter((state) => state !== time));
    setAmountTime(timeSelected.length === 2 ? dataAssetsScheduling.extra_fields.step : 0);
  } else {
    setTimeSelected((prevState) =>
      prevState.length === 0
        ? [time]
        : prevState[0] < time
        ? [prevState[0], time]
        : [time, prevState[0]],
    );
    setAmountTime(
      timeSelected.length === 0
        ? (dataAssetsScheduling?.extra_fields?.step as number)
        : dayjs(time, "HH:mm").diff(dayjs(timeSelected[0], "HH:mm"), "minutes"),
    );
  }
};
