/* eslint-disable array-callback-return */
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonLoading } from "@ionic/react";
import { qrCodeOutline } from "ionicons/icons";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockItemProtocolsDetailsList } from "@/pages/old/NewProtocol/components/BlockItemProtocolsDetailsList";
import { getProtocolById } from "@/services/old/servicos";
import { getContent } from "@/content/index";

import { Icon, TextProtocol } from "./styles";

interface ProtocolParams {
  id: string;
  SLUG: string;
}

export function Details() {
  const CONTENT = getContent();

  const params: ProtocolParams = useParams();

  const { isLoading, data } = useQuery(["getProtocolById", params.id], () =>
    getProtocolById(params.id, params.SLUG),
  );

  if (isLoading) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  } else if (!data) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.PROTOCOL}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  }

  let areaCategoria =
    data.data.categoriaServico?.area_categoria.titulo + ` > ${data.data.categoriaServico?.titulo}`;

  if (data.data.subCategoriaServico?.titulo) {
    areaCategoria = areaCategoria + ` > ${data.data.subCategoriaServico?.titulo}`;
  }

  return (
    <div style={{ padding: "20px 12px 12px" }}>
      <TextProtocol>
        <Icon icon={qrCodeOutline} /> Protocolo
      </TextProtocol>
      <BlockItemProtocolsDetailsList type="code" title="Código ID" description={data.data.id} />
      <BlockItemProtocolsDetailsList
        type="date"
        title="Data de criação"
        description={data.data.created_at}
      />
      <BlockItemProtocolsDetailsList
        type="status"
        title="Status"
        description={data.data.status.titulo}
      />
      <BlockItemProtocolsDetailsList
        type="default"
        title="Área categoria"
        description={areaCategoria}
      />
      {data.data.imagem && (
        <BlockItemProtocolsDetailsList
          type="image"
          title={CONTENT.PROTOCOLS.IMAGE}
          description={data.data.imagem}
        />
      )}
      {data.data.latitude && data.data.longitude && (
        <BlockItemProtocolsDetailsList
          type="map"
          title={CONTENT.LOCATION.TITLE}
          description=""
          lat={data.data.latitude}
          long={data.data.longitude}
        />
      )}

      {data.data.respostasCampos.map((item: any, index: number) => {
        if (item.tipo === "string" || item.tipo === "integer") {
          return (
            <BlockItemProtocolsDetailsList
              key={index}
              type="default"
              title={item.nome_campo}
              description={item.resposta ? item.resposta : "Sem valor"}
            />
          );
        }
        if (item.tipo === "data") {
          return (
            <BlockItemProtocolsDetailsList
              key={index}
              type="date"
              title={item.nome_campo}
              description={item.resposta}
            />
          );
        }
        if ((item.tipo === "imagem" || item.tipo === "arquivo") && !item.resposta) {
          return (
            <BlockItemProtocolsDetailsList
              key={index}
              type="default"
              title={item.nome_campo}
              description="Sem valor"
            />
          );
        }
        if (
          item.tipo === "imagem" ||
          (item.tipo === "arquivo" && item.resposta.match(/\.(jpeg|jpg|png|gif)$/) != null)
        ) {
          return (
            <BlockItemProtocolsDetailsList
              key={index}
              type="image"
              title={item.nome_campo}
              description={item.resposta}
            />
          );
        }

        if (item.tipo === "arquivo") {
          const descrition = item.resposta.split("/");
          return (
            <BlockItemProtocolsDetailsList
              key={index}
              type="file"
              title={item.nome_campo}
              description={item.resposta}
              descriptionFile={descrition[descrition.length - 1]}
            />
          );
        }
      })}
    </div>
  );
}
