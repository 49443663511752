import { getAppId } from "./provider";

import { http } from "@/lib/axios";
import { isLogged, getAccessToken } from "./auth";

export interface AnswerProps {
  id: number;
  willAnwser: boolean;
  text?: string;
  date?: Date;
  file?: any;
  objetive?: boolean;
}

export async function getForms(page: number, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    const headers = {
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    return http.get(
      `v2/` + APP_ID + `/pesquisas?apenas_abertas=1&paginate=1&page=${page}`,
      headers,
    );
  } else
    return http.get(`v2/` + APP_ID + `/pesquisas?apenas_abertas=1&paginate=1&page=${page}`, {});
}

export async function getFormById(id: string, SLUG: string) {
  if (isLogged()) {
    const headers = {
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
    };
    const APP_ID = await getAppId(SLUG);
    return http.get(`v2/` + APP_ID + `/pesquisas/${id}`, headers);
  } else return null;
}

/*
  idform = idpesquisa
  idPage = antigo idpergunta
*/
async function sendFileAnswer(
  idForm: number,
  idPage: number,
  idOption: number,
  file: any,
  SLUG: string,
) {
  const formData = new FormData();
  formData.append("resposta_arquivo", file);
  formData.append("opcoes", idOption + "");

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);
  return await http.post(
    `v2/${APP_ID}/pesquisas/${idForm}/perguntas/${idPage}/${idOption}/arquivo`,
    formData,
    headers,
  );
}

export async function formSendAnswers(
  idForm: number,
  idPage: number,
  answers: AnswerProps[],
  SLUG: string,
  geolocation: { lat: number; lng: number } | null,
) {
  const formData = new FormData();

  if (geolocation) {
    formData.append("latitude", geolocation.lat.toString());
    formData.append("longitude", geolocation.lng.toString());
  }

  for (let i = 0; i < answers.length; i++) {
    const item = answers[i];

    formData.append(`opcoes[${i}]`, item.id + "");

    if (item.file) {
      const res = await sendFileAnswer(idForm, idPage, item.id, item.file.file, SLUG);
      formData.append(`respostas_textos[${i}]`, res.data.data.resposta_texto);
    } else if (item.text) {
      formData.append(`respostas_textos[${i}]`, item.text);
    } else if (item.date) {
      formData.append(
        `respostas_textos[${i}]`,
        (item.date.getDay() < 10 ? "0" : "") +
          item.date.getDay() +
          "/" +
          (item.date.getMonth() < 10 ? "0" : "") +
          item.date.getMonth() +
          "/" +
          item.date.getFullYear(),
      );
    } else if (item.objetive) {
      formData.append(`respostas_textos[${i}]`, "objetiva");
    }
  }

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return await http
    .post(`v2/${APP_ID}/pesquisas/${idForm}/perguntas/${idPage}/responder`, formData, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}
