import { useEffect, useState } from "react";
import { IonModal } from "@ionic/react";
import { useAtom } from "jotai";
import { CaretDown } from "@phosphor-icons/react";

import { Container, ContainerModal, Radio, RadioButton, Radios } from "./styles";
import { ordinationProposalsAtom } from "../../atoms/ordination-proposals-atom";

const mapOrdination: Record<string, string> = {
  random: "Aleatório",
  tag: "Eixo",
  topRated: "Mais votados",
  vote: "Meus votos",
};

interface OrdinationProps {
  initialOrdination: string | null;
}

export function Ordination({ initialOrdination }: OrdinationProps) {
  const [ordination, setOrdination] = useAtom(ordinationProposalsAtom);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (initialOrdination) {
      setOrdination(initialOrdination);
    }
  }, [initialOrdination, setOrdination]);

  function handleSelect(value: string) {
    setOrdination(value);
    handleCloseModal();
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <>
      <Container onClick={() => setIsOpenModal(true)}>
        <span>{mapOrdination[ordination]}</span>
        <CaretDown size={16} weight="bold" color="#94A3B8" />
      </Container>

      <IonModal
        isOpen={isOpenModal}
        onDidDismiss={() => handleCloseModal()}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.2, 0.5]}
      >
        <ContainerModal>
          <strong>Ordenar por:</strong>

          <Radios
            value={ordination}
            // onIonChange={(event) => handleSelect(event.detail.value)}
          >
            <Radio selected={ordination === "random"} onClick={() => handleSelect("random")}>
              <label htmlFor="random">Aleatório</label>
              <RadioButton id="random" value="random" mode="md" />
            </Radio>
            <Radio selected={ordination === "tag"} onClick={() => handleSelect("tag")}>
              <label htmlFor="tag">Eixo</label>
              <RadioButton id="tag" value="tag" mode="md" />
            </Radio>
            <Radio selected={ordination === "topRated"} onClick={() => handleSelect("topRated")}>
              <label htmlFor="topRated">Mais votados</label>
              <RadioButton id="topRated" value="topRated" mode="md" />
            </Radio>
            <Radio selected={ordination === "vote"} onClick={() => handleSelect("vote")}>
              <label htmlFor="vote">Meus votos</label>
              <RadioButton id="vote" value="vote" mode="md" />
            </Radio>
          </Radios>
        </ContainerModal>
      </IonModal>
    </>
  );
}
