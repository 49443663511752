import dayjs from "dayjs";
import { http } from "@/lib/axios";

type getEventsParams = {
  appId?: number;
};

type eventsTypes = {
  id: number;
  titulo: string;
  banner: string;
  inicio_ts: string;
  endereco_extenso: string | null;
  descricao: string;
};

interface getEventsHttpResponse {
  data: Array<eventsTypes>;
  current_page: number;
  next_page_url: string | null;
  total: number;
}

export async function getPreviewEvents({ appId }: getEventsParams) {
  const response = await http.get<getEventsHttpResponse>(`v2/${appId}/eventos`, {
    params: {
      apenas_pais: 1,
      paginate: 1,
      page: 1,
      per_page: 5,
      inicio_ts: new Date().toISOString(),
    },
  });

  const events = response.data.data.map((item) => ({
    id: item.id,
    title: item.titulo,
    imageUrl: item.banner,
    time: formatDate(item.inicio_ts),
    localization: item.endereco_extenso,
    description: item.descricao,
  }));

  return events;
}

function formatDate(date: string) {
  return dayjs(date).format("DD/MM/YYYY [•] HH[h]mm");
}
