import { http } from "@/lib/axios";

type GetTrackerEventParams = {
  id: number;
};

type GetTrackerEventResponse = {
  data: {
    ultima_localizacao: {
      latitude: string;
      longitude: string;
    };
  };
};

export async function getTrackerEvent({ id }: GetTrackerEventParams) {
  try {
    const response = await http.get<GetTrackerEventResponse>(`/eventos/${id}/ultima_localizacao`);

    if (!response.data.data) {
      return undefined;
    }

    return {
      latitude: Number(response.data.data.ultima_localizacao.latitude),
      longitude: Number(response.data.data.ultima_localizacao.longitude),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
