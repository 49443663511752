import { ComponentProps } from "react";
import { cn } from "@/lib/utils";

interface HeaderProps extends ComponentProps<"header"> {
  variant?: "primary" | "secondary";
}

export function Header({ children, className, variant = "primary", ...props }: HeaderProps) {
  return (
    <header
      data-variant={variant}
      className={cn(
        "sticky top-0 z-50 flex w-full items-center bg-white p-3 pt-[calc(var(--safe-area-inset-top)+0.75rem)] data-[variant='primary']:bg-header",
        className,
      )}
      {...props}
    >
      {children}
    </header>
  );
}
