import { IonRadioGroup, IonRadio } from "@ionic/react";
import styled from "styled-components";
import { COLORS } from "@/data/colors";

interface RadioProps {
  selected: boolean;
}

export const Container = styled(IonRadioGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 375px) {
    gap: 16px;
  }
`;

export const Radio = styled.div<RadioProps>`
  display: flex;
  align-items: center;
  gap: 16px;

  label {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => (props.selected ? COLORS.PRIMARY : "#475569")};
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
`;

export const RadioButton = styled(IonRadio)`
  --border-radius: 50%;
  --inner-border-radius: 50%;
  --color: #94a3b8;
  --color-checked: ${COLORS.PRIMARY};
`;
