import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import illustrationEmpty from "@/assets/illustrations/empty.svg";

export function EmptyQuestions() {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col flex-wrap items-center justify-center gap-3">
      <img src={illustrationEmpty} alt="Celular com ícone de x no centro" />

      <div className="flex flex-col gap-2 text-center">
        <Text weight="medium" color="slate-700">
          {t("faq.Não encontramos questões")}
        </Text>

        <Text size="xs">{t("faq.Não foi possível encontrar questões na categoria")}</Text>
      </div>
    </div>
  );
}
