import { Container, Radio, RadioButton } from "./styles";

export type ItemRadioButton = {
  value: string;
  label: string;
};

interface RadioButtonsProps {
  values: ItemRadioButton[];
  selected: ItemRadioButton;
  onChange: (item: ItemRadioButton) => void;
}

export function RadioButtons({ values, selected, onChange }: RadioButtonsProps) {
  return (
    <Container
      value={selected?.value}
      onIonChange={(e) => {
        const value = values.find((item) => item.value === e.detail.value);
        if (value) {
          onChange(value);
        }
      }}
    >
      {values.map((radio) => (
        <Radio
          key={radio.value}
          selected={radio.value === selected?.value}
          onClick={() => onChange(radio)}
        >
          <RadioButton value={radio.value} mode="md"></RadioButton>
          <label>{radio.label}</label>
        </Radio>
      ))}
    </Container>
  );
}
