import { useMutation } from "@tanstack/react-query";
import { regiserUser } from "@/services/auth/register";

interface useRegisterParams {
  appId: number;
  name: string;
  email: string;
  cpf?: string;
  fone?: string;
  bairro?: number;
  password: string;
}

export function useRegisteUser() {
  return useMutation({
    mutationFn: (data: useRegisterParams) => {
      return regiserUser(data);
    },
  });
}
