import { Link } from "react-router-dom";
import { IonRippleEffect } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CalendarCheck, CaretRight } from "@phosphor-icons/react";

import { cn } from "@/lib/utils";
import { useAppSlug } from "@/store/useAppStore";

type CardQueuedAppointmentProps = {
  id: number;
  status: string;
  name: string;
};

const mapTitle: Record<string, string> = {
  chamado: "Chegou sua vez!",
  aguardando: "Você está na fila",
  indeferido: "Agendamento indeferido",
};

const mapMessage: Record<string, string> = {
  chamado: "Clique para realizar seu agendamento",
  aguardando: "Aguarde chegar sua vez para concluir",
  indeferido: "Clique para ver mais detalhes",
};

export function CardQueuedAppointment({ id, name, status }: CardQueuedAppointmentProps) {
  const slug = useAppSlug();

  return (
    <Link to={`/${slug}/agendamentos-fila/${id}`}>
      <div
        className={cn(
          "ion-activatable ripple-parent ion-activable flex items-center justify-between gap-4 rounded-md border-thin border-l-medium border-slate-200 p-3",
          {
            "border-l-primary-500": status === "chamado",
            "border-l-orange-500": status === "aguardando",
            "border-l-red-500": status === "indeferido",
          },
        )}
      >
        <div className="flex flex-col gap-1.5">
          <div className="flex flex-col">
            <Text color="slate-700">{mapTitle[status]}</Text>
            <Text size="xs">{name}</Text>
          </div>

          <div className="flex items-center gap-1.5">
            <CalendarCheck size={16} className="flex-shrink-0 text-primary-500" />
            <Text size="xs" color="slate-700">
              {mapMessage[status]}
            </Text>
          </div>
        </div>

        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-50">
          <CaretRight weight="bold" size={12} className="text-primary-500" />
        </div>

        <IonRippleEffect className="text-slate-100" />
      </div>
    </Link>
  );
}
