import { http } from "@/lib/axios";
import { Region } from "./types";

interface getRegionsParams {
  appId: number;
}

type RegionResponse = {
  id: number;
  titulo: string;
};

export async function getRegions({ appId }: getRegionsParams): Promise<Array<Region>> {
  const response = await http.get<Array<RegionResponse>>(`/v2/${appId}/bairros`);

  const regions = response.data.map((item) => ({
    id: item.id,
    name: item.titulo,
  }));

  return regions;
}
