import { UserCircle } from "@phosphor-icons/react";
import { Tag, Theme } from "../../proposals/hooks/use-proposals";
import { IonSkeletonText } from "@ionic/react";

type CardProposalProps = {
  title: string | null;
  creator: string | null;
  tags: Array<Tag>;
  themes: Array<Theme>;
};

export function CardProposal({ title, creator, tags, themes }: CardProposalProps) {
  return (
    <div className="flex flex-col rounded-md border border-slate-200 shadow-sm">
      <div className="flex flex-1 flex-col gap-2 border-b border-gray-200 px-4 py-5">
        {themes.length || tags.length ? (
          <div className="flex flex-wrap items-center gap-2">
            {tags.length
              ? tags.map((item) => (
                  <span
                    key={item.id}
                    className="rounded-xs bg-[#dbecfe] px-2 py-1 text-xs font-medium leading-tight text-primary-500 "
                  >
                    {item.title}
                  </span>
                ))
              : null}
            {themes.length
              ? themes.map((item) => (
                  <span
                    key={item.id}
                    className="rounded-xs bg-[#f1f5f9] px-2 py-1 text-xs font-medium leading-tight text-slate-700"
                  >
                    {item.title}
                  </span>
                ))
              : null}
          </div>
        ) : null}

        <strong className="m-0 line-clamp-5 text-sm font-normal leading-[160%] text-slate-700">
          {title}
        </strong>

        {creator ? (
          <div className="item flex w-fit gap-1.5 rounded-full border border-slate-200 px-2 py-1">
            <UserCircle />
            <span className="text-xs font-medium text-slate-700 first-letter:uppercase">
              {creator}
            </span>
          </div>
        ) : null}
      </div>

      {/* <footer className="flex items-center px-5 py-3.5">
        <p className="m-0 text-lg font-medium text-slate-700">
          {voters} <span className="text-xs font-normal"> {voters > 1 ? "VOTOS" : "VOTO"}</span>
        </p>
      </footer> */}
    </div>
  );
}

export function CardProposalSkeleton() {
  return (
    <div className="flex flex-col rounded-md border border-slate-200 shadow-sm">
      <div className="flex flex-1 flex-col gap-2 border-b border-gray-200 px-4 py-5">
        <div className="flex flex-wrap items-center gap-2">
          <IonSkeletonText animated style={{ height: 22, width: 80 }} />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
        </div>
      </div>

      {/* <footer className="flex items-center px-5 py-3.5">
        <IonSkeletonText animated style={{ width: 60 }} />
      </footer> */}
    </div>
  );
}
