import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { IframeTemplate } from "@/templates/old/IframeTemplate";
import { addTokenUrl } from "@/pages/old/Services/components/TabServiceList";
import { PageTitle } from "@/components/old/SideMenu";
import { getServicoCategoryById } from "@/services/old/servicos";
import { getContent } from "@/content/index";

interface ServiceParams {
  id: string;
  SLUG: string;
}

export function ServiceIframe() {
  const CONTENT = getContent();

  const params: ServiceParams = useParams();

  const { isLoading, data } = useQuery(
    ["getServicoCategoryById", params.id],
    () => getServicoCategoryById(params.id, params.SLUG),
    { cacheTime: 0 },
  );

  if (isLoading) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING} </PageTitle>;
  }

  return (
    <IframeTemplate
      src={data?.data.adicionarToken ? addTokenUrl(data?.data.integracao) : data?.data.integracao}
      name={data?.data.titulo || ""}
    />
  );
}
