import dayjs from "dayjs";

import { http } from "@/lib/axios";
import { IMessage } from ".";

interface IMessageRequest {
  id: number;
  created_at: string;
  interno: boolean;
  is_file: boolean;
  mensagem: string;
  time?: string;
  kind?: "file" | "image" | "info" | "change_state" | "default";
}

function convertTextWithAnchors(text: string): string {
  return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
}

export const getKind = (message: string, hasKind?: string) =>
  message.includes("Status do protocolo alterado para:")
    ? "change_state"
    : message.includes("Solicitação registrada com sucesso") ||
      message.includes("Você respondeu a pesquisa de satisfação")
    ? "info"
    : hasKind || "default";

export const formatData = (data: IMessageRequest): IMessage => ({
  id: data.id,
  created_at: data.created_at,
  is_file: data.is_file,
  kind: getKind(data.mensagem),
  message: data.is_file ? data.mensagem : convertTextWithAnchors(data.mensagem),
  support: data.interno,
  time: dayjs(data.created_at).format("HH:mm"),
});

const groupMessagesByDate = (messages: Array<IMessageRequest>) =>
  messages.reduce(
    (dateMessage, message) => {
      const key =
        dateMessage[message.created_at.split("T")[0]] ||
        (dateMessage[message.created_at.split("T")[0]] = []);
      key.push(formatData(message));
      return dateMessage;
    },
    {} as Record<string, Array<IMessage>>,
  );

interface IMessagesParams {
  appId: number;
  protocolId: number;
}

export async function getMessages({
  appId,
  protocolId,
}: IMessagesParams): Promise<Record<string, Array<IMessage>>> {
  const data = (await http.get(`v3/${appId}/protocolos/${protocolId}/mensagens`))
    .data as Array<IMessageRequest>;

  return groupMessagesByDate(data);
}
