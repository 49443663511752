import { useHistory, useParams } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";

import { ReactPortal, Modal, Button } from "@/components";
import { useAppSlug } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";

type ModalInfoAuthProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ModalInfoAuth({ isOpen, onClose }: ModalInfoAuthProps) {
  const { id } = useParams<{ id: string }>();
  const searchParams = useSearchParams();
  const navigation = useHistory();
  const slug = useAppSlug();

  function handleGoAuth() {
    navigation.replace(`/${slug}/auth`);
    onClose();
  }

  function handleGoScheduling() {
    searchParams.delete("finished");
    searchParams.delete("step");
    searchParams.append("step", "resource");

    navigation.push({
      pathname: `/${slug}/agendamentos/${id}/criar-agendamento`,
      search: searchParams.toString(),
    });

    onClose();
  }

  return (
    <ReactPortal>
      <Modal showModal={isOpen} onClose={onClose}>
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col items-center gap-2">
            <Text size="md" color="slate-700" weight="medium">
              Atenção!
            </Text>
            <Text color="slate-700" className="text-center">
              Sem autenticação, você não receberá notificações no aplicativo, apenas no email
              informado no momento do agendamento, e terá que consultar os agendamentos pelo CPF.
            </Text>
          </div>

          <footer className="flex w-full flex-col gap-3">
            <Button variant="secondary" size="lg" full onClick={handleGoAuth}>
              Entrar ou criar conta
            </Button>
            <Button size="lg" full onClick={handleGoScheduling}>
              Continuar
            </Button>
          </footer>
        </div>
      </Modal>
    </ReactPortal>
  );
}
