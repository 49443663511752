import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IonLoading } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { ChatNew } from "@/pages/old/NewProtocol/components/ChatNew";
import { getProtocolById } from "@/services/old/servicos";
import { STORAGE } from "@/data/storage";
import { getContent } from "@/content/index";

import { Container } from "./styles";

interface ProtocolParams {
  id: string;
  SLUG: string;
}

export function Chat() {
  const CONTENT = getContent();

  const params: ProtocolParams = useParams();

  const apiName = localStorage.getItem(STORAGE.SOCKET_CHANNEL.KEY);

  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState();
  const [blockSendMessage, setBlockSendMessage] = useState(false);
  const [failed, setFailed] = useState(false);
  const [allowSendFile, setAllowSendFile] = useState(false);

  useEffect(() => {
    getProtocolById(params.id, params.SLUG)
      .then((res: any) => {
        setAllowSendFile(res.data.categoriaServico.accept_file);
        setId(res.data.id);
        setBlockSendMessage(!res.data.replica);
        setIsLoading(false);
      })
      .catch(() => {
        setFailed(true);
      });
  }, []);

  if (isLoading) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  } else if (failed) {
    return (
      <PageTemplate name={CONTENT.PROTOCOLS.MESSAGES}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  }

  return (
    <Container>
      {id && (
        <ChatNew
          SLUG={params.SLUG}
          id={id}
          allow_file_upload={true || allowSendFile}
          api_name={apiName || ""}
          block_send_message={blockSendMessage}
          type={"protocol"}
        />
      )}
    </Container>
  );
}
