import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { cpf as cpfValidate } from "cpf-cnpj-validator";
import { IonLoading, IonInput, IonAlert, IonActionSheet } from "@ionic/react";
import { trash, share, close } from "ionicons/icons";
import styled from "styled-components";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageTitle } from "@/components/old/SideMenu";
import { PageLoading } from "@/components/old/PageLoading";
import { ErrorMessage } from "@/components/old/Base";
import { BlockForm } from "@/components/old/BlockForm";
import { GetNeighborhood, Neighborhood } from "@/components/old/GetNeighbordhood";
import {
  cancelarConta,
  getUserData,
  isLogged,
  loadUserData,
  logout,
  retriverPassword,
  updateUserData,
  updateUserImage,
} from "@/services/old/auth";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { useConfig } from "@/hooks/useConfig";
import { removeCaracteresNaoNumericos } from "@/utils/removeCaracteresNaoNumericos";
import { NewUploadImage } from "@/components/old/UploadImage/new";
import { Button } from "@astrolabe-ui/react";

const IonActionStyled = styled(IonActionSheet)`
  .action-sheet-group {
    background-color: white;
  }
`;

interface paramsPage {
  SLUG: string;
}

export function MyProfile() {
  const CONTENT = getContent();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [username, setUserName] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [cpfInput, setCpfInput] = useState("");
  const [userId, setUserId] = useState();
  const [loginError, setLoginError] = useState({ name: "", neighborhood: "", image: "", cpf: "" });
  const [form, setForm] = useState(new FormData());
  const [image, setImage] = useState({
    base64: "",
    file: { name: "" },
  });
  const [placeHolderImg, setPlaceHolderImg] = useState();
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood>();

  const [isOpenModal, setIsOpenModal] = useState("");
  const [showActionSheet, setShowActionSheet] = useState(false);

  const params: paramsPage = useParams();

  const showCPF = useConfig({
    label: "cpf.opcional",
    slug: params.SLUG,
  });

  function imageChangeHandle(data: any) {
    setImage(data);
  }

  useEffect(() => {
    setIsLoading(true);
    getUserData()
      .then((res) => {
        if (res.data.bairro) {
          setSelectedNeighborhood({
            id: res.data.bairro,
            title: "",
            lat: 0,
            lng: 0,
          });
        }
        setName(res.data.name);
        setUserId(res.data.id);
        setUserName(res.data.email);
        setPlaceHolderImg(res.data.imagem);

        if (res.data.cpf) {
          setCpf(res.data.cpf);
          setCpfInput(res.data.cpf);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  function handlerRegister() {
    let nameMsg = "";
    let wrongValue = false;
    let neighborhoodMsg = "";
    let cpfMsg = "";

    if (name.length === 0) {
      nameMsg = "O nome é obrigatório";
      wrongValue = true;
    }

    if (!!cpfInput && !cpfValidate.isValid(cpfInput)) {
      cpfMsg = "Informe um CPF válido";
      wrongValue = true;
    }

    if (!selectedNeighborhood) {
      neighborhoodMsg = CONTENT.LOCATION.ERROR_NEED_NEIGHBORHOOD;
      wrongValue = true;
    }

    setLoginError({ name: nameMsg, neighborhood: neighborhoodMsg, image: "", cpf: cpfMsg });

    if (wrongValue) {
      return;
    }

    setForm(new FormData());

    form.append("name", name);

    form.append("bairro", selectedNeighborhood?.id + "");

    if (cpfInput) {
      form.append("cpf", removeCaracteresNaoNumericos(cpfInput));
    }

    setShowLoadingDialog(true);
    if (image.base64) {
      const imgForm = new FormData();
      imgForm.append("imagem", b64toBlob(image.base64), image.file.name);
      updateUserImage(imgForm)
        .then(() => updateData())
        .catch((err) => {
          setShowLoadingDialog(false);
          setLoginError({
            image: err.response.data.error.imagem.join(" "),
            name: "",
            neighborhood: "",
            cpf: "",
          });
        });
    } else {
      updateData();
    }
  }

  function updateData() {
    updateUserData(form)
      .then(() => {
        loadUserData(params.SLUG).then(() => {
          setShowAlert(true);
          setShowLoadingDialog(false);
        });
      })
      .catch((res) => {
        setShowLoadingDialog(false);
        let nameMsg = "";

        if (res.data.error.name) {
          nameMsg = res.data.error.name;
        }

        setLoginError({ name: nameMsg, neighborhood: "", image: "", cpf: "" });
      });
  }

  function b64toBlob(data: string) {
    const block = data.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];

    const sliceSize = 512;

    const byteCharacters = atob(realData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  if (!isLogged()) {
    return (
      <PageTemplate name={CONTENT.USER.MY_PROFILE} hideHeaderUserButton={true}>
        <PageTitle>{CONTENT.GLOBAL.NEEDTOBELOGED}</PageTitle>
      </PageTemplate>
    );
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.USER.MY_PROFILE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      name={CONTENT.USER.MY_PROFILE}
      hideHeaderUserButton={true}
      profile={true}
      setShowActionSheet={(value: boolean) => setShowActionSheet(value)}
    >
      <BlockForm>
        <NewUploadImage
          onChange={imageChangeHandle}
          title={CONTENT.USER.PROFILE_IMAGE}
          placeHolderURL={placeHolderImg}
        />
        {loginError.image && <ErrorMessage>{loginError.image}</ErrorMessage>}

        <IonInput
          className="has-highlight-border"
          labelPlacement="stacked"
          fill="outline"
          name="email"
          value={username}
          label={`${CONTENT.USER.EMAIL}:`}
          placeholder="exemplo@grt8.com"
          disabled
        />

        {!!showCPF && (
          <>
            <IonInput
              className="has-highlight-border"
              labelPlacement="stacked"
              fill="outline"
              type="text"
              name="cpf"
              value={cpfInput}
              label="CPF:"
              placeholder="000.000.000-00"
              disabled={!!cpf}
              onIonInput={(e) => {
                if (e.target.value) {
                  setCpfInput(cpfValidate.format(e.target.value.toString()));
                }
              }}
            />
            {!!loginError.cpf && <ErrorMessage>{loginError.cpf}</ErrorMessage>}
          </>
        )}

        <IonInput
          className="has-highlight-border"
          labelPlacement="stacked"
          fill="outline"
          name="name"
          value={name}
          label={`${CONTENT.USER.NAME}:`}
          placeholder="Maria Silva"
          onIonInput={(e) => {
            if (e.detail.value) {
              setName(e.detail.value.toString());
            }
          }}
        />
        {loginError.name && <ErrorMessage>{loginError.name}</ErrorMessage>}

        <GetNeighborhood
          SLUG={params.SLUG}
          currentNeighborhood={selectedNeighborhood?.id}
          onValueChange={(n) => {
            setSelectedNeighborhood(n);
          }}
        />
        {loginError.neighborhood && <ErrorMessage>{loginError.neighborhood}</ErrorMessage>}

        <footer className="space-y-3">
          <Button full variant="primary" onClick={() => handlerRegister()}>
            {CONTENT.USER.PROFILE_UPDATE}
          </Button>

          <Button
            full
            variant="secondary"
            onClick={() => history.push(`/${getAppSlug()}/change-password`)}
          >
            {CONTENT.USER.CHANGE_PASSWORD}
          </Button>
        </footer>
      </BlockForm>

      <IonAlert
        isOpen={isOpenModal === "first"}
        onDidDismiss={() => (isOpenModal !== "second" ? setIsOpenModal("") : "")}
        cssClass="my-custom-class"
        header={CONTENT.USER.ALERT_START_CANCEL_ACCOUNT_PROCESS_TITLE}
        message={CONTENT.USER.ALERT_START_CANCEL_ACCOUNT_PROCESS}
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setIsOpenModal("");
            },
          },
          {
            text: "Confirmar",
            handler: () => {
              setIsOpenModal("second");
            },
          },
        ]}
      />

      <IonAlert
        isOpen={isOpenModal === "second"}
        onDidDismiss={() => setIsOpenModal("")}
        cssClass="my-custom-class"
        header={"Excluir conta!"}
        message="Confirme a operação inserindo a senha abaixo e depois clicando em confirmar!"
        inputs={[
          {
            name: "password",
            type: "password",
            placeholder: "Confirmar senha",
            cssClass: "specialClass",
            attributes: {
              inputmode: "text",
            },
          },
        ]}
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            handler: (data) => {
              cancelarConta(data.password, params.SLUG, userId).then(
                () => {
                  logout();
                  history.push("/" + getAppSlug() + "/home");
                },
                () => {
                  <ErrorMessage>{CONTENT.USER.CREDENTIALS_DONT_MATCH}</ErrorMessage>;
                },
              );
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
          history.replace("/" + getAppSlug() + "/home");
        }}
        header={CONTENT.USER.ALERT_USER_UPDATE_TITLE}
        message={CONTENT.USER.ALERT_USER_UPDATE_MSG}
        buttons={[CONTENT.USER.ALERT_USER_UPDATE_BT]}
      />
      <IonLoading isOpen={showLoadingDialog} message={CONTENT.ALERT_DIALOG.TITTLE} />
      <IonActionStyled
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass=""
        buttons={[
          {
            text: CONTENT.USER.CHANGE_PASSWORD,
            icon: share,
            handler: () => {
              retriverPassword(username, params.SLUG)
                .then(() => {
                  history.push("/" + getAppSlug() + "/change-password");
                })
                .catch();
            },
          },
          {
            text: CONTENT.USER.REQUEST_CANCEL_ACCOUNT,
            role: "destructive",
            icon: trash,
            handler: () => {
              setIsOpenModal("first");
            },
          },
          {
            text: "Cancelar",
            icon: close,
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      ></IonActionStyled>
    </PageTemplate>
  );
}
