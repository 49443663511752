import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IonText, IonSelectOption, IonSelect } from "@ionic/react";

import { getContent } from "@/content/index";
import { getNeighborhood } from "@/services/old/auth";

export type Neighborhood = {
  id: number;
  title: string;
  lat: number;
  lng: number;
};

interface NeighborhoodProps {
  SLUG: string;
  currentNeighborhood?: number;
  onValueChange?: (neighborHood: Neighborhood) => void;
}

interface NeighborhoodResponse {
  id: number;
  titulo: string;
  latitude: string;
  longitude: string;
}

export function GetNeighborhood({ SLUG, currentNeighborhood, onValueChange }: NeighborhoodProps) {
  const CONTENT = getContent();

  const [currentNeighborhoodId, setCurrentNeighborhoodId] = useState<number>(
    currentNeighborhood ?? 0,
  );

  const { isLoading, data: neighborhoods } = useQuery(
    ["neighborhoods"],
    getNeighborhoods,
    { staleTime: 10 * (60 * 1000) }, // 10 min
  );

  async function getNeighborhoods() {
    try {
      const response = await getNeighborhood(SLUG);

      const data = response.data;

      const neighborhoodsFormatted: Neighborhood[] = data.map((item: NeighborhoodResponse) => ({
        id: item.id,
        title: item.titulo,
        lat: item.latitude,
        lng: item.longitude,
      }));

      return neighborhoodsFormatted;
    } catch (error) {
      console.error(error);
    }
  }

  function select(neighborhoodId: number) {
    const neighborhood = neighborhoods?.find((item) => item.id === neighborhoodId);

    if (neighborhood) {
      setCurrentNeighborhoodId(neighborhood.id);

      if (onValueChange) {
        onValueChange(neighborhood);
      }
    }
  }

  return (
    <div>
      {isLoading && <IonText>{CONTENT.GLOBAL.LOADING}</IonText>}

      {!isLoading && (
        <IonSelect
          className="has-highlight-border"
          fill="outline"
          labelPlacement="stacked"
          mode="md"
          justify="start"
          value={currentNeighborhoodId}
          label={`${CONTENT.LOCATION.NEIGHBORHOOD}:`}
          placeholder={CONTENT.GLOBAL.SELECT}
          onIonChange={(event) => select(event.detail.value)}
        >
          {neighborhoods?.map((item) => (
            <IonSelectOption key={item.id} value={item.id}>
              {item.title}
            </IonSelectOption>
          ))}
        </IonSelect>
      )}
    </div>
  );
}
