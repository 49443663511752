import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

export function openFileInBrowser(urlFile: string) {
  const url =
    Capacitor.getPlatform() === "android"
      ? `https://docs.google.com/viewer?url=${encodeURIComponent(urlFile)}`
      : urlFile;

  Browser.open({
    url,
  });
}
