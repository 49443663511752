import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, useMap } from "react-leaflet";
import { IonModal } from "@ionic/react";
import { OutputView, Text, TextInput } from "@astrolabe-ui/react";
import {
  Copy,
  PencilSimple,
  QrCode,
  Table,
  File,
  ArrowRight,
  CaretLeft,
} from "@phosphor-icons/react";

import { Button } from "@/components/Button";
import { ProtocolDetail } from "@/services/protocols";
import { useAppStore } from "@/store/useAppStore";
import { http } from "@/lib/axios";
import { decodeFormatDate } from "@/utils/decodeFormatDate";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";

const Map = ({ lat, lng, popup }: { lat: number; lng: number; popup: string }) => {
  const map = useMap();

  useEffect(() => {
    map.flyTo({ lat, lng });
  }, [lat, lng, map]);

  return (
    <>
      <Marker
        position={{
          lat,
          lng,
        }}
      >
        <Popup maxWidth={192}>
          <Text asChild className="text-center font-medium text-slate-700" style={{ margin: 0 }}>
            {popup}
          </Text>
        </Popup>
      </Marker>
      <ZoomControl position="topright" />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </>
  );
};

export const Details = ({ protocol }: { protocol: ProtocolDetail }) => {
  const { t } = useTranslation();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const queryClient = useQueryClient();

  const [showZoom, setShowZoom] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [titlePrototocol, setTitleProtocol] = useState<string>();
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 });
  const [textAddressMap, setTextAddressMap] = useState({ title: "", subtitle: "" });

  const handleCopy = () => {
    window.navigator.clipboard.writeText(String(protocol.id));
    toast.success(t("general.Copiado com sucesso"));
  };

  function formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  const handleUpdateTitleProtocol = () => {
    http
      .post(`v3/${appId}/protocolos/${protocol.id}/updateTitle`, {
        _method: "put",
        title: titlePrototocol,
      })
      .then((res) => {
        console.log(res);
        toast.success(t("protocols.Protocolo editado com sucesso."));
        queryClient.invalidateQueries(["protocols", protocol.id]);
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("error.Falha ao atualizar o protocolo!"));
      });
  };

  return (
    <div className="flex flex-col gap-5 p-4 pb-6">
      <IonModal isOpen={showMapModal} onDidDismiss={() => setShowMapModal(false)}>
        <div className="relative flex h-full w-full flex-col">
          <button
            onClick={() => setShowMapModal(false)}
            className="absolute  left-4 top-4 z-50 rounded-full bg-white p-2 drop-shadow"
          >
            <CaretLeft className="text-primary-500" weight="bold" size={20} />
          </button>

          <MapContainer
            center={{
              lat: centerMap.lat,
              lng: centerMap.lng,
            }}
            zoom={60}
            className="z-10 grow"
            zoomControl={false}
          >
            <Map lat={centerMap.lat} lng={centerMap.lng} popup={t("location.Local do protocolo")} />
          </MapContainer>

          <div className="bottom-0 z-50 flex w-full flex-wrap justify-center rounded-lg bg-white p-5">
            <Text className="text-center text-slate-700">{textAddressMap.title}</Text>
            <Text className="text-center">{textAddressMap.subtitle}</Text>
          </div>
        </div>
      </IonModal>

      <div className="flex items-center gap-3">
        <QrCode className="text-lg text-slate-400" />
        <Text color="slate-700">{t("general.Protocolos")}</Text>
      </div>

      <OutputView
        heading={t("general.Título")}
        content={
          showEditTitle ? (
            <div className="flex flex-wrap gap-2">
              <TextInput
                defaultValue={protocol.title}
                onChange={(e) => setTitleProtocol(e.target.value)}
              />

              <div className="flex w-full justify-around">
                <Button
                  onClick={() => {
                    setShowEditTitle(false);
                    handleUpdateTitleProtocol();
                  }}
                >
                  {t("general.Editar")}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowEditTitle(false);
                  }}
                >
                  {t("general.Cancelar")}
                </Button>
              </div>
            </div>
          ) : (
            protocol.title
          )
        }
        titleButton={`${t("general.Editar título")}`}
        {...(!showEditTitle && {
          action: () => setShowEditTitle((prevState) => !prevState),
          iconButton: <PencilSimple className="text-primary-500" />,
        })}
      />

      <OutputView
        heading={t("general.Código ID")}
        content={String(protocol.id)}
        titleButton={`${t("general.Copiar ID")}`}
        action={handleCopy}
        iconButton={<Copy className="text-primary-500" />}
      />

      <OutputView
        heading={t("general.Data de criação")}
        content={decodeFormatDate(protocol.created_at)}
      />

      <OutputView
        heading={t("filters.Situação")}
        content={
          <div className="flex flex-wrap gap-2">
            <Text
              color={
                protocol?.status.title === "Em Aberto"
                  ? "primary-500"
                  : protocol?.status.title === "Encerrado"
                    ? "green-600"
                    : "orange-600"
              }
            >
              {protocol?.status?.title === "Em Atendimento" && protocol?.tagCategoryService?.title
                ? protocol?.tagCategoryService.title
                : protocol?.status.title}
            </Text>
          </div>
        }
      />

      <OutputView heading={t("filters.Serviço")} content={protocol.categoryService.title} />

      <OutputView heading={t("filters.Categoria")} content={protocol.category.title} />

      {protocol.fields.length ? (
        <>
          <div className="flex gap-2">
            <Table className="text-lg text-slate-400" />
            <Text color="slate-700" size="sm">
              {t("protocols.Dados do protocolo")}
            </Text>
          </div>

          {protocol.fields.map((field) => (
            <OutputView
              key={field.name}
              heading={field.name}
              content={
                typeof field.value === "object" ? (
                  field.value.textFormatted
                ) : field.type === "imagem" ? (
                  <img
                    src={`${field.value}`}
                    alt="image protocol"
                    className={cn("h-[100px] w-full animate-fadeIn rounded-sm object-cover", {
                      "h-full": showZoom,
                    })}
                  />
                ) : field.type === "arquivo" ? (
                  <Text
                    onClick={() =>
                      typeof field.value === "string" && window.open(field.value, "_blank")
                    }
                    className="flex items-center gap-1"
                    color="primary-500"
                  >
                    {t("general.Ver arquivo")}
                    <File size={16} />
                  </Text>
                ) : field.type === "data" ? (
                  formatDate(field.value)
                ) : (
                  field.value
                )
              }
              {...(field.type === "location" && {
                action: () => {
                  setShowMapModal(true);
                  if (typeof field.value === "object") {
                    setCenterMap({ lat: field.value.lat, lng: field.value.lng });
                    setTextAddressMap({
                      title: `${field.value.street} - ${field.value.number}`,
                      subtitle: `${field.value.neighborhood}, ${field.value.city} - ${field.value.uf}`,
                    });
                  }
                },
                titleButton: t("general.Ver no mapa") as string,
                iconButton: <ArrowRight className="text-primary-500" />,
              })}
              {...(field.type === "imagem" && {
                onClick: () => setShowZoom((prevState) => !prevState),
              })}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};
