import { IonSkeletonText } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { Text } from "@astrolabe-ui/react";

import { getGallery } from "@/services/ppa/get-gallery";

export function Gallery() {
  const { data, isLoading } = useQuery({
    queryKey: ["gallery_ppa"],
    queryFn: () => getGallery(),
    staleTime: 60 * 1000 * 5, // 5 minutos
  });

  return (
    <section className="mx-auto flex w-full max-w-6xl flex-col gap-4 bg-white py-6">
      <header className="flex w-full items-center justify-between px-4">
        <Text asChild color="slate-700" weight="medium" className="text-md lg:text-lg">
          <strong>Galeria de fotos</strong>
        </Text>

        {/* <button className="text-sm font-medium text-[#00629D]">Ver tudo</button> */}
      </header>

      <ul className="flex w-full gap-4 overflow-x-auto px-4">
        {data?.length && !isLoading
          ? data.map((item) => (
              <li key={item.id} className="w-full flex-none sm:w-1/3 md:w-1/4 lg:w-1/5">
                <img
                  src={item.image}
                  className="h-full w-full overflow-hidden rounded-md object-cover"
                  alt=""
                />
              </li>
            ))
          : null}

        {!data?.length && isLoading
          ? Array.from(Array(10).keys()).map((item) => (
              <li key={item} className="w-full flex-none sm:w-1/3 md:w-1/4 lg:w-1/5">
                <IonSkeletonText
                  animated
                  className="aspect-video h-full w-full overflow-hidden rounded-md"
                />
              </li>
            ))
          : null}
      </ul>
    </section>
  );
}
