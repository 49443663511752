import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetAtom } from "jotai";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft, X } from "@phosphor-icons/react";

import { Header, HeaderButton, Modal, ReactPortal, Button } from "@/components";
import { useSearchParams } from "@/hooks/useSearchParams";
import { scheduleCompleteAtom } from "../atoms/schedule-completion-atom";

export function HeaderScheduleComplete() {
  const searchParams = useSearchParams();
  const navigation = useHistory();

  const updateSchedulingCompletenData = useSetAtom(scheduleCompleteAtom);

  const [showModal, setShowModal] = useState(false);

  function handleGoBack() {
    navigation.goBack();
  }

  function handleFinished() {
    updateSchedulingCompletenData({
      entityId: null,
      resource: null,
      schedule: null,
      hour: null,
      local: null,
    });

    handleCloseModal();

    searchParams.delete("openModal");
    searchParams.delete("step");

    navigation.replace({ pathname: "", search: searchParams.toString() });
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <Header variant="secondary">
      <div className="flex w-full items-center justify-between">
        <HeaderButton
          variant="secondary"
          icon={<CaretLeft weight="bold" />}
          onClick={handleGoBack}
          disabled={searchParams.get("step") === "local"}
        />

        <HeaderButton variant="secondary" icon={<X weight="bold" />} onClick={handleOpenModal} />
      </div>

      <ReactPortal>
        <Modal showModal={showModal} onClose={handleCloseModal}>
          <div className="space-y-8">
            <div className="space-y-1 text-center">
              <Text size="md" weight="semibold" color="slate-700">
                Encerrar processo
              </Text>
              <Text>
                Caso deseje cancelar o processo de agendamento, clique em{" "}
                <strong className="font-medium">&apos;Encerrar&apos;</strong> nos botões abaixo.
              </Text>
            </div>

            <div className="space-y-2">
              <Button full size="lg" variant="tertiary" onClick={handleCloseModal}>
                Cancelar
              </Button>

              <Button full size="lg" onClick={handleFinished}>
                Encerrar
              </Button>
            </div>
          </div>
        </Modal>
      </ReactPortal>
    </Header>
  );
}
