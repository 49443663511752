import { http } from "@/lib/axios";

interface changePasswordParams {
  appId: number;
  username: string;
  password: string;
  token: string;
}

export function changePassword({ appId, username, password, token }: changePasswordParams) {
  return http.post(`v3/${appId}/recuperar_senha/alterar_senha`, {
    username,
    password,
    token,
  });
}
