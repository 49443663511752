import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IonLoading } from "@ionic/react";

import { BlockItemAreaList } from "../BlockItemAreaList";
import { getServicesV3 } from "@/services/old/servicos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

import { Content, ContentItem, NotContent } from "./styles";

interface paramsPage {
  SLUG: string;
}

export function TabAreaList() {
  const location = useLocation();
  const params: paramsPage = useParams();
  const CONTENT = getContent();

  const queryParams = new URLSearchParams(location.search);

  const { isLoading, data } = useQuery(["getservicos"], () =>
    getServicesV3({
      slug: params.SLUG,
      aggregatorOnly: queryParams.get("aggregator_only"),
      aggregatorExcept: queryParams.get("aggregator_except"),
    }),
  );

  if (isLoading || !data) {
    return <IonLoading isOpen={true} message={CONTENT.GLOBAL.LOADING} />;
  }

  if (!data || data?.data.length === 0) {
    return (
      <>
        <NotContent> {CONTENT.GLOBAL.NOCONTENT} </NotContent>
      </>
    );
  }

  return (
    <Content>
      {data?.data.map((area: any, index: number) => {
        return (
          <ContentItem key={index}>
            <BlockItemAreaList
              title={area.titulo}
              customImage={area.imagem}
              routerLink={`/${getAppSlug()}/area-services/${area.id}`}
            />
          </ContentItem>
        );
      })}
    </Content>
  );
}
