import { http } from "@/lib/axios";

import { NewsCategory } from "./types";

type getNewsCategorieHttpResponse = {
  id: number;
  nome: string;
};

export async function getNewsCategories(appId: number): Promise<Array<NewsCategory>> {
  const response = await http.get<Array<getNewsCategorieHttpResponse>>(
    `v3/${appId}/categoriaNoticias`,
  );

  const newsCategories = response.data.map((item) => ({
    categoryId: item.id,
    title: item.nome,
  }));

  return newsCategories;
}
