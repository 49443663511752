import { useInfiniteQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { getNews } from "@/services/news";
import { useDebounce } from "@/hooks/useDebounce";
import { filterAtom } from "../atoms/filterAtom";

interface useNewsParams {
  appId: number;
}

export function useNews({ appId }: useNewsParams) {
  const { query, categories } = useAtomValue(filterAtom);

  const queryDeffered = useDebounce(query, 700);

  const filters = {
    title: queryDeffered,
    categories,
  };

  return useInfiniteQuery({
    queryKey: ["news", filters],
    queryFn: ({ pageParam = 1 }) => getNews({ appId, filters, page: pageParam }),
    getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.currentPage + 1 : undefined),
    enabled: !!appId,
  });
}
