import dayjs from "dayjs";
import { Booking } from "@/services/assetsScheduling";
import { TFunction } from "i18next";

export const getFieldsBooking = (booking: Booking, t: TFunction<"translation", undefined>) => {
  const fields = [
    {
      label: t("booking.Local"),
      content: booking.espaco.unidade.titulo,
    },
    {
      label: t("booking.Espaço"),
      content: booking.espaco.nome,
    },
    {
      label: t("booking.CPF"),
      content: booking.documento,
    },
    {
      label: t("booking.Nome"),
      content: booking.nome_completo,
    },
    {
      label: t("booking.Telefone"),
      content: booking.telefone,
    },
    {
      label: t("booking.Justificativa"),
      content: booking.pauta,
    },
    {
      label: t("booking.Data"),
      content: dayjs(booking.hora_inicio).format("DD [de] MMM [de] YYYY"),
    },
    {
      label: t("booking.Hora"),
      content: `${dayjs(booking.hora_inicio).format("HH:mm")} às ${dayjs(booking.hora_fim).format(
        "HH:mm",
      )}`,
    },
  ];
  return fields;
};
