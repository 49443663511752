import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, cn, Text, IconButton } from "@astrolabe-ui/react";
import { File, FileDoc, FilePdf, Image, UploadSimple, Warning, X } from "@phosphor-icons/react";

import { Loading } from "@/components/Loading";
import { usePhotoGallery } from "@/hooks/usePhotoGallery";
import { useAppStore } from "@/store/useAppStore";
import { http } from "@/lib/axios";
import { Modal } from "@/components";

import { useFormContextProtocol } from "../../Protocol";

interface FileInputProps {
  id: string;
  name: string;
  required?: boolean;
  image?: boolean;
  hint: string | null;
  defaultValue?: string;
  onChange: (option: string) => void;
}

export const FileInput = ({
  id,
  name,
  hint,
  required = false,
  image = false,
  onChange,
  ...rest
}: FileInputProps) => {
  const { t } = useTranslation();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { watch, setValue, getValues } = useFormContext();

  const { setBlocked } = useFormContextProtocol();

  const { takePhoto } = usePhotoGallery();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageBlob, setImageBlob] = useState<string>();
  const [nameFile, setNameFile] = useState<string>();
  const [typeFile, setTypeFile] = useState<string>("");
  const [showZoom, setShowZoom] = useState(false);

  const file = watch(id);

  const iconFile = (type: string) => {
    switch (type) {
      case "application/pdf":
        return <FilePdf className="text-red-500 " size={20} />;
      case "text/plain":
        return <FileDoc className="text-blue-600 " size={20} />;
      default:
        return <File size={20} className="text-slate-400" />;
    }
  };

  const handleImage = async (mode: "camera" | "photos") => {
    setLoading(true);

    setBlocked(true);

    const photo = await takePhoto(mode);

    const formData = new FormData();

    if (photo) {
      formData.append("file", photo.file);
      http
        .post(`/v3/${appId}/upload/file`, formData)
        .then((res) => {
          setImageBlob(res.data.file_url);
          setValue(id, res.data.file_url);
          onChange(res.data.file_url);
        })
        .catch((error: any) => {
          console.log("ERROR =", error);
        })
        .finally(() => {
          setLoading(false);
          setBlocked(false);
        });
    } else {
      setLoading(false);
      setBlocked(false);
    }
    setShowModal(false);
  };

  const handleDeleteImage = () => {
    setImageBlob("");
    setValue(id, "");
    onChange("");
  };

  const handleZoomImage = () => {
    setShowZoom((prevState) => !prevState);
  };

  const handleFile = (fileList: FileList | null) => {
    setLoading(true);
    setBlocked(true);
    if (fileList) {
      const { type } = fileList[0];
      const formData = new FormData();
      formData.append("file", fileList[0]);
      http
        .post(`/v3/${appId}/upload/file`, formData)
        .then((res) => {
          setValue(id, res.data.file_url);
          onChange(res.data.file_url);
          setTypeFile(type);
          setNameFile(res.data.file_url);
        })
        .catch((error: any) => {
          console.log("ERROR handleFile =", error);
        })
        .finally(() => {
          setLoading(false);
          setBlocked(false);
        });
    }
  };

  const handleDeleteFile = () => {
    setValue(id, "");
    onChange("");
  };

  useEffect(() => {
    !image && rest.defaultValue && rest.defaultValue.length > 0 && setNameFile(rest.defaultValue);
    image && rest.defaultValue && rest.defaultValue.length > 0 && setImageBlob(rest.defaultValue);
  }, [id, image, rest.defaultValue, setValue]);

  return (
    <div
      {...(image && !imageBlob && { onClick: () => setShowModal(true) })}
      {...(!image &&
        file &&
        file.length > 0 && {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(getValues(id.toString()), "_blank");
          },
        })}
    >
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col items-center gap-3">
          <Button
            variant="secondary"
            type="button"
            size="lg"
            full
            className="border-slate-200"
            onClick={() => handleImage("camera")}
          >
            {t("profile.Usar câmera")}
          </Button>

          <Button
            variant="primary"
            type="button"
            size="lg"
            full
            onClick={() => handleImage("photos")}
          >
            {t("profile.Escolher na galeria")}
          </Button>
        </div>
      </Modal>

      <input
        type="file"
        hidden
        disabled={!image && file && file.length > 0}
        id={name}
        {...(image && { accept: "image/*" })}
        {...(!image && {
          onChange: (e) => handleFile(e.target.files),
        })}
      />

      <label
        {...(!image && { htmlFor: name })}
        className={cn(
          "flex w-full items-center justify-center rounded-md border-thin border-slate-200 p-4 font-sans text-xs text-slate-700",
          {
            "border-red-500": required,
          },
        )}
      >
        {loading ? (
          <Loading variant="secondary" />
        ) : image && imageBlob ? (
          <div className="flex w-full flex-wrap gap-2">
            <div className="flex w-full items-center justify-center gap-3">
              <Image className="text-md text-primary-500" alt="icon image" />
              <Text color="primary-500" size="xs">
                {name}
              </Text>
            </div>
            <div className="relative w-full">
              <img
                src={imageBlob}
                alt="image protocol"
                className={cn("h-[100px] w-full rounded-sm object-cover", {
                  "animate-fadeIn": image && imageBlob,
                  "h-full": showZoom,
                })}
              />
              <IconButton
                className="absolute right-1 top-1 bg-white p-1 text-slate-700"
                variant="tertiary"
                rounded="full"
                type="button"
                size="md"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.target.type !== "button" ? handleDeleteImage() : handleZoomImage();
                }}
                icon={<X />}
              />
            </div>
          </div>
        ) : (
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full flex-wrap items-center justify-center gap-2">
              {!image ? (
                <UploadSimple className={cn("text-md text-primary-500", {})} alt="icon file" />
              ) : (
                <Image className="text-md text-primary-500" alt="icon image" />
              )}
              <Text className={cn("text-center", {})} color="primary-500" size="xs">
                {name}
              </Text>
              {file && file.length > 0 && (
                <div className="flex w-full items-center gap-2 text-slate-700 ">
                  {iconFile(typeFile)}
                  <div className="w-full overflow-hidden">
                    <Text color="slate-700" className="truncate">
                      {nameFile}
                    </Text>
                  </div>
                  <IconButton
                    type="button"
                    className="right-1 top-1 p-1"
                    size="xs"
                    rounded="full"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteFile();
                    }}
                    icon={<X size={8} className="text-slate-400" />}
                  />
                </div>
              )}
            </div>
            {required && <Warning className={cn("mr-2 text-md text-red-500")} />}
          </div>
        )}
      </label>
      {required && (
        <p className={cn("mt-2 text-xs text-red-500")}>
          {t(`error.Campo obrigatório. Adicione ${image ? "uma imagem" : "um arquivo"}`)}
        </p>
      )}
      {!required && !!hint && (
        <Text asChild size="xs" color="slate-400" className="ml-1 mt-1">
          <p>{hint}</p>
        </Text>
      )}
    </div>
  );
};
