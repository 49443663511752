import { http } from "@/lib/axios";
import { getAppId } from "./provider";

export async function getInfoCategory(page: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/${APP_ID}/categoriaInformacoes?page=${page}&paginate=1`);
}

interface getInfoCategoryByIdProps {
  page?: number;
  category: string;
  search?: string;
  tag?: number | null;
}

/* export async function getInfoCategoryById(categoriaId: string,page: string){
  return http.get(`v2/`+APP_ID+`/informacoes?conditions=categoria:=:${categoriaId}&page=${page}&paginate=1`)
  //return http.get("v2/"+APP_ID+`/categoriaInformacoes?conditions=id:=:${categoriaId}&page=${page}&paginate=1`)
} */

export async function newGetInfoCategoryById(
  props: Omit<getInfoCategoryByIdProps, "page">,
  SLUG: string,
): Promise<Array<any>> {
  const APP_ID = await getAppId(SLUG);

  let url = `v2/` + APP_ID + `/informacoes?conditions=`;

  url = url + `categoria:=:${props.category}`;

  if (props.search && props.search.length > 0) {
    url = url + `;titulo:unaccent:${props.search}`;
  }

  if (props.tag !== undefined && props.tag !== null && props.tag >= 0) {
    url += `&tag=${props.tag}`;
  }

  const response = await http.get(url);

  return response.data;
}

export async function getInfoCategoryById(props: getInfoCategoryByIdProps, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  let url = `v2/` + APP_ID + `/informacoes?conditions=`;

  url = url + `categoria:=:${props.category}`;

  if (props.search && props.search.length > 0) {
    url = url + `;titulo:ilike:${props.search}`;
  }

  url = url + `&paginate=1&page=${props.page}`;

  if (props.tag !== undefined && props.tag !== null && props.tag >= 0) {
    url += `&tag=${props.tag}`;
  }

  return http.get(url);
}

export async function getInfoById(infoId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);
  return http.get(`v2/${APP_ID}/informacoes/${infoId}`);
}

export async function getTagsByCategoryId(id: number, SLUG: string) {
  const APP_ID = localStorage.getItem(`${SLUG}_ID_APP`);
  return http.get(`v2/${APP_ID}/tag?conditions=categoria_id:ilike:${id}`);
}
