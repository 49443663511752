import { useMutation, useQuery } from "@tanstack/react-query";

import { getHolder } from "@/services/holder";
import { createHolder } from "@/services/holder/createHolderReview";
import { alterUserViewedReviewHolder } from "@/services/holder/alterUserViewedReviewHolder";

interface IUseHolderParams {
  appId?: number;
  id?: number;
  staleTime?: number;
}

export type CreateReview = {
  id_titular: number;
  nome_social?: string;
  foto?: string;
  email?: string;
  telefone?: string;
};

export function useGetHolder({ appId, id, staleTime }: IUseHolderParams) {
  return useQuery({
    queryKey: ["get-holder", appId, id],
    queryFn: () => getHolder({ appId: Number(appId), titularId: Number(id) }),
    enabled: !!appId && !!id,
    retry: false,
    cacheTime: 0,
    ...(staleTime && { staleTime }),
  });
}

export function useCreateReviewHolder({ appId }: IUseHolderParams) {
  return useMutation({
    mutationFn: (payload: CreateReview) => createHolder({ appId: Number(appId), data: payload }),
  });
}

export function useAlterUserViewedReviewHolder({ appId, id }: { appId?: number; id: number }) {
  return useMutation({
    mutationFn: (idField?: number) =>
      alterUserViewedReviewHolder({ appId: Number(appId), id, idField }),
    retry: false,
  });
}
