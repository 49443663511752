import { Capacitor } from "@capacitor/core";

import { http } from "@/lib/axios";
import { getAppId } from "./provider";
import { getAccessToken, isLogged } from "./auth";
import { useAppStore } from "@/store/useAppStore";

export async function getServicos(SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    return http.get(`v2/` + APP_ID + `/areaCategoriaServicos`, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    });
  }

  return http.get(`v2/` + APP_ID + `/areaCategoriaServicos`);
}

interface IGetServicesParams {
  slug: string;
  aggregatorOnly: string | null;
  aggregatorExcept: string | null;
}

export async function getServicesV3({
  slug,
  aggregatorOnly,
  aggregatorExcept,
}: IGetServicesParams) {
  const appId = await getAppId(slug);

  return http.get(`v3/${appId}/areaCategoriaServicos`, {
    params: {
      ...(aggregatorOnly ? { aggregator_only: aggregatorOnly } : {}),
      ...(aggregatorExcept ? { aggregator_except: aggregatorExcept } : {}),
    },
    headers: {
      ...(isLogged() ? { Authorization: `Bearer ${getAccessToken()}` } : {}),
    },
  });
}

export async function getServicoCategoryById(categoryId: string, SLUG: string) {
  const APP_ID = await getAppId(SLUG);

  if (isLogged()) {
    return http.get(`v2/` + APP_ID + `/categoriaServicos/${categoryId}`, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + getAccessToken(),
      },
    });
  }

  return http.get(`v2/` + APP_ID + `/categoriaServicos/${categoryId}`);
}

export async function registProtocol(formData: any, SLUG: string, hideUser: any) {
  // if(!is_access_avalible()||!isLogged())
  //   return false;

  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  Capacitor.isNativePlatform()
    ? formData.append("origem", "mobile")
    : formData.append("origem", "web");

  if (hideUser) {
    return http
      .post("v2/" + APP_ID + "/protocolos/anonimo", formData, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  } else {
    return http
      .post("v2/" + APP_ID + "/protocolos", formData, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}

export async function getMyProtocols(SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http
    .get("v2/" + APP_ID + "/protocolos/meusProtocolos", headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function getProtocolById(categoryId: string, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const APP_ID = await getAppId(SLUG);

  const { app } = useAppStore.getState();

  const appId = APP_ID ?? app?.id;

  return http
    .get(`v2/` + appId + `/protocolos/${categoryId}`, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function getProtocolByIdV2(id: string, SLUG: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http.get(`v2/${APP_ID}/protocolos/${id}`, headers);
}

export async function getAreaCategoria(SLUG: string, id: string) {
  const headers = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  const APP_ID = await getAppId(SLUG);

  return http.get(`v2/${APP_ID}/areaCategoriaServicos/${id}`, headers);
}
