import { Text } from "@astrolabe-ui/react";
import { CalendarCheck, Clock } from "@phosphor-icons/react";

interface ChildEvent {
  id: number;
  title: string;
  date: string;
  hour: string;
  banner: string;
}

interface ScheduleCardProps {
  title: string;
  events: Array<ChildEvent>;
  handleOpenNews: (id: number) => void;
}

export function ScheduleCard({ title, events, handleOpenNews }: ScheduleCardProps) {
  return (
    <div className="flex flex-col gap-4">
      <Text size="lg" weight="medium" className="px-4 text-slate-700">
        {title}
      </Text>

      <div className="hide-scrollbar flex w-full items-center gap-3 overflow-x-auto px-4">
        {events.map((child) => (
          <div
            onClick={() => handleOpenNews(child.id)}
            key={child.id}
            className="flex max-w-[282px] flex-shrink-0 items-center gap-2 rounded-md border-medium border-slate-100 p-2"
          >
            <img src={child.banner} alt={child.title} width={112} className="rounded-md" />

            <div className="flex flex-col gap-2">
              <Text size="xs" weight="medium" className="line-clamp-2 text-slate-700">
                {child.title}
              </Text>

              <div className="flex gap-1">
                <CalendarCheck size={16} color="#2563EB" className="flex-shrink-0" />
                <Text size="xs" weight="normal" className="text-slate-500">
                  {child.date}
                </Text>
              </div>

              <div className="flex items-center gap-1">
                <Clock size={16} color="#2563EB" className="flex-shrink-0" />
                <Text size="xs" weight="normal" className="text-slate-500">
                  {child.hour}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
