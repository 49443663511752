import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export async function getAppIdentifier() {
  if (Capacitor.isNativePlatform()) {
    const appInfo = await App.getInfo();
    return appInfo.id;
  } else {
    const url = window.location.href.replace("https://", "").replace("http://", "");
    return url.split("/")[1];
  }
}
