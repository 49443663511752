export function dateToHour(date: string) {
  const mydate = new Date(date);
  return dateToHour2(mydate);
}

export function dateToHourFullDate(date: string) {
  const mydate = new Date(date);

  return (
    (mydate.getDate() < 10 ? "0" : "") +
    mydate.getDate() +
    "/" +
    (mydate.getMonth() + 1 < 10 ? "0" : "") +
    (mydate.getMonth() + 1) +
    "/" +
    mydate.getFullYear() +
    " - " +
    (mydate.getHours() < 10 ? "0" : "") +
    mydate.getHours() +
    ":" +
    (mydate.getMinutes() < 10 ? "0" : "") +
    mydate.getMinutes()
  );
}

export function dateToHour2(mydate: Date) {
  const today = new Date();

  if (
    mydate.getDay() === today.getDay() &&
    mydate.getMonth() === today.getMonth() &&
    mydate.getFullYear() === today.getFullYear()
  ) {
    return (
      (mydate.getHours() < 10 ? "0" : "") +
      mydate.getHours() +
      ":" +
      (mydate.getMinutes() < 10 ? "0" : "") +
      mydate.getMinutes()
    );
  } else {
    return (
      (mydate.getDate() < 10 ? "0" : "") +
      mydate.getDate() +
      "/" +
      (mydate.getMonth() + 1 < 10 ? "0" : "") +
      (mydate.getMonth() + 1) +
      "/" +
      mydate.getFullYear() +
      " - " +
      (mydate.getHours() < 10 ? "0" : "") +
      mydate.getHours() +
      ":" +
      (mydate.getMinutes() < 10 ? "0" : "") +
      mydate.getMinutes()
    );
  }
}
