export const totalVacanciesByService = (service: {
  nome: string;
  id: number;
  descricao: string;
  agendamentos: Array<{
    id: number;
    vagas_disponiveis: number;
    data_aplicacao: string;
    json_vagas_hora: string;
  }>;
}) => {
  const totalVacancies = service.agendamentos.reduce(
    (accumulator, current) => current.vagas_disponiveis + accumulator,
    0,
  );

  return totalVacancies;
};
