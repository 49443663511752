import { useQuery } from "@tanstack/react-query";
import { http } from "@/lib/axios";
import { getAccessToken } from "@/services/old/auth";
import { getAppId } from "@/services/old/provider";

export type Theme = {
  id: number;
  title: string;
};

export type Consult = {
  id: number;
  title: string | null;
  limitVoters: number;
  limitProposal: number;
  isExpired?: boolean;
  moderation: boolean;
  qtdVotersUser: number;
  themes: Array<Theme>;
};

interface GetConsultParams {
  SLUG: string;
  id?: number;
}

interface GetConsltResponse {
  id: number;
  titulo: string | null;
  data_encerramento: string | null;
  limite_voto: number;
  limite_proposta: number;
  moderacao: boolean;
  votos_usuario: number;
  temas: Array<{
    id: number;
    titulo: string;
  }>;
}

async function getConsult({ SLUG, id }: GetConsultParams): Promise<Consult> {
  try {
    const appId = await getAppId(SLUG);

    const response = await http.get<GetConsltResponse>(`v3/${appId}/consultas/${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    const data = response.data;

    const themes = data?.temas.map((item) => ({
      id: item.id,
      title: item.titulo,
    }));

    const isExpired = data.data_encerramento
        ? new Date(data.data_encerramento) < new Date()
        : false;

    return {
      id: data.id,
      title: data.titulo,
      limitProposal: data.limite_proposta,
      limitVoters: data.limite_voto,
      moderation: data.moderacao,
      qtdVotersUser: data.votos_usuario,
      isExpired,
      themes,
    };
  } catch (error) {
    console.error("Erro ao buscar consulta: ", error);
    throw error;
  }
}

export function useConsult({ SLUG, id }: GetConsultParams) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["consult", id],
    queryFn: () => getConsult({ SLUG, id }),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return {
    consult: data,
    isLoading,
    isError,
  };
}
