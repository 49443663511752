import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { IonModal } from "@ionic/react";
import { Button, Text, TextInput, cn } from "@astrolabe-ui/react";
import { CaretLeft, Compass, Crosshair, X } from "@phosphor-icons/react";

import { IconButton, Loading } from "@/components";
import { cities as citiesByJson } from "@/data/cities";
import { ufs } from "@/data/ufs";
import { formatCep } from "@/utils/formats";

import { SelectCity } from "./components/SelectCity";
import { Map } from "./Map";
import { IPopMessage, MapProvider } from "./context";

import mapIcon from "@/assets/illustrations/map.svg";

export interface IDisplayAddress {
  title: string;
  subtitle: string;
}

export interface ISchema {
  postcode: {
    required: boolean;
  };
  uf: {
    required: boolean;
  };
  city: {
    required: boolean;
  };
  street: {
    required: boolean;
  };
  neighborhood: {
    required: boolean;
  };
  number: {
    required: boolean;
  };
  complement: {
    required: boolean;
  };
  landmark: {
    required: boolean;
  };
}

export type IFormAddress =
  | {
      uf?: string;
      city?: string;
      neighborhood?: string;
      street?: string;
      number?: string;
      postcode?: string;
      complement?: string;
      landmark?: string;
      lat: number;
      lng: number;
    }
  | {
      uf: string;
      city: string;
      neighborhood: string;
      street: string;
      postcode: string;
      lat: number;
      lng: number;
      number?: string;
      complement?: string;
      landmark?: string;
    };

export type valueType = {
  address: string;
  lat: number;
  lng: number;
  city: string;
  uf: string;
  neighborhood: string;
  street: string;
  number?: string;
  postcode: string;
  complement?: string;
  landmark?: string;
};

export const LocationInput = ({
  name,
  hint,
  required = { value: false, message: "" },
  popMessage,
  getLocation,
  schema = {
    postcode: {
      required: false,
    },
    uf: {
      required: true,
    },
    neighborhood: {
      required: true,
    },
    city: {
      required: true,
    },
    street: {
      required: true,
    },
    number: {
      required: false,
    },
    complement: { required: false },
    landmark: { required: false },
  },
  wihtoutMapButton = false,
  onLoad,
  ...rest
}: {
  name?: string;
  hint: string | null;
  required?: { value: boolean; message: string };
  popMessage?: IPopMessage;
  getLocation: (value: valueType) => void;
  defaultValue?: valueType;
  removeClick?: () => void;
  schema?: ISchema;
  wihtoutMapButton?: boolean;
  onLoad?: () => void;
}) => {
  const { t } = useTranslation();

  const initialDataLocation = { lat: 0, lng: 0 };

  // Modalset
  const [showMapModal, setShowMapModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendByUser, setSendByUser] = useState(true);

  // Fields settings
  const [textLocation, setTextLocation] = useState<IDisplayAddress>();
  const [dataLocation, setDataLocation] = useState<IFormAddress>(initialDataLocation);

  // Fields
  const [cities, setCities] = useState<Array<string>>([]);

  useEffect(() => {
    const cityWithUf = citiesByJson.estados
      .map((uf) => uf.cidades.map((c) => `${c} - ${uf.sigla}`).flat())
      .flat();
    setCities(cityWithUf);
  }, [dataLocation.uf]);

  useEffect(() => {
    if (dataLocation.street && dataLocation.neighborhood && dataLocation.city && dataLocation.uf) {
      setTextLocation({
        title: `${dataLocation.street}, ${dataLocation.number ?? "s/n"}${
          dataLocation.complement ? `, ${dataLocation.complement}` : ""
        }`,
        subtitle: `${dataLocation.neighborhood}, ${dataLocation.city}${
          dataLocation.landmark ? `, ${dataLocation.landmark}` : ""
        }`,
      });
    }
  }, [dataLocation]);

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  useEffect(() => {
    rest.defaultValue && setDataLocation(rest.defaultValue);
  }, [rest.defaultValue]);

  async function handleOnChangeCep(value: string) {
    try {
      setDataLocation((prevState) => ({
        ...prevState,
        postcode: formatCep(value),
      }));

      if (value.length === 9) {
        const response = await axios(`https://viacep.com.br/ws/${value}/json`);

        const data = response.data;

        setDataLocation((prevState) => ({
          ...prevState,
          city: data.localidade,
          neighborhood: data.bairro,
          street: data.logradouro,
          uf: ufs.UF.find((uf) => uf.sigla === data.uf)?.sigla,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <IonModal isOpen={showMapModal} onDidDismiss={() => setShowMapModal(false)}>
        <div className="relative flex h-full w-full flex-col">
          <button
            onClick={() => {
              setShowMapModal(false);
              if (!rest.defaultValue && !sendByUser) {
                setDataLocation(initialDataLocation);
                setTextLocation(undefined);
              }
            }}
            className="absolute left-4 top-[calc(var(--safe-area-inset-top)+1rem)] z-50 rounded-full bg-white p-2 drop-shadow"
          >
            <CaretLeft className="text-primary-500" weight="bold" size={20} />
          </button>

          <MapProvider
            popMessage={popMessage}
            dataLocation={dataLocation}
            setDataLocation={setDataLocation}
            setTextLocation={setTextLocation}
            textLocation={textLocation}
            sendByUser={sendByUser}
            setSendByUser={setSendByUser}
            loading={loading}
            setLoading={setLoading}
          >
            <Map />
          </MapProvider>
          <div
            className={cn(
              "absolute bottom-0 z-50 flex w-full flex-wrap justify-center rounded-t-lg bg-white p-5 pb-[calc(var(--safe-area-inset-bottom)+1.25rem)]",
            )}
          >
            {loading ? (
              <Loading variant="secondary" />
            ) : (
              <>
                {textLocation ? (
                  <>
                    <div className="flex flex-col">
                      <Text className="text-center text-slate-700">{textLocation.title}</Text>
                      <Text className="text-center">{textLocation.subtitle}</Text>
                    </div>

                    <Button
                      className="mt-6 w-full disabled:bg-slate-400"
                      size="lg"
                      disabled={loading || !(dataLocation.lat && dataLocation.lng)}
                      type="button"
                      onClick={() => {
                        if (dataLocation.lat && dataLocation.lng) {
                          setShowFormModal(!sendByUser);
                          setShowMapModal(false);
                          sendByUser === true &&
                            getLocation({
                              address: `${textLocation.title} - ${textLocation.subtitle}`,
                              city: `${dataLocation.city}`,
                              lat: dataLocation.lat,
                              lng: dataLocation.lng,
                              neighborhood: `${dataLocation.neighborhood}`,
                              postcode: `${dataLocation.postcode ?? ""}`,
                              street: `${dataLocation.street}`,
                              uf: `${dataLocation.uf}`,
                              complement: dataLocation.complement,
                              number: dataLocation.number ?? "s/n",
                              landmark: dataLocation.landmark,
                            });
                        }
                      }}
                    >
                      {dataLocation.lat && dataLocation.lng
                        ? `${t("location.Confirmar localização")}`
                        : `${t("location.Selecione no mapa")}`}
                    </Button>
                  </>
                ) : (
                  <Text size="md" color="slate-700">
                    {t("location.Selecione no mapa")}
                  </Text>
                )}
              </>
            )}
          </div>
        </div>
      </IonModal>

      <IonModal isOpen={showFormModal} onDidDismiss={() => setShowFormModal(false)}>
        <div className="flex min-h-full flex-col overflow-auto px-4 pb-[calc(var(--safe-area-inset-bottom)+1.5rem)] pt-[calc(var(--safe-area-inset-top)+1rem)]">
          <div className="flex w-full items-center after:flex-1 after:content-['']">
            <div className="flex-1">
              <button
                onClick={() => {
                  setShowFormModal(false);
                  if (!rest.defaultValue) {
                    setDataLocation(initialDataLocation);
                    setTextLocation(undefined);
                  }
                }}
                type="button"
                className=" z-50 rounded-full bg-white p-2 drop-shadow "
              >
                <X className="text-lg text-primary-600" />
              </button>
            </div>

            <Text className="text-center font-medium text-slate-700">
              {name || t("general.Localização")}
            </Text>
          </div>

          {!wihtoutMapButton && (
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                setShowMapModal(true);
                setSendByUser(false);
              }}
              className="mt-6"
            >
              <Crosshair className="text-primary-500" />
              <span className="font-medium">{t("location.Aponte localização no mapa")}</span>
            </Button>
          )}

          <div className="my-4 px-4">
            <Text className="text-center text-slate-700">
              {dataLocation.street || "Rua"}, {dataLocation.number || "número"}
              {dataLocation.complement ? `, ${dataLocation.complement}` : null}
            </Text>
            <Text className="text-center">
              {dataLocation.neighborhood || "Bairro"}, {dataLocation.city || "Cidade"}
              {dataLocation.landmark ? `, ${dataLocation.landmark}` : null}
            </Text>
          </div>

          <div className="flex h-full flex-col gap-4">
            <TextInput
              floating
              placeholder={`${t("auth.CEP")}`}
              inputMode="numeric"
              value={dataLocation.postcode ?? ""}
              onChange={(e) => handleOnChangeCep(e.target.value)}
              {...(validate &&
                schema.postcode.required &&
                !dataLocation.postcode && { error: t("error.CEP é obrigatório.") as string })}
            />

            <SelectCity
              title="Selecione a cidade"
              cities={cities}
              selected={dataLocation.city ? `${dataLocation.city} - ${dataLocation.uf}` : undefined}
              {...(validate && !dataLocation.city && { error: t("error.Cidade é obrigatório") })}
              onChange={(value) => {
                setDataLocation((prevState) => ({
                  ...prevState,
                  city: value.split(" - ")[0],
                  uf: value.split(" - ")[1],
                }));
              }}
            />

            <TextInput
              floating
              placeholder={`${t("auth.Bairro")}`}
              value={dataLocation.neighborhood || ""}
              {...(validate &&
                !dataLocation.neighborhood && { error: t("error.Bairro é obrigatório") })}
              onChange={(e) =>
                setDataLocation((prevState) => ({ ...prevState, neighborhood: e.target.value }))
              }
            />

            <TextInput
              floating
              placeholder={`${t("auth.Rua")}`}
              value={dataLocation.street || ""}
              {...(validate && !dataLocation.street && { error: t("error.Rua é obrigatório") })}
              onChange={(e) =>
                setDataLocation((prevState) => ({ ...prevState, street: e.target.value }))
              }
            />

            <TextInput
              floating
              placeholder={`${t("auth.Número")}`}
              onChange={(e) =>
                setDataLocation((prevState) => ({ ...prevState, number: String(e.target.value) }))
              }
              value={dataLocation.number || ""}
              {...(validate &&
                schema.number.required &&
                !dataLocation.number && { error: t("error.Número é obrigatório") as string })}
            />

            <TextInput
              floating
              placeholder={`${t("auth.Complemento")}`}
              onChange={(e) =>
                setDataLocation((prevState) => ({ ...prevState, complement: e.target.value }))
              }
              value={dataLocation.complement || ""}
              {...(validate &&
                schema.complement.required &&
                !dataLocation.street && { error: t("error.Complemento é obrigatório") as string })}
            />

            <TextInput
              floating
              placeholder={"Ponto de referência"}
              onChange={(e) =>
                setDataLocation((prevState) => ({ ...prevState, landmark: e.target.value }))
              }
              value={dataLocation.landmark || ""}
              {...(validate &&
                schema.landmark.required &&
                !dataLocation.street && { error: "Ponto de referência é obrigatório" as string })}
            />

            <div className="mt-8 flex h-full w-full items-end">
              <Button
                full
                size="lg"
                type="button"
                onClick={() => {
                  const isInvalid =
                    (!dataLocation.city && schema.city.required) ||
                    (!dataLocation.neighborhood && schema.neighborhood.required) ||
                    (!dataLocation.street && schema.street.required) ||
                    (!dataLocation.uf && schema.uf.required) ||
                    (!dataLocation.postcode && schema.postcode.required) ||
                    (!dataLocation.number && schema.number.required) ||
                    (!dataLocation.complement && schema.complement.required) ||
                    (!dataLocation.landmark && schema.landmark.required);
                  setValidate(isInvalid);
                  if (!dataLocation.lat && !dataLocation.lng && !isInvalid) {
                    setSendByUser(true);
                    setShowMapModal(true);
                  } else if (!isInvalid && dataLocation.lat && dataLocation.lng) {
                    getLocation({
                      address: `${textLocation?.title} - ${textLocation?.subtitle}`,
                      city: `${dataLocation.city}`,
                      lat: dataLocation.lat,
                      lng: dataLocation.lng,
                      neighborhood: `${dataLocation.neighborhood}`,
                      postcode: `${dataLocation.postcode ?? ""}`,
                      street: `${dataLocation.street}`,
                      uf: `${dataLocation.uf}`,
                      complement: dataLocation.complement,
                      number: dataLocation.number ?? "s/n",
                      landmark: dataLocation.landmark,
                    });
                    setShowFormModal(false);
                  }
                }}
              >
                {t("location.Confirmar localização")}
              </Button>
            </div>
          </div>
        </div>
      </IonModal>

      <div
        onClick={() => setShowFormModal(true)}
        className={cn(
          "flex w-full flex-wrap items-center justify-center gap-2 rounded-md border-thin border-slate-200 p-4 font-sans text-xs text-primary-500",
          {
            "border-red-500": required.value,
          },
        )}
      >
        <div className="flex w-full justify-center">
          <Compass className="mr-2 text-md" /> <span>{name || t("general.Localização")}</span>
        </div>
        {textLocation && (
          <div className="flex w-full  gap-3">
            <img src={mapIcon} alt="map icon" />

            <div className="grow">
              <Text className=" text-slate-700">{textLocation.title}</Text>
              <Text className="">{textLocation.subtitle}</Text>
            </div>

            <IconButton
              className="bg-white text-slate-700"
              variant="tertiary"
              icon={<X />}
              rounded="full"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTextLocation(undefined);
                setDataLocation(initialDataLocation);
                rest.removeClick && rest.removeClick();
              }}
            />
          </div>
        )}
      </div>

      {required && required.value && (
        <span className="mt-1.5 inline-block text-xs text-red-500">{required.message}</span>
      )}

      {!required.value && !!hint && (
        <Text asChild size="xs" color="slate-400" className="ml-1 mt-1">
          <p>{hint}</p>
        </Text>
      )}
    </>
  );
};
