import { IonFab, IonFabButton, IonIcon, IonPage, IonContent } from "@ionic/react";
import { add } from "ionicons/icons";

import { PageContent } from "@/components/old/Base";
import { Header } from "@/components/old/Header";
import { getBackgroundColor } from "@/data/colors";

interface PageTemplateProps {
  name: string;
  headerIcon?: string;
  children: any;
  backPress?: any;
  hideHeaderUserButton?: boolean;
  showAddButton?: boolean;
  profile?: boolean;
  backgroundColor?: string;
  setShowActionSheet?: (value: boolean) => void;
  fabIcon?: any;
  onAddButtonClick?: () => void;
  onSearchTextChange?: (search: string) => void;
}

export function PageTemplate(props: PageTemplateProps) {
  return (
    <IonPage>
      <Header
        name={props.name}
        icon={props.headerIcon}
        backPress={props.backPress}
        onSearchTextChange={props.onSearchTextChange}
        setShowActionSheet={props.setShowActionSheet}
        hideUserButton={props.hideHeaderUserButton}
        profile={props.profile}
      />

      <IonContent
        fullscreen
        style={{
          "--background": props.backgroundColor ? props.backgroundColor : getBackgroundColor(),
        }}
      >
        <PageContent>{props.children}</PageContent>

        {props.showAddButton && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={props.onAddButtonClick}>
              <IonIcon icon={props.fabIcon ? props.fabIcon : add} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  );
}
