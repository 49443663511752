import React from "react";
import * as PhosphorIcon from "@phosphor-icons/react";

export type IconPhosphorProps = keyof Omit<typeof PhosphorIcon, "IconBase" | "IconContext" | "SSR">;

interface IconProps extends PhosphorIcon.IconProps {
  icon: IconPhosphorProps;
}

export function CustomIcon({ icon, ...rest }: IconProps) {
  return React.createElement(PhosphorIcon[icon], rest);
}
