import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { IconButton, Text } from "@astrolabe-ui/react";
import { X } from "@phosphor-icons/react";

import { Button } from "@/components";
import { getAppSlug } from "@/services/old/starter";
import { useBookingContext } from "@/pages/AssetsScheduling/contexts/BookingContext";
import { useStepsContext } from "@/pages/AssetsScheduling/contexts/StepsContext";
import { useInstitution } from "@/pages/AssetsScheduling/hooks/useInstitution";
import { useAppStore } from "@/store/useAppStore";
import { formatCpf } from "@/utils/formats";

import illustrationSuccess from "@/assets/illustrations/success.svg";

export const Success = ({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { handleGoTo, dataAssetsScheduling } = useStepsContext();
  const booking = useBookingContext();

  const appId = useAppStore((state) => state.app?.id);

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const { refetch } = useInstitution({ appId, id: Number(params.id) });

  const handleCodeCopy = () => {
    toast.success(t("booking.Código copiado"));
    navigator.clipboard.writeText(String(booking?.id));
  };

  return (
    <div className="flex grow flex-col items-center justify-between gap-2 p-4">
      <div className="flex justify-end self-end">
        <IconButton
          variant="outlined"
          icon={<X className="text-primary" />}
          rounded="full"
          className="print:hidden"
          onClick={() => {
            handleGoTo && handleGoTo(0);
            refetch();
            setIsOpen(false);
          }}
        />
      </div>

      <div className="flex grow flex-col items-center">
        <img
          src={illustrationSuccess}
          alt="Blocos com ícones de check."
          className="mb-14 max-w-[80%]"
        />

        <div className="flex flex-col gap-7">
          <Text weight="medium" size="md" color="slate-700" className="text-center">
            {t("booking.Agendamento realizado com sucesso")}
          </Text>

          <Text className="text-center">
            {`${t("booking.Você concluiu seu agendamento.")}
                  ${t("booking.Você pode imprimir seu comprovante, caso tenha interesse.")}`}
          </Text>

          <Text className="text-center">
            {t("booking.Agendamentos criados estarão sempre disponíveis pra consulta.")}
          </Text>

          <Text className="text-center">
            {t("booking.Nome")}: {booking?.nome_completo}
          </Text>

          <div className="flex flex-col items-center justify-center gap-1 p-1">
            <Text className="text-center">
              {t("booking.Nome")}: {dataAssetsScheduling?.nome_completo}
            </Text>

            <Text className="text-center">
              {t("booking.Email")}: {dataAssetsScheduling?.email}
            </Text>

            <Text className="text-center">
              {t("booking.CPF")}: {formatCpf(dataAssetsScheduling?.documento as string)}
            </Text>

            <Text className="text-center">{t("booking.Código")}</Text>
            <div
              className="flex gap-2 rounded-md border-thin border-slate-400 px-2 py-1"
              onClick={() => {
                handleCodeCopy();
              }}
            >
              {booking &&
                booking.id
                  .toString()
                  .split("")
                  .map((letter, index) => (
                    <Text key={index} className="text-center">
                      {letter}
                    </Text>
                  ))}
            </div>
          </div>
        </div>
      </div>

      <Button
        size="lg"
        className="print:hidden"
        full
        variant="tertiary"
        onClick={() => {
          booking &&
            history.replace(
              `/${getAppSlug()}/assets-scheduling/${booking.id}?espaco_id=${booking.espaco.id}`,
            );
        }}
      >
        {t("booking.Consultar agendamento")}
      </Button>

      <Button
        size="lg"
        className="print:hidden"
        full
        onClick={() => {
          window.print();
        }}
      >
        {t("booking.Imprimir comprovante")}
      </Button>
    </div>
  );
};
