import styled from "styled-components";
import { COLORS } from "@/data/colors";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const Icon = styled.div`
  margin: 0 auto;
  height: 56px;
  width: 56px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #dcfce7;
`;

export const Title = styled.h1`
  color: ${COLORS.PRIMARY};
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #6c7ba0;
  text-align: center;
  margin: 0;
`;

export const Button = styled.button`
  margin-top: 64px;
  background-color: ${COLORS.PRIMARY};
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  max-width: 297px;
  padding: 20px 24px;
  border-radius: 6px;
`;
