import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Text } from "@astrolabe-ui/react";
import { IonSkeletonText, IonContent } from "@ionic/react";
import { CaretLeft, NewspaperClipping } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import illustrationEmpty from "@/assets/illustrations/empty.svg";

import { Layout } from "@/templates/Layout";
import { Content } from "@/templates/Content";
import { HeaderButton, Header, InfiniteScroll } from "@/components";
import { CardNews, CardNewsSkeleton } from "./components/CardNews";
import { useAppStore } from "@/store/useAppStore";

import { useNews } from "./hooks/useNews";
import { FilterBar } from "./components/FilterBar";

export function ListNews() {
  const { t } = useTranslation();
  const history = useHistory();

  const app = useAppStore((state) => state.app);

  if (!app) {
    throw new Error("Not loaded app");
  }

  const appId = app.id;
  const slug = app.slug;

  const { data, isLoading, hasNextPage, fetchNextPage } = useNews({
    appId,
  });

  async function handleFetchNextPage() {
    await fetchNextPage();
  }

  function handleOpenNews(id: number) {
    history.push(`/${slug}/news/${id}`);
  }

  function handleGoBack() {
    history.replace(`/${slug}`);
  }

  return (
    <Layout>
      <Header>
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton icon={<CaretLeft weight="bold" />} onClick={handleGoBack} />
          </div>

          <Text size="md" weight="medium" leading="relaxed" className="text-textHeader">
            {t("news.Notícias")}
          </Text>
        </div>
      </Header>

      <IonContent>
        <Content>
          <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-4 px-4 py-6 ios:pb-2">
            <FilterBar />

            <div className="flex flex-1 flex-col gap-4">
              {!data?.pages[0].data.length && isLoading ? <LoadingListNews /> : null}

              {!data?.pages[0].data.length && !isLoading ? <EmptyListNews /> : null}

              {data?.pages[0].data.length ? (
                <div className="flex flex-col gap-4">
                  <header className="flex items-center gap-2">
                    <NewspaperClipping size={20} className="text-slate-400" />
                    <Text size="md" color="slate-700">
                      {data.pages[0]?.total} {t("news.notícia(s)")}
                    </Text>
                  </header>

                  <InfiniteScroll hasNextPage={!!hasNextPage} fetchNextPage={handleFetchNextPage}>
                    {data.pages.map((page, index) => (
                      <Fragment key={index}>
                        {page.data.map((news) => (
                          <CardNews
                            key={news.id}
                            title={news.title}
                            category={news.category}
                            categories={news.categories}
                            imageUrl={news.imageUrl}
                            like={news.like}
                            time={news.time}
                            handleOnClick={() => handleOpenNews(news.id)}
                          />
                        ))}
                      </Fragment>
                    ))}
                  </InfiniteScroll>
                </div>
              ) : null}
            </div>
          </div>
        </Content>
      </IonContent>
    </Layout>
  );
}

function LoadingListNews() {
  return (
    <div className="flex flex-col gap-4">
      <header className="flex items-center gap-2">
        <IonSkeletonText animated className="h-5 w-5" />
        <IonSkeletonText animated className="w-1/4 leading-relaxed" />
      </header>

      {Array.from(Array(3).keys()).map((item) => (
        <div key={item} className="py-4">
          <CardNewsSkeleton />
        </div>
      ))}
    </div>
  );
}

function EmptyListNews() {
  const { t } = useTranslation();

  return (
    <div className="w-ful flex h-full flex-col items-center justify-center gap-6 px-4 text-center">
      <img src={illustrationEmpty} alt="" />
      <div className="flex flex-col gap-2">
        <Text weight="medium" color="slate-700">
          {t("news.Não possuímos notícias")}
        </Text>
        <Text>{t("news.Você será notificado quando tivermos novas notícias")}</Text>
      </div>
    </div>
  );
}
