import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import { COLORS } from "@/data/colors";

export const Content = styled.div`
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  padding: 12px 20px 20px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-size: 14px;
  color: #334155;
`;

export const Title = styled.label`
  font-weight: 500;
  margin-bottom: 8px;
`;

export const ContentInputButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  font-size: 12px;
  color: #94a3b8;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #fff;

  &:focus {
    outline: none;
    border: 1px solid #94a3b8 !important;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;

export const ButtonSearch = styled.button`
  width: 42px;
  height: 42px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonReset = styled.button`
  width: 42px;
  height: 42px;
  background-color: #dc2626;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(IonIcon)`
  font-size: 16px;
  color: #ffffff;
`;

export const IconClose = styled(IonIcon)`
  font-size: 22px;
  color: #ffffff;
`;
