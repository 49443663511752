import styled, { css } from "styled-components";
import { IonButton, IonRouterLink, IonIcon, IonRippleEffect } from "@ionic/react";
import { documentTextOutline, chevronDownCircleOutline } from "ionicons/icons";

import { getContent } from "@/content/index";
import { dateToHour } from "@/services/old/functions";
import { Block } from "@/components/old/Block";

const Container = styled(IonRouterLink)`
  display: block;
  margin-bottom: 10px;
`;

const Content = styled.div`
  padding: 8px;
`;

const ButtonDiv = styled.div`
  width: 100%;
  text-align: end;
  justify-content: end;
`;

interface FormDescriptionProps {
  expandLine?: boolean;
}

const FormDescription = styled.p<FormDescriptionProps>`
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${(props) =>
    props.expandLine &&
    css`
      -webkit-line-clamp: 2;
    `}
`;

const FormDate = styled.p`
  width: 100%;
  font-size: 14px;
  opacity: 0.7;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 3px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface BlockFormItemProps {
  openURL?: string;
  title: string;
  description?: string;
  answered: boolean;
  dateStart: string;
  dateEnd?: string;
  hideButton?: boolean;
  expandLine?: boolean;
}

export function BlockFormItem(props: BlockFormItemProps) {
  const CONTENT = getContent();
  return (
    <Container routerLink={props.openURL} color={"dark"}>
      <Block noPadding title={props.title}>
        <Content>
          <FormDate>
            {dateToHour(props.dateStart)}
            {props.dateEnd && " à " + dateToHour(props.dateEnd)}
          </FormDate>

          <FormDescription expandLine={!props.expandLine}>{props.description}</FormDescription>

          {!props.hideButton && (
            <ButtonDiv>
              <IonButton color={props.answered ? "success" : "primary"}>
                <IonIcon
                  style={{ marginRight: 7 }}
                  icon={props.answered ? chevronDownCircleOutline : documentTextOutline}
                />
                {props.answered ? CONTENT.FORM.ANSWERED : CONTENT.FORM.ANSWER}
              </IonButton>
            </ButtonDiv>
          )}
        </Content>

        {props.openURL && <IonRippleEffect type="bounded" />}
      </Block>
    </Container>
  );
}
