import styled, { css } from "styled-components";
import { COLORS } from "@/data/colors";

interface InputWrapperProps {
  isFocused?: boolean;
  isValid?: boolean;
  isErrored?: boolean;
}

export const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #475569;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;
  margin-top: 4px;
  padding: 16px;

  display: flex;
  align-items: center;

  border-radius: 6px;
  border: 2px solid #cbd5e1;
  background-color: transparent;

  cursor: default;

  &:focus {
    border-color: ${COLORS.PRIMARY};
    outline: none;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #dc2626;
    `}

  ${(props) =>
    props.isValid &&
    css`
      border-color: #00a650;
    `}

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  input {
    flex: 1;
    height: 100%;
    background-color: transparent;
    border: 0;

    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #1e293b;

    &:focus {
      outline: none;
    }

    ::-webkit-input-placeholder {
      color: #94a3b8;
    }
    :-moz-placeholder {
      color: #94a3b8;
      opacity: 1;
    }
    ::-moz-placeholder {
      color: #94a3b8;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: #94a3b8;
    }
    ::-ms-input-placeholder {
      color: #94a3b8;
    }
    ::placeholder {
      color: #94a3b8;
    }
  }

  svg {
    margin-left: 16px;
  }
`;

export const ErrorMessage = styled.span`
  margin-top: 6px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #dc2626;
`;
