import { useQuery } from "@tanstack/react-query";

import { getAppId } from "@/services/old/provider";
import { http } from "@/lib/axios";
import { getAccessToken } from "@/services/old/auth";
import { Theme } from "../../proposals/hooks/use-proposals";

interface GetThemesParams {
  SLUG: string;
  idConsult: number;
}

async function getThemes({ SLUG, idConsult }: GetThemesParams): Promise<Array<Theme>> {
  const appId = await getAppId(SLUG);

  const response = await http.get(`/v3/${appId}/consultas/${idConsult}/temas`, {
    headers: {
      Authorization: `Beader ${getAccessToken()}`,
    },
  });

  const data = response?.data.map((item: any) => ({
    id: item.id,
    title: item.titulo,
  }));

  return data;
}

export function useThemes({ SLUG, idConsult }: GetThemesParams) {
  const {
    data: themes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["themes", idConsult],
    queryFn: () => getThemes({ SLUG, idConsult }),
    refetchOnWindowFocus: false,
    enabled: !!idConsult,
  });

  return {
    themes,
    isLoading,
    isError,
  };
}
