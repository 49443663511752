import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";

import { BlockItemImage } from "@/components/old/BlockItemImage";
import { PageTitle } from "@/components/old/SideMenu";
import { getAccessToken } from "@/services/old/auth";
import { getServicos } from "@/services/old/servicos";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";

interface paramsPage {
  SLUG: string;
}

export function addTokenUrl(url: string) {
  const token = getAccessToken();

  if (token) {
    return `${url}${url.includes("?") ? "&" : "?"}token=${token}`;
  }

  return url;
}

export function TabServiceListExpandImages() {
  const CONTENT = getContent();

  const params: paramsPage = useParams();

  const { isLoading, data } = useQuery(["getservicos"], () => getServicos(params.SLUG), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  if (isLoading || !data) {
    return <PageTitle>{CONTENT.GLOBAL.LOADING}</PageTitle>;
  }

  if (!data || data?.data.length === 0) {
    return (
      <>
        <PageTitle> {CONTENT.GLOBAL.NOCONTENT} </PageTitle>
      </>
    );
  }

  return (
    <>
      {/* <PageTitle> {CONTENT.SERVICES.PAGE_TITLE} </PageTitle> */}
      <IonGrid style={{ width: "100%", paddingBottom: "16px" }}>
        <IonAccordionGroup expand="inset">
          {data?.data.map((area: any, index: number) => {
            return (
              <div key={index}>
                <IonAccordion
                  value={area.titulo}
                  style={{
                    marginBottom: "20px",
                    borderRadius: "6px",
                    // backgroundColor: "white"
                  }}
                >
                  <IonItem
                    slot="header"
                    color="transparent"
                    lines="none"
                    style={{
                      backgroundColor: "white",
                      borderBottom: "none",
                    }}
                  >
                    {area.imagem ? (
                      <IonThumbnail slot="start">
                        <img alt="Silhouette of mountains" src={area.imagem} />{" "}
                      </IonThumbnail>
                    ) : null}
                    <IonLabel>{area.titulo}</IonLabel>
                  </IonItem>
                  <div
                    className="ion-padding"
                    slot="content"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#ffffff70",
                      borderRadius: "0px 0px 6px 6px",
                    }}
                  >
                    <IonRow style={{ padding: "0 8px" }}>
                      {area.categorias?.map((categoriaItem: any, categoriaIndex: number) => {
                        return (
                          <IonCol
                            size={area.categorias.length === 1 ? "12" : "6"}
                            key={categoriaIndex}
                          >
                            {categoriaItem.integracao && categoriaItem.abrir_externo && (
                              <BlockItemImage
                                openNewWindow={true}
                                imageUrl={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                                href={
                                  categoriaItem.adicionarToken
                                    ? addTokenUrl(categoriaItem.integracao)
                                    : categoriaItem.integracao
                                }
                              />
                            )}
                            {categoriaItem.integracao && !categoriaItem.abrir_externo && (
                              <BlockItemImage
                                routerLink={`/${getAppSlug()}/services-abrir-externo/${
                                  categoriaItem.id
                                }`}
                                imageUrl={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                              />
                            )}
                            {!categoriaItem.integracao && !categoriaItem.abrir_externo && (
                              <BlockItemImage
                                routerLink={`/${getAppSlug()}/services-abrir/${categoriaItem.id}`}
                                imageUrl={categoriaItem.imagem}
                                title={categoriaItem.titulo}
                              />
                            )}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                  </div>
                </IonAccordion>
              </div>
            );
          })}
        </IonAccordionGroup>
      </IonGrid>
    </>
  );
}
