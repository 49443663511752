import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";

import { PageTemplate } from "@/templates/old/PageTemplate";
import { PageLoading } from "@/components/old/PageLoading";
import { PageTitle } from "@/components/old/SideMenu";
import { BlockMapWithMarkers } from "@/pages/old/Infos/components/BlockMapWithMarkers";
import { BlockItemImage, Tag } from "@/components/old/BlockItemImage";
import { getTagsByCategoryId, newGetInfoCategoryById } from "@/services/old/infos";
import { getContent } from "@/content/index";
import { checkURLIsFile } from "@/utils/checkURLIsFile";
import { openFileInBrowser } from "@/utils/openFileInBrowser";
import { getFormatFile } from "@/utils/getFormatFile";

const Filters = styled(IonItem)`
  --padding-start: none;
  border-radius: 4px;
  margin-top: 6px;
`;

const Label = styled(IonLabel)`
  margin-left: 12px;
`;

interface OpenInfoParams {
  id: number;
  title: string;
  url?: string;
  external: boolean;
}

interface PostPageParams {
  id: string;
  SLUG: string;
}

export function InfoCategoryOpen() {
  const history = useHistory();
  const CONTENT = getContent();

  const params: PostPageParams = useParams();

  const [currentSearchText, setCurrentSearchText] = useState("");
  const [filterTag, setFilterTag] = useState<number | null>(null);

  const { isLoading, data } = useQuery(
    ["getInfoCategory", params.id, currentSearchText, filterTag],
    () =>
      newGetInfoCategoryById(
        {
          category: params.id,
          search: currentSearchText,
          tag: filterTag,
        },
        params.SLUG,
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setShowMap((data && data[0]?.categoria.exibirmapa) ?? null);
  }, [data]);

  const [showMap, setShowMap] = useState<null | boolean>(null);

  const { data: tags } = useQuery(["tags", params.id], () =>
    getTagsByCategoryId(Number(params.id), params.SLUG),
  );

  function makeSearch(search: string) {
    if (search !== currentSearchText && search.length > 0) {
      setCurrentSearchText(search);
    }
  }

  function clearSearch() {
    setCurrentSearchText("");
  }

  function handleOpenInfo({ id, title, url, external }: OpenInfoParams) {
    if (!url) {
      history.push(`/${params.SLUG}/info-abrir/${id}`);
    }

    if (url) {
      if (external) {
        window.open(url, "_blank");
        return;
      }

      const isFile = checkURLIsFile(url);
      const format = getFormatFile(url);
      const isMedia = ["mp3", "ogg", "wav", "aac", "webm"].includes(String(format));

      if (isFile && isMedia) {
        history.push(`/${params.SLUG}/info-media/${id}`);
        return;
      }

      if (isFile && format !== "php" && format !== "html") {
        openFileInBrowser(url);
        return;
      }

      history.push(
        `/${params.SLUG}/abrir?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
      );
    }
  }

  if (isLoading) {
    return (
      <PageTemplate name={CONTENT.INFORMATION.HEADER_TITLE}>
        <PageLoading description={CONTENT.GLOBAL.LOADING} />
      </PageTemplate>
    );
  }

  if (!data?.length) {
    return (
      <PageTemplate name={CONTENT.INFORMATION.HEADER_TITLE}>
        <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate
      name={
        currentSearchText.length > 0
          ? currentSearchText
          : data[0]?.categoria.titulo
          ? data[0].categoria.titulo
          : CONTENT.INFORMATION.HEADER_TITLE
      }
      backPress={currentSearchText.length > 0 ? clearSearch : null}
      onSearchTextChange={(search) => {
        makeSearch(search);
      }}
    >
      {showMap !== null && showMap && (
        <>
          <BlockMapWithMarkers
            showUserLocation={true}
            markers={data
              ?.filter((item: any) => item.latitude && item.longitude)
              .map((item: any) => ({
                id: item.id,
                lat: item.latitude,
                lng: item.longitude,
                img: item.imagem,
                label: item.titulo,
                icon: item.icon,
                href: `/info-abrir/${item.id}`,
              }))}
          />

          <IonButton
            color="primary"
            className="ion-no-padding mt-4"
            onClick={() => setShowMap(false)}
            expand="block"
          >
            Ver listagem
          </IonButton>
        </>
      )}

      {showMap !== null && !showMap && (
        <>
          {data[0].categoria.tags.length > 0 && (
            <Filters>
              <Label>{CONTENT.INFORMATION.FILTER}</Label>
              <IonSelect
                placeholder="Tag"
                value={filterTag}
                onIonChange={(e) => {
                  if (e.detail.value >= 0 || e.detail.value === null) {
                    setFilterTag(e.detail.value);
                  }
                }}
              >
                <IonSelectOption value={null}>Todas</IonSelectOption>
                {tags?.data.map((tag: Tag) => (
                  <IonSelectOption key={tag.id} value={tag.id}>
                    {tag.nome}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </Filters>
          )}

          {!data || data?.length === 0 ? (
            <div>
              <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
            </div>
          ) : (
            <>
              {data?.map((item: any, index: number) => {
                return (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    <div
                      onClick={() =>
                        handleOpenInfo({
                          id: item.id,
                          title: item.titulo,
                          url: item.link_servico,
                          external: item.abrir_externo,
                        })
                      }
                    >
                      <BlockItemImage
                        title={item.titulo}
                        discription={item.descricao}
                        tags={item.tags}
                        imageUrl={item.imagem}
                        // routerLink={`/${getAppSlug()}/info-abrir/${item.id}`}
                      />
                    </div>
                  </div>
                );
              })}

              <IonButton
                color="primary"
                className="ion-no-padding mt-4"
                onClick={() => setShowMap(true)}
                expand="block"
              >
                Ver mapa
              </IonButton>
            </>
          )}
        </>
      )}

      {(!data || data.length === 0) && !tags && (
        <div>
          <PageTitle>{CONTENT.GLOBAL.NOCONTENT}</PageTitle>
        </div>
      )}
    </PageTemplate>
  );
}
