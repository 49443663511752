import { http } from "@/lib/axios";

type SubscribeParams = {
  appId?: number;
  eventId: number;
};

export async function subscribeToEvent({ appId, eventId }: SubscribeParams) {
  try {
    await http.post(`v2/${appId}/eventos/inscricao`, {
      evento_id: eventId,
    });

    return { registered: true };
  } catch (error) {
    console.error("Erro ao inscrever no evento:", error);
    throw error;
  }
}

export async function unsubscribeFromEvent({ appId, eventId }: SubscribeParams) {
  try {
    await http.delete(`v2/${appId}/eventos/inscricao/cancelar`, {
      data: {
        evento_id: eventId,
      },
    });

    return { registered: false };
  } catch (error) {
    console.error("Erro ao cancelar inscrição no evento:", error);
    throw error;
  }
}
