import { create } from "zustand";

export type Version = {
  url: string;
  version: string;
};

export type TermsOfUse = {
  url: string;
  version: string;
};

export type UF = {
  name: string;
  id: number;
  slug: string;
};

export type City = {
  name: string;
  id: number;
};

export type Location = {
  latitude: string | null;
  longitude: string | null;
  uf: UF | null;
  city: City | null;
};

export type App = {
  id: number;
  name: string;
  slug: string;
  urlPlaystore: string | null;
  urlAppstore: string | null;
  language: string | null;
  maintenance: boolean;
  colors: {
    primary: string | null;
    secondary: string | null;
    header: string | null;
    textHeader: string | null;
    buttons: string | null;
    background: string | null;
  };
  images: {
    splash: string;
    logo: string;
    logoHeader: string;
  };
  socketChannel: string | null;
  oneSignalAppId: string | null;
  oneSignalRest: string | null;
  location: Location | null;
  termsOfUse: TermsOfUse | null;
  version: Version | null;
};

interface IUseAppStoreParams {
  app: App | null;
  updateApp: (app: App) => void;
}

export const useAppStore = create<IUseAppStoreParams>((set, _) => {
  return {
    app: null,
    updateApp: (app: App) => {
      set({ app });
    },
  };
});

export const useAppId = () => useAppStore((state) => state.app?.id);
export const useAppSlug = () => useAppStore((state) => state.app?.slug);
