import { TabTemplate } from "@/templates/old/TabTemplate";
import { getContent } from "@/content/index";

import { TabServiceList } from "./components/TabServiceList";
import { TabProtocolsList } from "./components/TabProtocolsList";

const firstTab = {
  value: "Serviços",
  content: <TabServiceList />,
};

const secondTab = {
  value: "Protocolos",
  content: <TabProtocolsList />,
};

export function Services() {
  const CONTENT = getContent();

  const params = new URLSearchParams(window.location.search);

  const queryParams = params.get("tab" || "services");

  return (
    <TabTemplate
      selected={queryParams === "protocols" ? secondTab.value : firstTab.value}
      first={firstTab}
      second={secondTab}
      name={CONTENT.SERVICES.HEADER_TITLE}
    />
  );
}
