import { COLORS } from "@/data/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  button {
    margin-top: auto;
    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    background-color: ${COLORS.PRIMARY};

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    height: 56px;
    width: 100%;
  }
`;
