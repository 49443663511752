import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";
import { IonSkeletonText } from "@ionic/react";
import { Text } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Layout } from "@/templates/Layout";
import { Header, HeaderButton } from "@/components";
import { Content } from "@/templates/Content";
import { CardQueuedAppointment } from "../../components/card-queued-appointment";
import { CardScheduling } from "../../components/card-scheduling";
import { FilterQuery } from "./components/filter-query";
import { useAppId, useAppSlug } from "@/store/useAppStore";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useSchedulings } from "./hooks/use-schedulings";
import { filterSchedulingsAtom } from "./atoms/filter-schedulings-atom";

import illustrationSearchScheduling from "@/assets/illustrations/search-scheduling.svg";

export function SearchSchedulings() {
  const params = useSearchParams();
  const navigation = useHistory();
  const appId = useAppId();
  const slug = useAppSlug();

  if (!appId) {
    throw new Error("Not loaded app");
  }

  const queryCPF = useAtomValue(filterSchedulingsAtom);

  const { data, isInitialLoading } = useSchedulings({ appId });

  function handleGoBack() {
    if (params.get("not-back")) {
      navigation.replace(`/${slug}/home`);
      return;
    }

    navigation.goBack();
  }

  return (
    <Layout>
      <Header variant="secondary">
        <div className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <HeaderButton
              variant="secondary"
              icon={<CaretLeft weight="bold" />}
              onClick={handleGoBack}
            />
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            Buscar por CPF
          </Text>
        </div>
      </Header>

      <Content>
        <div className="mx-auto flex min-h-full w-full max-w-3xl flex-col gap-6 px-4 pb-12 pt-6 ios:pb-2">
          <FilterQuery />

          {isInitialLoading && !data ? <ContentLoading /> : null}

          {!isInitialLoading && !data ? <ContentEmpty /> : null}

          {!isInitialLoading && data ? (
            <div className="flex flex-1 flex-col gap-4">
              <Text color="slate-700">
                Resultados para <strong className="font-semibold">“{queryCPF}”</strong>
              </Text>

              <div className="flex flex-col gap-3">
                <Text color="slate-700" weight="medium">
                  Fila
                </Text>

                {data.queuedAppointments.length ? (
                  <div className="flex flex-col gap-4">
                    {data.queuedAppointments.map((item) => (
                      <CardQueuedAppointment
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        status={item.status}
                      />
                    ))}
                  </div>
                ) : null}

                {!data.queuedAppointments.length ? <Text>Nenhum resultado encontrado.</Text> : null}
              </div>

              <div className="flex flex-col gap-3">
                <Text color="slate-700" weight="medium">
                  Agendamentos
                </Text>

                {data.schedulings.length ? (
                  <div className="flex flex-col gap-4">
                    {data.schedulings.map((item) => (
                      <CardScheduling
                        key={item.id}
                        id={item.id}
                        local={item.local?.name}
                        name={item.name}
                        date={item.date?.date}
                        hour={item.hour?.date}
                        status={item.status}
                      />
                    ))}
                  </div>
                ) : null}

                {!data.schedulings.length ? <Text>Nenhum resultado encontrado.</Text> : null}
              </div>
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  );
}

function ContentLoading() {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <IonSkeletonText animated className="h-[21px] w-64" />

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <IonSkeletonText animated className="h-[21px] w-20" />

          <div className="flex flex-col gap-4">
            {Array.from(Array(3).keys()).map((index) => (
              <IonSkeletonText key={index} animated className="h-[89px] rounded-md" />
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <IonSkeletonText animated className="h-[21px] w-32" />

          <div className="flex flex-col gap-4">
            {Array.from(Array(3).keys()).map((index) => (
              <IonSkeletonText key={index} animated className="h-[121px] rounded-md" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ContentEmpty() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 text-center">
      <img src={illustrationSearchScheduling} alt="" />
      <Text>Faça uma busca geral de agendamentos cadastrados no sistema por CPF.</Text>
    </div>
  );
}
