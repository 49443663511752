import { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import { Geolocation } from "@capacitor/geolocation";
import Leaflet, { LatLngTuple } from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { IonButton, IonText, IonIcon, IonFabButton } from "@ionic/react";
import { locateOutline } from "ionicons/icons";

import { Block } from "@/components/old/Block";
import { BlockItemImage } from "@/components/old/BlockItemImage";
import { getAppSlug } from "@/services/old/starter";
import { getAddress } from "@/services/old/location";
import { STORAGE } from "@/data/storage";
import { COLORS } from "@/data/colors";
import { getContent } from "@/content/index";

const Title = styled.h1`
  font-size: 1em;
  margin: 0;
  font-weight: 600;
  color: white;
`;

const OverlayDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 10000;
  box-shadow: inset 0px -30px 50px 0px rgb(0 0 0 / 65%);
`;

const ButtonsDiv = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  margin-bottom: 8px;
  border-top: solid ${COLORS.BORDER} 1px;
`;

export interface MarkerItem {
  id: number;
  lat: number;
  lng: number;
  icon?: string;
  img?: string;
  label?: string;
  href?: string;
}

type BlockMapProps = {
  showUserLocation?: boolean;
  animated?: boolean;
  overlayTitle?: string;
  customMapHeigh?: string;
  hideUserControl?: boolean;
  hideNavegationButton?: boolean;
  hideDetail?: boolean;
  markers: MarkerItem[];
};

export function BlockMapWithMarkers(props: BlockMapProps) {
  const CONTENT = getContent();

  const [map, setMap] = useState<Leaflet.Map | null>(null);
  const [address, setAddress] = useState("");
  const [currentPos, setCurrentPos] = useState(0);
  const [reload, setReload] = useState(true);
  const [isShowingUserLocation, setIsShowingUserLocation] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState<MarkerItem[]>();
  const [markerItems, setMarkerItems] = useState<Leaflet.Marker[]>([]);
  const [userMarker, setUserMarker] = useState<Leaflet.Marker>();
  const appLocation = localStorage.getItem(STORAGE.LOCATION.KEY)?.split(",") || [0, 0];

  const [userLat, setUserLat] = useState("");
  const [userLng, setUserLng] = useState("");

  function isMarkerAvalible(pos: number) {
    return markers && markers[pos] && markers[pos].lat && markers[pos].lng;
  }

  function getCurrentCenter(pos: number) {
    if (isMarkerAvalible(pos) && markers) {
      const originCenter: LatLngTuple = [markers[pos].lat, markers[pos].lng];

      return originCenter;
    } else {
      const originCenter: LatLngTuple = [+appLocation[0], +appLocation[1]];

      return originCenter;
    }
  }

  function updatePosition(pos: number) {
    setIsShowingUserLocation(false);
    setCurrentPos(pos);

    if (map && markers) {
      getAddress(markers[pos].lat, markers[pos].lng)
        .then((res) => {
          setAddress(res.formated);
        })
        .catch(() => {
          setAddress("");
        });

      map.flyTo(getCurrentCenter(pos));
      markerItems[pos].openPopup();
    }
  }
  function locateUser() {
    if (map && markers) {
      setIsShowingUserLocation(true);
      setAddress(CONTENT.LOCATION.YOU_ARE_HERE);
      map.flyTo([+userLat, +userLng]);
      if (userMarker) {
        userMarker.openPopup();
      }
    }
  }

  useEffect(() => {
    let isMounted = true;
    const array: MarkerItem[] = [];

    if (props.showUserLocation) {
      Geolocation.getCurrentPosition()
        .then((res) => {
          setUserLat(res.coords.latitude + "");
          setUserLng(res.coords.longitude + "");
        })
        .catch((err) => {
          console.log("Error getting location", err);
        });
    }

    props.markers.forEach((item: MarkerItem) => {
      if (item && item.lat && item.lng) {
        array.push(item);
      }
    });

    if (array && array.length > 0) {
      setMarkers(array);

      setMarkerItems((markerItems: Leaflet.Marker[]) =>
        array.map((_, i) => markerItems[i] || createRef()),
      );

      if (props.animated) {
        setTimeout(() => {
          if (isMounted) {
            setCurrentPos(isMarkerAvalible(currentPos + 1) ? currentPos + 1 : 0);
            setReload(!reload);
          }
        }, 4000);

        updatePosition(currentPos);
      } else {
        updatePosition(0);
      }
    }

    setTimeout(() => {
      setShowMap(true);
    }, 1000);

    return () => {
      isMounted = false;
    }; // use cleanup to toggle value, if unmounted
  }, [props.markers.length, markerItems[0], reload]);

  return (
    <div>
      <Block noPadding>
        {props.overlayTitle && (
          <OverlayDiv>
            <Title> {props.overlayTitle} </Title>
          </OverlayDiv>
        )}
        {showMap && (
          <MapContainer
            style={{ width: "100%", height: props.customMapHeigh || "350px" }}
            center={getCurrentCenter(0)}
            zoom={props.animated ? 13 : 16}
            zoomControl={!props.hideUserControl}
            dragging={!props.hideUserControl}
            scrollWheelZoom={!props.hideUserControl}
            ref={(map) => setMap(map)}
          >
            <TileLayer url={"https://tile.openstreetmap.org/{z}/{x}/{y}.png"} />

            {userLng.length > 0 && userLat.length > 0 && props.showUserLocation && (
              <Marker
                icon={Leaflet.icon({
                  iconUrl: "/assets/mapicons/male.png",
                  iconSize: [32, 37],
                  iconAnchor: [16, 37],
                  popupAnchor: [0, -32],
                })}
                ref={(ref) => {
                  if (ref) {
                    setUserMarker(ref);
                  }
                }}
                eventHandlers={{
                  click: () => {
                    locateUser();
                  },
                }}
                position={[+userLat, +userLng]}
              >
                <Popup>{CONTENT.LOCATION.YOU_ARE_HERE}</Popup>
              </Marker>
            )}

            {markers &&
              markers.map((item: MarkerItem, index: number) => {
                if (item.icon) {
                  const icon = Leaflet.icon({
                    iconUrl: "/assets/mapicons/" + item.icon + ".png",
                    iconSize: [32, 37],
                    iconAnchor: [16, 37],
                    popupAnchor: [0, -32],
                  });
                  return (
                    <Marker
                      key={index}
                      icon={icon}
                      position={[item.lat, item.lng]}
                      ref={(ref) => {
                        if (ref) {
                          markerItems[index] = ref;
                        }
                      }}
                      eventHandlers={{
                        click: () => {
                          updatePosition(index);
                        },
                      }}
                    >
                      {item.label && <Popup>{item.label}</Popup>}
                    </Marker>
                  );
                } else {
                  return (
                    <Marker
                      key={index}
                      position={[item.lat, item.lng]}
                      ref={(ref) => {
                        if (ref) {
                          markerItems[index] = ref;
                        }
                      }}
                      eventHandlers={{
                        click: () => {
                          updatePosition(index);
                        },
                      }}
                    >
                      {item.label && <Popup>{item.label}</Popup>}
                    </Marker>
                  );
                }
              })}
          </MapContainer>
        )}

        {userLng.length > 0 && userLat.length > 0 && props.showUserLocation && (
          <div
            style={{
              position: "absolute",
              right: "15px",
              top: +(props.customMapHeigh || "350px").replace("px", "") - 75 + "px",
              zIndex: 1000000,
            }}
          >
            <IonFabButton size={"small"} onClick={locateUser}>
              <IonIcon icon={locateOutline} />
            </IonFabButton>
          </div>
        )}

        {markers && markers.length > 0 && !props.hideNavegationButton && (
          <div>
            {!props.hideDetail && (
              <div style={{ display: "flex", flexDirection: "column", margin: "8px" }}>
                {markers[currentPos].label && !isShowingUserLocation && (
                  <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <IonText>
                      <b>{markers[currentPos].label}</b>
                    </IonText>
                  </div>
                )}

                {markers[currentPos].img && !isShowingUserLocation && (
                  <BlockItemImage imageUrl={markers[currentPos].img} />
                )}

                {address && address.length > 0 && (
                  <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <IonText>
                      <b>{!isShowingUserLocation ? CONTENT.LOCATION.ADDRESS + ": " : ""}</b>
                      {address}
                    </IonText>
                  </div>
                )}
                {markers[currentPos].href && !isShowingUserLocation && (
                  <div style={{ marginBottom: "8px", alignSelf: "center" }}>
                    <IonButton
                      expand="block"
                      routerLink={("/" + getAppSlug() + markers[currentPos].href).replace(
                        "//",
                        "/",
                      )}
                    >
                      {CONTENT.INFORMATION.DETAIL}
                    </IonButton>
                  </div>
                )}
              </div>
            )}

            <ButtonsDiv>
              <div style={{ width: "33%", display: "flex", justifyContent: "flex-end" }}>
                <IonButton
                  disabled={!isMarkerAvalible(currentPos - 1)}
                  onClick={() => {
                    updatePosition(currentPos - 1);
                  }}
                >
                  {CONTENT.INFORMATION.BACK}
                </IonButton>
              </div>
              <div
                style={{
                  width: "33%",
                  height: "46px",
                  paddingTop: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IonText>{currentPos + 1}/</IonText>
                <IonText>{markers.length}</IonText>
              </div>

              <div style={{ width: "33%", display: "flex", justifyContent: "flex-start" }}>
                <IonButton
                  disabled={!isMarkerAvalible(currentPos + 1)}
                  onClick={() => {
                    updatePosition(currentPos + 1);
                  }}
                >
                  {CONTENT.INFORMATION.NEXT}
                </IonButton>
              </div>
            </ButtonsDiv>
          </div>
        )}
      </Block>
    </div>
  );
}
