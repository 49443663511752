import { Toaster, ToastIcon, resolveValue } from "react-hot-toast";
import { Text } from "@astrolabe-ui/react";

export function CustomToaster() {
  return (
    <Toaster>
      {(t) => (
        <div
          data-visible={t.visible}
          className="mt-[calc(var(--safe-area-inset-top)+2rem)] flex animate-leave items-center gap-2 rounded-md bg-white px-4 py-3 shadow-sm data-[visible=true]:animate-enter"
        >
          <ToastIcon toast={t} />
          <Text color="slate-700" weight="medium">
            {resolveValue(t.message, t)}
          </Text>
        </div>
      )}
    </Toaster>
  );
}
