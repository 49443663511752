import {
  CardRoot,
  CardContent,
  CardTitle,
  CardDescription,
  CardIndicator,
} from "@astrolabe-ui/react";

import { useAssetSchedulingContext } from "../contexts/AssetsSchedulingContext";
import { useStepsContext } from "../contexts/StepsContext";

export const Places = () => {
  const { institution } = useAssetSchedulingContext();

  const { dataAssetsScheduling, setDataAssetsScheduling, handleNextStep } = useStepsContext();

  const handleSelectPlace = (id: number) => {
    setDataAssetsScheduling &&
      setDataAssetsScheduling((prevState) => ({
        unidade_id: prevState?.unidade_id,
        espaco_id: id,
        documento: prevState?.documento || "",
        nome_completo: prevState?.nome_completo || "",
        telefone: prevState?.telefone || "",
        email: prevState?.email || "",
      }));
    handleNextStep && handleNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-5">
      {institution?.unidades
        .find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
        ?.espacos.filter((place) => place.status)
        .map((place) => (
          <div key={place.id} className="w-full">
            <CardRoot
              data-selected={place.id === dataAssetsScheduling?.espaco_id}
              className="data-[selected=true]:border-primary-500"
              onClick={() => handleSelectPlace(place.id)}
            >
              <CardContent>
                <CardTitle>{place.nome}</CardTitle>
                <CardDescription>{place.descricao}</CardDescription>
              </CardContent>

              <CardIndicator />
            </CardRoot>
          </div>
        ))}
    </div>
  );
};
