import { useEffect, useState } from "react";
import { Network } from "@capacitor/network";
import { Button, Text } from "@astrolabe-ui/react";
import { Modal } from "@/components";

export function WithoutConnectionModal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Network.addListener("networkStatusChange", ({ connected }) => {
      if (!connected) {
        setShowModal(true);
      } else if (showModal) {
        setShowModal(false);
      }
    });

    return () => {
      Network.removeAllListeners();
    };
  }, [showModal]);

  async function handleCloseModal() {
    const { connected } = await Network.getStatus();

    if (!connected) {
      return;
    }

    setShowModal(false);
  }

  return (
    <Modal showModal={showModal} onClose={handleCloseModal} blockedDragEnd>
      <div className="space-y-8">
        <div className="space-y-1 text-center">
          <Text size="md" weight="semibold" color="slate-700">
            Sem conexão com internet
          </Text>
          <Text>
            Verifique sua conexão para retornar a utilizar as funcionalidades da aplicação!
          </Text>
        </div>

        <Button full size="lg" onClick={handleCloseModal}>
          Tentar novamente
        </Button>
      </div>
    </Modal>
  );
}
