import { ResponseInstitution } from "@/services/assetsScheduling";
import { IDataAssetsScheduling } from "../..";

export const getPlace = (
  institution?: ResponseInstitution,
  dataAssetsScheduling?: IDataAssetsScheduling,
) =>
  institution?.unidades
    .find((uni) => uni.id === dataAssetsScheduling?.unidade_id)
    ?.espacos.find((place) => place.id === dataAssetsScheduling?.espaco_id);
