import { PageTemplate } from "@/templates/old/PageTemplate";
import { TabProtocolList } from "@/pages/old/Services/components/TabProtocolList";

export function MyProtocols() {
  return (
    <PageTemplate name="Meus protocolos">
      <TabProtocolList />
    </PageTemplate>
  );
}
