import { useQuery } from "@tanstack/react-query";
import { getNewsByid } from "@/services/news";

interface useNewsByIdParams {
  appId: number;
  newsId: number;
}

export function useNewsById({ appId, newsId }: useNewsByIdParams) {
  return useQuery({
    queryKey: ["news", newsId],
    queryFn: () => getNewsByid({ appId, newsId }),
    enabled: !!appId,
    staleTime: 60 * 1000 * 5, // 5 minutos
  });
}
