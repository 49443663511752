import { Text } from "@astrolabe-ui/react";
import { SignIn } from "@phosphor-icons/react";

import { useAppSlug } from "@/store/useAppStore";
import { NavLink } from "./NavLink";

export function DisconnectedContent() {
  const slug = useAppSlug();

  return (
    <div className="flex-1 space-y-4 pt-[calc(var(--safe-area-inset-top)+1.5rem)]">
      <Text color="slate-700" className="px-4">
        Realize autenticação para ter acesso as funcionalidades
      </Text>

      <NavLink path={`/${slug}/auth`} title="Realizar login" icon={SignIn} />
    </div>
  );
}
