import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import { COLORS } from "@/data/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  footer {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${COLORS.PRIMARY};
    border-radius: 6px;
    background-color: ${COLORS.PRIMARY};

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    height: 56px;
    width: 100%;

    button.secondary {
      background: #e2e8f0;
      color: #64748b;
    }

    &:disabled {
      background-color: #e2e8f0;
    }

    &.outline-btn {
      background-color: transparent;
      border-color: #e2e8f0;
      color: ${COLORS.PRIMARY};
    }
  }
`;

export const ContentFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface ConetentFieldProps {
  relative?: boolean;
}

export const ConetentField = styled.div<ConetentFieldProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 15px;
  color: #777777;
`;

interface LabelProps {
  error?: boolean;
}

export const ContentLabel = styled.label<LabelProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  padding: 18px 16px;
  border-radius: 8px;
  /* border: 1px solid #E2E8F0; */
  border: ${({ error }) =>
    !error ? "1px solid #E2E8F0 !important" : "1px solid #dc2626 !important"};
`;

export const LabelFile = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentLabelTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconRemoveFile = styled(IonIcon)`
  font-size: 24px;
  color: #475569;
  position: absolute;
  top: 85px;
  right: 28px;
  z-index: 200;
  display: flex;
  justify-content: end;
  height: 24px;
  width: 24px;
  background-color: #e2e8f0;
  border-radius: 50%;
  display: inline-block;

  &:hover {
    background-color: #f8fafc;
  }
`;

export const TextFile = styled.span`
  font-size: 14px;
  color: #777777;
`;
interface InputProps {
  error?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  font-size: 14px;
  padding: 18px 16px;
  color: #334155;
  border-radius: 8px;
  border: ${({ error }) =>
    !error ? "1px solid #E2E8F0 !important" : "1px solid #dc2626 !important"};
  background-color: #fff;

  &:focus {
    outline: none;
    border: ${({ error }) =>
      !error ? "1px solid #94A3B8 !important" : "1px solid #dc2626 !important"};
  }
`;

interface TextAreaProps {
  error?: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  font-size: 14px;
  padding: 18px 16px;
  color: #334155;
  border-radius: 8px;
  resize: none;
  border: ${({ error }) =>
    !error ? "1px solid #E2E8F0 !important" : "1px solid #dc2626 !important"};
  background-color: #fff;

  &:focus {
    outline: none;
    border: ${({ error }) =>
      !error ? "1px solid #94A3B8 !important" : "1px solid #dc2626 !important"};
  }
`;

export const TextError = styled.small`
  color: #dc2626;
  font-size: 12px;
`;

export const ContentPlate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const ContentToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentMessageSubCategory = styled.div`
  font-size: 12px;
  color: #475569;
`;

export const ImagePreview = styled.div`
  width: 100%;
  max-height: 200px;
  background: #27272a;
  overflow: hidden;
  border-radius: 8px;
`;

export const Image = styled.img`
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

export const FilePreview = styled.div`
  width: 100%;
  height: 100px;
  background: #27272a;
  border-radius: 8px;
  gap: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const IconFilePreview = styled(IonIcon)`
  font-size: 45px;
`;
