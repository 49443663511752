import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { IonPage } from "@ionic/react";
import { CaretLeft } from "@phosphor-icons/react";

import { Container, BlockText } from "./styles";

import { Header, HeaderButton } from "@/templates/old/ppa/Header";
import { MainContent } from "@/templates/old/ppa/MainContent";

import logoRNComAgente from "@/assets/images/rn-com-a-gente.png";
import logoPPA from "@/assets/images/logo-ppa.png";

export function RNComAGente() {
  const { SLUG } = useParams<{ SLUG: string }>();
  const history = useHistory();

  function handleGoBack() {
    history.goBack();
  }

  return (
    <IonPage>
      <Header>
        <div>
          <HeaderButton icon={<CaretLeft size={20} weight="bold" />} handleOnClick={handleGoBack} />
        </div>
        <NavLink to={`/${SLUG}/`}>
          <img src={logoPPA} alt="OPP RN" className="w-40" />
        </NavLink>
      </Header>

      <MainContent>
        <Container>
          <img src={logoRNComAgente} alt="RN Com a gente" />

          <BlockText>
            <strong>O que é o RN com a Gente?</strong>
            <p>
              O projeto &quot;RN com a Gente&quot; visa implementar iniciativas integradas para o
              atendimento à população de todas as faixas etárias, promovendo uma colaboração entre
              órgãos e entidades públicas, além de parceiros do Governo do RN. Essas ações são
              projetadas para ocorrer antes das Plenárias do Orçamento Participativo (OP), que
              ocorrem nos diversos territórios do Rio Grande do Norte.
            </p>
          </BlockText>

          <BlockText>
            <strong>Qual o objetivo do programa?</strong>
            <p>
              O objetivo central do programa é fortalecer a participação cidadã e garantir que as
              necessidades e demandas da comunidade sejam atendidas de maneira eficaz. Ao envolver
              múltiplos atores, incluindo setores públicos e privados, o &quot;RN com a Gente&quot;
              busca criar soluções abrangentes e sustentáveis que melhorem a qualidade de vida em
              todo o estado, através da facilitação de acesso dos serviços do Governo do Estado.
            </p>
          </BlockText>
        </Container>
      </MainContent>
    </IonPage>
  );
}
