import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { IonSpinner } from "@ionic/react";

import { Modal } from "@/components/old/Modal";
import { getAppSlug } from "@/services/old/starter";
import { getContent } from "@/content/index";
import { ValidationData } from "../..";

import { Container, Infos, Info, Divider } from "./styles";

interface SelectMontherNameProps {
  handleBack: () => void;
  onCloseModal: () => void;
  isLoading: boolean;
}

export function ConfirmData({ handleBack, onCloseModal, isLoading }: SelectMontherNameProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { getValues } = useFormContext<ValidationData>();
  const values = getValues();

  const history = useHistory();
  const CONTENT = getContent();

  function handleCloseModal() {
    setIsOpenModal(false);
    onCloseModal();
    history.push(`/${getAppSlug()}`);
  }

  return (
    <Container>
      <Infos>
        <Info>
          <small>{CONTENT.VINCULAR_TITULAR.CPF}</small>
          <span>{values.cpf}</span>
        </Info>
        <Divider />
        <Info>
          <small>{CONTENT.VINCULAR_TITULAR.MOTHER_NAME}</small>
          <span>{values.motherName.label}</span>
        </Info>
        <Divider />
        <Info>
          <small>{CONTENT.VINCULAR_TITULAR.BIRTH_DATA}</small>
          <span>{values.birthDate.label}</span>
        </Info>
      </Infos>

      <footer>
        <button type="button" onClick={handleBack} className="secondary">
          {CONTENT.VINCULAR_TITULAR.RETURN_STEP}
        </button>
        <button type="submit">
          {isLoading ? <IonSpinner name="crescent" /> : CONTENT.VINCULAR_TITULAR.CONFIRM}
        </button>
      </footer>

      <Modal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        title={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED}
        description={CONTENT.VINCULAR_TITULAR.CPF_BLOCKED_MESSAGE}
        status="error"
        textButton={CONTENT.VINCULAR_TITULAR.RETURN}
        onActionPrimary={handleCloseModal}
      />
    </Container>
  );
}
