import { useMutation } from "@tanstack/react-query";
import { generateUrlTemporaryFile } from "@/services/files";

type useSendTemporaryFileParams = {
  appId: number;
  file: Blob;
};

export function useSendTemporaryFile() {
  return useMutation({
    mutationFn: (data: useSendTemporaryFileParams) => generateUrlTemporaryFile(data),
  });
}
