import { useAtom, useAtomValue } from "jotai";

import { Container, Input, Row } from "./styles";

import { FilterTheme } from "../filter-theme/filter-theme";
import { Theme } from "../../hooks/use-proposals";
import { filterProposalsAtom } from "../../atoms/filter-proposals-atom";
import {
  selectedTerritoryAtom,
  territoriesAtom,
} from "../../../territories/atoms/territories-atom";

import { territories } from "@/pages/ppa/data/cities";

interface FilterBarProps {
  themes: Array<Theme>;
  isExpired?: boolean;
}

export function FilterBar({ themes, isExpired }: FilterBarProps) {
  const [filters, setFilters] = useAtom(filterProposalsAtom);

  const territorie = useAtomValue(territoriesAtom);
  const [selectedTerritory, setSelectedTerritory] = useAtom(selectedTerritoryAtom);

  function handleOnChange(value: string) {
    setFilters({
      ...filters!,
      query: value,
    });
  }

  return (
    <Container>
      <Row>
        <Input
          value={filters.query}
          onChange={(event) => handleOnChange(event.target.value)}
          placeholder="Buscar"
        />
        <FilterTheme themes={themes} />
      </Row>

      {!isExpired ? (
        <div className="flex flex-wrap items-center gap-2">
          {territorie.map((item) => (
            <button
              key={item.id}
              data-selected={item.id === selectedTerritory}
              className="flex w-fit items-center gap-1 rounded-full border border-slate-200 px-3 py-1.5 text-sm font-medium leading-normal text-slate-700 transition-colors data-[selected=true]:border-primary-800 data-[selected=true]:bg-primary-800 data-[selected=true]:text-white"
              onClick={() => setSelectedTerritory(item.id)}
            >
              {item.name}
            </button>
          ))}
        </div>
      ) : null}

      {isExpired ? (
        <div className="flex flex-wrap items-center gap-2">
          {territories.map((territory) => (
            <button
              key={territory.id}
              data-selected={territory.id === selectedTerritory}
              className="flex w-fit items-center gap-1 rounded-full border border-slate-200 px-3 py-1.5 text-sm font-medium leading-normal text-slate-700 transition-colors data-[selected=true]:border-primary-800 data-[selected=true]:bg-primary-800 data-[selected=true]:text-white"
              onClick={() => setSelectedTerritory(territory.id)}
            >
              {territory.territory}
            </button>
          ))}
        </div>
      ) : null}
    </Container>
  );
}
