import { useState, useEffect } from "react";
import styled from "styled-components";
import { IonInput, IonTextarea, IonCheckbox, IonDatetime } from "@ionic/react";

import { UploadFile } from "@/components/old/UploadFile";
import { COLORS } from "@/data/colors";
import { getContent } from "@/content/index";

const Checkbox = styled(IonCheckbox)`
  margin-top: 5px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const ContentDiv = styled.div`
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid ${COLORS.BACKGROUND_COLOR};
`;

const AnswerArea = styled.div`
  width: 100%;
`;

const Title = styled.b`
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DateTime = styled(IonDatetime)`
  width: 100%;
  min-width: fit-content;
  max-width: initial;
`;

interface AnswerProps {
  id: number;
  willAnwser: boolean;
  text?: string;
  date?: Date;
  file?: any;
  objetive?: boolean;
}

interface BlockFormQuestionProps {
  title: string;
  id: number;
  objetive: boolean;
  requiered: boolean;
  type: string;
  oldAnswer?: string;
  maxAnswersAmount?: number;
  answeringsAmount?: number;
  answeresArray?: AnswerProps[];
  rideCheckBox?: boolean;
  onAnswerChange: ({ id, willAnwser, text, date, file }: AnswerProps) => void;
}

export function BlockFormQuestion(props: BlockFormQuestionProps) {
  const [willAnwser, setWillAnwser] = useState(props.requiered);
  const [selectedDate, setSelectedDate] = useState("");
  const [text, setText] = useState("");
  const [uploadedURL, setUploadedURL] = useState("");

  const CONTENT = getContent();

  function convertDateToUTC(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  }

  useEffect(() => {
    if (props.rideCheckBox) {
      setWillAnwser(true);
    }
    if (props.oldAnswer) {
      setWillAnwser(true);
      if (props.type === "string" || props.type === "int") {
        setText(props.oldAnswer);
      } else if (props.type === "photo" || props.type === "file") {
        setUploadedURL(props.oldAnswer);
      } else if (props.type === "date") {
        setSelectedDate(props.oldAnswer);
      }
    }
  }, []);

  if (props.objetive) {
    return (
      <Container>
        <Checkbox
          key={"checkbox" + props.id}
          disabled={props.requiered}
          checked={willAnwser}
          onIonChange={(e) => {
            if (e.detail.checked) {
              if (props.maxAnswersAmount && props.answeringsAmount) {
                if (props.answeringsAmount >= props.maxAnswersAmount) {
                  setWillAnwser(false);
                } else {
                  setWillAnwser(e.detail.checked);
                }
              } else {
                setWillAnwser(e.detail.checked);
              }
            } else {
              setWillAnwser(e.detail.checked);
            }
            props.onAnswerChange({
              id: props.id,
              willAnwser: e.detail.checked,
              objetive: true,
            });
          }}
        />

        <ContentDiv>
          <Title>{props.title}</Title>
        </ContentDiv>
      </Container>
    );
  } else {
    return (
      <Container>
        {!props.rideCheckBox && (
          <Checkbox
            key={"checkbox" + props.id}
            disabled={props.requiered}
            checked={willAnwser}
            onIonChange={(e) => {
              if (e.detail.checked) {
                if (props.maxAnswersAmount && props.answeringsAmount) {
                  if (props.answeringsAmount >= props.maxAnswersAmount) {
                    setWillAnwser(false);
                  } else {
                    setWillAnwser(e.detail.checked);
                  }
                } else {
                  setWillAnwser(e.detail.checked);
                }
              } else {
                setWillAnwser(e.detail.checked);
              }
              props.onAnswerChange({
                id: props.id,
                willAnwser: e.detail.checked,
              });
            }}
          />
        )}
        <ContentDiv key={"contentDiv" + props.id}>
          <Title>{props.title}</Title>
          {(willAnwser || props.rideCheckBox) && (
            <AnswerArea>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: COLORS.BACKGROUND_COLOR || COLORS.PRIMARY,
                }}
              />
              {props.type === "string" && (
                <IonTextarea
                  autoGrow={true}
                  value={text}
                  placeholder={"Resposta"}
                  onIonInput={(e) => {
                    if (e.detail.value) {
                      setText(e.detail.value);
                      props.onAnswerChange({
                        id: props.id,
                        willAnwser,
                        text,
                      });
                    }
                  }}
                />
              )}
              {props.type === "int" && (
                <IonInput
                  type={"number"}
                  placeholder={"0.000,00"}
                  value={text}
                  onIonInput={(e) => {
                    if (e.detail.value) {
                      const text = e.detail.value.replace(/\D/g, "");
                      setText(text);
                      props.onAnswerChange({
                        id: props.id,
                        willAnwser,
                        text,
                      });
                    }
                  }}
                />
              )}
              {props.type === "photo" && (
                <div style={{ marginBottom: "5px" }}>
                  <UploadFile
                    isImage={true}
                    uploadedURL={uploadedURL}
                    onChange={(data) => {
                      props.onAnswerChange({
                        id: props.id,
                        willAnwser,
                        file: data,
                      });
                    }}
                  />
                </div>
              )}
              {props.type === "file" && (
                <UploadFile
                  uploadedURL={uploadedURL}
                  onChange={(data) => {
                    props.onAnswerChange({
                      id: props.id,
                      willAnwser,
                      file: data,
                    });
                  }}
                />
              )}
              {props.type === "date" && (
                <DateTime
                  placeholder={CONTENT.GLOBAL.SELECT}
                  display-timezone="utc"
                  presentation="date"
                  preferWheel
                  value={selectedDate}
                  doneText={CONTENT.GLOBAL.DONE}
                  cancelText={CONTENT.GLOBAL.PREV}
                  onIonChange={(e) => {
                    if (e.detail.value) {
                      setSelectedDate(new Date(String(e.detail.value)) + "");
                      props.onAnswerChange({
                        id: props.id,
                        willAnwser,
                        date: convertDateToUTC(new Date(String(e.detail.value))),
                      });
                    }
                  }}
                />
              )}
            </AnswerArea>
          )}
        </ContentDiv>
      </Container>
    );
  }
}
